import { isAdmin } from '@/components/routing/roles';
import { Chevron } from '@/components/shared/Chevron';
import { FlexBox } from '@/components/shared/FlexBox';
import {
  getColumnWidth,
  getDelimiters, getHeaders, renderTableSectionHeaders, TableColumnHeader
} from '@/components/shared/Table/core/Components';
import { HiddenHeader } from '@/components/shared/Table/core/FilterableTableHeaders';
import {
  FilterableTableHeaders, NoDataRow, Table, TableCell, TableRow
} from '@/components/shared/Table/Table';
import { usePageSettingsContext } from '@/components/userSettings/PageSettingsContext';
import { SortedHeader } from '@/models/SortOrder';
import { NetworkStatusLoading } from '@/services/NetworkStatusLoading';
import { basePathDefaultTermsConditions } from '@/services/termsService';
import { groupBy } from '@/utility/arrayHelpers';
import { GlobalStoreKeys } from '@/utility/globals';
import { SessionStore } from '@/utility/sessionStore';
import { addOrRemoveArray } from '@/utility/stateArrayHelpers';
import { theme } from '@/utility/theme';
import { WarWeb } from '@/war';
import React, {
  PropsWithChildren, useEffect, useState
} from 'react';
import styled from 'styled-components';
import { Area } from './Area';
import { useFilterableTableHeadersContext } from './FilterableTableHeadersContext';
import { tableSections, tableSectionsUnderwriter } from './tableHeaders';
import { CountrySelection } from './TermsConditionsPageContext';

const RegionCell = styled(TableCell)`
  font-size: 16px;
`;

interface RegionProps {
  region: string;
  countries: WarWeb.DefaultTcGroup[];
  expanded: CountrySelection[];
  toggleCountry: (level1: string, level2: string) => void
}
const Region = ({ region, countries, expanded, toggleCountry }: RegionProps) => {
  const [showRegion, setShowRegion] = useState(SessionStore.get(GlobalStoreKeys.DefaultTermsConditionsRegionsToggled)?.[region] ?? true);
  const [focusedId, setFocusedId] = useState<string>();

  const toggleRegion = () => {
    const existing = SessionStore.get(GlobalStoreKeys.DefaultTermsConditionsRegionsToggled);
    const result = existing ? { ...existing, [region]: !showRegion } : { [region]: !showRegion };
    SessionStore.set(GlobalStoreKeys.DefaultTermsConditionsRegionsToggled, result);
    setShowRegion(!showRegion);
  };

  return (
    <>
      <TableRow backgroundColor={theme.blueGray} onClick={toggleRegion}>
        {isAdmin() && <TableCell />}
        <TableCell slim>
          <FlexBox center>
            {showRegion ? <Chevron top /> : <Chevron bottom />}
          </FlexBox>
        </TableCell>
        <RegionCell span={3}>
          {region}
        </RegionCell>
        <TableCell delimiter="left" span={3} />
        <TableCell delimiter="left" span={4} />
        <TableCell delimiter="left" span={4} />
      </TableRow>
      {showRegion && countries?.map(terms => (
        <Area
          terms={terms}
          key={`${terms.level1}.${terms.level2}`}
          expanded={expanded}
          toggleCountry={toggleCountry}
          focusedId={focusedId}
          setFocusedId={setFocusedId} />
      ))}
    </>
  );
};

export const defaultFilters = { pageNumber: 1, pageSize: 2000 };

interface DefaultTermsConditionsTableProps {
  regions?: WarWeb.SearchResult<WarWeb.DefaultTcGroup>,
  loadStatus: NetworkStatusLoading
}

interface TableWrapperProps {
  headers: TableColumnHeader[];
  sticky?: boolean;
  striped?: boolean;
}

const TableWrapper = ({ children, headers, ...props }: PropsWithChildren<TableWrapperProps>) => (
  <Table columns={headers.length} layout={headers.map(h => getColumnWidth(h.format)).join(' ')} {...props}>
    {children}
  </Table>
);

export const DefaultTermsConditionsTable = ({
  regions,
  loadStatus
}: DefaultTermsConditionsTableProps) => {
  const { pageSettings, setPageSettings } = usePageSettingsContext();
  const { filters } = useFilterableTableHeadersContext();

  const tableSectionsByRole = isAdmin() ? tableSections : tableSectionsUnderwriter;
  const headers = getHeaders(tableSectionsByRole);

  const restoredState = pageSettings?.defaultTerms;
  const [expanded, setExpanded] = useState<CountrySelection[]>(restoredState?.expanded || []);

  useEffect(() => {
    if (regions) {
      if (filters.SelectedCoveredAreas) setExpanded(regions.items.map(a => ({ level1: a.level1, level2: a.level2 })) || []);
    }
  }, [regions]);

  useEffect(() => {
    if (!filters) setExpanded([]);
  }, [filters]);

  useEffect(() => {
    setPageSettings(
      {
        defaultTerms: {
          ...pageSettings?.defaultTerms,
          expanded
        }
      }
    );
  }, [expanded]);

  const toggleCountry = (level1: string, level2: string) => {
    addOrRemoveArray(expanded, setExpanded, a => a.level1 === level1 && a.level2 === level2, { level1, level2 });
  };

  const header = (
    <FilterableTableHeaders
      endpoint={basePathDefaultTermsConditions}
      delimiters={getDelimiters(tableSectionsByRole)}
      wide
    />
  );

  const hiddenHeader = React.cloneElement(header, { hidden: true });

  return (
    <>
      <TableWrapper headers={headers} sticky>
        {renderTableSectionHeaders(tableSectionsByRole)}
        {header}
      </TableWrapper>

      <TableWrapper headers={headers} striped>
        <HiddenHeader>
          {hiddenHeader}
        </HiddenHeader>

        {!loadStatus.loading && regions && regions.items?.length > 0
          ? groupBy(regions.items, (item: WarWeb.DefaultTcGroup) => [item.level1]).map((countries: WarWeb.DefaultTcGroup[]) => (
            <Region
              key={`${countries[0].level1}.${countries[0].level2}`}
              region={countries[0].level1}
              countries={countries}
              expanded={expanded}
              toggleCountry={toggleCountry}
            />
          ))
          : <NoDataRow loadStatus={loadStatus} />}
      </TableWrapper>
    </>
  );
};
