import { FlexBox } from '@/components/shared/FlexBox';
import { ChildrenProps } from '@/models/utils/ChildrenProps';
import styled from 'styled-components';

export const ButtonRow = ({ children }: ChildrenProps) => <FlexBox justifyContent="flex-end" gap="20px">{children}</FlexBox>;

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormLayout = styled(Layout)`
  gap: 1rem;
`;

export const ModalContentLayout = styled(Layout)`
  gap: 2rem;
`;
