import { Edit } from '@instech/icons';
import styled from 'styled-components';
import { Routes } from '@/components/routing/routes';
import { QuoteActions } from '@/components/pages/bows/details/quoteActionsMap';
import { useValidationContext } from '@/services/ValidationContext';
import { fieldNameFilter } from '@/utility/errorHelpers';
import { SidebarItem } from './SidebarItem';
import { useQuoteContext } from './QuoteContext';
import {
  Counter, Label, SidebarSubHeader
} from './Components';
import { useSelectedQuote } from '../Bow/useSelectedQuote';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

interface SidebarVesselItemProps {
  compact?: boolean;
  warningsPath?: string;
  warningsAreRegex: boolean;
}

export const SidebarVesselItem = ({ compact = false, warningsPath, warningsAreRegex }: SidebarVesselItemProps) => {
  const { ids } = useQuoteContext();
  const { bowId, quoteId } = ids;
  const { warnings } = useValidationContext();

  const selectedQuote = useSelectedQuote();
  if (!selectedQuote) return null;

  const toolbar = [
    {
      icon: <Edit />,
      action: QuoteActions.SetVessel,
      to: `${Routes.createbow}/vessel?bowId=${bowId}&quoteId=${quoteId}`
    }
  ];

  return (
    <Container>
      <SidebarSubHeader>
        <Label>Vessel</Label>
        <Counter number={fieldNameFilter(warnings, warningsPath, warningsAreRegex).length} />
      </SidebarSubHeader>

      {selectedQuote && (
        <SidebarItem toolbar={toolbar} compact={compact} links={selectedQuote.links} fieldPath="vessel">
          {selectedQuote.vessel.fleet && <h2>{selectedQuote.vessel.fleet.name}</h2>}
          {selectedQuote.vessel.name ? <h3>{selectedQuote.vessel.name}</h3> : <em>No Vessel Chosen</em>}
          <small>{selectedQuote.vessel.imoNumber}</small>
        </SidebarItem>
      )}
    </Container>
  );
};
