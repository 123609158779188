import { theme } from '@/utility/theme';
import { WarWeb } from '@/war';

export type TabKey = 'red' | 'yellow' | 'grey' | 'blue' | 'green';

export interface TabProps {
  key: TabKey;
  highlight: string;
  status?: WarWeb.BowStatus;
  description: string;
  align?: string;
  noDataMessage: string;
  actionToResolve?: string;
}

export const tabs: TabProps[] = [
  {
    key: 'red',
    highlight: theme.pink,
    status: 'Confirmed',
    description: 'Passed exit date',
    noDataMessage: 'No Confirmed BOWs passed exit date',
    actionToResolve: 'Please finalize or update'
  },
  {
    key: 'blue',
    highlight: theme.status.blue,
    status: 'PendingConfirmation',
    description: 'Pending Confirmation',
    noDataMessage: 'No Pending Confirmation bows',
  },
  {
    key: 'yellow',
    highlight: theme.yellow,
    status: 'Quoted',
    description: 'Entry approaching or passed',
    noDataMessage: 'No Quoted BOWs with entry approaching or passed',
    actionToResolve: 'Please update or cancel'
  },
  {
    key: 'grey',
    highlight: theme.border.gray,
    status: 'Draft',
    description: 'Passed entry or exit date',
    noDataMessage: 'No Draft BOWs',
    actionToResolve: 'Please update or cancel'
  },
  {
    key: 'green',
    highlight: theme.border.lightGreen,
    noDataMessage: 'No data',
    description: 'Upcoming and active BOWs'
  }
];
