import React from 'react';
import { NameGroup, Tooltip } from '@/components/shared/Table/core/Components';
import { TableCell } from '@/components/shared/Table/Table';
import { WarWeb } from '@/war';
import { formatDecimal } from '@/utility/formatter';
import { hraNameSplitter } from '@/utility/hraNameSplitter';

export const renderSharedDefaultTermsTableCells = (terms: WarWeb.DefaultTcGroup | WarWeb.DefaultTcMatch) => {
  const hraField = (terms as WarWeb.DefaultTcMatch).highRiskArea;
  const [hra, hraDesc] = hraNameSplitter(hraField);

  return (
    <>
      <TableCell>
        {terms as WarWeb.DefaultTcMatch && <NameGroup values={(terms as WarWeb.DefaultTcMatch).vesselTypes} />}
      </TableCell>
      <TableCell>{terms.tier}</TableCell>
      <TableCell delimiter="left">{formatDecimal(terms.hmRateNcb)}</TableCell>
      <TableCell>{formatDecimal(terms.tloRateNcb)}</TableCell>
      <TableCell>{formatDecimal(terms.lohRateNcb)}</TableCell>
      <TableCell right delimiter="left">{terms.ncbRebate}</TableCell>
      <TableCell right>{terms.agrRebate}</TableCell>
      <TableCell right>{terms.rcrRebate}</TableCell>
      <TableCell right>{formatDecimal(terms.knr5Rebate)}</TableCell>
      <TableCell delimiter="left">{formatDecimal(terms.netHmRate)}</TableCell>
      <TableCell right>{terms.quoteValidHours}</TableCell>
      <TableCell right>{terms.breachDays}</TableCell>
      <TableCell>
        <Tooltip name={hraDesc}>{hra}</Tooltip>
      </TableCell>
    </>
  );
};
