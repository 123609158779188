import { theme } from '@/utility/theme';
import { DropdownControlled } from '@instech/components';
import React, { useState } from 'react';
import styled from 'styled-components';
import { components } from 'react-select';
import { MenuProvider, useMenuContext } from '@/components/pages/dashboard/MenuContext';
import { ButtonProps, ButtonWithLoader } from '../../Button';

const { DropdownIndicator, MenuList } = components;

const SearchResult = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0px;

  & a {
    width: 100%;
  }
`;

const DropdownIndicatorWrapper = styled.div`
  cursor: pointer;
`;

const OpenMenu = (props: any) => {
  const { setIsOpen } = useMenuContext();

  return (
    <DropdownIndicatorWrapper onMouseOver={() => setIsOpen!(true)} onClick={() => setIsOpen!(true)}>
      <DropdownIndicator {...props} />
    </DropdownIndicatorWrapper>
  );
};

const MenuListWrapper = (props: any) => {
  const { setIsOpen } = useMenuContext();

  return (
    <div onMouseLeave={() => setIsOpen!(false)}>
      <MenuList {...props} />
    </div>
  );
};

export interface ButtonItem extends ButtonProps {
  text: string;
  id: string;
  onClick?: () => Promise<void>;
}

interface DropdownButtonProps {
  options: ButtonItem[];
}
export const DropdownWrapper = ({ options }: DropdownButtonProps) => {
  const { isOpen, setIsOpen } = useMenuContext();
  const customStyles = {
    menu: (base: any) => ({
      ...base,
      padding: 0,
      margin: '0px',
      border: 'none',
      borderRadius: 0,
    }),
    control: (provided: any) => ({
      ...provided,
      minWidth: 0,
      border: 'none',
      height: '41px',
      boxShadow: 'none',
      background: theme.green,
      borderRadius: '2px',
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: '0px',
    }),
    option: (base: any, state: any) => ({
      ...base,
      width: '100%',
      background: state.background,
      padding: '0px',
      margin: '0px',
    }),
    menuList: (base: any) => ({
      ...base,
      padding: '0px',
      borderRadius: '0px',
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: theme.marineBlue,
      padding: '0px',
    }),
  };

  const renderResult = (data: unknown) => {
    const result = data as ButtonItem;
    const { text, id, ...rest } = result;
    return (
      <SearchResult key={id}>
        <ButtonWithLoader {...rest}>{text}</ButtonWithLoader>
      </SearchResult>
    );
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsOpen!(false);
  };

  const firstItem = options[0];
  const rest = options.filter((b) => b.id !== firstItem.id);

  return (
    <DropdownControlled
      name="underwriterSection"
      value={firstItem}
      options={rest}
      tabIndex={0}
      styles={customStyles}
      onBlur={handleBlur}
      isSearchable={false}
      components={{
        DropdownIndicator: OpenMenu,
        MenuList: MenuListWrapper,
      }}
      formatOptionLabel={renderResult}
      menuIsOpen={isOpen}
      theme={(defaultTheme: any) => ({
        ...defaultTheme,
        colors: {
          ...defaultTheme.colors,
        },
      })}
    />
  );
};

export const DropdownButton = ({ options }: DropdownButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <MenuProvider isOpen={isOpen} setIsOpen={setIsOpen}>
      <DropdownWrapper options={options} />
    </MenuProvider>
  );
};
