import { Add } from '@instech/icons';
import React, { PropsWithChildren, useState } from 'react';
import styled from 'styled-components';
import { isAdmin, isUnderwriter } from '@/components/routing/roles';
import { ButtonWithLoader } from '@/components/shared/Button';
import { Dropdown, TextField } from '@/components/shared/Form/FormFields';
import { RichText } from '@/components/shared/Form/RichTextForm/RichText';
import { Table, TableHeader } from '@/components/shared/Table/Table';
import { upsertTextCondition, useTextConditions } from '@/services/textConditionsService';
import { theme } from '@/utility/theme';
import { WarWeb } from '@/war';
import { ErrorMessage } from '@/components/shared/Form/core/Components';
import { useRebateAssociations } from '@/services/taxonomyService';
import { Conditions, ConditionsType } from '@/components/pages/agreementDetails/models';
import { ConditionsEditorRow, TextConditionAction } from './ConditionsEditorRow';
import { useConditionsContext } from './ConditionsContext';
import { HiddenHeader } from '@/components/shared/Table/core/FilterableTableHeaders';

const NewWarrantyContainer = styled.div`
  display: flex;
`;

const Container = styled.div`
  width: 80%;
  padding-right: 2rem;
`;

const SaveContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1em;
  margin-bottom: 1em;
  height: 100%;
  width: 20%;
`;

const Content = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 1px solid ${theme.lightGray};
  border-radius: 3px;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0.5rem 0;

  > div {
    width: 35%;
    min-width: 250px;
  }
`;

export interface NewTerm {
  id: number;
  text?: string;
}

interface TableWrapperProps {
  canEdit: boolean;
  sticky?: boolean;
  striped?: boolean;
}

const TableWrapper = ({ children, canEdit, ...props }: PropsWithChildren<TableWrapperProps>) => (
  <Table columns={canEdit ? 5 : 4} layout={`0.35fr 3fr ${canEdit ? '0.4fr' : ''} 0.6fr 0.7fr`} maxHeight="450px" {...props}>
    {children}
  </Table>
);

interface ConditionsEditorProps {
  conditionsType: ConditionsType;
  update: (term: WarWeb.Term, action: TextConditionAction) => void;
  canEdit?: boolean;
  conditions?: Conditions;
}
export const ConditionsEditor = ({ conditionsType, update, canEdit = false, conditions }: ConditionsEditorProps) => {
  const { mutate } = useTextConditions();
  const { data: rebates } = useRebateAssociations();
  const { terms } = useConditionsContext();

  const [filter, setFilter] = useState('');
  const [newText, setNewText] = useState<string>('');
  const [networkError, setNetworkError] = useState<string>();

  const [editId, setEditId] = useState<number | undefined>();
  const [reset, setReset] = useState<any>(undefined);

  const [associatedRebateId, setAssociatedRebateId] = useState<number>();

  const onRebateChangeHandler = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setAssociatedRebateId(value === '' ? undefined : parseInt(value));
  };

  const addNewCondition = async () => {
    if (!newText) return;

    const body: WarWeb.UpsertTextTermRequest = {
      action: 'Add',
      text: newText,
      associatedRebate: { rebateId: associatedRebateId }
    };
    setNetworkError(undefined);
    const { error } = await upsertTextCondition(body);

    if (error) {
      setNetworkError('An error occurred, could not create');
      return;
    }

    void mutate();
    setNewText('');
    setReset({});
  };

  const rebateOptions = rebates?.map(rebate => ({ name: rebate.name, value: `${rebate.id}` }));
  rebateOptions?.unshift({ name: 'All', value: '' });

  const filteredTerms = filter ? terms?.filter(x => (`${x.text} ${x.associatedRebate?.name}`)?.toLowerCase().includes(filter.toLowerCase())) : terms;

  const header = (
    <>
      <TableHeader>ID</TableHeader>
      <TableHeader>Description</TableHeader>
      {canEdit && <TableHeader />}
      <TableHeader center>Included</TableHeader>
      <TableHeader center>Target</TableHeader>
    </>
  );

  const hiddenHeader = React.cloneElement(header, { hidden: true });

  return (
    <>
      {/* <TreeView name="selectedTerms" data={selectedTerms} /> */}
      <FilterContainer>
        <TextField
          name="filter"
          label="Filter"
          placeholder="Filter"
          onChange={e => setFilter(e.target.value)}
          value={filter}
        />
      </FilterContainer>
      <Content>
        <TableWrapper canEdit={canEdit} sticky>
          {header}
        </TableWrapper>

        <TableWrapper canEdit={canEdit} striped>
          <HiddenHeader>
            {hiddenHeader}
          </HiddenHeader>

          {filteredTerms?.filter(t => !!t.text)?.map(w => {
            //  w.id>=0 is a temporary workaround to disable editing of required warranties for underwriters
            // in the future we will have a separate warranty property for this
            const canToggle = isAdmin() || (isUnderwriter() && w.id >=0 ) || (conditions && !conditions[conditionsType].find(t => t.termId === w.id)?.mandatoryOptions.some(u => u.option));
            return (
              <ConditionsEditorRow
                key={w.id}
                term={w}
                canEdit={canEdit}
                canToggle={canToggle ?? false}
                editMode={editId === w.id}
                setEditId={setEditId}
                update={update}
              />
            );
          })}
        </TableWrapper>
      </Content>

      {isAdmin() && (
        <>
          <div>
            {`Add new ${conditionsType}`}
          </div>
          <NewWarrantyContainer>
            <Container>
              <RichText
                richText={newText}
                reset={reset}
                onRichTextChange={value => setNewText(value)}
              />
            </Container>
            <SaveContainer>
              <Dropdown
                compact
                label="Rebate association"
                name="associatedRebate"
                placeholder="All"
                options={rebateOptions || []}
                changeHandler={onRebateChangeHandler}
              />
              <ButtonWithLoader onClick={addNewCondition} background={theme.border.blue}>
                <Add />
              </ButtonWithLoader>
              {networkError && (<ErrorMessage>{networkError}</ErrorMessage>)}
            </SaveContainer>
          </NewWarrantyContainer>
        </>
      )}

    </>
  );
};
