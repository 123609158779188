import { openErrorModal } from '@/components/error/ErrorModal';
import { PageHeader } from '@/components/layout/PageHeader';
import { useModalContext } from '@/components/modal/ModalContext';
import { isBroker } from '@/components/routing/roles';
import { Routes } from '@/components/routing/routes';
import { QuoteProvider } from '@/components/shared/BowSidebar/QuoteContext';
import { HttpActionProps } from '@/components/shared/HttpAction';
import { useBow } from '@/services/bowServices';
import { HateoasExecutorProvider } from '@/services/HateoasExecutorContext';
import { simpleRequest } from '@/services/utility/simpleFetch';
import { ValidationProvider } from '@/services/ValidationContext';
import { parseErrors } from '@/utility/errorHelpers';
import { Globals } from '@/utility/globals';
import { WarWeb } from '@/war';
import {
  AxiosRequestConfig, AxiosResponse, Method
} from 'axios';
import {
  useEffect, useRef, useState
} from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ProgressBar } from '../../../shared/ProgressBar';
import {
  getBowDetailsPageHeader, statusStep, statusSteps
} from '../details/BowDetails';
import { Documentation } from './Documentation';

const Page = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledProgressBar = styled(ProgressBar)`
  width: 500px;
  display: flex;
  align-items: center;
`;

export const DocumentsPage = () => {
  const { bowId } = useParams<any>();
  const { data: bowData, mutate, isLoading } = useBow(bowId);
  const { open } = useModalContext();
  const [loading, setLoading] = useState(true);

  const isMounted = useRef(true);
  useEffect(() => () => {
    isMounted.current = false;
  }, []);

  const hateoasExecutor = async <T,>(links: HttpActionProps[] | undefined, action: string, body?: {}, sectionName?: string, config?: AxiosRequestConfig)
    : Promise<AxiosResponse<T> | undefined> => {
    const link = links?.find(n => n.action === action);
    if (!link) {
      const message = isBroker() ? undefined : `Action ${action} missing in links`;
      open(() => openErrorModal(message));
      return undefined;
    }

    setLoading(true);
    const { data, error } = await simpleRequest<T>(link.href, link.httpVerb as Method, body, config);

    if (error) parseErrors(isMounted.current, (message, title) => open(openErrorModal(message, title)), error);
    else await mutate();

    setLoading(false);
    return data;
  };

  return (
    <ValidationProvider>
      <HateoasExecutorProvider
        hateoasExecutor={hateoasExecutor}
        loading={loading}
        setLoading={setLoading}
      >
        <Page>
          {!isLoading && bowData && (
            <>
              <HeaderContainer>
                <PageHeader
                  breadcrumb={{ label: getBowDetailsPageHeader(bowData), to: `${Routes.bows}/${bowId}` }}
                  header="Documents"
                />
                <StyledProgressBar className="" steps={statusSteps} step={(statusStep(bowData.status) ?? 1) - 1} />

                {/* <ButtonGroup>
            <Button to="#" disabled>Notification Settings</Button>
            <Button to="#" disabled>Download all</Button>
          </ButtonGroup> */}
              </HeaderContainer>

              <ContentContainer>
                {bowData.quotes?.map((quote: WarWeb.BowQuote) => (
                  <QuoteProvider
                    key={quote.id}
                    quoteDocuments={quote.documents}
                    ids={{ bowId, quoteId: quote.id }}
                   >
                    <Documentation quote={quote} />
                  </QuoteProvider>
                ))}
              </ContentContainer>
            </>
          )}
        </Page>

      </HateoasExecutorProvider>
    </ValidationProvider>
  );
};
