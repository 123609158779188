import { TableSection } from '@/components/shared/Table/core/Components';

export const tableSections : TableSection[] =
[
  {
    columns: [
      { title: 'Vessel',
        propertyName: 'Name',
        parameterName: 'SelectedNames' },
      { title: 'Fleet',
        propertyName: 'FleetName',
        parameterName: 'SelectedFleetNames' },
      { title: 'Client',
        propertyName: 'ClientName',
        parameterName: 'SelectedClientNames' },
      { title: 'IMO',
        propertyName: 'Imo',
        parameterName: 'SelectedImos' },
      { title: 'Type',
        propertyName: 'VesselTypeName',
        parameterName: 'SelectedVesselTypeNames' },
    ]
  }
];
