import { InlineModalProps } from '@/components/modal/InlineModalProps';
import { ModalLayout } from '@/components/modal/ModalLayout';
import { Routes } from '@/components/routing/routes';
import { Button } from '@/components/shared/Button';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { AgreementTypeEnum } from '@/models/Agreement';
import { theme } from '@/utility/theme';
import { WarningFilled } from '@instech/icons';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { useAgreementsPageContext } from '../../agreements/AgreementsPageContext';

// TODO: Same styling as on DeleteAgreementConfirmationModal and vessel agreements copy/paste
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 2rem;
  text-align: center;
`;

const StyledWarning = styled(WarningFilled)`
  path {
    fill: ${props => props.theme.marineBlue};
  }
  height: 60px;
  width: 60px;
  margin: 3rem;
`;

const PendingUpdateHeader = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 2rem;
`;

const PendingUpdateDescription = styled.div`
  margin-bottom: 2rem;
`;

export const AgreementPendingUpdateModal = ({ closeModal }: InlineModalProps) => {
  const history = useHistory();
  const { agreementType } = useAgreementsPageContext();

  const handleButtonClick = () => {
    history.push(agreementType === AgreementTypeEnum.Standard ? Routes.termsDefault : Routes.termsSpecific);
    closeModal();
  };

  const options = {
    size: 'small'
  };

  return (
    <ModalLayout closeModal={closeModal} options={options}>
      <Container>
        <StyledWarning />
        <PendingUpdateHeader>
          The agreement updates will be completed soon
        </PendingUpdateHeader>
        <PendingUpdateDescription>
          New agreements and value changes might take up to five minutes to display in the T&C overview
        </PendingUpdateDescription>
      </Container>
      <ButtonGroup justify="center">
        {/* <Button onClick={close} background={theme.ultraLightGray}>Stay here</Button> */}
        <Button onClick={handleButtonClick} background={theme.green}>Go to overview</Button>
      </ButtonGroup>
    </ModalLayout>
  );
};
