import { openErrorModal } from '@/components/error/ErrorModal';
import { useModalContext } from '@/components/modal/ModalContext';
import { Box } from '@/components/shared/Box';
import { Button, ButtonWithLoader } from '@/components/shared/Button';
import { FlexBox } from '@/components/shared/FlexBox';
import { CustomSelect, CustomSelectValue } from '@/components/shared/Form/fields/CustomSelect';
import { NumberField } from '@/components/shared/Form/fields/NumberField';
import { TextField } from '@/components/shared/Form/FormFields';
import { renderTableHeaders, SpanCell } from '@/components/shared/Table/core/Components';
import {
  Table, TableCell, TableRow
} from '@/components/shared/Table/Table';
import { Validator } from '@/components/shared/Validator';
import { useHighRiskAreas } from '@/services/highRiskAreasService';
import {
  deleteKnRTerm, upsertKnrTerms, useKnRTermsForFleet
} from '@/services/knrtermsService';
import { useValidationContext } from '@/services/ValidationContext';
import { minus } from '@/utility/arrayHelpers';
import { pluralize } from '@/utility/dateCalc';
import { parseErrors } from '@/utility/errorHelpers';
import { getInputString, getInputValue } from '@/utility/formatter';
import { MaxNumberTimeValue } from '@/utility/globals';
import { theme } from '@/utility/theme';
import { WarWeb } from '@/war';
import { Divider } from '@instech/components';
import { Trash } from '@instech/icons';
import React, { useEffect, useState } from 'react';
import { ActionMeta, SingleValue } from 'react-select';
import styled from 'styled-components';
import { useKnRTermsPageContext } from './context/KnRTermsPageContext';
import { VesselPickerModal } from './VesselPickerModal';

const ContentContainer = styled.div`
  padding: 1rem;
`;

const StyledTableCell = styled(TableCell)`
  padding-right: 2em;
`;

const Wrapper = styled.div`
  margin: 1rem 0;
`;

const DropdownContainer = styled.div`
  width: 350px;
`;

const tableHeaders = ['', 'Included', 'Excluded', 'Days'];

interface NewEntryState extends WarWeb.KnrTermTarget {
  additionalGrossPremium?: number;
  lossOfHireRate?: string;
  originalVesselIds?: number[];
}

export const NewEntry = () => {
  const { fleetId, entry, setEntry } = useKnRTermsPageContext();
  const { data: terms, mutate: mutateKnR } = useKnRTermsForFleet(fleetId);
  const { data: highRiskAreas } = useHighRiskAreas();

  const { open } = useModalContext();
  const [state, setState] = useState<NewEntryState>({ fleetId, additionalGrossPremium: 0, lossOfHireDays: 0, originalVesselIds: [] });
  const { setErrors } = useValidationContext();
  const [customTripType, setCustomTripType] = useState(false);
  const [showVesselModal, setShowVesselModal] = useState(false);

  useEffect(() => {
    setState(s => ({ ...s, vesselIds: [], fleetId }));
  }, [fleetId]);

  const active = terms?.find(t => t.id === entry);

  useEffect(() => {
    setErrors(undefined);
    if (active) {
      const originalVesselIds = [...(active.target.vesselIds || [])];
      setState({
        fleetId: active.target.fleetId,
        highRiskAreaId: active.target.highRiskAreaId,
        vesselIds: [...(active.target.vesselIds || [])],
        armedGuardsIncluded: active.target.armedGuardsIncluded,
        lossOfHireIncluded: active.target.lossOfHireIncluded,
        lossOfHireDays: active.target.lossOfHireDays,
        tripType: active.target.tripType,
        additionalGrossPremium: active.additionalGrossPremium,
        lossOfHireRate: active.lossOfHireRate ? active.lossOfHireRate.toString() : undefined,
        originalVesselIds
      });
      setCustomTripType(!(active.target.tripType === 'Single transit' || active.target.tripType === 'Round voyage'));
    } else {
      setState(oldState => ({
        ...oldState,
        vesselIds: []
      }));
    }
  }, [active]);

  const setVesselIds = (vesselIds?: number[]) => {
    setState({ ...state, vesselIds });
  };

  const resetEntry = () => {
    void mutateKnR();
    setEntry();
  };

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = e.target;

    const stateWithLOHHandled = { ...state };
    if (name === 'lossOfHireIncluded' && value === 'off') {
      stateWithLOHHandled.lossOfHireDays = 0;
    }

    if (type === 'radio' && (value === 'on' || value === 'off')) {
      setState({ ...stateWithLOHHandled, [name]: value === 'on' });
      return;
    }

    let valueParsed: any = value;
    if (name === 'lossOfHireDays') valueParsed = parseInt(valueParsed);
    if (name === 'additionalGrossPremium') valueParsed = parseFloat(valueParsed);
    if (name === 'lossOfHireRate') valueParsed = value;
    setState({ ...stateWithLOHHandled, [name]: Number.isNaN(valueParsed) ? value : valueParsed });
  };

  const onHraSelect = (input: SingleValue<CustomSelectValue>, action: ActionMeta<CustomSelectValue>) => {
    if (!input) return;
    setState({ ...state, highRiskAreaId: input.value });
  };

  const deleteKnrTermsHandler = async (vesselsToRemove?: number[]) => {
    const request = { ...state, vesselIds: vesselsToRemove || state.vesselIds };
    const { error } = await deleteKnRTerm(request);
    if (error) open(openErrorModal);
    resetEntry();
  };

  const upsertKnrTermsHandler = async () => {
    if (state.armedGuardsIncluded == null || state.lossOfHireIncluded == null) {
      setErrors({
        ...(state.armedGuardsIncluded == null && { ArmedGuardsIncluded: ['Included or excluded must be specified'] }),
        ...(state.lossOfHireIncluded == null && { LossOfHireIncluded: ['Included or excluded must be specified'] })
      });
      return;
    }
    setErrors(undefined);

    const vesselsToRemove = minus(state.originalVesselIds, state.vesselIds);
    if (vesselsToRemove && vesselsToRemove.length > 0) {
      await deleteKnrTermsHandler(vesselsToRemove);
    }

    const request: WarWeb.KnrTermUpsertRequest = {
      target: { ...state },
      additionalGrossPremium: state.additionalGrossPremium,
      lossOfHireRate: state.lossOfHireRate ? parseFloat(state.lossOfHireRate) : undefined
    };
    const { error } = await upsertKnrTerms(request);
    if (error) parseErrors(true, (message, title) => open(openErrorModal(message, title)), error, setErrors);
    else resetEntry();
  };

  const options = highRiskAreas?.map(hra => ({ label: hra.name, value: hra.id }));

  return (
    <Box header={active ? 'Editing entry' : 'New entry'} highlight={active ? theme.status.yellow : theme.green}>
      <ContentContainer>

        <Wrapper>
          <Validator keys={['HighRiskAreaId']}>
            <FlexBox flexDirection="column" gap="1em">
              <DropdownContainer>

                <CustomSelect
                  aria-label="Area"
                  placeholder="-"
                  name="highRiskAreaId"
                  options={options}
                  value={options?.find(l => l.value === state?.highRiskAreaId)}
                  onChange={onHraSelect}
                />
              </DropdownContainer>
              {fleetId && (
                <Button background={theme.lightGray} onClick={() => setShowVesselModal(true)}>
                  {state.vesselIds && state.vesselIds.length > 0 ?
                    `${state.vesselIds.length} ${pluralize('vessel', state.vesselIds.length)}` :
                    'Select vessels'}
                </Button>
              )}
            </FlexBox>
          </Validator>
          <Divider />
        </Wrapper>

        <Table columns={tableHeaders.length} layout="2fr 1fr 1fr 1fr">
          {renderTableHeaders(tableHeaders, [], [], [0, 1, 2, 3], true)}
          <TableRow>
            <TableCell>Armed guards</TableCell>
            <TableCell center>
              <input type="radio" name="armedGuardsIncluded" value="on" checked={state.armedGuardsIncluded || false} onChange={changeHandler} />
            </TableCell>
            <TableCell center>
              <input type="radio" name="armedGuardsIncluded" value="off" checked={state.armedGuardsIncluded === false || false} onChange={changeHandler} />
            </TableCell>
            <TableCell />
          </TableRow>
          <TableRow>
            <SpanCell height="1" start={1} end={-1}>
              <Validator keys={['ArmedGuardsIncluded']} />
            </SpanCell>
          </TableRow>

          <TableRow>
            <TableCell>Loss of hire</TableCell>
            <TableCell center>
              <input type="radio" name="lossOfHireIncluded" value="on" checked={state.lossOfHireIncluded || false} onChange={changeHandler} />
            </TableCell>
            <TableCell center>
              <input type="radio" name="lossOfHireIncluded" value="off" checked={state.lossOfHireIncluded === false || false} onChange={changeHandler} />
            </TableCell>
            <TableCell minWidth="80px">
              <Validator keys={['LossOfHireDays', 'LossOfHireIncluded']} hideErrors>
                <NumberField
                  disabled={!state.lossOfHireIncluded}
                  type="int"
                  maxValue={MaxNumberTimeValue}
                  name="lossOfHireDays"
                  value={getInputValue(state.lossOfHireDays)}
                  onChange={changeHandler}
                  placeholder="Days"
                />

              </Validator>
            </TableCell>
          </TableRow>
          <TableRow>
            <SpanCell height="1" start={1} end={-1}>
              <Validator keys={['LossOfHireDays', 'LossOfHireIncluded']} />
            </SpanCell>
          </TableRow>

          <TableRow>
            <TableCell span={4}><Divider /></TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Single transit</TableCell>
            <TableCell center>
              <input type="radio"
                name="tripType"
                value="Single transit"
                checked={state.tripType === 'Single transit' && !customTripType}
                onChange={e => {
                  setCustomTripType(false);
                  changeHandler(e);
                }} />
            </TableCell>
            <TableCell span={2} />
          </TableRow>
          <TableRow>
            <TableCell>Round voyage</TableCell>
            <TableCell center>
              <input
                type="radio"
                name="tripType"
                value="Round voyage"
                checked={state.tripType === 'Round voyage' && !customTripType}
                onChange={e => {
                  setCustomTripType(false);
                  changeHandler(e);
                }} />
            </TableCell>
            <TableCell span={2} />
          </TableRow>
          <TableRow>
            <TableCell>Custom type</TableCell>
            <TableCell center>
              <input
                type="radio"
                checked={customTripType}
                onChange={() => {
                  setCustomTripType(true);
                  setState({ ...state, tripType: '' });
                }} />
            </TableCell>
            <TableCell span={2} />
            {customTripType && (
              <TableRow>
                <TableCell span={4}><TextField name="tripType" onChange={changeHandler} value={state.tripType} /></TableCell>
              </TableRow>
            )}
          </TableRow>

          <TableRow>
            <SpanCell height="1" start={1} end={-1}>
              <Validator keys={['TripType']} />
            </SpanCell>
          </TableRow>
        </Table>

        <Divider />

        <Table columns={2} layout="3fr 2fr">
          <TableRow>
            <TableCell>
              Additional premium (gross)

            </TableCell>
            <TableCell>
              <Validator keys={['AdditionalGrossPremium']} hideErrors>
                <NumberField
                  postfix="USD"
                  name="additionalGrossPremium"
                  onChange={changeHandler}
                  value={getInputValue(state.additionalGrossPremium)}
                />

              </Validator>
            </TableCell>
          </TableRow>
          <TableRow>
            <SpanCell height="1" start={1} end={-1}>
              <Validator keys={['AdditionalGrossPremium']} />
            </SpanCell>
          </TableRow>

          <TableRow>
            <TableCell>
              Loss of Hire rate

            </TableCell>
            <StyledTableCell>
              <Validator keys={['LossOfHireRate']} hideErrors>
                <NumberField
                  maxValue={100}
                  postfix="%"
                  name="lossOfHireRate"
                  onChange={changeHandler}
                  value={getInputString(state.lossOfHireRate)}
                />
              </Validator>
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <SpanCell height="1" start={1} end={-1}>
              <Validator keys={['LossOfHireRate']} />
            </SpanCell>
          </TableRow>

          <TableRow>
            <TableCell span={2}>
              {
                !active ?
                  (
                    <ButtonWithLoader onClick={upsertKnrTermsHandler} background={theme.green}>Add as new entry</ButtonWithLoader>
                  )
                  : (
                    <FlexBox gap="5px">
                      <ButtonWithLoader onClick={e => deleteKnrTermsHandler()} background={theme.mediumRed}><Trash /></ButtonWithLoader>
                      <Button onClick={resetEntry} background={theme.lightGray}>Cancel</Button>
                      <ButtonWithLoader onClick={upsertKnrTermsHandler} background={theme.softBlue}>Save</ButtonWithLoader>
                    </FlexBox>
                  )
              }
            </TableCell>
          </TableRow>
        </Table>
      </ContentContainer>
      {showVesselModal && <VesselPickerModal closeModal={() => setShowVesselModal(false)} vesselIds={state.vesselIds} setVesselIds={setVesselIds} />}
    </Box>
  );
};
