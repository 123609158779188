import axios, { AxiosRequestConfig } from 'axios';
import { SessionStore } from '@/utility/sessionStore';
import { userManager } from '@/utility/userManager';
import { HttpError } from './HttpError';

const client = axios.create({
  baseURL: '/api/',
  timeout: 30000
});

client.interceptors.request.use(
  config => userManager.getAccessTokenAsync()
    .then(token => {
      if (token && config.headers) {
        // axios 0.22 introduced stricter typing which isn't correct in all scenarios, like this one
        // awaiting type update so that we can clean this up
        (config.headers as any).Authorization = `Bearer ${token}`;
      }
      return config;
    }),
  error => Promise.reject(error)
);

client.interceptors.response.use(
  response => {
    if (SessionStore.get('devState')?.fakeErrors) {
      // eslint-disable-next-line no-console
      console.log('axios response fake error');
      throw new HttpError(500);
    }
    return response;
  },
  // https://github.com/axios/axios/issues/815
  error => {
    if (
      error.request.responseType === 'blob' &&
      error.response.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf('json') !== -1
    ) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          // need to process reader.response (ArrayBuffer | string | null) via JSON stringify
          // eslint-disable-next-line no-param-reassign
          error.response.data = JSON.parse(JSON.parse(JSON.stringify(reader.result)));
          resolve(Promise.reject(error));
        };

        reader.onerror = () => {
          reject(error);
        };

        reader.readAsText(error.response.data);
      });
    }

    return Promise.reject(error);
  }
);

export const getJsonAsync = async (endpoint: string, config?: AxiosRequestConfig) =>
  client.get(endpoint, config).then(response => response.data);

export const postJsonAsync = async <T>(endpoint: string, body: any, config?: AxiosRequestConfig) =>
  client.post<T>(endpoint, body, config).then(response => response.data);

export default client;
