import React, { useState, FC } from 'react';
import { TableCell, TableRow } from '@/components/shared/Table/Table';
import { addOrRemove } from '@/utility/arrayHelpers';
import { Insify } from '@/war';
import { Toggle } from '../../Form/fields/Toggle';

export type RebateAction = 'add' | 'remove';

interface RebatesEditorRowProps {
  rebate: Insify.War.Terms.Core.Models.Lists.Rebate.Rebate;
  selectedRebates: number[];
  removeDisabled: boolean;
  setSelectedRebates: (a: number[]) => void;
  updateRebates: (rebateId: number, action: RebateAction) => void;
  setWarning: (w: string) => void;
}
export const RebatesEditorRow: FC<RebatesEditorRowProps> = ({ rebate, selectedRebates, setSelectedRebates, removeDisabled, updateRebates, setWarning }) => {
  const isIncluded = selectedRebates?.includes(rebate.id);
  const [selectedState, setSelectedState] = useState<boolean>(isIncluded);

  const validate = () => {
    if (isIncluded && removeDisabled) {
      setWarning('Currently not possible to remove rebates from Total Voyage. Please remove rebates from individual destinations.');
      return false;
    }
    return true;
  };

  const onCheckboxChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!validate()) return;

    setSelectedState(e.target.checked);

    const newSelectedRebates = [...selectedRebates];
    addOrRemove(newSelectedRebates, rebate.id);
    setSelectedRebates(newSelectedRebates);

    updateRebates(rebate.id, e.target.checked ? 'add' : 'remove');
  };

  return (
    <TableRow>
      <TableCell>{rebate.name}</TableCell>
      <TableCell textWrap>
        {rebate.description}
      </TableCell>
      <TableCell center onClick={validate}>
        <Toggle
          name={`${rebate.id}`}
          checked={selectedState}
          onChange={onCheckboxChangeHandler}
        />
      </TableCell>
    </TableRow>
  );
};
