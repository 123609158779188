import { Counter } from '@/components/shared/BowSidebar/Components';
import { Box } from '@/components/shared/Box';
import { theme } from '@/utility/theme';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { CoverageBroker } from './CoverageBroker';
import { useAgreementDataContextBroker } from '../agreementDetails/AgreementDataContextBroker';
import { PremiumRebatesBroker } from './PremiumRebatesBroker';
import { WarrantiesSubjectivitiesBroker } from './WarrantiesSubjectivitiesBroker';

const TabsContainer = styled.div`
    display: flex;
`;

const Tab = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  padding: 0.5rem 2rem 0.5rem 0.5rem;
  cursor: pointer;
  border-bottom: 2px solid ${props => props.theme.whiteBlue};
  color: ${props => props.theme.sanMarino};
  margin-top: 5px;

  ${props => props.selected && css`
    margin-top: 0;
    border-top: 5px solid ${props.theme.green};
    border-bottom: none;
    border-right: 2px solid ${props.theme.whiteBlue};
    &:not(:first-child) {
      border-left: 2px solid ${props.theme.whiteBlue};
    }
    color: ${props.theme.marineBlue};
  `}
`;

const ContentContainer = styled.div`
  padding: 2rem;
`;

const tabs = [
  'Coverage',
  'Premium & rebates',
  'Warranties & subjectivities'
];

const TabText = ({ text, count }: { text: string, count?: number }) => (
  <>
    {text}
    {count ? <Counter color={theme.white} backgroundColor={theme.sanMarino} number={count} /> : null}
  </>
);

export const SettingsBroker = () => {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const { data } = useAgreementDataContextBroker();

  return (
    <Box header="Settings">
      <TabsContainer>
        <Tab selected={activeTab === tabs[0]} onClick={() => setActiveTab(tabs[0])}>{tabs[0]}</Tab>
        <Tab selected={activeTab === tabs[1]} onClick={() => setActiveTab(tabs[1])}>{tabs[1]}</Tab>
        <Tab selected={activeTab === tabs[2]} onClick={() => setActiveTab(tabs[2])}>
          <TabText text={tabs[2]} />
        </Tab>
      </TabsContainer>
      <ContentContainer>
        {activeTab === tabs[0] &&
          <CoverageBroker coverage={data.coverage} />}
        {activeTab === tabs[1] &&
          <PremiumRebatesBroker data={data} />}
        {activeTab === tabs[2] &&
          <WarrantiesSubjectivitiesBroker data={data} />}
      </ContentContainer>
    </Box>
  );
};
