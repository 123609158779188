import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  EditorState, convertToRaw, convertFromRaw, CompositeDecorator, ContentBlock, RawDraftContentState
} from 'draft-js';
import styled from 'styled-components';
import linkify from 'linkify-it';
import tlds from 'tlds';
import { InputPopup } from '@/components/shared/Form/RichTextForm/core/InputPopup';
import { markdownToDraft, draftToMarkdown } from './core/markdown-draft-js';

const urlMatcher = linkify()
  .tlds(tlds);

interface RichTextState {
  id: string;
  text: string;
}
const initialState: () => RichTextState = () => ({
  id: uuidv4(),
  text: ''
});

interface RichTextData {
  text: string;
}
export const initializeRichText = (data: RichTextData) => {
  if (data === undefined) return initialState();

  const { text } = data;

  return {
    text: text ?? ''
  };
};

const markdownOptions = {
  preserveNewlines: true,
  remarkableOptions: {
    disable: {
      // Disabling this makes the parser treat '    ' before a line as a paragraph instead
      // of a 'code' block, which the oarser doesn't currently have a setup to parse.
      block: ['code']
    }
  }
};

const findURLs = (contentBlock: ContentBlock, callback: (startIndex: number, endIndex: number) => void) => {
  const text = contentBlock.getText();

  const matches = urlMatcher.match(text);

  if (matches?.length) {
    matches.forEach(match => callback(match.index, match.lastIndex));
  }
};

const findLinkEntities = (contentBlock: any, callback: any, contentState: any) => {
  // find "typed in" URLs (like typing bt.no)
  findURLs(contentBlock, callback);

  // find "clicked in" links, using the toolbar
  contentBlock.findEntityRanges(
    (character: any) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      );
    },
    callback
  );
};

const StyledLink = styled.a`
  text-decoration: underline;
`;

const Link = (props: any) => {
  const linkInstance = props.entityKey ? props.contentState.getEntity(props.entityKey) : null;
  const currentUrl = linkInstance?.getData().url;

  const [url, setUrl] = useState<string>(currentUrl);

  return (
    // <InputPopup url={url} setUrl={setUrl}>
    <StyledLink
      href={url}
      target="_blank"
      rel="noopener noreferrer">
      {props.children}
    </StyledLink>
    // </InputPopup>
  );
};

const decorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link,
  },
]);

export const getEditorState = (text: string) => {
  const draftState = convertFromRaw(markdownToDraft(text, markdownOptions) as RawDraftContentState);

  return new (EditorState.createWithContent as any)(draftState, decorator) as EditorState;
};

export const editorStateToText = (editorState: EditorState) => {
  const draftContent = convertToRaw(editorState.getCurrentContent());
  const markDown = draftToMarkdown(draftContent, markdownOptions);
  return markDown;
};
