import { WarWeb } from '@/war';
import React, { createContext, FC, useContext } from 'react';

export interface CountrySelection {
  level1: string;
  level2: string;
}

export interface TermsSelection {
  agreementIds: number[];
  countryIds: number[];
  vesselIds: number[];
  level1: string;
  level2: string;
  areaIds: number[];
  fleetId: number;
}
interface TermsConditionsPageProviderProps {
  fleetId?: number;
  regions?: WarWeb.SearchResult<WarWeb.SpecificTcGroup | WarWeb.BrokerTcGroup>;
  isNameColumnVisible?: boolean;
  isClaimsPenaltyBased?: boolean;
  editAction: () => void;
  setMainActionClicked: (mainActionClicked: boolean) => void;
}

const TermsConditionsPageContext = createContext<TermsConditionsPageProviderProps>({
  editAction: () => {},
  setMainActionClicked: () => {},
});
export const TermsConditionsPageProvider: FC<TermsConditionsPageProviderProps> = ({
  fleetId,
  regions,
  isNameColumnVisible,
  isClaimsPenaltyBased,
  editAction,
  setMainActionClicked,
  children,
}) => {

  return (
    <TermsConditionsPageContext.Provider
      value={{ fleetId, regions, isNameColumnVisible, isClaimsPenaltyBased, editAction, setMainActionClicked }}
    >
      {children}
    </TermsConditionsPageContext.Provider>
  );
};

export const useTermsConditionsPageContext = () => useContext(TermsConditionsPageContext);
