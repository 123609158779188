import useSWR from 'swr';
import { AxiosRequestConfig } from 'axios';
import { getJsonAsync } from '@/services/client';

/**
 * @deprecated Consider using regular SWR
 *
 * Meant as a utility extension for useSWR, originally made while SWR was in alpha.
 * Over time, there are now multiple official "useSWR<verb>" variants, and useSWREndpoint might've run its course.
 * However, we don't want to remove useSWREndpoint as of now as it's been OK for us in this project as a utility over useSWR.
 */
export const useSWREndpoint = <T>(endpoint: string | null, config?: AxiosRequestConfig, customFetcher?: () => Promise<any>) => {
  const fetcher = () => getJsonAsync(endpoint || '', config);
  const swr = useSWR<T>(endpoint, customFetcher || fetcher);

  return {
    data: swr.data,
    error: swr.error,
    isLoading: !!endpoint && !swr.error && !swr.data,
    mutate: swr.mutate
  };
};
