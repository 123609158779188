import { baseTheme } from '@instech/components';

/* Alpha percentage in hex for colors:
    75%: BF , 50%: 80 , 25%: 40, 15%: 26 */

// TODO: Clean up overriding properties after using base theme
export const theme = {
  ...baseTheme,
  sanMarino: '#3F6C97',
  sanMarino25: '#3F6C9740',
  blueGray: '#BFCEDB',
  veryLightGreen: '#b9e2d0', // non-alpha version of lightGreen50
  lightGreen80: '#98d7bb',
  lightGreen50: '#73C6A180',
  lightGreen25: '#73C6A140',
  lightGreen10: '#73C6A126',
  mediumGreen: '#3A8D68',
  mediumRed50: '#FDA995',
  mediumRed75: '#FDA995BF',
  mediumYellow50: '#FFD26C80',
  mediumYellow75: '#FFD26CBF',
  lightRed50: '#F5866C80',
  pink: '#FD92A3',
  mediumRed: '#F5866C',
  cyan: '#2E827E',
  border: {
    ...baseTheme.border,
    sanMarino: '#3F6C97',
    lightGreen: '#64C698',
    lightRed: '#FE8E9C',
    blue: '#46C0DA',
    yellow: '#FFCE74'
  },
  background: {
    primary: '#E5EBF0'
  },
  status: {
    ...baseTheme.status,
    lightRed: '#fce1da',
    darkRed: '#880d18',
    orange: '#f7a48f',
    solitude: '#f1f3f6'
  },
  portal: {
    maxWidth: '1800px',
    contentPadding: '0 2rem',
    contentPaddingSmallDesktop: '0 1rem',
    contentPaddingTablet: '0 .5rem'
  },
  header: {
    height: '100px',
    scrolledHeight: '48px',
    scrolledHeightValue: 48
  },
  breakpoints: {
    ...baseTheme.breakpoints,
    desktopSmall: '1400px'
  }
};

export type Theme = typeof theme;
