import { getFleetsAndVessels } from '@/services/fleetsService';
import { fleetVesselSearchResultToObject } from '@/utility/fleetVesselSearchResultType';
import { MinSearchLength } from '@/utility/globals';
import { WarWeb } from '@/war';
import { ActionMeta, SingleValue } from 'react-select';
import styled from 'styled-components';
import {
  CustomAsyncSelect, CustomDropdownIndicator, NoDropdownIndicator
} from './Form/fields/CustomAsyncSelect';

const SearchResult = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 50px;
  }
`;

const SearchSubContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FleetName = styled.div`
  text-transform: uppercase;
  color: ${props => props.theme.marineBlue50};
  letter-spacing: 0.07em;
  font-size: 11px;
`;

const Result = styled.div`
  font-size: 16px;
`;

export const renderFleetAndVesselSearchResult = (result: any) => (
  <SearchResult>
    {fleetVesselSearchResultToObject(result.type)?.icon}
    {result.type === 'Fleet' ?
      <Result>{result.name}</Result>
      : (
        <SearchSubContainer>
          <FleetName>
            {result.fleetName}
          </FleetName>
          <Result>{`${result.name} - ${result.imo}`}</Result>
        </SearchSubContainer>
      )}
  </SearchResult>
);

interface MarineObjectSearchProps {
  onChange: (newValue: SingleValue<WarWeb.MarineMatch>, action: ActionMeta<any>) => void;
  customStyles: any;
  withIcon?: boolean;
  isNonRateLead?: boolean;
}
export const MarineObjectSearch = ({ onChange, customStyles, withIcon, isNonRateLead }: MarineObjectSearchProps) => {
  // TODO: Fix return type
  const loadOptions: any = async (freetext: string) => {
    if (freetext.length < MinSearchLength) {
      return [];
    }

    let selectedRateLeadTypes: WarWeb.RateLeadType[] | undefined;
    if (isNonRateLead !== undefined) {
      selectedRateLeadTypes = isNonRateLead ? ['NonRateLead', 'Mixed'] : ['RateLead', 'Mixed'];
    }

    const params = {
      freetext,
      ...(isNonRateLead !== undefined && { selectedRateLeadTypes })
    };

    const { data } = await getFleetsAndVessels(params);
    return data?.data;
  };

  const styles = {
    container: (provided: any) => ({
      ...provided,
      ...customStyles
    })
  };

  return (
    <CustomAsyncSelect
      name="selectMarineObject"
      aria-label="Search"
      placeholder="Fleet name, Vessel name or IMO Number..."
      loadOptions={loadOptions}
      value={null}
      onChange={onChange}
      formatOptionLabel={renderFleetAndVesselSearchResult}
      tabIndex={0}
      autoFocus
      styles={styles}
      openMenuOnClick={false}
      noOptionsMessage={() => 'No results found'}
      components={withIcon ? CustomDropdownIndicator : NoDropdownIndicator}
    />
  );
};
