import { TableSection } from '@/components/shared/Table/core/Components';

export const tableSectionsInternal: TableSection[] = [
  {
    name: 'Vessel',
    columns: [
      {
        title: 'Name',
        format: 'text',
        propertyName: 'Name',
        parameterName: 'SelectedNames'
      },
      {
        title: 'Type',
        format: 'label',
        propertyName: 'VesselTypeName',
        parameterName: 'SelectedVesselTypeNames'
      },
      {
        title: 'Year',
        propertyName: 'Built',
        parameterName: 'SelectedBuilt'
      },
    ]
  },
  {
    name: 'Sums Insured',
    tag: 'interests',
    columns: [
      { title: 'Period',
        align: 'left',
        format: 'label' },
      { title: 'H&M',
        align: 'left',
        format: 'wide-int' },
      { title: 'TLO',
        align: 'left',
        format: 'wide-int' },
      { title: 'LOH',
        align: 'left',
        format: 'wide-int' },
      { title: 'K&R',
        align: 'left',
        format: 'wide-int' }
    ]
  },
  {
    columns: [
      { title:
        'Fleet Specific T&C' },
      {
        title: 'New',
        align: 'center',
        propertyName: 'IsNew',
        type: 'Boolean',
        parameterName: 'SelectedIsNew',
        dropdownAlign: 'right'
      }
    ]
  },
  {
    name: '',
    columns: [
      { key: 1,
        format: 'int' }
    ]
  }
];

export const tableSections: TableSection[] = [
  {
    name: 'Vessel',
    columns: [
      {
        title: 'Name',
        propertyName: 'Name',
        format: 'text',
        parameterName: 'SelectedNames'
      },
      {
        title: 'Type',
        format: 'label',
        propertyName: 'VesselTypeName',
        parameterName: 'SelectedVesselTypeNames'
      },
      {
        title: 'Year',
        propertyName: 'Built',
        noDropdown: true
      },
    ]
  },
  {
    name: 'Sums Insured',
    tag: 'interests',
    columns: [
      { title: 'Period',
        align: 'left',
        format: 'wide-int' },
      { title: 'H&M',
        align: 'left',
        format: 'wide-int' },
      { title: 'TLO',
        align: 'left',
        format: 'wide-int' },
      { title: 'LOH',
        align: 'left',
        format: 'wide-int' },
      { title: 'K&R',
        align: 'left',
        format: 'wide-int' }
    ]
  },
  {
    name: '',
    columns: [
      { key: 1 }
    ]
  }
];
