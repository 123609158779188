import { ModalLayout } from '@/components/modal/ModalLayout';
import { Button } from '@/components/shared/Button';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { TextField } from '@/components/shared/Form/FormFields';
import { theme } from '@/utility/theme';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useAgreementDetailsContext } from './AgreementDetailsContext';
import { ErrorMessage } from '@/components/shared/Form/core/Components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 1rem 1rem;
`;

const Wide = styled.div`
  width: 100%;
`;

const Column = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${(props) =>
    props.gap &&
    css`
      row-gap: 1rem;
    `};
`;

const StyledError = styled(ErrorMessage)`
  justify-content: end;
`;

interface ModalProps {
  closeModal: () => void;
}

export const EditAgreementNameModal = ({ closeModal }: ModalProps) => {
  const { agreementName, setAgreementName, agreementErrorMessage, setAgreementErrorMessage } =
    useAgreementDetailsContext();
  const [localAgreementName, setLocalAgreementName] = useState(agreementName);

  const handleNameInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLocalAgreementName(e.target.value);
    setAgreementName(e.target.value);
    if (e.target.value.length > 50) setAgreementErrorMessage('Trade Agreement Name must be less than 50 characters.');
    else setAgreementErrorMessage('');
  };

  const saveChanges = () => {
    if (localAgreementName.length <= 50) {
      setAgreementName(localAgreementName);
      closeModal();
    }
  };

  const discard = () => {
    setAgreementName(localAgreementName);
    setAgreementErrorMessage('');
    closeModal();
  };

  const modalOptions = {
    title: 'Edit Trade Agreement Name',
    size: 'medium',
  };

  return (
    <ModalLayout closeModal={closeModal} options={modalOptions}>
      <Container>
        <Column gap="2rem">
          <Wide>
            <TextField
              name="name"
              label="Trade Agreement Name"
              value={localAgreementName}
              onChange={handleNameInputChange}
              maxLength={50}
              placeholder="Give the trade agreement a custom name."
            />
          </Wide>
          {agreementErrorMessage && <StyledError>{agreementErrorMessage}</StyledError>}

          <ButtonGroup justify="flex-end">
            <Button onClick={discard} background={theme.ultraLightGray}>
              CANCEL
            </Button>
            <Button onClick={saveChanges} disabled={!!agreementErrorMessage} background={theme.green}>
              OK
            </Button>
          </ButtonGroup>
        </Column>
      </Container>
    </ModalLayout>
  );
};
