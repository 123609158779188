import { isBroker } from '@/components/routing/roles';
import { useSWREndpoint } from '@/hooks/useSWREndpoint';
import { WarWeb } from '@/war';
import { AcceptHeaders } from './config';
import { postFetcher } from './utility/simpleFetch';

const basepath = 'terms';

export const getBasePathBrokerTerms = (fleetId:any) => `${basepath}/broker/fleets/${fleetId}`;

export const basePathSpecificTerms = `${basepath}/specific`;

export const basePathDefaultTermsConditions = `${basepath}/default`;

export const useDefaultTermsConditions = (params?: WarWeb.DefaultTcSearchParameters, key?: string) =>
  useSWREndpoint<WarWeb.SearchResult<WarWeb.DefaultTcMatch>>(
    params ? `${basePathDefaultTermsConditions}?key=${key}` : null,
    undefined,
    () => postFetcher<WarWeb.SearchResult<WarWeb.DefaultTcMatch>>(`${basePathDefaultTermsConditions}`, params, AcceptHeaders.SearchResult)
  );

export const useDefaultTermsConditionsRegions = (params?: WarWeb.DefaultTcSearchParameters, key?: string) =>
  useSWREndpoint<WarWeb.SearchResult<WarWeb.DefaultTcGroup>>(
    params ? `${basePathDefaultTermsConditions}/groups?key=${key}` : null,
    undefined,
    () => postFetcher<WarWeb.SearchResult<WarWeb.DefaultTcGroup>>(`${basePathDefaultTermsConditions}/groups`, params, AcceptHeaders.SearchResult)
  );

export const useTermsConditions = (params?: WarWeb.SpecificTcSearchParameters | WarWeb.BrokerTcSearchParameters, key?: string) => {
  let uri: string | null = null;
  let restParams = params;
  if (isBroker()) {
    if (params) {
      const { fleetId, ...rest } = params;
      restParams = rest;
      uri = fleetId ? getBasePathBrokerTerms(fleetId) : null;
    }
  } else {
    uri = basePathSpecificTerms;
  }
  return useSWREndpoint<WarWeb.SearchResult<WarWeb.SpecificTcMatch>>(
    restParams ? `${uri}?key=${key}` : null,
    undefined,
    () => postFetcher<WarWeb.SearchResult<WarWeb.SpecificTcMatch>>(uri, restParams, AcceptHeaders.SearchResult)
  );
  // return useSWREndpoint<WarWeb.SearchResult<WarWeb.SpecificTcMatch | WarWeb.BrokerTcMatch>>(uri, AcceptHeaders.SearchResult);
};

export const useTermsConditionsRegions = (params?: WarWeb.SpecificTcSearchParameters | WarWeb.BrokerTcSearchParameters, key?: string) => {
  let uri: string | null;
  let restParams = params;
  if (isBroker()) {
    const { fleetId, ...rest } = params as WarWeb.BrokerTcSearchParameters;
    restParams = rest;
    uri = fleetId ? `${getBasePathBrokerTerms(fleetId)}/groups` : null;
  } else {
    uri = `${basePathSpecificTerms}/groups`;
  }
  return useSWREndpoint<WarWeb.SearchResult<WarWeb.BrokerTcGroup | WarWeb.SpecificTcGroup>>(
    restParams ? `${uri}?key=${key}` : null,
    undefined,
    () => postFetcher<WarWeb.SearchResult<WarWeb.BrokerTcGroup | WarWeb.SpecificTcGroup>>(uri, restParams, AcceptHeaders.SearchResult)
  );
};

const indexerStatusUri = `${basepath}/indexer`;
export const useTermsIndexerStatus = () => useSWREndpoint<WarWeb.IndexingInformation>(indexerStatusUri);
