// related to discussion on https://github.com/microsoft/TypeScript/pull/12253#issuecomment-353494273
export function keysOf<TObj extends object>(obj: TObj): Array<keyof TObj> {
  return Object.keys(obj) as Array<keyof TObj>;
}

// https://stackoverflow.com/questions/41980195/recursive-partialt-in-typescript
export type RecursivePartial<T> = {
  [P in keyof T]?:
  T[P] extends (infer U)[] ? RecursivePartial<U>[] :
  T[P] extends (object | undefined) ? RecursivePartial<T[P]> :
  T[P];
};

export const qsParamToIntArray = (param: any) => Array.isArray(param) ? (param).map(x => parseInt(x)) : [parseInt(param)];
export const qsParamToStringArray = (param: any) => (Array.isArray(param) ? (param).map(x => x) : [param]) as string[];

export function isEmpty(obj?: Object) {
  if (obj == null) return false;
  return obj // 👈 null and undefined check
    && Object.keys(obj).length === 0
    && Object.getPrototypeOf(obj) === Object.prototype;
}

export function pick(obj?: Record<string, any>, values?: string[]) {
  if (!obj || !values) return {};
  return values.reduce((a: Record<string, any>, b) => {
    if (Object.keys(obj).includes(b)) {
      a[b] = obj[b];
    }
    return a;
  }, {});
}

export const remove = (keys?: string[], obj?: Record<string, any>) => {
  if (!obj) return [];
  if (!keys || keys.length === 0) return obj;

  const remainingKeys = Object.keys(obj).filter(k => !keys.includes(k));
  const remaining = pick(obj, remainingKeys);
  return remaining;
};

// https://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-and-arrays-by-string-path
export const resolvePath = (object?: Record<string, any>, path?: string, defaultValue?: string) =>
  path?.split(/[.[\]'"]/)
    .filter(p => p)
    .reduce((o, p) => o ? o[p] : defaultValue, object);

export const toLowerCase = (obj?: Record<string, any>) => obj?.map((k: string) => k.toLowerCase());

export const toLowerKeys = (obj?: Record<string, any>) => {
  if (!obj) return undefined;
  return Object.keys(obj).reduce((accumulator: Record<string, any>, key) => {
    accumulator[key.toLowerCase()] = obj[key];
    return accumulator;
  }, {});
};

export const only = (obj?: Record<string, any>, keys?: string[]) => {
  if (!obj || !keys?.some(x => x)) return false;
  const remaining = remove(keys, obj);
  return isEmpty(remaining);
};
