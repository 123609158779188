import React, {
  FC, useEffect, useState
} from 'react';
import { useValidationContext } from '@/services/ValidationContext';
import { concatNullsafe } from '@/utility/arrayHelpers';
import { addOrUpdateArray } from '@/utility/stateArrayHelpers';
import { formatDecimalsInText } from '@/utility/formatter';
import { ValidationMessages } from '@/models/ValidationMessages';
import styled from 'styled-components';
import { DangerNotification, WarningNotification } from '@instech/components';
import { keyToLabel } from '@/utility/errorHelpers';
import { size as sizeType } from '@instech/components/dist/shared/components/Notifications/types';
import { remove } from '@/utility/objectHelpers';
import { useHateoasExecutorContext } from '@/services/HateoasExecutorContext';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const NotificationWrapper = styled.div<{ fleetSpecificPage?: boolean }>`
  margin-bottom: 1em;
  margin-top: ${props => props.fleetSpecificPage ? '2rem' : '0'};
`;

export type Level = 'warnings' | 'errors' | 'all';

interface RemainingValidationsProps {
  rootElement?: any;
  reset?: boolean;
  level?: Level;
  size?: sizeType;
  showAll?: boolean;
  fleetSpecificPage?: boolean;
}
export const RemainingValidations: FC<RemainingValidationsProps> = ({ rootElement, reset = false, level = 'all', size = 'large', showAll = false, fleetSpecificPage }) => {
  const { errors, seenErrors, setSeenErrors, dismissedErrors, setDismissedErrors } = useValidationContext();
  const { loading } = useHateoasExecutorContext();
  const [remainingErrors, setRemainingErrors] = useState<ValidationMessages>();

  const { warnings, seenWarnings, setSeenWarnings, dismissedWarnings, setDismissedWarnings } = useValidationContext();
  const [remainingWarnings, setRemainingWarnings] = useState<ValidationMessages>();

  const [show, setShow] = useState<boolean>(false);
  const [canceled, setCanceled] = useState<boolean>(false);

  useEffect(() => {
    if (reset) {
      setSeenErrors([]);
      setDismissedErrors([]);
      setSeenWarnings([]);
      setDismissedWarnings([]);
    }
  }, []);

  useEffect(() => {
    setShow(false);
    setCanceled(false);

    const unseenErrors = showAll ? errors || [] : remove(concatNullsafe(seenErrors, dismissedErrors), errors);
    setRemainingErrors(unseenErrors);

    const unseenWarnings = showAll ? warnings || [] : remove(concatNullsafe(seenWarnings, dismissedWarnings), warnings);
    const warningKeysLowerCase = Object.keys(unseenWarnings)?.map(k => ({
      key: k,
      match: k.toLowerCase()
    }));

    const overlappedErrors: string[] = [];

    Object.keys(unseenErrors)?.forEach(errorKey => {
      const check = warningKeysLowerCase.find(w => w.match === errorKey.toLowerCase());
      if (check != null) {
        if (unseenErrors[errorKey].join(', ') === unseenWarnings[check.key].join(', ')) {
          overlappedErrors.push(check.key);
        }
      }
    });

    setRemainingWarnings(remove(overlappedErrors, unseenWarnings));

    // have a small delay to allow UX to render, before showing "uncaught" validations at the top
    setTimeout(() => setShow(true), 1000);

    return () => {
      setCanceled(true);
    };
  }, [warnings, seenWarnings, dismissedWarnings, errors, seenErrors, dismissedErrors]);

  const dismissError = (key: string) => {
    addOrUpdateArray(dismissedErrors, setDismissedErrors, (a: string) => a === key, key);
  };

  const dismissWarning = (key: string) => {
    addOrUpdateArray(dismissedWarnings, setDismissedWarnings, (a: string) => a === key, key);
  };

  if (!show || canceled || loading) return null;

  return (
    <Container>
      {remainingErrors && (level === 'errors' || level === 'all') && (
        <>
          {Object.keys(remainingErrors).map(key => (
            <NotificationWrapper key={key} fleetSpecificPage >
              <DangerNotification
                size={size}
                headingText={rootElement ? keyToLabel(rootElement, key, 0) : key}
                descriptionText={remainingErrors[key].map((m: string) => formatDecimalsInText(m)).join(', ')}
                onClose={() => dismissError(key)} />
            </NotificationWrapper>
          ))}
        </>
      )}

      {remainingWarnings && (level === 'warnings' || level === 'all') && (
        <>
          {Object.keys(remainingWarnings).map(key => (
            <NotificationWrapper key={key}>
              <WarningNotification
                size={size}
                headingText={rootElement ? keyToLabel(rootElement, key, 0, true) : key}
                descriptionText={remainingWarnings[key].map((m: string) => formatDecimalsInText(m)).join(', ')}
                onClose={() => dismissWarning(key)} />
            </NotificationWrapper>
          ))}
        </>
      )}
    </Container>
  );
};
