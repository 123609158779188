export const MediaType = {
  Excel: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  SearchResult: 'application/insify.war.searchresult+json',
  Pdf: 'application/pdf',
};

export const AcceptHeaders = {
  SearchResult: {
    headers: {
      Accept: MediaType.SearchResult,
    },
  },
  Excel: {
    headers: {
      Accept: MediaType.Excel,
    },
  },
  Pdf: {
    headers: {
      Accept: MediaType.Pdf,
    },
  },
};

export const convertTargetsToKey = (path?: string, targets?: Object) => `${path}?key=${JSON.stringify(targets)}`;
