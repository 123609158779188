import { ChildrenProps } from '@/models/utils/ChildrenProps';
import React, {
  createContext,
  useContext, useMemo, useState
} from 'react';
import { WarWeb } from '@/war';

interface IAgreementDataContextBroker {
  data: WarWeb.BrokerAgreementSummary;
  setData: React.Dispatch<React.SetStateAction<WarWeb.BrokerAgreementSummary>>;
}

const AgreementDataContextBroker = createContext<IAgreementDataContextBroker | undefined>(undefined);

const AgreementDataProviderBroker = ({ children }: ChildrenProps) => {
  const [data, setData] = useState<WarWeb.BrokerAgreementSummary>({
    coverage: [],
    defaultRates: [],
    knrRebates: [],
    rebates: [],
    subjectivities: [],
    warranties: [],
    targets: {
      agreementIds: [],
      areas: [],
      totalTargetCount: 0,
      vessels: [],
      vesselTypes: []
    }
  });

  const value = useMemo(
    () => ({ data, setData }),
    [data]
  );

  return (
    <AgreementDataContextBroker.Provider value={value}>
      {children}
    </AgreementDataContextBroker.Provider>
  );
};

const useAgreementDataContextBroker = () => {
  const context = useContext(AgreementDataContextBroker);

  if (context === undefined) {
    throw new Error('useAgreementDataContext must be used within a AgreementDataProvider');
  }
  return context;
};

export { AgreementDataProviderBroker, useAgreementDataContextBroker };
