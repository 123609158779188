import { isInternalUser } from '@/components/routing/roles';
import { TableSection } from '@/components/shared/Table/core/Components';

export const tableSections : TableSection[] =
[
  {
    columns: [
      { title: 'Fleet',
        format: 'text',
        propertyName: 'Name',
        parameterName: 'SelectedNames' },
      { title: 'Client',
        format: 'text',
        propertyName: 'ClientName',
        parameterName: 'SelectedClientNames' },
      { title: 'Tier',
        format: 'char',
        propertyName: 'Tier',
        parameterName: 'SelectedTiers' },
      { title: 'Vessels',
        propertyName: 'VesselCount',
        format: 'int',
        type: 'Number',
        parameterName: 'VesselCount' },
      { title: 'New',
        format: 'label',
        align: 'center',
        propertyName: 'NewVesselCount',
        type: 'Number',
        parameterName: 'NewVesselCount',
        dropdownAlign: 'right' },
      { title: '', key: 1 }
    ]
  }
];

export const tableSectionsInternalUser = () => {
  if (isInternalUser({ onlyEditors: true })) return tableSections;

  const nonEditorSkips = ['Tier', 'NewVesselCount'];
  return tableSections.map(s => ({
    ...s,
    columns: s.columns.filter(h => !nonEditorSkips.includes(h.propertyName || ''))
  }));
};

export const tableSectionsExternalUser = () => {
  const externalSkips = ['ClientName', 'Tier', 'VesselCount', 'NewVesselCount'];

  return tableSections.map(s => ({
    ...s,
    columns: s.columns.filter(h => !externalSkips.includes(h.propertyName || ''))
  }));
};
