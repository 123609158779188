import {
  createContext, PropsWithChildren, useContext, useMemo
} from 'react';

interface ContextData {
  open: (component: unknown) => void;
  close: () => void;
}

const ModalContext = createContext<ContextData>(null as unknown as ContextData);

export const ModalProvider = ({ children, open, close }: PropsWithChildren<ContextData>) => {
  const value = useMemo(() => ({ open, close }), [open, close]);
  return (
    <ModalContext.Provider value={value}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => useContext(ModalContext);
