import { FieldLabel } from '@/components/shared/Form/core/Components';
import { Table } from '@/components/shared/Table/Table';
import { useEditExcludedAreasContext } from '../EditExcludedAreasContext/EditExcludedAreasContext';
import { DeletableTableRow } from '../DeletableTableRow';

export const EntryExitPointsEditor = () => {
  const {
    state: { areaId, entryAndExitPoints },
    deleteEntryExitPoint, undoDeleteEntryExitPoint
  } = useEditExcludedAreasContext();

  const entriesExits = entryAndExitPoints.filter(p => p.oceanAreaId === areaId);

  return (
    <div>
      <FieldLabel>Entry / Exit Points</FieldLabel>
      {entriesExits?.length > 0 && (
        <Table layout="1fr auto" striped>
          {entriesExits.map(point => (
            <DeletableTableRow
              key={point.id}
              item={{ ...point, description: point.timezoneId }}
              removeItem={deleteEntryExitPoint}
              undoRemoveItem={undoDeleteEntryExitPoint}
            />
          ))}
        </Table>
      )}
    </div>
  );
};
