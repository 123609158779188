import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { theme } from '@/utility/theme';
import { TextFieldDeprecated, TextFieldDeprecatedProps } from './TextFieldDeprecated';

const ActionContainer = styled.span`
  display: flex;
  align-items: flex-start;
`;

const Container = styled.div<{ actionVisible: boolean }>`
  ${props => props.actionVisible && css`
    margin-left: -10px;
  `};
`;

const FieldAction = styled.span`
  position: relative;
  left: 7px;
  top: 8px;
  color: ${theme.marineBlue};
  cursor: pointer;

  svg {
    width: 10px;
  }
`;

export interface TextFieldWithActionProps extends TextFieldDeprecatedProps {
  autoHide?: boolean;
  onFocus?: (e: React.FocusEvent<HTMLInputElement & HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement & HTMLTextAreaElement>) => void;
  action?: {
    icon: React.ReactNode,
    onClick: () => void
  };
}

export const TextFieldWithAction: FC<TextFieldWithActionProps> = ({
  autoHide = true,
  action,
  onFocus,
  onBlur,
  ...props
}) => {
  const [hasFocus, setHasFocus] = useState(false);

  const handleFocus = (e: React.FocusEvent<HTMLInputElement & HTMLTextAreaElement>) => {
    setHasFocus(true);
    if (onFocus) {
      onFocus(e);
    }
  };
  const handleBlur = (e: React.FocusEvent<HTMLInputElement & HTMLTextAreaElement>) => {
    if (autoHide) setTimeout(() => setHasFocus(false), 150);
    if (onBlur) {
      onBlur(e);
    }
  };

  const actionVisible = !!action && (!autoHide || hasFocus);

  return (
    <ActionContainer>
      {action && actionVisible && (
        <FieldAction onClick={action.onClick}>{action.icon}</FieldAction>
      )}
      <Container actionVisible={actionVisible}>
        <TextFieldDeprecated onFocus={handleFocus} onBlur={handleBlur} {...props} />
      </Container>
    </ActionContainer>
  );
};
