import { Button } from '@/components/shared/Button';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { deleteImpersonateBusinessOperations, impersonateBusinessOperations } from '@/services/businessOperationsImpersonationService';
import { useMe } from '@/services/meService';
import { theme } from '@/utility/theme';

export const BusinessOperationsImpersonator = () => {
  const { data: me } = useMe();

  const impersonateHandler = async () => {
    await impersonateBusinessOperations();
    window.location.reload();
  };

  const revertRoleHandler = async () => {
    await deleteImpersonateBusinessOperations();
    window.location.reload();
  };

  const validImpersonationRoles = me?.role === 'Admin' || me?.role === 'Underwriter';

  return (
    <ButtonGroup>
      <Button onClick={impersonateHandler} disabled={!validImpersonationRoles || me.impersonated} background={theme.lightGreen}>Impersonate business operations</Button>
      <Button onClick={revertRoleHandler} disabled={!me?.impersonated} background={theme.mediumRed}>Revert role</Button>
    </ButtonGroup>
  );
};
