import React from 'react';
import {
  World2, Image2, Anchor, Business, BoatFront, Route
} from '@instech/icons';
import styled from 'styled-components';
import { theme } from './theme';

const GreenAnchor = styled(Anchor)`
  path {
    fill: ${theme.green};
  }
`;

interface AreaType {
  level: string;
  icon?: any;
}
export const areaType: AreaType[] = [
  {
    level: 'region',
    icon: <World2 />
  },
  {
    level: 'area',
    icon: <Image2 />
  },
  {
    level: 'port',
    icon: <Anchor />
  },
  {
    level: 'TimeException',
    icon: <GreenAnchor />
  },
  {
    level: 'ocean',
    icon: <Image2 />
  },
  {
    level: 'trade',
    icon: <Business />
  },
  {
    level: 'voyage',
    icon: <BoatFront />
  },
  {
    level: 'areaAlias',
    icon: <Route />
  }
];

export const areaTypeToObject = (level: string) =>
  areaType.find(x => x.level.toLowerCase() === level.toLowerCase());
