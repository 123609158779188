import {
  createContext, PropsWithChildren, useContext, useMemo
} from 'react';

export interface CountrySelection {
  level1: string;
  level2: string;
}

interface ConditionsProviderProps {
  editAction: () => void;
  setMainActionClicked: (mainActionClicked: boolean) => void;
}

const TermsConditionsPageContext = createContext<ConditionsProviderProps>({
  editAction: () => { },
  setMainActionClicked: () => { }
});

export const TermsConditionsPageProvider = ({
  editAction,
  setMainActionClicked,
  children
}: PropsWithChildren<ConditionsProviderProps>) => {
  const values = useMemo(() => ({
    editAction,
    setMainActionClicked
  }), [editAction, setMainActionClicked]);

  return (
    <TermsConditionsPageContext.Provider value={values}>
      {children}
    </TermsConditionsPageContext.Provider>
  );
};

export const useTermsConditionsPageContext = () => useContext(TermsConditionsPageContext);
