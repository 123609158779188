import { isAdmin, isUnderwriter } from '@/components/routing/roles';
import { Routes } from '@/components/routing/routes';
import { Checkbox } from '@/components/shared/Form/fields/Checkbox';
import { TableRow } from '@/components/shared/Table/core/Components';
import { TableCell } from '@/components/shared/Table/Table';
import { TargetType } from '@/models/TargetType';
import { useDefaultTermsConditions } from '@/services/termsService';
import { equals } from '@/utility/arrayHelpers';
import { WarWeb } from '@/war';
import {
  Dispatch, SetStateAction, useState
} from 'react';
import { useHistory } from 'react-router';
import { Globals } from '@/utility/globals';
import { IconMultiAgreement } from '@/components/shared/Icons/IconMultiAgreement';
import styled from 'styled-components';
import { clearActiveTabName } from '@/utility/sessionStore';
import { addOrRemoveArray } from '../../../utility/stateArrayHelpers';
import { useAgreementsPageContext } from '../agreements/AgreementsPageContext';
import { AgreementEntry, useAgreementsOverviewContext } from '../agreementsOverview/AgreementsOverviewContext';
import { useFilterableTableHeadersContext } from './FilterableTableHeadersContext';
import { renderSharedDefaultTermsTableCells } from './SharedTableCells';
import { useTermsConditionsPageContext } from './TermsConditionsPageContext';
import { OverflowItemsRow } from './OverflowItemsRow';

const YachtIconContainer = styled.div`
  margin: 10px;
  padding-left: 4px;
`;
interface TermsConditionsRowProps {
  terms: WarWeb.DefaultTcMatch;
  focusedId?: number;
  setFocusedId: Dispatch<SetStateAction<number | undefined>>;
}

const TermsConditionsRow = ({ terms, focusedId, setFocusedId }: TermsConditionsRowProps) => {
  const { agreementEntries, setAgreementEntries } = useAgreementsOverviewContext();
  const { setAgreementSummaryTarget } = useAgreementsPageContext();
  const { setMainActionClicked } = useTermsConditionsPageContext();
  const history = useHistory();

  const existsInSelection = !!agreementEntries.find(s => equals(s.areaIds, terms.coveredAreaIds) && equals(s.vesselTypeIds, terms.vesselTypeIds));

  const currentEntry: AgreementEntry = {
    agreementId: terms.agreementId,
    agreementIds: terms.agreementIds,
    areaIds: terms.coveredAreaIds,
    vesselTypeIds: terms.vesselTypeIds,
    level1: terms.level1,
    level2: terms.level2,
    type: terms.coveredAreaType,
    daysCovered: terms.breachDays
  };

  const handleCheckboxChange = () => {
    addOrRemoveArray(
      agreementEntries,
      setAgreementEntries,
      s => equals(s.areaIds, terms.coveredAreaIds) && equals(s.vesselTypeIds, terms.vesselTypeIds),
      currentEntry
    );
  };

  const handleClick = () => {
    const targetType = currentEntry.vesselTypeIds?.some(x => x) ? TargetType.VesselTypeSpecific : TargetType.Standard;
    setAgreementSummaryTarget({
      areaIds: currentEntry.areaIds,
      vesselTypeIds: currentEntry.vesselTypeIds,
      agreementIds: currentEntry.agreementIds,
      targetType,
      daysCoveredGroup: currentEntry.daysCovered,
      hasMultipleSets: terms.multipleSets
    });
    setAgreementEntries([currentEntry]);
    setMainActionClicked(true);
    clearActiveTabName();
    setTimeout(() => {
      history.push(Routes.termsDefaultDetails);
    }, 500);
  };

  return (
    <TableRow onClick={handleClick} hasFocus={focusedId === terms.agreementId} onHover={() => setFocusedId(undefined)} hover>
      <TableCell slim
        onClick={isUnderwriter() ? undefined : e => e.stopPropagation()}
        tabIndex={isUnderwriter() ? 0 : undefined}
        onKeyDown={e => e.key === 'Enter' ? handleClick() : undefined}
        onFocus={() => setFocusedId(terms.agreementId)}
        onBlur={() => setFocusedId(undefined)}>
        {(isUnderwriter() && terms.multipleSets) && (
          <YachtIconContainer>
            <IconMultiAgreement />
          </YachtIconContainer>
        )}
        {isAdmin() && (
          terms.multipleSets ? (
            <YachtIconContainer>
              <IconMultiAgreement />
            </YachtIconContainer>
          ) :
            (
              <Checkbox
                name={`${terms.agreementId}`}
                checked={existsInSelection}
                onChange={handleCheckboxChange}
              />
            )

        )}
      </TableCell>
      {isAdmin() && <TableCell />}
      <TableCell>
        {terms.level3}
      </TableCell>
      {renderSharedDefaultTermsTableCells(terms)}
    </TableRow>
  );
};
interface TermsConditionsProps {
  level1: string;
  level2: string;
}
export const TermsConditions = ({ level1, level2 }: TermsConditionsProps) => {
  const { filters: baseParams } = useFilterableTableHeadersContext();
  const orderBy: any = baseParams?.orderBy ? baseParams.orderBy : 'Level3, Tier';
  const [focusedId, setFocusedId] = useState<number>();

  const params = { ...baseParams, orderBy, selectedLevel1: [level1], selectedLevel2: [level2], pagesize: Globals.AgreementsOverviewGroupSize };

  const { data } = useDefaultTermsConditions(params, JSON.stringify(params));

  return (
    <>
      {data?.items.map((terms: WarWeb.DefaultTcMatch) => (
        <TermsConditionsRow
          key={terms.agreementId}
          focusedId={focusedId}
          setFocusedId={setFocusedId}
          terms={terms}
        />
      ))}
      <OverflowItemsRow paging={data?.pagingMetadata} />
    </>
  );
};
