import { bowStatus, Status } from '@/utility/bowStatus';
import { VoyageContractTypes } from '@/utility/contractTypes';
import { pluralize } from '@/utility/dateCalc';
import { theme } from '@/utility/theme';
import { TimeConverter } from '@/utility/timeConverter';
import moment from 'moment';
import { Refresh } from '@instech/icons';
import { HateoasExecutorType } from '@/services/HateoasExecutorContext';
import { QuoteActions } from '@/components/pages/bows/details/quoteActionsMap';
import { WarWeb } from '@/war';
import { useSelectedQuote } from '../Bow/useSelectedQuote';
import { Label, SidebarSubHeader } from './Components';
import { SidebarItem } from './SidebarItem';

const ContractType = ({ coverage }: { coverage: WarWeb.CoverageDetails }) => {
  const { contractType, contractName } = coverage;
  const contractTypeDisplay =
    contractType === 'MinimumProRata' && coverage.maximumProrataDays === 0
      ? 'Minimum'
      : contractType && VoyageContractTypes[contractType];

  return (
    <SidebarItem>
      <strong>Contract type</strong>
      {contractName ? `${contractTypeDisplay} - ${contractName}` : contractTypeDisplay}
    </SidebarItem>
  );
};

interface SidebarCoverageItemProps {
  hateoasExecutor?: HateoasExecutorType;
}
export const SidebarCoverageItem = ({ hateoasExecutor }: SidebarCoverageItemProps) => {
  const selectedQuote = useSelectedQuote();
  if (!selectedQuote) return null;

  const { coverageDetails: coverage, status, expiration } = selectedQuote;

  const expiryDateTime = moment(expiration);
  const isQuotedStatus = bowStatus.find(n => n.title === status)?.status === Status.Quoted;
  const isExpired = isQuotedStatus && expiryDateTime.isBefore(moment());

  const toolbar = [
    {
      icon: <Refresh />,
      action: QuoteActions.RevalidateQuote,
      onClick: () => hateoasExecutor && hateoasExecutor(selectedQuote?.links, QuoteActions.RevalidateQuote, {}),
    },
  ];

  const { links } = selectedQuote;

  return (
    <>
      <SidebarSubHeader>
        <Label>Coverage</Label>
      </SidebarSubHeader>

      {coverage.contractType && <ContractType coverage={coverage} />}

      {!!coverage.daysCovered && (
        <SidebarItem>
          <strong>Days covered</strong>
          {`Minimum ${coverage.daysCovered} days`}
        </SidebarItem>
      )}

      {coverage.hoursValid != null && (
        <SidebarItem toolbar={toolbar} links={links} background={isExpired ? theme.status.orange : ''}>
          <strong>Quote validity</strong>
          {`${coverage.hoursValid} ${pluralize('hour', coverage.hoursValid)}`}
          {expiration && (
            <>
              <br />
              {`Expires ${TimeConverter.ToDateTime(expiryDateTime.toISOString())}`}
            </>
          )}
        </SidebarItem>
      )}

      {!!coverage.maximumProrataDays && (
        <SidebarItem>
          <strong>Maximum pro rata days</strong>
          {`${coverage.maximumProrataDays} days`}
        </SidebarItem>
      )}
    </>
  );
};
