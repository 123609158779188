import { ChildrenProps } from '@/models/utils/ChildrenProps';
import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FlexBox } from '../shared/FlexBox';

const StyledFlexBox = styled(FlexBox)`
  height: 4rem;
  margin: 1rem 0;
`;

const StyledHeader = styled.h1`
  font-size: 28px;
  font-weight: bold;
  white-space: nowrap;
  margin: 0;
`;

const Header = ({ children }: ChildrenProps) => (
  <StyledHeader>
    {children}
  </StyledHeader>
);

const BreadcrumbText = styled.h2`
  color: ${props => props.theme.marineBlue50};
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

interface Breadcrumb {
  label: string;
  to?: string;
  onClick?: () => void;
}

interface BreadcrumbWrapperProps {
  breadcrumb?: Breadcrumb
}
const BreadcrumbWrapper = ({ children, breadcrumb }: PropsWithChildren<BreadcrumbWrapperProps>) =>
  breadcrumb?.to ? <Link to={breadcrumb?.to}>{children}</Link> : <>{children}</>;

interface PageHeaderProps {
  header: React.ReactNode,
  breadcrumb?: Breadcrumb
}
export const PageHeader = ({ header, breadcrumb }: PageHeaderProps) => (
  <StyledFlexBox justifyContent="center" flexDirection="column">
    {!!breadcrumb && (
    <BreadcrumbWrapper breadcrumb={breadcrumb}>
      <BreadcrumbText onClick={breadcrumb?.onClick}>
        {'< '}
        {breadcrumb.label}
      </BreadcrumbText>
    </BreadcrumbWrapper>
    )}
    <Header>{header}</Header>
  </StyledFlexBox>
);
