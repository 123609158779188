import moment from 'moment';

const dateTimeFormatter = (date, format = 'L HH:mm:ss', showGTMOffset) => {
  if (date?.indexOf('Z') > 0) {
    const d = moment(date);
    const offset = d.parseZone().utcOffset() / 60;
    const adjustedFormat = d.parseZone().month() === 4 ? format.replace('MMM.', 'MMM') : format;
    return `${d.local().format(adjustedFormat)} ${showGTMOffset ? ` GMT +${offset}` : ''}`;
  }

  // no time part:
  if (format.indexOf(':') < 0) return moment(date).format(format);

  return moment(date).format(format);
};

const ToDate = timestring => dateTimeFormatter(timestring, 'D MMM. YYYY');

const ToDateTime = (timestring, showGTMOffset = false) => dateTimeFormatter(timestring, 'D MMM. YYYY [at] HH:mm', showGTMOffset);

const ToNaiveDateTime = date => moment(date).parseZone().format('YYYY-MM-DDTHH:mm:ss');

const Trunc = timestring => timestring.substring(0, timestring.indexOf('.'));

const adjustForTimezone = date => {
  const timeOffsetInMS = date.getTimezoneOffset() * 60000;
  date.setTime(date.getTime() + timeOffsetInMS);
  return date;
};

export const TimeConverter = {
  dateTimeFormatter,
  ToDate,
  ToDateTime,
  ToNaiveDateTime,
  Trunc,
  adjustForTimezone
};
