import { BowsBox } from '@/components/shared/Bow/core/BowsBox';
import { BoxContent } from '@/components/shared/Box';
import { FlexBox } from '@/components/shared/FlexBox';
import { Tab } from '@/components/shared/Tab';
import {
  getHeaders, SpanCell, TableRow
} from '@/components/shared/Table/core/Components';
import { usePageSettingsContext } from '@/components/userSettings/PageSettingsContext';
import { Filters } from '@/models/Filters';
import { useBowCounts } from '@/services/bowServices';
import { useShowClients } from '@/services/meService';
import { sum } from '@/utility/arrayHelpers';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { theme } from '@/utility/theme';
import { clientColumnKey } from '../bows/core/headers';
import { baseFilters } from './baseFilters';
import { DashboardCard } from './core/DashboardCard';
import { tableSections } from './core/headers';
import { DashboardLayout } from './DashboardLayout';
import { TabProps, TabKey, tabs } from './dashboardTabs';

const Content = styled(FlexBox)`

  ${BoxContent} {
    box-shadow: 0px -4px 3px ${theme.lightGray}AA;
  }
`;

const defaultFilters = {
  pageNumber: '1'
};

const TabsContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const MessageContainer = styled.div`
  display: flex;
  justify-content: inherit;
  padding-top: 0.4em;
  color: ${theme.sanMarino};
`;

interface NoDataProps {
  message: string;
}
const NoData = ({ message }: NoDataProps) => (
  <TableRow shaded={false}>
    <SpanCell start={1} end={-1}>
      <MessageContainer>{message}</MessageContainer>
    </SpanCell>
  </TableRow>
);

const StyledTab = styled(Tab)`
  background-color: ${theme.white};
  margin-right: 6px;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  border-top: 5px solid ${props => props.highlight};
  border-radius: 4px 4px 0 0;

  ${props => props.selected && css`
    z-index: 2;
  `};
`;

export const DashboardBroker = () => {
  const { pageSettings, setPageSettings, setPageKey } = usePageSettingsContext();
  const showClients = useShowClients();

  const [activeTab, setActiveTab] = useState<TabKey>();

  useEffect(() => {
    setPageKey('dashboard');
    return () => setPageKey();
  }, []);

  const restoredFilters = pageSettings?.dashboard?.filters ?? {};

  const [filters, setFilters] = useState<Filters>({
    ...defaultFilters,
    ...restoredFilters
  });

  useEffect(() => {
    setPageSettings(
      {
        dashboard: {
          ...pageSettings?.dashboard,
          filters
        }
      }
    );
  }, [filters]);

  const [counts, isLoading] = useBowCounts(filters);

  useEffect(() => {
    if (!activeTab && counts && !isLoading) {
      const tab = tabs.find((t: TabProps) => t.key === activeTab || sum(counts[t.key].count) > 0);
      setActiveTab(tab?.key || 'green');
    }
  }, [counts]);

  const changeTab = (tab: TabKey) => {
    setActiveTab(tab);
    setFilters({ ...filters, pageNumber: 1 });
  };
  
  const headers = getHeaders(tableSections, !showClients ? h => h.key !== clientColumnKey : undefined);
  
  return (
    <DashboardLayout filters={filters} setFilters={setFilters}>

      <TabsContainer>
        {tabs.map((t: TabProps) => (
          <StyledTab
            onClick={() => changeTab(t.key)}
            selected={activeTab === t.key}
            highlight={t.highlight}>
            <DashboardCard data={counts[t.key]} tab={t} activeTab={activeTab} />

          </StyledTab>
        ))}
      </TabsContainer>

      <Content flexDirection="column">

        {!!activeTab && baseFilters[activeTab].map((bf, i) => (
          <>
            {((sum(counts[activeTab].count) === 0 && i === 0) || counts[activeTab].count[i] > 0) && (
              <BowsBox
                category={activeTab}
                headers={headers}
                initialFilters={defaultFilters}
                tabFilters={bf}
                filters={filters}
                setFilters={setFilters}
                noDataDisplay={
                  <NoData message={filters ? 'No BOWs matches your current filters' : tabs.find((t: TabProps) => t.key === activeTab)?.noDataMessage ?? 'No data'} />
                }
                config={{
                  paging: true,
                  filterButtons: true,
                  noHeader: true
                }} />
            )}
          </>
        ))}

      </Content>
    </DashboardLayout>
  );
};
