import { WarWeb } from '@/war';
import { TargetType } from './TargetType';

export type AgreementType = 'StandardAgreement' | 'SpecificAgreement';
export enum AgreementTypeEnum {
  Standard = 'Standard',
  Specific = 'Specific'
}

export const isStandardAgreementType = (type?: TargetType) =>
  type === TargetType.Standard || type === TargetType.VesselTypeSpecific || type === TargetType.Standard + TargetType.VesselTypeSpecific;

interface IDirtyable {
  isDirty?: boolean;
  bySystem?: boolean;
}
interface IElementAction {
  action?: WarWeb.ElementAction;
}

export const agreementErrorKeys = {
  defaultRates: 'Rates(InteresTypeId',
  additionalRates: 'Rates(InterestTypeId',
  rebates: 'Rebates(RebateId',
  knrRebates: 'KnrRebates(KnrRebateId'
};

export type ContractType = Exclude<WarWeb.ContractType, 'Fixed'>; // TODO: Add back when backend supports Fixed

export interface ValueSummary<T> extends IDirtyable, WarWeb.ValueSummary<T> { }
export interface ItemSummary<T> extends IDirtyable, WarWeb.ItemSummary<T> { }
export interface RateItemSummary extends IDirtyable, WarWeb.RateItemSummary { }
export interface TextConditionSummary extends IDirtyable, IElementAction, WarWeb.TextConditionSummary { }

export interface CoverageSummary extends WarWeb.CoverageSummary {
  quoteValidHours: ValueSummary<number>;
  daysCovered: ValueSummary<number>;
  maximumDaysCovered: ValueSummary<number>;
  contractType: ValueSummary<ContractType>;
  maximumProRataDays: ValueSummary<number>
}
