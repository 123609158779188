import styled from 'styled-components';
import { TermsPane } from './TermsPane';

export const ContentArea = styled.div`
  padding: 30px;
`;

const PreTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 23px;
  opacity: 0.6;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  line-height: 34px;
  letter-spacing: 0;
  text-transform: uppercase;
`;

interface PageTitleProps {
  title: string;
  preTitle?: string;
}
export const PageTitle = ({ title, preTitle }: PageTitleProps) => (
  <div>
    {preTitle && <PreTitle>{preTitle}</PreTitle>}
    <Title>{title}</Title>
  </div>
);

interface Props {
  preTitle?: string;
}
export const TermsOfUse = ({ preTitle }: Props) => (
  <ContentArea>
    <PageTitle title="Terms of Use" />
    <TermsPane />
  </ContentArea>
);

export const showTermsOfUseModal = () => ({
  component: TermsOfUse,
  options: {
    showHeader: true,
    size: 'large',
    clickOutsideToClose: true
  },
  args: {}
});
