import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { theme } from '@/utility/theme';
import { useErrorContext } from '@/services/ErrorContext';
import {
  FieldLayout, FieldLabel, InputField
} from '../core/Components';

/* In order to style the arrow on the dropdown, we need to wrap the
Select in a new element, add the arrow to the wrapper, and
then give the Select extra padding to make space. */
export const DropdownField = styled(InputField) <{ highlight: boolean, compact: boolean, hasError?: boolean }>`
  position: relative; 
  
  & select {
    padding-right: 40px;
    min-width: 40px;

    ${props => props.compact && css`
      padding: 0.3em;
      padding-left: 0.6em;
      padding-right: 1.5em;
    `}

  }

  &::after {
    content: '';
    position: absolute;
    height: 0.4em;
    width: 0.4em;
    margin-top: ${props => props.compact ? '0.9em' : '0.9em'};
    border-style: solid;
    border-color: ${props => props.theme.marineBlue};
    border-width: 0.11em 0.11em 0 0;
    box-sizing: border-box;
    transform: rotate(135deg);
    right: ${props => props.compact ? '0.6em' : '0.9em'};
    pointer-events: none;
  }
`;

interface OptionsValue {
  name: string;
  value: string;
}

interface DropdownProps {
  name: string;
  label?: string;
  options?: OptionsValue[],
  placeholder?: string;
  disabled?: boolean;
  selectedValue?: string | undefined;
  isTouched?: boolean;
  compact?: boolean;
  simplify?: boolean;
  required?: boolean;
  hasDarkBackground?: boolean;
  changeHandler: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  autoFocus?: boolean;
  tabIndex?: number;
}

export const Dropdown: FC<DropdownProps> = (
  {
    name,
    label,
    options = [],
    placeholder,
    disabled = false,
    selectedValue,
    required = false,
    isTouched = false,
    compact = false,
    simplify = false,
    hasDarkBackground,
    changeHandler,
    ...props
  }
) => {
  const { hasError } = useErrorContext();

  return (
    <FieldLayout>
      {label && (
        <FieldLabel as="label" htmlFor={name}>
          {label}
        </FieldLabel>
      )}
      {simplify && options.length === 1
        ? <strong>{options[0]?.name ?? placeholder}</strong>
        : (
          <DropdownField highlight={!isTouched && required} hasError={hasError} compact={compact}>
            <select id={name} name={name} onChange={changeHandler} value={selectedValue} disabled={disabled} {...props}>
              {placeholder && (
                <option hidden key={placeholder}>{placeholder}</option>
              )}
              {options.map((option, i) => (
                <option key={option.value} defaultValue={!placeholder && i === 0 ? '1' : '0'} value={option.value}>
                  {option.name}
                </option>
              ))}
            </select>
          </DropdownField>
        )}
    </FieldLayout>
  );
};
