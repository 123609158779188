import { useSWREndpoint } from '@/hooks/useSWREndpoint';
import { WarWeb, Insify } from '@/war';

const basepath = 'taxonomy';

export const useVesselTypes = () => useSWREndpoint<WarWeb.VesselType[]>(`${basepath}/vessel-types`);

export const useRebateAssociations = () => useSWREndpoint<Insify.War.Terms.Core.Models.Lists.Rebate.Rebate[]>(`${basepath}/rebates`);

export const useTokens = () => useSWREndpoint<string[]>(`${basepath}/tokens`);
