import { FieldLayout } from '@/components/shared/Form/core/Components';
import { CustomSelect, CustomSelectValue } from '@/components/shared/Form/fields/CustomSelect';
import { TextField } from '@/components/shared/Form/FormFields';
import * as React from 'react';
import { ActionMeta, SingleValue } from 'react-select';
import styled from 'styled-components';

const SubContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.background.primary};
  padding-bottom: 1rem;
  
  ${FieldLayout} {
    padding: .25rem;
    flex: 1 1 33%;  
  }
`;

interface Filters {
  locode: string;
  portName: string;
  hra?: number;
}
interface BulkChangePortFiltersProps {
  options?: {
    label: string;
    value: number;
  }[];
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
}

export const BulkChangePortFilters = ({ options, filters, setFilters }: BulkChangePortFiltersProps) => {
  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters(prev => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const changeHRA = (input: SingleValue<CustomSelectValue>, action: ActionMeta<CustomSelectValue>) => {
    setFilters(prev => ({ ...prev, hra: input?.value }));
  };

  const styles = {
    control: (provided: any) => ({
      ...provided,
      minWidth: '100px'
    })
  };

  return (
    <SubContainer>
      <TextField
        label="Code"
        placeholder="Code..."
        name="locode"
        value={filters.locode}
        onChange={onTextChange}
      />
      <TextField
        label="Name"
        placeholder="Name..."
        name="portName"
        value={filters.portName}
        onChange={onTextChange}
      />
      <CustomSelect
        key={filters.hra == null ? null : undefined}
        value={options?.find(x => x.value === filters.hra)}
        aria-label="HRA"
        styles={styles}
        isClearable
        options={options}
        onChange={changeHRA}
        name="hra" />
    </SubContainer>
  );
};
