import { CustomAsyncSelect, NoDropdownIndicator } from '@/components/shared/Form/fields/CustomAsyncSelect';
import { AgreementTypeEnum } from '@/models/Agreement';
import { TargetType } from '@/models/TargetType';
import { getPorts, usePorts } from '@/services/portsService';
import { areaTypeToObject } from '@/utility/areaType';
import { MinSearchLength } from '@/utility/globals';
import { addOrUpdateArray } from '@/utility/stateArrayHelpers';
import { WarWeb } from '@/war';
import { Remove } from '@instech/icons';
import { useEffect, useState } from 'react';
import { SingleValue } from 'react-select';
import styled from 'styled-components';
import { AgreementViewMode, useAgreementDetailsContext } from '../../agreementDetails/AgreementDetailsContext';
import { useAgreementSelectionContext } from '../../agreementDetails/AgreementSelectionContext';
import { useAgreementsPageContext } from '../../agreements/AgreementsPageContext';
import { useIsBulkEditing } from '../../agreements/useIsBulkEditing';
import {
  FilterTargets, FilterValue, FilterValueNameContainer, SelectionInputContainer
} from '../shared/SelectionComponents';

const Container = styled.div`
  display: flex;
`;
const FilterHeader = styled.div`
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0.5rem;
`;
const CountryNameContainer = styled.div`
  font-size: 12px;
  letter-spacing: 0.05px;
  text-transform: uppercase;
`;

const SearchResult = styled.div<{ isTimeException: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${props => props.isTimeException ? props.theme.lightGreen25 : ''};

  svg {
    width: 30px;
    margin-right: 1em;
  }
`;

interface AreaSummary extends WarWeb.AreaSummary {
  isExcluded?: boolean;
}

export const renderPortResult = (result: WarWeb.AreaMatch) => {
  if (result == null) return null;
  const name = result.areaType === 'Ocean' ? result.oceanAreaName : result.portName;
  const desc = result.areaType === 'Ocean' ? result.regionName : result.countryName;
  return (
    <SearchResult isTimeException={!result.isExcluded}>
      {areaTypeToObject(result.areaType)?.icon}
      <div>
        {name}
        <CountryNameContainer>
          {desc}
        </CountryNameContainer>
      </div>
    </SearchResult>
  );
};

const convertAreaMatchToAreaSummary = (area: WarWeb.AreaMatch): AreaSummary => {
  const isPort = area.areaType === 'Port';
  return {
    areaId: area.areaId!,
    country: isPort ? area.countryName : area.regionName,
    name: isPort ? area.portName : area.oceanAreaName,
    areaType: isPort ? 'Port' : 'OceanArea',
    isExcluded: area.isExcluded
  };
};

export const PortsSelector = () => {
  const { viewMode, targetsSummary } = useAgreementDetailsContext();
  const { agreementType } = useAgreementsPageContext();
  const isBulkEdit = useIsBulkEditing();

  const { areaIds, setAreaIds, areaIdsTimeExceptions, setAreaIdsTimeExceptions, targetType } = useAgreementSelectionContext();

  const [filters, setFilters] = useState<WarWeb.AreaSearchParameters>({ pageSize: 1000, pageNumber: 1, isExcluded: true });

  const [selectedPorts, setSelectedPorts] = useState<AreaSummary[]>([]);
  const canBeEdited = viewMode !== AgreementViewMode.Inspect && !isBulkEdit;

  const { data: initialResultForCreate } = usePorts(filters.selectedAreaIds &&
    { selectedAreaIds: filters.selectedAreaIds, ...filters });
  const [selectedPort, setSelectedPort] = useState<WarWeb.AreaMatch | null>();

  useEffect(() => {
    if (areaIds.length > 0 && selectedPorts.length < areaIds.length) {
      setFilters(prev => ({ ...prev, selectedAreaIds: areaIds }));
    }
  }, [areaIds]);

  // Ensure selected ports is updated after areaIds might have been updated (e.g. external source)
  useEffect(() => {
    setSelectedPorts(prev => prev.filter(x => areaIds.includes(x.areaId)));
  }, [areaIds]);

  useEffect(() => {
    if (initialResultForCreate && initialResultForCreate.items.length > 0) {
      const areas = initialResultForCreate?.items.map(p => convertAreaMatchToAreaSummary(p));
      setSelectedPorts(areas);
      setFilters(prev => ({ ...prev, selectedAreaIds: undefined }));
    }
  }, [initialResultForCreate]);

  // Handle filters and non-excluded areas when we open a trade, or toggle trade on or off in create mode
  useEffect(() => {
    if (agreementType === AgreementTypeEnum.Standard) return;

    if (targetType === TargetType.Trade) setFilters(prev => ({ ...prev, isExcluded: undefined }));
    else if (viewMode === AgreementViewMode.Create) {
      setFilters(prev => ({ ...prev, isExcluded: true }));
      if (selectedPorts.length > 0) {
        setAreaIds(selectedPorts.filter(x => x.isExcluded).map(x => x.areaId));
        setAreaIdsTimeExceptions([]);
        setSelectedPorts(prev => prev.filter(x => x.isExcluded));
      }
    }
  }, [targetType]);

  // Areas on existing agreements should be the truth
  useEffect(() => {
    if (targetsSummary) {
      setSelectedPorts(targetsSummary.areas.map(x => ({ ...x, isExcluded: x.areaType !== 'NonExcluded' })));
    }
  }, [targetsSummary]);

  const addPort = (area?: SingleValue<WarWeb.AreaMatch>) => {
    setSelectedPort(null);
    if (!area?.areaId || selectedPorts.some(x => x.areaId === area.areaId)) return;

    const newArea = convertAreaMatchToAreaSummary(area);

    addOrUpdateArray(selectedPorts, setSelectedPorts, x => x.areaId === area.areaId, newArea);
    setAreaIds([...areaIds, area.areaId]);
    if (!area.isExcluded) setAreaIdsTimeExceptions([...areaIdsTimeExceptions || [], area.areaId]);
  };

  const removePort = (id: number) => {
    setSelectedPorts([...selectedPorts.filter(x => x.areaId !== id)]);
    setAreaIds([...areaIds.filter(x => x !== id)]);
    setAreaIdsTimeExceptions(prev => ([...prev.filter(x => x !== id)]));
  };

  // TODO: Fix return type
  const loadOptions: any = async (searchString: string) => {
    if (searchString.length < MinSearchLength) {
      return [];
    }
    const params = { ...filters, freetext: searchString };
    const { data: results } = await getPorts(params);
    return results?.data?.items;
  };

  const styles = {
    container: (provided: any) => ({
      ...provided,
      width: '100%',
    })
  };
  const renderFilterValues = (areas: AreaSummary[]) => areas.map(a => {
    const isOcean = a.areaType === 'OceanArea';
    const editable = canBeEdited && (viewMode === AgreementViewMode.Create || selectedPorts.length > 1);

    return (
      <FilterValue key={a.areaId}
        ocean={isOcean}
        isExcluded={a.isExcluded}
        isEdit={editable}
        onClick={() => editable && removePort(a.areaId)}
      >
        <FilterValueNameContainer>
          {a.name}
          <CountryNameContainer>{a.country}</CountryNameContainer>
        </FilterValueNameContainer>
        {editable && <Remove />}
      </FilterValue>
    );
  });

  return (
    <Container>
      {canBeEdited && (
        <SelectionInputContainer>
          <>
            <FilterHeader>Excluded area</FilterHeader>
            <CustomAsyncSelect
              name="addExcludedArea"
              value={selectedPort}
              components={NoDropdownIndicator}
              placeholder="Excluded additional areas..."
              loadOptions={loadOptions}
              openMenuOnClick={false}
              onChange={addPort}
              styles={styles}
              formatOptionLabel={renderPortResult}
              noOptionsMessage={() => 'No results found'}
              tabIndex={0}
            />
          </>
        </SelectionInputContainer>
      )}
      <FilterTargets>
        {renderFilterValues(selectedPorts)}
      </FilterTargets>
    </Container>
  );
};
