import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Checkbox } from '@/components/shared/Form/fields/Checkbox';
import { WarWeb } from '@/war';
import { theme } from '@/utility/theme';
import { TableCell, TableRow } from '@/components/shared/Table/Table';
import { Dropdown } from '@/components/shared/Form/FormFields';
import { useEditExcludedAreasContext } from '../EditExcludedAreasContext/EditExcludedAreasContext';
import { FieldType } from '../types';
import { isChecked } from '../utils';

const OtherArea = styled.span<{ isCustomArea: boolean }>`
  ${props => props.isCustomArea && css`
    font-style: italic;
  `};
`;

const TextStyling = styled.span<{ isBold: boolean }>`
  ${props => props.isBold && css`
    font-weight: bold;
  `}
  display: contents;
`;

interface HraOption {
  name: string;
  value: string;
}

interface PortRowProps {
  port: WarWeb.AreaMatch;
  dropdownHRAs?: HraOption[];
  isCustomArea: boolean;
}
export const PortRow = ({ port, dropdownHRAs, isCustomArea }: PortRowProps) => {
  const [highRiskArea, setHighRiskArea] = useState<number>();
  const {
    state: { selectedPorts, areaId },
    addSelectedPort, deleteSelectedPort, updateSelectedPort
  } = useEditExcludedAreasContext();

  const selectedPort = selectedPorts?.find(l => l.locode === port.locode);
  const isInAnotherArea = selectedPorts?.some(l => l.locode === port.locode && l.areaId !== areaId);

  const otherAreaIsCustomArea = port.oceanAreaId != null;
  const otherAreaName = otherAreaIsCustomArea ? port.oceanAreaName : port.countryName;

  const onChangeHandler = (locode: string, key: FieldType, e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const index = selectedPorts?.findIndex(h => h.locode === locode) ?? -1;

    let updatedPort = index >= 0 && selectedPorts ? { ...selectedPorts[index] } : undefined;
    const persistedState = updatedPort && key !== 'HRA' ? updatedPort[key]?.persistedState : undefined;

    let highRiskAreaId = highRiskArea;
    if (key === 'HRA') {
      if (!areaId) return;
      highRiskAreaId = parseInt(e.target.value);
      setHighRiskArea(highRiskAreaId);

      if (updatedPort && highRiskAreaId === (updatedPort?.HRA?.persistedState ?? 0)) {
        delete updatedPort.highRiskAreaIdUpdate;
      } else {
        const highRiskRequest: WarWeb.HighRiskAreaIdUpdate = { highRiskAreaId: highRiskAreaId ?? undefined };
        updatedPort = {
          ...updatedPort,
          isCustomArea: updatedPort?.isCustomArea ?? isCustomArea,
          highRiskAreaIdUpdate: highRiskRequest
        };
      }
    } else if ((e.target as HTMLInputElement).checked) {
      if (!areaId) return;

      // port has been removed (but not saved) and is now "added back" => only filter and return
      const noOperation = selectedPorts?.some(l => l.locode === locode && l[key]?.isRemovedSelection);

      const highRiskRequest: WarWeb.HighRiskAreaIdUpdate = { highRiskAreaId };
      updatedPort = {
        ...updatedPort,
        locode,
        areaId,
        isCustomArea,
        ...(highRiskAreaId && { highRiskAreaIdUpdate: highRiskRequest }),
        [key]: {
          persistedState,
          isNewSelection: !noOperation
        }
      };

      if (key === 'excluded') {
        if (updatedPort.inArea?.isRemovedSelection) updatedPort = { ...updatedPort, inArea: { persistedState: updatedPort.inArea?.persistedState } };
        else if (!updatedPort.inArea?.persistedState) updatedPort = { ...updatedPort, inArea: { persistedState, isNewSelection: true } };
      }
      if (key === 'inArea' && !isCustomArea) updatedPort = { ...updatedPort, excluded: { persistedState, isNewSelection: true } };
    } else {
      const noOperation = selectedPorts?.some(l => l.locode === locode && l[key]?.isNewSelection);

      if (noOperation) {
        if (updatedPort) {
          delete updatedPort[key];
          if (key === 'excluded' && updatedPort.inArea?.isNewSelection) delete updatedPort.inArea;
        }
      } else {
        const highRiskRequest: WarWeb.HighRiskAreaIdUpdate = { highRiskAreaId };
        updatedPort = {
          ...updatedPort,
          locode,
          areaId,
          isCustomArea,
          ...(highRiskAreaId && { highRiskAreaIdUpdate: highRiskRequest }),
          [key]: { persistedState, isRemovedSelection: true }
        };
      }
    }

    if (index >= 0) {
      if (updatedPort) updateSelectedPort(updatedPort);
      else deleteSelectedPort(locode);
    } else if (updatedPort) addSelectedPort(updatedPort);
  };

  const isNewSelection = selectedPort?.excluded?.isNewSelection;
  const isRemovedSelection = selectedPort?.excluded?.isRemovedSelection;

  const isSelected = isChecked(selectedPort?.excluded);

  const isSelectedIncluded = isChecked(selectedPort?.inArea);
  const isNewSelectionIncluded = selectedPort?.inArea?.isNewSelection;
  const isRemovedSelectionIncluded = selectedPort?.inArea?.isRemovedSelection;

  let highlightColor = '';
  if (!isInAnotherArea) {
    if (isNewSelection || isNewSelectionIncluded || !!selectedPort?.highRiskAreaIdUpdate) highlightColor = theme.status.yellow;
    else if (isRemovedSelection || isRemovedSelectionIncluded) highlightColor = theme.mediumRed;
  }

  let hraSelection;
  if (highRiskArea != null) hraSelection = `${highRiskArea}`;
  else hraSelection = port.highRiskAreaId ? `${port.highRiskAreaId}` : '0';

  return (
    <TextStyling isBold={isSelectedIncluded ?? false}>
      <TableRow backgroundColor={highlightColor}>
        <TableCell>
          <Checkbox
            name={`inArea-${port.locode}`}
            disabled={!isCustomArea}
            checked={isSelectedIncluded}
            onChange={e => onChangeHandler(port.locode, 'inArea', e)}
          />
        </TableCell>
        <TableCell textWrap>{port.portName}</TableCell>
        <TableCell>{port.locode}</TableCell>
        <TableCell textWrap>
          {isInAnotherArea ? <OtherArea isCustomArea={otherAreaIsCustomArea}>{otherAreaName}</OtherArea> : port.countryName}
        </TableCell>
        <TableCell>
          <Dropdown
            compact
            name={`HRA-${port.locode}`}
            disabled={!isSelected}
            selectedValue={hraSelection}
            options={dropdownHRAs}
            changeHandler={e => onChangeHandler(port.locode, 'HRA', e)} />
        </TableCell>
        <TableCell>
          <Checkbox
            name={`excluded-${port.locode}`}
            disabled={isSelected && !isNewSelection}
            backgroundColor={theme.green}
            checked={isSelected}
            onChange={e => onChangeHandler(port.locode, 'excluded', e)}
          />
        </TableCell>
      </TableRow>
    </TextStyling>
  );
};
