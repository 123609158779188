export const steps = [
  { step: 1,
    key: 'vesselPane',
    name: 'Vessel',
    path: 'vessel',
    autoAdvance: true,
    warningsKeys: ['vessel'] },
  {
    step: 2,
    key: 'timeAndLocationPane',
    name: 'Time & Location',
    path: 'itinerary',
    autoAdvance: false,
    warningsKeys: ['^coveredAreas([[0-9]+]((.Itinerary[[0-9]+])?).[A-Za-z]+)?$']
  },
  {
    step: 3,
    key: 'additionalPremiumPane',
    name: 'Additional Premium',
    path: 'premium',
    autoAdvance: false,
    warningsKeys: ['Configuration.Knr']
  },
  {
    step: 4,
    key: 'termsAndConditionsPane',
    name: 'Terms & Conditions',
    path: 'terms',
    autoAdvance: false,
    warningsKeys: []
  }
];
