import { Routes } from '@/components/routing/routes';
import { ChangesProvider } from '@/components/shared/BowSidebar/ChangesContext';
import { IdState, QuoteProvider } from '@/components/shared/BowSidebar/QuoteContext';
import { useQuote } from '@/hooks/useQuote';
import { useBow } from '@/services/bowServices';
import { ValidationProvider } from '@/services/ValidationContext';
import { WarWeb } from '@/war';
import { diff } from 'deep-object-diff';
import Qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { BowWizard, isEdit } from './BowWizard';
import { QuoteCommentsProvider } from './QuoteCommentsContext';
import { countDestinations } from './utilities/destinationsHelpers';
import styled from 'styled-components';

const BowWizardContainer = styled.div`  
  margin: 0 auto;
  max-width: ${props => props.theme.portal.maxWidth};
`;

interface LocationState {
  from: {
    pathname: string;
  };
}

export const CreateBowPage = () => {
  const location = useLocation<LocationState>();
  const params = Qs.parse(location.search, { ignoreQueryPrefix: true });
  const bowId = params.bowId ? params.bowId.toString() : undefined;
  const quoteId = params.quoteId ? params.quoteId.toString() : undefined;

  const [selectedConfiguration, setSelectedConfiguration] = useState<number>();
  const [isNoClaimsBonusBased, setIsNoClaimsBonusBased] = useState(true);
  const [isMultiDestination, setIsMultiDestination] = useState<boolean>();

  const history = useHistory();
  const [showState, setShowState] = useState(false);

  const [ids, setIds] = useState<IdState>({
    bowId,
    quoteId
  });

  // if no bowId is given, then this is "create new bow" and we have NO data to wait for (load).
  const [loadCompleted, setLoadCompleted] = useState(!ids.bowId);

  const { data: breach, error, isLoading } = useBow(bowId);
  const selectedQuote = useQuote(bowId, quoteId) || breach?.primaryQuote;

  const [prevBreach, setPrevBreach] = useState<WarWeb.Bow>();
  const [changes, setChanges] = useState<Partial<WarWeb.Bow>>();
  const isCreate = !isEdit(bowId, breach?.status);

  useEffect(() => {
    if (!isLoading && error?.response?.status === 404) history.push(Routes.bows);

    if (breach) {
      setIsNoClaimsBonusBased(selectedQuote?.claimsPenaltyInfo.calculationType === 'NoClaimBonus');
      setIsMultiDestination(() => countDestinations(selectedQuote) > 1);

      if (!isCreate) {
        // detect changes
        if (prevBreach) setChanges(diff(prevBreach, breach));
        setPrevBreach(breach);
      }

      if (!ids.quoteId) {
        setIds({ bowId: breach.id, quoteId: selectedQuote?.id });
      }
      setLoadCompleted(true);
    }
  }, [breach, error]);

  return (
    <QuoteProvider
      showState={showState}
      ids={ids}
      setIds={setIds}
      showSidebarLinks={false}
      selectedConfiguration={selectedConfiguration}
      setSelectedConfiguration={setSelectedConfiguration}
      isNoClaimsBonusBased={isNoClaimsBonusBased}
      isMultiDestination={isMultiDestination}
    >
      <ChangesProvider changes={changes}>
        <ValidationProvider>
          <QuoteCommentsProvider>
            <BowWizardContainer >
            {loadCompleted && <BowWizard />}
            </BowWizardContainer>
          </QuoteCommentsProvider>
        </ValidationProvider>
      </ChangesProvider>
    </QuoteProvider>
  );
};
