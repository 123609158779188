import { useTermsIndexerStatus } from '@/services/termsService';
import { TimeConverter } from '@/utility/timeConverter';
import styled from 'styled-components';
import { Loader } from '@instech/components';

const IndexerStatusContainer = styled.div`
    display: flex;
    margin-bottom: 0.5rem;
    font-size: 14px;
`;

const IndexerText = styled.span`
    font-weight: 600;
`;

export const TermsIndexerStatus = () => {
  const { data, isLoading } = useTermsIndexerStatus();

  return (
    data ? (
      <IndexerStatusContainer>
        Data updated:&nbsp;
        {isLoading ? <Loader size="small" /> : (
          <IndexerText>
            {data.lastIndexed ? TimeConverter.ToDateTime(data.lastIndexed) : 'Unknown'}
          </IndexerText>
        )}
      </IndexerStatusContainer>
    ) : null
  );
};
