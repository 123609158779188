import { Chevron } from '@/components/shared/Chevron';
import { WarWeb } from '@/war';
import styled, { css } from 'styled-components';
import { MenuDots } from '@instech/icons';

const PaginationBarContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
`;

const PaginationButton = styled.a<{ current?: boolean }>`
  font-weight: 700;
  font-size: 14px;
  margin-right: 2rem;
  padding: 0.5rem 0.8rem;
  cursor: pointer;

  &:first-child {
    margin-right: 1rem;
  }

  &:last-child {
    margin-left: -1rem;
    margin-right: 0;
  }

  &:hover {

  };

  ${props => props.current && css`
    background-color: ${props.theme.mediumBlue}44;
    border-radius: 3px;

    &:hover {
    }
  `};
`;

const TotalText = styled.span`
  font-size: 14px;
`;

const StyledMenuDots = styled(MenuDots)`
    margin-top: 10px;
`;

interface PaginationBarProps {
  currentPage: number;
  metadata: WarWeb.PagingMetadata;
  size?: number;
  changePage: (page: number) => void;
}

export const PaginationBar = ({ currentPage, metadata, size, changePage }: PaginationBarProps) => {
  const numPages = metadata.totalPages;
  const arrayLength = (numPages || 0) + 1;
  let windowSize = size ?? 10;
  const backLag = 5;
  if (currentPage > backLag + 1) windowSize -= 1;
  if (currentPage < numPages - backLag + 1) windowSize -= 1;

  if (metadata.totalPages <= 1) return null;

  return (
    <PaginationBarContainer>
      <PaginationButton onClick={() => currentPage > 1 && changePage(currentPage - 1)}>
        <Chevron left />
      </PaginationButton>
      {currentPage > backLag + 1 && (
        <PaginationButton onClick={() => changePage(1)}>
          1
        </PaginationButton>
      )}
      {currentPage > backLag + 2 && (
        <PaginationButton>
          <StyledMenuDots />
        </PaginationButton>
      )}
      {[...Array(arrayLength).keys()].splice(Math.max(currentPage - backLag, 1), windowSize).map((e, i) => (
        <PaginationButton key={e} current={e === currentPage} onClick={() => changePage(e)}>
          {e}
        </PaginationButton>
      ))}
      {currentPage < numPages - backLag + 1 && numPages > windowSize && (
        <>
          <PaginationButton>
            <StyledMenuDots />
          </PaginationButton>
          <PaginationButton onClick={() => changePage(numPages)}>
            {numPages}
          </PaginationButton>
        </>
      )}
      <PaginationButton onClick={() => currentPage < numPages && changePage(currentPage + 1)}>
        <Chevron right />
      </PaginationButton>
      <TotalText>{`Total: ${metadata.totalCount} items`}</TotalText>
    </PaginationBarContainer>
  );
};
