import React from 'react';
import styled from 'styled-components';

const Main = styled.div`
  background: url(images/bg_waves.png) no-repeat center center fixed; 
  background-size: cover;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  width: 400px;
  background-color: ${props => props.theme.white};
  margin-top: 140px;
  margin-left: 60px;
  box-shadow: 0 8px 24px 0 rgba(21,21,34,0.2);
`;

const Content = styled.div`
  padding: 3rem 4rem;

  h1 {
    font-size: 48px;
    font-weight: 400;
  }

  p {
    font-size: 16px;
    line-height: 1.3em;
  }
`;

export const ErrorPage = () => (
  <Main>
    &nbsp;
    <ContentWrapper>
      <Content>
        <h1>Error</h1>

        <p>
          Page not found
        </p>

      </Content>
    </ContentWrapper>
  </Main>
);
