import { LabelValuePair, PageLoader } from '@instech/components';
import { FloppyDisk } from '@instech/icons';
import { useEntryExitPoints } from '@/services/excludedAreasService';
import { ModalLayout } from '@/components/modal/ModalLayout';
import { useEditExcludedAreasContext } from '../EditExcludedAreasContext/EditExcludedAreasContext';
import { AreaAliasSpecification } from '../EditExcludedAreasContext/types';
import { getAliasDescription } from './utils';
import { PredefinedDirectionsModalForm } from './PredefinedDirectionsModalForm';

const getInitialValues = (existingAlias: AreaAliasSpecification, options: LabelValuePair[]) => ({
  name: existingAlias.name,
  entry: options.find(o => o.value === `${existingAlias.entryPointId}`)!,
  exit: options.find(o => o.value === `${existingAlias.exitPointId}`)!
});

interface FormValues {
  name: string;
  entry: LabelValuePair;
  exit: LabelValuePair;
}

interface EditPredefinedDirectionModalProps {
  aliasId: number;
  closeModal: () => void;
}
export const EditPredefinedDirectionModal = ({ aliasId, closeModal }: EditPredefinedDirectionModalProps) => {
  const { state: { areaId, areaAliases }, isCustomArea, updateAreaAlias } = useEditExcludedAreasContext();
  const existingAlias = areaAliases.find(a => a.id === aliasId);
  const { data: entryExitPoints } = useEntryExitPoints(isCustomArea ? areaId : undefined);

  if (!existingAlias) throw new Error('Alias not found');
  if (!entryExitPoints) return <PageLoader />;

  const dropdownOptions = entryExitPoints.map(p => ({ label: p.name, value: `${p.id}` }));
  const initialValues = getInitialValues(existingAlias, dropdownOptions);
  const existingNames = areaAliases.map(alias => alias.name);

  const handleSave = (values: FormValues) => {
    const entryPointId = Number(values.entry.value);
    const exitPointId = Number(values.exit.value);
    const description = getAliasDescription(entryPointId, exitPointId, entryExitPoints);
    const updatedAlias: AreaAliasSpecification = {
      ...existingAlias,
      name: values.name,
      description,
      entryPointId,
      exitPointId,
      action: existingAlias.action === 'Add' ? 'Add' : 'Edit'
    };
    updateAreaAlias(updatedAlias);
    closeModal();
  };

  return (
    <ModalLayout closeModal={closeModal} options={{ title: 'Edit Direction', size: 'small' }}>
      <PredefinedDirectionsModalForm
        initialValues={initialValues}
        onSubmit={handleSave}
        dropdownOptions={dropdownOptions}
        closeModal={closeModal}
        submitText="SAVE"
        submitIcon={<FloppyDisk />}
        existingNames={existingNames}
      />
    </ModalLayout>
  );
};
