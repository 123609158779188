import { AgreementViewMode, useAgreementDetailsContext } from '@/components/pages/agreementDetails/AgreementDetailsContext';
import { useAgreementSelectionContext } from '@/components/pages/agreementDetails/AgreementSelectionContext';
import { TargetType } from '@/models/TargetType';
import { AgreementSummaryTarget, useAgreementsPageContext } from '@/components/pages/agreements/AgreementsPageContext';
import { WarWeb } from '@/war';
import { getAgreementDetailsParams } from '@/utility/sessionStore';
import { AgreementTypeEnum } from '@/models/Agreement';
import { useParams } from 'react-router';
import { useQuery } from '@/hooks/useQuery';

/**
 * Used for fetching agreements data, any target type or view mode
 */
interface IAgreementTargets {
  agreementTargets: Pick<WarWeb.AgreementTargets, 'agreementIds' | 'fleetId' | 'targetType' | 'areaIds' | 'daysCoveredGroup' | 'minimumSumInsured'>;
}
export const useAgreementTargets = (): IAgreementTargets => {
  const { targetType } = useAgreementSelectionContext();

  // Get best effort route params or query params
  const { areaIds: areaIdsParam, targetType: targetTypeParam } = useParams<any>();
  const query = useQuery();
  const queryAreaIds = query.get('areaIds');
  const queryTargetType = query.get('targetType');

  const { viewMode } = useAgreementDetailsContext();
  const { agreementSummaryTarget, setAgreementSummaryTarget, setIsBulkEdit, agreementType } = useAgreementsPageContext();

  if (viewMode === AgreementViewMode.Create) return { agreementTargets: {} };

  const agreementTargets: Partial<WarWeb.AgreementTargets> = {
    areaIds: agreementSummaryTarget.areaIds,
    vesselTypeIds: agreementSummaryTarget.vesselTypeIds,
    vesselIds: agreementSummaryTarget.vesselIds,
    agreementIds: agreementSummaryTarget.agreementIds,
    targetType: agreementSummaryTarget.targetType,
    fleetId: targetType === TargetType.Unspecified ? agreementSummaryTarget.fleetId : undefined,
    daysCoveredGroup: agreementSummaryTarget.hasMultipleSets ? agreementSummaryTarget.daysCoveredGroup : undefined,
    minimumSumInsured: agreementSummaryTarget.hasMultipleSets ? agreementSummaryTarget.minimumSumInsured : undefined
  };

  // We are not in create mode and cannot see any agreementIds, page has probably been refreshed - try to fetch ids from session storage
  if (agreementSummaryTarget.agreementIds.length === 0) {
    const targets = getAgreementDetailsParams();

    // If we get nothing from session storage, it might be a redirect from specific agreement rate guide to a standard agreement
    if (!targets) {
      if (queryAreaIds && queryTargetType) {
        return {
          agreementTargets: {
            areaIds: [parseInt(queryAreaIds)],
            targetType: parseInt(queryTargetType),
            daysCoveredGroup: agreementSummaryTarget.hasMultipleSets ? agreementSummaryTarget.daysCoveredGroup : undefined,
            minimumSumInsured: agreementSummaryTarget.hasMultipleSets ? agreementSummaryTarget.minimumSumInsured : undefined
          }
        };
      }
      return {
        agreementTargets: {
          areaIds: areaIdsParam,
          targetType: targetTypeParam
        }
      };
    }

    if (!targets.agreementIds) return { agreementTargets: {} };

    setIsBulkEdit((agreementType === AgreementTypeEnum.Specific && targets.agreementIds.length > 1) ||
      (agreementType === AgreementTypeEnum.Standard && targets.agreementIds.length > 5));

    const targetsConverted: AgreementSummaryTarget = {
      areaIds: targets.areaIds,
      vesselTypeIds: targets.vesselTypeIds,
      agreementIds: targets.agreementIds,
      targetType: targets.targetType,
      fleetId: targets.fleetIds?.at(0),
      daysCoveredGroup: targets.daysCoveredGroup,
      minimumSumInsured: targets.minimumSumInsured,
      vesselIds: targets.vesselIds,
      hasMultipleSets: targets.hasMultipleSets
    };
    setAgreementSummaryTarget(targetsConverted);
    return { agreementTargets: targetsConverted };
  }

  return { agreementTargets };
};
