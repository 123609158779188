import { Export } from '@instech/icons';
import styled from 'styled-components';
import { RateItemSummary } from '@/models/Agreement';
import { TableCell } from '@/components/shared/Table/Table';
import { AgreementViewMode, useAgreementDetailsContext } from '@/components/pages/agreementDetails/AgreementDetailsContext';
import { useAgreementDataContext } from '@/components/pages/agreementDetails/AgreementDataContext';

export const PaddedTableCell = styled(TableCell)`
  padding-top: 1.2em;
`;

const PopulateCell = styled(PaddedTableCell)`
  svg {
    cursor: pointer;
  }
`;

const HmRateId = 2;

interface PopulateRateCellProps {
  item: RateItemSummary;
}
export const PopulateRateCell = ({ item, ...props }: PopulateRateCellProps) => {
  const { viewMode } = useAgreementDetailsContext();
  const { premium, setPremium } = useAgreementDataContext();

  const populateCellClick = () => {
    const tempRates = [...premium.defaultRates];
    const tempAdditionalRates = [...premium.additionalRates];

    const populate = (rate: RateItemSummary) => {
      if (rate.id === HmRateId) return;
      /* eslint-disable no-param-reassign*/
      rate.value = item.value ? item.value * rate.primaryRateDistributionFactor : undefined;
      rate.isDirty = true;
      /* eslint-enable no-param-reassign*/
    };

    tempRates.forEach(r => r.tier === item.tier && populate(r));
    tempAdditionalRates.forEach(r => r.tier === item.tier && populate(r));

    setPremium({ ...premium, defaultRates: tempRates, additionalRates: tempAdditionalRates });
  };

  return (
    item.id === HmRateId && viewMode !== AgreementViewMode.Inspect ?
      <PopulateCell {...props}><Export onClick={() => populateCellClick()} /></PopulateCell> :
      null
  );
};
