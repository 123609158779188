import { useErrorContext } from '@/services/ErrorContext';
import { MaxNumberValue } from '@/utility/globals';
import { theme } from '@/utility/theme';
import { TextFieldControlled, TextFieldControlledProps } from '@instech/components';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { FlexBox } from '../../FlexBox';

const Postfix = styled.div<{ postFixPadding?: string }>`
  padding-left: ${props => props.postFixPadding ? props.postFixPadding : '0.5em'};
  padding-top: ${props => props.postFixPadding ? props.postFixPadding : '0'};
`;

const Wrapper = styled(FlexBox) <{ dirty?: boolean, compact?: boolean, hasError?: boolean }>`

  input {
    
    ${props => props.hasError && css`
      border: 1px solid ${theme.status.darkRed};
      color: ${theme.status.darkRed};
    `};

    padding: 0px ${props => props.compact ? '7px' : '16px'};

    text-align: right;
    padding-right: 0.4em;
  }

  & span {
    height: auto;
  }
`;

type NumberFieldType = 'int' | 'decimal';

export interface NumberFieldProps extends TextFieldControlledProps {
  type?: NumberFieldType;
  maxValue?: number;
  minValue?: number;
  // not taken from ValidationProvider, as TextField is often used outside of ValidationContext!
  setDismissedErrors?: React.Dispatch<React.SetStateAction<string[]>>;
  noErrors?: boolean;
  postfix?: string;
  postFixPadding?: string;
  dirty?: boolean;
  minimal?: boolean;
  compact?: boolean;
  updateValidationError?: any;
}

export const NumberField = (
  {
    type = 'decimal',
    value,
    label,
    minValue,
    maxValue,
    onChange,
    setDismissedErrors,
    noErrors = false,
    minimal = false,
    compact = false,
    dirty = false,
    postfix,
    postFixPadding,
    updateValidationError,
    ...props
  }: NumberFieldProps
) => {
  const { hasError, errorKeys } = useErrorContext();
  const [validationMessage, setValidationMessage] = useState<string | undefined>();

  const validate = (input?: string) => {

    if (!input) {
      setValidationMessage(undefined);
      return true;
    }

    // if numeric input contains only single "-", then let it pass (negative number coming up)
    if (input !== '-') {
      const validNumberPattern = type === 'decimal' ? '^-?\\d*(\\.\\d*)?$' : '^-?\\d*$';
      const numericCheck = input.match(new RegExp(validNumberPattern));

      // we stop non-numeric input from passing through,
      // but we DON'T stop numeric values (like, we allow the user to type 200, even if maxValue = 100)
      // the validation message, however, will be shown
      const numericValue = parseInt(input);

      let isInvalid = false;

      if (!numericCheck) {
        return false;
      }

      if ((minValue || minValue === 0) && numericValue < minValue) {
        setValidationMessage(`Value must be greater than ${minValue}`);
        isInvalid = true;
        updateValidationError && updateValidationError(props.name, true); //Setting a validation error for the field
      }
      // this is to circumvent JSON serialization failure, which would give a 500 error (not 400 error)
      if (numericValue > MaxNumberValue) {
        setValidationMessage('Value too large');
        isInvalid = true;
        updateValidationError && updateValidationError(props.name, true); //Setting a validation error for the field
      }
      if (!!maxValue && numericValue > maxValue) {
        setValidationMessage(`Value must be smaller than ${maxValue}`);
        isInvalid = true;
        updateValidationError && updateValidationError(props.name, true); //Setting a validation error for the field
      }
      if (!isInvalid) {
        setValidationMessage(undefined);
        updateValidationError && updateValidationError(props.name, false);
      }
    }
    return true;
  };

  const inputCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!validate(e.target.value)) return;
    if (onChange) onChange(e);
    if (hasError && setDismissedErrors) setDismissedErrors(errorKeys || []);
  };

  return (
    <Wrapper
      alignItems="center"
      dirty={dirty}
      compact={compact}
      hasError={hasError}>
      <TextFieldControlled
        value={value}
        touched={dirty}
        error={hasError && !dirty ? undefined : validationMessage}
        noErrors={noErrors}
        noLabel={!label}
        label={label}
        onChange={inputCheck}
        {...props} />
      {postfix && <Postfix postFixPadding={postFixPadding}>{postfix}</Postfix>}
    </Wrapper>
  );
};
