import { CalculationsBox } from '@/components/shared/Bow/Calculations/CalculationsBox';
import { KidnapRansomBox } from '@/components/shared/Bow/KidnapRansom/KidnapRansomBox';
import { useSelectedQuote } from '@/components/shared/Bow/useSelectedQuote';
import { RemainingValidations } from '@/components/shared/RemainingValidations';
import { Validator } from '@/components/shared/Validator';
import { useHateoasExecutorContext } from '@/services/HateoasExecutorContext';
import { useValidationContext } from '@/services/ValidationContext';
import { isEmpty } from '@/utility/objectHelpers';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { steps } from './bowSteps';
import { useBowWizardStateContext } from './BowWizardStateContext';

const PremiumContainer = styled.div`
  display: grid;
  grid-template-columns: 4fr 2fr;
  grid-template-rows: auto;
  column-gap: 2rem;
  row-gap: 2rem;
  grid-template-areas:
    "errors errors"
    "k-r-coverage blank"
    "calc calc";
 
    .errors {
    grid-area: errors;
  }
  .k-r-coverage {
    grid-area: k-r-coverage;
  }
  .blank {
    grid-area:blank;
  }
  .calc {
    grid-area: calc;
  }
`;

export const PremiumStep = () => {
  const { errors } = useValidationContext();
  const { loading } = useHateoasExecutorContext();

  const { setStepDone } = useBowWizardStateContext();

  // TODO: This (Next button) should be "activated" from backend, through a HATEOAS link
  useEffect(() => {
    setStepDone(isEmpty(errors));
  }, [errors]);

  const selectedQuote = useSelectedQuote();

  return (
    <PremiumContainer>
      <div className="errors">
        <Validator keys={steps.filter(s => s.step !== 3)?.flatMap(u => u.warningsKeys.map(v => `/${v}/`))} hideWarnings />
        <RemainingValidations rootElement={selectedQuote} />
      </div>

      <div className="k-r-coverage">
        <KidnapRansomBox />
      </div>

      <div className="blank">&nbsp;</div>

      <div className="calc">
        <CalculationsBox loadStatus={{ loading }} />
      </div>

    </PremiumContainer>
  );
};
