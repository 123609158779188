/* eslint-disable max-len */
const termsOfUseText = '### 1. Introduction' +
'\n\nThe following conditions govern your personal use of Norwegian Hull Club&#39;s (&quot;NHC&quot;) web solution (the &quot;Portal&quot;) for communication regarding assured vessels&#39; entry into high risk areas (&quot;HRA&quot;) constituting breaches of warranty (&quot;BOW&quot;) under their WAR risk insurances, and the calculation and payment of additional premiums due to such BOWs. You are hereinafter referred to as the &quot;User&quot;.' +
'\n\n\nThe User gains access to the Portal once he has registered his user account and been granted access by NHC.' +
'\n\n\nThe User may not use the Portal for any other purpose or in any other way than as expressly permitted herein, and shall do so acting in good faith. The User shall not make the web solution available to any third party without prior written consent from NHC. The User agrees not to develop derivative works which are intended to be substitutes for the Portal.' +
'\n\n### 2. Description and user instructions' +
'\n\n#### 2.1 User Instructions – core functionalities' +
'\n\nThe Portal is a tool for agreeing terms and conditions for trade in conditional or excluded areas (HRA). To access and use the Portal, a username and a password is required. This username and password is personal and the User shall protect the password in order to prevent unauthorized access and use. If the User shares a computer, the User shall log off after use. NHC does not accept any responsibility for the consequences of any unauthorized access or use of the Portal.' +
'\n\n\nTrade in HRA is hereinafter referred to as breach of trading warranties (BOW). Except for agreeing terms and conditions for trade in HRA, use of the Portal will not amend the underlying WAR risk policy unless explicitly agreed, neither will these Conditions of use. Unintentional and/or obvious errors arising from the use of the Portal, e.g. the Portal generating obviously incorrect BOWs, shall not be binding on NHC, and shall not give rise to any legal position or claim for the User or any person or entity for whom he is acting. This includes, without limitation, obviously incorrect terms in generated BOWs.' +
'\n\n\nThe use of the Portal builds on (1) pre-set commercial terms for trade in specific HRAs by specific vessels, (2) interests covered for WAR risks with NHC, and (3) the User&#39;s input in the form of vessel details, trading area, trading duration and rebates (if applicable). Based on the pre-set terms, coupled with the Users&#39; input, the Portal produces quotes and agreements regarding terms and conditions for trade in HRA. All information in connection with a BOW is stored as an individual registration, each with its unique reference number. The information and documentation connected to each registration is retrievable only by the User, the User&#39;s company, the assured and NHC.' +
'\n\n\nA registration/BOW can have the statuses; &quot;Draft&quot;, &quot;Quoted&quot;, &quot;Confirmed&quot; and &quot;Finalized&quot;, as well as system statues &quot;Discarded&quot; and &quot;Cancelled&quot;. When a registration is given a certain status, or when parameters are changed in a certain status, the Portal automatically generates documentation called &quot;UNDERWRITER VERSION&quot; which includes all the agreed terms and conditions, which is the legally binding document between NHC and the User. The documentation can be downloaded from the Portal and all historical versions are also available.' +
'\n\n#### *Draft*' +
'\n\nIn this status, information has been registered but terms and conditions for the voyage to a HRA has not been confirmed. Thus, no formal quote is offered and no binding agreement is established.' +
'\n\n#### *Quoted*' +
'\n\nIn this status, information has been registered but terms and conditions for the voyage to a HRA has not been confirmed. Thus, no binding agreement is established, but a legally binding quote is offered by NHC for the User to accept (by changing status to Confirmed by actively selecting this option) or reject/let lapse.' +
'\n\n#### *Confirmed*' +
'\n\nIn this status, information has been registered and terms and conditions for the BOW have been agreed.' +
'\n\n\nHowever, these terms and conditions are subject to the vessel entering the HRA within the time limit stated on the confirmation document. The agreement is to cover the vessel according to its underlying WAR risks policy and any additional and explicitly stated conditions for the duration of the BOW. The calculation of the additional premium (AP) for this cover has also been agreed, but the final AP pends on the total durability of the voyage which has yet to be determined.' +
'\n\n\nThe User is obligated to expediently collect the information necessary to finalize the BOW, and – when all due information is collected – finalize the BOW.' +
'\n\n#### *Finalized*' +
'\n\nIn this status, information has been registered, terms and conditions for the voyage to a HRA has been confirmed and the total durability of the voyage has been agreed. The agreement is to cover the vessel according to its underlying WAR risks policy and any additional and explicitly stated conditions for the duration of the BOW. The final additional premium (AP) for this cover has also been calculated.' +
'\n\n\n#### 2.2. Additional, non-binding features' +
'\n\nIn addition to the features described above, which are essential for the agreements regarding terms and conditions for BOWs, the Portal contains certain features which are included exclusively as an extra service to the User. These additional features do not have any legally binding effect on the agreed terms and conditions for BOWs, nor on NHC in general. The features include, but are not necessarily limited to, the following:' +
'\n\n#### *Broker&#39;s documentation*' +
'\n\nThe PDF file &#39;&#39;BROKER VERSION&#39;&#39; is meant to provide the User with a document which can be used, unchanged, towards the User&#39;s client(s) and/or within the User&#39;s own organization. This acts as a supplement to &quot;UNDERWRITER VERSION&quot;, but has no legally binding effect.' +
'\n\n#### *Tax calculation*' +
'\n\nRegistration of tax percentages and corresponding calculation of tax per interest is available to the User. The percentages and tax will be displayed in the broker version of the documentation. NHC is not responsible for any miscalculations or other unintentional errors in the calculation of tax.' +
'\n\n#### *Comment*' +
'\n\nThe User may enter additional information in a free text field &quot;Comment&quot;. This information will be displayed in the broker version documentation.' +
'\n\n\n### 3. Obligations of the User' +
'\n\nThe User is responsible for entering only and all correct and relevant information, including but not limited to:' +
'\n\n\n(*a*) Vessel' +
'\n\n(*b*) Area / Port' +
'\n\n(*c*) Entry time' +
'\n\n(*d*) Exit time, and' +
'\n\n(*e*) Rebates (if applicable) including K&amp;R insurance.' +
'\n\n\nFurther, the User shall in good faith control that the concluded agreement is correct based on the registered information (user input) and the pre-set commercial terms for the relevant vessel in the relevant HRA. If the User discovers that the concluded agreement is incorrect in any way, the User shall immediately notify NHC.' +
'\n\n### 4. Changes' +
'\n\nNHC has the right to amend, develop and terminate the Portal and the User&#39;s access to the Portal, at NHC&#39;s sole discretion and without notice. NHC has the right to revise and modify these Conditions of use at any time.' +
'\n\n### 5. Responsibility and Limitation on Liability' +
'\n\nNorwegian Hull Club shall not be liable for any direct or consequential loss caused by the use of the Portal. Further, NHC shall not be liable for any loss or other consequence from malfunctions or errors in the web solution. NHC may require you to change password at given intervals.' +
'\n\n### 6. Intellectual property rights' +
'\n\nFull title to and ownership of the Portal and all exclusive rights therein, including without limitation all intellectual property rights, hereunder but not limited to trade secrets, trademarks, patents, and copy rights, shall remain with NHC. No title, ownership nor any exclusive rights to the Portal are transferred to the User. The User agrees not to develop derivative works which are intended to be substitutes for Portal.' +
'\n\n### 7. Non­-disclosure' +
'\n\nThe Portal has commercial value and is a utility in the business of NHC. The Portal&#39;s contents, including but not limited to its technical solutions, production methods, commercial analyses or calculations, are deemed as trade secrets and is regarded as Confidential Information.' +
'\n\n\nThe User has a duty of nondisclosure of Confidential Information to third parties. The User shall carefully restrict access to Confidential Information to colleagues and clients as is reasonably required and shall require those persons to sign nondisclosure restrictions at least as protective as those in these Conditions of use. The User shall not, without prior written approval from NHC, use for the User&#39;s own benefit, publish, copy, or otherwise disclose to others, or permit the use by others for their benefit or to the detriment of NHC, any Confidential Information.' +
'\n\n\nNHC has a duty of nondisclosure of data entered into the Portal to third parties. NHC shall carefully restrict access to the data to NHC employees, the User, the User&#39;s company and the assured.' +
'\n\n\nThe nondisclosure obligation shall continue to apply after the agreement has been terminated.' +
'\n\n### 8. Choice of law, dispute resolution and legal venue' +
'\n\nThese Conditions of use shall be governed by Norwegian law. Any disputes arising out of them shall be decided with final and enforceable effect by arbitration in Bergen, Norway. The rules of the Norwegian Arbitration Act (Act No. 25 of May 14th 2004) shall apply to the composition and procedure of the arbitration tribunal.';

export const useTermsOfUse = () => termsOfUseText;
