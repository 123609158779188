import { ReactElement } from 'react';
import {
  Dropdown, LabelValuePair, SlimButton, TextField
} from '@instech/components';
import * as Yup from 'yup';
import { Formik, FormikValues } from 'formik';
import { dropdownOptionRequired, stringRequired } from '@/utility/validationSchemas';
import styled from 'styled-components';
import {
  ButtonRow, FormLayout, ModalContentLayout
} from '../ModalComponents';

const validationSchema = (names: string[]) => Yup.object().shape({
  name: stringRequired().test(
    'is-unique',
    ({ value }) => `The name "${value}" is already in use in this area`,
    value => !names.includes(value)
  ),
  entry: dropdownOptionRequired(),
  exit: dropdownOptionRequired()
});

const EntryExitLayout = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
`;

interface PredefinedDirectionsModalFormProps<T> {
  initialValues: FormikValues & T;
  onSubmit: (values: T) => void;
  dropdownOptions: LabelValuePair[];
  closeModal: () => void;
  submitText: string;
  submitIcon: ReactElement;
  existingNames: string[]
}
/**
 * Generic form used by both Add and Edit modals
*/
export const PredefinedDirectionsModalForm = <T, >(
  { initialValues, onSubmit, dropdownOptions, closeModal, submitText, submitIcon, existingNames }: PredefinedDirectionsModalFormProps<T>
) => (
  <Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
    validationSchema={validationSchema(existingNames)}
    >
    {({ handleSubmit, dirty }) => (
      <ModalContentLayout>
        <FormLayout>
          <TextField label="Direction Name" name="name" placeholder="" maxLength={255} forceMaxLength />
          <EntryExitLayout>
            <Dropdown name="entry" label="Entry" options={dropdownOptions} truncateOptions />
            <Dropdown name="exit" label="Exit" options={dropdownOptions} truncateOptions />
          </EntryExitLayout>
        </FormLayout>
        <ButtonRow>
          <SlimButton onClick={closeModal} variant="secondary">CANCEL</SlimButton>
          <SlimButton onClick={handleSubmit} startIcon={submitIcon} disabled={!dirty}>{submitText}</SlimButton>
        </ButtonRow>
      </ModalContentLayout>
    )}
  </Formik>
  );
