import React from 'react';
import { Boat, Books } from '@instech/icons';

interface FleetVesselSearchResultType {
  type: string;
  icon: React.ReactNode;
}
export const fleetVesselSearchResultType: FleetVesselSearchResultType[] = [
  {
    type: 'Fleet',
    icon: <Books />
  },
  {
    type: 'Vessel',
    icon: <Boat />
  }
];

export const fleetVesselSearchResultToObject = (type: string) =>
  fleetVesselSearchResultType.find(x => x.type === type);
