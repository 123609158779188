import { InlineModalProps } from '@/components/modal/InlineModalProps';
import { ModalLayout } from '@/components/modal/ModalLayout';
import { Button } from '@/components/shared/Button';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import {
  NoDataRow, renderTableHeaders, Table, TableCell, TableRow
} from '@/components/shared/Table/Table';
import { AgreementTypeEnum } from '@/models/Agreement';
import { useAgreementHistory } from '@/services/agreementHistoryService';
import { theme } from '@/utility/theme';
import { TimeConverter } from '@/utility/timeConverter';
import { WarWeb } from '@/war';
import styled from 'styled-components';
import { UserInitials } from '../../../../utility/userNameHelpers';
import { Box } from '../../../shared/Box';
import { useAgreementsPageContext } from '../../agreements/AgreementsPageContext';
import { useAgreementTargets } from '../../agreements/useAgreementTargets';

interface LogRowProps {
  entry: WarWeb.AgreementChangeLog;
}
const ChangeLogRow = ({ entry }: LogRowProps) => {
  const { agreementType } = useAgreementsPageContext();
  return (
    <TableRow>
      <TableCell textWrap>{entry.lastUpdated.comment ?? 'n/a'}</TableCell>
      <TableCell>
        {TimeConverter.ToDateTime(entry.lastUpdated.lastUpdatedDate)}
      </TableCell>
      <TableCell><UserInitials name={entry.lastUpdated.user.name} /></TableCell>
      {agreementType === AgreementTypeEnum.Specific && (
        <>
          <TableCell>{entry.vessel.name}</TableCell>
          <TableCell>{entry.vessel.imo}</TableCell>
        </>
      )}
    </TableRow>
  );
};

const ScrollContainer = styled.div`
  overflow-y: scroll;
  display: contents;
  color: ${theme.marineBlue};
  font-size: 14px;
`;

export const ChangeLogModal = ({ closeModal }: InlineModalProps) => {
  const { agreementType } = useAgreementsPageContext();
  const { agreementTargets } = useAgreementTargets();
  const { data, isValidating: isLoading, error } = useAgreementHistory(agreementTargets);

  const tableHeaders = agreementType === AgreementTypeEnum.Standard ? ['Change', 'Date', 'By'] : ['Change', 'Date', 'By', 'Vessel', 'Imo'];

  return (
    <ModalLayout closeModal={closeModal}>
      <Box header="Change log" shaded={false}>

        {!isLoading && data ? (
          <ScrollContainer>
            <Table striped
              columns={tableHeaders.length}
              layout={agreementType === AgreementTypeEnum.Standard ? '3fr 1fr 1fr' : '3fr 1.4fr 1fr 1.4fr 1fr'}
              maxHeight="600px">
              {renderTableHeaders(tableHeaders)}
              {data.map(log =>
                <ChangeLogRow key={log.versionTime} entry={log} />)}
            </Table>
          </ScrollContainer>
        ) : <NoDataRow loadStatus={{ loading: isLoading, success: !error }} />}
      </Box>

      <ButtonGroup justify="flex-end">
        <Button onClick={closeModal} background={theme.green}>Close</Button>
      </ButtonGroup>
    </ModalLayout>
  );
};
