import { WarWeb } from '@/war';
import { AreaName } from '../types';
import { Action, ActionTypes } from './actions';
import { ActionItem, EditExcludedAreasState } from './types';

export const initialState: EditExcludedAreasState = {
  regionId: undefined,
  areaId: undefined,
  areaType: undefined,
  oceanAreaId: undefined,
  oceanAreaNames: [],
  selectedPorts: [],
  entryAndExitPoints: [],
  hraForArea: [],
  selectedAll: [],
  errors: {},
  editingNewArea: false,
  isSaving: false,
  areaAliases: []
};

export const initializer = (initialValues: { regionId?: number; areaId?: number; areaType?: string; }): EditExcludedAreasState => ({
  ...initialState,
  regionId: initialValues.regionId,
  areaId: initialValues.areaId,
  areaType: initialValues.areaType
});

const replaceArrayItem = <T, >(array: T[], index: number, newItem: T) => {
  const newArray = [...array];
  newArray[index] = newItem;
  return newArray;
};

const deleteActionItem = <T extends ActionItem>(existinItems: T[], id: number) => {
  const index = existinItems.findIndex(p => p.id === id);
  const existingItem = existinItems[index];

  // If the item exists only in state, just remove it
  if (existingItem.action === 'Add') {
    return existinItems.filter(p => p.id !== id);
  }

  const updatedItem = {
    ...existingItem,
    action: 'Delete'
  };

  return replaceArrayItem(existinItems, index, updatedItem);
};

const unDeleteActionItem = <T extends ActionItem>(existinItems: T[], id: number) => {
  const index = existinItems.findIndex(p => p.id === id);
  const existingItem = existinItems[index];

  const updatedItem = {
    ...existingItem,
    action: 'NotSet'
  };

  return replaceArrayItem(existinItems, index, updatedItem);
};

const getNewCustomAreaName = (state: EditExcludedAreasState, newAreaId: number) : AreaName => {
  const newName = `Untitled Custom Area #${Math.abs(newAreaId)}`;
  if (!state.regionId) throw new Error('Region ID not set');
  return {
    regionId: state.regionId,
    oceanAreaId: newAreaId,
    name: newName,
    action: 'Add'
  };
};
const deleteOceanAreaName = (state: EditExcludedAreasState, area: WarWeb.Area) =>
  state.oceanAreaNames
    .filter(l => l.oceanAreaId !== state.areaId)
    .concat({ regionId: state.regionId!, name: area.name, oceanAreaId: state.areaId!, action: 'Delete' });

export const editExcludedAreasReducer = (state: EditExcludedAreasState, action: Action): EditExcludedAreasState => {
  switch (action.type) {
    case ActionTypes.AddEntryExitPoint:
      return {
        ...state,
        entryAndExitPoints: [...state.entryAndExitPoints, action.payload]
      };
    case ActionTypes.DeleteEntryExitPoint:
      return {
        ...state,
        entryAndExitPoints: deleteActionItem(state.entryAndExitPoints, action.payload)
      };
    case ActionTypes.UndoDeleteEntryExitPoint:
      return {
        ...state,
        entryAndExitPoints: unDeleteActionItem(state.entryAndExitPoints, action.payload)
      };
    case ActionTypes.SetEntryExitPoints:
      return {
        ...state,
        entryAndExitPoints: action.payload
      };
    case ActionTypes.SetRegionId:
      return {
        ...state,
        regionId: action.payload
      };
    case ActionTypes.SetAreaId:
      return {
        ...state,
        areaId: action.payload
      };
    case ActionTypes.SetAreaType:
      return {
        ...state,
        areaType: action.payload
      };
    case ActionTypes.SetHraForArea:
      return {
        ...state,
        hraForArea: action.payload
      };
    case ActionTypes.AddSelectedPort:
      return {
        ...state,
        selectedPorts: [...state.selectedPorts, action.payload]
      };
    case ActionTypes.DeleteSelectedPort:
      return {
        ...state,
        selectedPorts: state.selectedPorts.filter(p => p.locode !== action.payload)
      };
    case ActionTypes.UpdateSelectedPort:
      return {
        ...state,
        selectedPorts: replaceArrayItem(state.selectedPorts, state.selectedPorts.findIndex(p => p.locode === action.payload.locode), action.payload)
      };
    case ActionTypes.SetSelectedPorts:
      return {
        ...state,
        selectedPorts: action.payload
      };
    case ActionTypes.AddNewCustomArea:
      return {
        ...state,
        areaId: action.payload,
        oceanAreaNames: [...state.oceanAreaNames, getNewCustomAreaName(state, action.payload)],
        editingNewArea: true
      };
    case ActionTypes.DeleteCustomArea:
      return {
        ...state,
        selectedPorts: state.selectedPorts.filter(l => !(l.areaId === action.payload.id && l.excluded?.isNewSelection)),
        oceanAreaNames: deleteOceanAreaName(state, action.payload),
        editingNewArea: false
      };
    case ActionTypes.UndoAddedCustomArea:
      return {
        ...state,
        areaId: undefined,
        selectedPorts: state.selectedPorts.filter(l => !(l.areaId === action.payload && l.excluded?.isNewSelection)),
        oceanAreaNames: state.oceanAreaNames.filter(l => l.oceanAreaId !== action.payload),
        editingNewArea: false
      };
    case ActionTypes.SetIsSaving:
      return {
        ...state,
        isSaving: action.payload
      };
    case ActionTypes.SetOceanAreaNames:
      return {
        ...state,
        oceanAreaNames: action.payload
      };
    case ActionTypes.AddAreaAlias:
      return {
        ...state,
        areaAliases: [...state.areaAliases, action.payload]
      };
    case ActionTypes.DeleteAreaAlias:
      return {
        ...state,
        areaAliases: deleteActionItem(state.areaAliases, action.payload)
      };
    case ActionTypes.UndoDeleteAreaAlias:
      return {
        ...state,
        areaAliases: unDeleteActionItem(state.areaAliases, action.payload)
      };
    case ActionTypes.UpdateAreaAlias:
      return {
        ...state,
        areaAliases: replaceArrayItem(state.areaAliases, state.areaAliases.findIndex(p => p.id === action.payload.id), action.payload)
      };
    case ActionTypes.SetAreaAliases:
      return {
        ...state,
        areaAliases: action.payload
      };
    case ActionTypes.AddError:
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload
        }
      };
    case ActionTypes.ClearErrors:
      return {
        ...state,
        errors: {}
      };
    default:
      return state;
  }
};
