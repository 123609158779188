import { ChildrenProps } from '@/models/utils/ChildrenProps';
import { AgreementsOverviewProvider } from './AgreementsOverviewContext';
// Shared overview for specific and default
// Responsible for layout -> header and body, but doesn't control the content of those
// Subcomponent controls header content, adjustable for specific and default
// Subcomponent controls body content, both shared and not between agreement types

export const AgreementsOverviewPage = ({ children }: ChildrenProps) => (
  <AgreementsOverviewProvider>
    {children}
  </AgreementsOverviewProvider>
);
