import React, {
  useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import {
  RichUtils, EditorState, ContentState
} from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import 'draft-js/dist/Draft.css';
import styled, { css } from 'styled-components';
import createMentionPlugin, { defaultSuggestionsFilter, MentionData, } from '@draft-js-plugins/mention';
import '@draft-js-plugins/mention/lib/plugin.css';
import { customBlockStyleFn } from './core/customStyles';
import {
  InlineStyleControls, BlockStyleControls, LinkStyleControls
} from './core/Controls';
import { editorStateToText, getEditorState } from './utility';
import { Token } from './Token';
import { useTokens } from '@/services/taxonomyService';

const TextStyleRow = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  background-color: ${props => props.theme.lightGray};
  padding-left: 20px;
`;

interface TextEditorProps {
  padding?: string;
  spaceBetween?: string;
}
const TextEditor = styled.div<TextEditorProps>`
  display: flex;
  flex-wrap: nowrap;
  height: 80px;
  border-radius: 1px;
  padding: ${props => props.padding};
  font-size: 16px;

  @media (max-width: 1100px) {
    flex-wrap: wrap;
    & > * {
      width: 100%;
    }
  }

  ${props => props.spaceBetween && css`
    & > *:not(:first-child) {
      @media (min-width: 1101px) {
        margin-left: ${props.spaceBetween};
      }
      @media (max-width: 1100px) {
        margin-top: ${props.spaceBetween};
      }
    }
  `}
`;

const TextView = styled.div`
  height: 80px;
  width: 100%;
  background: ${props => props.theme.white};
  border: 1px solid ${props => props.theme.border.gray};
  border-radius: 3px;
  padding: 12px;
  font-size: 14px;
  box-sizing: content-box;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.05);
  overflow: auto;

 
  // Force margins to not collapse, to prevent scrollbars rendering when they should not.
  & .public-DraftEditor-content {
    display: block;
    overflow: auto;
  }
`;

interface RichTextProps {
  onRichTextChange: (text: string) => void;
  richText: string;
  padding?: string;
  spaceBetween?: string;
  useSpellcheck?: boolean;
  reset?: any;
}
export const RichText = (
  { onRichTextChange, richText, padding, reset, spaceBetween, useSpellcheck = true }: RichTextProps
) => {
  const [editorState, setEditorState] = useState(getEditorState(richText));
  const { data } = useTokens();

  const editorRef = useRef<Editor>(null);

  useEffect(() => {
    if (!reset) return;
    setEditorState(EditorState.push(editorState, ContentState.createFromText(''), 'remove-range'));
  }, [reset]);

  const onChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
    const newText = editorStateToText(newEditorState);
    onRichTextChange(newText);
  };

  const onToggleInline = (style: string) => {
    const result = RichUtils.toggleInlineStyle(editorState, style);
    onChange(result);
  };

  const onToggleBlock = (style: string) => {
    const result = RichUtils.toggleBlockType(editorState, style);
    onChange(result);
  };

  const onToggleLink = (urlValue: string) => {
    const contentState = editorState.getCurrentContent();

    const startKey = editorState.getSelection().getStartKey();
    const startOffset = editorState.getSelection().getStartOffset();
    const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
    const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
    let url = '';
    if (linkKey) {
      const linkInstance = contentState.getEntity(linkKey);
      url = linkInstance.getData().url;
    }

    let link;
    while (!(link?.startsWith('http://') || link?.startsWith('https://'))) {
      link = prompt('Please enter link (must begin with http:// or https://)', link ?? url);
      if (!link) return;
    }

    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      { url: link }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    // Apply entity
    let nextEditorState = EditorState.set(editorState,
      { currentContent: contentStateWithEntity });

    // Apply selection
    nextEditorState = RichUtils.toggleLink(nextEditorState,
      nextEditorState.getSelection(), link ? entityKey : null);

    onChange(nextEditorState);
  };

  const handleKeyCommand = (command: any) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return true;
    }
    return false;
  };

  const handleTab = (evt: React.KeyboardEvent<{}>) => {
    evt.preventDefault();
    const newState = RichUtils.onTab(evt, editorState, 4);
    if (newState) {
      onChange(newState);
      return true;
    }
    return false;
  };

  // mention stuff

  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState<MentionData[]>([]);

  useEffect(() => {
    if (data) setSuggestions(data.map(t => ({ name: `[${t}]` })));
  }, [data]);

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);

  const onSearchChange = useCallback(({ value }: { value: string }) => {
    setSuggestions(defaultSuggestionsFilter(value, suggestions));
  }, [suggestions]);

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      mentionTrigger: '[',
      supportWhitespace: true,
      /*
      mentionComponent(mentionProps) {
        return (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <span
            className={mentionProps.className}
            // eslint-disable-next-line no-alert
            onClick={() => console.log('Clicked on the Mention!', mentionProps)}
          >
            <span>{`[${mentionProps.mention.token}`}</span>
            <span style={{ display: 'none' }}>
              {mentionProps.mention.token}
            </span>
          </span>
        );
      }
      */
    });
    // eslint-disable-next-line no-shadow
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { MentionSuggestions } = mentionPlugin;
    // eslint-disable-next-line no-shadow
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  return (
    <>
      <TextStyleRow>
        <InlineStyleControls onToggle={onToggleInline} editorState={editorState} />
        <BlockStyleControls onToggle={onToggleBlock} editorState={editorState} />
        <LinkStyleControls onToggle={onToggleLink} editorState={editorState} />
      </TextStyleRow>
      <TextEditor padding={padding} spaceBetween={spaceBetween}>
        <TextView onClick={() => editorRef.current?.focus()}>
          <Editor
            ref={editorRef}
            editorState={editorState}
            handleKeyCommand={handleKeyCommand as any}
            onChange={onChange}
            onTab={handleTab}
            spellCheck={useSpellcheck}
            blockStyleFn={customBlockStyleFn}
            plugins={plugins}
          />
          <MentionSuggestions
            open={open}
            onOpenChange={onOpenChange}
            suggestions={suggestions}
            onSearchChange={onSearchChange}
            entryComponent={Token}
          // onAddMention={onAddToken}
          />
        </TextView>
      </TextEditor>
      {/* <pre>
        {editorStateToText(editorState)}
      </pre> */}
    </>
  );
};
