import { useSWREndpoint } from '@/hooks/useSWREndpoint';
import { WarWeb } from '@/war';
import Qs from 'qs';
import { deleteData, postData } from './utility/simpleFetch';

const basepath = 'knrterms';

export const useKnRTermsForFleet = (fleetId?: number) => useSWREndpoint<WarWeb.KnrTermResponse[]>(fleetId ? `${basepath}?${Qs.stringify({ fleetId })}` : null);

export const upsertKnrTerms = async (params: WarWeb.KnrTermUpsertRequest) => postData(basepath, params);

export const deleteKnRTerm = async (params: WarWeb.KnrTermTarget) => deleteData(basepath, params);
