import { Routes } from '@/components/routing/routes';
import { AgreementTypeEnum } from '@/models/Agreement';
import { TargetType } from '@/models/TargetType';
import React, {
  createContext, FC, useContext, useMemo, useState
} from 'react';
import { useLocation } from 'react-router';

const AgreementsPageContext = createContext<IAgreementsPageContext | undefined>(undefined);

export interface AgreementSummaryTarget {
  areaIds?: number[];
  vesselTypeIds?: number[];
  agreementIds: number[];
  targetType: TargetType;
  fleetId?: number; // Required when requesting summary with unspecified target type, not used otherwise
  daysCoveredGroup?: number;
  minimumSumInsured?: number;
  vesselIds?: number[];
  hasMultipleSets: boolean; // If true, the agreement has multiple alternatives
}
interface CreateAgreementParams {
  areaIds: number[];
}

interface IAgreementsPageContext {
  agreementType: AgreementTypeEnum;
  isBulkEdit: boolean;
  setIsBulkEdit: React.Dispatch<React.SetStateAction<boolean>>;
  agreementSummaryTarget: AgreementSummaryTarget;
  setAgreementSummaryTarget: React.Dispatch<React.SetStateAction<AgreementSummaryTarget>>;
  createAgreementParams: CreateAgreementParams;
  setCreateAgreementParams: React.Dispatch<React.SetStateAction<CreateAgreementParams>>;
  showDeleteStatus: boolean;
  setShowDeleteStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const AgreementsPageProvider: FC = ({ children }) => {
  const location = useLocation();
  const locationIncludesDefault = useMemo(() => location.pathname.includes(Routes.termsDefault), [location.pathname]);
  const agreementType = useMemo(() => locationIncludesDefault ? AgreementTypeEnum.Standard : AgreementTypeEnum.Specific, [locationIncludesDefault]);

  const [isBulkEdit, setIsBulkEdit] = useState(false);
  const [showDeleteStatus, setShowDeleteStatus] = useState(false);

  const [agreementSummaryTarget, setAgreementSummaryTarget] = useState<AgreementSummaryTarget>({
    agreementIds: [],
    targetType: TargetType.Unspecified,
    hasMultipleSets: false
  });
  const [createAgreementParams, setCreateAgreementParams] = useState<CreateAgreementParams>({ areaIds: [] });

  const value = useMemo(
    () => (
      {
        agreementType,
        isBulkEdit,
        setIsBulkEdit,
        agreementSummaryTarget,
        setAgreementSummaryTarget,
        createAgreementParams,
        setCreateAgreementParams,
        showDeleteStatus,
        setShowDeleteStatus
      }),
    [agreementType, isBulkEdit, agreementSummaryTarget, createAgreementParams, showDeleteStatus]
  );

  return (
    <AgreementsPageContext.Provider value={value}>
      {children}
    </AgreementsPageContext.Provider>
  );
};

const useAgreementsPageContext = () => {
  const context = useContext(AgreementsPageContext);

  if (context === undefined) {
    throw new Error('useAgreementsPageContext must be used within a AgreementsPageProvider');
  }
  return context;
};

export { AgreementsPageProvider, useAgreementsPageContext };
