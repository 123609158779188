import { Routes } from '@/components/routing/routes';
import { Box } from '@/components/shared/Box';
import { CustomSelect, CustomSelectValue } from '@/components/shared/Form/fields/CustomSelect';
import {
  NoDataRow,
  Table, TableCell, TableHeader, TableRow
} from '@/components/shared/Table/Table';
import {
  AgreementTypeEnum, RateItemSummary, ValueSummary
} from '@/models/Agreement';
import { TargetType } from '@/models/TargetType';
import { useAgreements } from '@/services/agreementsService';
import { ShorthandTiers } from '@/utility/agreementTiers';
import { getDifference } from '@/utility/array';
import { minus } from '@/utility/arrayHelpers';
import { excludeFixedContractType } from '@/utility/contractTypes';
import { formatInteger, formatPercentage } from '@/utility/formatter';
import { theme } from '@/utility/theme';
import { WarWeb } from '@/war';
import { Export } from '@instech/icons';
import QueryString from 'qs';
import React, {
  FC, useEffect, useState
} from 'react';
import { ActionMeta, SingleValue } from 'react-select';
import styled from 'styled-components';
import { Button } from '../../shared/Button';
import { useAgreementDataContext } from '../agreementDetails/AgreementDataContext';
import { useAgreementSelectionContext } from '../agreementDetails/AgreementSelectionContext';

const Content = styled.div`
   padding: 1em;
 `;
const SummaryItem = styled.div`
   padding-top: 1.5em;
 `;
const ButtonWrapper = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
 `;
const StyledExport = styled(Export)`
   width: 12px;
   opacity: 0.7;
   cursor: pointer;
 `;
interface DefaultTermsSummaryProps {
  areaId: number;
}
const DefaultTermsSummary: FC<DefaultTermsSummaryProps> = ({ areaId }) => {
  const { setCoverage, setPremium } = useAgreementDataContext();
  const { data, isValidating: isLoading, error } = useAgreements(
    areaId ? { areaIds: [areaId], targetType: TargetType.Standard } : undefined,
    AgreementTypeEnum.Standard,
    'rateGuideSummary'
  );

  const target = { areaIds: areaId, targetType: TargetType.Standard };
  const params = QueryString.stringify(target, { indices: false });

  const WarHullMachineryRate = 2;

  const getTierAndMakeDirty = <T,>(obj: (ValueSummary<T> | RateItemSummary)[], tier?: string) =>
    obj.filter(t => t.tier === tier).map(v => ({ ...v, isDirty: true }));

  const populate = (tier?: WarWeb.TierType) => {
    if (!data) return;
    setCoverage({
      quoteValidHours: {
        ...data.coverage.quoteValidHours,
        tier: undefined,
        isDirty: true
      },
      daysCovered: {
        ...data.coverage.daysCovered,
        tier: undefined,
        isDirty: true
      },
      maximumDaysCovered: {
        ...data.coverage.maximumDaysCovered,
        tier: undefined,
        isDirty: true
      },
      maximumProRataDays: { ...data.coverage.maximumProRataDays, isDirty: true, tier: undefined },
      contractType: {
        ...data.coverage.contractType,
        value: excludeFixedContractType(data.coverage.contractType.value),
        rangeMinimum: undefined,
        rangeMaximum: undefined,
        tier: undefined,
        isDirty: true
      }
    });

    setPremium({
      defaultRates: [...data.defaultRates.filter(t => t.tier === tier).map(v => ({ ...v, isDirty: true, tier: undefined }))],
      additionalRates: [...data.additionalRates.filter(t => t.tier === tier).map(v => ({ ...v, isDirty: true, tier: undefined }))],
      rebates: [...data.rebates.filter(t => t.tier === (tier === 'Minimum' ? 'Maximum' : tier))
        .map(v => ({ ...v, value: v.value ?? 0, isDirty: true, tier: undefined }))],
      knrRebates: [...data.knrRebates.filter(t => t.tier === (tier === 'Minimum' ? 'Maximum' : tier))
        .map(v => ({ ...v, value: v.value ?? 0, isDirty: true, tier: undefined }))]
    });
  };

  const getDetailsLink = (tab: string) => `${Routes.termsDefaultDetails}/${tab}?${params}`;

  return (
    data ? (
      <>
        <SummaryItem>
          <Table columns={3} striped>
            <TableHeader>Premium</TableHeader>
            <TableHeader>War H&M</TableHeader>
            <TableHeader />
            {data?.defaultRates.filter(rate => rate.id === WarHullMachineryRate && rate.tier !== 'Maximum').map(rate => (
              <TableRow key={`${rate.tier}-HM`}>
                <TableCell>{ShorthandTiers[(rate.tier as WarWeb.TierType)]}</TableCell>
                <TableCell right>{formatPercentage(rate.value)}</TableCell>
                <TableCell center hover onClick={() => populate(rate.tier)}><StyledExport /></TableCell>
              </TableRow>
            ))}
          </Table>
          <ButtonWrapper>
            <Button to={getDetailsLink('Premium & rebates')} target="_blank" thin>View</Button>
          </ButtonWrapper>
        </SummaryItem>
        <SummaryItem>
          <Table columns={2} striped>
            <TableHeader>Rebates</TableHeader>
            <TableHeader>Maximum</TableHeader>
            {data?.rebates.filter(rebate => rebate.tier === 'Maximum').map(rebate => (
              <TableRow key={rebate.id}>
                <TableCell>{rebate.shortName}</TableCell>
                <TableCell right>{rebate.value ? formatPercentage(rebate.value) : 'n/a'}</TableCell>
              </TableRow>
            ))}
          </Table>
          <ButtonWrapper>
            <Button to={getDetailsLink('Premium & rebates')} target="_blank" thin>View</Button>
          </ButtonWrapper>
        </SummaryItem>
        <SummaryItem>
          <Table columns={2} striped>
            <TableHeader>K&R (USD)</TableHeader>
            <TableHeader>Maximum</TableHeader>
            {data?.knrRebates.filter(rebate => rebate.tier === 'Maximum').map(rebate => (
              <TableRow key={rebate.id}>
                <TableCell>{rebate.shortName}</TableCell>
                <TableCell right>{rebate.value ? formatPercentage(rebate.value) : 'n/a'}</TableCell>
              </TableRow>
            ))}
          </Table>
          <ButtonWrapper>
            <Button to={getDetailsLink('Premium & rebates')} target="_blank" thin>View</Button>
          </ButtonWrapper>
        </SummaryItem>
        <SummaryItem>
          <Table columns={3} striped>
            <TableHeader>Coverage</TableHeader>
            <TableHeader>Quote valid hours</TableHeader>
            <TableHeader>Days covered</TableHeader>
              <TableRow>
                <TableCell/>
                <TableCell right>{formatInteger(data.coverage.quoteValidHours.value)}</TableCell>
                <TableCell right>{formatInteger(data.coverage.daysCovered.value)}</TableCell>
              </TableRow>
          </Table>
          <ButtonWrapper>
            <Button to={getDetailsLink('Coverage')} target="_blank" thin>View</Button>
          </ButtonWrapper>
        </SummaryItem>
      </>
    ) : (
      <NoDataRow loadStatus={{ loading: isLoading, success: !error }} />
    )
  );
};

export const RateGuide = () => {
  const { areaIds, areaIdsTimeExceptions } = useAgreementSelectionContext();
  const excludedAreaIds = getDifference(areaIds, areaIdsTimeExceptions || []);
  // TODO: Clean up target/key mix with component above
  const { data, mutate } = useAgreements(excludedAreaIds.length > 0 ?
    { areaIds: excludedAreaIds, targetType: TargetType.Standard } : undefined, AgreementTypeEnum.Standard, 'rateGuide');
  const [selectedPorts, setSelectedPorts] = useState<WarWeb.AreaSummary[]>();
  const [displayId, setDisplayId] = useState<number>();

  useEffect(() => {
    if (data) {
      const newAreaId = minus(excludedAreaIds, selectedPorts?.map(x => x.areaId)) ?? [];
      setSelectedPorts(data.targets.areas.filter(x => x.areaType !== 'NonExcluded'));
      setDisplayId(newAreaId.length > 0 ? newAreaId[0] : data.targets.areas.filter(x => x.areaType !== 'NonExcluded')[0]?.areaId);
    } else if (excludedAreaIds.length === 0) {
      setSelectedPorts(undefined);
      setDisplayId(undefined);
    }
  }, [data, areaIds]);

  const changePort = (input: SingleValue<CustomSelectValue>, action: ActionMeta<CustomSelectValue>) => {
    setDisplayId(input?.value);
    void mutate();
  };

  const options = selectedPorts?.map(p => ({ label: p.name, value: p.areaId }));
  const defaultValue = options?.find(x => x.value === displayId);

  const styles = {
    control: (provided: any) => ({
      ...provided,
      minWidth: 0
    })
  };

  return (
    <Box header="Rate guidance" highlight={theme.marineBlue50}>
      <Content>
        {selectedPorts && selectedPorts.length > 0 && (
          <>
            <CustomSelect name="ports"
              options={options}
              onChange={changePort}
              value={defaultValue}
              styles={styles}
            />
            {displayId && <DefaultTermsSummary areaId={displayId} />}
          </>
        )}
      </Content>
    </Box>
  );
};
