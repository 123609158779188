import { ChildrenProps } from '@/models/utils/ChildrenProps';
import { WarWeb } from '@/war';
import React, {
  createContext, useContext, useMemo, useState
} from 'react';
import { useLocation } from 'react-router';

const AgreementDetailsContext = createContext<IAgreementsDetailsContext | undefined>(undefined);

export enum AgreementViewMode {
  Inspect = 'Inspect',
  Edit = 'Edit',
  Create = 'Create',
}

interface IAgreementsDetailsContext {
  viewMode: AgreementViewMode;
  setViewMode: (m: AgreementViewMode) => void;
  isBulkChangingAreas: boolean;
  setIsBulkChangingAreas: React.Dispatch<React.SetStateAction<boolean>>;
  targetsSummary: WarWeb.TargetsSummary;
  setTargetsSummary: React.Dispatch<React.SetStateAction<WarWeb.TargetsSummary>>;
  mutateAgreement: () => void;
  setMutateAgreement: React.Dispatch<React.SetStateAction<() => void>>;
  history?: WarWeb.HistoricSummary;
  setHistory: React.Dispatch<React.SetStateAction<WarWeb.HistoricSummary | undefined>>;
  comment: string;
  setComment: React.Dispatch<React.SetStateAction<string>>;
  agreementName: string;
  setAgreementName: React.Dispatch<React.SetStateAction<string>>;
  agreementErrorMessage: string;
  setAgreementErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}

const AgreementDetailsProvider = ({ children }: ChildrenProps) => {
  const [isBulkChangingAreas, setIsBulkChangingAreas] = useState(false);
  const location = useLocation();
  const [viewMode, setViewMode] = useState<AgreementViewMode>(
    location.pathname.includes('create') ? AgreementViewMode.Create : AgreementViewMode.Inspect
  );
  const [targetsSummary, setTargetsSummary] = useState<WarWeb.TargetsSummary>({
    agreementIds: [],
    areas: [],
    totalTargetCount: 0,
    vesselTypes: [],
    vessels: [],
  });
  const [agreementName, setAgreementName] = useState('');
  const [agreementErrorMessage, setAgreementErrorMessage] = useState('');
  const [comment, setComment] = useState('');
  const [mutateAgreement, setMutateAgreement] = useState(() => () => {});
  const [history, setHistory] = useState<WarWeb.HistoricSummary>();

  const value = useMemo(
    () => ({
      viewMode,
      setViewMode,
      isBulkChangingAreas,
      setIsBulkChangingAreas,
      targetsSummary,
      setTargetsSummary,
      mutateAgreement,
      setMutateAgreement,
      history,
      setHistory,
      comment,
      setComment,
      agreementName,
      setAgreementName,
      agreementErrorMessage,
      setAgreementErrorMessage
    }),
    [viewMode, isBulkChangingAreas, targetsSummary, mutateAgreement, history, comment, agreementName, agreementErrorMessage]
  );

  return <AgreementDetailsContext.Provider value={value}>{children}</AgreementDetailsContext.Provider>;
};

const useAgreementDetailsContext = () => {
  const context = useContext(AgreementDetailsContext);

  if (context === undefined) {
    throw new Error('useAgreementDetailsContext must be used within a AgreementDetailsProvider');
  }
  return context;
};

export { AgreementDetailsProvider, useAgreementDetailsContext };
