import { Routes } from '@/components/routing/routes';
import { WarningFilled } from '@instech/icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FlexBox } from '../shared/FlexBox';

const Main = styled.div`
  background-color: ${props => props.theme.whiteBlue};
  background-size: cover;
  min-height: 100vh;
`;

const Toolbar = styled(FlexBox)`
  background-color: ${props => props.theme.marineBlue};
  width: 100vw;
  min-height: 100px;
`;

const ContentWrapper = styled(FlexBox)`
  min-height: calc(100vh - 100px);
`;

const StyledLogo = styled.img`
  path {
    fill: white;
  }
  color: ${props => props.theme.white};
  height: 64px;
  width: 130px;
`;

const StyledWarning = styled(WarningFilled)`
  path {
    fill: ${props => props.theme.marineBlue};
  }
  height: 60px;
  width: 60px;
`;

const Content = styled.div`
  margin-top: 10px;
  font-size: ${props => props.isSmall ? '18px' : '24px'};
  font-weight: bold;
  text-align: center;
`;

const StyledLink = styled(Link)`
  margin-top: 10px;
  font-size: ${props => props.isSmall ? '18px' : '24px'};
  text-decoration: underline;
  text-align: center;
  justify-self: center;
`;

const Spacing = styled.div`
  padding: 1rem 0;
`;

export const NoAccessPage = () => (
  <Main>
    <Toolbar center>
      <StyledLogo src="images/NHC_logo.svg" alt="NHC logo" />
    </Toolbar>
    <ContentWrapper center flexDirection="column">
      <div>
        <StyledWarning />
      </div>
      <Content>Sorry, you do not have access to this page</Content>
      <StyledLink to={Routes.logout}>Sign out</StyledLink>
      <Spacing />
      <StyledLink isSmall to={Routes.base}>Or you could try to go back to the main page</StyledLink>
    </ContentWrapper>
  </Main>
);
