import React, {
  useState, useEffect, useCallback
} from 'react';
import styled, { css, keyframes } from 'styled-components';
import { MenuDots } from '@instech/icons';
import ReactTooltip from 'react-tooltip';
import {
  Button, ButtonShape, IconContainer
} from '@/components/shared/Button';
import { theme } from '@/utility/theme';
import { MenuItem } from '@/models/utils/MenuItem';

const fadeIn = keyframes`
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity:1;
  }
  to {
    opacity:0;
  }
`;

interface MenuState {
  open: boolean,
  closing?: boolean
}
const Container = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 30px;
  width: 100px;
`;

const ButtonGroup = styled.div<{ menuState: MenuState }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  svg {
    ${props => props.menuState.open && css`
        animation: ${fadeIn} 1s;
    `}
  }

  svg {
    ${props => props.menuState.closing && css`
        animation: ${fadeOut} 1s;
    `}
  }

  ${ButtonShape} {
    margin-right: 0rem;
    padding: 5px;

    &:hover { 
      background-color: ${props => props.theme.green};
    }
  }
  ${IconContainer} {
    margin-right: 0rem;
    padding: 0;
  }
`;

const StyledTooltip = styled(ReactTooltip)`
  font-size: 14px;
  text-transform: uppercase;
  padding: 0.3rem 0.8rem;
  letter-spacing: 0.05em;
  font-weight: 500;
`;

interface ButtonMenuProps {
  items: MenuItem[],
  open?: boolean
}
export const ButtonMenu = ({ items, open = false }: ButtonMenuProps) => {
  const [menuState, setMenuState] = useState<MenuState>({ open });

  useEffect(() => {
    setMenuState({ open });
  }, [open]);

  const closeMenuNow = useCallback(() => {
    setMenuState({ open: false });
  }, []);

  const closeMenu = useCallback(() => {
    setTimeout(closeMenuNow, 1000);
  }, [closeMenuNow]);

  useEffect(() => {
    if (menuState.closing) closeMenu();
  }, [menuState, closeMenu]);

  const openMenu = () => {
    setMenuState({ open: true });
  };

  const handleClick = (e: any) => {
    e.preventDefault();
    openMenu();
    setTimeout(closeMenuNow, 10000);
  };

  return (
    <Container>
      {menuState.open ? (
        <ButtonGroup onMouseLeave={() => setMenuState({ ...menuState, closing: true })} menuState={menuState}>
          {items.map(item => (
            <div key={item.id}>
              <div data-tip={item.name}>
                {item.onClick
                  ? <Button tabIndex={0} key={item.name} onClick={item.onClick} icon={item.icon} target={item.target} />
                  : <Button tabIndex={0} key={item.name} to={item.route} href={item.href} icon={item.icon} target={item.target} />}
              </div>
              <StyledTooltip className="tooltip" place="top" type="info" textColor={theme.white} backgroundColor={theme.marineBlue} effect="solid" />
            </div>
          ))}
        </ButtonGroup>
      )
        : (
          <MenuDots onClick={handleClick} onMouseEnter={openMenu} />
        )}
    </Container>
  );
};
