import { InlineModalProps } from '@/components/modal/InlineModalProps';
import { ModalLayout } from '@/components/modal/ModalLayout';
import { Button, ButtonWithLoader } from '@/components/shared/Button';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { theme } from '@/utility/theme';
import { WarWeb } from '@/war';
import { Check, WarningFilled } from '@instech/icons';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 2rem;
  text-align: center;
`;

const StyledWarning = styled(WarningFilled)`
  path {
    fill: ${props => props.theme.marineBlue};
  }
  height: 60px;
  width: 60px;
  margin: 3rem;
`;

const DeleteHeader = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 2rem;
`;

const DeleteDescription = styled.div`
  margin-bottom: 2rem;
`;

const VesselContainer = styled.span`
  white-space: nowrap; 
`;

interface Props extends Required<InlineModalProps> {
  vessel: WarWeb.MarineMatch;
  agreementCount: number;

}
export const AddFleetSpecificTermsConfirmationModal = ({ vessel, agreementCount, apply, closeModal }: Props) => {
  const options = {
    size: 'small',
    clickOutsideToClose: true,
    draggable: true
  };

  const submitHandler = async () => {
    await apply();
    closeModal();
  };

  return (
    <ModalLayout closeModal={closeModal} options={options}>
      <Container>
        <StyledWarning />
        <DeleteHeader>
          Adding Fleet Specific T&C
        </DeleteHeader>
        <DeleteDescription>
          You are adding
          {' '}
          {agreementCount}
          {' '}
          Fleet specific T&C agreements to
          {' '}
          <VesselContainer>{vessel.name}</VesselContainer>
          <p />
          The agreements will be available for use immediately. The agreements might take up to five minutes to display in the Fleet Overview
        </DeleteDescription>
        <ButtonGroup justify="space-between">
          <Button background={theme.ultraLightGray} onClick={closeModal}>Cancel</Button>
          <ButtonWithLoader background={theme.green} icon={<Check />} onClick={submitHandler}>Apply</ButtonWithLoader>
        </ButtonGroup>
      </Container>
    </ModalLayout>
  );
};
