import React, { FC } from 'react';
import { Route, Switch } from 'react-router';
import { BaseLayout } from '../layout/BaseLayout';
import { AcceptTermsPage } from '../pages/acceptTerms/AcceptTermsPage';
import { CallbackPage } from '../pages/authentication/CallbackPage';
import { LoginPage } from '../pages/authentication/LoginPage';
import { LogoutPage } from '../pages/authentication/LogoutPage';
import { NoAccessPage } from '../pages/NoAccessPage';
import { ProtectedRoute } from './ProtectedRoute';
import { Routes } from './routes';

export const RootRouting: FC = ({ children }) => (
  <Switch>
    <Route exact path={Routes.login}>
      <LoginPage />
    </Route>
    <Route exact path={Routes.callback}>
      <CallbackPage />
    </Route>
    <Route exact path={Routes.logout}>
      <LogoutPage />
    </Route>
    <Route exact path={Routes.noAccess}>
      <NoAccessPage />
    </Route>
    <ProtectedRoute exact path={Routes.termsOfUse}>
      <AcceptTermsPage />
    </ProtectedRoute>

    <ProtectedRoute>
      {children}
    </ProtectedRoute>
  </Switch>
);
