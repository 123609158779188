import React, {
  createContext, PropsWithChildren, useContext, useMemo
} from 'react';

const MenuContext = createContext<MenuProviderProps>({
  isOpen: false,
  setIsOpen: () => { }
});

interface MenuProviderProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}
export const MenuProvider = ({
  isOpen,
  setIsOpen,
  children
}: PropsWithChildren<MenuProviderProps>) => {
  const value = useMemo(() => (
    {
      isOpen,
      setIsOpen
    }
  ), [isOpen, setIsOpen]);

  return (
    <MenuContext.Provider value={value}>
      {children}
    </MenuContext.Provider>
  );
};

export const useMenuContext = () => useContext(MenuContext);
