import { PaginationBar } from '@/components/pages/common/PaginationBar';
import { FilterableTableHeadersProvider, useFilterableTableHeadersContext } from '@/components/pages/defaultTermsConditions/FilterableTableHeadersContext';
import { Box } from '@/components/shared/Box';
import { Filters } from '@/models/Filters';
import { SortedHeader } from '@/models/SortOrder';
import { useBows } from '@/services/bowServices';
import { bowStatus, Status } from '@/utility/bowStatus';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { FilterBar } from '../../FilterBar';
import { FilterButton } from '../../FilterButton';
import { TableColumnHeader } from '../../Table/core/Components';
import { BowTable } from './BowTable';

const StyledBox = styled(Box)`
  margin-bottom: 2rem;
`;

const BoxContent = styled.div<{ noHeader?: boolean }>`
  ${props => !props.noHeader && css`
    border-top: 5px solid ${props.theme.border.lightGreen};
    border-radius: 5px;
    background: ${props.theme.white};
  `};
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1em 0;
  margin: 0 0.5em;
`;

const StatusButtons = styled.div`
  display: flex;
`;

interface BowFiltersProps {
  statusButtons?: boolean;
}

const BowFilters = ({ statusButtons }: BowFiltersProps) => {
  const { headers, filters, resetFilters, toggleFacet } = useFilterableTableHeadersContext();

  const filterApplied = (parameterName: string, facet: string) => filters[parameterName]?.includes(facet);

  const searchableFields = Object.keys(filters)
    .filter(parameterName => headers.find(header => header.parameterName === parameterName))
    .filter(parameterName => parameterName !== 'SelectedStatus')
    .filter(parameterName => !!filters[parameterName]);
    return (
    <FilterContainer>
      <StatusButtons>
        {statusButtons && bowStatus?.filter(f => f.status !== Status.Discarded).map(status => (
          <FilterButton
            key={status.title}
            label={status.title === "PendingConfirmation" ? 'Pending' : status.title}
            toggle={() => toggleFacet!('SelectedStatus', status.title)}
            selected={filterApplied('SelectedStatus', status.title)}
            color={status.color}
            iconHeight={16}
            leftIcon={status.smallIcon} />
        ))}
      </StatusButtons>

      <StatusButtons>
        <FilterBar searchableFields={searchableFields} />
      </StatusButtons>
    </FilterContainer>
  );
};

interface BowsBoxProps {
  highlight?: string;
  title?: string;
  category?: string;
  initialFilters: Filters;
  tabFilters?: Filters;
  filters: Filters;
  setFilters: (filters: Filters) => void;
  headers: TableColumnHeader[];
  initialSorting?: any;
  noDataDisplay?: React.ReactNode;

  config?: {
    paging?: boolean;
    noHeader?: boolean;
    counterInTitle?: boolean;
    resizeColumns?: boolean;
    statusButtons?: boolean
    filterButtons?: boolean;
    action?: {
      callback?: () => void;
      label: string | React.ReactElement;
    };
    hasMoreDataCallback?: (hasMoreData: boolean) => void;
  }
}
export const BowsBox = ({
  highlight,
  title, category, initialFilters, tabFilters, filters, setFilters,
  headers, initialSorting, noDataDisplay, config
}: BowsBoxProps) => {
  const [sortedHeader, setSortedHeader] = useState<SortedHeader | undefined>(initialSorting);

  const currentFilters = {
    ...initialFilters,
    ...tabFilters,
    ...filters
  };
  
  const { data: bows, isLoading, error } = useBows(currentFilters, JSON.stringify(currentFilters));

  const moreDataExists = !!bows?.pagingMetadata?.totalCount && bows?.pagingMetadata?.totalCount > currentFilters.pageSize;
  const formattedTitle = `${title}${(config?.counterInTitle && bows) ? ` - ${bows.pagingMetadata.totalCount}` : ''}`;

  if (config?.hasMoreDataCallback) config.hasMoreDataCallback(moreDataExists);

  return (
    <FilterableTableHeadersProvider
      headers={headers}
      category={category}
      columnMetadata={bows?.columnMetadata}
      initialFilters={initialFilters}
      filters={filters}
      sortedHeader={sortedHeader}
      setSortedHeader={setSortedHeader}
      setFilters={setFilters}
      isLoading={isLoading}
    >

      <StyledBox
        highlight={highlight}
        header={title ? formattedTitle : undefined}
        action={config?.action}
      >

        {(config?.filterButtons || config?.statusButtons) && (
          <BoxContent noHeader={config?.noHeader}>
            <BowFilters statusButtons={config?.statusButtons} />
          </BoxContent>
        )}

        <BowTable
          noHeader={config?.noHeader}
          resizeColumns={config?.resizeColumns}
          bows={bows}
          loadStatus={{ loading: isLoading, success: !error }}
          noDataDisplay={noDataDisplay}
        />

      </StyledBox>

      {config?.paging && bows?.pagingMetadata && (
        <PaginationBar
          currentPage={+currentFilters.pageNumber}
          metadata={bows.pagingMetadata}
          changePage={newPage => setFilters({ ...filters, pageNumber: `${newPage}` })}
        />
      )}
    </FilterableTableHeadersProvider>
  );
};
