import { useMe } from '@/services/meService';
import { useSections } from '@/services/sectionsSevice';
import { theme } from '@/utility/theme';
import { WarWeb } from '@/war';
import { DropdownControlled } from '@instech/components';
import React, { useEffect, useState } from 'react';
import { Theme } from 'react-select';
import styled from 'styled-components';

const DropdownWrapper = styled.span`
  z-index: 10;

  > div {
    width: 100%;
    cursor: pointer;
  }
`;

interface SectionDropdownProps {
  underwriterSection?: WarWeb.Section;
  setUnderwriterSection: React.Dispatch<React.SetStateAction<WarWeb.Section | undefined>>;
}

export const SectionDropdown = ({ underwriterSection, setUnderwriterSection }: SectionDropdownProps) => {
  const { data: me } = useMe();
  const { data } = useSections();

  const [sections, setSections] = useState<WarWeb.Section[]>();

  const allSections = {
    id: 0,
    code: '',
    name: 'All Sections'
  };

  useEffect(() => {
    setSections([allSections].concat(data || []));
  }, [data]);

  useEffect(() => {
    setUnderwriterSection(sections?.find(u => u.code === (me?.sectionCode)));
  }, [me, setUnderwriterSection]);

  const handleChange = (val: any) => {
    setUnderwriterSection(sections?.find(u => u.code === val.value));
  };

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      minWidth: 0,
      width: '300px',
      border: `1px solid ${theme.marineBlue50}`,
      borderRadius: '2px',
      height: '40px'
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: theme.marineBlue,
    }),
  };

  return (
    <DropdownWrapper>
      <DropdownControlled
        name="underwriterSection"
        value={underwriterSection ? { label: underwriterSection.name, value: underwriterSection.code } : { label: allSections.name, value: allSections.code }}
        options={sections?.map(s => ({ label: s.name, value: s.code }))}
        onChange={handleChange}
        tabIndex={0}
        styles={customStyles}
        theme={(defaultTheme: Theme): Theme => ({
          ...defaultTheme,
          colors: {
            ...defaultTheme.colors,
            primary: theme.mediumBlue,
          }
        })}
      />
    </DropdownWrapper>
  );
};
