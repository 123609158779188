import { useTermsOfUse } from '@/services/termsOfUseServices';
import { Pane } from '@instech/components';
import { Loader } from '@instech/components';
import { RichTextView } from '@/components/shared/Form/RichTextForm/RichTextView';

export const TermsPane = () => {
  const termsText = useTermsOfUse();

  return (
    <Pane margin="32px 0px 0px">
      {!termsText ? (
        <Loader />
      ) : (
        <RichTextView text={termsText} />
      )}
    </Pane>
  );
};
