import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  getDelimiters, getHeaders, SpanCell, TableCell, TableRow
} from '@/components/shared/Table/core/Components';
import {
  FilterableTableHeaders,
  NoDataRow, Table
} from '@/components/shared/Table/Table';
import { Filters } from '@/models/Filters';
import { SortedHeader } from '@/models/SortOrder';
import { basepath as portsEndpoint } from '@/services/portsService';
import { useHighRiskAreas } from '@/services/highRiskAreasService';
import { WarWeb } from '@/war';
import { Box } from '@/components/shared/Box';
import { SelectionBar } from '@/components/shared/SelectionBar';
import { HiddenHeader } from '@/components/shared/Table/core/FilterableTableHeaders';
import { useExcludedAreas } from '@/services/excludedAreasService';
import { concatNullsafe } from '@/utility/arrayHelpers';
import { useEditExcludedAreasContext } from '../EditExcludedAreasContext/EditExcludedAreasContext';
import { FilterableTableHeadersProvider } from '../../../defaultTermsConditions/FilterableTableHeadersContext';
import { isChecked } from '../utils';
import { PortRow } from './PortRow';
import { ExcludedPort } from '../types';
import { tableSections } from './tableHeaders';
import { usePortsSearch, useUpdateSelectedPorts } from './hooks';
import { PortFilters } from './PortFilters';
import { SearchBoxContainer } from './Components';

const FilterContainer = styled.div`
  display: flex;  
`;

const StyledBox = styled(Box)`
  margin-bottom: 2rem;
`;

const magicMaxRowsInChromiumTable = 500;
const defaultFilters = { pageNumber: '1', pageSize: `${magicMaxRowsInChromiumTable - 2}`, orderBy: 'CountryName, PortName' };

export const PortsTable = () => {
  const {
    state: {
      areaId,
      regionId,
      selectedPorts,
      oceanAreaNames
    },
    isCustomArea,
    isNewArea,
    setSelectedPorts
  } = useEditExcludedAreasContext();

  const isDeletedArea = isCustomArea && oceanAreaNames?.some(l => l.oceanAreaId === areaId && l.action === 'Delete');

  const [filterRegionId, setFilterRegionId] = useState(regionId);

  useEffect(() => {
    setFilterRegionId(regionId);
  }, [regionId]);

  const [ports, setPorts] = useState<WarWeb.AreaMatch[]>([]);
  const [columnMetadata, setColumnMetadata] = useState<WarWeb.ColumnMetadata[]>();
  const [allPortsExcludedForArea, setAllPortsExcludedForArea] = useState<ExcludedPort[]>([]);

  const [freetext, setFreetext] = useState('');
  const [filters, setFilters] = useState<Filters>(defaultFilters);
  const [sortedHeader, setSortedHeader] = useState<SortedHeader>();

  const { data: summary } = useExcludedAreas(isCustomArea && !isNewArea ? areaId : undefined);
  const { portSearchResults, error, isLoading } = usePortsSearch(filters, isCustomArea, filterRegionId);

  const noHRAValue = 0; // actual value (in DB) is null, but UX requires a numeric value
  const { data: highRiskAreas } = useHighRiskAreas();
  const dropdownHRAs = highRiskAreas?.map(hra => ({ name: hra.name, value: hra.id.toString() }));
  dropdownHRAs?.unshift({ name: 'No HRA', value: noHRAValue.toString() });

  const updateExcludedPorts = () => {
    const landAndOceanAreaPorts = [...selectedPorts?.filter(p => p.areaId === areaId && (isChecked(p.excluded) || isChecked(p.inArea))) || []];
    setAllPortsExcludedForArea(prev => ([...prev, ...landAndOceanAreaPorts.filter(p => !prev.some(u => u.locode === p.locode))]));
  };

  useEffect(() => {
    const missingPorts = summary?.filter(p => !allPortsExcludedForArea.some(a => a.locode === p.locode))
      .map(p => ({
        locode: p.locode,
        areaId: p.oceanAreaId,
        isCustomArea: true,
        inArea: {
          persistedState: true
        },
        excluded: {
          persistedState: p.isExcluded
        },
        HRA: {
          persistedState: p.highRiskAreaId
        }
      }));
    if (!missingPorts) return;

    const updatedSet: ExcludedPort[] = concatNullsafe(allPortsExcludedForArea, missingPorts);
    setAllPortsExcludedForArea(prev => ([...prev, ...updatedSet.filter(p => !prev.some(u => u.locode === p.locode))]));
  }, [summary]);

  useEffect(() => {
    setFreetext('');
    const { selectedLocodes, ...rest } = filters;
    setFilters({ ...rest, freetext: '' });
    updateExcludedPorts();
  }, [areaId]);

  useEffect(() => {
    if (!portSearchResults) return;
    setPorts(portSearchResults.items);
    setColumnMetadata(portSearchResults.columnMetadata);
  }, [portSearchResults]);

  useUpdateSelectedPorts(isCustomArea, portSearchResults?.items);

  useEffect(() => {
    updateExcludedPorts();
  }, [selectedPorts]);

  const headers = getHeaders(tableSections);
  const layout = '1fr 2fr 1fr 2fr 1.7fr 1fr';

  const renderPorts = () => {
    if (!regionId || !areaId) return <TableRow><SpanCell start={1} end={-1}><h3>Select region and area</h3></SpanCell></TableRow>;
    if (ports?.some(x => x)) {
      return ports
        .map(p => (
          <PortRow
            key={p.areaId}
            port={p}
            dropdownHRAs={dropdownHRAs}
            isCustomArea={isCustomArea}
          />
        ));
    }
    return <NoDataRow loadStatus={{ loading: isLoading, success: !error }} />;
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFreetext(e.target.value);
    setFilters({ ...filters, freetext: e.target.value });
  };

  const header = (
    <FilterableTableHeaders
      endpoint={portsEndpoint}
      delimiters={getDelimiters(tableSections)}
    />
  );

  return (
    <FilterableTableHeadersProvider
        headers={headers}
        columnMetadata={columnMetadata}
        filters={filters}
        sortedHeader={sortedHeader}
        setSortedHeader={setSortedHeader}
        initialFilters={defaultFilters}
        setFilters={setFilters}
        isLoading={isLoading}
      >
      <SearchBoxContainer>
        <FilterContainer>
          <SelectionBar
              type="Port"
              parameterName="selectedLocodes"
              adjective="in Area"
              editMode={!isDeletedArea}
              selection={allPortsExcludedForArea.filter(t => t.areaId === areaId && (!t.isCustomArea || isChecked(t.inArea)))?.map(t => t.locode).flat()}
              selected={!!filters?.selectedLocodes && filters.selectedLocodes.length > 0 && filters.preset === 'includedPorts'}
              setter={setSelectedPorts}
            />
          <SelectionBar
              type="Port"
              parameterName="selectedLocodes"
              adjective="Excluded"
              editMode={!isDeletedArea}
              selection={allPortsExcludedForArea.filter(t => t.areaId === areaId && isChecked(t.excluded))?.map(t => t.locode).flat()}
              selected={!!filters?.selectedLocodes && filters.selectedLocodes.length > 0 && filters.preset === 'excludedPorts'}
              setter={setSelectedPorts}
            />
        </FilterContainer>
        <PortFilters
            filterRegionId={filterRegionId}
            setFilterRegionId={setFilterRegionId}
            freetext={freetext}
            handleInput={handleInput}
          />
      </SearchBoxContainer>
      <StyledBox shaded={false}>
        {isDeletedArea ? (
          <>
            <h2>Area is marked for deletion.</h2>
            <strong>
              All ports excluded to this area
              will revert to being excluded to their corresponding country.
            </strong>
          </>
        ) : (
          <>
            <Table columns={headers.length} sticky layout={layout} maxHeight="500px">
              {header}
            </Table>

            <Table columns={headers.length} striped layout={layout} maxHeight="500px">
              <HiddenHeader>
                {header}
              </HiddenHeader>

              {ports
                ? (
                  <>
                    {renderPorts()}
                    {ports?.length >= magicMaxRowsInChromiumTable - 2 && (
                    <TableRow>
                      <TableCell center span={5}><em>More data exists. Use a filter on country or port name</em></TableCell>
                    </TableRow>
                    )}
                  </>
                )
                : <NoDataRow loadStatus={{ loading: isLoading, success: !!ports }} />}
            </Table>
          </>
        )}
      </StyledBox>
    </FilterableTableHeadersProvider>
  );
};
