import { SearchFieldDropdown } from '@/components/shared/Form/FormFields';
import { IconButton } from '@instech/components';
import { Remove, WarningFilled } from '@instech/icons';
import { DropdownElement } from '@/components/shared/Form/fields/SearchResults';
import { ErrorMessage, FieldLabel } from '@/components/shared/Form/core/Components';
import { useField } from 'formik';
import { FlexBox } from '@/components/shared/FlexBox';
import styled, { css } from 'styled-components';
import { useTimeZonesWithFilters } from './useTimeZonesWithFilters';
import { TimezoneResult } from '../../types';
import { NameDescriptionElement } from '../../NameDescriptionElement';

const Label = styled(FieldLabel)`
  font-size: 14px;
`;

const ErrorComponent = ({ message }: { message: string; }) => (
  <>
    <WarningFilled />
    {message}
  </>
);

const FieldWithError = styled.div<{ error: boolean }>`
  display: contents;
  ${props => props.error && css`
    color: ${props.theme.red};
      & input {
        border-color: ${props.theme.red};
      }
    `};
`;

const renderResult = (result: TimezoneResult) =>
  <NameDescriptionElement name={result.area} description={result.timezone} />;

export const TimeZoneField = () => {
  const [field, { error, touched }, helpers] = useField('timezone');
  const { timezones, updateSearchText } = useTimeZonesWithFilters();
  const timezone = field.value;
  const isError = error !== undefined && touched;
  const setTimezone = helpers.setValue;

  return (
    <FieldWithError error={isError}>
      <Label as="label" htmlFor="timezone">
        Timezone or City
      </Label>
      {timezone ? (
        <FlexBox>
          <DropdownElement info>{renderResult(timezone)}</DropdownElement>
          <IconButton icon={<Remove />} onClick={() => setTimezone(undefined)} title="Remove" />
        </FlexBox>
      ) : (
        <>
          <SearchFieldDropdown
            name="timezone"
            onChange={e => updateSearchText(e.target.value)}
            results={timezones}
            keyField="area"
            loadStatus={{ loading: false, success: !!timezones }}
            callback={setTimezone}
            renderResult={renderResult}
            error={error} />
          <ErrorMessage>{isError ? <ErrorComponent message={error} /> : ''}</ErrorMessage>
        </>
      )}
    </FieldWithError>
  );
};
