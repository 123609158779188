import { TableSection } from '@/components/shared/Table/core/Components';

export const tableSections : TableSection[] = [
  {
    columns: [
      { title: 'Included in Area',
        noDropdown: true },
      { title: 'Name',
        propertyName: 'PortName',
        noDropdown: true },
      { title: 'Locode',
        propertyName: 'Locode',
        noDropdown: true },
      { title: 'Area',
        noDropdown: true },
      { title: 'K&R',
        noDropdown: true },
      { title: 'Excluded',
        noDropdown: true },
    ]
  }
];
