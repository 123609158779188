import { DestinationOption } from '@/components/pages/bows/details/destinationOption';
import { NoDataRow } from '@/components/shared/Table/core/Components';
import { useHighRiskAreas } from '@/services/highRiskAreasService';
import { useValidationContext } from '@/services/ValidationContext';
import { getLastElement } from '@/utility/array';
import { renderDestionationsResult } from '@/utility/destinationsDropdownHelpers';
import { fieldNameFilter } from '@/utility/errorHelpers';
import { Globals } from '@/utility/globals';
import { theme } from '@/utility/theme';
import { useEffect } from 'react';
import styled from 'styled-components';
import { useQuoteContext } from '../../BowSidebar/QuoteContext';
import { Box } from '../../Box';
import { SelectDropdown } from '../../Form/fields/SelectDropdown';
import { Middot } from '../../Middot';
import { Validator } from '../../Validator';
import { useSelectedQuote } from '../useSelectedQuote';
import { KidnapRansomDestination } from './KidnapRansomDestination';
import { RebatesTable } from './RebatesTable';

const Content = styled.div`
  padding: 1rem 1.5rem 1.5rem 1.5rem;
  display: flex;
  flex-direction: column;
`;

const Narrow = styled.div`
  width: 400px;
`;

const Split = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:first-child {
      padding-right: 2em;
    }

    &:last-child {
      border-left: 1px solid ${theme.border.sanMarino};
      padding-left: 2em;
    }
  }
`;

export const KidnapRansomBox = () => {
  const { selectedConfiguration, setSelectedConfiguration, isMultiDestination } = useQuoteContext();
  const { errors, warnings, resetWarnings } = useValidationContext();

  const { data: highRiskAreas } = useHighRiskAreas();

  const selectedQuote = useSelectedQuote();
  const coveredAreas = selectedQuote?.coveredAreas;

  const destinationsOptions = coveredAreas?.flatMap((item, coveredAreaIndex) => {
    const country: DestinationOption[] = [
      {
        id: item.id,
        type: item.coveredAreaType.toString(),
        hra:
          item.coveredAreaType === 'OceanArea'
            ? highRiskAreas?.find((hra) => hra.id === item.highRiskAreaId)?.name
            : undefined,
        name: item.name,
        coveredAreaIndex,
        entry: item.entry,
        exit: item.exit,
        isValid: item.coveredAreaType === 'OceanArea',
        hasWarnings:
          item.coveredAreaType === 'OceanArea' &&
          fieldNameFilter(warnings, `coveredAreas[${coveredAreaIndex}].Configuration.Knr`).length > 0,
        links: item.links,
      },
    ];

    const ports = item.itinerary.flatMap((dest, destinationIndex) => {
      const port: DestinationOption = {
        id: dest.id,
        type: dest.port.isTimeException ? 'TimeException' : 'Port',
        hra: highRiskAreas?.find((hra) => hra.id === dest.port.highRiskAreaId)?.name,
        name: dest.port.name,
        coveredAreaIndex,
        destinationIndex,
        entry: dest.entry,
        exit: dest.exit,
        isValid: !dest.port.isTimeException,
        hasWarnings:
          fieldNameFilter(
            warnings,
            `coveredAreas[${coveredAreaIndex}].Itinerary[${destinationIndex}].Configuration.Knr`
          ).length > 0,
        links: dest.links,
      };

      return port;
    });
    return country.concat(ports);
  });

  useEffect(() => {
    if (!selectedConfiguration && destinationsOptions) {
      setSelectedConfiguration!(destinationsOptions[0]?.id);
    }
  }, []);

  if (!coveredAreas || !destinationsOptions) return null;

  const totalVoyageNode: DestinationOption = {
    id: Globals.TotalVoyageId,
    type: 'Voyage',
    name: 'Total Voyage',
    isValid: true,
    entry: coveredAreas[0]?.entry,
    exit: coveredAreas[coveredAreas.length - 1]?.exit,
  };

  const containsAnyHraDestinations =
    coveredAreas.some((c) => c.highRiskAreaId !== null && c.highRiskAreaId !== 0) ||
    coveredAreas.some((c) => c.itinerary.some((i) => i.port.highRiskAreaId !== null && i.port.highRiskAreaId !== 0));

  destinationsOptions.unshift(totalVoyageNode);

  const selectedDestination = !isMultiDestination
    ? getLastElement(destinationsOptions)
    : destinationsOptions.find((c) => c.id === selectedConfiguration);

  const setConfiguration = (item: any) => {
    resetWarnings();
    setSelectedConfiguration!(item.id);
  };

  const noKnRProvider =
    selectedQuote?.knrProvider === 'None' ||
    selectedQuote?.knrProvider === null ||
    selectedQuote?.knrProvider === undefined;
  const isKnRBoxVisible = selectedQuote?.links?.some((l) => l.action === 'AddKnr') || !noKnRProvider;

  return (
    <Box
      header={
        containsAnyHraDestinations && isKnRBoxVisible ? (
          <>
            Rebates and K&R Coverage
            {selectedQuote?.policyPrefix && (
              <>
                <Middot />
                {selectedQuote?.policyPrefix}
              </>
            )}
          </>
        ) : (
          'Rebates'
        )
      }
    >
      {isMultiDestination && (
        <SelectDropdown
          results={destinationsOptions}
          callback={setConfiguration}
          selectedItem={selectedDestination}
          renderResult={renderDestionationsResult}
          keyField="id"
        />
      )}
      <Content>
        {selectedDestination &&
          (containsAnyHraDestinations && isKnRBoxVisible ? (
            <Split>
              <Validator keys={['/^coveredAreas.*\\.Itinerary.*\\.Configuration\\.Knr/']} hideWarnings />
              <RebatesTable destination={selectedDestination} />
              {selectedDestination.hra || selectedConfiguration === Globals.TotalVoyageId ? (
                <KidnapRansomDestination destination={selectedDestination} />
              ) : (
                (errors || warnings) && (
                  <NoDataRow
                    size="small"
                    loadStatus={{ loading: false, success: true }}
                    header="K&R Coverage pending"
                    message="Please correct remaining warnings"
                  />
                )
              )}
            </Split>
          ) : (
            <Narrow>
              <RebatesTable destination={selectedDestination} />
            </Narrow>
          ))}
      </Content>
    </Box>
  );
};
