import { ChildrenProps } from '@/models/utils/ChildrenProps';
import React, {
  createContext, useMemo, useContext, useState
} from 'react';

interface IQuoteCommentsContext {
  quoteComments: FinishQuoteState;
  setQuoteComments: React.Dispatch<React.SetStateAction<FinishQuoteState>>;
}

const QuoteCommentsContext = createContext<IQuoteCommentsContext | undefined>(undefined);

export interface FinishQuoteState {
  clientReference?: string,
  comment?: string
}
const QuoteCommentsProvider = ({ children }: ChildrenProps) => {
  const [quoteComments, setQuoteComments] = useState<FinishQuoteState>({
    clientReference: undefined,
    comment: undefined
  });

  const value = useMemo(
    () => ({ quoteComments, setQuoteComments }),
    [quoteComments]
  );

  return (
    <QuoteCommentsContext.Provider value={value}>
      {children}
    </QuoteCommentsContext.Provider>
  );
};

const useQuoteCommentsContext = () => {
  const context = useContext(QuoteCommentsContext);

  if (context === undefined) {
    throw new Error('useQuoteCommentsContext must be used within a QuoteCommentsProvider');
  }
  return context;
};

export { QuoteCommentsProvider, useQuoteCommentsContext };
