import { PageHeader } from '@/components/layout/PageHeader';
import { isAdmin, isInternalUser } from '@/components/routing/roles';
import { Routes } from '@/components/routing/routes';
import { Box } from '@/components/shared/Box';
import { Button } from '@/components/shared/Button';
import { FilterBar } from '@/components/shared/FilterBar';
import { FlexBox } from '@/components/shared/FlexBox';
import { SelectionBar } from '@/components/shared/SelectionBar';
import { getHeaders } from '@/components/shared/Table/core/Components';
import { TermsIndexerStatus } from '@/components/shared/TermsIndexerStatus';
import { usePageSettingsContext } from '@/components/userSettings/PageSettingsContext';
import useDebounce from '@/hooks/useDebouncedValue';
import { useQuery } from '@/hooks/useQuery';
import { Filters } from '@/models/Filters';
import { SortedHeader, toFilter } from '@/models/SortOrder';
import { TargetType } from '@/models/TargetType';
import { useDefaultTermsConditionsRegions } from '@/services/termsService';
import { theme } from '@/utility/theme';
import { Gear, Pen } from '@instech/icons';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { InformationNotification } from '@instech/components';
import { clearActiveTabName } from '@/utility/sessionStore';
import { useAgreementsPageContext } from '../agreements/AgreementsPageContext';
import { useAgreementsOverviewContext } from '../agreementsOverview/AgreementsOverviewContext';
import { defaultFilters, DefaultTermsConditionsTable } from './DefaultTermsConditionsTable';
import { FilterableTableHeadersProvider } from './FilterableTableHeadersContext';
import { tableSections, tableSectionsUnderwriter } from './tableHeaders';
import { TermsConditionsPageProvider } from './TermsConditionsPageContext';

const FilterContainer = styled(FlexBox)`
  padding: 1em 0;
  margin: 0 0.5em;
`;

const NotificationContainer = styled.div`
  margin: 0.5rem 0;
`;

export const DefaultTermsConditionsPage = () => {
  const { agreementEntries, setAgreementEntries } = useAgreementsOverviewContext();
  const { setAgreementSummaryTarget, showDeleteStatus, setShowDeleteStatus } = useAgreementsPageContext();

  const { pageSettings, setPageSettings, setPageKey } = usePageSettingsContext();
  const [mainActionClicked, setMainActionClicked] = useState<boolean>(false);

  useEffect(() => {
    setPageKey('defaultTerms');
    return () => setPageKey();
  }, []);

  const restoredFilters = pageSettings?.defaultTerms?.filters ?? {};

  const query = useQuery();
  const selectedCoveredAreasParam = [query.get('SelectedCoveredAreas')];

  const defaultAndParamsFilters = {
    ...defaultFilters,
    ...restoredFilters,
    ...(selectedCoveredAreasParam.some(x => Boolean(x)) && { SelectedCoveredAreas: selectedCoveredAreasParam })
  };

  const [done, setDone] = useState<boolean>(false);
  const [filters, setFilters] = useState<Filters>(defaultAndParamsFilters);

  const tableSectionsByRole = isAdmin() ? tableSections : tableSectionsUnderwriter;
  const headers = getHeaders(tableSectionsByRole);
  const history = useHistory();

  const restoredSorting = toFilter(restoredFilters?.orderBy);
  const [sortedHeader, setSortedHeader] = useState<SortedHeader | undefined>(restoredSorting);

  const debouncedFilters = useDebounce(filters, 500);
  const { data: regions, isLoading, error } = useDefaultTermsConditionsRegions(debouncedFilters, JSON.stringify(debouncedFilters));

  useEffect(() => {
    setDone(!!regions);
  }, [regions]);

  useEffect(() => {
    if (sortedHeader) {
      setFilters({ ...filters, orderBy: `${sortedHeader.propertyName} ${sortedHeader.sortOrder}` });
    }
  }, [sortedHeader]);

  useEffect(() => {
    const { selectedCoveredAreaIds, ...rest } = filters;

    setPageSettings(
      {
        defaultTerms: {
          ...pageSettings?.defaultTerms,
          filters: { ...rest }
        }
      }
    );
  }, [filters]);

  const editAreas = () => {
    const vesselTypes = agreementEntries.flatMap(x => x.vesselTypeIds).some(x => x);
    let targetType = vesselTypes ? TargetType.VesselTypeSpecific : TargetType.Standard;

    if (vesselTypes && !agreementEntries.every(x => x.vesselTypeIds?.some(y => y))) {
      targetType = TargetType.Standard + TargetType.VesselTypeSpecific;
    }

    setAgreementSummaryTarget({
      agreementIds: agreementEntries.flatMap(x => x.agreementIds),
      targetType,
      hasMultipleSets: false // It is not possible to select agreements with multiple sets in the table
    });
    setMainActionClicked(true);
    clearActiveTabName();
    setTimeout(() => {
      history.push(Routes.termsDefaultDetails);
    }, 500);
  };

  return (
    <>
      <FlexBox justifyContent="space-between" alignItems="center">
        <PageHeader header="Default T&C" />
        {isInternalUser({ onlyEditors: true }) && <Button icon={<Gear />} to={Routes.termsExcludedAreas}>Excluded areas</Button>}
      </FlexBox>

      <TermsIndexerStatus />

      <FilterableTableHeadersProvider
        headers={headers}
        columnMetadata={regions?.columnMetadata}
        initialFilters={defaultFilters}
        filters={filters}
        sortedHeader={sortedHeader}
        setSortedHeader={setSortedHeader}
        setFilters={setFilters}
        isLoading={isLoading}
      >

        {/* <TreeView data={agreementEntries} /> */}
        <Box highlight={theme.border.lightGreen}>

          <FlexBox justifyContent="space-between" alignItems="center">
            {isAdmin() ? (
              <SelectionBar
                type="agreement"
                parameterName="selectedCoveredAreaIds"
                editMode
                initialSelection={[]}
                selection={agreementEntries.flatMap(x => x.areaIds)}
                selected={!!filters?.selectedCoveredAreaIds && filters.selectedCoveredAreaIds.length > 0}
                setter={setAgreementEntries}>
                <Button
                  icon={<Pen />}
                  onClick={editAreas}
                  background={theme.green}
                  isActivated={mainActionClicked}
                  shouldActivateByEnter={agreementEntries.length > 0}
                  disabled={!mainActionClicked && agreementEntries.length === 0}>
                  Edit
                </Button>
              </SelectionBar>
            ) : <div />}

            <FilterContainer justifyContent="space-between">
              <FilterBar />
            </FilterContainer>

          </FlexBox>

          {showDeleteStatus && (
            <NotificationContainer>
              <InformationNotification headingText="Delete success!"
                size="large"
                descriptionText="Agreements successfully deleted. It might take a few minutes before the table is updated."
                onClose={() => setShowDeleteStatus(false)} />
            </NotificationContainer>
          )}

          <TermsConditionsPageProvider
            editAction={editAreas}
            setMainActionClicked={setMainActionClicked}>

            <DefaultTermsConditionsTable regions={regions} loadStatus={{ loading: !done, success: !error }} />

          </TermsConditionsPageProvider>
        </Box>
      </FilterableTableHeadersProvider>
    </>
  );
};
