import styled, { css } from 'styled-components';
import { Cry, Lighthouse } from '@instech/icons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
`;

const NoDataHeader = styled.div<{ size?: string }>`
  font-size: 28px;
  font-weight: bold;
  ${props => props.size !== 'small' && css`
    margin: 1rem 0;
  `};
`;

const NoDataTip = styled.div`
  font-size: 14px;
  display: flex;
  flex-wrap: nowrap;
`;

const ClearLink = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
`;

const IconContainer = styled.div<{ size?: string }>`
  padding-bottom: 1rem;
`;

interface DatasetProps {
  size?: string;
  header?: string;
  message?: string;
}
const EmptyDataset = ({ size, header, message }: DatasetProps) => (
  <>
    <IconContainer size={size}><Lighthouse /></IconContainer>
    <NoDataHeader size={size}>{header ?? 'Nothing to see here'}</NoDataHeader>
    <NoDataTip>
      {message ?? (
        <>
          Try making a different selection or&nbsp;
          <ClearLink>clear all filters</ClearLink>
        </>
      )}
    </NoDataTip>
  </>
);

const Error = ({ size, header, message }: DatasetProps) => (
  <>
    <IconContainer size={size}><Cry /></IconContainer>
    <NoDataHeader size={size}>That didn't go well</NoDataHeader>
    <NoDataTip>
      Try again later or&nbsp;
      <ClearLink>contact support</ClearLink>
    </NoDataTip>
  </>
);

interface NodataProps {
  success?: boolean;
  size?: string;
  header?: string;
  message?: string;
}
export const NoData = ({ success, size, header, message }: NodataProps) => (
  <Container>
    {success ?
      <EmptyDataset size={size} header={header} message={message} />
      : <Error size={size} header={header} message={message} />}
  </Container>
);
