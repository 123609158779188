import {
  Enlarge, SortDown, SortUp
} from '@instech/icons';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { SearchFilterDropdown } from '@/components/shared/Table/core/SearchFilterDropdown';
import { SortOrder } from '@/models/SortOrder';
import { useFilterableTableHeadersContext } from '@/components/pages/defaultTermsConditions/FilterableTableHeadersContext';
import { ValidationProvider } from '@/services/ValidationContext';
import {
  getHeaderAlignment, TableColumnHeader, TableHeader
} from './Components';
import { RangeFilterDropdown } from './RangeFilterDropdown';
import { useTermsConditionsPageContext } from '@/components/pages/specificTermsConditions/TermsConditionsPageContext';

export const HiddenHeader = styled.div`
  display: contents;

  & * {
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: none;
    border-top: none;
  }
`;

const NonFilterable = styled(TableHeader) <{ right?: boolean, center?: boolean }>`
  display: flex;
  align-items: center;
  height: 100%;

  ${props => (props.right) && css`
    justify-content: flex-end;
    text-align: right;
    `};  
    
  ${props => (props.center) && css`
    justify-content: center;
    text-align: center;
  `};    
`;

const Filterable = styled(TableHeader) <{ delimiter?: string, filtered: boolean, selected?: boolean, right?: boolean, center?: boolean }>`
  display: flex;
//  justify-content: ${props => props.right ? 'flex-end' : 'space-between'};
  justify-content: space-between;
  align-items: center;
  height: 100%;
  cursor: pointer;

  ${props => (props.filtered || props.selected) && css`
    background-color: ${props.selected ? props.theme.green : props.theme.lightGreen50};
    border-radius: 5px 5px 0 0;
  `};

  ${props => (props.right) && css`
    justify-content: flex-end;
    text-align: right;
    `};  
    
    ${props => (props.center) && css`
    justify-content: center;
    text-align: center;
  `};    
`;

const HeaderFilterButton = styled.a`
  svg {
    height: 14px;
  }
`;

const FilterContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const FacetCount = styled.span`
  padding: 0 0.5em;
  margin-left: 0.5rem;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Subtitle = styled.div`
  font-weight: 400;
`;

interface FilterExtraProps {
  filter: string | string[],
  sortOrder?: SortOrder,
  sorted?: boolean,
  noDropdown?: boolean,
}
const FilterExtra = ({ filter, sortOrder, sorted, noDropdown }: FilterExtraProps) => {
  const sortIcon = sortOrder === SortOrder.Ascending ? <SortDown /> : <SortUp />;
  return (
    <>
      {filter?.length > 0 && <FacetCount>{filter.length}</FacetCount>}
      {sorted ? sortIcon : (!noDropdown && <Enlarge />)}
    </>
  );
};

export interface Extras {
  tag: string;
  element: React.ReactNode;
}

interface FilterableTableHeadersProps {
  endpoint: string,
  delimiters: number[],
  wide?: boolean,
  hidden?: boolean
}
export const FilterableTableHeaders = (
  {
    endpoint,
    delimiters = [],
    wide = false,
    hidden = false
  }: FilterableTableHeadersProps
) => {
  const { isNameColumnVisible } = useTermsConditionsPageContext();
  const [displayedHeader, setDisplayedHeader] = useState<string>();

  const { headers, columnMetadata, filters, changeFilter, sortedHeader, setSortedHeader, isLoading } = useFilterableTableHeadersContext();
  const onHeaderClick = (filterable: boolean, propertyName?: string) => {
    if (!propertyName || displayedHeader === propertyName) return;
    if (filterable) {
      setDisplayedHeader(propertyName);
    } else {
      const reversedSortOrder = sortedHeader?.sortOrder === SortOrder.Descending ? SortOrder.Ascending : SortOrder.Descending;
      const sortOrder = sortedHeader?.propertyName !== propertyName ? SortOrder.Descending : reversedSortOrder;
      setSortedHeader({ propertyName, sortOrder });

      if (sortedHeader && changeFilter) {
        changeFilter('orderBy', `${propertyName} ${sortOrder}`);
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<any>, positive: () => void, negative: () => void) => {
    if (e.key === 'Enter') positive();
    else if (e.key === 'Escape') negative();
  };

  const renderFilterableHeader = (header: TableColumnHeader, i: number) => {
    const firstFacets = columnMetadata?.find(n => n.propertyName === header.propertyName)?.facets;
  
    if (!header.propertyName) return null;

    return (
      <ValidationProvider>
        <FilterContainer tabIndex={hidden ? undefined : 0}
          onKeyDown={e => handleKeyDown(
            e,
            () => onHeaderClick(!header.noDropdown && header.parameterName !== undefined && (!!firstFacets || header.type === 'Number'), header.propertyName),
            () => setDisplayedHeader(undefined)
          )}>
          <Filterable
            onClick={() => onHeaderClick(!header.noDropdown && header.parameterName !== undefined, header.propertyName)}
            delimiter={delimiters.includes(i) ? 'left' : undefined}
            selected={!header.noDropdown && displayedHeader === header.propertyName}
            filtered={!!filters && header.parameterName !== undefined && !!filters[header.parameterName]}
            right={getHeaderAlignment(header) === 'right'}
            center={getHeaderAlignment(header) === 'center'}
          >
            <TitleContainer>
              {header.title}
              <Subtitle>{header.subtitle}</Subtitle>
            </TitleContainer>
            <HeaderFilterButton>
              <FilterExtra
                filter={filters && header?.parameterName ? filters[header.parameterName] : []}
                sorted={sortedHeader?.propertyName === header.propertyName}
                sortOrder={sortedHeader?.sortOrder}
                noDropdown={header.noDropdown || !header.parameterName}
              />
            </HeaderFilterButton>
          </Filterable>
          {!isLoading && !header.noDropdown && header.parameterName && (
            firstFacets ? (
              <SearchFilterDropdown
                close={() => setDisplayedHeader(undefined)}
                title={header.title}
                type={header.type}
                endpoint={endpoint}
                propertyName={header.propertyName}
                parameterName={header.parameterName}
                display={displayedHeader === header.propertyName}
                facets={firstFacets}
                right={header.dropdownAlign === 'right'}
              />
            ) : (
              <RangeFilterDropdown
                close={() => setDisplayedHeader(undefined)}
                title={header.title}
                type={header.type}
                propertyName={header.propertyName}
                parameterName={header.parameterName}
                display={displayedHeader === header.propertyName}
                right={header.dropdownAlign === 'right'}
              />
            )
          )}
        </FilterContainer>
      </ValidationProvider>
    );
  };

  const renderNonFilterableHeader = (header: TableColumnHeader, i: number) => (
    <NonFilterable
      delimiter={delimiters.includes(i) ? 'left' : undefined}
      right={getHeaderAlignment(header) === 'right'}
      center={getHeaderAlignment(header) === 'center'}
    >
      <TitleContainer>
        {header.title}
        <Subtitle>{header.subtitle}</Subtitle>
      </TitleContainer>
    </NonFilterable>
  );
  
  return (
    <>
      {headers && headers.map((header, i) => (
        <React.Fragment key={header.propertyName || header.title || header.key}>
          {header.propertyName
            ? header.propertyName === 'AgreementName' && !isNameColumnVisible ? ''
            : (renderFilterableHeader(header, i))
            : (renderNonFilterableHeader(header, i))}
        </React.Fragment>
      ))}
    </>
  );
};
