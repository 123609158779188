import React, {
  createContext, PropsWithChildren, useContext, useMemo
} from 'react';

const ErrorContext = createContext<ErrorProviderProps>({
  hasError: false
});

interface ErrorProviderProps {
  errorKeys?: string[];
  hasError?: boolean;
  errorMessage?: string;
}
export const ErrorProvider = ({
  hasError = false,
  errorKeys,
  errorMessage,
  children
}: PropsWithChildren<ErrorProviderProps>) => {
  const value = useMemo(() => (
    {
      hasError,
      errorKeys,
      errorMessage
    }
  ), [errorKeys, errorMessage, hasError]);

  return (
    <ErrorContext.Provider value={value}>
      {children}
    </ErrorContext.Provider>
  );
};

export const useErrorContext = () => useContext(ErrorContext);
