import React, { useState } from 'react';
import { Pen } from '@instech/icons';
import styled, { css } from 'styled-components';
import { HateoasHandler } from '@/components/shared/HateoasHandler';
import { WarWeb } from '@/war';
import { Button } from '@/components/shared/Button';
import { theme } from '@/utility/theme';
import { BowActions } from '../bowActionsMap';
import { ClientRefModal } from '../../core/ClientRefModal';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const DisplayText = styled.div<{ isEmpty?: boolean }>`
  display: flex;
  padding-left: 0.5rem;
  align-items: flex-end;

  ${props => props.isEmpty && css`
    font-weight: normal;
    color: ${theme.marineBlue50};
  `}
`;

const NonRateLeadLabel = styled(DisplayText)`
  margin-left: 2rem;
`;

export const ClientRef = ({ bowData }: { bowData: WarWeb.Bow }) => {
  const [showEditClientRefModal, setShowEditClientRefModal] = useState(false);

  return (
    <Wrapper>
      {bowData.bowType === 'NonRateLead' ? (
        <NonRateLeadLabel>{bowData.clientReference}</NonRateLeadLabel>
      ) : (
        <>
          &nbsp;-
          <DisplayText isEmpty={!bowData.clientReference}>{bowData.clientReference ?? 'Client Ref.'}</DisplayText>
        </>
      )}

      <HateoasHandler
        links={bowData.links}
        action={BowActions.SetClientReference}
        editVariant={(
          <Button icon={<Pen />} onClick={() => setShowEditClientRefModal(true)} />
        )} />
      {showEditClientRefModal && (
        <ClientRefModal
          closeModal={() => setShowEditClientRefModal(false)}
        />
      )}
    </Wrapper>
  );
};
