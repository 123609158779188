import { WarWeb } from '@/war';
import {
  createContext, PropsWithChildren, useContext
} from 'react';

const QuoteContext = createContext<QuoteProviderProps>({
  ids: {
    bowId: '',
    quoteId: ''
  },
});

export interface IdState {
  bowId?: string;
  quoteId?: string;
}

interface QuoteProviderProps {
  showState?: boolean;
  ids: IdState,
  setIds?: (ids: IdState) => void;
  setSelectedQuoteId?: (quoteId: string) => void;
  showSidebarLinks?: boolean;
  selectedConfiguration?: number;
  setSelectedConfiguration?: (id?: number) => void;
  isNoClaimsBonusBased?: boolean;
  isMultiDestination?: boolean;
  quoteDocuments?: WarWeb.LegalDocument[];
}
export const QuoteProvider = ({
  showState = false,
  ids,
  setIds,
  setSelectedQuoteId,
  showSidebarLinks = true,
  selectedConfiguration,
  setSelectedConfiguration,
  isNoClaimsBonusBased,
  isMultiDestination,
  quoteDocuments,
  children
}: PropsWithChildren<QuoteProviderProps>) => (
  <QuoteContext.Provider value={{
    ids,
    setIds,
    setSelectedQuoteId,
    showState,
    showSidebarLinks,
    selectedConfiguration,
    setSelectedConfiguration,
    isNoClaimsBonusBased,
    quoteDocuments,
    isMultiDestination
  }}>
    {children}
  </QuoteContext.Provider>
);

export const useQuoteContext = () => useContext(QuoteContext);
