export enum SortOrder {
  Ascending = 'asc',
  Descending = 'desc'
}

export interface SortedHeader {
  propertyName: string,
  sortOrder: SortOrder
}

export const toFilter = (param?: string) => {
  if (!param) return undefined;
  return {
    propertyName: param?.split(' ')[0],
    sortOrder: param?.split(' ')[1] as SortOrder
  };
};