import { Pane, SlimButton } from '@instech/components';
import styled from 'styled-components';
import { Plus, Trash } from '@instech/icons';
import { useArea } from '@/services/areasService';
import { useModalOpenClose } from '@/hooks/useModalOpenClose';
import { useEditExcludedAreasContext } from '../EditExcludedAreasContext/EditExcludedAreasContext';
import { CustomAreaForm } from './CustomAreaForm';
import { EntryExitPointsEditor } from './EntryExitPointsEditor';
import { AddEntryExitModal } from './AddEntryExitModal/AddEntryExitModal';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
`;

const DeleteCustomAreaButton = () => {
  const { state: { areaId }, isNewArea, deleteCustomArea, undoAddedCustomArea } = useEditExcludedAreasContext();
  const { data: area } = useArea(isNewArea ? undefined : areaId);

  // If area is saved in DB, send delete request. Otherwise, remove it from state.
  const handleDelete = () => {
    if (!areaId) return;
    if (areaId > 0 && area) {
      deleteCustomArea(area);
      return;
    }
    undoAddedCustomArea(areaId);
  };
  return <SlimButton startIcon={<Trash />} variant="secondary" onClick={handleDelete}>DELETE CUSTOM AREA</SlimButton>;
};

const AddEntryExitButton = ({ onClick }: { onClick: () => void; }) => (
  <SlimButton startIcon={<Plus />} variant="secondary" onClick={onClick}>ADD ENTRY / EXIT POINT</SlimButton>
);

const Editor = () => {
  const { isCustomArea, state: { areaId } } = useEditExcludedAreasContext();
  const { modalIsOpen, openModal, closeModal } = useModalOpenClose();

  if (!isCustomArea || !areaId) return <>Not applicable.</>;
  return (
    <Wrapper>
      <CustomAreaForm />
      <EntryExitPointsEditor />
      <AddEntryExitButton onClick={openModal} />
      <DeleteCustomAreaButton />
      {modalIsOpen && <AddEntryExitModal closeModal={closeModal} />}
    </Wrapper>
  );
};

export const CustomAreaEditor = () => (
  <Pane title="Custom area" color="green">
    <Editor />
  </Pane>
);
