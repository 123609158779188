import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Chevron } from '../../Chevron';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-self: center;
`;

const ArrowNumber = styled.span`
  font-size: 12px;
  line-height: 10px;
  text-align: center;
  vertical-align: middle;
`;

const EmptyArrow = styled.div`
  height: 14px;
  width: auto;
`;

const StyledArrow = styled.button`
  height: 14px;
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
`;

const Arrow = ({ show, top, bottom, onClick }) => {
  if (!show) return <EmptyArrow />;
  return (
    <StyledArrow type="button" onClick={onClick}>
      <Chevron top={top} bottom={bottom} thin />
    </StyledArrow>
  );
};
Arrow.propTypes = {
  show: PropTypes.bool,
  top: PropTypes.bool,
  bottom: PropTypes.bool,
  onClick: PropTypes.func
};

export const ArrowButton = ({ number, first, last, onClickUp, onClickDown }) => (
  <Wrapper>
    <Arrow show={!first} top onClick={onClickUp} />
    <ArrowNumber>{number}</ArrowNumber>
    <Arrow show={!last} bottom onClick={onClickDown} />
  </Wrapper>
);
ArrowButton.propTypes = {
  number: PropTypes.number,
  first: PropTypes.bool,
  last: PropTypes.bool,
  onClickUp: PropTypes.func,
  onClickDown: PropTypes.func
};
