import { Routes } from '@/components/routing/routes';
import { Button } from '@/components/shared/Button';
import { AgreementTypeEnum } from '@/models/Agreement';
import { deleteAgreements } from '@/services/agreementsService';
import { theme } from '@/utility/theme';
import { Trash } from '@instech/icons';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { useAgreementsPageContext } from '../../agreements/AgreementsPageContext';
import { useAgreementTargets } from '../../agreements/useAgreementTargets';
import { DeleteSpecificAgreementsConfirmationModal } from '../../agreementsOverview/deleteSelection/DeleteAgreementsConfirmationModals';
import { AgreementViewMode, useAgreementDetailsContext } from '../AgreementDetailsContext';

export const DeleteSpecificOrTradeAgreementsButton = ({ disabled }: { disabled?: boolean }) => {
  const { agreementType, setShowDeleteStatus, isBulkEdit } = useAgreementsPageContext();
  const { agreementTargets } = useAgreementTargets();
  const { viewMode, targetsSummary } = useAgreementDetailsContext();
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const deleteHandler = async () => {
    const { agreementIds, targetType } = agreementTargets;
    const deleteTargets = { agreementIds, targetType };

    await deleteAgreements(deleteTargets);
    setShowDeleteStatus(true);
    history.push(Routes.termsSpecific);
    setShowDeleteModal(false);
  };

  if (agreementType !== AgreementTypeEnum.Specific || isBulkEdit || viewMode !== AgreementViewMode.Inspect) return null;

  return (
    <>
      <Button
        onClick={() => setShowDeleteModal(true)}
        background={theme.ultraLightGray}
        icon={<Trash />}
        disabled={disabled}>
        Delete
      </Button>
      {showDeleteModal && (
        <DeleteSpecificAgreementsConfirmationModal
          numberOfVessels={targetsSummary.vessels.length}
          numberOfAgreements={targetsSummary.agreementIds.length}
          closeModal={() => setShowDeleteModal(false)}
          apply={deleteHandler} />
      )}
    </>
  );
};
