export const addOrUpdateArray = <T,>(state: T[], setter: ((s: T[]) => void), key: ((a: T) => boolean), item: T) => {
  const updatedState = [...state];
  const index = updatedState.findIndex(key);
  if (index < 0) {
    setter(updatedState.concat(item));
  } else {
    updatedState[index] = item;
    setter(updatedState);
  }
};

export const addOrRemoveArray = <T,>(state: T[], setter: ((s: T[]) => void), key: ((a: T) => boolean), item: T) => {
  const updatedState = [...state];
  const index = updatedState.findIndex(key);
  if (index < 0) {
    setter(updatedState.concat(item));
  } else {
    updatedState.splice(index, 1);
    setter(updatedState);
  }
};

export const stickyItem = <T,>(item: T | undefined, list: T[] | undefined, key: ((a: T) => boolean)) => 
  list?.find(key) ? list : [item, ...list || []];
