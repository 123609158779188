import {
  useLocation, matchPath, Redirect
} from 'react-router';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { getNavbarItems } from '@/components/routing/navbarRoutes';
import { Routes } from '@/components/routing/routes';
import { theme } from '@/utility/theme';
import { isExpired } from '../../routing/roles';

const NavBarContainer = styled.ul`
  display: flex;
  height: 100%;
  color: ${props => props.theme.white};
  padding-left: 1rem;
`;

const NavItemContainer = styled.li`
  display: flex;
  height: 100%;
  flex-direction: column-reverse;
`;

const StyledLink = styled(Link) <{ selected?: boolean }>`
  display: flex;
  align-items: center;
  height: 40px;
  ${props => props.selected && css`
      background-color: ${props.theme.background.primary};
      color: ${props.theme.marineBlue};
  `}
  padding: 0 2rem;
  margin: 0 0.5rem;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  border-radius: 6px 6px 0 0;


  @media (max-width: ${theme.breakpoints.desktopSmall}) {
    padding: 0 1rem;
    margin: 0 .5rem;
    font-size: 13px;
  }

  @media (max-width: ${theme.breakpoints.tabletMax}) {
    padding: 0 12px;
    margin: 0 6px;
    font-size: 12px;
  }  
`;

interface NavItemProps {
  path: string;
  name: string;
}
const NavItem = ({ path, name }: NavItemProps) => {
  const location = useLocation();
  const isSelected = !!matchPath(location.pathname, path);

  return (
    <NavItemContainer>
      <StyledLink selected={isSelected} to={path}>
        {name}
      </StyledLink>
    </NavItemContainer>
  );
};

export const NavBar = () => {
  const menuItems = getNavbarItems();

  if (isExpired()) { return <Redirect to={Routes.base} />; }

  return (
    <NavBarContainer>
      {menuItems.map(each => <NavItem key={each.path} {...each} />)}
    </NavBarContainer>
  );
};
