import { TextFieldControlled, TextFieldControlledProps } from '@instech/components';
import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div <{ dirty?: boolean, minimal?: boolean, compact?: boolean }>`

  input {
    
    padding: 0px ${props => props.compact ? '7px' : '16px'};

    ${props => props.minimal && css`
      border: none;
      padding: 13px 2px;
      border-radius: 6px;
      height: 1em;
      background: transparent;

      &:focus {
        border: none;
      }
    `};
  }

  & span {
    height: auto;
  }
   
`;

export interface TextFieldProps extends TextFieldControlledProps {
  noErrors?: boolean;
  dirty?: boolean;
  minimal?: boolean;
  compact?: boolean;
}
export const TextField = (
  {
    label,
    noErrors = false,
    minimal = false,
    compact = false,
    dirty = false,
    ...props
  }: TextFieldProps
) => (
  <Wrapper
    dirty={dirty}
    minimal={minimal}
    compact={compact}>
    <TextFieldControlled
      touched={dirty}
      noErrors={noErrors}
      noLabel={!label}
      label={label}
      {...props} />
  </Wrapper>
);
