import { PageHeader } from '@/components/layout/PageHeader';
import { isBroker, isInternalUser } from '@/components/routing/roles';
import { Routes } from '@/components/routing/routes';
import { ValidationProvider } from '@/services/ValidationContext';
import styled, { css } from 'styled-components';
import { useState } from 'react';
import { AgreementViewMode, useAgreementDetailsContext } from '../agreementDetails/AgreementDetailsContext';
import { HeaderActions } from '../agreementDetails/HeaderActions/HeaderActions';
import { AppliesToSelection } from '../defaultTermsDetails/appliesToSelection/AppliesToSelection';
import { BulkChangeAreasModal } from '../defaultTermsDetails/appliesToSelection/BulkChangeAreas/BulkChangeAreasModal';
import { RateGuide } from './RateGuide';
import { Settings } from './Settings';
import { TextConditionsUpdater } from './TextConditionsUpdater';
import { EditAgreementNameModal } from '../agreementDetails/EditTradeAgreementNameModal';
import { HeaderContent } from './HeaderContent';

const ContentContainer = styled.div<{ isEdit: boolean }>`
  display: grid;
  grid-template-columns: ${props => (props.isEdit ? '0.5fr 6fr' : 'auto')};
  grid-template-rows: auto;
  column-gap: 2rem;

  ${props =>
    props.isEdit
      ? css`
          grid-template-areas:
            'rateguide settings'
            'rateguide applies-to';
        `
      : css`
          grid-template-areas:
            'settings'
            'applies-to';
        `};

  .rateguide {
    grid-area: rateguide;
  }
  .settings {
    grid-area: settings;
    overflow: auto;
  }
  .applies-to {
    grid-area: applies-to;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Container = styled.div`
  margin-bottom: 1.5rem;
`;

const SideContainer = styled.div`
  width: 10vw;
  min-width: 270px;
  height: 70vh;
`;

export const TermsDetailsPage = () => {
  const { viewMode, isBulkChangingAreas, setIsBulkChangingAreas } = useAgreementDetailsContext();
  const isEdit = viewMode !== AgreementViewMode.Inspect;

  const [isEditTradeAgreementNameOpen, setIsEditTradeAgreementNameOpen] = useState(false);

  const closeEditAgreementNameModal = () => {
    setIsEditTradeAgreementNameOpen(false);
  };

  return (
    <ValidationProvider>
      <>
        <TextConditionsUpdater />
        {isBulkChangingAreas && <BulkChangeAreasModal closeModal={() => setIsBulkChangingAreas(false)} />}
        <>
          <HeaderContainer>
            <PageHeader
              header={<HeaderContent setIsEditTradeAgreementNameOpen={setIsEditTradeAgreementNameOpen} />}
              breadcrumb={{
                label: isBroker() ? 'Terms & Conditions' : 'Fleet specific T&C',
                to: isBroker() ? Routes.terms : Routes.termsSpecific,
              }}
            />
            {isInternalUser() && <HeaderActions />}
          </HeaderContainer>
          <ContentContainer isEdit={isEdit}>
            {isEdit && (
              <SideContainer className="rateguide">
                <RateGuide />
              </SideContainer>
            )}
            <Container className="settings">
              <Settings />
            </Container>
            <Container className="applies-to">
              <AppliesToSelection />
            </Container>
          </ContentContainer>
          {isEditTradeAgreementNameOpen && <EditAgreementNameModal closeModal={closeEditAgreementNameModal} />}
        </>
      </>
    </ValidationProvider>
  );
};
