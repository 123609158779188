import { PageHeader } from '@/components/layout/PageHeader';
import { Button, ButtonShape } from '@/components/shared/Button';
import { theme } from '@/utility/theme';
import { useAccount } from 'its-js-authentication';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  ${ButtonShape} {
    width: max-content;
    color: white;
  }
`;

export const ProfileLogout = () => {
  const { logout } = useAccount();
  return (
    <Container>
      <PageHeader header="Sign out" />
      <p>Do you want to sign out of the system?</p>
      <Button onClick={logout} background={theme.marineBlue}>Yes</Button>
    </Container>
  );
};
