import { openErrorModal } from '@/components/error/ErrorModal';
import { useModalContext } from '@/components/modal/ModalContext';
import { isBroker, isBusinessOperations } from '@/components/routing/roles';
import { Routes } from '@/components/routing/routes';
import { useQuoteContext } from '@/components/shared/BowSidebar/QuoteContext';
import { Button, IconContainer } from '@/components/shared/Button';
import { Checkbox } from '@/components/shared/Form/fields/Checkbox';
import { HttpActionProps } from '@/components/shared/HttpAction';
import { Disabled } from '@/components/shared/LoadingOverlay';
import { TreeView } from '@/development/TreeView';
import { useQuote } from '@/hooks/useQuote';
import { getBowQuotePath, useBow } from '@/services/bowServices';
import { HateoasExecutorProvider } from '@/services/HateoasExecutorContext';
import { simpleRequest } from '@/services/utility/simpleFetch';
import { useValidationContext } from '@/services/ValidationContext';
import { isInternalEnvironment } from '@/utility/environment';
import { fieldNameFilter, parseErrors } from '@/utility/errorHelpers';
import { getInputString } from '@/utility/formatter';
import { theme } from '@/utility/theme';
import { WarWeb } from '@/war';
import { Trash } from '@instech/icons';
import {
  AxiosRequestConfig, AxiosResponse, Method
} from 'axios';
import Qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Sidebar } from '../../shared/BowSidebar/Sidebar';
import { GenerateQuoteModal } from '../bows/core/GenerateQuoteModal';
import { BowActions } from '../bows/details/bowActionsMap';
import { ConfirmationModal, ConfirmationModalProps } from '../bows/details/ConfirmationModal';
import { QuoteActions } from '../bows/details/quoteActionsMap';
import { QuoteLabel } from '../bows/details/quoteLabelMap';
import { quoteStatusConfirmations } from '../bows/details/quoteStatusConfirmations';
import { steps } from './bowSteps';
import { BowWizardStateProvider } from './BowWizardStateContext';
import { HeaderActions } from './HeaderActions';
import { useQuoteCommentsContext } from './QuoteCommentsContext';
import { WizardRoutes } from './WizardRoutes';
import { ExposureOptionsProps } from '../bows/details/PlaceExposure';

const WizardContainer = styled.div`
  height: 100%;
  margin-bottom: 100px;
`;

const CreateBowContainer = styled.div`
  display: grid;
  height: calc(100vh - 50px - 68px);
  grid-template-columns: 1fr 5fr;
  grid-template-rows: max-content;
  padding: 1em;
  column-gap: 2rem;
  row-gap: 2rem;
  grid-template-areas:
    "sidebar wizard";

  .sidebar {
    grid-area:sidebar;
  }
  .progress {
      grid-area:progress;
    }
  .wizard{
    grid-area:wizard;
  }
`;

const SidebarContainer = styled.div`
  height: 100%;
  margin-bottom: 100px;
`;

const DebugBar = styled.div` 
  display: flex;
  width:60%;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  margin: 1em 0;
  padding: 0 2em;
  background-color: ${props => props.theme.white};
  border-radius: 3px;

  ${IconContainer} {
    & svg {
      height: 15px;
    }
  }
`;

interface LocationState {
  from: {
    pathname: string;
  };
}

interface WizardStateProps {
  isCreate?: boolean;
  step: number;
  stepsDone: boolean[];
  stepIsDirty: boolean;
  resetKey: number;
  isNonRateLead: boolean;
}

export const isEdit = (bowId?: string, status?: WarWeb.BowStatus) => bowId && status !== 'Draft';

export const BowWizard = () => {
  const { ids, setIds } = useQuoteContext();
  const { bowId, quoteId } = ids;
  const [showGenerateQuoteModal, setShowGenerateQuoteModal] = useState<boolean>(false);

  const { open } = useModalContext();
  const history = useHistory();
  const location = useLocation<LocationState>();

  const stepFromRoute = (route: string) => {
    for (let i = 0; i < steps.length; i += 1) {
      if (route.indexOf(steps[i].path) >= 0) {
        return i;
      }
    }
    return 0;
  };

  const [loading, setLoading] = useState(false);
  const [whatsLoading, setWhatsLoading] = useState<string | undefined>('quote');
  const [lastRequest, setLastRequest] = useState<HttpActionProps>();
  const [lastRoute, setLastRoute] = useState();
  const [lastRequestBody, setLastRequestBody] = useState<any>();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationModalProps, setConfirmationModalProps] = useState<ConfirmationModalProps>();

  const { quoteComments, setQuoteComments } = useQuoteCommentsContext();

  const { setErrors, warnings, setWarnings, seenWarnings, dismissedWarnings, resetWarnings, setDismissedErrors } = useValidationContext();

  const { data: bowData, mutate } = useBow(bowId);
  const selectedQuote = useQuote(bowId, quoteId) || bowData?.primaryQuote;

  const [wizardState, setWizardState] = useState<WizardStateProps>({
    step: stepFromRoute(location.pathname), // initialStep?
    stepsDone: Array(steps.length).fill(false),
    stepIsDirty: false,
    resetKey: 0,
    isNonRateLead: location.search.indexOf('NonRateLead') >= 0
  });

  useEffect(() => {
    if (bowData) {
      setWarnings(selectedQuote?.warnings);

      const isNonRateLead = bowData?.bowType === 'NonRateLead';

      setQuoteComments({
        clientReference: quoteComments.clientReference ?? getInputString(bowData?.clientReference),
        comment: quoteComments.comment ?? getInputString(selectedQuote?.comment)
      });

      const isCreate = !isEdit(bowId, bowData?.status);
      if (!quoteId) {
        setWizardState(prev => ({ ...prev, isCreate, isNonRateLead, step: stepFromRoute(location.pathname) }));
      } else {
        setWizardState(prev => ({ ...prev, isCreate, isNonRateLead }));
      }
    }
  }, [bowData]);

  const [showState, setShowState] = useState(false);

  const resolveStep = () => {
    if (!selectedQuote) return 0;
    const hasItineraryWarnings = fieldNameFilter(selectedQuote?.warnings, 'coveredAreas', true).length > 0;
    // step 2 (ADDITIONAL PREMIUM) is the "default step" of the wizard
    const foundStep = (selectedQuote.coveredAreas?.length === 0 || hasItineraryWarnings) ? 2 : 2;
    setWizardState({ ...wizardState, step: foundStep });
    return foundStep;
  };

  const setStepDone = (done: boolean) => {
    const updatedStepsDone = [...wizardState.stepsDone];
    updatedStepsDone[wizardState.step] = done;
    setWizardState(prev => ({ ...prev, stepsDone: updatedStepsDone }));
  };

  const changeStep = (step: number) => {
    setErrors(undefined);
    let newStep = step;
    if (newStep < 0) newStep = 0;
    else if (newStep > steps.length - 1) newStep = steps.length - 1;
    setWizardState(prev => ({ ...prev, step: newStep, stepIsDirty: false }));
    history.push(`${Routes.createbow}/${steps[newStep].path}?${Qs.stringify({ bowId, quoteId })}`);
  };

  const incrementStep = (increment: number, force = false) => {
    changeStep(wizardState.step + increment);
  };

  useEffect(() => {
    if (steps[wizardState.step].autoAdvance && wizardState.stepIsDirty && wizardState.stepsDone[wizardState.step]) {
      if (wizardState.isCreate) incrementStep(1, true);
      else if (bowId && quoteId) history.push(`${Routes.bows}/${getBowQuotePath(bowId, quoteId)}`);
    }
  }, [wizardState.stepsDone]);

  const [labels, setLabels] = useState({
    title: bowId ? 'Edit Quote' : 'New Bow',
    breadcrumb: quoteId ? { label: 'Bow Details', to: `${Routes.bows}/${bowId}` } : { label: 'Breach of warranty', to: Routes.bows }
  });

  const reset = () => {
    setWizardState({ ...wizardState, step: 0, isCreate: true, stepsDone: Array(steps.length).fill(false), resetKey: wizardState.resetKey + 1 });
    if (setIds) setIds({});
    setWarnings(undefined);
    setErrors(undefined);
    setLabels({ title: 'New Bow', breadcrumb: { label: 'Breach of warranty', to: Routes.bows } });

    history.push(`${Routes.createbow}/${steps[0].path}`);
  };

  const onSuccess = async (action: string) => {
    action !== BowActions.DeleteBow && await mutate();

    if (action === QuoteLabel.GenerateQuote || (action === QuoteActions.CompleteItineraryEdit && !wizardState.isCreate)) {
      history.push(`${Routes.bows}/${bowId}`);
    }

    if (action === BowActions.DeleteBow || action === BowActions.CancelBow || action === QuoteActions.CancelAlternative) {
      history.push(Routes.bows);
    }
  };

  const hateoasSetup = (link: HttpActionProps, body?: {}, sectionName?: string) => {
    setWhatsLoading(sectionName);
    setErrors(undefined);
    resetWarnings();
    setLoading(true);
    setLastRequest(link);
    setLastRequestBody(body);
    setLastRoute(location.pathname);
    setWizardState(prev => ({ ...prev, stepIsDirty: true }));
  };

  const hateoasCleanup = () => {
    setWhatsLoading('');
    setDismissedErrors([]);
    resetWarnings();
    setLoading(false);
  };

  const hateoasExecutor = async <T,>(links: HttpActionProps[] | undefined, action: string, body?: {}, sectionName?: string, config?: AxiosRequestConfig)
    : Promise<AxiosResponse<T> | undefined> => {
    const link = links?.find(n => n.action === action);
    if (!link) {
      const message = isBroker() ? undefined : `Action ${action} missing in links`;
      open(() => openErrorModal(message));
      return undefined;
    }
    hateoasSetup(link, body, sectionName);
    const { data, error } = await simpleRequest<T>(link.href, link.httpVerb as Method, body, config);

    if (error) parseErrors(true, (message, title) => open(openErrorModal(message, title)), error, setErrors);
    else await onSuccess(action);

    hateoasCleanup();
    return data;
  };

  const confirmStatusChange = async (links: WarWeb.HyperMediaLink[], action: string) => {
    const confirmationConfig = quoteStatusConfirmations.find(n => n.key === action);
    if (!bowId || !selectedQuote || !bowData) return;

    const request = { bowId, quoteId: selectedQuote.id };

    // some status changes require a popup (confirmation of some sort), so we need a callback (to hateoasExecutor)
    // if not, we call hateoasExecutor directly
    if (action === QuoteLabel.GenerateQuote) {
      setShowGenerateQuoteModal(true);
    } else if (!confirmationConfig) {
      await hateoasExecutor<WarWeb.BowQuote>(links, action, request, 'quote');
    } else {
      setConfirmationModalProps({
        links:links,
        config: confirmationConfig,
        positiveAction: (response: { [key: string]: boolean } | ExposureOptionsProps) => hateoasExecutor(links, action, { ...request, ...response }, 'quote'),
        closeModal: () => setShowConfirmationModal(false)
      });
      setShowConfirmationModal(true);
    }
  };

  const completeEdit = async () => {
    await hateoasExecutor(selectedQuote?.links, QuoteActions.CompleteItineraryEdit, {}, 'quote');
  };

  const done = async () => {
    if (wizardState.isCreate) return; // don't finish "edit mode" just yet, if creating a new bow
    if (selectedQuote?.settings?.inItineraryEditMode) {
      await completeEdit();
    } else if (bowId && quoteId) history.push(`${Routes.bows}/${getBowQuotePath(bowId, quoteId)}`);
  };

  const warningsPaths = steps.flatMap(u => u.warningsKeys);
  return (
    <HateoasExecutorProvider
      hateoasExecutor={hateoasExecutor}
      lastRequest={lastRequest}
      lastRoute={lastRoute}
      lastRequestBody={lastRequestBody}
      loading={loading}
      setLoading={setLoading}
      whatsLoading={whatsLoading}
    >
      <BowWizardStateProvider
        step={wizardState.step}
        setStepDone={setStepDone}
        isCreate={wizardState.isCreate}
        isNonRateLead={wizardState.isNonRateLead}>

        {loading && whatsLoading === 'quote' && <Disabled />}
        <HeaderActions
          changeStep={changeStep}
          incrementStep={incrementStep}
          warningsPaths={warningsPaths}
          done={done}
          confirmStatusChange={confirmStatusChange}
        />

        {isInternalEnvironment() && (
          <>
            <DebugBar>
              <div>
                bowId:
                {' '}
                {ids.bowId}
                <br />
                quoteId:
                {' '}
                {ids.quoteId}
              </div>
              <div>
                Created:
                {' '}
                {bowData?.created && new Date(Date.parse(bowData?.created.dateTime)).toLocaleString()}
                <br />
                Updated:
                {' '}
                {bowData?.lastUpdated && new Date(Date.parse(bowData?.lastUpdated.dateTime)).toLocaleString()}
              </div>
              <div>
                <Checkbox
                  checked={showState}
                  onChange={() => setShowState(!showState)}
                  name="ShowState"
                  rightLabel="Show State"
                />
              </div>
              {!isBusinessOperations() ? <Button background={theme.green} icon={<Trash />} onClick={reset}>Reset Form</Button> : null}
            </DebugBar>

            {showState && (
              <>
                <TreeView name="state" data={wizardState} />
                <TreeView name="ids" data={ids} />
                <TreeView name="labels" data={labels} />
                <TreeView name="warnings" data={warnings} />
              </>
            )}
          </>
        )}

        <CreateBowContainer>
          {bowData && (
            <SidebarContainer className="sidebar">
              <Sidebar
                header
                confirmStatusChange={confirmStatusChange}
                hateoasExecutor={hateoasExecutor}
                warningsPaths={warningsPaths}
                warningsAreRegex />

              {showState && <TreeView name="breach" data={bowData} />}
            </SidebarContainer>
          )}

          <WizardContainer className="wizard">
            <WizardRoutes resolveStep={resolveStep} />
          </WizardContainer>

        </CreateBowContainer>

        {showGenerateQuoteModal && (
          <GenerateQuoteModal
            closeModal={() => setShowGenerateQuoteModal(false)}
          />
        )}
        {showConfirmationModal && confirmationModalProps && (
          <ConfirmationModal {...confirmationModalProps} />
        )}

      </BowWizardStateProvider>
    </HateoasExecutorProvider>
  );
};
