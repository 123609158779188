import { getUserRole } from '@/components/routing/roles';
import { Routes } from '@/components/routing/routes';
import { FlexBox } from '@/components/shared/FlexBox';
import { useHasScrolled } from '@/hooks/useHasScrolled';
import { useMe } from '@/services/meService';
import {
  PortalSelector, UserMenu, Notifications
} from 'insify-remote-component-loader';
import { theme } from '@/utility/theme';

import { useAccount } from 'its-js-authentication';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';
import { Brand } from './Brand';
import { NavBar } from './NavBar';
import { PortalName } from './PortalName';

const BackgroundContainer = styled.div<{ scrolled?: boolean, impersonated?: boolean }>`
  background-color: ${props => props.theme.marineBlue};
  position: ${props => props.scrolled && 'sticky'};
  top: 0;
  z-index: 50;
      
  ${props => props.impersonated && css`
    border-bottom: 10px solid yellow;
  `};
`;

const HeaderContainer = styled.div<{ scrolled?: boolean }>`
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${props => props.scrolled ? props.theme.header.scrolledHeight : props.theme.header.height};
  padding: ${props => props.theme.portal.contentPadding};

  @media (max-width: ${theme.breakpoints.desktopSmall}) {
      padding: ${props => props.theme.portal.contentPaddingSmallDesktop};
    }

  @media (max-width: ${theme.breakpoints.tabletMax}) {
      padding: ${props => props.theme.portal.contentPaddingTablet};
    }
`;

const WidgetsTogglesWrapper = styled(FlexBox)`
  color: ${props => props.theme.white};
  height: 100%;
  gap: 1em;
`;

const portal = {
  name: 'war',
  displayName: 'WarWeb'
};
const profilePageUrl = '/profile';

const UserMenuFeatureGate = () => {
  const role = getUserRole();
  const history = useHistory();
  const account = useAccount();

  const scrolledHeight = 52; // TODO: Fix height difference from theme, verify
  const hasScrolled = useHasScrolled(scrolledHeight);

  const [notificationsIsOpen, setNotificationsIsOpen] = useState(false);

  const updatesConfig = {
    announcements: true,
    notifications: true
  };

  const links: React.ComponentProps<typeof UserMenu>['links'] = [
    {
      title: 'Notifications',
      onClick: () => setNotificationsIsOpen(true)
    },
    {
      href: `${profilePageUrl}/support`,
      title: 'Feedback & Support'
    },
    {
      href: Routes.announcements,
      title: 'Announcements'
    }
  ];

  return (
    <FlexBox flexDirection="column">
      <UserMenu account={account}
        history={history}
        role={{
          name: role, displayName: role
        }}
        portal={portal}
        links={links}
        profilePageUrl={profilePageUrl}
        config={updatesConfig} />
      <Notifications
        account={account}
        history={history}
        isOpen={notificationsIsOpen}
        setIsOpen={setNotificationsIsOpen}
        top={hasScrolled ? theme.header.scrolledHeight : theme.header.height} />
    </FlexBox>
  );
};

export const Header = ({ scrolled = false, hideMenu = false }) => {
  const { data: me } = useMe();
  const role = getUserRole();
  const history = useHistory();
  const account = useAccount();

  return (
    <BackgroundContainer scrolled={scrolled} impersonated={me?.impersonated}>
      <HeaderContainer scrolled={scrolled}>
        <FlexBox alignItems="center" gap="1.5rem">
          <Brand scrolled={scrolled} />
          <PortalName />
        </FlexBox>
        {!hideMenu && (
          <>
            <NavBar />
            <WidgetsTogglesWrapper flexDirection="row" alignItems="flex-end">
              <UserMenuFeatureGate />
              <PortalSelector account={account} history={history} role={role} portal={{ name: 'Insify War', displayName: 'War' }} />
            </WidgetsTogglesWrapper>
          </>
        )}
      </HeaderContainer>
    </BackgroundContainer>
  );
};
