import { TableSection } from '@/components/shared/Table/core/Components';

export const tableSections : TableSection[] =
[
  {
    columns: [
      { title: '', key: 1 },
      { title: '', key: 2 },
      { title: 'Covered Area',
        format: 'text',
        propertyName: 'CoveredAreas',
        parameterName: 'SelectedCoveredAreas' },
      { title: 'Name',
        format: 'text',
        propertyName: 'AgreementName',
        parameterName: 'SelectedAgreementNames' },
      { title: 'Client',
        format: 'text',
        propertyName: 'Client',
        parameterName: 'SelectedClients' },
      { title: 'Fleet',
        format: 'text',
        propertyName: 'Fleet',
        parameterName: 'SelectedFleets' },
      { title: 'Vessel',
        format: 'text',
        propertyName: 'Vessels',
        parameterName: 'SelectedVessels' },
      { title: 'Vessel Type',
        format: 'text',
        propertyName: 'VesselTypes',
        parameterName: 'SelectedVesselTypes' },
      { title: 'Tier',
        format: 'char',
        propertyName: 'Tier',
        parameterName: 'SelectedTiers' },
    ]
  },
  {
    name: 'Rates',
    subheader: '% - Gross',
    columns: [
      { title: 'H&M',
        format: 'decimal',
        type: 'Number',
        propertyName: 'HmRateNcb',
        parameterName: 'HmRateNcb' },
      { title: 'IV',
        format: 'decimal',
        type: 'Number',
        propertyName: 'TloRateNcb',
        parameterName: 'TloRateNcb' },
      { title: 'LOH',
        format: 'decimal',
        type: 'Number',
        propertyName: 'LohRateNcb',
        parameterName: 'LohRateNcb' },
    ]
  },
  {
    name: 'Bonuses & Rebates',
    subheader: '%',
    columns: [
      { title: 'NCB',
        format: 'decimal',
        type: 'Number',
        propertyName: 'NcbRebate',
        parameterName: 'NcbRebate' },
      { title: 'AGR',
        format: 'decimal',
        type: 'Number',
        propertyName: 'AgrRebate',
        parameterName: 'AgrRebate' },
      { title: 'RCR',
        format: 'decimal',
        type: 'Number',
        propertyName: 'RcrRebate',
        parameterName: 'RcrRebate' },
      { title: 'K&R',
        format: 'decimal',
        type: 'Number',
        propertyName: 'Knr5Rebate',
        parameterName: 'Knr5Rebate' },
    ]
  },
  {
    name: 'General',
    columns: [
      { title: 'H&M net',
        format: 'decimal',
        type: 'Number',
        propertyName: 'NetHmRate',
        parameterName: 'NetHmRate',
        dropdownAlign: 'right' },
      { title: 'Quote Valid (hrs)',
        format: 'int',
        type: 'Number',
        propertyName: 'QuoteValidHours',
        parameterName: 'QuoteValidHours',
        dropdownAlign: 'right' },
      { title: 'BOW Period',
        format: 'int',
        type: 'Number',
        propertyName: 'BreachDays',
        parameterName: 'BreachDays',
        dropdownAlign: 'right' },
      { title: 'K&R Area',
        propertyName: 'HighRiskArea',
        parameterName: 'SelectedHighRiskAreas',
        dropdownAlign: 'right' }
    ]
  }
];
export const tableSectionsWithoutName : TableSection[] =
[
  {
    columns: [
      { title: '', key: 1 },
      { title: '', key: 2 },
      { title: 'Covered Area',
        format: 'text',
        propertyName: 'CoveredAreas',
        parameterName: 'SelectedCoveredAreas' },
      { title: 'Client',
        format: 'text',
        propertyName: 'Client',
        parameterName: 'SelectedClients' },
      { title: 'Fleet',
        format: 'text',
        propertyName: 'Fleet',
        parameterName: 'SelectedFleets' },
      { title: 'Vessel',
        format: 'text',
        propertyName: 'Vessels',
        parameterName: 'SelectedVessels' },
      { title: 'Vessel Type',
        format: 'text',
        propertyName: 'VesselTypes',
        parameterName: 'SelectedVesselTypes' },
      { title: 'Tier',
        format: 'char',
        propertyName: 'Tier',
        parameterName: 'SelectedTiers' },
    ]
  },
  {
    name: 'Rates',
    subheader: '% - Gross',
    columns: [
      { title: 'H&M',
        format: 'decimal',
        type: 'Number',
        propertyName: 'HmRateNcb',
        parameterName: 'HmRateNcb' },
      { title: 'IV',
        format: 'decimal',
        type: 'Number',
        propertyName: 'TloRateNcb',
        parameterName: 'TloRateNcb' },
      { title: 'LOH',
        format: 'decimal',
        type: 'Number',
        propertyName: 'LohRateNcb',
        parameterName: 'LohRateNcb' },
    ]
  },
  {
    name: 'Bonuses & Rebates',
    subheader: '%',
    columns: [
      { title: 'NCB',
        format: 'decimal',
        type: 'Number',
        propertyName: 'NcbRebate',
        parameterName: 'NcbRebate' },
      { title: 'AGR',
        format: 'decimal',
        type: 'Number',
        propertyName: 'AgrRebate',
        parameterName: 'AgrRebate' },
      { title: 'RCR',
        format: 'decimal',
        type: 'Number',
        propertyName: 'RcrRebate',
        parameterName: 'RcrRebate' },
      { title: 'K&R',
        format: 'decimal',
        type: 'Number',
        propertyName: 'Knr5Rebate',
        parameterName: 'Knr5Rebate' },
    ]
  },
  {
    name: 'General',
    columns: [
      { title: 'H&M net',
        format: 'decimal',
        type: 'Number',
        propertyName: 'NetHmRate',
        parameterName: 'NetHmRate',
        dropdownAlign: 'right' },
      { title: 'Quote Valid (hrs)',
        format: 'int',
        type: 'Number',
        propertyName: 'QuoteValidHours',
        parameterName: 'QuoteValidHours',
        dropdownAlign: 'right' },
      { title: 'BOW Period',
        format: 'int',
        type: 'Number',
        propertyName: 'BreachDays',
        parameterName: 'BreachDays',
        dropdownAlign: 'right' },
      { title: 'K&R Area',
        propertyName: 'HighRiskArea',
        parameterName: 'SelectedHighRiskAreas',
        dropdownAlign: 'right' }
    ]
  }
];
