import React from 'react';
import styled from 'styled-components';
import { useValidationContext } from '@/services/ValidationContext';
import { Counter } from './BowSidebar/Components';
import { fieldNameFilter } from '@/utility/errorHelpers';

const ProgressLine = styled.div`
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 6px 4px #4887b122;
`;

const CompletedLine = styled.div<{ step: number, numSteps: number }>`
  background-image: linear-gradient(to right, ${props => props.theme.cyan} ,${props => props.theme.green});
  width: ${props => ((props.step + 1) / props.numSteps) * 100}%;
  height: 6px;
  border-radius: 2px 3px 3px 2px;
`;

const Steps = styled.div` 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 1em;
`;

const Step = styled.div<{ currentStep: boolean }>`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  width: 25%;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  color: ${props => props.currentStep ? props.theme.marineBlue : props.theme.marineBlue50};
  cursor: pointer;
`;
const Container = styled.div`
  background-color: ${props => props.theme.white};
  height: 6px;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
`;

interface ProgressBarProps {
  steps: {
    step: number;
    name: string;
  }[],
  step: number;
  changeStep?: (step: number) => void;
  warningsPaths?: (string | undefined)[];
  warningsAreRegex?: boolean;
  className?: string;
}
export const ProgressBar = ({ steps, step, changeStep, warningsPaths, warningsAreRegex, className }: ProgressBarProps) => {
  const { warnings } = useValidationContext();
  
  return (
    <Container className={className}>
      <ProgressLine>
        <CompletedLine step={step} numSteps={Object.keys(steps).length} />
      </ProgressLine>
      <Steps>
        {steps && steps.map((each, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Step key={`progressBar-${i}`} currentStep={i === step} onClick={changeStep ? () => changeStep(i) : undefined}>
            {each.step}
            .&nbsp;
            {each.name}
            {warnings && warningsPaths && warningsPaths.length > 0 && warningsPaths[i] &&
              <Counter number={fieldNameFilter(warnings, warningsPaths[i], warningsAreRegex).length} />}
          </Step>
        ))}
      </Steps>
    </Container>
  );
};
