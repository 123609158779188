import {
  createContext, PropsWithChildren, useContext
} from 'react';

const BowWizardStateContext = createContext<BowWizardStateProviderProps | undefined>(undefined);

interface BowWizardStateProviderProps {
  isCreate?: boolean;
  step: number;
  setStepDone: (done: boolean) => void;
  isNonRateLead: boolean;
}
const BowWizardStateProvider = ({
  isCreate,
  step,
  setStepDone,
  isNonRateLead,
  children
}: PropsWithChildren<BowWizardStateProviderProps>) => (
  <BowWizardStateContext.Provider value={{
    isCreate,
    step,
    setStepDone,
    isNonRateLead
  }}>
    {children}
  </BowWizardStateContext.Provider>
);

const useBowWizardStateContext = () => {
  const context = useContext(BowWizardStateContext);

  if (context === undefined) {
    throw new Error('useBowWizardStateContext must be used within a BowWizardStateProvider');
  }
  return context;
};

export { BowWizardStateProvider, useBowWizardStateContext };
