import { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Remove } from '@instech/icons';
import Draggable from 'react-draggable';
import { theme } from '@/utility/theme';
import { childrenPropType } from '../../prop-types/custom-prop-types';
import { IconButton } from '../shared/LinkButton/IconButton';

const onClickHandler = fn => evt => evt.target === evt.currentTarget && fn();

const ModalScroll = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: ${props => props.zIndex || 1000};
  overflow-y: scroll;
`;

const Overlay = styled.div`
  width: 100%;
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 40px;
  background-color: rgba(0,0,0,0.65);
  box-sizing: border-box;
  overflow: hidden;
`;

const Container = styled.div`
  pointer-events: none;
  background: none;
  position: relative;
  padding: 0px 16px;
  display: flex;
  justify-content: center;
`;

const LayoutArea = styled.div`
  pointer-events: auto;
  width: 100%;
  max-width: 1110px;
  background: #fff;
  border: 0;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  display: flex;
  flex-direction: column;

  ${props => props.size === 'xl' && css`
    max-width: 1400px;
  `};
  ${props => props.size === 'large' && css`
    max-width: 950px;
  `};

  ${props => props.size === 'medium' && css`
    max-width: 700px;
  `};

  ${props => props.size === 'small' && css`
    max-width: 512px;
  `};
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 2.5rem;
  padding-left: 20px;
  padding-right: 7px;
  background-color: ${theme.lightGray};
  
  ${props => props.isDraggable && css`
    cursor: move;
  `};

  svg {
    height: 20px;
  }
`;

const ModalTitle = styled.span`
  font-size: 20px;
  font-weight: bold;
`;

const ModalBody = styled.div`
  padding: 20px;

`;

const MaybeDraggable = ({ isDraggable, children }) => {
  if (isDraggable) {
    return (
      <Draggable handle=".handle">{children}</Draggable>
    );
  }
  return <>{children}</>;
};

export const ModalLayout = ({ children, options = {}, closeModal, zIndex = 100 }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = null;
    };
  }, []);

  return (
    <ModalScroll zIndex={zIndex}>
      <Overlay onClick={options.clickOutsideToClose ? onClickHandler(closeModal) : undefined}>
        <MaybeDraggable isDraggable={options.draggable}>
          <Container role="dialog">
            <LayoutArea size={options.size}>
              {(options.title || options.showHeader || options.draggable) && (
                <ModalHeader isDraggable={options.draggable} className="handle">
                  <ModalTitle>{options.title}</ModalTitle>
                  <IconButton
                    icon={<Remove />}
                    onClick={closeModal} // Wrapping this in onClickHandler doesn't work?
                  />
                </ModalHeader>
              )}
              <ModalBody hasHeader={options.title || options.showHeader}>
                {children}
              </ModalBody>
            </LayoutArea>
          </Container>
        </MaybeDraggable>
      </Overlay>
    </ModalScroll>
  );
};

ModalLayout.propTypes = {
  children: childrenPropType.isRequired,
  options: PropTypes.shape({
    title: PropTypes.string
  }),
  closeModal: PropTypes.func.isRequired,
  zIndex: PropTypes.number
};
