import React, { MouseEvent } from 'react';
import {
  MentionData,
  MentionPluginTheme,
} from '@draft-js-plugins/mention';

export interface TokenProps {
    className?: string;
    onMouseDown(event: MouseEvent): void;
    onMouseUp(event: MouseEvent): void;
    onMouseEnter(event: MouseEvent): void;
    role: string;
    id: string;
    'aria-selected'?: boolean | 'false' | 'true';
    theme?: MentionPluginTheme;
    mention: MentionData;
    isFocused: boolean;
    searchValue?: string;
  }
export const Token = (props: TokenProps) => {
  const {
    mention,
    theme: mentionTheme,
    searchValue, // eslint-disable-line @typescript-eslint/no-unused-vars
    isFocused, // eslint-disable-line @typescript-eslint/no-unused-vars
    ...parentProps
  } = props;

  return (
    <div {...parentProps}>
      <div className={mentionTheme?.mentionSuggestionsEntryContainer}>
        {mention.name}
      </div>
    </div>
  );
};
