import { WarWeb } from '@/war';
import useSWR from 'swr';
import {
  deleteData, postData, putData
} from './utility/simpleFetch';

const basepath = 'area-aliases';

export const useAreaAlias = (id?: number) =>
  useSWR<WarWeb.AreaAlias>(id ? `${basepath}/${id}` : null);

export const useAreaAliasesByAreaId = (areaId?: number) =>
  useSWR<WarWeb.AreaAlias[]>(areaId ? `${basepath}/areaId/${areaId}` : null);

export const addAreaAliasAsync = (request: WarWeb.AddAreaAliasRequest) =>
  postData(basepath, request);

export const updateAreaAliasAsync = (request: WarWeb.UpdateAreaAliasRequest) =>
  putData(`${basepath}/${request.id}`, request);

export const deleteAreaAliasAsync = (id: number) =>
  deleteData(`${basepath}/${id}`);
