import { Box } from '@/components/shared/Box';
import { Button } from '@/components/shared/Button';
import { AgreementTypeEnum } from '@/models/Agreement';
import { theme } from '@/utility/theme';
import { Pen } from '@instech/icons';
import styled from 'styled-components';
import { AgreementViewMode, useAgreementDetailsContext } from '../../agreementDetails/AgreementDetailsContext';
import { useAgreementsPageContext } from '../../agreements/AgreementsPageContext';
import { useIsBulkEditing } from '../../agreements/useIsBulkEditing';
import { VesselSelector } from '../../specificTermsDetails/appliesToSelection/VesselSelector';
import { PortsSelector } from './PortsSelector';
import { VesselTypeSelector } from './VesselTypeSelector';

const ContentContainer = styled.div`
    display: flex;
`;

const LeftSection = styled.div`
    display: flex;
    justify-content: space-between;
    flex: 1 1 66%;
    flex-direction: column;
`;

const ButtonContainer = styled.div`
  width: 250px;
  padding: 1rem;
`;

export const AppliesToSelection = () => {
  const { agreementType } = useAgreementsPageContext();
  const { viewMode, setIsBulkChangingAreas } = useAgreementDetailsContext();
  const isBulkEdit = useIsBulkEditing();
  return (
    <Box highlight={theme.green} header="Applies to Selection">
      <ContentContainer>
        <LeftSection>
          <PortsSelector />
          {viewMode !== AgreementViewMode.Inspect && !isBulkEdit && (
            <ButtonContainer>
              <Button onClick={() => setIsBulkChangingAreas(true)} icon={<Pen />} background={theme.lightGray}>Add / remove areas</Button>
            </ButtonContainer>
          )}
          {agreementType === AgreementTypeEnum.Standard ? <VesselTypeSelector /> : <VesselSelector />}
        </LeftSection>
      </ContentContainer>
    </Box>
  );
};
