import { QuoteActions } from '@/components/pages/bows/details/quoteActionsMap';
import { isAdmin, isUnderwriter } from '@/components/routing/roles';
import { useHateoasExecutorContext } from '@/services/HateoasExecutorContext';
import { useValidationContext } from '@/services/ValidationContext';
import { addOrRemove } from '@/utility/arrayHelpers';
import { fieldNameFilter } from '@/utility/errorHelpers';
import { formatInteger } from '@/utility/formatter';
import { Edit } from '@instech/icons';
import { useState } from 'react';
import styled from 'styled-components';
import { useSelectedQuote } from '../Bow/useSelectedQuote';
import { checkLink } from '../HateoasHandler';
import {
  Counter, Label, SidebarSubHeader
} from './Components';
import { EditItemWrapper, SidebarEditField } from './SidebarEditField';
import { SidebarItem } from './SidebarItem';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SidebarItemSpaced = styled(SidebarItem)`
  margin-bottom: 10px;
`;

const CurrencyContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  width: 100%;
`;

interface SidebarPremiumItemProps {
  compact?: boolean;
  warningsPath?: string;
  warningsAreRegex: boolean;
}

export const SidebarPremiumItem = ({ compact = false, warningsPath, warningsAreRegex }: SidebarPremiumItemProps) => {
  const { lastRequest } = useHateoasExecutorContext();
  const { warnings } = useValidationContext();

  const [editStates, setEditStates] = useState<string[]>([]); // which fields are enabled (shown)

  const selectedQuote = useSelectedQuote();
  if (!selectedQuote) return null;

  const isAdjustedForMinimumPremium = selectedQuote.calculations?.some(c => c.adjustedForMinimumPremium);
  const netPremiumPrefix = isAdjustedForMinimumPremium ? 'Minimum ' : 'Net ';

  const hasTimeAdjustmentFactor = selectedQuote?.duration?.timeAdjustmentFactor > 0 || false;
  const netPremiumTimeAdjustmentPrefix = !hasTimeAdjustmentFactor ? 'Minimum ' : 'Net ';

  const { premiums } = selectedQuote;
  if (!premiums) return null;

  const toggleEdit = (tag: string) => {
    const updatedState = [...editStates];
    addOrRemove(updatedState, tag);
    setEditStates(updatedState);
  };

  const toolbarGrossPremium = isAdmin() || isUnderwriter() ? [
    {
      icon: <Edit />,
      action: QuoteActions.SetPremium,
      onClick: () => toggleEdit('grossPremium')
    }
  ] : [];

  const toolbarNetPremiumAfterRebates = isAdmin() || isUnderwriter() ? [
    {
      icon: <Edit />,
      action: QuoteActions.SetPremium,
      onClick: () => toggleEdit('netPremiumAfterRebates')
    }
  ] : [];

  const toolbarNetPremiumAfterTimeAdjustment = isAdmin() || isUnderwriter() ? [
    {
      icon: <Edit />,
      action: QuoteActions.SetPremium,
      onClick: () => toggleEdit('netPremiumAfterTimeAdjustment')
    }
  ] : [];

  return (
    <Container>
      <SidebarSubHeader>
        <Label>Additional Premium</Label>
        <Counter number={fieldNameFilter(warnings, warningsPath, warningsAreRegex).length} />
      </SidebarSubHeader>

      {premiums.grossPremium.length > 0 && (
        <SidebarItemSpaced
          compact={compact}
          toolbar={editStates.includes('grossPremium') ? [] : toolbarGrossPremium}
          links={premiums.grossPremium[0]?.links}>

          <h3>Gross premium</h3>
          {premiums.grossPremium.map(n => (
            <SidebarEditField
              key={`${n.currency}-GP`}
              currency={n.currency}
              value={n.value}
              name={`grossPremium-${n.currency}`}
              makeRequest={value => ({ premium: value })}
              action={QuoteActions.SetPremium}
              links={n.links}
              active={editStates?.includes('grossPremium')}
              close={() => toggleEdit('grossPremium')}
              errorKey="Premium"
              isRelevant={!!checkLink(n.links, lastRequest)}
            >
              <CurrencyContainer>
                <h2>
                  {`${n.currency} ${formatInteger(n.value)}`}
                </h2>
              </CurrencyContainer>
            </SidebarEditField>
          ))}
        </SidebarItemSpaced>
      )}

      {premiums.netPremiumAfterRebates.length > 0 && (
        <SidebarItemSpaced
          compact={compact}
          toolbar={editStates.includes('netPremiumAfterRebates') ? [] : toolbarNetPremiumAfterRebates}
          links={premiums.netPremiumAfterRebates[0]?.links}>
          <h3>{`${netPremiumTimeAdjustmentPrefix}premium after rebates`}</h3>
          {premiums.netPremiumAfterRebates.map(n => (
            <SidebarEditField
              key={`${n.currency}-NP`}
              currency={n.currency}
              value={n.value}
              name={`netPremiumAfterRebates-${n.currency}`}
              makeRequest={value => ({ premium: value })}
              action={QuoteActions.SetPremium}
              links={n.links}
              active={editStates?.includes('netPremiumAfterRebates')}
              close={() => toggleEdit('netPremiumAfterRebates')}
              errorKey="Premium"
              isRelevant={!!checkLink(n.links, lastRequest)}
            >
              <CurrencyContainer>
                <h2>
                  {`${n.currency} ${formatInteger(n.value)}`}
                </h2>
              </CurrencyContainer>
            </SidebarEditField>
          ))}
        </SidebarItemSpaced>
      )}

      {premiums.netPremiumAfterTimeAdjustment.length > 0 && (
        <SidebarItemSpaced
          compact={compact}
          toolbar={editStates.includes('netPremiumAfterTimeAdjustment') ? [] : toolbarNetPremiumAfterTimeAdjustment}
          links={premiums.netPremiumAfterTimeAdjustment[0]?.links}>
          <h3>{`${netPremiumPrefix}premium after time adjustment`}</h3>
          {premiums.netPremiumAfterTimeAdjustment.map(n => (
            <SidebarEditField
              key={`${n.currency}-NP_TA`}
              currency={n.currency}
              value={n.value}
              name={`netPremiumAfterTimeAdjustment-${n.currency}`}
              makeRequest={value => ({ premium: value })}
              action={QuoteActions.SetPremium}
              links={n.links}
              active={editStates?.includes('netPremiumAfterTimeAdjustment')}
              close={() => toggleEdit('netPremiumAfterTimeAdjustment')}
              errorKey="Premium"
              isRelevant={!!checkLink(n.links, lastRequest)}
            >
              <CurrencyContainer>
                <h2>
                  {`${n.currency} ${formatInteger(n.value)}`}
                </h2>
              </CurrencyContainer>
            </SidebarEditField>
          ))}
        </SidebarItemSpaced>
      )}

      {premiums.brokerage.length > 0 && (
        <SidebarItemSpaced compact={compact}>
          <h3>Brokerage</h3>
          {premiums.brokerage.map(n => (
            <EditItemWrapper key={`${n.currency}-BR`}>
              <CurrencyContainer>
                <h2>
                  {`${n.currency} ${formatInteger(n.value)}`}
                </h2>
              </CurrencyContainer>
            </EditItemWrapper>
          ))}
        </SidebarItemSpaced>
      )}

      {premiums.netApToUnderwriters.length > 0 && (
        <SidebarItemSpaced compact={compact}>
          <h3>Net AP to Underwriters</h3>
          {premiums.netApToUnderwriters.map(n => (
            <EditItemWrapper key={`${n.currency}-N-AP`}>
              <CurrencyContainer>
                <h2>
                  {`${n.currency} ${formatInteger(n.value)}`}
                </h2>
              </CurrencyContainer>
            </EditItemWrapper>
          ))}
        </SidebarItemSpaced>
      )}

      {premiums.netPremiumAfterTax.length > 0 && (
        <SidebarItemSpaced compact={compact}>
          <h3>{`${netPremiumPrefix}Premium Including Tax`}</h3>
          {premiums.netPremiumAfterTax.map(n => (
            <EditItemWrapper key={`${n.currency}-AT`}>
              <CurrencyContainer>
                <h2>
                  {`${n.currency} ${formatInteger(n.value)}`}
                </h2>
              </CurrencyContainer>
            </EditItemWrapper>
          ))}
        </SidebarItemSpaced>
      )}
    </Container>
  );
};
