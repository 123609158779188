import { Box } from '@/components/shared/Box';
import { Chevron } from '@/components/shared/Chevron';
import {
  NoDataRow, renderTableHeaders, Table, TableCell, TableRow
} from '@/components/shared/Table/Table';
import { useExcludedAreas } from '@/services/excludedAreasService';
import { theme } from '@/utility/theme';
import { WarWeb } from '@/war';
import { Image2 } from '@instech/icons';
import { useMemo } from 'react';
import { useExcludedAreaPageContext } from '../context/ExcludedAreaPageContext';

const tableHeaders = ['Name', 'Code', ''];

const AreaRow = ({ area }: { area: WarWeb.Area }) => {
  const { areaId, setAreaId, setAreaType } = useExcludedAreaPageContext();

  const setArea = (selectedArea: WarWeb.Area) => {
    setAreaType(selectedArea.areaType);
    setAreaId(selectedArea.id);
  };

  return (
    <TableRow backgroundColor={areaId === area.id ? theme.lightGreen50 : undefined} onClick={() => setArea(area)}>
      <TableCell>{area.name}</TableCell>
      <TableCell>{area.locode}</TableCell>
      <TableCell right><Chevron right /></TableCell>
    </TableRow>
  );
};

export const AreaColumn = () => {
  const { regionId } = useExcludedAreaPageContext();
  const { data: areas, error } = useExcludedAreas(regionId);
  const sortedAreas = useMemo(() => areas?.sort((x, y) => (x.name > y.name) ? 1 : -1), [areas]);

  const renderAreas = () => {
    if (!regionId) return null;
    return sortedAreas ?
      sortedAreas.map(area => <AreaRow key={area.id} area={area} />) :
      <NoDataRow loadStatus={{ loading: !areas && !error, success: !error }} />;
  };

  return (
    <Box icon={<Image2 />} header="Areas">
      <Table columns={tableHeaders.length} striped>
        {renderTableHeaders(tableHeaders)}
        {renderAreas()}
      </Table>
    </Box>
  );
};
