import { WarWeb } from '@/war';

export const getRequestHasChanges = (request: WarWeb.AgreementUpdateParameters) =>
  Object.entries(request).some(([key, value]) => {
    if (key === 'targets') return false;
    if (key === 'updateComment') return false;
    if (value == null) return false;
    if (Array.isArray(value)) return value.length > 0;
    return true;
  });
