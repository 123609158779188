import * as CSS from 'csstype';
import styled, { css } from 'styled-components';

interface FlexBoxProps {
  justifyContent?: CSS.Property.JustifyContent;
  alignItems?: CSS.Property.AlignItems;
  flexDirection?: CSS.Property.FlexDirection;
  center?: boolean;
  gap?: string;
}

export const FlexBox = styled.div<FlexBoxProps>`
  display: flex;



  ${props => props.center && css`
    justify-content: center;
    align-items: center;
  `}

  ${props => props.flexDirection && css`
    flex-direction: ${props.flexDirection};
  `}
  ${props => props.justifyContent && css`
    justify-content: ${props.justifyContent};
  `}
  ${props => props.alignItems && css`
    align-items: ${props.alignItems};
  `}
  ${props => props.gap && css`
    gap: ${props.gap};
  `}
`;
