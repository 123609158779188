import client from '@/services/client';
import { postData } from '@/services/utility/simpleFetch';

const downloadBlob = (blob, filename) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
};

export const addTimezoneOffset = uri => {
  const timeZoneOffset = new Date().getTimezoneOffset();
  return `${uri}${uri.indexOf('?') < 0 ? '?' : '&'}timezoneOffset=${timeZoneOffset}`;
};

export const parseBlobData = (response, type) => {
  const [, headers] = response.headers['content-disposition'].split('filename=');
  const [result,] = headers.split(';');
  const returnedFileName = decodeURIComponent(result);

  downloadBlob(new Blob([response.data], { type }), returnedFileName);
};

export const downloadSearchData = async (uri, filters, type) => {
  const { data: response } = await postData(addTimezoneOffset(uri), filters, { headers: {
    Accept: type
  },
  responseType: 'blob', timeout: 300000 });

  parseBlobData(response, type);
};

export const downloadData = async (uri, type) => {
  const response = await client.get(addTimezoneOffset(uri), { headers: {
    Accept: type
  },
  responseType: 'blob' });

  parseBlobData(response, type);
};
