import { useEffect } from 'react';
import useDebounce from '@/hooks/useDebouncedValue';
import { Filters } from '@/models/Filters';
import { usePorts } from '@/services/portsService';
import { WarWeb } from '@/war';
import { useEditExcludedAreasContext } from '../EditExcludedAreasContext/EditExcludedAreasContext';
import { isNewSelection } from './utils';

const mapPort = (p: WarWeb.AreaMatch, areaId?: number) => ({
  locode: p.locode,
  areaId: p.oceanAreaId ?? p.countryId,
  isCustomArea: !!p.oceanAreaId,
  ...(p.isExcluded && { excluded: { persistedState: true } }),
  ...((p.oceanAreaId ?? p.countryId) === areaId && { inArea: { persistedState: true } }),
  HRA: { persistedState: p.highRiskAreaId }
});

export const useUpdateSelectedPorts = (isCustomArea: boolean, ports?: WarWeb.AreaMatch[]) => {
  const { state: { areaId, selectedPorts }, setSelectedPorts } = useEditExcludedAreasContext();

  useEffect(() => {
    if (!ports) return;
    const newSelectedPorts = selectedPorts?.filter(p => isNewSelection(p)) || [];

    const isOldScelectedPort = (p: WarWeb.AreaMatch) => {
      const isNew = newSelectedPorts.some(s => s.locode === p.locode);
      return !isNew && (p.isExcluded || (isCustomArea ? p.oceanAreaId === areaId : p.countryId === areaId));
    };

    const oldSelectedPorts = ports
      ?.filter(isOldScelectedPort)
      .map(p => mapPort(p, areaId)) ?? [];

    const portsArray = [...newSelectedPorts, ...oldSelectedPorts];

    const uniqueSelectedPorts = [
      ...new Set(portsArray.map(o => JSON.stringify(o))),
    ].map(string => JSON.parse(string));

    setSelectedPorts(uniqueSelectedPorts);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ports, isCustomArea, setSelectedPorts, areaId]);
};

export const usePortsSearch = (filters: Filters, isCustomArea: boolean, filterRegionId?: number) => {
  const { state: { areaId, regionId } } = useEditExcludedAreasContext();
  const debouncedFilters = useDebounce(filters, 500);
  const baseFilters = isCustomArea ? { regionId: filterRegionId } : { countryId: areaId };
  const requestIsNonNull = regionId && areaId;
  const { data, error, isLoading } = usePorts(requestIsNonNull ? { selectedAreaTypes: ['Port'], ...baseFilters, ...debouncedFilters } : null);

  return { portSearchResults: data, error, isLoading };
};
