import React from 'react';
import { Conditions } from '../../defaultTermsDetails/settings/textConditions/Conditions';
import { DuoTableLayout } from '../../defaultTermsDetails/shared/SettingsComponents';

export const ConditionsContainer = () => (
  <DuoTableLayout>
    <Conditions conditionsType="warranties" agreementType="SpecificAgreement" />
    <Conditions conditionsType="subjectivities" agreementType="SpecificAgreement" />
  </DuoTableLayout>
);
