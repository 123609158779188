import React, {
  FC, useRef, useState
} from 'react';
import styled from 'styled-components';
import { DownArrow } from '@instech/icons';
import { useOutsideAlerter } from '@/hooks/mouseEventHelpers';
import { FieldLayout } from '../core/Components';
import { DropdownElement, SearchResults } from './SearchResults';

const FitFieldLayout = styled(FieldLayout)`
  position: relative;
  display: inline-block;
  width: 100%; 
`;

const SelectedItem = styled.div`
  display: flex;
  width: 100%;
`;

const SelectedElement = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid ${props => props.theme.marineBlue50};
`;

interface SelectDropdownProps {
  results?: any[],
  selectedItem?: any,
  label?: string;
  keyField?: string;
  placeholder?: string;
  callback: (item: any) => void;
  renderResult: (item: any) => React.ReactNode;
}
export const SelectDropdown : FC<SelectDropdownProps> = ({ results, selectedItem, callback, renderResult, keyField }) => {
  const [display, setDisplay] = useState(false);
  const wrapperRef = useRef(null);

  const close = () => {
    setDisplay(false);
  };

  const select = (item: any) => {
    callback(item);
    close();
  };

  useOutsideAlerter(wrapperRef, display, close);

  return (
    <FitFieldLayout ref={wrapperRef}>
      <SelectedElement onClick={() => setDisplay(!display)}>
        <DropdownElement info>
          <SelectedItem>
            {renderResult(selectedItem)}
          </SelectedItem>
          <div>
            <DownArrow />
          </div>
        </DropdownElement>
      </SelectedElement>
      <SearchResults
        results={results}
        display={display}
        select={select}
        renderResult={renderResult}
        keyField={keyField}
      />
    </FitFieldLayout>
  );
};
