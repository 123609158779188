import { PageHeader } from '@/components/layout/PageHeader';
import {
  isBroker,
  isBusinessOperations, isInternalUser, isReinsurer
} from '@/components/routing/roles';
import { Routes } from '@/components/routing/routes';
import { SectionDropdown } from '@/components/shared/Bow/core/SectionDropdown';
import { Button } from '@/components/shared/Button';
import { FlexBox } from '@/components/shared/FlexBox';
import { ButtonItem, DropdownButton } from '@/components/shared/Form/fields/DropdownButton';
import { Filters } from '@/models/Filters';
import { theme } from '@/utility/theme';
import { WarWeb } from '@/war';
import { Add } from '@instech/icons';
import React, {
  PropsWithChildren, useState, useEffect
} from 'react';

import styled from 'styled-components';

const Page = styled.div`
`;

const HeaderContainer = styled(FlexBox)`
  width: 100%;
`;

const DropdownWrapper = styled(FlexBox)`
  width: 200px;
`;

interface DashboadLayoutProps {
  filters?: Filters;
  setFilters?: React.Dispatch<React.SetStateAction<Filters>>;
}

export const DashboardLayout = ({ filters, setFilters, children }: PropsWithChildren<DashboadLayoutProps>) => {
  const shouldSeeSectionDropdown = isInternalUser();
  const [underwriterSection, setUnderwriterSection] = useState<WarWeb.Section>();

  useEffect(() => {
    if (setFilters) setFilters({ ...filters, selectedSectionCode: underwriterSection?.code });
  }, [underwriterSection]);

  const buttonSelections: ButtonItem[] = [
    {
      id: '1',
      text: 'Create New Bow',
      to: Routes.createbow,
      background: theme.green,
      icon: <Add />
    },
    {
      id: '2',
      text: 'Create Non Rate Lead Bow',
      to: `${Routes.createbow}?NonRateLead`,
      background: theme.white,
      icon: <Add />
    }
  ];

  return (
    <Page>
      <HeaderContainer alignItems="center" justifyContent="space-between">

        <FlexBox alignItems="center" gap="2em">
          <PageHeader header="Dashboard" />

          {shouldSeeSectionDropdown && setFilters && (
            <DropdownWrapper>
              <SectionDropdown
                underwriterSection={underwriterSection}
                setUnderwriterSection={setUnderwriterSection}
              />
            </DropdownWrapper>
          )}
        </FlexBox>
        {!isBusinessOperations() && !isReinsurer() && (
          <div>
            {isBroker() ? <Button to={Routes.createbow} background={theme.green} icon={<Add />}>New bow</Button>
              : <DropdownButton options={buttonSelections} />}
          </div>
        )}

      </HeaderContainer>

      {children}
    </Page>
  );
};
