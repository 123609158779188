import { WarWeb } from '@/war';
import { useAreaAliasesByAreaId } from '@/services/areaAliasService';
import { useEntryExitPoints } from '@/services/excludedAreasService';
import { useEffect } from 'react';
import { AreaAliasSpecification } from './types';

const mapToAreaAliasSpecification = (alias: WarWeb.AreaAlias, entriesExits: WarWeb.EntryExitPoint[]): AreaAliasSpecification => {
  const entryName = entriesExits.find(e => e.id === alias.entryPointId)?.name;
  const exitName = entriesExits.find(e => e.id === alias.exitPointId)?.name;
  const description = `${entryName} -> ${exitName}`;
  return ({
    ...alias,
    description,
    action: 'NotSet'
  });
};

export const useSeedAreaAliasSpecifications = (
  callback : (aliases: AreaAliasSpecification[]) => void,
  existingAliases: AreaAliasSpecification[],
  areaId?: number
) => {
  const isExistingArea = areaId && areaId > 0;
  const { data: areaAliases } = useAreaAliasesByAreaId(isExistingArea ? areaId : undefined);
  const { data: entriesExits } = useEntryExitPoints(isExistingArea ? areaId : undefined);

  useEffect(() => {
    if (areaAliases === undefined || entriesExits === undefined) return;
    const aliasSpecifications = areaAliases
      .map(alias => mapToAreaAliasSpecification(alias, entriesExits));
    const existing = existingAliases
      .filter(a => !aliasSpecifications.some(x => x.id === a.id));

    callback(existing.concat(aliasSpecifications));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaAliases, entriesExits, callback]);
};
