import { Area } from '@/components/pages/specificTermsConditions/Area';
import { isBroker, isInternalUser } from '@/components/routing/roles';
import { Chevron } from '@/components/shared/Chevron';
import { FlexBox } from '@/components/shared/FlexBox';
import {
  getColspans,
  getColumnWidth,
  getDelimiters, getHeaders, renderTableSectionHeaders, TableColumnHeader
} from '@/components/shared/Table/core/Components';
import { HiddenHeader } from '@/components/shared/Table/core/FilterableTableHeaders';
import {
  FilterableTableHeaders, NoDataRow, Table, TableCell, TableRow
} from '@/components/shared/Table/Table';
import { usePageSettingsContext } from '@/components/userSettings/PageSettingsContext';
import { SortedHeader } from '@/models/SortOrder';
import { NetworkStatusLoading } from '@/services/NetworkStatusLoading';
import { basePathSpecificTerms, getBasePathBrokerTerms } from '@/services/termsService';
import { groupBy } from '@/utility/arrayHelpers';
import { GlobalStoreKeys } from '@/utility/globals';
import { SessionStore } from '@/utility/sessionStore';
import { addOrRemoveArray } from '@/utility/stateArrayHelpers';
import { theme } from '@/utility/theme';
import { WarWeb } from '@/war';
import React, {
  PropsWithChildren, useEffect, useState
} from 'react';
import styled from 'styled-components';
import { useFilterableTableHeadersContext } from '../defaultTermsConditions/FilterableTableHeadersContext';
import { CountrySelection } from '../defaultTermsConditions/TermsConditionsPageContext';
import { tableSections as tableSectionsAdmin, tableSectionsWithoutName as tableSectionsAdminWithoutName } from './tableHeaders';
import { tableSectionsBroker, tableSectionsBrokerClaimsPenalty, tableSectionsBrokerClaimsPenaltyWithoutName, tableSectionsBrokerWithoutName } from './tableHeadersBroker';
import { useTermsConditionsPageContext } from './TermsConditionsPageContext';

const RegionCell = styled(TableCell)`
  font-size: 16px;
`;

interface RegionProps {
  region: string;
  countries: WarWeb.SpecificTcGroup[] | WarWeb.BrokerTcGroup[];
  expanded: CountrySelection[];
  toggleCountry: (level1: string, level2: string) => void;
  colspans: number[];
}
const Region = ({ region, countries, expanded, toggleCountry, colspans }: RegionProps) => {
  const [showRegion, setShowRegion] = useState(SessionStore.get(GlobalStoreKeys.TermsConditionsRegionsToggled)?.[region] ?? true);
  const [focusedId, setFocusedId] = useState<string>();

  const toggleRegion = () => {
    const existing = SessionStore.get(GlobalStoreKeys.TermsConditionsRegionsToggled);
    const result = existing ? { ...existing, [region]: !showRegion } : { [region]: !showRegion };
    SessionStore.set(GlobalStoreKeys.TermsConditionsRegionsToggled, result);
    setShowRegion(!showRegion);
  };

  const colspansCopy = [...colspans];

  return (
    <>
      <TableRow backgroundColor={theme.blueGray} onClick={toggleRegion}>
        {isInternalUser() && <TableCell />}
        <TableCell>
          <FlexBox center>
            {showRegion ? <Chevron top /> : <Chevron bottom />}
          </FlexBox>
        </TableCell>
        <RegionCell span={colspansCopy[0] - (isInternalUser() ? 2 : 1)}>
          {region}
        </RegionCell>
        {colspansCopy.splice(1).map((d, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <TableCell key={`${region}-${i}`} delimiter="left" span={d} />
        ))}
      </TableRow>
      {showRegion && countries?.map((terms: WarWeb.SpecificTcGroup | WarWeb.BrokerTcGroup) => (
        <Area
          key={`${terms.level1}.${terms.level2}`}
          terms={terms}
          expanded={expanded}
          toggleCountry={toggleCountry}
          focusedId={focusedId}
          setFocusedId={setFocusedId} />
      ))}
    </>
  );
};

export const defaultFilters = { pageNumber: 1, pageSize: 2000, orderBy: 'LastUpdated desc' };

export const resolveTableSections = (isClaimsPenaltyBased?: boolean, isNameColumnVisible?: boolean) => {
  if (isInternalUser()) return isNameColumnVisible ? tableSectionsAdmin : tableSectionsAdminWithoutName;
  if (isBroker()) {
    if (isClaimsPenaltyBased) return isNameColumnVisible ? tableSectionsBrokerClaimsPenalty : tableSectionsBrokerClaimsPenaltyWithoutName;
    else return isNameColumnVisible ? tableSectionsBroker : tableSectionsBrokerWithoutName;
  }
  return [];
};

interface TableWrapperProps {
  headers: TableColumnHeader[];
  sticky?: boolean;
  striped?: boolean;
}

const TableWrapper = ({ children, headers, ...props }: PropsWithChildren<TableWrapperProps>) => (
  <Table columns={headers.length} layout={headers.map(h => getColumnWidth(h.format)).join(' ')} {...props}>
    {children}
  </Table>
);

interface TermsConditionsTableProps {
  loadStatus: NetworkStatusLoading
}

export const TermsConditionsTable = ({
  loadStatus
}: TermsConditionsTableProps) => {
  const { pageSettings, setPageSettings } = usePageSettingsContext();
  const { filters } = useFilterableTableHeadersContext();

  const { fleetId, isClaimsPenaltyBased, regions, isNameColumnVisible } = useTermsConditionsPageContext();

  const tableSections = resolveTableSections(isClaimsPenaltyBased, isNameColumnVisible);
  const headers = getHeaders(tableSections);
  
  const restoredState = pageSettings?.specificTerms;
  const [expanded, setExpanded] = useState<CountrySelection[]>(restoredState?.expanded || []);

  useEffect(() => {
    if (regions) {
      if (filters.SelectedCoveredAreas) setExpanded(regions.items.map(a => ({ level1: a.level1, level2: a.level2 })) || []);
    }
  }, [regions]);

  useEffect(() => {
    if (!filters) setExpanded([]);
  }, [filters]);

  useEffect(() => {
    setPageSettings(
      {
        specificTerms: {
          ...pageSettings?.specificTerms,
          expanded
        }
      }
    );
  }, [expanded]);

  const toggleCountry = (level1: string, level2: string) => {
    addOrRemoveArray(expanded, setExpanded, a => a.level1 === level1 && a.level2 === level2, { level1, level2 });
  };

  const header = (
    <FilterableTableHeaders
      endpoint={isBroker() ? getBasePathBrokerTerms(fleetId) : basePathSpecificTerms}
      delimiters={getDelimiters(tableSections)}
      wide
    />
  );

  const hiddenHeader = React.cloneElement(header, { hidden: true });

  return (
    <>
      <TableWrapper headers={headers} sticky>
        {renderTableSectionHeaders(tableSections)}
        {header}
      </TableWrapper>

      <TableWrapper headers={headers} striped>
        <HiddenHeader>
          {hiddenHeader}
        </HiddenHeader>

        {!loadStatus.loading && regions && regions.items?.length > 0
          ? groupBy(regions.items, (item: WarWeb.SpecificTcGroup | WarWeb.BrokerTcGroup) => [item.level1])
            .map((countries: WarWeb.SpecificTcGroup[] | WarWeb.BrokerTcGroup[]) => (
              <Region
                key={`${countries[0].level1}.${countries[0].level2}`}
                region={countries[0].level1}
                countries={countries}
                expanded={expanded}
                toggleCountry={toggleCountry}
                colspans={getColspans(tableSections)}
              />
            ))
          : <NoDataRow loadStatus={loadStatus} />}
      </TableWrapper>
    </>
  );
};
