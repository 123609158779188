import { Update as UpdatesPageWidget } from 'insify-remote-component-loader';
import { useAccount } from 'its-js-authentication';
import React from 'react';
import { useHistory } from 'react-router';

export const UpdatesPage = () => {
    const history = useHistory();
    const account = useAccount();
    const portal = {
        displayName: 'WarWeb',
        name: 'war'
    };

    return (
        <UpdatesPageWidget account={account} history={history} portal={portal} />
    );
};
