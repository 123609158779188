import React from 'react';
import { isBroker } from '@/components/routing/roles';
import { TableCell } from '@/components/shared/Table/Table';
import { BrokerTcMatch, SpecificTcMatch } from '@/models/TermsMatch';
import { formatDecimal, formatInteger } from '@/utility/formatter';
import { WarWeb } from '@/war';
import { Tooltip } from '@/components/shared/Table/core/Components';
import { hraNameSplitter } from '@/utility/hraNameSplitter';

export const renderSharedSpecificTermsTableCells = (
  terms: WarWeb.SpecificTcMatch | WarWeb.BrokerTcMatch | WarWeb.SpecificTcGroup | WarWeb.BrokerTcGroup,
  isClaimsPenaltyBased?: boolean
) => {
  const hraField = (terms as SpecificTcMatch).highRiskArea;
  const [hra, hraDesc] = hraNameSplitter(hraField);

  return (
    <>
      {!isClaimsPenaltyBased ? (
        <>
          <TableCell delimiter="left">{formatDecimal(terms.hmRateNcb)}</TableCell>
          <TableCell>{formatDecimal(terms.tloRateNcb)}</TableCell>
          <TableCell>{formatDecimal(terms.lohRateNcb)}</TableCell>
          <TableCell right delimiter="left">{terms.ncbRebate}</TableCell>
        </>
      ) : (
        <>
          <TableCell delimiter="left">{formatDecimal(terms.hmRateCp)}</TableCell>
          <TableCell>{formatDecimal(terms.tloRateCp)}</TableCell>
          <TableCell>{formatDecimal(terms.lohRateCp)}</TableCell>
          <TableCell right delimiter="left">{terms.claimsPenalty}</TableCell>
        </>
      )}
      <TableCell right>{terms.agrRebate}</TableCell>
      <TableCell right>{terms.rcrRebate}</TableCell>
      <TableCell right>{formatDecimal(terms.knr5Rebate)}</TableCell>
      {isBroker() && (
        <>
          <TableCell delimiter="left">
            {formatInteger((terms as BrokerTcMatch).singleTransitMax)}
          </TableCell>
          <TableCell>
            {formatInteger((terms as BrokerTcMatch).singleTransitArmedGuardsMax)}
          </TableCell>
        </>
      )}
      <TableCell delimiter="left">{formatDecimal(terms.netHmRate)}</TableCell>
      <TableCell right>{terms.quoteValidHours}</TableCell>
      <TableCell right>{terms.breachDays}</TableCell>
      <TableCell>
        <Tooltip name={hraDesc}>{hra}</Tooltip>
      </TableCell>
    </>
  );
};
