import {
  Table, TableCell, TableRow
} from '@/components/shared/Table/Table';
import { WarWeb } from '@/war';

import { formatInteger } from '@/utility/formatter';
import styled from 'styled-components';
import { groupBy } from '@/utility/arrayHelpers';
import moment from 'moment';
import { FlexBox } from '@/components/shared/FlexBox';

const InterestContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 85%;
`;

const Currency = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  color: ${props => props.theme.marineBlue75};
`;

const SumInsured = styled.div`
  display: flex;
  text-align: right;
`;

const renderSum = (interest?: WarWeb.InterestMatch) => {
  if (!interest) return <InterestContainer>-</InterestContainer>;
  const { currency, sumInsured } = interest;

  return (
    <InterestContainer>
      <Currency>
        {currency}
      </Currency>
      <SumInsured>
        {formatInteger(sumInsured)}
      </SumInsured>
    </InterestContainer>
  );
};

const Interest = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.4em;
`;

interface InterestMatchId extends WarWeb.InterestMatch {
  id: number
}

const renderInterests = (vesselId: string, id: number, period?: InterestMatchId[]) => {
  const interest = period?.filter(i => i.id === id)?.sort((a, b) => a.currency.localeCompare(b.currency)); // possible multiple currencies
  const isEmpty = !interest?.some(x => x);

  return (
    <Interest
      key={`${vesselId}-interest-${id}`}>
      {isEmpty ? '-' :
        interest?.map(i => (
          <FlexBox key={`${vesselId}-interest-${id}-${i.currency}`}>{renderSum(i)}</FlexBox>))}
    </Interest>
  );
};

export const InterestsSubtable = ({ vessel, layout }: {
  vessel: WarWeb.MarineMatch,
  layout?: string
}) => {
  const addId = (id: number, data?: WarWeb.InterestMatch[]) => data?.map(d => ({
    ...d,
    id
  }));

  const InterestIds = {
    HullAndMachineryInterest: 0,
    TotalLossInterest: 1,
    LossOfHireInterest: 2,
    KidnapRansomInterest: 3
  };

  const interests = [
    addId(InterestIds.HullAndMachineryInterest, vessel.hullAndMachineryInterest),
    addId(InterestIds.TotalLossInterest, vessel.totalLossInterest),
    addId(InterestIds.LossOfHireInterest, vessel.lossOfHireInterest),
    addId(InterestIds.KidnapRansomInterest, vessel.kidnapRansomInterest)
  ].flat();

  const periods: InterestMatchId[][] = groupBy(interests, (it: WarWeb.InterestMatch) => it.fromDate + it.toDate);

  periods.sort((a: InterestMatchId[], b: InterestMatchId[]) => {
    const fromDateA = moment(a[0].fromDate);
    const fromDateB = moment(b[0].fromDate);
    return fromDateA.isBefore(fromDateB) ? -1 : 1;
  });

  return (
    <Table columns={5} layout={layout} backgroundColor="transparent">
      {periods.map(period => (
        <TableRow key={`${period[0].fromDate}-${period[0].toDate}`}>
          <TableCell>
            {`${moment(period[0].fromDate).format('DD.MM.YYYY')} - ${moment(period[0].toDate).format('DD.MM.YYYY')}`}
          </TableCell>
          <TableCell right>{renderInterests(vessel.id, InterestIds.HullAndMachineryInterest, period)}</TableCell>
          <TableCell right>{renderInterests(vessel.id, InterestIds.TotalLossInterest, period)}</TableCell>
          <TableCell right>{renderInterests(vessel.id, InterestIds.LossOfHireInterest, period)}</TableCell>
          <TableCell right>{renderInterests(vessel.id, InterestIds.KidnapRansomInterest, period)}</TableCell>
        </TableRow>
      ))}
    </Table>
  );
};
