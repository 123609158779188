/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

export const SelectionInputContainer = styled.div`
  padding: 1rem;
  min-width: 320px;

  > div {
    width: 100%;
  }
`;

export const FilterTargets = styled.div`
  padding: 0.5rem;
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  align-items: flex-end;
`;

export const FilterValue = styled.span<{ ocean?: boolean, vesselType?: boolean, isEdit?: boolean, isExcluded?: boolean }>`
  padding: 0.5rem 1rem;
  padding-right: ${props => !props.isEdit || props.ocean ? '1rem' : null};
  background-color: ${props => !props.isExcluded ? props.theme.lightGreen50 : props.ocean ? props.theme.mediumBlue : props.theme.border.gray};
  ${props => props.vesselType && css`
    background-color: ${props.theme.ultraLightGray};
  `}
  font-size: 14px;
  margin: 0.5rem;
  border-radius: 4px;
  display: flex;
  align-items: center;

  ${props => props.isEdit && css`
    &:hover {
      cursor: pointer;
      background-color: ${props.theme.green};
      background-color: ${props.isExcluded ? props.theme.blueGray : ''};
      background-color: ${props.ocean ? props.theme.softBlue : ''};
      background-color: ${props.vesselType ? props.theme.lightGray : ''};
    }
  `}
;

svg {
  height: 1.5rem;
}
`;

export const FilterValueNameContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FilterHeader = styled.div`
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-size: 14px;
`;
