import { isBroker, isInternalUser } from '@/components/routing/roles';
import { Chevron } from '@/components/shared/Chevron';
import { Checkbox } from '@/components/shared/Form/fields/Checkbox';
import { getColspans } from '@/components/shared/Table/core/Components';
import { TableCell, TableRow } from '@/components/shared/Table/Table';
import { useTermsConditions } from '@/services/termsService';
import { WarWeb } from '@/war';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAgreementsOverviewContext } from '../agreementsOverview/AgreementsOverviewContext';
import { useFilterableTableHeadersContext } from '../defaultTermsConditions/FilterableTableHeadersContext';
import { CountrySelection } from '../defaultTermsConditions/TermsConditionsPageContext';
import { renderSharedSpecificTermsTableCells } from './SharedTableCells';
import { tableSections as tableSectionsAdmin, tableSectionsWithoutName as tableSectionsAdminWithoutName } from './tableHeaders';
import { tableSectionsBroker, tableSectionsBrokerWithoutName } from './tableHeadersBroker';
import { TermsConditions } from './TermsConditions';
import { useTermsConditionsPageContext } from './TermsConditionsPageContext';

const StyledFleetLink = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;

  & svg {
    transform: rotate(180deg);
  }
`;

const AreaName = styled.div`
  cursor: pointer;
`;

interface AreaRowProps {
  terms: WarWeb.SpecificTcGroup | WarWeb.BrokerTcGroup;
  expanded: boolean;
  toggleExpand: (e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => void;
  focusedId?: string;
  setFocusedId: React.Dispatch<React.SetStateAction<string | undefined>>;
}
const AreaRow = ({ terms, expanded, toggleExpand, focusedId, setFocusedId }: AreaRowProps) => {
  const { agreementEntries, setAgreementEntries } = useAgreementsOverviewContext();
  const { fleetId, isClaimsPenaltyBased, editAction, isNameColumnVisible } = useTermsConditionsPageContext();
  const { filters } = useFilterableTableHeadersContext();

  const tableSections = isBroker() ? (isNameColumnVisible ? tableSectionsBroker : tableSectionsBrokerWithoutName) : (isNameColumnVisible ? tableSectionsAdmin : tableSectionsAdminWithoutName);

  const colspans = getColspans(tableSections);

  const [isRowChecked, setIsRowChecked] = useState(false);

  const baseParams = { ...filters, selectedLevel1: [terms.level1], selectedLevel2: [terms.level2] };
  const params = isBroker() ? { ...baseParams, fleetId } : baseParams;
  const { data: agreementsOnArea } = useTermsConditions(isRowChecked ? params : undefined, JSON.stringify(params));

  const handleInputChange = () => {
    setIsRowChecked(!isRowChecked);
  };

  useEffect(() => {
    const entriesExceptCurrent = agreementEntries.filter(x => !(x.level1 === terms.level1 && x.level2 === terms.level2));

    if (!isRowChecked) {
      setAgreementEntries(entriesExceptCurrent);
      return;
    }

    if (!agreementsOnArea) return;

    const entries = [...entriesExceptCurrent,
      ...agreementsOnArea.items.map(x => ({
        agreementId: x.agreementId,
        agreementIds: x.agreementIds,
        fleetId: x.fleetId,
        areaIds: x.coveredAreaIds,
        vesselIds: x.vesselIds,
        level1: x.level1,
        level2: x.level2,
        type: x.coveredAreaType
      }))
    ];

    setAgreementEntries(entries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRowChecked, agreementsOnArea]);

  const agreementsForCurrentRow = agreementEntries.filter(x => x.level1 === terms.level1 && x.level2 === terms.level2);

  const hasSomeSelected = agreementsForCurrentRow.length > 0;
  const isAllSelected = hasSomeSelected && agreementsForCurrentRow.length === agreementsOnArea?.items.length;

  // TODO: Consider adding "isChecked" as prop on the row in addition to selection data, instead of current local state "isSelected"

  return (
    <TableRow hasFocus={focusedId === `${terms.level1}.${terms.level2}`} onHover={() => setFocusedId(undefined)} hover>
      {isInternalUser() && terms.coveredAreaType !== 'Trade' && (
        <TableCell slim onClick={handleInputChange}>
          <Checkbox
            readOnly
            name={`${terms.level1}.${terms.level2}`}
            checked={hasSomeSelected}
            indeterminate={hasSomeSelected && !isAllSelected}
            onKeyDown={e => e.key === 'Enter' ? editAction() : toggleExpand(e)}
            onFocus={() => setFocusedId(`${terms.level1}.${terms.level2}`)}
            onBlur={() => setFocusedId(undefined)}
          />
        </TableCell>
      )}
      {isInternalUser() && terms.coveredAreaType === 'Trade' && <TableCell />}
      <TableCell slim hover>
        <StyledFleetLink tabIndex={isBroker() || terms.coveredAreaType === 'Trade' ? 0 : undefined} onClick={toggleExpand} onKeyDown={toggleExpand}>
          {expanded ? <Chevron top thin /> : <Chevron bottom thin />}
        </StyledFleetLink>
      </TableCell>
      <TableCell span={colspans[0] - (isBroker() ? 1 : 2)}>
        <AreaName onClick={toggleExpand}>{terms.level2}</AreaName>
      </TableCell>

      {renderSharedSpecificTermsTableCells(terms, isBroker() && isClaimsPenaltyBased)}
      {expanded && <TermsConditions level1={terms.level1} level2={terms.level2} />}
    </TableRow>
  );
};

interface AreaProps {
  terms: WarWeb.SpecificTcGroup | WarWeb.BrokerTcGroup;
  expanded: CountrySelection[];
  toggleCountry: (level1: string, level2: string) => void;
  focusedId?: string;
  setFocusedId: React.Dispatch<React.SetStateAction<string | undefined>>;
}
export const Area = ({ terms, expanded, toggleCountry, focusedId, setFocusedId }: AreaProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(expanded.some(a => a.level1 === terms.level1 && a.level2 === terms.level2));

  const toggle = (e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>, level1: string, level2: string) => {
    if (e.type === 'keydown') {
      const keyboardEvent = e as React.KeyboardEvent<HTMLDivElement>;
      if (keyboardEvent.key !== 'ArrowRight' && keyboardEvent.key !== 'ArrowLeft') return;
      if ((keyboardEvent.key === 'ArrowRight' && isExpanded) || (keyboardEvent.key === 'ArrowLeft' && !isExpanded)) return;
      e.preventDefault();
    }
    toggleCountry(level1, level2);
  };

  useEffect(() => {
    setIsExpanded(expanded.some(a => a.level1 === terms.level1 && a.level2 === terms.level2));
  }, [expanded]);

  return (
    <AreaRow
      terms={terms}
      expanded={isExpanded}
      toggleExpand={(e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => toggle(e, terms.level1, terms.level2)}
      focusedId={focusedId}
      setFocusedId={setFocusedId} />
  );
};
