import { Remove, TimeMachine } from '@instech/icons';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { useFilterableTableHeadersContext } from '../pages/defaultTermsConditions/FilterableTableHeadersContext';
import { FilterButton } from './FilterButton';

const Content = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.7em;
  padding: 1em;
`;

const ResetIcon = styled(TimeMachine)`
  filter: invert(20%) brightness(250%) contrast(50%);
`;

interface SelectionBarProps {
  type: string;
  parameterName?: string;
  adjective?: string;
  editMode: boolean;
  selection?: any[] | undefined;
  selected?: boolean;
  initialSelection?: any[];
  setter: (a: any) => void;
  resetSelectionCallback?: () => void;
}
export const SelectionBar = ({
  type,
  editMode,
  selection,
  parameterName,
  adjective,
  selected = false,
  initialSelection,
  setter,
  resetSelectionCallback,
  children
}: PropsWithChildren<SelectionBarProps>) => {
  const numSelected = selection?.length ?? 0;
  const { filters, setFilters } = useFilterableTableHeadersContext();

  const resetSelection = () => {
    const updatedSelection = initialSelection ?? [];
    setter(updatedSelection);
    if (resetSelectionCallback) resetSelectionCallback();

    if (parameterName && filters[parameterName]) {
      setFilters({
        ...filters,
        [parameterName]: updatedSelection.some(x => Boolean(x)) ? [...updatedSelection.map(v => `${v}`)] : undefined,
        pageNumber: '1'
      });
    }
  };

  const showSelection = () => {
    if (!parameterName) return;
    if (!filters[parameterName]) {
      const selectionAlwaysAnArray = selection || [];
      setFilters({ ...filters, [parameterName]: [...selectionAlwaysAnArray.map(v => `${v}`)], pageNumber: '1' });
    } else {
      const updatedFilters = { ...filters };
      delete updatedFilters[parameterName];
      setFilters(updatedFilters);
    }
  };

  return (
    <Content>
      <FilterButton
        label={`${numSelected} ${type}${numSelected === 1 ? '' : 's'} ${editMode ? adjective || 'selected' : adjective || 'included'}`}
        iconHeight={28}
        selected={selected}
        disabled={numSelected === 0 || !parameterName || !editMode}
        toggle={showSelection} />

      {editMode && initialSelection && (
        <FilterButton
          toggle={resetSelection}
          label={`${initialSelection ? 'Reset' : 'Clear'} selection`}
          leftIcon={initialSelection ? <ResetIcon /> : <Remove />}
          iconHeight={14} />
      )}
      {children}
    </Content>
  );
};
