import styled, { css } from 'styled-components';
import { TimelineVoyageVert } from '@instech/icons';
import { theme } from '@/utility/theme';
import { PropsWithChildren } from 'react';
import { FieldLayout } from '../../../shared/Form/core/Components';
import { DropdownField } from '../../../shared/Form/fields/Dropdown';

export const Row = styled.div`
  color: ${props => props.theme.sanMarino};
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${FieldLayout} {
    padding-right: 20px;
  }

  ${DropdownField} {
    min-width: 200px;
    max-width: 250px;
  }
`;

interface SpacerProps {
  start?: number;
  background?: string;
  borderSides?: string;
  borderBottom?: string;
}
export const Spacer = styled.div<SpacerProps>`
  display: flex;
  align-items: center;
  grid-column: ${props => props.start ?? 1} / -1;
  ${props => !props.children && css`
    height: 0;
  `};
  ${props => props.background && css`
    background-color: ${props.background};
  `};
  
  ${props => props.borderSides && css`
    border-left: 1px solid ${props.borderSides};
    border-right: 1px solid ${props.borderSides};
  `};
  ${props => props.borderBottom && css`
    border-bottom: 1px solid ${props.borderBottom};
  `};
`;

export const RemoveContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  
  & svg {
    width: 14px;
  }
`;

const Trail = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  background-color: inherit;
  align-items: center;
`;

const TrailLabel = styled.div`
  display: flex;
  color: ${theme.sanMarino};
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  padding-right: 0.3rem;
  font-size: 16px;
  font-weight: lighter;
  white-space: nowrap;

  @media (max-width: 1400px) {
    display: none;
  }
`;

export const TrailWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  // grid-row 1 / -1 does not yet work
  // https://stackoverflow.com/questions/42239778/items-that-span-all-columns-rows-using-css-grid-layout
  grid-row: span 200; 
  padding-top: 1em;
  background-color: ${theme.background.primary};
`;

export const DotWrapper = styled.div<{ cover?: boolean }>`
  display: flex;
  justify-content: flex-end;

  background-color: ${theme.background.primary};

  ${props => !!props.cover && css`
    z-index: 5;
  `};
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30px;
  padding: 0.2em 1em;
  background-color: inherit;
  z-index: 3;
`;

const TrailContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 0.3em;
  height: 100%;
  background-color: inherit;
`;

export const TrailMask = styled.div<{ cover?: boolean }>`
  ${props => !!props.cover && css`
    background-color: inherit;
  `};
  height: 100%;
  z-index: 3;

  @media (max-width: 1400px) {
    display: none;
  }
`;

export const TrailLine = styled(TimelineVoyageVert) <{ offsetx: string, offsety: string, left?: boolean }>`
  position: absolute;
  z-index: 2;
  height: calc(100% - 2*${props => props.offsety});
  top: ${props => props.offsety};
  ${props => props.left ? 'left' : 'right'}: calc(${props => props.offsetx}); 
`;

interface TrailDotProps {
  label?: string;
  color?: string;
  first?: boolean;
  last?: boolean;
}
export const TrailDot = ({ label, color, first, last, children }: PropsWithChildren<TrailDotProps>) => (
  <Trail>
    {label && (
      <TrailLabel>
        {label}
      </TrailLabel>
    )}
    <TrailContainer>
      <TrailMask cover={first} />
      <IconContainer>
        {children || (
          <svg viewBox="0 0 24 24" width={12} height={12}>
            <circle
              cx={973}
              cy={168}
              r={12}
              transform="translate(-961 -156)"
              fill={color ?? theme.border.sanMarino}
              fillRule="evenodd"
            />
          </svg>
        )}
      </IconContainer>
      <TrailMask cover={last} />
    </TrailContainer>
  </Trail>
);
