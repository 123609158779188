import { openErrorModal } from '@/components/error/ErrorModal';
import { PageHeader } from '@/components/layout/PageHeader';
import { useModalContext } from '@/components/modal/ModalContext';
import { isBroker, isInternalUser } from '@/components/routing/roles';
import { Routes } from '@/components/routing/routes';
import { Box } from '@/components/shared/Box';
import { Button } from '@/components/shared/Button';
import { FilterBar } from '@/components/shared/FilterBar';
import { getHeaders } from '@/components/shared/Table/core/Components';
import useDebounce from '@/hooks/useDebouncedValue';
import { Filters } from '@/models/Filters';
import { SortedHeader } from '@/models/SortOrder';
import { updateFleetRequest, useFleet } from '@/services/fleetsService';
import { RequestResult } from '@/services/utility/simpleFetch';
import { useVessels } from '@/services/vesselsService';
import { theme } from '@/utility/theme';
import { WarWeb } from '@/war';
import { Edit } from '@instech/icons';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { PaginationBar } from '../common/PaginationBar';
import { FilterableTableHeadersProvider } from '../defaultTermsConditions/FilterableTableHeadersContext';
import { SpaceBetweenContainer } from '../defaultTermsDetails/shared/SettingsComponents';
import { FleetSettingsModal } from './FleetSettingsModal';
import { tableSections, tableSectionsInternal } from './tableHeaders';
import { VesselTable } from './VesselTable';

const SubHeader = styled.div`
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: ${props => props.theme.marineBlue75};
  text-transform: uppercase;
`;

const StyledBox = styled(Box)`
  margin-bottom: 2rem;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  padding: 1em 0;
  margin: 0 0.5em;
`;

export const FleetDetailsPage = () => {
  const { clientId, fleetId } = useParams<any>();
  const { open } = useModalContext();

  const defaultFilters = { fleetId, pageNumber: '1', pageSize: '15' };

  const [filters, setFilters] = useState<Filters>(defaultFilters);
  const debouncedFilters = useDebounce(filters, 500);
  const [sortedHeader, setSortedHeader] = useState<SortedHeader>();
  const [showModal, setShowModal] = useState(false);

  const { data: vessels, isLoading, error: vesselsError } = useVessels(debouncedFilters);
  const { data: fleet, mutate } = useFleet(clientId, fleetId);

  const breadcrumb = {
    label: 'Fleet overview',
    to: `${Routes.fleets}`
  };

  const headers = getHeaders(isInternalUser() ? tableSectionsInternal : tableSections);

  const performServiceCall = async (service: () => Promise<RequestResult<any>>) => {
    const { error } = await service();
    if (error) open(openErrorModal);
    else await mutate();
  };

  const setFleetRequest = async (request: WarWeb.UpdateFleetRequest) => {
    await performServiceCall(() => updateFleetRequest(clientId, fleetId, request));
  };

  return (
    <>
      <SpaceBetweenContainer>

        <div>
          <PageHeader breadcrumb={breadcrumb} header={fleet?.name ?? ''} />
          {isInternalUser() && (
            <SubHeader>
              Fleet Tier
              {' '}
              {fleet?.tier}
            </SubHeader>
          )}
        </div>

        {(isInternalUser({ onlyEditors: true }) || isBroker()) &&
          <Button icon={<Edit />} onClick={() => setShowModal(true)}>FLEET SETTINGS</Button>}
      </SpaceBetweenContainer>

      <StyledBox highlight={theme.border.lightGreen}>

        <FilterableTableHeadersProvider
          headers={headers}
          columnMetadata={vessels?.columnMetadata}
          initialFilters={defaultFilters}
          filters={filters}
          sortedHeader={sortedHeader}
          setSortedHeader={setSortedHeader}
          setFilters={setFilters}
          isLoading={isLoading}
        >
          <FilterContainer>
            <FilterBar />
          </FilterContainer>

          <VesselTable
            loadStatus={{ loading: isLoading, success: !vesselsError }}
          />

        </FilterableTableHeadersProvider>
      </StyledBox>

      {vessels?.pagingMetadata && (
        <PaginationBar
          currentPage={+filters.pageNumber}
          metadata={vessels?.pagingMetadata}
          changePage={newPage => setFilters({ ...filters, pageNumber: `${newPage}` })}
        />
      )}

      {showModal && <FleetSettingsModal closeModal={() => setShowModal(false)} callback={setFleetRequest} />}
    </>
  );
};
