import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { WarningFilled } from '@instech/icons';
import { Button } from '@/components/shared/Button';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { TextField } from '@/components/shared/Form/FormFields';
import { Table, TableHeader } from '@/components/shared/Table/Table';
import { theme } from '@/utility/theme';
import { useRebateAssociations } from '@/services/taxonomyService';
import { DestinationOption } from '@/components/pages/bows/details/destinationOption';
import { Globals } from '@/utility/globals';
import { ModalLayout } from '@/components/modal/ModalLayout';
import { RebateAction, RebatesEditorRow } from './RebatesEditorRow';

const Content = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 1px solid ${theme.lightGray};
  border-radius: 3px;
`;

const FilterContainer = styled.div`
  margin: 0.5rem 0;
`;

const WarningsContainer = styled.div`
  display: flex;
  color: ${theme.status.red};
  gap: 1em;
`;

const StyledWarningFilled = styled(WarningFilled)`
  color: ${theme.status.orange};
  width: 20px;
`;

interface RebatesModalProps {
  isOpen: boolean;
  closeModal: () => void;
  destination: DestinationOption;
  includedRebates: number[];
  updateRebates: (rebateId: number, action: RebateAction) => void;
}
export const RebatesModal = ({ isOpen, closeModal, destination, includedRebates, updateRebates }: RebatesModalProps) => {
  const { data: rebates } = useRebateAssociations();
  const [warning, setWarning] = useState('');

  useEffect(() => {
    setWarning('');
  }, [isOpen]);

  const [filter, setFilter] = useState('');
  const [selectedRebates, setSelectedRebates] = useState<number[]>(includedRebates);

  useEffect(() => {
    setSelectedRebates(includedRebates);
  }, [includedRebates]);

  if (!isOpen) return null;

  const filteredRebates = filter ? rebates?.filter(x =>
    x.name?.toLowerCase().includes(filter.toLowerCase())
    || x.description?.toLowerCase().includes(filter.toLowerCase())) : rebates;

  const options = {
    title: `Add / Remove Rebates - ${destination.name}`,
    size: 'small',
    draggable: true
  };

  return (
    <ModalLayout options={options} closeModal={closeModal}>
      <FilterContainer>
        <TextField name="filter" placeholder="Filter" onChange={e => setFilter(e.target.value)} value={filter} />
      </FilterContainer>
      <Content>
        <Table striped columns={3} layout="1fr 4fr 1fr" maxHeight="450px">
          <TableHeader>Abbreviation</TableHeader>
          <TableHeader>Description</TableHeader>
          <TableHeader center>Included</TableHeader>

          {filteredRebates?.filter(r => r.id !== Globals.ClaimsPenaltyRebateId)?.map(rebate => (
            <RebatesEditorRow
              key={rebate.id}
              rebate={rebate}
              removeDisabled={destination.id === Globals.TotalVoyageId}
              selectedRebates={selectedRebates}
              setSelectedRebates={setSelectedRebates}
              updateRebates={updateRebates}
              setWarning={setWarning}
            />
          ))}
        </Table>
      </Content>

      {warning && (
        <WarningsContainer>
          <StyledWarningFilled />
          {warning}
        </WarningsContainer>
      )}

      <ButtonGroup justify="flex-end">
        <Button onClick={closeModal} background={theme.lightGray}>Done</Button>
      </ButtonGroup>
    </ModalLayout>
  );
};
