import { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div<ButtonGroupProps>`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  justify-content: ${props => props.justify || ''};
  align-items: ${props => props.align ?? 'center'};

  & > button + button {
    ${props => props.direction === 'column' ? css`
      margin-bottom: 1rem;
     ` : css`
      margin-left: 1rem;
     `};
  }

  & > a:not(:first-child) > div { // our custom Button component
    ${props => props.direction === 'column' ? css`
      margin-bottom: 1rem;
    ` : css`
      margin-left: 1rem;
    `}
  }
`;

interface ButtonGroupProps {
  direction?: string;
  justify?: string;
  align?: string;
}
export const ButtonGroup = ({ children, direction, justify, align }: PropsWithChildren<ButtonGroupProps>) => (
  <Wrapper direction={direction} justify={justify} align={align}>
    {children}
  </Wrapper>
);
