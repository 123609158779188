import React from 'react';
import { darken } from 'polished';
import {
  Circle, Pending, Cancelled as CancelledIcon, Draft as DraftIcon
} from '@instech/icons';
import { WarWeb } from '@/war';
import { theme } from './theme';

export enum Status {
  Draft = 0,
  Quoted = 1,
  PendingConfirmation = 2,
  Confirmed = 3,
  Finalized = 4,
  Cancelled = 5,
  Discarded = 6,
}

interface BowStatus {
  sortOrder: number;
  status: Status;
  title: string;
  noun: string;
  action?: string;
  color: string;
  icon?: React.ReactNode;
  smallIcon?: React.ReactNode;
}
export const bowStatus: BowStatus[] = [
  {
    sortOrder: 5,
    status: Status.Cancelled,
    title: 'Cancelled',
    noun: 'Cancelled',
    color: theme.status.solitude,
    icon: <CancelledIcon color={theme.border.gray} width={24} height={24} />,
    smallIcon: <CancelledIcon color={theme.border.gray} width={16} height={16} />
  },
  {
    sortOrder: 0,
    status: Status.Draft,
    title: 'Draft',
    noun: 'Draft',
    color: darken(0.1, theme.whiteBlue),
    icon: <DraftIcon color={theme.border.gray} width={24} height={24} />,
    smallIcon: <DraftIcon color={theme.border.gray} width={14} height={14} />
  },
  {
    sortOrder: 1,
    status: Status.Quoted,
    title: 'Quoted',
    noun: 'Quote',
    color: theme.lightGreen50,
    icon: <Circle color={theme.green} dashArray={5.4} />,
    smallIcon: <Circle color={theme.green} strokeWidth={3} dashArray={5.4} />
  },
  {
    sortOrder: 2,
    status: Status.PendingConfirmation,
    title: 'PendingConfirmation',
    noun: 'Bow',
    color: theme.lightGreen80,
    icon: <Pending color={theme.border.gray} width={24} height={24} strokeWidth={2} />,
    smallIcon: <Pending color={theme.border.gray} strokeWidth={2} />
  },
  {
    sortOrder: 3,
    status: Status.Confirmed,
    title: 'Confirmed',
    noun: 'Bow',
    color: theme.lightGreen80,
    icon: <Circle color={theme.green} />,
    smallIcon: <Circle color={theme.green} strokeWidth={3} />
  },
  {
    sortOrder: 4,
    status: Status.Finalized,
    title: 'Finalized',
    noun: 'Bow',
    color: theme.green,
    icon: <Circle isFilled color={theme.green} />,
    smallIcon: <Circle isFilled color={theme.green} />
  },
  {
    sortOrder: 6,
    status: Status.Discarded,
    title: 'Discarded',
    noun: 'Discarded',
    color: theme.mediumGray,
    icon: <Circle color={theme.border.gray} />,
    smallIcon: <Circle color={theme.border.gray} strokeWidth={3} />
  },
];

export const bowStatusToObject = (value?: WarWeb.BowStatus) =>
  bowStatus.find(x => x.title === value);
