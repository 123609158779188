import { useErrorContext } from '@/services/ErrorContext';
import { MaxNumberValue } from '@/utility/globals';
import { theme } from '@/utility/theme';
import React, { FC } from 'react';
import styled from 'styled-components';
import {
  FieldLabel, FieldLayout, InputField
} from '../core/Components';

const Postfix = styled.div<{ postFixPadding?: string }>`
  padding-left: ${props => props.postFixPadding ? props.postFixPadding : '0.5em'};
`;

const IconContainer = styled.div<{ right?: boolean }>`
  position: relative;
  height: 16px;
  z-index: 10;
  top: 10px;
  left: ${props => props.right ? '-15px' : '32px'};
  margin-left: -20px;
`;

const InputLength = styled.div<{ isOver?: boolean }>`
  font-size: 12px;
  text-align: right;
  width: 100%;
  font-weight: normal;
  color: ${props => props.isOver ? theme.status.darkRed : theme.marineBlue40};
  padding-top: 5px;
`;

export interface TextFieldDeprecatedProps {
  name: string;
  label?: string;
  id?: string;
  postfix?: string;
  postFixPadding?: string;
  numberOfCharacters?: number;
  step?: number;
  rows?: number;
  autoComplete?: string;
  onFocus?: (e: React.FocusEvent<HTMLInputElement & HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement & HTMLTextAreaElement>) => void;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement & HTMLTextAreaElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement & HTMLTextAreaElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLInputElement & HTMLTextAreaElement>) => void;
  onMouseOver?: (e: any) => void;
  onMouseOut?: (e: any) => void;
  value?: number | string;
  maxValue?: number;
  minValue?: number;
  maxLength?: number;
  type?: string;
  compact?: boolean;
  hasDarkBackground?: boolean;
  dirty?: boolean;
  minimal?: boolean;
  noshade?: boolean;
  disabled?: boolean;
  nonedit?: boolean;
  tabIndex?: number;
  inputFieldRef?: React.MutableRefObject<any>;
  autoFocus?: boolean;
  icon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  stretch?: boolean;
}
export const TextFieldDeprecated: FC<TextFieldDeprecatedProps> = (
  {
    name,
    id,
    type = 'text',
    rows,
    numberOfCharacters,
    value,
    minValue,
    maxValue,
    maxLength,
    label,
    postfix,
    hasDarkBackground = false,
    compact,
    dirty = false,
    noshade = false,
    minimal = false,
    nonedit = false,
    inputFieldRef,
    icon,
    rightIcon,
    postFixPadding,
    onChange,
    stretch = false,
    ...props
  }
) => {
  const { hasError } = useErrorContext();

  const validation: string[] = [];
  // const hasLowerConstraint = (minValue || minValue === 0);
  // const hasUpperConstraint = (maxValue || maxValue === 0);
  // if ((hasLowerConstraint || hasLowerConstraint) && type === 'number' && value) {
  //   const valueAsInt = parseInt(`${value}`);
  //   if (!Number.isNaN(valueAsInt)) {
  //     if (hasLowerConstraint && valueAsInt < minValue) validation = [`Must be above ${minValue}`];
  //     if (hasUpperConstraint && valueAsInt > maxValue) validation = [`Must be below ${maxValue}`];
  //   }
  // }

  const inputCheck = (e: any) => {
    if (type === 'number' && e.target.value > MaxNumberValue) return;
    if (maxValue && type === 'number' && e.target.value > maxValue) return;
    if (onChange) onChange(e);
  };

  return (
    <FieldLayout stretch={stretch}>
      {label && (
        <FieldLabel as="label" htmlFor={name}>
          {label}
        </FieldLabel>
      )}
      <FieldLayout direction="row">
        <InputField
          compact={compact}
          numberOfCharacters={numberOfCharacters}
          dirty={dirty}
          hasError={hasError}
          hasIcon={!!icon}
          hasRightIcon={!!rightIcon}
          minimal={minimal}
          noshade={noshade}
          nonedit={nonedit}>
          {icon && <IconContainer>{icon}</IconContainer>}
          {rows ?
            <textarea id={id ?? name} name={name} value={value} rows={rows} {...props} onChange={inputCheck} />
            :
            <input id={id ?? name} name={name} type={type} ref={inputFieldRef} value={value} readOnly={nonedit} onChange={inputCheck} {...props} />}
          {rightIcon && <IconContainer right>{rightIcon}</IconContainer>}
        </InputField>
        {postfix && <Postfix postFixPadding={postFixPadding}>{postfix}</Postfix>}
      </FieldLayout>
      {maxLength && <InputLength isOver={(value as string)?.length > maxLength}>{`${typeof value === 'string' ? value.length : 0}/${maxLength}`}</InputLength>}
    </FieldLayout>
  );
};
