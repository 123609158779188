import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from '@instech/icons';
import { NumberField } from '@/components/shared/Form/fields/NumberField';
import { ReinsuranceValues } from './ConfirmationModal';
import { QuoteLabel } from './quoteLabelMap';

const Wrapper = styled.div`
  margin: 32px 0;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  input {
    text-align: left;
  }
`;
const DivideExposureWrapper = styled.div`
  display: flex;
`;
const StyledLink = styled(Link)`
  margin: 0 12px;
  align-self: center;
`;
const RadioButtonWrapper = styled.div`
  margin: 16px 0;
  display: flex;
  align-items: center;
  grid-gap: 4px;
`;

interface RadioButtonProps {
  name: string;
  value: string;
  id: string;
  label: string;
  checked: boolean;
  onOptionChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioButton = ({ name, value, id, label, checked, onOptionChange }: RadioButtonProps): JSX.Element => (
  <RadioButtonWrapper>
    <input type='radio' name={name} value={value} id={id} onChange={onOptionChange} checked={checked} />
    <label htmlFor={id}>{label}</label>
  </RadioButtonWrapper>
);

type SectionExposure = { currency: string; value: number };

interface DataProps {
  reinsuranceType: string;
  section2Exposure: SectionExposure[];
  section2Share: number;
  section4Exposure: SectionExposure[];
  section4Share: number;
}

export const ExposureButtons = ({ hateoasExecutor, bowData, loading, setLoading, isDisabled, setIsDisabled }: any) => {
  const [data, setData] = useState<DataProps>({
    reinsuranceType: bowData?.primaryQuote.reinsurance?.reinsuranceType,
    section2Exposure: bowData?.primaryQuote.reinsurance?.section2Exposure,
    section2Share: bowData?.primaryQuote.reinsurance?.section2Share,
    section4Exposure: bowData?.primaryQuote.reinsurance?.section4Exposure,
    section4Share: bowData?.primaryQuote.reinsurance?.section4Share,
  });
  const [isValidationError, setIsValidationError] = useState({
    section2Share: false,
    section4Share: false
  });
  const updateValidationError = (fieldName: string, hasError: boolean) => {
    setIsValidationError(prevErrors => ({
      ...prevErrors,
      [fieldName]: hasError,
    }));
  };

  const okButtonShouldBeDisabled = (data.reinsuranceType === 'Section2And4' && data.section2Share + data.section4Share !== 100) || (isValidationError.section2Share || isValidationError.section4Share);
  
  useEffect(() => {
    if (okButtonShouldBeDisabled) {
      setIsDisabled(true);
    }
    else {
      setIsDisabled(false);
    }
  }, [data]);

  const links = bowData.primaryQuote.links;

  const onOptionChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setData((prevData) => {
      return { ...prevData, reinsuranceType: e.target.value };
    });
    value !== ReinsuranceValues.Section2And4 && handleRequest(value, name);
  };

  const onInputValueChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setData((prev: any) => {
      return { ...prev, [name]: value };
    });
  };

  const handleRequest = (value: string, name: string): void => {
    const key = name === ReinsuranceValues.section4Share ? 'remainingShare' : 'share';

    const payload = {
      reinsuranceType: name === 'options' ? value : ReinsuranceValues.Section2And4,
      [key]: name === 'options' ? 0 : value,
    };

    setLoading(true);
    const putData = async () => {
      try {
        const newData = await hateoasExecutor(links, QuoteLabel.SetReinsurance, payload);
        setData(newData.data.reinsurance);
      } catch (error) {
        console.error('Error occurred while fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    putData();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      (e.target as HTMLInputElement).blur();
    }
  };

  return (
    <Wrapper>
      <RadioButton
        name='options'
        value={ReinsuranceValues.Section2And4}
        id='exposure-buttons-radio-section2and4'
        label='Divide exposure between Section 2 and Section 4'
        checked={data.reinsuranceType === ReinsuranceValues.Section2And4}
        onOptionChange={onOptionChange}
      />
      {data.reinsuranceType === ReinsuranceValues.Section2And4 && (
        <DivideExposureWrapper>
          <InputWrapper>
            <NumberField
              label='Section 2'
              maxValue={100}
              minValue={0}
              postfix='%'
              postFixPadding='0.5em'
              name={ReinsuranceValues.section2Share}
              onChange={onInputValueChange}
              onBlur={() => {data?.section2Share && handleRequest(data?.section2Share.toString(), ReinsuranceValues.section2Share)}}
              onKeyDown={handleKeyDown}
              value={data?.section2Share ?? 0}
              disabled={loading}
              dirty={true}
              updateValidationError={updateValidationError}
          />
            {data.section2Exposure &&
              data.section2Exposure.length > 0 &&
              data.section2Exposure.map((item, i) => (
                <span key={i}>
                  {item.currency} {item.value.toLocaleString()}
                </span>
              ))}
          </InputWrapper>
          <StyledLink />
          <InputWrapper>
            <NumberField
              label='Section 4'
              maxValue={100}
              minValue={0}
              postfix='%'
              postFixPadding='0.5em'
              name={ReinsuranceValues.section4Share}
              onChange={onInputValueChange}
              onBlur={() => {data?.section4Share && handleRequest(data?.section4Share.toString(), ReinsuranceValues.section4Share)}}
              onKeyDown={handleKeyDown}
              value={data?.section4Share ?? 0}
              disabled={loading}
              dirty={true}
              updateValidationError={updateValidationError}
            />
            {data.section4Exposure &&
              data.section4Exposure.length > 0 &&
              data.section4Exposure.map((item, i) => (
                <span key={i}>
                  {item.currency} {item.value.toLocaleString()}
                </span>
              ))}
          </InputWrapper>
        </DivideExposureWrapper>
      )}
      <RadioButton
        name='options'
        value={ReinsuranceValues.Section2And5}
        id='exposure-buttons-radio-section2and5'
        label='Place all exposure on Section 5'
        checked={data.reinsuranceType === 'Section2And5'}
        onOptionChange={onOptionChange}
      />
    </Wrapper>
  );
};
