import {
  Table, TableCell, TableRow,
  renderTableHeaders
} from '@/components/shared/Table/Table';
import { WarWeb } from '@/war';
import { formatInteger, formatPercentage } from '@/utility/formatter';
import { FlexBox } from '@/components/shared/FlexBox';
import {
  DuoTableLayout, HorizontalTableLayout, TableHeaderTitle
} from '../defaultTermsDetails/shared/SettingsComponents';

type SummaryObject = { [key: number]: { [key: string]: string; }; };

const getRatesHeaders = (defaultRates: WarWeb.BrokerRateSummary[]) => defaultRates.reduce((acc, rate) => {
  if (rate.standalone) {
    if (acc.includes(`${rate.shortName} AP`)) return acc;
    return [...acc, `If ${rate.shortName} stand-alone`, `${rate.shortName} AP`];
  }
  if (acc.includes(rate.shortName)) return acc;
  return [...acc, rate.shortName];
}, [] as string[]);

const getRatesSummary = (defaultRates: WarWeb.BrokerRateSummary[]) => defaultRates.reduce((acc, rateSummary) => {
  rateSummary.rateItems.forEach(rateItem => {
    if (rateSummary.standalone) {
      acc[rateItem.daysCovered] = {
        ...acc[rateItem.daysCovered],
        [`If ${rateSummary.shortName} stand-alone`]: formatPercentage(rateItem.value),
        [`${rateSummary.shortName} AP`]: formatInteger(rateItem.minimumPremium ?? 0)
      };
    }
    acc[rateItem.daysCovered] = { ...acc[rateItem.daysCovered], [rateSummary.shortName]: formatPercentage(rateItem.value) };
  });
  return acc;
}, {} as SummaryObject);

const getRebatesSummary = (knrRebates: WarWeb.BrokerRebateSummary[]) => knrRebates.reduce((acc, rebateSummary) => {
  rebateSummary.rebateItems.forEach(rebateItem => {
    acc[rebateItem.daysCovered] = { ...acc[rebateItem.daysCovered], [rebateSummary.shortName]: formatPercentage(rebateItem.value) };
  });
  return acc;
}, {} as SummaryObject);

const SummaryRow = ({ daysCovered, summary }: { daysCovered: number; summary: SummaryObject }) => (
  <TableRow top>
    <TableCell>{`${daysCovered} days`}</TableCell>
    {Object.keys(summary[daysCovered]).map(item => <TableCell key={`${item} ${daysCovered}`}>{summary[daysCovered][item]}</TableCell>)}
  </TableRow>
);

const SummaryTable = ({ headers, summary }: { headers: string[]; summary: SummaryObject }) => (
  <>
    {renderTableHeaders(headers)}
    {Object.keys(summary).map(daysCovered => (
      <SummaryRow key={daysCovered} daysCovered={parseInt(daysCovered)} summary={summary} />
    ))}
  </>
);

export const PremiumRebatesBroker = ({ data }: { data: WarWeb.BrokerAgreementSummary }) => {
  const { defaultRates, rebates, knrRebates } = data;

  const ratesHeaders = getRatesHeaders(defaultRates);
  const ratesSummary = getRatesSummary(defaultRates);
  const rebatesSummary = getRebatesSummary(rebates);
  const knrRebatesSummary = getRebatesSummary(knrRebates);

  return (
    <FlexBox flexDirection="column" gap="3rem">
      <HorizontalTableLayout>
        <Table columns={ratesHeaders.length + 1} striped>
          <TableHeaderTitle>Rates</TableHeaderTitle>
          <SummaryTable headers={ratesHeaders} summary={ratesSummary} />
        </Table>
      </HorizontalTableLayout>
      <DuoTableLayout>
        <Table columns={rebates.length + 1} striped>
          <TableHeaderTitle>Rebates</TableHeaderTitle>
          <SummaryTable headers={rebates.map(rebate => rebate.shortName)} summary={rebatesSummary} />
        </Table>
        <Table columns={knrRebates.length + 1} striped>
          <TableHeaderTitle>K&R</TableHeaderTitle>
          <SummaryTable headers={knrRebates.map(rebate => rebate.shortName)} summary={knrRebatesSummary} />
        </Table>
      </DuoTableLayout>
    </FlexBox>
  );
};
