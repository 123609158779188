// Groups collections having matching key value. In a following manner:
// a = [ ["k1", "A"], ["k2", "B"], ["k3", "C"] ];
// b = [ ["k1", "D"], ["k4", "E"] ];
// zipBy([a, b], item => item[0])
// => [ {key: "k1", items: [ ["k1", "A"],  [k1, "D"] ]},
//      {key: "k2", items: [ ["k2", "B"],  null ]},
//      {key: "k3", items: [ ["k3", "C"],  null ]},
//      {key: "k4", items: [ null,         ["k4", "E"] ]}
// In essence, a group by which replaces items not matched with NULLs in the final groups
export function zipBy<TItem, TKey>(items: TItem[][], keySelector: ((item: TItem) => TKey))
  : { key: TKey, items: (TItem | undefined)[] }[] {
  const uniqueKeys = new Set(items.flatMap(itemCollection => itemCollection.map(keySelector)));
  return [...uniqueKeys].map(key => ({
    key,
    items: items.map(itemCollection => itemCollection.find(item => keySelector(item) === key))
  }));
}

// Get difference between arrays
// symmetric, difference from both sets [1,2,3], [3,4,5] => [1,2,4,5]
// asymmetric, what is only in a [1,2,3], [3,4,5] => [1,2]
// intersection, what is in both [1,2,3], [3,4,5] => [3]
// https://stackoverflow.com/a/33034768
export const getDifferenceSymmetric = <T>(a: T[], b: T[]) =>
  a.filter(x => !b.includes(x))
    .concat(b.filter(x => !a.includes(x)));

export const getDifference = <T>(a: T[], b: T[]) => a.filter(x => !b.includes(x));

export const getIntersection = <T>(a: T[], b: T[]) => a.filter(x => b.includes(x));

export const getLastElement = <T>(array: T[]) => array.length > 0 ? array[array.length - 1] : undefined;
