import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Box } from '@/components/shared/Box';
import { Chevron } from '@/components/shared/Chevron';
import {
  renderTableHeaders, Table, TableCell, TableRow
} from '@/components/shared/Table/Table';
import { groupBy } from '@/utility/arrayHelpers';
import { bowStatusToObject, Status } from '@/utility/bowStatus';
import { TimeConverter } from '@/utility/timeConverter';
import { WarWeb } from '@/war';
import { DocumentationCard } from './DocumentationCard';
import { DocumentationSet } from './documentationSet';

const StyledTableCell = styled(TableCell)`
  padding: 1rem;
`;

const StyledFleetLink = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;

  & svg {
    transform: rotate(180deg);
  }
`;

interface DocumentRowProps {
  doc: DocumentationSet;
  comment: string;
  expiration: string;
  isExpanded: boolean;
}
const DocumentRow: FC<DocumentRowProps> = ({ doc, comment, expiration, isExpanded }) => {
  const bowStatus = bowStatusToObject(doc.bowStatus);
  const [expanded, setExpanded] = useState<boolean>(isExpanded);

  return (
    <>
      <TableRow>
        <StyledTableCell>{bowStatus?.icon}</StyledTableCell>
        <TableCell>{TimeConverter.ToDateTime(doc.createdTime)}</TableCell>
        <TableCell>{doc.fleetName}</TableCell>
        <TableCell>{doc.vesselName}</TableCell>
        <TableCell>{doc.imoNumber}</TableCell>
        <TableCell>{doc.policyYear}</TableCell>
        <TableCell>{doc.rebates}</TableCell>
        <TableCell slim hover>
          <StyledFleetLink onClick={() => setExpanded(!expanded)}>
            {expanded ? <Chevron top thin /> : <Chevron bottom thin />}
          </StyledFleetLink>
        </TableCell>
      </TableRow>
      {expanded && (
        <TableRow>
          <DocumentationCard documentation={doc} comment={comment} expiration={expiration} />
        </TableRow>
      )}
    </>
  );
};

const headers = ['Status', 'Created and sent date', 'Fleet', 'Vessel', 'IMO no.', 'Policy year', 'Rebates', ''];

interface DocumentationProps {
  quote: WarWeb.BowQuote;
}

export const Documentation: FC<DocumentationProps> = ({ quote }) => {
  const bowStatus = bowStatusToObject(quote.status);

  if (bowStatus?.status === Status.Draft || bowStatus?.status === Status.Cancelled || bowStatus?.status === Status.Discarded) { return null; }

  const documentSets = groupBy(quote?.documents, (doc: WarWeb.LegalDocument) => [doc.documentSetId])
    .map(documentSet => ({
      createdTime: documentSet[0].createdTime,
      documentSetId: documentSet[0].documentSetId,
      bowStatus: documentSet[0].bowStatus,
      vesselName: documentSet[0].vesselName,
      fleetName: documentSet[0].fleetName,
      imoNumber: documentSet[0].imoNumber,
      rebates: documentSet[0].rebates?.join(','),
      policyYear: documentSet[0].policyYear?.join(','),
      validity: documentSet[0].documentValidityTime,
      comment: documentSet[0].comment
    }));
    
  return (
    <Box header={`Alternative ${quote.alternative}`}>
      <Table columns={headers.length} striped>
        {renderTableHeaders(headers)}
        {documentSets.map((doc: DocumentationSet, i) => (
          <DocumentRow
            key={doc.documentSetId}
            doc={doc}
            comment={quote.comment}
            expiration={quote.expiration}
            isExpanded={i + 1 === documentSets.length}
          />
        ))}
      </Table>
    </Box>
  );
};
