import { useSWREndpoint } from '@/hooks/useSWREndpoint';
import { WarWeb } from '@/war';
import Qs from 'qs';

const basepath = 'areas';
const getAreaPath = (id: number) => `${basepath}/${id}`;

const getAreasPath = (params: WarWeb.AreasParameters) => `${basepath}?${Qs.stringify(params)}`;

export const useRegions = () => useSWREndpoint<WarWeb.Area[]>(`${basepath}/regions`);

export const useSubareas = (id?: number) => useSWREndpoint<WarWeb.Area[]>(id ? `${getAreaPath(id)}/subareas` : null);

export const useArea = (id?: number) => useSWREndpoint<WarWeb.Area>(id ? getAreaPath(id) : null);

export const useAreas = (params?: WarWeb.AreasParameters) => useSWREndpoint<WarWeb.Area[]>(params ? getAreasPath(params) : null);
