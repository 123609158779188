import { PageHeader } from '@/components/layout/PageHeader';
import { Routes } from '@/components/routing/routes';
import { ValidationProvider } from '@/services/ValidationContext';
import styled from 'styled-components';
import { AgreementViewMode, useAgreementDetailsContext } from '../agreementDetails/AgreementDetailsContext';
import { HeaderActions } from '../agreementDetails/HeaderActions/HeaderActions';
import { AppliesToSelection } from '../defaultTermsDetails/appliesToSelection/AppliesToSelection';
import { BulkChangeAreasModal } from '../defaultTermsDetails/appliesToSelection/BulkChangeAreas/BulkChangeAreasModal';
import { SettingsYacht } from './Settings/SettingsYacht';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Container = styled.div`
  margin-bottom: 1.5rem;
`;

const PortTitleWrapper = styled.span`
  font-weight: normal;
`;

const getHeader = ({ viewMode, portTitle }: { viewMode: AgreementViewMode, portTitle: string }) => {
  const inspectMode = viewMode !== AgreementViewMode.Edit;
  return (
    <span>
      <span>{inspectMode ? 'Inspect - ' : 'Edit - '}</span>
      <PortTitleWrapper>{portTitle}</PortTitleWrapper>
    </span>
  );
};

export const DefaultTermsDetailsPageYacht = () => {
  const { viewMode, isBulkChangingAreas, setIsBulkChangingAreas, targetsSummary } = useAgreementDetailsContext();
  const portTitle =
    targetsSummary.totalTargetCount > 1
      ? `${targetsSummary.totalTargetCount} Agreements`
      : `${targetsSummary.areas[0]?.name}`;

  const header = getHeader({ viewMode, portTitle });

  return (
    <ValidationProvider>
      {isBulkChangingAreas && <BulkChangeAreasModal closeModal={() => setIsBulkChangingAreas(false)} />}
      <>
        <HeaderContainer>
          <PageHeader header={header} breadcrumb={{ label: 'Default T&C', to: Routes.termsDefault }} />
          <HeaderActions />
        </HeaderContainer>
        <Container>
          <SettingsYacht />
        </Container>
        <Container>
          <AppliesToSelection />
        </Container>
      </>
    </ValidationProvider>
  );
};
