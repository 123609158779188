import { TableCell, TableRow } from '@/components/shared/Table/Table';
import { Globals } from '@/utility/globals';
import { WarWeb } from '@/war';

export const OverflowItemsRow = ({ paging }: { paging?: WarWeb.PagingMetadata }) => {
  const resultsRemaining = paging?.totalCount ? paging.totalCount - Globals.AgreementsOverviewGroupSize : 0;

  return (
    paging?.hasNext ? (
      <TableRow outline>
        <TableCell span="1/-1">
          {`${resultsRemaining} more results available. Please use filters to narrow down the results.`}
        </TableCell>
      </TableRow>
    ) : null
  );
};
