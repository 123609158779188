import { useBow } from '@/services/bowServices';
import { WarWeb } from '@/war';
import { useMemo } from 'react';
import { useQuoteContext } from '../BowSidebar/QuoteContext';

export const useSelectedQuote = () => {
  const { ids } = useQuoteContext();
  const { bowId, quoteId } = ids;

  const { data } = useBow(bowId);
  const typedData : WarWeb.Bow | undefined = data;

  const selectedQuote = useMemo(
    () => typedData?.quotes.find(x => x.id === quoteId),
    [quoteId, typedData?.quotes]
  );

  return selectedQuote;
};
