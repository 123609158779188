import { Divider } from '@instech/components';
import { RatesTable } from '../defaultTermsDetails/settings/premiumRebates/RatesTable';
import { RebatesTable } from '../defaultTermsDetails/settings/premiumRebates/RebatesTable';
import { CoverageYacht } from './CoverageYacht';
import { SumInsuredYacht } from './SumInsuredYacht';

export const DaysCoveredTabYacht = () => (
  <>
    <CoverageYacht />
    <Divider />
    <SumInsuredYacht />
    <br />
    <RatesTable agreementType="StandardAgreement" />
    <RebatesTable />
  </>
);
