import { Close } from '@instech/icons';
import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@/components/shared/Button';
import { TextFieldWithAction } from '@/components/shared/Form/fields/TextFieldWithAction';
import {
  renderTableHeaders, Table, TableCell, TableHeader, TableRow
} from '@/components/shared/Table/Table';
import {
  AgreementType, RateItemSummary, ValueSummary
} from '@/models/Agreement';
import { AgreementTiers } from '@/utility/agreementTiers';
import { unique } from '@/utility/arrayHelpers';
import {
  formatDecimal, formatDecimalsInText, formatPercentage, formatRange, getInputValue
} from '@/utility/formatter';
import { keysOf } from '@/utility/objectHelpers';
import { theme } from '@/utility/theme';
import { Validator } from '@/components/shared/Validator';
import { AgreementViewMode, useAgreementDetailsContext } from '@/components/pages/agreementDetails/AgreementDetailsContext';
import { useAgreementDataContext } from '@/components/pages/agreementDetails/AgreementDataContext';
import { MaxNumberTimeValue } from '@/utility/globals';
import { HorizontalTableLayout, TableHeaderTitle } from '../../shared/SettingsComponents';
import { PaddedTableCell, PopulateRateCell } from './PopulateRateCell';

const ToggleInterests = styled.div`
  display: flex;
  margin-top: 1rem;
  div {
    width: 200px;
  }
`;
const StyledTableHeader = styled(TableHeader)`
  width: 130px;
`;

type RateType = 'defaultRates' | 'additionalRates';

export const hasValue = <T, >(item: ValueSummary<T>) =>
  (item.isDirty && item.value !== undefined) ||
  !(item.value === undefined || (item.value == null && item.existingValueCount === 0));

export const RatesTable = ({ agreementType }: { agreementType: AgreementType }) => {
  const { viewMode } = useAgreementDetailsContext();
  const { premium, setPremium } = useAgreementDataContext();
  const [showAllInterests, setShowAllInterests] = useState(false);
  const isEdit = viewMode !== AgreementViewMode.Inspect;

  if (!premium) return null;

  const ratesHeaders: string[] = [];
  premium.defaultRates.forEach(rate => {
    if (rate.standalone) {
      if (ratesHeaders.includes(`${rate.shortName} AP`)) return;
      ratesHeaders.push(`If ${rate.shortName} stand-alone`);
      ratesHeaders.push(`${rate.shortName} AP`);
      return;
    }
    if (ratesHeaders.includes(rate.shortName)) return;
    ratesHeaders.push(rate.shortName);
  });
  if (isEdit) ratesHeaders.splice(1, 0, 'Populate');

  const additionalRatesHeaders = unique(premium.additionalRates, ['shortName']).map(x => x.shortName);

  const relevantTiers = keysOf(AgreementTiers).filter(x => x !== 'Maximum');

  const getRateType = (item: RateItemSummary) => premium.additionalRates.some(x => x.id === item.id) ? 'additionalRates' : 'defaultRates';

  type updateType = 'Rate' | 'MinimumPremium' | 'Both';

  const updateValue = (val: string | null, item: RateItemSummary, target: updateType) => {
    const tempRates = [...premium[getRateType(item)]];
    const cell = tempRates.find(x => x.id === item.id && x.tier === item.tier && x.standalone === item.standalone);
    if (!cell) return;

    const value = val ? parseFloat(val) : undefined;
    if (target === 'MinimumPremium' || target === 'Both') cell.minPremium.value = value;
    if (target === 'Rate' || target === 'Both') cell.value = value;
    cell.isDirty = true;
    setPremium({ ...premium, [getRateType(item)]: tempRates });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, item: RateItemSummary, target: updateType) => {
    updateValue(e.target.value, item, target);
  };

  const renderEditCell = (item: RateItemSummary) => {
    const action = hasValue(item) ?
      {
        icon: <Close />,
        onClick: () => {
          // reset rate also resets minimum rate
          updateValue(null, item, 'Both');
        }
      } : undefined;

    const errorKey = `Rates(InterestTypeId=${item.id};${item.standalone ? 'Standalone' : ''})`;

    const percentageField = isEdit ? (
      <Validator keys={[errorKey]} formatter={formatDecimalsInText}>
        <TextFieldWithAction type="number"
          name={`${item.id}`}
          numberOfCharacters={7}
          placeholder={formatRange(item)}
          action={action}
          value={getInputValue(item.value)}
          minValue={0}
          maxValue={MaxNumberTimeValue}
          postfix="%"
          onChange={e => handleInputChange(e, item, 'Rate')}
          dirty={item.isDirty} />

      </Validator>
    ) : formatRange(item, formatDecimal, '%') ?? formatPercentage(item.value);

    const errorKeyMinPremium = `Rates(InterestTypeId=${item.id};MinimumPremium)`;

    const minPremiumField = isEdit ? (
      <Validator keys={[errorKeyMinPremium]} formatter={formatDecimalsInText}>
        <TextFieldWithAction type="number"
          name={`${item.id}`}
          placeholder={formatRange(item.minPremium)}
          action={action}
          minValue={0}
          numberOfCharacters={7}
          value={getInputValue(item.minPremium.value)}
          onChange={e => handleInputChange(e, item, 'MinimumPremium')}
          dirty={item.isDirty} />

      </Validator>
    ) : formatRange(item.minPremium, formatDecimal) ?? formatDecimal(item.minPremium.value);

    return (
      <Fragment key={`${item.id}-${item.standalone}`}>
        <TableCell minWidth={getRateType(item) === 'additionalRates' ? '140px' : undefined}>{percentageField}</TableCell>
        {item.standalone && <TableCell>{minPremiumField}</TableCell>}
        <PopulateRateCell item={item} />
      </Fragment>
    );
  };

  const renderRatesTable = (tableType: RateType) =>
    agreementType === 'StandardAgreement' ?
      relevantTiers.map(tier => {
        const tieredRates = premium[tableType].filter(r => r.tier === tier);
        return (
          <TableRow top key={tier}>
            <TableCell>{AgreementTiers[tier]}</TableCell>
            {tieredRates.map(rate => renderEditCell(rate))}
          </TableRow>
        );
      }) : (
        <TableRow top>
          <PaddedTableCell>Rate</PaddedTableCell>
          <TableRow top>
            {premium[tableType].map(rate => renderEditCell(rate))}
          </TableRow>
        </TableRow>
      );

  return (
    <>
      <HorizontalTableLayout>

        <Table striped columns={ratesHeaders.length + 1}>
          <TableHeaderTitle>Rates</TableHeaderTitle>
          {renderTableHeaders(ratesHeaders)}
          {ratesHeaders.length > 0 ? renderRatesTable('defaultRates') : null}
        </Table>
      </HorizontalTableLayout>

      {isEdit && (
        <ToggleInterests>
          <Button background={theme.lightGray} onClick={() => setShowAllInterests(!showAllInterests)}>
            {showAllInterests ? 'Hide additional interests' : 'Show additional interests'}
          </Button>
        </ToggleInterests>
      )}

      {showAllInterests && (
        <Table hasOverflow striped columns={additionalRatesHeaders.length + 1}>
          <StyledTableHeader />
          {renderTableHeaders(additionalRatesHeaders)}
          {additionalRatesHeaders.length > 0 ? renderRatesTable('additionalRates') : null}
        </Table>
      )}
    </>
  );
};
