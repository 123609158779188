import { getClients, useClient } from '@/services/clientsService';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { WarWeb } from '@/war';
import { SingleValue } from 'react-select';
import { CustomAsyncSelect } from '@/components/shared/Form/fields/CustomAsyncSelect';
import { useMe } from '@/services/meService';
import { Button } from '@/components/shared/Button';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { createBrokerImpersonation, deleteBrokerImpersonation } from '@/services/brokerImpersonationService';
import { theme } from '@/utility/theme';
import { MinSearchLength } from '@/utility/globals';

const Container = styled.div`
  display: flex;
`;

const SearchResult = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 50px;
  }
`;

const ClientName = styled.div`
  font-size: 16px;
`;

export const ClientSelector = () => {
  const { data: me } = useMe();
  const { data: currentClient } = useClient(me?.impersonated ? me?.clientIds[0] : undefined);

  const [client, setClient] = useState<WarWeb.ClientsMatch>();

  useEffect(() => {
    if (currentClient) setClient({ clientId: currentClient.id, clientName: currentClient.name });
  }, [currentClient]);

  const changeRole = async () => {
    if (client) {
      localStorage.removeItem('acceptedTerms');
      await createBrokerImpersonation({ clientId: client.clientId });
      window.location.reload();
    }
  };

  const revertRole = async () => {
    await deleteBrokerImpersonation();
    window.location.reload();
  };

  // TODO: Fix return type
  const loadOptions: any = async (searchString: string) => {
    if (searchString.length < MinSearchLength) {
      return [];
    }
    const params = { clientName: searchString };
    const { data: results } = await getClients(params);
    return results?.data;
  };

  const renderResult = (result: any) => (
    <SearchResult>
      <ClientName>{result.clientName}</ClientName>
    </SearchResult>
  );

  const selectClient = (input: SingleValue<WarWeb.ClientsMatch>, _: unknown) => {
    if (input) setClient(input);
    else setClient(undefined);
  };

  const styles = {
    container: (provided: any) => ({
      ...provided,
      width: '350px',
      marginRight: '1em'
    })
  };

  const isImpersonated = me?.impersonated;

  return (
    <Container>
      <CustomAsyncSelect
        name="clientName"
        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        value={client}
        placeholder="Client name"
        loadOptions={loadOptions}
        openMenuOnClick={false}
        onChange={selectClient}
        styles={styles}
        isDisabled={isImpersonated}
        getOptionValue={option => option.clientName}
        formatOptionLabel={renderResult}
        noOptionsMessage={() => 'No results found'}
        tabIndex={0}
      />

      <ButtonGroup>
        <Button onClick={changeRole} disabled={!client || isImpersonated} background={theme.green} tabIndex={0}>Change</Button>
        <Button onClick={revertRole} disabled={!isImpersonated} background={theme.mediumRed} tabIndex={0}>Revert role</Button>
      </ButtonGroup>
    </Container>
  );
};
