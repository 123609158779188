import { Routes } from '@/components/routing/routes';
import { useSelectedQuote } from '@/components/shared/Bow/useSelectedQuote';
import { useQuoteContext } from '@/components/shared/BowSidebar/QuoteContext';
import {
  Button, ButtonShape, ButtonWithLoader
} from '@/components/shared/Button';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { HateoasHandler } from '@/components/shared/HateoasHandler';
import { MediaType } from '@/services/config';
import { useHateoasExecutorContext } from '@/services/HateoasExecutorContext';
import { max } from '@/utility/arrayHelpers';
import { addTimezoneOffset, parseBlobData } from '@/utility/downloadHelper';
import { theme } from '@/utility/theme';
import { TimeConverter } from '@/utility/timeConverter';
import { WarWeb } from '@/war';
import { Download, FileParagraph } from '@instech/icons';
import styled, { css } from 'styled-components';
import { QuoteActions } from '../quoteActionsMap';

const DocumentDate = styled.div`
   color: ${props => props.theme.sanMarino};
    font-weight: normal;
    text-align: center;
    background-color: ${theme.lightGreen25};
    border-radius: 3px;
    padding: 0.5rem;
    font-size: 12px;
    margin: 0.3rem 0;
`;

const DownloadContainer = styled.div<{ small?: boolean }>` 
  ${ButtonShape} {
    color: ${theme.sanMarino};
    justify-content: flex-start;
    padding: 0 0.5rem;
    ${props => props.small && css`
      font-weight: normal;
      font-size: 12px;
      text-transform: none;
    `}

    &:hover {
      background-color: ${props => props.theme.ultraLightGray};
    }
  }
`;

const Actions = styled.div<{ left?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  padding-${props => props.left ? 'right' : 'left'}: 1.5rem;

  svg {
    stroke-width: 3px;
    width: 14px;
  }
`;

const DownloadLinks = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding-top: 1rem;
`;

const Content = styled.div`
  display: flex;
  height: 17rem;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 2em;
  max-width: 20vw;

  > svg {
    height: 5rem;
    width: auto;
  }
`;

const EmptyDocumentationIcon = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding-top: 1rem;
`;

const EmptyDocumentation = () => (
  <Content>
    <EmptyDocumentationIcon>
      <FileParagraph />
    </EmptyDocumentationIcon>
    <p>No formal quotation given for current selection</p>
  </Content>
);

export const DocumentationTable = () => {
  const { ids } = useQuoteContext();
  const { bowId } = ids;
  const selectedQuote = useSelectedQuote();
  const { hateoasExecutor } = useHateoasExecutorContext();

  if (!selectedQuote) return null;

  const latestDocument: WarWeb.LegalDocument = max(selectedQuote.documents, 'createdTime');

  const downloadDocument = async (action: string) => {
    const link = selectedQuote.links.find(a => a.action === action);
    if (!link) return;
    const links = [{
      ...link,
      href: addTimezoneOffset(link.href)
    }];

    const response = await hateoasExecutor(links, action, {}, 'documentation', {
      headers: {
        Accept: MediaType.Pdf
      },
      responseType: 'blob'
    });

    if (response) parseBlobData(response, MediaType.Pdf);
  };

  return (
    selectedQuote.status !== 'Draft' && selectedQuote.status !== 'Discarded' ? (
      <>
        <DocumentDate>
          {latestDocument ? `Updated ${TimeConverter.ToDateTime(latestDocument.createdTime)}` :
            'Documents are being generated'}
        </DocumentDate>

        <DownloadLinks>
          <HateoasHandler
            links={selectedQuote.links}
            action={QuoteActions.BrokerDocument}
            editVariant={(
              <DownloadContainer>
                <ButtonWithLoader onClick={() => downloadDocument(QuoteActions.BrokerDocument)}>
                  <Actions left>
                    <Download />
                  </Actions>
                  Broker version
                </ButtonWithLoader>
              </DownloadContainer>
            )}
          />

          <HateoasHandler
            links={selectedQuote.links}
            action={QuoteActions.KidnapRansomDocument}
            editVariant={(
              <DownloadContainer>
                <ButtonWithLoader onClick={() => downloadDocument(QuoteActions.KidnapRansomDocument)}>
                  <Actions left>
                    <Download />
                  </Actions>
                  K&R version
                </ButtonWithLoader>
              </DownloadContainer>
            )}
          />
          <HateoasHandler
            links={selectedQuote.links}
            action={QuoteActions.UnderwriterDocument}
            editVariant={(
              <DownloadContainer small>
                <ButtonWithLoader
                  onClick={() => downloadDocument(QuoteActions.UnderwriterDocument)}>
                  <Actions left>
                    <Download />
                  </Actions>
                  Underwriter version
                </ButtonWithLoader>
              </DownloadContainer>
            )}
          />

        </DownloadLinks>

        <ButtonGroup justify="flex-end">
          <Button thin to={`${Routes.bows}/${bowId}/documents`}>See more</Button>
        </ButtonGroup>
      </>
    ) : <EmptyDocumentation />
  );
};
