import {
  createContext, FC, useContext, useMemo, useState
} from 'react';

const ExcludedAreaPageContext = createContext<ProviderProps>({
  setRegionId: () => { },
  setAreaId: () => { },
  setAreaType: () => { },
  hasSaved: false,
  setHasSaved: () => { }
});

interface ProviderProps {
  regionId?: number;
  areaId?: number;
  areaType?: string;
  hasSaved: boolean;
  setRegionId: (id: number) => void;
  setAreaId: (id?: number) => void;
  setAreaType: (areaType: string) => void;
  setHasSaved: (hasSaved: boolean) => void;
}
export const ExcludedAreaPageProvider: FC = ({ children }) => {
  const [regionId, setRegionId] = useState<number>();
  const [areaId, setAreaId] = useState<number>();
  const [areaType, setAreaType] = useState<string>();
  const [hasSaved, setHasSaved] = useState<boolean>(false);

  const value =
    useMemo(
      () => ({ regionId, areaId, areaType, hasSaved, setRegionId, setAreaId, setAreaType, setHasSaved }),
      [regionId, areaId, areaType, hasSaved, setRegionId, setAreaId, setAreaType, setHasSaved]
    );
  return (
    <ExcludedAreaPageContext.Provider value={value}>
      {children}
    </ExcludedAreaPageContext.Provider>
  );
};

export const useExcludedAreaPageContext = () => useContext(ExcludedAreaPageContext);
