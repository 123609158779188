import { isAdmin, isUnderwriter } from '@/components/routing/roles';
import { FilterBar } from '@/components/shared/FilterBar';
import { FilterButton } from '@/components/shared/FilterButton';
import { renderFleetAndVesselSearchResult } from '@/components/shared/MarineObjectSearch';
import { Checkbox } from '@/components/shared/Form/fields/Checkbox';
import { SearchFieldDropdown } from '@/components/shared/Form/FormFields';
import { SelectionBar } from '@/components/shared/SelectionBar';
import {
  getDelimiters, getHeaders, NoDataRow, TableHeader
} from '@/components/shared/Table/core/Components';
import {
  FilterableTableHeaders, Table, TableCell, TableRow
} from '@/components/shared/Table/Table';
import useDebounce from '@/hooks/useDebouncedValue';
import { Filters } from '@/models/Filters';
import { SortedHeader, SortOrder } from '@/models/SortOrder';
import { useFleetsAndVessels } from '@/services/fleetsService';
import { basepath, useVessels } from '@/services/vesselsService';
import { addOrRemove } from '@/utility/arrayHelpers';
import { WarWeb } from '@/war';
import {
  ChangeEvent,
  useEffect, useMemo, useState
} from 'react';
import styled from 'styled-components';
import { AgreementViewMode, useAgreementDetailsContext } from '../../agreementDetails/AgreementDetailsContext';
import { useAgreementSelectionContext } from '../../agreementDetails/AgreementSelectionContext';
import { useIsBulkEditing } from '../../agreements/useIsBulkEditing';
import { PaginationBar } from '../../common/PaginationBar';
import { FilterableTableHeadersProvider } from '../../defaultTermsConditions/FilterableTableHeadersContext';
import { tableSections } from './headers';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StatusButtons = styled.div`
  display: flex;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.3rem;
  width: 35vw;

  div {
    width: 100%;
  }
`;

interface AppliesToRowProps {
  row: WarWeb.MarineMatch
}
const AppliesToRow = ({ row }: AppliesToRowProps) => {
  const { viewMode } = useAgreementDetailsContext();
  const { setVesselIds, vesselIds } = useAgreementSelectionContext();
  const isBulkEdit = useIsBulkEditing();

  const isSelected = vesselIds?.some(vesselId => vesselId === row.vesselId);
  const canEditVessel = viewMode !== AgreementViewMode.Inspect && !isBulkEdit;

  const onChangeHandler = (id: number, fleetId?: number) => {
    const updatedSelection = [...vesselIds];
    addOrRemove(updatedSelection, id);
    setVesselIds(updatedSelection);
    // setFleetId(updatedSelection.length === 0 ? undefined : fleetId);
  };

  return (
    <TableRow hover onClick={canEditVessel ? () => onChangeHandler(row.vesselId!, row.fleetId) : undefined}>
      {canEditVessel && (
        <TableCell>
          <Checkbox
            name={row.id.toString()}
            checked={isSelected}
            onChange={() => onChangeHandler(row.vesselId!, row.fleetId)}
          />
        </TableCell>
      )}
      <TableCell>{row.name}</TableCell>
      <TableCell>{row.fleetName}</TableCell>
      <TableCell>{row.clientName}</TableCell>
      <TableCell>{row.imo}</TableCell>
      <TableCell>{row.vesselTypeName}</TableCell>
    </TableRow>
  );
};

const defaultSorting = {
  propertyName: 'Name',
  sortOrder: SortOrder.Ascending
};

const defaultFilters = {
  pageNumber: '1',
  pageSize: '10'
};

export const VesselSelector = () => {
  const { viewMode, targetsSummary } = useAgreementDetailsContext();
  const { vesselIds, setVesselIds, fleetIds, setFleetIds } = useAgreementSelectionContext();

  const [sortedHeader, setSortedHeader] = useState<SortedHeader | undefined>(defaultSorting);

  const isBulkEdit = useIsBulkEditing();
  const canEditVessel = viewMode !== AgreementViewMode.Inspect && !isBulkEdit;

  const [searchFleetsFilters, setSearchFleetsFilters] = useState<Filters>();
  const debouncedFleetsFilters = useDebounce(searchFleetsFilters, 500);
  const { data: results, isLoading: searchLoading } = useFleetsAndVessels(debouncedFleetsFilters);

  const handleInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchFleetsFilters({ ...searchFleetsFilters, freetext: e.target.value });
  };

  const originalVesselIds = useMemo(() => targetsSummary?.vessels.map(x => x.vesselId) || [], [targetsSummary.vessels]);

  const defaultFiltersUnderwriter = {
    ...defaultFilters,
    fleetId: fleetIds[0]
  };
  const defaultFiltersInspect = useMemo(() => ({
    ...defaultFilters,
    selectedVesselIds: originalVesselIds, // TODO: Perhaps undefinable
    fleetId: isAdmin() ? undefined : fleetIds[0]
  }), [fleetIds, originalVesselIds]);

  const defaultFiltersEdit = isAdmin() ? defaultFilters : defaultFiltersUnderwriter;

  const [filters, setFilters] = useState<Filters>(canEditVessel ? defaultFiltersEdit : defaultFiltersInspect);

  const clearFilters = () => setFilters(canEditVessel ? defaultFiltersEdit : defaultFiltersInspect);

  const debouncedFilters = useDebounce(filters, 500);

  useEffect(() => {
    setFilters(canEditVessel ? defaultFiltersEdit : defaultFiltersInspect);
  }, [canEditVessel, defaultFiltersInspect, fleetIds]);

  const setFleet = (selection: WarWeb.MarineMatch) => {
    setFleetIds([selection.fleetId]);
    setFilters({ ...filters, fleetId: `${selection.fleetId}` });
    setSearchFleetsFilters(undefined);
  };

  const { data: vessels, isLoading, error } = useVessels(isAdmin() || debouncedFilters.fleetId ? debouncedFilters : undefined);

  const [selectAll, setSelectAll] = useState<boolean>();
  const { data: allVessels } = useVessels(canEditVessel && selectAll ? { ...debouncedFilters, pageNumber: '1', pageSize: '1000' } : undefined);

  const headers = getHeaders(tableSections);

  useEffect(() => {
    clearFilters();
  }, [filters.SelectedVesselIds]);

  useEffect(() => {
    if (viewMode === AgreementViewMode.Inspect) {
      setFilters(prev => ({ ...prev, selectedVesselIds: originalVesselIds }));
    }
  }, [originalVesselIds]);

  const toggleVessels = () => {
    setSelectAll(!selectAll);
  };

  const resetFleet = () => {
    const { fleetId: fleetIdFilter, selectedVesselIds, ...rest } = filters;
    setVesselIds([]);
    setFilters(rest);
    setFleetIds([]);
  };

  useEffect(() => {
    if (selectAll) {
      if (allVessels) {
        const preExistingPlusAll = [...new Set(vesselIds.concat(allVessels.items.map(v => v.vesselId!)))];
        setVesselIds(preExistingPlusAll);
      }
    }
  }, [allVessels, selectAll, setVesselIds]);

  useEffect(() => {
    if (selectAll === false) {
      setVesselIds([]);
    }
  }, [selectAll]);

  return (
    <>
      {isAdmin() || fleetIds.length > 0 ? (
        <>
          {vessels ? (
            <FilterableTableHeadersProvider
              headers={headers}
              columnMetadata={vessels?.columnMetadata}
              initialFilters={canEditVessel ? defaultFiltersEdit : defaultFiltersInspect}
              filters={filters}
              setFilters={setFilters}
              sortedHeader={sortedHeader}
              setSortedHeader={setSortedHeader}
              isLoading={isLoading}
            >
              <HeaderContainer>
                <SelectionBar
                  type="vessel"
                  parameterName="selectedVesselIds"
                  editMode={canEditVessel}
                  selection={vesselIds}
                  selected={!!filters?.selectedVesselIds && filters.selectedVesselIds.length > 0}
                  initialSelection={originalVesselIds}
                  setter={setVesselIds}
                  resetSelectionCallback={() => setSelectAll(undefined)}
                />
                {canEditVessel && (
                  <>
                    {isUnderwriter() &&
                      <FilterButton label="Reset fleet" toggle={resetFleet} />}

                    <StatusButtons>
                      <FilterBar />
                    </StatusButtons>
                  </>
                )}

              </HeaderContainer>

              <Table columns={headers.length + (canEditVessel ? 1 : 0)} striped>
                {canEditVessel && (
                  <TableHeader>
                    <Checkbox
                      name="selectAll"
                      checked={selectAll}
                      onChange={toggleVessels}
                    />
                  </TableHeader>
                )}
                <FilterableTableHeaders
                  endpoint={basepath}
                  delimiters={getDelimiters(tableSections)}
                  wide
                />
                {vessels?.items?.filter(v => canEditVessel || vesselIds?.some(x => x === v.vesselId))?.map(vessel => (
                  <AppliesToRow key={vessel.id} row={vessel} />
                ))}
              </Table>
            </FilterableTableHeadersProvider>
          ) : <NoDataRow loadStatus={{ loading: isLoading, success: !error }} />}
        </>
      ) : (
        isUnderwriter() && (
          <Content>
            <SearchFieldDropdown
              label="Search"
              name="area"
              placeholder="Fleet name, Vessel name, IMO Number"
              onChange={handleInput}
              results={results}
              keyField="vesselId"
              loadStatus={{ loading: searchLoading, success: !!results }}
              callback={setFleet}
              renderResult={renderFleetAndVesselSearchResult}
            />
          </Content>
        )
      )}
      {vessels?.pagingMetadata && (
        <PaginationBar
          currentPage={+filters.pageNumber}
          metadata={vessels.pagingMetadata}
          changePage={newPage => setFilters({ ...filters, pageNumber: `${newPage}` })}
        />
      )}
    </>
  );
};
