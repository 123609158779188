import React, { useEffect } from 'react';
import { isAdmin } from '@/components/routing/roles';
import { AgreementTypeEnum, TextConditionSummary } from '@/models/Agreement';
import { TargetType } from '@/models/TargetType';
import { useAgreements } from '@/services/agreementsService';
import { getDifference, zipBy } from '@/utility/array';
import { WarWeb } from '@/war';
import { useAgreementSelectionContext } from '../agreementDetails/AgreementSelectionContext';
import { AgreementViewMode, useAgreementDetailsContext } from '../agreementDetails/AgreementDetailsContext';
import { useIsBulkEditing } from '../agreements/useIsBulkEditing';
import { useAgreementDataContext } from '../agreementDetails/AgreementDataContext';
import { ConditionsType } from '../agreementDetails/models';

// TODO: Convert to hook once we sort out on which conditions it should be called
export const TextConditionsUpdater = () => {
  const { viewMode } = useAgreementDetailsContext();
  const { conditions, setConditions } = useAgreementDataContext();
  const { areaIds, areaIdsTimeExceptions } = useAgreementSelectionContext();

  const isBulkEdit = useIsBulkEditing();
  const isEdit = viewMode !== AgreementViewMode.Inspect;
  const isAdminAndBulkEdit = isAdmin() && isBulkEdit;

  const excludedAreaIds = getDifference(areaIds, areaIdsTimeExceptions || []);
  const standardAgreementsTarget = { areaIds: excludedAreaIds, targetType: TargetType.Standard };
  const { data: standardAgreements } = useAgreements(isEdit && !isAdminAndBulkEdit && excludedAreaIds.length > 0
    ? standardAgreementsTarget : undefined, AgreementTypeEnum.Standard, 'textConditionsUpdater');

  useEffect(() => {
    if (!isEdit || isAdminAndBulkEdit) return;

    const setupConditions = (localConditionsType: ConditionsType, agreements?: WarWeb.AgreementSummary) => {
      const defaultTextConditions: TextConditionSummary[] = agreements ? agreements[localConditionsType].filter(c => c.tier === 'Minimum')
        .map(x => ({ ...x, isDirty: true, action: 'Add', tier: undefined, bySystem: true })) : [];
      const specificTextConditions = conditions ? conditions[localConditionsType].filter(x => !x.bySystem) : [];

      const getStateForZip = (limit?: TextConditionSummary, existing?: TextConditionSummary): TextConditionSummary => {
        const isMandatoryOnLimit = limit?.mandatoryOptions.some(it => it.option);
        const isRecommendedOnLimit = limit?.includedOptions.every(it => !it.option);
        const isIncludedOnExisting = existing?.includedOptions.some(it => it.option);

        if (isAdmin() && existing?.isDirty) return existing;
        if (isMandatoryOnLimit && isIncludedOnExisting) {
          existing!.mandatoryOptions[0].option = true;
          return existing!;
        }
        if (isMandatoryOnLimit) return limit!;

        // Default checked if new, recommended condition
        if (isRecommendedOnLimit && !existing) {
          limit!.includedOptions = [...limit!.includedOptions.map(x => ({ ...x, option: true }))];
        }

        // Show "recommended" label if it's on limit but not checked
        if (isRecommendedOnLimit && existing && !isIncludedOnExisting) {
          existing.bySystem = true;
        }

        if (existing) return existing;

        return limit!;
      };

      const zipped = zipBy([defaultTextConditions, specificTextConditions], it => it.termId).map(zip => {
        const [limit, existing] = zip.items;
        return getStateForZip(limit, existing);
      });

      setConditions(prev => prev ? { ...prev, [localConditionsType]: zipped } : { subjectivities: [], warranties: [], [localConditionsType]: zipped });
    };

    setupConditions('warranties', standardAgreements);
    setupConditions('subjectivities', standardAgreements);
  }, [standardAgreements, viewMode]);

  return (
    null
  );
};
