import React, { useState } from 'react';
import { isInternalUser, isReinsurer } from '@/components/routing/roles';
import { Routes } from '@/components/routing/routes';
import { ButtonMenu } from '@/components/shared/ButtonMenu';
import {
  BorderedTextBox, TableCell, TableRow
} from '@/components/shared/Table/Table';
import { setNotNew } from '@/services/vesselsService';
import { WarWeb } from '@/war';
import { MenuItem } from '@/models/utils/MenuItem';
import { copyPasteAgreements } from '@/services/agreementsService';
import {
  Close, Copy, CurrencyDollar, Paste, Pin, PrivacyPolicy
} from '@instech/icons';
import QueryString from 'qs';
import styled from 'styled-components';
import { Loader } from '@instech/components';
import { groupBy } from '@/utility/arrayHelpers';
import moment from 'moment';
import { getColumnWidth } from '@/components/shared/Table/core/Components';
import { tableSections, tableSectionsInternal } from './tableHeaders';
import { InterestsSubtable } from './InterestsSubtable';
import { SetVesselNotNewConfirmationModal } from './SetVesselNotNewConfirmationModal';
import { AddFleetSpecificTermsConfirmationModal } from './AddFleetSpecificTermsConfirmationModal';

const StyledTableRow = styled(TableRow)`
  > span {
    justify-content: center;
  }
`;

const LoaderWapper = styled.div`
  margin: 0 1.5rem;
`;

const StyledClose = styled(Close)`
  height: 12px;
`;

interface InterestMatchId extends WarWeb.InterestMatch {
  id: number
}

export const VesselRow = ({ vessel, baseVessel, setBaseVessel, agreementCount, setAgreementCount, mutate }: {
  vessel?: WarWeb.MarineMatch,
  baseVessel?: WarWeb.MarineMatch,
  setBaseVessel: (vesselId?: WarWeb.MarineMatch) => void,
  agreementCount: number,
  setAgreementCount: React.Dispatch<React.SetStateAction<number>>,
  mutate: () => void
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showVesselNotNewModal, setShowVesselNotNewModal] = useState(false);
  const [showCopyTermsModal, setShowCopyTermsModal] = useState(false);

  const tableSectionsByRole = isInternalUser() ? tableSectionsInternal : tableSections;
  const subtableLayout = tableSectionsByRole.find(h => h.tag === 'interests')?.columns.map(h => getColumnWidth(h.format)).join(' ');

  if (!vessel) return null;

  const handleCopy = () => {
    if (!vessel.vesselId) return;
    setBaseVessel(vessel);
    setAgreementCount(vessel.agreementCount ?? 0);
  };

  const handleRemove = async () => {
    setBaseVessel();
    setAgreementCount(0);
  };

  const pasteAgreements = async () => {
    if (!vessel.vesselId || !baseVessel?.vesselId) return;
    setIsLoading(true);
    await copyPasteAgreements(baseVessel.vesselId, vessel.vesselId);
    mutate();
    setTimeout(async () => {
      mutate();
      setIsLoading(false);
      await handleRemove();
    }, 5000);
  };

  const handlePaste = async () => {
    if (!vessel.vesselId) return;
    setShowCopyTermsModal(true);
  };

  const removeNewFlag = async () => {
    if (!vessel.vesselId) return;
    setIsLoading(true);
    await setNotNew(vessel.vesselId);
    mutate();
    setTimeout(async () => {
      mutate();
      setIsLoading(false);
      await handleRemove();
    }, 5000);
  };

  const handleSetNotNew = async () => {
    if (!vessel.vesselId) return;
    setShowVesselNotNewModal(true);
  };

  const menuItems: MenuItem[] = [
    {
      id: 1,
      name: 'Location',
      icon: <Pin />,
      href: `https://www.marinetraffic.com/en/ais/details/ships/${vessel.imo}`,
      target: `_marineTraffic${vessel.imo}`
    },
    {
      id: 3,
      name: 'Breach of Warranty',
      icon: <CurrencyDollar />,
      route: `${Routes.bows}?${QueryString.stringify({ SelectedFleets: vessel.fleetName, SelectedVessels: vessel.name })}`
    }
  ];
  if (!isReinsurer()) {
    menuItems.push(
      {
        id: 2,
        name: 'Terms & Conditions',
        icon: <PrivacyPolicy />,
        route: `${Routes.termsSpecific}?${QueryString.stringify({ SelectedFleets: vessel.fleetName, SelectedVessels: vessel.name })}`
      }
    );
    menuItems.sort((x, y) => x.id - y.id);
  }

  const hasAgreements = vessel.agreementCount && vessel.agreementCount > 0;
  if (isInternalUser({ onlyEditors: true })) {
    if (hasAgreements && vessel.vesselId !== baseVessel?.vesselId) {
      menuItems.push(
        {
          id: 4,
          name: 'Copy Fleet Specific T&C',
          icon: <Copy />,
          onClick: handleCopy,
        },
      );
    }

    if (hasAgreements && vessel.vesselId === baseVessel?.vesselId) {
      menuItems.push(
        {
          id: 5,
          name: 'Remove selection',
          icon: <Close />,
          onClick: handleRemove,
        },
      );
    }

    if (!hasAgreements && baseVessel?.vesselId != null) {
      menuItems.push(
        {
          id: 6,
          name: 'Paste Fleet Specific T&C',
          icon: <Paste />,
          onClick: handlePaste,
        }
      );
    }
  }

  const addId = (id: number, data?: WarWeb.InterestMatch[]) => data?.map(d => ({
    ...d,
    id
  }));

  const InterestIds = {
    HullAndMachineryInterest: 0,
    TotalLossInterest: 1,
    LossOfHireInterest: 2,
    KidnapRansomInterest: 3
  };

  const interests = [
    addId(InterestIds.HullAndMachineryInterest, vessel.hullAndMachineryInterest),
    addId(InterestIds.TotalLossInterest, vessel.totalLossInterest),
    addId(InterestIds.LossOfHireInterest, vessel.lossOfHireInterest),
    addId(InterestIds.KidnapRansomInterest, vessel.kidnapRansomInterest)
  ].flat();

  const periods: InterestMatchId[][] = groupBy(interests, (it: WarWeb.InterestMatch) => it.fromDate + it.toDate);

  periods.sort((a: InterestMatchId[], b: InterestMatchId[]) => {
    const fromDateA = moment(a[0].fromDate);
    const fromDateB = moment(b[0].fromDate);
    return fromDateA.isBefore(fromDateB) ? -1 : 1;
  });

  return (
    <StyledTableRow dashed={vessel.vesselId === baseVessel?.vesselId}>
      <TableCell>{vessel.name}</TableCell>
      <TableCell>{vessel.vesselTypeName}</TableCell>
      <TableCell>{vessel.built}</TableCell>
      <TableCell slim span={5} delimiter="left">
        <InterestsSubtable vessel={vessel} layout={subtableLayout} />
      </TableCell>

      {isInternalUser() && <TableCell right delimiter="left">{isLoading ? <Loader size="small" /> : vessel.agreementCount}</TableCell>}
      {
        isInternalUser() && (
          <TableCell center>
            {vessel.isNew && (
              <BorderedTextBox onClick={() => handleSetNotNew()}>
                {isLoading ? <LoaderWapper><Loader size="small" /></LoaderWapper> : (
                  <>
                    New
                    {isInternalUser({ onlyEditors: true }) && (
                      <StyledClose />
                    )}
                  </>
                )}
              </BorderedTextBox>
            )}
          </TableCell>
        )
      }

      {/* <TableCell delimiter="left" /> // TODO: Add Total gross premium for all roles
      <TableCell /> // TODO: Add NHC net share for non-broker roles */}
      <TableCell>
        <ButtonMenu items={menuItems} />
      </TableCell>

      {showVesselNotNewModal && <SetVesselNotNewConfirmationModal apply={removeNewFlag} vessel={vessel} closeModal={() => setShowVesselNotNewModal(false)} />}
      {showCopyTermsModal && (
        <AddFleetSpecificTermsConfirmationModal
          apply={pasteAgreements}
          agreementCount={agreementCount}
          vessel={vessel}
          closeModal={() => setShowCopyTermsModal(false)} />
      )}
    </StyledTableRow>
  );
};
