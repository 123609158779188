import React, {
  useEffect, useRef, useState
} from 'react';
import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { Edit, Load } from '@instech/icons';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { TimeConverter } from '@/utility/timeConverter';
import { theme } from '@/utility/theme';
import { bowStatus, Status } from '@/utility/bowStatus';
import { useHateoasExecutorContext } from '@/services/HateoasExecutorContext';
import { HateoasHandler } from '@/components/shared/HateoasHandler';
import { WarWeb } from '@/war';
import { useQuoteContext } from '@/components/shared/BowSidebar/QuoteContext';
import { DirtyableValue } from '@/models/utils/DirtyableValue';
import { getLastElement } from '@/utility/arrayHelpers';
import { Globals } from '@/utility/globals';
import { TextField } from '@/components/shared/Form/fields/TextField';
import { QuoteActions } from '../quoteActionsMap';

const Container = styled.div`
  border-right: 3px solid ${props => props.theme.border.white};
  height: 100%;
`;

// highlight={selected ? statusColor(quote.status, quoteStatusModifier !== null) : ''}
const QuoteContainer = styled.div<{ status: string, selected?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  white-space: nowrap;
  flex: 0 0 auto;
  position: relative;

  ${props => props.selected && css`
    background-color: ${props.theme.lightBlue};
  `};

  font-size: 12px;
  line-height: 1.3em;
  width: 350px;
  height: 100%;
`;

const Content = styled.div`
  padding: 0 1em 0.5em 1em;
  /* border-bottom: 1px solid ${props => props.theme.lightGray}; */
  cursor: pointer;
`;

const Title = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
`;

const VesselName = styled.div`
  display: block;
  font-size: inherit;
  margin-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  flex-shrink: 1;
  width: auto;
  text-overflow: ellipsis;
`;

const Rebates = styled.div`
  display: flex;
  flex: 0;
  width: auto;
  flex-shrink: 0;
`;

const Comment = styled.div<{ selected: boolean, editable: boolean }>`
  font-weight: 400;
  font-size: 14px;
  min-height: calc(1.5em + 5px);
  border-radius: 2px;
  overflow: hidden;
  text-overflow: ellipsis;

  ${props => props.editable && css`
    :hover {
      background-color: ${props.selected ? props.theme.white : props.theme.ultraLightGray};
    }
  `};

  .tooltip {
    color: ${theme.marineBlue};
    max-width: 350px;
    line-height: 1.5em;
    white-space: normal;
    box-shadow: 3px 6px 20px 12px rgba(72,135,177,0.2);
  }
`;

const Metadata = styled.div` 
  font-size: 11px;
  font-weight: lighter;
  color: ${props => props.theme.marineBlue75};
`;

const statusColor = (status: string, expired: boolean) => {
  if (expired) return theme.status.orange;
  return bowStatus.find(n => n.title === status)?.color || darken(0.2, theme.whiteBlue);
};

const StatusLine = styled.div<{ status: string, selected?: boolean }>`
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 17px;
  cursor: pointer;

  ${props => props.status === 'Draft' || props.status === 'Quoted' || props.status === 'PendingConfirmation' ? css`
    background: repeating-linear-gradient(
      -45deg,
      ${props.theme.white},
      ${props.theme.white} 10px,
      ${props.color} 10px,
      ${props.color} 20px
    );
  ` : css`
    background-color: ${props.color};
  `};
`;

const StatusIndicator = styled.div<{ status: string, selected?: boolean }>`
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  text-transform: uppercase;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  height: 100%;
  width: 35%;

  span {
    display: inline-block;
    line-height: 1em;
    border-radius: 2px;
    padding: 0 0.2em;
    ${props => (props.status === 'Draft' || props.status === 'Quoted' || props.status === 'PendingConfirmation') && css`
      background-color: ${props.theme.white};
    `};
  }
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const TopPart = styled.div`
  display: flex;
  height: 5px;
`;

const BottomLine = styled.div<{ status: string, selected?: boolean }>`
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 5px;
  cursor: pointer;
  background-color: ${props => props.color};
`;

const BottomIndicator = styled.div<{ status: string, selected?: boolean }>`
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  height: 20px;
  width: 35%;
  border-radius: 4px 0px 0px 0px;
  background-color: ${props => props.color};
  span {
    display: inline-block;
    line-height: 1em;
    border-radius: 2px;
    padding: 0 0.2em;
  }
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex: 1;
`;

const Cutaway = styled.div<{ selected?: boolean }>`
  display: flex;
  height: ${props => props.selected ? '12px' : '17px'};
  background-color: ${props => props.selected ? props.theme.lightBlue : props.theme.white};
`;

const EditIndicator = styled(Edit)`
  position: absolute;
  height: 16px;
  z-index: 10;
  top: 2.2rem;
  right: 1rem;
`;

const LoadingAnimation = styled(Load)`
  position: absolute;
  height: 7px;
  z-index: 10;
  top: 2.2rem;
  right: 1rem;
`;

const CommentTooltip = styled.a`
`;

interface CornerProps {
  currentColor: string
}
const Corner = ({ currentColor }: CornerProps) => (
  <svg width="4px" height="4px" viewBox="0 0 4 4" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path d="M0,0 C0,2.209139 1.790861,4 4,4 L0,4 L0,0 Z" id="Combined-Shape" fill={currentColor} />
    </g>
  </svg>
);

interface QuotesBoxProps {
  quote: WarWeb.BowQuote,
  selectAlternative: (id: string) => void;
  selected?: boolean;
}
export const QuotesBox = ({ quote, selectAlternative, selected = false }: QuotesBoxProps) => {
  const { isNoClaimsBonusBased } = useQuoteContext();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { hateoasExecutor } = useHateoasExecutorContext();
  const quoteReference = useRef<HTMLDivElement>(null);
  const [comment, setComment] = useState<DirtyableValue<string>>({ value: quote?.comment });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setComment({ value: quote?.comment, isDirty: false });
  }, [quote?.comment]);

  useEffect(() => {
    if (selected && quoteReference.current) quoteReference.current.scrollIntoView({ block: 'center' });
  }, [selected]);

  const commentDoesNotOverflow = (element: HTMLDivElement | null) => {
    if (!element) return true;
    return element.scrollWidth <= element.clientWidth;
  };

  const listRebates = () => {
    const selectedRebates = quote.rebates?.filter(m => m.isSelected).map(r => r.shortName);
    if (!selectedRebates) return '';

    const ncbRebateIndex = selectedRebates?.findIndex(r => r === 'NCB');
    if (!isNoClaimsBonusBased) {
      if (ncbRebateIndex >= 0) selectedRebates.splice(ncbRebateIndex, 1);
      selectedRebates.unshift('CP');
    }

    if (selectedRebates.length > 0) return `(${selectedRebates.join(', ')})`;
    return '';
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setComment({ value: e.target.value, isDirty: true });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      (e.target as HTMLInputElement).blur();
    }
  };

  const commitComment = async () => {
    if (comment.isDirty) {
      setLoading(true);
      void hateoasExecutor(quote.links, QuoteActions.UpdateComment, { comment: comment.value }, 'comment');
      setLoading(false);
    }
  };

  const isQuotedStatus = bowStatus.find(n => n.title === quote.status)?.status === Status.Quoted;
  const isPendingConfirmationStatus = bowStatus.find(n => n.title === quote.status)?.status === Status.PendingConfirmation;
  const isExpired = isQuotedStatus && moment(quote.expiration).isBefore(moment());

  let quoteStatusModifier = null;
  if (isExpired && isQuotedStatus) quoteStatusModifier = 'expired ';

  const quoteStatusLabel = isPendingConfirmationStatus ? 'Pending' : bowStatus.find(n => n.title === quote.status)?.title;
  const quoteStatusLabelWithModifier = `${quoteStatusModifier}${bowStatus.find(n => n.title === quote.status)?.noun}`;

  const randomID = String(Math.random());

  const utcNow = moment().utc();
  const utcEntryCheck = moment().utc().add(Globals.EntryHoursCheck, 'hours');
  const utcExitCheck = moment().utc().subtract(Globals.ExitDaysCheck, 'days');

  const utcEntryDate = quote.coveredAreas && quote.coveredAreas[0] ? quote.coveredAreas[0].entry?.utc : undefined;
  const utcExitDate = quote.coveredAreas ? getLastElement(quote.coveredAreas)?.exit?.utc : undefined;

  const isEntryAlert = moment(utcEntryDate).isBetween(utcNow, utcEntryCheck);
  const isEntryPastAlert = moment(utcEntryDate).isBefore(utcNow);
  const isExitAlert = moment(utcExitDate).isBefore(utcExitCheck);

  const entryText = `Entry < ${Globals.EntryHoursCheck} hr`;
  const entryPastText = 'Passed Entry';
  const exitText = `Exit > ${Globals.ExitDaysCheck} days`;

  let backgroundAlertColor = selected ? theme.lightBlue : theme.white;
  let alertText = '';

  if (quote.coveredAreas && quote.coveredAreas.length > 0) {
    if (quote.status === 'Quoted') {
      if (isEntryAlert && !isExitAlert) {
        backgroundAlertColor = theme.yellow;
        alertText = entryText;
      }
      if (isEntryPastAlert && !isExitAlert) {
        backgroundAlertColor = theme.yellow;
        alertText = entryPastText;
      }
      if (isExitAlert) {
        backgroundAlertColor = theme.yellow;
        alertText = exitText;
      }
    }
    if (quote.status === 'Confirmed' && isExitAlert) {
      backgroundAlertColor = theme.mediumRed;
      alertText = exitText;
    }
  }

  return (
    <Container onClick={() => !selected ? selectAlternative(quote.id) : undefined} ref={quoteReference}>
      <QuoteContainer status={quote.status} selected={selected}>
        <StatusLine status={quote.status} color={statusColor(quote.status, isExpired)} selected={selected}>
          <StatusContainer>
            {selected && <TopPart />}
            <Cutaway selected={selected} />
          </StatusContainer>
          <Corner currentColor={selected ? theme.lightBlue : theme.white} />
          <StatusIndicator status={quote.status} color={statusColor(quote.status, isExpired)} selected={selected}>
            <span>
              {quoteStatusModifier !== null ? quoteStatusLabelWithModifier : quoteStatusLabel}
            </span>
          </StatusIndicator>
        </StatusLine>
        <Content>
          <Title>
            <VesselName>{`${quote.alternative} - ${quote.vessel.name}`}</VesselName>
            <Rebates>{listRebates()}</Rebates>
          </Title>
          <HateoasHandler
            links={quote.links}
            action={QuoteActions.UpdateComment}
            editVariant={(
              <Comment selected={selected} editable>
                <TextField
                  minimal
                  name="comment"
                  noErrors
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  onBlur={commitComment}
                  value={comment.value || ''}
                  autoComplete="off"
                  placeholder="Add comment..."
                />
                <EditIndicator />
                {loading && <LoadingAnimation />}
              </Comment>
            )}
            viewVariant={(
              <Comment ref={wrapperRef} selected={selected} editable={false}>
                <CommentTooltip data-for={randomID} data-tip="">
                  {quote.comment || ' '}
                </CommentTooltip>
                <ReactTooltip
                  id={randomID}
                  backgroundColor={theme.white}
                  disable={commentDoesNotOverflow(wrapperRef?.current)}
                  effect="float"
                  className="tooltip">
                  {quote.comment || ' '}
                </ReactTooltip>
              </Comment>
            )}
          />
          <Metadata>
            Saved
            {' '}
            {TimeConverter.ToDateTime(quote.lastUpdated?.dateTime)}
          </Metadata>
        </Content>
        <BottomContainer>
          <BottomLine status={quote.status} color={selected ? backgroundAlertColor : theme.white} selected={selected} />
          <BottomIndicator status={quote.status} color={backgroundAlertColor} selected={selected}>
            <span>
              {alertText}
            </span>
          </BottomIndicator>
        </BottomContainer>
      </QuoteContainer>
    </Container>
  );
};
