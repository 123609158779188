import React from 'react';
import { useAccount } from 'its-js-authentication';
import { useLocation } from 'react-router';
import { Routes } from '@/components/routing/routes';
import { useQuery } from '@/hooks/useQuery';

const getRedirectRoute = ({ state }) => {
  if (!state || !state.from) return Routes.base;
  const { pathname, search } = state.from;
  return pathname + search;
};

export const LoginPage = () => {
  const { login } = useAccount();
  const location = useLocation();
  const route = getRedirectRoute(location);
  const query = useQuery();

  const directExternalLogin = query.has('directExternalLogin');
  const options = {
    state: { route },
    extraQueryParams: { directExternalLogin }
  };
  login(options);

  return (
    <div>
      You will now be logged in
    </div>
  );
};
