import QueryString from 'qs';
import moment from 'moment-timezone';
import { TimezoneResult } from '@/components/pages/excludedAreas/EditExcludedAreasPage/types';
import { Filters } from '@/models/Filters';
import { useSWREndpoint } from '@/hooks/useSWREndpoint';
import { AcceptHeaders } from './config';

const timezones = moment.tz.names().map(timezone => (
  {
    area: timezone,
    timezone: ` (GMT${moment.tz(timezone).format('Z')}) `
  }));

export const getTimezonesUri = (params: Filters) => `dummyTimezones?${QueryString.stringify(params, { indices: false })}`;

const timezonesFetcher = async (params: Filters) => {
  const hits: TimezoneResult[] = [];
  if (params.freetext) {
    timezones.forEach(timezone => {
      if (timezone.area.toLowerCase().indexOf(params.freetext.toLowerCase()) >= 0
      || timezone.timezone.toLowerCase().indexOf(params.freetext.toLowerCase()) >= 0) {
        hits.push(timezone);
      }
    });
  }
  return hits;
};

export const useTimezones = (params: Filters) =>
  useSWREndpoint<TimezoneResult[]>(getTimezonesUri(params), AcceptHeaders.SearchResult, () => timezonesFetcher(params));
