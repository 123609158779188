import {
  NoDataRow,
  renderTableHeaders, Table, TableCell, TableRow
} from '@/components/shared/Table/Table';
import { useFleetHistory } from '@/services/fleetsService';
import { UserInitials } from '@/utility/userNameHelpers';
import { WarWeb } from '@/war';
import { formatDate } from '@/utility/dateCalc';

const headers = ['New Tier', 'Date', 'By'];

const TierHistoryRow = ({ entry }: { entry: WarWeb.FleetTierHistoryEntry }) => (
  <TableRow>
    <TableCell>{entry.tier}</TableCell>
    <TableCell>{formatDate(entry.date)}</TableCell>
    <TableCell><UserInitials name={entry.user.name} /></TableCell>
  </TableRow>
);

export const ChangeLogTable = ({ clientId, fleetId }: { clientId: number, fleetId: number }) => {
  const { data, isLoading, error } = useFleetHistory(clientId, fleetId);
  return (
    <Table columns={headers.length}>
      {renderTableHeaders(headers)}
      {data && data.fleetHistories.length > 0 ?
        data.fleetHistories.map(item => <TierHistoryRow key={item.date} entry={item} />) :
        <NoDataRow loadStatus={{ loading: isLoading, success: !error }} />}
    </Table>
  );
};
