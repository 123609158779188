import styled, { css } from 'styled-components';
import { WarningFilled } from '@instech/icons';
import { theme } from '@/utility/theme';
import React, { PropsWithChildren } from 'react';

export const FieldLayout = styled.div<{ direction?: string, stretch?: boolean }>`
  display: flex;
  flex-direction: ${props => props.direction || 'column'};
  ${props => props.direction === 'row' && css`
    align-items: center;
  `};
  box-sizing: border-box;
  ${props => props.stretch && css`
    width: 100%;
  `};
`;

export const ErrorMessage = styled.span<{ align?: string, hasDarkBackground?: boolean, notRounded?: boolean }>`
  display: flex;
  padding: 2px;
  ${props => !props.notRounded && css`
    border-radius: 0 0 4px 4px;
  `};
  line-height: 1.5em;
  gap: 0.5em;
  
  ${props => props.hasDarkBackground ? css`
    background-color: ${props.theme.status.darkRed};
    color: ${props.theme.white};
  ` : css`
    color: ${props.theme.status.darkRed};
  `};
  font-size: 14px;
  text-align: ${props => props.align || 'right'};
  white-space: pre-wrap;
`;

export const Warnings = styled.div<{ hasDarkBackground?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  color: ${props => props.hasDarkBackground ? props.theme.white : props.theme.sanMarino};
  font-size: 14px;
  gap: 0.5em;
`;

export const WarningMessage = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.5em;
`;

export const FieldLabel = styled.span`
  display: inline-block;
  height: 15px;
  margin-bottom: 4px;
  font-size: 13px;
  font-weight: bold;
  cursor: default;
`;

export const EmptyLabel = styled.span`
  height: 15px;
  margin-bottom: 12px;
`;

interface InputFieldProps {
  numberOfCharacters?: number;
  hasError?: boolean;
  dirty?: boolean;
  nonedit?: boolean;
  minimal?: boolean;
  noshade?: boolean;
  noBorder?: boolean;
  compact?: boolean;
  hasIcon?: boolean;
  hasRightIcon?: boolean;
}
export const InputField = styled.div<InputFieldProps>`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ${props => (props.hasIcon || props.hasRightIcon) && css`
    display: flex;
    flex-direction: row;
  `};
  
  width: 100%;

  & select,
  & input,
  & textarea {
    color: currentColor;
    font-size: 16px;
    font-weight: normal;
    width: ${props => props.numberOfCharacters ? `calc(${props.compact ? 20 : 50}px + ${props.numberOfCharacters}ch)` : '100%'};

    ::placeholder { 
      color: ${props => props.theme.sanMarino};
    }

    box-sizing: border-box;

    ${props => props.nonedit && css`
      &:focus {
        outline: none;
      }
    `};

    ${props => props.minimal ? css`
      border: none;
      padding: 5px;
      margin-left: -5px;
      background: transparent;

      &:focus {
        outline: none;
      }

      &:hover {
        color:  ${props.theme.sanMarino};
      }
    ` : css`
      height: 40px; 
      padding: 0px ${props.compact ? '7px' : '16px'};
      border: 1px solid ${props.theme.border.gray};

      ${props.noBorder && css`
        border: none;
      `};
      border-radius: 2px;
      ${!!props.noshade && css`
        box-shadow: 0 2px 5px 0 rgba(0,0,0,0.05);
      `};
    `};

    ${props => props.hasIcon && css`
      padding-left: 45px;
    `};

    appearance: none;

    /* Extend styles for native datetime picker */
    &[type="date"],
    &[type="time"] {
      &::-webkit-inner-spin-button {
        appearance: none;
      }
    }

    &[type="number"] {
      -moz-appearance: textfield;
      text-align: right;
    }

    ${props => props.hasError && css`
      border: 2px solid ${props.theme.status.darkRed};
    `};

    ${props => props.dirty && css`
      font-weight: bold;
    `};
  }

  /* Extend styles for textarea */
  & textarea {
    height: unset; // To allow the usage of text areas "rows", which we use to render text areas, to decide height
    padding: 8px 16px;
    resize: vertical;
  }
`;

export const InputFieldArea = styled.div<{ hasError: boolean }>`
  & textarea {
    width: 100%;
    height: 78px;
    min-height: 40px;
    padding: 8px 16px;
    border-radius: 3px;
    box-sizing: border-box;
    border: 1px solid;
    border-color: ${props => props.theme.border.gray};
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.05);
    appearance: none;
    resize: vertical;

    ${props => props.hasError && css`
      border-radius: 4px 4px 0 0;
      border: 2px solid ${props.theme.status.darkRed};
    `};
  }
`;

interface ComboFieldProps {
  label?: string;
  name: string;
}
export const ComboField = ({ label, name, children }: PropsWithChildren<ComboFieldProps>) => (
  <FieldLayout>
    {label && (
      <FieldLabel as="label" htmlFor={name}>
        {label}
      </FieldLabel>
    )}
    {children}
  </FieldLayout>
);

interface WarningFieldProps {
  warnings?: string[],
  hasDarkBackground?: boolean
}
export const WarningField = ({ warnings, hasDarkBackground }: WarningFieldProps) => {
  if (!warnings || warnings.length === 0) return null;
  return (
    <Warnings hasDarkBackground={hasDarkBackground}>
      {warnings.map(w => (
        <WarningMessage key={w}>
          <div><WarningFilled color={theme.yellow} /></div>
          <div>{w}</div>
        </WarningMessage>
      ))}
    </Warnings>
  );
};

interface ErrorFieldProps {
  error?: string,
  hasDarkBackground?: boolean
}
export const ErrorField = ({ error, hasDarkBackground = false }: ErrorFieldProps) => {
  if (!error) return null;
  return (
    <ErrorMessage align="left" hasDarkBackground={hasDarkBackground}>
      <div><WarningFilled /></div>
      <div>{error}</div>
    </ErrorMessage>
  );
};
