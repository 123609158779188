import { Box } from '@/components/shared/Box';
import { MarineObjectSearch } from '@/components/shared/MarineObjectSearch';
import { WarWeb } from '@/war';
import { useState } from 'react';
import { SingleValue } from 'react-select';
import styled, { css } from 'styled-components';
import { Chevron } from '../../../shared/Chevron';
import {
  NoDataRow,
  Table, TableCell as TCell, TableRow
} from '../../../shared/Table/Table';
import { useBowWizardStateContext } from '../BowWizardStateContext';
import { FleetAndVesselState, FleetState } from './models';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 1.3rem;
  max-width: 950px;
`;

const TableArea = styled.div`
  background-color: ${props => props.theme.white};
  width: 100%;
`;

const TableCell = styled(TCell) <{ full?: boolean }>`
  ${props => !props.full && css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
  font-size: 16px;
  margin-top: 1px;
`;

const OpenIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  color: ${props => props.theme.sanMarino};
`;

const AreaDivider = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.17em;
  font-weight: bolder;
  padding: 1rem;
`;

const Title = styled.h2`
  font-size: 18px;
  margin-bottom: 1rem;
  padding-left: 1ch;
`;

interface FleetRowProps {
  setFleet: (fleet: FleetState) => void;
  fleet: WarWeb.MarineMatch;
}

const FleetRow = ({ setFleet, fleet }: FleetRowProps) => {
  const [rowFocus, setRowFocus] = useState<boolean>(false);

  return (
    <TableRow hasFocus={rowFocus} onClick={() => setFleet({ id: fleet.fleetId, name: fleet.name, clientId: fleet.clientId })}>
      <TableCell
        tabIndex={0}
        onKeyDown={e => e.key === 'Enter' ? setFleet({ id: fleet.fleetId, name: fleet.name, clientId: fleet.clientId }) : undefined}
        onFocus={() => setRowFocus(true)}
        onBlur={() => setRowFocus(false)}>
        {fleet.name}
      </TableCell>
      <TableCell><OpenIcon><Chevron right thin /></OpenIcon></TableCell>
    </TableRow>
  );
};

interface FleetTableProps {
  setFleet: (fleet: FleetState) => void;
  fleets: WarWeb.MarineMatch[];
}

const FleetTable = ({ fleets, setFleet }: FleetTableProps) => (
  <>
    <Title>Select fleet</Title>
    <Table columns={2} striped stretch>
      {fleets && fleets.map((row: WarWeb.MarineMatch) => (
        <FleetRow
          key={row.id}
          setFleet={setFleet}
          fleet={row}
        />
      ))}
    </Table>
  </>
);

interface LoadStatus {
  loading: boolean,
  success: boolean
}

interface FleetsPickerProps {
  fleets?: WarWeb.MarineMatch[],
  setFleet: (fleet: FleetState) => void,
  setFleetAndVessel: (newState: FleetAndVesselState) => void,
  loadStatus: LoadStatus
}

export const FleetsPicker = ({ fleets, setFleet, setFleetAndVessel, loadStatus }: FleetsPickerProps) => {
  const { isNonRateLead } = useBowWizardStateContext();

  const selectMarineObject = (selection: SingleValue<WarWeb.MarineMatch>) => {
    if (!selection) return;
    const fleet: FleetState = { id: selection.fleetId, name: selection.fleetName, clientId: selection.clientId };
    if (selection.type === 'Vessel') {
      if (!selection.vesselId) return;
      setFleetAndVessel({ fleet, vessel: { id: selection.vesselId, name: selection.name } });
    } else setFleet(fleet);
  };

  const styles = {
    maxWidth: '850px',
  };

  return (
    <Content>
      <Box>
        <Content>
          <MarineObjectSearch
            customStyles={styles}
            onChange={selectMarineObject}
            isNonRateLead={isNonRateLead}
            withIcon />
        </Content>
      </Box>

      <AreaDivider>- OR -</AreaDivider>

      <TableArea>
        <Content>
          {!fleets || loadStatus.loading ?
            <NoDataRow loadStatus={loadStatus} />
            : <FleetTable fleets={fleets} setFleet={setFleet} />}
        </Content>
      </TableArea>
    </Content>
  );
};
