import { Counter } from '@/components/shared/BowSidebar/Components';
import { Box } from '@/components/shared/Box';
import { RemainingValidations } from '@/components/shared/RemainingValidations';
import { theme } from '@/utility/theme';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useAgreementDataContext } from '../agreementDetails/AgreementDataContext';
import { Coverage } from '../defaultTermsDetails/settings/coverage/Coverage';
import { ConditionsContainer } from './settings/Conditions';
import { PremiumAndRebates } from './settings/PremiumRebates';

const TabsContainer = styled.div`
    display: flex;
`;

const Tab = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  padding: 0.5rem 2rem 0.5rem 0.5rem;
  cursor: pointer;
  border-bottom: 2px solid ${props => props.theme.whiteBlue};
  color: ${props => props.theme.sanMarino};
  margin-top: 5px;

  ${props => props.selected && css`
    margin-top: 0;
    border-top: 5px solid ${props.theme.green};
    border-bottom: none;
    border-right: 2px solid ${props.theme.whiteBlue};
    &:not(:first-child) {
      border-left: 2px solid ${props.theme.whiteBlue};
    }
    color: ${props.theme.marineBlue};
  `}
`;

const ContentContainer = styled.div`
  padding: 2rem;
`;

const tabs = [
  'Coverage',
  'Premium & rebates',
  'Warranties & subjectivities'
];

const TabText = ({ text, count }: { text: string, count?: number }) => (
  <>
    {text}
    {count ? <Counter color={theme.white} backgroundColor={theme.sanMarino} number={count} /> : null}
  </>
);

export const Settings = () => {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const { conditions } = useAgreementDataContext();
  const conditionsBySystemCount = conditions ?
    conditions?.subjectivities.filter(x => x.bySystem).length + conditions?.warranties.filter(x => x.bySystem).length :
    undefined;

  return (
    <>
        <RemainingValidations fleetSpecificPage />
      <Box header="Settings">
        <TabsContainer>
          <Tab selected={activeTab === tabs[0]} onClick={() => setActiveTab(tabs[0])}>{tabs[0]}</Tab>
          <Tab selected={activeTab === tabs[1]} onClick={() => setActiveTab(tabs[1])}>{tabs[1]}</Tab>
          <Tab selected={activeTab === tabs[2]} onClick={() => setActiveTab(tabs[2])}>
            <TabText text={tabs[2]} count={conditionsBySystemCount} />
          </Tab>
        </TabsContainer>
        <ContentContainer>
          {activeTab === tabs[0] &&
            <Coverage />}
          {activeTab === tabs[1] &&
            <PremiumAndRebates />}
          {activeTab === tabs[2] &&
            <ConditionsContainer />}
        </ContentContainer>
      </Box>
    </>
  );
};
