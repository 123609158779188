import { BowDetailsPage } from '@/components/pages/bows/details/BowDetailsPage';
import { FleetDetailsPage } from '@/components/pages/fleetDetails/FleetDetailsPage';
import { useQueryString } from '@/hooks/useQuery';
import { useMe } from '@/services/meService';
import { PageLoader } from '@instech/components';
import { useAccount } from 'its-js-authentication';
import { useEffect, useState } from 'react';
import {
  Redirect, Route, Switch
} from 'react-router';
import { BaseLayout } from '../layout/BaseLayout';
import { AgreementsPage } from '../pages/agreements/AgreementsPage';
import { BowPage } from '../pages/bows/BowPage';
import { DocumentsPage } from '../pages/bows/documents/DocumentsPage';
import { CreateBowPage } from '../pages/create-bow/CreateBowPage';
import { DashboardBroker } from '../pages/dashboard/DashboardBroker';
import { ErrorPage } from '../pages/ErrorPage';
import { ExposurePage } from '../pages/exposure/ExposurePage';
import { FleetOverview } from '../pages/fleetOverview/FleetOverview';
import { Profile } from '../pages/profile/Profile';
import {
  isExpired, Roles, saveUserRole
} from './roles';
import { Routes } from './routes';
import { FeedbackAndSupportPage } from '../pages/external/FeedbackAndSupportPage';
import { UpdatesPage } from '../pages/external/UpdatesPage';

const SharedRoutes = () => {
  const search = useQueryString();

  return (
    <Switch>
      <Route exact path={Routes.bows}>
        <BowPage />
      </Route>
      <Route path={`${Routes.createbow}`}>
        <CreateBowPage />
      </Route>
      <Route exact path={`${Routes.bows}/:bowId`}>
        <BowDetailsPage />
      </Route>
      <Route exact path={`${Routes.bows}/:bowId/quotes/:quoteId`}>
        <BowDetailsPage />
      </Route>
      <Route exact path={`${Routes.bows}/:bowId/documents`}>
        <DocumentsPage />
      </Route>

      <Route exact path={Routes.fleets}>
        <FleetOverview />
      </Route>
      <Route exact path={`${Routes.fleets}/clients/:clientId/fleets/:fleetId`}>
        <FleetDetailsPage />
      </Route>

      <Route path={Routes.profile}>
        <Profile />
      </Route>
      <Route exact path={Routes.feedbackAndSupport}>
        <FeedbackAndSupportPage />
      </Route>
      <Route exact path={Routes.announcements}>
        <UpdatesPage />
      </Route>

      <Route path={Routes.terms}>
        <AgreementsPage />
      </Route>

      <Route exact path={Routes.base}>
        <Redirect to={{ pathname: Routes.dashboard, search }} />
      </Route>

      <Route>
        <ErrorPage />
      </Route>
    </Switch>
  );
};

const BrokerRoutes = () => (
  <Switch>
    <Route exact path={Routes.dashboard}>
      <DashboardBroker />
    </Route>

    <SharedRoutes />
  </Switch>
);

const ReinsurerRoutes = () => (
  <Switch>
    <Route exact path={Routes.base}>
      <Redirect to={{ pathname: Routes.bows }} />
    </Route>
    <Route exact path={Routes.bows}>
      <BowPage />
    </Route>

    <Route path={Routes.profile}>
      <Profile />
    </Route>
    <Route exact path={Routes.feedbackAndSupport}>
      <FeedbackAndSupportPage />
    </Route>
    <Route exact path={Routes.announcements}>
      <UpdatesPage />
    </Route>

    <Route>
      <ErrorPage />
    </Route>
  </Switch>
);

const UnderwriterRoutes = () => (
  <Switch>
    <Route exact path={Routes.dashboard}>
      <DashboardBroker />
    </Route>

    <Route exact path={`${Routes.exposure}`}>
      <ExposurePage />
    </Route>

    <SharedRoutes />

  </Switch>
);

export const AppRouting = () => {
  const { data: me, error, isValidating } = useMe();
  const { isAuthenticated } = useAccount();
  const [userRole, setUserRole] = useState<any>();

  const isReinsurerLocal = me?.role?.toString() === Roles.reinsurer;
  const isBrokerLocal = me?.role?.toString() === Roles.broker;

  const isBusinessOperationsLocal = me?.role?.toString() === Roles.businessOperations;
  const isUnderwriterLocal = me?.role?.toString() === Roles.underwriter;
  const isAdminLocal = me?.role?.toString() === Roles.admin;

  const hasAnyValidRole = Object.values(Roles).some((role: string) => role === me?.role?.toString());
  const hasAcceptedTerms = localStorage.getItem('acceptedTerms');
  const showTermsOfUse = isBrokerLocal && !hasAcceptedTerms;

  useEffect(() => {
    if (me?.role) void saveUserRole(me.role.toString());
  }, [me]);

  useEffect(() => {
    if (me?.role) {
      const myInterval = setInterval(() => {
        const userRoleLocal = localStorage.getItem('role');
        if (userRoleLocal) {
          setUserRole(userRoleLocal);
          clearInterval(myInterval);
        }
      }, 500);
    }
  }, [me?.role]);

  if (userRole) {
    if (error) {
      return <Redirect to={Routes.noAccess} />;
    }

    if (isAuthenticated && isExpired()) {
      return <Redirect to={Routes.noAccess} />;
    }

    if (showTermsOfUse) {
      return <Redirect to={Routes.termsOfUse} />;
    }

    return (
      <BaseLayout>
        {isReinsurerLocal && <ReinsurerRoutes />}
        {(isAdminLocal || isUnderwriterLocal || isBusinessOperationsLocal) && <UnderwriterRoutes />}
        {isBrokerLocal && <BrokerRoutes />}
      </BaseLayout>
    );
  }

  if (!isValidating && isAuthenticated && !hasAnyValidRole) {
    return <Redirect to={Routes.noAccess} />;
  }

  return <PageLoader />;
};
