import React from 'react';
import PropTypes from 'prop-types';
import {
  Table, FilterableTableHeaders, TableRow, TableCell
} from '@/components/shared/Table/Table';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Chevron } from '@/components/shared/Chevron';
import { Routes } from '@/components/routing/routes';
import {
  bowPropType, itemsWithMetadataPropType, tableHeadersPropType
} from '@/prop-types/custom-prop-types';
import { bowStatusToObject } from '@/utility/bowStatus';
import { NameGroup } from '@/components/shared/Table/core/Components';

//  TODO: Content will change when models are updated. Much is copy/paste from BowTable, extract components as needed.

const StyledBowLink = styled(Link)`
    display: flex;
    justify-content: center;
    padding: 10px;
`;

const ExposureRow = ({ bow }) => (
  <TableRow>
    <TableCell>{bow.internalReference}</TableCell>
    <TableCell>{bow.clientReference}</TableCell>
    <TableCell>{bowStatusToObject(bow.status).icon}</TableCell>
    <TableCell>{bow.fleetName}</TableCell>
    <TableCell>{bow.vesselName}</TableCell>
    <TableCell>{bow.vesselType}</TableCell>
    <TableCell>
      <NameGroup values={bow.coveredAreas} />
    </TableCell>
    <TableCell>
      <NameGroup values={bow.ports} />
    </TableCell>
    <TableCell>{new Date(Date.parse(bow.entry)).toLocaleDateString()}</TableCell>
    <TableCell>{new Date(Date.parse(bow.exit)).toLocaleDateString()}</TableCell>
    <TableCell slim hover>
      <StyledBowLink to={`${Routes.bows}/${bow.id}`}>
        <Chevron right thin />
      </StyledBowLink>
    </TableCell>
  </TableRow>
);
ExposureRow.propTypes = {
  bow: bowPropType
};

export const ExposureTable = ({ headers, bows }) => (
  <Table columns={headers.length} striped>
    {bows && <FilterableTableHeaders headers={headers} columnMetadata={bows.columnMetadata} />}
    {bows && bows.items.length > 0 && bows.items.map((bow, i) =>
      <ExposureRow key={bow.id} bow={bow} />)}
  </Table>
);
ExposureTable.propTypes = {
  headers: PropTypes.arrayOf(tableHeadersPropType),
  bows: PropTypes.objectOf(itemsWithMetadataPropType)
};
