import { TargetType } from '@/models/TargetType';
import { useAgreements } from '@/services/agreementsService';
import { getDifference } from '@/utility/array';
import { Loader, WarningNotification } from '@instech/components';
import { useAgreementsPageContext } from '../agreements/AgreementsPageContext';
import { useAgreementDetailsContext } from './AgreementDetailsContext';
import { useAgreementSelectionContext } from './AgreementSelectionContext';

export const WarnOverwriteExistingAgreements = () => {
  const { agreementType } = useAgreementsPageContext();
  const { targetsSummary } = useAgreementDetailsContext();
  const { areaIds, vesselIds, targetType } = useAgreementSelectionContext();

  // Does not take into account additions to area ids, would likely require multiple fetches and/or cross checking to determine changes to both
  const addedVesselIds = getDifference(vesselIds, targetsSummary.vessels.map(x => x.vesselId)); // targetsSummary.vessels should always be empty on create
  const targets = { areaIds, vesselIds: addedVesselIds, targetType: TargetType.VesselSpecific };

  const hasValidParams = targetType !== TargetType.Trade && areaIds.length > 0 && targets.vesselIds.length > 0;

  const { data: existingAgreements, isValidating: isLoadingExistingAgreements } =
    useAgreements(hasValidParams ? targets : undefined, agreementType, 'summaryModal');

  if (isLoadingExistingAgreements) return <Loader size="small" />;
  if (existingAgreements?.header?.lastUpdated == null) return null;

  return (
    <WarningNotification
      size="medium"
      headingText="There are existing agreements on this selection"
      descriptionText="If you continue you will overwrite the existing agreements"
    />
  );
};
