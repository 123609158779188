import { useSelectedQuote } from '@/components/shared/Bow/useSelectedQuote';
import { useQuoteContext } from '@/components/shared/BowSidebar/QuoteContext';
import { Box } from '@/components/shared/Box';
import { CustomAsyncSelect } from '@/components/shared/Form/fields/CustomAsyncSelect';
import { checkLink, HateoasHandler } from '@/components/shared/HateoasHandler';
import { Loader } from '@instech/components';
import { RemainingValidations } from '@/components/shared/RemainingValidations';
import { SpanCell } from '@/components/shared/Table/core/Components';
import { Validator } from '@/components/shared/Validator';
import { Filters } from '@/models/Filters';
import { useBow } from '@/services/bowServices';
import { getDestinations } from '@/services/destinationService';
import { useHateoasExecutorContext } from '@/services/HateoasExecutorContext';
import { useValidationContext } from '@/services/ValidationContext';
import { areaTypeToObject } from '@/utility/areaType';
import { theme } from '@/utility/theme';
import { WarWeb } from '@/war';
import { useEffect, useState } from 'react';
import { SingleValue } from 'react-select';
import styled, { css } from 'styled-components';
import { MinSearchLength } from '@/utility/globals';
import { openErrorModal } from '@/components/error/ErrorModal';
import { useModalContext } from '@/components/modal/ModalContext';
import { parseErrors } from '@/utility/errorHelpers';
import { QuoteActions } from '../bows/details/quoteActionsMap';
import { steps } from './bowSteps';
import { Spacer, TrailMask } from './components/Components';
import { CoveredArea } from './components/CoveredArea';
import { useBowWizardStateContext } from './BowWizardStateContext';

const Container = styled.div<{ hasTrailLine?: boolean }>`
  display: grid;
  background-color: ${theme.background.primary};

  grid-template-columns: ${props => props.hasTrailLine ? '0.5fr 2px' : ''} repeat(2, minmax(120px, 1.7fr)) repeat(2, minmax(260px, 2fr)) 0.2fr;
  @media (max-width: 1400px) {
    grid-template-columns: ${props => props.hasTrailLine ? '20px 2px' : ''} repeat(2, minmax(120px, 1.7fr)) repeat(2, minmax(260px, 2fr)) 0.2fr;
  }
  justify-content: start;
`;

const ErrorContainer = styled.div<{ hasTrailLine?: boolean }>`
  display: flex;
  flex-direction: column;
  grid-column: ${props => props.hasTrailLine ? 2 : 1}/-1;
  width: 100%;
`;

const SearchBox = styled(Box) <{ spacing: boolean }>`
  width: 100%;
  display: flex;
  ${props => props.spacing && css`
    margin-top: 1em;
  `}
`;

const SearchResult = styled.div`
  display: flex;
  align-items: flex-start;

  svg {
    width: 30px;
    margin-right: 1em;
  }
`;

const SearchResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${props => props.theme.white};
  padding: 1em;
`;

const StyledSpanCell = styled(SpanCell)`
  display: flex;
  flex-direction: row;
  overflow: visible;
`;

const CountryNameContainer = styled.div`
  font-size: 11px;
  letter-spacing: 0.05px;
  text-transform: uppercase;
  white-space: normal;
`;

export const ItineraryStep = () => {
  const { ids, isMultiDestination } = useQuoteContext();
  const { bowId, quoteId } = ids;
  const [filters, setFilters] = useState<Filters>();

  const { setStepDone } = useBowWizardStateContext();

  const { hateoasExecutor, lastRequest, loading } = useHateoasExecutorContext();
  const { errors, setErrors } = useValidationContext();
  const { open } = useModalContext();

  const { data: bowData } = useBow(bowId);
  const selectedQuote = useSelectedQuote();

  useEffect(() => {
    if (!bowData) return;
    const quote = bowData.quotes.find((q: WarWeb.BowQuote) => q.id === quoteId);
    if (!quote) return;

    // need vesselId to search for destinations
    setFilters({ ...filters, vesselId: selectedQuote?.vessel.id });
  }, [bowData]);

  // TODO: This (Next button) should be "activated" from backend, through a HATEOAS link
  useEffect(() => {
    setStepDone(
      // selectedQuote?.coveredAreas?.length > 0
      // && itineraryState.coveredAreas[0].itinerary && itineraryState.coveredAreas[0].itinerary.length > 0 &&
      !errors
    );
  }, [errors]);

  const addDestination = (area: SingleValue<WarWeb.DestinationMatch>, _: unknown) => {
    setFilters({ ...filters, freetext: '' });

    const newAreaRequest = {
      agreementId: area?.agreementId,
      areaId: area?.portId ?? area?.coveredAreaId ?? area?.areaId,
      areaAliasId: area?.areaAliasId
    };

    void hateoasExecutor(selectedQuote?.links, QuoteActions.AddArea, newAreaRequest);
  };

  const loadOptions: any = async (searchString: string) => {
    if (searchString.length < MinSearchLength) {
      return [];
    }
    const params = { ...filters, freetext: searchString };
    const { data: results, error } = await getDestinations(params);
    if (error) {
      parseErrors(true, (message, title) => open(openErrorModal(message, title)), error, setErrors);
      return [];
    }
    return results?.data;
  };

  const renderResult = (result: WarWeb.DestinationMatch) => {
    let desc = result.type === 'Ocean' ? result.name : result.country;
    if (result.type === 'Trade' || result.type === 'AreaAlias') desc = result.description;

    return (
      <SearchResult>
        {areaTypeToObject(result.type)?.icon}
        <SearchResultContainer>
          {result.name}
          <CountryNameContainer>
            {desc}
          </CountryNameContainer>
        </SearchResultContainer>
      </SearchResult>
    );
  };

  const styles = {
    container: (provided: any) => ({
      ...provided,
      width: '350px',
      marginRight: '1em'
    })
  };

  return (
    <Container hasTrailLine={isMultiDestination}>

      <ErrorContainer hasTrailLine={isMultiDestination}>
        <Validator keys={steps.filter(s => s.step !== 2)?.flatMap(u => u.warningsKeys.map(v => `/${v}/`))} hideWarnings />
        <RemainingValidations rootElement={selectedQuote} />
      </ErrorContainer>

      {selectedQuote?.coveredAreas?.map((each: WarWeb.CoveredArea, i: number) => (
        <CoveredArea
          key={`${each.id}_${each.entry}`}
          index={i}
          coveredArea={each}
          first={i === 0}
          last={i === (selectedQuote?.coveredAreas?.length ?? 0) - 1}
        />
      ))}

      <HateoasHandler
        links={selectedQuote?.links}
        action={QuoteActions.AddArea}
        editVariant={(
          <>
            {isMultiDestination && <TrailMask />}

            {loading && !!checkLink(selectedQuote?.links, lastRequest) ? <Loader />
              : (
                <StyledSpanCell start={isMultiDestination ? 2 : 1} span={isMultiDestination ? 6 : 5}>
                  <SearchBox spacing={!!selectedQuote?.coveredAreas && selectedQuote.coveredAreas.length > 0} highlight={theme.green}>
                    <Content>
                      <Validator keys={['AreaId', 'AgreementId', 'VesselId', 'coveredAreas']}>
                        <CustomAsyncSelect
                          name="addNewArea"
                          components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                          placeholder="Area, port or destination..."
                          value={null}
                          loadOptions={loadOptions}
                          openMenuOnClick={false}
                          onChange={addDestination}
                          styles={styles}
                          formatOptionLabel={renderResult}
                          noOptionsMessage={() => 'No results found'}
                          tabIndex={0}
                          autoFocus
                        />

                      </Validator>
                    </Content>
                  </SearchBox>

                </StyledSpanCell>
              )}
            <div />
            <div />
          </>
        )} />
      <Spacer />
    </Container>
  );
};
