import { NoDataRow } from '@/components/shared/Table/core/Components';
import { useValidationContext } from '@/services/ValidationContext';
import { getLastElement } from '@/utility/array';
import { renderDestionationsResult } from '@/utility/destinationsDropdownHelpers';
import { Globals } from '@/utility/globals';
import { useEffect } from 'react';
import styled from 'styled-components';
import { DestinationOption } from '../../../pages/bows/details/destinationOption';
import { useQuoteContext } from '../../BowSidebar/QuoteContext';
import { Box } from '../../Box';
import { SelectDropdown } from '../../Form/fields/SelectDropdown';
import { useSelectedQuote } from '../useSelectedQuote';
import { CoverageDestination } from './CoverageDestination';

const Content = styled.div`
  border-radius: 2px;
  padding: 1rem 1.5rem 1.5rem 1.5rem;
`;

export const CoverageBox = () => {
  const { selectedConfiguration, setSelectedConfiguration, isMultiDestination } = useQuoteContext();
  const { errors, warnings } = useValidationContext();

  const selectedQuote = useSelectedQuote();
  const coveredAreas = selectedQuote?.coveredAreas;

  let index = 0;
  const destinationsOptions: DestinationOption[] = coveredAreas?.flatMap(item => {
    const country = [{
      id: item.id,
      type: item.coveredAreaType.toString(),
      // eslint-disable-next-line no-plusplus
      ...(item.highRiskAreaId && { index: index++ }),
      name: item.name,
      entry: item.entry,
      exit: item.exit,
      isValid: item.coveredAreaType === 'OceanArea'
    }];

    const ports = item.itinerary.flatMap(dest => ({
      id: dest.id,
      type: dest.port.isTimeException ? 'TimeException' : 'Port',
      // eslint-disable-next-line no-plusplus
      ...(dest.port.highRiskAreaId && { index: index++ }),
      name: dest.port.name,
      entry: dest.entry,
      exit: dest.exit,
      isValid: !dest.port.isTimeException
    }));
    return country.concat(ports);
  }) || [];

  useEffect(() => {
    if (!selectedConfiguration && destinationsOptions) {
      setSelectedConfiguration!(destinationsOptions[0]?.id);
    }
  }, []);

  if (!coveredAreas) return null;

  const totalVoyageNode: DestinationOption = {
    id: Globals.TotalVoyageId,
    type: 'Voyage',
    name: 'Total Voyage',
    isValid: true,
    entry: coveredAreas[0]?.entry,
    exit: coveredAreas[coveredAreas.length - 1]?.exit
  };

  destinationsOptions.unshift(totalVoyageNode);

  const selectedDestination = !isMultiDestination
    ? getLastElement(destinationsOptions)
    : destinationsOptions.find(c => c.id === selectedConfiguration);

  const setConfiguration = (item: any) => {
    setSelectedConfiguration!(item.id);
  };

  return (
    <Box header="Coverage">
      {isMultiDestination && (
        <SelectDropdown
          results={destinationsOptions}
          callback={setConfiguration}
          selectedItem={selectedDestination}
          renderResult={renderDestionationsResult}
          keyField="id"
        />
      )}

      <Content>
        {selectedDestination && selectedDestination.isValid ? (
          <CoverageDestination
            destination={selectedDestination}
          />
        )
          : (
            <NoDataRow
              size="small"
              loadStatus={{ loading: false, success: true }}
              header={errors || warnings ? 'Coverage pending' : 'Nothing to see here'}
              message={errors || warnings ? 'Please correct remaining warnings' : 'Not relevant for chosen destination'} />
          )}
      </Content>
    </Box>
  );
};
