import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledButton = styled.button`
  box-sizing: border-box;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: ${props => props.width ? props.width : '40px'};
  height: ${props => props.height ? props.height : '40px'};
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    flex-shrink: 0;
  }
`;

export const IconButton = ({ icon, width, height, onClick }) => (
  <StyledButton width={width} height={height} onClick={onClick} type="button">
    {icon}
  </StyledButton>
);

IconButton.propTypes = {
  icon: PropTypes.node,
  width: PropTypes.string,
  height: PropTypes.string,
  onClick: PropTypes.func
};
