import { ModalLayout } from '@/components/modal/ModalLayout';
import { Button, ButtonWithLoader } from '@/components/shared/Button';
import { theme } from '@/utility/theme';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { WarningFilled } from '@instech/icons';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 2rem;
  text-align: center;
`;

const StyledWarning = styled(WarningFilled)`
  path {
    fill: ${props => props.theme.marineBlue};
  }
  height: 60px;
  width: 60px;
  margin: 3rem;
`;

const Header = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 2rem;
`;

const Description = styled.div`
  margin-bottom: 2rem;
`;

interface ChangeTabConfirmationModalProps {
  closeModal: () => void;
  apply: () => void;
}
export const ChangeTabConfirmationModal = ({ closeModal, apply }: ChangeTabConfirmationModalProps) => {
  const submitHandler = () => {
    apply();
    closeModal();
  };

  const options = {
    size: 'small',
    clickOutsideToClose: true
  };

  return (
    <ModalLayout closeModal={closeModal} options={options}>
      <Container>
        <StyledWarning />
        <Header>
          Are you sure you want to change tabs?
        </Header>
        <Description>
          You have unsaved chages in the current tab.
        </Description>
        <ButtonGroup justify="space-between">
          <Button background={theme.ultraLightGray} onClick={closeModal}>Cancel</Button>
          <ButtonWithLoader background={theme.green} onClick={submitHandler}>Change tabs</ButtonWithLoader>
        </ButtonGroup>
      </Container>
    </ModalLayout>
  );
};
