import { CoverageSummary } from '@/models/Agreement';
import { ChildrenProps } from '@/models/utils/ChildrenProps';
import React, {
  createContext,
  useContext, useMemo, useState
} from 'react';
import { Conditions, Premium } from './models';

interface IAgreementDataContext {
  coverage: CoverageSummary;
  setCoverage: React.Dispatch<React.SetStateAction<CoverageSummary>>;

  premium: Premium;
  setPremium: React.Dispatch<React.SetStateAction<Premium>>;

  conditions: Conditions;
  setConditions: React.Dispatch<React.SetStateAction<Conditions>>;
}

const AgreementDataContext = createContext<IAgreementDataContext | undefined>(undefined);

const AgreementDataProvider = ({ children }: ChildrenProps) => {
  const [coverage, setCoverage] = useState<CoverageSummary>({
    quoteValidHours: { existingValueCount: 0, targetedValueCount: 0 },
    daysCovered: { existingValueCount: 0, targetedValueCount: 0 },
    maximumDaysCovered: { existingValueCount: 0, targetedValueCount: 0 },
    contractType: { existingValueCount: 0, targetedValueCount: 0 },
    maximumProRataDays: { existingValueCount: 0, targetedValueCount: 0 }
  });
  const [premium, setPremium] = useState<Premium>({ additionalRates: [], defaultRates: [], knrRebates: [], rebates: [] });
  const [conditions, setConditions] = useState<Conditions>({ subjectivities: [], warranties: [] });

  const value = useMemo(
    () => ({ coverage, setCoverage, premium, setPremium, conditions, setConditions }),
    [conditions, coverage, premium]
  );

  return (
    <AgreementDataContext.Provider value={value}>
      {children}
    </AgreementDataContext.Provider>
  );
};

const useAgreementDataContext = () => {
  const context = useContext(AgreementDataContext);

  if (context === undefined) {
    throw new Error('useAgreementDataContext must be used within a AgreementDataProvider');
  }
  return context;
};

export { AgreementDataProvider, useAgreementDataContext };
