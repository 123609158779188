import { usePageSettingsContext } from '@/components/userSettings/PageSettingsContext';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

export const ScrollToTop = () => {
    const { pathname } = useLocation();
    const { pageSettings, pageKey } = usePageSettingsContext();

    useEffect(() => {
        const scrollPos = (pageSettings && pageKey && pageSettings[pageKey] && pageSettings[pageKey].scrollPosition) ?? 0;
        window.scrollTo(0, +scrollPos);
    }, [pathname, pageKey]);

    return null;
};
