import styled from 'styled-components';
import { TableCell, TableRow } from '@/components/shared/Table/Table';
import {
  Edit, RefreshDotted, Trash
} from '@instech/icons';
import { theme } from '@/utility/theme';
import { WarWeb } from '@/war';
import { ButtonMenu } from '@/components/shared/ButtonMenu';
import { MenuItem } from '@/models/utils/MenuItem';
import { NameDescriptionElement } from './NameDescriptionElement';

const IconContainer = styled.span`
  cursor: pointer;
  color: ${props => props.theme.marineBlue};
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const getBackgroundColor = (action: WarWeb.ElementAction) => {
  switch (action) {
    case 'Add':
      return theme.lightGreen50;
    case 'Delete':
      return theme.lightRed50;
    default:
      return '';
  }
};

interface EditCellProps {
  removeItem: () => void;
  updateItem?: () => void;
}
const EditCell = ({ removeItem, updateItem }: EditCellProps) => {
  const menuItems: MenuItem[] = [
    {
      id: 1,
      name: 'Edit',
      icon: <Edit />,
      onClick: updateItem
    },
    {
      id: 2,
      name: 'Delete',
      icon: <Trash />,
      onClick: removeItem
    },
  ];
  if (updateItem) {
    return (
      <TableCell right>
        <IconContainer color={theme.status.green}>
          <ButtonMenu items={menuItems} />
        </IconContainer>
      </TableCell>
    );
  }
  return (
    <TableCell right onClick={removeItem}>
      <IconContainer color={theme.status.red}>
        <Trash />
        DELETE
      </IconContainer>
    </TableCell>
  );
};

const UndoDeleteCell = ({ onClick }: { onClick: () => void;}) => (
  <TableCell right onClick={onClick}>
    <IconContainer color={theme.status.red}>
      <RefreshDotted />
      UNDO DELETE
    </IconContainer>
  </TableCell>
);

interface DeletableItem {
  id: number;
  name: string;
  description: string;
  action: WarWeb.ElementAction
}

interface DeletableTableRowProps {
  item: DeletableItem;
  removeItem: (id: number) => void;
  undoRemoveItem: (id: number) => void;
  updateItem?: (id: number) => void;
}
export const DeletableTableRow = ({ item, removeItem, undoRemoveItem, updateItem }: DeletableTableRowProps) => (
  <TableRow backgroundColor={getBackgroundColor(item.action)}>
    <TableCell>
      <NameDescriptionElement name={item.name} description={item.description} />
    </TableCell>
    {item.action === 'Delete' ?
      <UndoDeleteCell onClick={() => undoRemoveItem(item.id)} /> :
      <EditCell removeItem={() => removeItem(item.id)} updateItem={updateItem ? () => updateItem(item.id) : undefined} />}
  </TableRow>
);
