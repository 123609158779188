import { InlineModalProps } from '@/components/modal/InlineModalProps';
import { ModalLayout } from '@/components/modal/ModalLayout';
import { useQuoteContext } from '@/components/shared/BowSidebar/QuoteContext';
import { Button, ButtonWithLoader } from '@/components/shared/Button';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { FieldLabel } from '@/components/shared/Form/core/Components';
import { TextField } from '@/components/shared/Form/FormFields';
import { Validator } from '@/components/shared/Validator';
import { useBow } from '@/services/bowServices';
import { useHateoasExecutorContext } from '@/services/HateoasExecutorContext';
import { useValidationContext } from '@/services/ValidationContext';
import { isEmpty, pick } from '@/utility/objectHelpers';
import { theme } from '@/utility/theme';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { FinishQuoteState, useQuoteCommentsContext } from '../../create-bow/QuoteCommentsContext';
import { BowActions } from '../details/bowActionsMap';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 1rem 1rem;
`;

const Wide = styled.div`
  width: 100%;
`;

const Column = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${props => props.gap && css`
    row-gap: 1rem;
  `};

  ${FieldLabel} {
    font-size: 16px;
    margin-bottom: 0.5rem;
  }
`;

export const ClientRefModal = ({ closeModal }: InlineModalProps) => {
  const { ids } = useQuoteContext();
  const { bowId } = ids;
  const { hateoasExecutor } = useHateoasExecutorContext();
  const { errors, clearErrors } = useValidationContext();

  const { quoteComments, setQuoteComments } = useQuoteCommentsContext();
  const [initialComments, setInitialComments] = useState<FinishQuoteState | undefined>();
  const [hasSaved, setHasSaved] = useState(false);

  const { data: bowData } = useBow(bowId);

  useEffect(() => {
    setInitialComments(quoteComments);
  }, []);

  useEffect(() => {
    if (!hasSaved) return;
    if (!errors || isEmpty(pick(errors, ['ClientReference']))) closeModal();
    else setHasSaved(false);
  }, [errors, hasSaved, closeModal]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setQuoteComments({ ...quoteComments, clientReference: e.target.value });
  };

  const save = async () => {
    const request = {
      clientReference: quoteComments?.clientReference === '' ? undefined : quoteComments?.clientReference
    };
    await hateoasExecutor(bowData?.links, BowActions.SetClientReference, request, 'clientRef');
    setHasSaved(true);
  };

  const cancel = () => {
    if (initialComments) setQuoteComments(initialComments);
    clearErrors();
    closeModal();
  };

  const options = {
    title: 'Edit Client Reference',
    size: 'small',
    draggable: true
  };

  return (
    <ModalLayout options={options} closeModal={closeModal}>
      <Container>
        <Column gap="2rem">
          <Wide>
            <Validator keys={['ClientReference']}>
              <TextField
                name="clientReference"
                value={quoteComments.clientReference}
                onChange={handleInputChange}
                maxLength={50}
                label="Client Reference"
                placeholder="Client Ref."
              />

            </Validator>
          </Wide>
          <ButtonGroup justify="flex-end">
            <Button onClick={cancel} background={theme.lightGray}>Cancel</Button>
            <ButtonWithLoader onClick={save} background={theme.green}>Save</ButtonWithLoader>
          </ButtonGroup>
        </Column>

      </Container>
    </ModalLayout>
  );
};
