/* eslint-disable react/state-in-constructor */
/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DefaultErrorMessage } from './DefaultErrorMessage';
import { childrenPropType } from '../../prop-types/custom-prop-types';

const initialState = { hasError: false, message: null };
export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.reset = this.reset.bind(this);
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      message: error.message
    };
  }

  componentDidCatch(error, info) {
    console.error('ErrorBoundary caught an error', error, info);
  }

  reset() {
    this.setState(initialState);
  }

  render() {
    const { hasError, message } = this.state;
    if (hasError) {
      const { component: ErrorView = DefaultErrorMessage } = this.props;
      return <ErrorView message={message} reset={this.reset} />;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: childrenPropType.isRequired,
  component: PropTypes.func
};
