import { ModalLayout } from '@/components/modal/ModalLayout';
import { Button } from '@/components/shared/Button';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { FlexBox } from '@/components/shared/FlexBox';
import { FieldLayout } from '@/components/shared/Form/core/Components';

import { theme } from '@/utility/theme';
import { TextField } from '@instech/components';
import {
  Formik, FormikProps, FormikValues
} from 'formik';
import { useState } from 'react';

interface FormValues {
  daysCovered: string;
}

export enum DaysCoveredModalMode {
  Duplicate,
  Delete
}

export interface DaysCoveredModalData {
  mode: DaysCoveredModalMode;
  daysCovered?: number;
}

interface DuplicateDaysCoveredModalProps {
  save: (days: number) => void;
  close: () => void;
  data: DaysCoveredModalData;
}

export const DaysCoveredModal = ({ save, close, data }: DuplicateDaysCoveredModalProps) => {
  const [isSaving, setIsSaving] = useState(false);

  const submitModal = async (values: FormValues) => {
    setIsSaving(true);
    await save(parseInt(values.daysCovered));
    setIsSaving(false);
    close();
  };

  const title = data.mode === DaysCoveredModalMode.Duplicate ? 'New days covered set' : 'Are you sure you want to delete this?';
  const buttonActionTitle = data.mode === DaysCoveredModalMode.Duplicate ? 'Duplicate' : 'Delete';

  return (
    <ModalLayout closeModal={close} options={{ title, size: 'small' }}>
      <Formik
        initialValues={{ daysCovered: data.daysCovered?.toString() ?? '' }}
        onSubmit={submitModal}
      >
        {({ handleSubmit, dirty }: FormikProps<FormikValues>) => (
          <FlexBox flexDirection="column" gap="2rem">
            {data.mode === DaysCoveredModalMode.Duplicate ? (
              <FieldLayout>
                <TextField name="daysCovered" placeholder="" label="Days Covered" />
              </FieldLayout>
            ) : (
              <p>
                {`Deleting Days Covered option for ${data.daysCovered} days`}
              </p>
            )}

            <ButtonGroup justify="flex-end">
              <Button onClick={close} background={theme.ultraLightGray}>
                Cancel
              </Button>
              <Button background={theme.green}
                onClick={handleSubmit}
                disabled={data.mode !== DaysCoveredModalMode.Delete && (!dirty || isSaving)}
                loading={isSaving}>
                {buttonActionTitle}
              </Button>
            </ButtonGroup>
          </FlexBox>
        )}
      </Formik>
    </ModalLayout>
  );
};
