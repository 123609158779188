// Entry point for all things agreements, setting up routes
// Could potentially be used for something more, like getting query params

import { buildAgreementsRoutes } from './AgreementsRoutes';

export const AgreementsPage = () => (
  <>
    {buildAgreementsRoutes()}
  </>
);
