import { isReinsurer, isInternalUser } from '@/components/routing/roles';
import { Routes } from '@/components/routing/routes';
import { ButtonMenu } from '@/components/shared/ButtonMenu';
import {
  BorderedTextBox, TableCell, TableRow
} from '@/components/shared/Table/Table';
import { MenuItem } from '@/models/utils/MenuItem';
import { WarWeb } from '@/war';
import {
  Boat, CurrencyDollar, PrivacyPolicy
} from '@instech/icons';
import QueryString from 'qs';
import React from 'react';
import { useHistory } from 'react-router';

interface FleetItemsProps {
  fleet: WarWeb.MarineMatch;
  focusedId?: number;
  setFocusedId: React.Dispatch<React.SetStateAction<number | undefined>>;
}
export const FleetTableRow = ({ fleet, focusedId, setFocusedId }: FleetItemsProps) => {
  const history = useHistory();

  const menuItems: MenuItem[] = [
    {
      id: 1,
      name: 'Vessels',
      icon: <Boat />,
      route: `${Routes.fleets}/clients/${fleet.clientId}/fleets/${fleet.fleetId}`,
      default: true
    },
    {
      id: 3,
      name: 'Breach of Warranty',
      icon: <CurrencyDollar />,
      route: `${Routes.bows}?${QueryString.stringify({ SelectedFleets: fleet.name })}`
    }
  ];

  if (!isReinsurer()) {
    menuItems.push({
      id: 2,
      name: 'Terms & Conditions',
      icon: <PrivacyPolicy />,
      route: `${Routes.termsSpecific}?${QueryString.stringify({ SelectedFleets: fleet.name })}`,
    });
    menuItems.sort((x, y) => x.id - y.id);
  }

  const handleClick = () => {
    history.push(menuItems.find(x => x.default)?.route);
  };

  return (
    <TableRow hasFocus={focusedId === fleet.fleetId} hover onHover={() => setFocusedId(undefined)}>
      <TableCell
        tabIndex={0}
        onFocus={() => setFocusedId(fleet.fleetId)}
        onKeyDown={e => e.key === 'Enter' ? handleClick() : undefined}
        onClick={handleClick}>
        {fleet.name}
      </TableCell>
      {isInternalUser() && (
        <>
          <TableCell>{fleet.clientName}</TableCell>
          {isInternalUser({ onlyEditors: true }) && <TableCell>{fleet.tier}</TableCell>}
          <TableCell right>{fleet.vesselCount}</TableCell>
          {isInternalUser({ onlyEditors: true }) && (
            <TableCell center>
              {fleet.isNew && (
                <BorderedTextBox>{`${fleet.newVesselCount} New`}</BorderedTextBox>
              )}
            </TableCell>
          )}
        </>
      )}
      <TableCell center>
        <ButtonMenu items={menuItems} open={focusedId === fleet.fleetId} />
      </TableCell>
    </TableRow>
  );
};
