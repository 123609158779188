import { Box } from '@/components/shared/Box';
import { Chevron } from '@/components/shared/Chevron';
import { Checkbox } from '@/components/shared/Form/fields/Checkbox';
import {
  NoDataRow, renderTableHeaders, Table, TableCell, TableRow
} from '@/components/shared/Table/Table';
import { useExcludedAreas } from '@/services/excludedAreasService';
import { getDifference } from '@/utility/array';
import { theme } from '@/utility/theme';
import { WarWeb } from '@/war';
import { Image2 } from '@instech/icons';
import { useBulkChangeAreasContext } from '../BulkChangeAreasContext';

const tableHeaders = ['', 'Name', 'Code', ' ']; // Whitespace in second empty header to avoid same keys

const AreaRow = ({ area }: { area: WarWeb.Area }) => {
  const { state, dispatch } = useBulkChangeAreasContext();
  const { data } = useExcludedAreas(area.id);

  const setArea = () => {
    dispatch({ type: 'SetSelectedArea', payload: area.id });
  };

  // TODO: Move data fetching into 'onCheckboxChangeHandler', so we don't fetch for every area on load
  const onCheckboxChangeHandler = () => {
    if (!data) return;
    const wasChecked = state.checkedPorts.some(x => x === area.id);

    if (wasChecked) {
      const allPortIdsFromArea = data.filter(x => x.parentId === area.id || x.oceanAreaId === area.id).map(x => x.id);
      const result = getDifference(state.checkedPorts, allPortIdsFromArea);
      dispatch({ type: 'SetCheckedPorts', payload: result });
    } else {
      const portids = data.map(x => x.id);
      const uniqueAllPortIds = [...new Set(state.checkedPorts.concat(portids))];

      dispatch({ type: 'SetCheckedPorts', payload: uniqueAllPortIds });
    }
    dispatch({ type: 'TogglePort', payload: area.id });
  };

  const isSelected = state.selectedArea === area.id;
  const isChecked = state.checkedPorts.includes(area.id);

  return (
    <TableRow backgroundColor={isSelected ? theme.lightGreen50 : undefined} onClick={setArea}>
      <TableCell slim>
        {area.areaType === 'Ocean' && <Checkbox name={`area-${area.id}`} checked={isChecked} onChange={onCheckboxChangeHandler} />}
      </TableCell>
      <TableCell>{area.name}</TableCell>
      <TableCell>{area.locode}</TableCell>
      <TableCell right><Chevron right /></TableCell>
    </TableRow>
  );
};

export const BulkChangeAreaColumn = () => {
  const { state } = useBulkChangeAreasContext();
  const { data: areas, isLoading, error } = useExcludedAreas(state.selectedRegion);

  const renderAreas = () => {
    if (!state.selectedRegion) return null;
    return areas ?
      areas.map(area => <AreaRow key={area.id} area={area} />) :
      <NoDataRow loadStatus={{ loading: !areas && !error, success: !error }} />;
  };

  return (
    <Box icon={<Image2 />} header="Areas">
      <Table columns={tableHeaders.length} striped>
        {renderTableHeaders(tableHeaders)}
        {renderAreas()}
      </Table>
    </Box>
  );
};
