import React, { FC } from 'react';
import { HttpActionProps } from './HttpAction';

interface HateoasHandlerProps {
  links: HttpActionProps[] | undefined;
  action: string;
  editVariant: React.ReactNode;
  viewVariant?: React.ReactNode | undefined;
}

export const checkLink = (links: HttpActionProps[] | undefined, link: HttpActionProps | undefined) =>
  links?.find(n => n.action === link?.action && n.href === link?.href && n.httpVerb === link?.httpVerb);

export const checkAction = (links: HttpActionProps[] | undefined, action: string) =>
  links?.some(n => n.action === action);

export const HateoasHandler : FC<HateoasHandlerProps> = ({ links, action, editVariant, viewVariant }) => (
  <>
    {checkAction(links, action) ? <>{editVariant}</> : <>{viewVariant}</>}
  </>
);
