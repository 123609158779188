import { QuoteActions } from '@/components/pages/bows/details/quoteActionsMap';
import { isBroker, isInternalUser } from '@/components/routing/roles';
import { useQuoteContext } from '@/components/shared/BowSidebar/QuoteContext';
import { SpanCell } from '@/components/shared/Table/core/Components';
import { TableCell, TableRow } from '@/components/shared/Table/Table';
import { DirtyableValue } from '@/models/utils/DirtyableValue';
import { useHateoasExecutorContext } from '@/services/HateoasExecutorContext';
import { useValidationContext } from '@/services/ValidationContext';
import {
  formatAmount, formatInteger, formatInvert, formatPercentage, formatRate, tryParseFloat
} from '@/utility/formatter';
import { Globals } from '@/utility/globals';
import { theme } from '@/utility/theme';
import { WarWeb } from '@/war';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { NumberField } from '../../Form/fields/NumberField';
import { checkLink, HateoasHandler } from '../../HateoasHandler';
import { Validator } from '../../Validator';

interface StyledTableRowProps {
  transposed: boolean;
  isSum: boolean;
}
const StyledTableRow = styled(TableRow) <StyledTableRowProps>`

  ${props => props.isSum && css`
    > span {
      font-weight: 600;
    }
  `};

  ${props => props.isSum && !props.transposed && css`
    > span {
      border-top: 1px solid ${props.theme.border.sanMarino};
    }
  `};
`;

const SumLines = styled(TableCell) <{ height: string }>`
  grid-column-start: 1;
  grid-column-end: -1;
  padding: 0;
  border-top: 1px solid ${props => props.theme.border.sanMarino};
  height: 4px;
  border-bottom: 2px solid ${props => props.theme.border.sanMarino};
`;

export const getDelimiter = (transposed: boolean) => transposed ? 'top' : 'left';

interface CalculationsRowProps {
  showTaxes: boolean;
  transposed: boolean;
  showNetPremiumAfterTimeAdjustment: boolean;
  showCurrency: boolean;
  calculation: WarWeb.Calculation;
  calculationsType?: string;
  delimiters: number[];
}

export const CalculationsRow = ({
  showTaxes,
  transposed,
  showNetPremiumAfterTimeAdjustment,
  showCurrency,
  calculation,
  calculationsType,
  delimiters
}: CalculationsRowProps) => {
  const { hateoasExecutor, lastRequest, loading } = useHateoasExecutorContext();
  const { errors, setDismissedErrors } = useValidationContext();
  const { isMultiDestination, isNoClaimsBonusBased } = useQuoteContext();

  const [grossRateNoClaimsBonus, setGrossRateNoClaimsBonus] = useState<DirtyableValue<string>>({
    value: calculation.interestNumber !== Globals.KnRInterestNumber ? calculation?.grossRate?.toString() : undefined
  });
  const [grossRateClaimsPenalty, setGrossRateClaimsPenalty] = useState<DirtyableValue<string>>({
    value: calculation.interestNumber !== Globals.KnRInterestNumber ? calculation?.grossRateCp?.toString() : undefined
  });
  const [grossPremiumNoClaimsBonus, setGrossPremiumNoClaimsBonus] = useState<DirtyableValue<string>>({
    value: calculation?.grossPremium?.toString()
  });
  const [grossPremiumClaimsPenalty, setGrossPremiumClaimsPenalty] = useState<DirtyableValue<string>>({
    value: calculation?.grossPremiumCp?.toString()
  });
  const [taxRate, setTaxRate] = useState<DirtyableValue<string>>({
    value: calculation?.taxRate?.toString()
  });

  useEffect(() => {
    if (calculation && !loading) {
      setGrossRateNoClaimsBonus({
        value: calculation.interestNumber !== Globals.KnRInterestNumber ? calculation?.grossRate?.toString() : undefined,
        isDirty: false
      });
      setGrossRateClaimsPenalty({
        value: calculation.interestNumber !== Globals.KnRInterestNumber ? calculation?.grossRateCp?.toString() : undefined,
        isDirty: false
      });
      setGrossPremiumNoClaimsBonus({
        value: calculation?.grossPremium?.toString(),
        isDirty: false
      });
      setGrossPremiumClaimsPenalty({
        value: calculation?.grossPremiumCp?.toString(),
        isDirty: false
      });
      setTaxRate({
        value: calculation?.taxRate?.toString(),
        isDirty: false
      });
    }
  }, [calculation, errors, lastRequest]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (e.target.name === 'rateNoClaimsBonus') setGrossRateNoClaimsBonus({ value, isDirty: true });
    else if (e.target.name === 'rateClaimsPenalty') setGrossRateClaimsPenalty({ value, isDirty: true });
    else if (e.target.name === 'premiumNoClaimsBonus') setGrossPremiumNoClaimsBonus({ value, isDirty: true });
    else if (e.target.name === 'tax') setTaxRate({ value, isDirty: true });
    else setGrossPremiumClaimsPenalty({ value, isDirty: true });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      (e.target as HTMLInputElement).blur();
    }
  };

  const getValueOrNull = (value?: string) => value == null || value === '' ? null : value;

  const commitRateNoClaimsBonus = () => {
    if (grossRateNoClaimsBonus.isDirty) {
      const setRateRequest = {
        ...(calculationsType === 'Voyage' ? { calculationId: calculation.id } : { interestNumber: calculation.interestNumber }),
        calculationType: 'NoClaimBonus' as WarWeb.CalculationType,
        rate: getValueOrNull(grossRateNoClaimsBonus.value)
      };
      const action = calculationsType === 'Voyage' || !isMultiDestination ? QuoteActions.SetRate : QuoteActions.SetDestinationRate;
      void hateoasExecutor(calculation.links, action, setRateRequest, 'calculations');
    }
    setGrossRateNoClaimsBonus({ ...grossRateNoClaimsBonus, isDirty: false });
  };

  const commitRateClaimsPenalty = () => {
    if (grossRateClaimsPenalty.isDirty) {
      const setRateRequest = {
        ...(calculationsType === 'Voyage' ? { calculationId: calculation.id } : { interestNumber: calculation.interestNumber }),
        calculationType: 'ClaimsPenalty' as WarWeb.CalculationType,
        rate: getValueOrNull(grossRateClaimsPenalty.value)
      };
      const action = calculationsType === 'Voyage' || !isMultiDestination ? QuoteActions.SetRate : QuoteActions.SetDestinationRate;
      void hateoasExecutor(calculation.links, action, setRateRequest, 'calculations');
    }
    setGrossRateClaimsPenalty({ ...grossRateClaimsPenalty, isDirty: false });
  };

  const commitTax = () => {
    if (taxRate.isDirty) {
      const setTaxRequest: WarWeb.SetCalculationTaxRequest = {
        calculationId: calculation.id,
        taxRate: parseFloat(taxRate.value ?? '0')
      };
      void hateoasExecutor(calculation.links, QuoteActions.SetTax, setTaxRequest, 'calculations');
    }
    setTaxRate({ ...taxRate, isDirty: false });
  };

  const resolvePremiumAction = () => {
    if (calculation.interestNumber === Globals.KnRInterestNumber) return QuoteActions.SetKnrPremium;
    return calculationsType === 'Voyage' || !isMultiDestination ? QuoteActions.SetPremium : QuoteActions.SetDestinationPremium;
  };

  const commitPremiumNoClaimsBonus = () => {
    if (grossPremiumNoClaimsBonus.isDirty) {
      const setPremiumRequest = {
        ...(calculationsType === 'Voyage' || !isMultiDestination ? { calculationId: calculation.id } : { interestNumber: calculation.interestNumber }),
        calculationType: 'NoClaimBonus' as WarWeb.CalculationType,
        premium: getValueOrNull(grossPremiumNoClaimsBonus.value)
      };
      void hateoasExecutor(calculation.links, resolvePremiumAction(), setPremiumRequest, 'calculations');
    }
    setGrossPremiumNoClaimsBonus({ ...grossPremiumNoClaimsBonus, isDirty: false });
  };

  const commitPremiumClaimsPenalty = () => {
    if (grossPremiumClaimsPenalty.isDirty) {
      const setPremiumRequest = {
        ...(calculationsType === 'Voyage' || !isMultiDestination ? { calculationId: calculation.id } : { interestNumber: calculation.interestNumber }),
        calculationType: 'ClaimsPenalty' as WarWeb.CalculationType,
        premium: getValueOrNull(grossPremiumClaimsPenalty.value)
      };
      void hateoasExecutor(calculation.links, resolvePremiumAction(), setPremiumRequest, 'calculations');
    }
    setGrossPremiumClaimsPenalty({ ...grossPremiumClaimsPenalty, isDirty: false });
  };

  const isSum = calculation.rowType === 'Sum' as WarWeb.CalculationRowType;

  const showNCBFields = (isInternalUser() || (isBroker() && isNoClaimsBonusBased));
  type errorKey = 'Rate' | 'Premium' | 'TaxRate';
  const errorCellOffsets = {
    Rate: 4,
    Premium: 5,
    TaxRate: 8 + (isNoClaimsBonusBased ? 0 : 2),
  };
  const errorCellOffset = (errors && errorCellOffsets[Object.keys(errors)[0] as errorKey]) ?? 4;
  const rebatesSum = calculation.rebates?.reduce((acc, prev) => {
    if (isNoClaimsBonusBased || prev.shortName !== 'NCB') return prev ? acc + (prev.rebate || 0) : acc;
    return acc;
  }, 0);
  
  const rebateSumString = formatInvert(formatAmount, rebatesSum);
  const rebateSumValue = rebateSumString === '0.00' ? '' : rebateSumString;

  return (
    <>
      <StyledTableRow
        transposed={transposed}
        shaded={false}
        isSum={isSum}
        backgroundColor={isSum && !transposed ? theme.lightGreen25 : undefined}
      >
        {transposed ? (
          <>
            <TableCell right={transposed}>{calculation.name}</TableCell>
            <TableCell>{showCurrency ? calculation.currency : ''}</TableCell>
          </>
        ) : (
          <>
            <TableCell>{showCurrency ? calculation.currency : ''}</TableCell>
            <TableCell span={!transposed && isSum ? 2 : 1}>
              {calculation.abbreviation}
            </TableCell>
          </>
        )}

        {/*
          FOR NON-TRANSPOSED VIEW:
          For Sum line, allow Interest cell to span 2 cells, to ensure space for "Sum Insured" (see above)
          As such, the sum line needs to skip the SumInsured cell (which is empty/not used anyway) (see below)
        */}
        {(!isSum || transposed) && (
          <TableCell right>
            {formatInteger(calculation.sumInsured)}
          </TableCell>
        )}

        {showNCBFields && (
          <TableCell right>
            {!isSum && (
              <HateoasHandler
                links={calculation.interestNumber !== Globals.KnRInterestNumber && !transposed ? calculation.links : undefined}
                action={calculationsType === 'Voyage' || !isMultiDestination ? QuoteActions.SetRate : QuoteActions.SetDestinationRate}
                editVariant={(
                  // hideErrors on the field, since we rather show the error on a separate *row* of the table (for more space/width)
                  <Validator keys={['Rate']} isRelevant={!!checkLink(calculation.links, lastRequest)} hideErrors>
                    <NumberField
                      step={0.01}
                      name="rateNoClaimsBonus"
                      noErrors
                      disabled={loading}
                      setDismissedErrors={setDismissedErrors}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                      onBlur={commitRateNoClaimsBonus}
                      postfix="%"
                      value={grossRateNoClaimsBonus.value ?? ''}
                      dirty={grossRateNoClaimsBonus.isDirty}
                    />

                  </Validator>
                )}
                viewVariant={calculation.interestNumber !== Globals.KnRInterestNumber ?
                  `${formatRate(tryParseFloat(grossRateNoClaimsBonus.value), '%')}`
                  : ''}
              />
            )}
          </TableCell>
        )}

        {!isNoClaimsBonusBased && (
          <TableCell right>
            {!isSum && (
              <HateoasHandler
                links={calculation.interestNumber !== Globals.KnRInterestNumber && !transposed ? calculation.links : undefined}
                action={calculationsType === 'Voyage' || !isMultiDestination ? QuoteActions.SetRate : QuoteActions.SetDestinationRate}
                editVariant={(
                  <Validator keys={['Rate']} isRelevant={!!checkLink(calculation.links, lastRequest)} hideErrors>
                    <NumberField
                      step={10}
                      name="rateClaimsPenalty"
                      noErrors
                      disabled={loading}
                      setDismissedErrors={setDismissedErrors}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                      onBlur={commitRateClaimsPenalty}
                      postfix="%"
                      value={grossRateClaimsPenalty.value ?? ''}
                      dirty={grossRateClaimsPenalty.isDirty}
                    />
                  </Validator>
                )}
                viewVariant={formatRate(tryParseFloat(grossRateClaimsPenalty.value), '%')}
              />
            )}
          </TableCell>
        )}

        {isBroker() &&
          (!isSum ? (
            <TableCell right>
              {calculation.netRate != null ? `${formatRate(calculation.netRate, '%')}` : ''}
            </TableCell>
          ) : (
            <TableCell />
          ))}

        {showNCBFields && (
          <TableCell right>
            <HateoasHandler
              links={(isSum || transposed) ? undefined : calculation.links}
              action={resolvePremiumAction()}
              editVariant={(
                <Validator keys={['Premium']} isRelevant={!!checkLink(calculation.links, lastRequest)} hideErrors>
                  <NumberField
                    step={10}
                    name="premiumNoClaimsBonus"
                    setDismissedErrors={setDismissedErrors}
                    disabled={loading}
                    noErrors
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    onBlur={commitPremiumNoClaimsBonus}
                    value={grossPremiumNoClaimsBonus.value ?? ''}
                    dirty={grossPremiumNoClaimsBonus.isDirty}
                  />

                </Validator>
              )}
              viewVariant={formatAmount(tryParseFloat(grossPremiumNoClaimsBonus.value))}
            />
          </TableCell>
        )}

        {!isNoClaimsBonusBased && (
          <TableCell right>
            <HateoasHandler
              links={isSum || transposed ? undefined : calculation.links}
              action={calculationsType === 'Voyage' || !isMultiDestination ? QuoteActions.SetPremium : QuoteActions.SetDestinationPremium}
              editVariant={(
                <Validator keys={['Premium']} isRelevant={!!checkLink(calculation.links, lastRequest)} hideErrors>
                  <NumberField
                    step={10}
                    name="premiumClaimsPenalty"
                    noErrors
                    setDismissedErrors={setDismissedErrors}
                    disabled={loading}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    onBlur={commitPremiumClaimsPenalty}
                    value={grossPremiumClaimsPenalty.value ?? ''}
                    dirty={grossPremiumClaimsPenalty.isDirty}
                  />

                </Validator>
              )}
              viewVariant={formatAmount(tryParseFloat(grossPremiumClaimsPenalty.value))}
            />
          </TableCell>
        )}

        {calculationsType === 'Voyage' && (
          <>
            {transposed ? (
              calculation.rebates && calculation.rebates.map(each => (
                (isNoClaimsBonusBased || each.shortName !== 'NCB')
                && (
                  <React.Fragment key={each.rebateId}>
                    <TableCell right delimiter="top">{`${formatInvert(formatAmount, each.rebate)}`}</TableCell>
                    <TableCell right>{formatAmount(each.netAfterRebate)}</TableCell>
                  </React.Fragment>
                )
              ))
            ) : (
              <TableCell right delimiter="left">
                {calculation.interestNumber !== Globals.KnRInterestNumber && `${rebateSumValue}`}
              </TableCell>
            )}
            {
              transposed && calculation.interestNumber === Globals.KnRInterestNumber && (
                [...Array(delimiters[1] - delimiters[0])].map(e => (
                  <>
                    <TableCell delimiter="top" />
                    <TableCell />
                  </>
                ))
              )
            }
            <TableCell right delimiter={getDelimiter(transposed)}>{formatAmount(calculation.netPremiumAfterRebates)}</TableCell>

            {showNetPremiumAfterTimeAdjustment &&
              <TableCell right>{formatAmount(calculation.netPremiumAfterTimeAdjustment)}</TableCell>}

            {showTaxes && (
              <>
                {isSum ? (
                  <TableCell right />
                ) : (
                  <TableCell right>
                    <HateoasHandler
                      links={isSum || transposed ? undefined : calculation.links}
                      action={QuoteActions.SetTax}
                      editVariant={(
                        <Validator keys={['TaxRate']} isRelevant={!!checkLink(calculation.links, lastRequest)} hideErrors>
                          <NumberField
                            step={10}
                            name="tax"
                            noErrors
                            setDismissedErrors={setDismissedErrors}
                            disabled={loading}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                            onBlur={commitTax}
                            value={taxRate?.value}
                            postfix="%"
                            dirty={taxRate.isDirty}
                          />
                        </Validator>
                      )}
                      viewVariant={formatPercentage(calculation.taxRate)}
                    />
                  </TableCell>
                )}

                {isBroker() && (
                  <TableCell right>
                    {formatAmount(calculation.taxAmount)}
                  </TableCell>
                )}

                <TableCell right>
                  {formatAmount(calculation.netPremiumAfterTax)}
                </TableCell>

              </>
            )}

            {!isNoClaimsBonusBased && (
              <TableCell right delimiter={getDelimiter(transposed)}>
                {calculation.interestNumber !== Globals.KnRInterestNumber ? formatAmount(calculation.claimsPenalty) : ''}
              </TableCell>
            )}
          </>
        )}

        {!transposed && !isSum && (
          <>
            <SpanCell height="1" start={1} end={errorCellOffset} />
            <SpanCell height="1" start={errorCellOffset} end={-1}>
              <Validator keys={['TaxRate', 'Rate', 'Premium']} isRelevant={!!checkLink(calculation.links, lastRequest)} />
            </SpanCell>
          </>
        )}
      </StyledTableRow>

      {!transposed && isSum && (
        <TableRow backgroundColor={theme.lightGreen25}>
          <SumLines height="4px" />
        </TableRow>
      )}
    </>
  );
};
