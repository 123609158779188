import { ActionItem } from './EditExcludedAreasContext/types';
import { Selection } from './types';

export const isChanged = (s?: Selection) => {
  if (!s) return false;
  return s.isNewSelection || s.isRemovedSelection;
};

export const isChecked = (s?: Selection) => {
  if (!s) return false;
  return (s.persistedState || s.isNewSelection) && !s?.isRemovedSelection;
};

/**
 * Returns the next available id for a new action item.
 * Existing items (in db) has positive ids, new items (in state) has negative ids.
 */
export const getNewActionItemId = (items: ActionItem[]) => {
  const ids = items.map(p => p.id);
  const minimum = Math.min(...ids);
  if (minimum === Infinity) return -1; // No existing items
  return minimum > -1 ? -1 : minimum - 1;
};
