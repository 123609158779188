import { PageHeader } from '@/components/layout/PageHeader';
import { Routes } from '@/components/routing/routes';
import { useQuoteContext } from '@/components/shared/BowSidebar/QuoteContext';
import { Button } from '@/components/shared/Button';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { HateoasHandler } from '@/components/shared/HateoasHandler';
import { useHasScrolled } from '@/hooks/useHasScrolled';
import { useQuote } from '@/hooks/useQuote';
import { useBow } from '@/services/bowServices';
import { useValidationContext } from '@/services/ValidationContext';
import { only } from '@/utility/objectHelpers';
import { theme } from '@/utility/theme';
import { TimeConverter } from '@/utility/timeConverter';
import { WarWeb } from '@/war';
import {
  Approved, LeftArrow, RightArrow
} from '@instech/icons';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { ProgressBar } from '../../shared/ProgressBar';
import { QuoteActions } from '../bows/details/quoteActionsMap';
import { getLabel, QuoteLabel } from '../bows/details/quoteLabelMap';
import { steps } from './bowSteps';
import { useBowWizardStateContext } from './BowWizardStateContext';

const HeaderContainer = styled.div<{ hasScrolled: boolean }>`
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.background.primary};

  ${props => props.hasScrolled && css`
    box-shadow: 0 4px 8px -8px gray;
  `};
  position: sticky;
  top: ${props => props.theme.header.scrolledHeight};
  z-index: 5;
`;

const StyledProgressBar = styled(ProgressBar)`
  width: 50vw;
  max-width: 900px;
`;

const DoneButtonContainer = styled.div`
  position: relative;
  top: 0.5em;
`;

const DescriptionDate = styled.div`
  position: relative;
  top: 0.5em;
  font-size: 12px;
  opacity: 0.8;
  letter-spacing: 0.5px;
`;

interface HeaderActionsProps {
  changeStep: (step: number) => void;
  incrementStep: (increment: number, force?: boolean) => void;
  confirmStatusChange: (links: WarWeb.HyperMediaLink[], action: string) => {};
  done: () => void;
  warningsPaths: (string | undefined)[];
}
export const HeaderActions: FC<HeaderActionsProps> = ({ changeStep, incrementStep, confirmStatusChange, done, warningsPaths }) => {
  const { ids } = useQuoteContext();
  const { errors } = useValidationContext();
  const { bowId, quoteId } = ids;

  const { step } = useBowWizardStateContext();

  const { data: breach } = useBow(bowId);
  const selectedQuote = useQuote(bowId, quoteId) || breach?.primaryQuote;

  const isEdit = bowId && breach?.status !== 'Draft';

  const scrolledHeight = 52;
  const hasScrolled = useHasScrolled(scrolledHeight);

  const labels = {
    title: isEdit ? 'Edit Alternative' : 'New Bow',
    breadcrumb: isEdit ?
      {
        label: 'Bow Details',
        onClick: done
      }
      : { label: 'Breach of warranty', to: Routes.bows }
  };

  return (
    <HeaderContainer hasScrolled={hasScrolled}>
      <PageHeader header={labels.title} breadcrumb={labels.breadcrumb} />
      <StyledProgressBar
        steps={steps.map(n => ({ step: n.step, name: n.name }))}
        step={step}
        changeStep={changeStep}
        warningsPaths={warningsPaths}
        warningsAreRegex
        className="progress"
      />

      {(!bowId || breach?.status === 'Draft') && (
        <ButtonGroup>
          {step > 0 && (
            <Button
              thin
              onClick={() => incrementStep(-1)}
              background={theme.white}
              icon={<LeftArrow />}
            />
          )}
          {step < steps.length - 1 && (
            <Button
              onClick={() => incrementStep(1)}
              tabIndex={0}
              disabled={!bowId}
              background={theme.border.lightGreen}
              rightIcon={<RightArrow />}
            >
              Next
            </Button>
          )}
          {step === steps.length - 1 && (
            <HateoasHandler links={selectedQuote?.links}
              action={QuoteActions.GenerateQuote}
              editVariant={(
                <Button
                  onClick={() => confirmStatusChange(selectedQuote?.links || [], QuoteLabel.GenerateQuote)}
                  icon={<Approved />}
                  disabled={!!errors && !only(errors, ['ClientReference', 'Comments'])}
                  background={theme.border.lightGreen}
                >
                  {getLabel(QuoteLabel.GenerateQuote)}
                </Button>
              )} />
          )}
        </ButtonGroup>
      )}
      {isEdit && (
        <DoneButtonContainer>
          <Button
            onClick={done}
            background={theme.border.lightGreen}
          >
            Done
          </Button>
          <DescriptionDate>
            {`Saved ${TimeConverter.ToDateTime(breach?.lastUpdated?.dateTime)}`}
          </DescriptionDate>
        </DoneButtonContainer>
      )}
    </HeaderContainer>
  );
};
