import AsyncSelect, { AsyncProps } from 'react-select/async';
import {
  GroupBase, components, DropdownIndicatorProps
} from 'react-select';
import { Search } from '@instech/icons';
import { FieldLabel, FieldLayout } from '../core/Components';
import { sharedCustomSelectStyles } from './CustomSelect';

export const NoDropdownIndicator = { DropdownIndicator: () => null, IndicatorSeparator: () => null };

export const DropdownIcon = (props: DropdownIndicatorProps<any>) => (
  <components.DropdownIndicator {...props}>
    <Search />
  </components.DropdownIndicator>
);

export const CustomDropdownIndicator = { DropdownIndicator: DropdownIcon, IndicatorSeparator: () => null };

export function CustomAsyncSelect<Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: AsyncProps<Option, IsMulti, Group>) {
  const { name, 'aria-label': label, styles, ...rest } = props;

  const defaultStyles = {
    ...sharedCustomSelectStyles,
    control: (provided: any, state?: any) => ({
      ...provided,
      '*': {
        boxShadow: 'none !important',
      },
      'boxShadow': state.isFocused ? '0 0 0 1px black' : 'none',
      'borderColor': state.isFocused ? '#000000' : 'lightgrey',
    }),
  };

  return (
    <FieldLayout>
      {label && (
        <FieldLabel as="label" htmlFor={name}>
          {label}
        </FieldLabel>
      )}
      <AsyncSelect name={name} styles={{ ...defaultStyles, ...styles }} {...rest} />
    </FieldLayout>
  );
}
