import React from 'react';
import { SingleValue } from 'react-select';
import { useRegions } from '@/services/areasService';
import { CustomSelect, CustomSelectValue } from '@/components/shared/Form/fields/CustomSelect';
import { useEditExcludedAreasContext } from '../EditExcludedAreasContext/EditExcludedAreasContext';
import { SearchBox } from '../../../create-bow/components/SearchBox';
import { SearchBoxContainer } from './Components';

interface PortFiltersProps {
  filterRegionId?: number;
  setFilterRegionId: (value?: number) => void;
  freetext: string;
  handleInput: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}
export const PortFilters = ({ filterRegionId, setFilterRegionId, freetext, handleInput }: PortFiltersProps) => {
  const { state: { regionId }, isCustomArea } = useEditExcludedAreasContext();
  const { data: regions } = useRegions();

  const searchHint = isCustomArea ? 'Country, Port, Locode' : 'Port or Locode';

  const dropdownRegions = regions?.map(r => ({ label: r.name, value: r.id }));

  const onChangeHandler = (input: SingleValue<CustomSelectValue>) => {
    if (!input) return;
    setFilterRegionId(input.value);
  };
  return (
    <div>
      <SearchBoxContainer>
        {isCustomArea && (
          <CustomSelect
            aria-label="Select ports across regions"
            name="region"
            placeholder={regions?.find(r => r.id === filterRegionId || r.id === regionId)?.name}
            options={dropdownRegions}
            onChange={onChangeHandler}
            defaultValue={dropdownRegions?.find(x => x.value === filterRegionId)} />
        )}
        <SearchBox
          label="Filter By"
          value={freetext}
          handleInput={handleInput}
          placeholder={searchHint} />
      </SearchBoxContainer>
    </div>
  );
};
