import styled, { css } from 'styled-components';
import { ExposureButtons } from './ExposureButtons';
import { TableHeader } from '@/components/shared/Table/core/Components';
import { Table, TableCell, TableRow } from '@/components/shared/Table/Table';
import { WarWeb } from '@/war';

interface StyledTableRowProps {
  isSum: boolean;
}
export interface ExposureProps {
  calculations: any;
  hateoasExecutor?: (links: any, action: string, options: ExposureOptionsProps) => any;
  bowData?: WarWeb.Bow;
  loading?: boolean;
  isDisabled?: boolean;
  setLoading?: any;
  setIsDisabled?: (value: boolean) => void;
}
export interface ExposureOptionsProps {
  reinsuranceType?: string;
  share?: number;
  remainingShare?: number;
}
const StyledTableRow = styled(TableRow)<StyledTableRowProps>`
  ${(props) =>
    props.isSum &&
    css`
      > span {
        font-weight: 600;
        border-top: 2px solid ${props.theme.border.sanMarino};
        border-bottom: 2px solid ${(props) => props.theme.border.sanMarino};
        background: ${(props) => props.theme.lightGreen80} !important;
      }
    `};
`;

export const PlaceExposure = ({ calculations, hateoasExecutor, bowData, loading, setLoading, isDisabled, setIsDisabled }: ExposureProps) => {
  return (
    <div>
      <Table columns={4} striped={true}>
        <TableHeader>Currency</TableHeader>
        <TableHeader>Interest</TableHeader>
        <TableHeader>Sum Insured</TableHeader>
        <TableHeader>Our Exposure</TableHeader>
        {calculations.map((row: WarWeb.Calculation) => (
          <StyledTableRow key={row.id + 1} isSum={row.rowType == 'Sum'}>
            <TableCell>{row.currency}</TableCell>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.sumInsured?.toLocaleString()}</TableCell>
            <TableCell>{row.ourExposure?.toLocaleString()}</TableCell>
          </StyledTableRow>
        ))}
      </Table>
      <ExposureButtons isDisabled={isDisabled} setIsDisabled={setIsDisabled} hateoasExecutor={hateoasExecutor} bowData={bowData} loading={loading} setLoading={setLoading} />
    </div>
  );
};
