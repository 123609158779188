import { AgreementTypeEnum } from '@/models/Agreement';
import { TimeConverter } from '@/utility/timeConverter';
import { UserInitials } from '@/utility/userNameHelpers';
import { useAgreementDetailsContext } from '../AgreementDetailsContext';

interface LastChangedProps {
  type: AgreementTypeEnum;
}
export const LastChanged = ({ type }: LastChangedProps) => {
  const { history } = useAgreementDetailsContext();

  if (!history) return null;

  const renderLastChanged = () => history.lastUpdated ? (
    <div>
      {`Last changed ${TimeConverter.ToDateTime(history.lastUpdated)} by `}
      <UserInitials name={history.lastUpdatedBy} />
    </div>
  ) : null;
  const renderCreatedBy = () => history.created ? (
    <div>
      {`Created ${TimeConverter.ToDateTime(history.created)} by `}
      <UserInitials name={history.createdBy} />
    </div>
  ) : null;

  return (
    <>
      {renderLastChanged()}
      {type === AgreementTypeEnum.Specific && (
        renderCreatedBy()
      )}
    </>
  );
};
