import { AgreementTypeEnum } from '@/models/Agreement';
import { WarWeb } from '@/war';
import useSWR from 'swr';
import { TargetType, targetTypeIsAlternativeCapable } from '@/models/TargetType';
import { postJsonAsync } from './client';
import { postData } from './utility/simpleFetch';

const basepath = 'agreements';

const agreementsFetcher = async (targets?: any, source?: string) => {
  const tempPostFix = source ? `?${source}` : '';
  return postJsonAsync<WarWeb.AgreementSummary>(`${basepath}/summary${tempPostFix}`, targets);
};

const cleanAgreementTargets = (targets?: WarWeb.AgreementTargets) => {
  if (!targets) return null;
  if (targets.areaIds && targets.areaIds.length > 0) return { ...targets, agreementIds: [] };
  return targets;
};

const cleanAgreementAlternativeTargets = (targets?: WarWeb.AgreementTargets) => {
  if (!targets) return undefined;
  if (targetTypeIsAlternativeCapable(targets.targetType ?? TargetType.Unspecified)) return targets;

  // When targeting agreements not capable of alternatives, we need to clear the daysCoveredGroup and minimumSumInsured from the targets
  const cleanTargets: WarWeb.AgreementTargets = {
    ...targets,
    daysCoveredGroup: undefined,
    minimumSumInsured: undefined
  };
  return cleanTargets;
};

export const useAgreements = (params?: WarWeb.AgreementTargets, key?: AgreementTypeEnum, source?: string) => {
  const targets = cleanAgreementTargets(params);
  const uriKey = `agreements/summary/${JSON.stringify(targets)}`;
  return useSWR<WarWeb.AgreementSummary>(targets ? uriKey : null, () => agreementsFetcher(targets, source));
};

export const useBrokerAgreements = (params?: WarWeb.BrokerAgreementSummaryTargets) => {
  const uri = `${basepath}/summary/broker`;
  return useSWR<WarWeb.BrokerAgreementSummary>(params ? uri : null, () => postJsonAsync<WarWeb.BrokerAgreementSummary>(uri, cleanAgreementTargets(params)));
};

export const updateAgreements = async (params: WarWeb.AgreementUpdateParameters) => {
  const request: WarWeb.AgreementUpdateParameters = { ...params, targets: cleanAgreementAlternativeTargets(params.targets) };
  return postData<WarWeb.AgreementUpdateResult>(basepath, request);
};

export const deleteAgreements = async (params: WarWeb.AgreementTargets) => {
  const request: WarWeb.DeleteAgreementTargetsParameters = {
    targets: cleanAgreementTargets(params)!,
  };
  await postData(`${basepath}/delete`, request);
};

export const copyPasteAgreements = async (fromVesselId: number, toVesselId: number) => postData(`${basepath}/copy/${fromVesselId}/${toVesselId}`);

export const useAgreementsSummaryAlternatives = (params?: WarWeb.AgreementTargets) => {
  const uri = `${basepath}/summary/alternatives`;
  const targets = cleanAgreementTargets(params);
  const uriKey = `${uri}/${JSON.stringify(targets)}`;
  return useSWR<WarWeb.AgreementAlternativesSummary[]>(targets ? uriKey : null, () => postJsonAsync<WarWeb.AgreementAlternativesSummary[]>(uri, targets));
};

export const createAgreementAlternative = (params: WarWeb.CreateAgreementAlternativeParameters) => {
  const targets = cleanAgreementTargets(params.targets);
  const request = { ...params, targets };
  return postData(`${basepath}/alternative/create`, request);
};
export const deleteAgreementAlternative = (params: WarWeb.DeleteAgreementAlternativeParameters) => {
  const targets = cleanAgreementTargets(params.targets);
  const request = { ...params, targets };
  return postData(`${basepath}/alternative/delete`, request);
};
