import React from 'react';
import { useAccount } from 'its-js-authentication';
import { Redirect } from 'react-router';
import { Routes } from '@/components/routing/routes';

export const LogoutPage = () => {
  const { logout } = useAccount();
  logout();

  return (
    <Redirect to={Routes.base} />
  );
};
