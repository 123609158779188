import { useHasScrolled } from '@/hooks/useHasScrolled';
import { ChildrenProps } from '@/models/utils/ChildrenProps';
import styled from 'styled-components';
import { theme } from '@/utility/theme';
import { Header } from './Header';

const Page = styled.div`
  margin-bottom: 100px;
`;

const Layout = styled.div<{ scrolled?: boolean }>`
    margin: 0 auto;
    padding: ${props => props.theme.portal.contentPadding};
    margin-top: ${props => props.scrolled ? '52px' : null};

    @media (max-width: ${theme.breakpoints.desktopSmall}) {
      padding: ${props => props.theme.portal.contentPaddingSmallDesktop};
    }

    @media (max-width: ${theme.breakpoints.tabletMax}) {
      padding: ${props => props.theme.portal.contentPaddingTablet};
    }

`;

export const BaseLayout = ({ children }: ChildrenProps) => {
  const scrolledHeight = 52; // TODO: Fix height difference from theme, verify
  const hasScrolled = useHasScrolled(scrolledHeight);

  return (
    <Page>
      <Header scrolled={hasScrolled} />
      <Layout scrolled={hasScrolled}>
        {children}
      </Layout>
    </Page>
  );
};
