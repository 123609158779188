import React from 'react';
import { TextField } from '@/components/shared/Form/FormFields';
import {
  Table, TableCell, TableRow
} from '@/components/shared/Table/Table';
import {
  ContractType, CoverageSummary, isStandardAgreementType, ValueSummary
} from '@/models/Agreement';
import {
  formatInteger, formatRange, getInputValue
} from '@/utility/formatter';
import { WarWeb } from '@/war';
import { Validator } from '@/components/shared/Validator';
import { useAgreementSelectionContext } from '@/components/pages/agreementDetails/AgreementSelectionContext';
import { AgreementViewMode, useAgreementDetailsContext } from '@/components/pages/agreementDetails/AgreementDetailsContext';
import { useAgreementDataContext } from '@/components/pages/agreementDetails/AgreementDataContext';
import { DuoTableLayout, renderCoverageTableHeaders } from '../../shared/SettingsComponents';
import { ContractTypeTable } from './ContractTypeTable';
import { MaxNumberTimeValue } from '@/utility/globals';

type FieldNames = 'daysCovered' | 'maximumDaysCovered' | 'quoteValidHours';
type ErrorKeys = 'DaysCovered' | 'MaximumDaysCovered' | 'QuoteValidHours';

export const Coverage = () => {
  const { coverage, setCoverage } = useAgreementDataContext();
  const { targetType } = useAgreementSelectionContext();
  const { viewMode } = useAgreementDetailsContext();

  if (!coverage) return null;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: FieldNames) => {
    const { name, value } = e.target;
    const tempCoverage : CoverageSummary = { ...coverage };

    const valueAsInt = value ? parseInt(value) : undefined;

    if (fieldName) {
      const field = tempCoverage[fieldName];
      field.value = valueAsInt;
      field.isDirty = true;
    } else if (name === 'maximumProRataDays') {
      const field = tempCoverage.maximumProRataDays;
      field.value = valueAsInt;
      field.isDirty = true;
    } else if (name === 'contractType') {
      const field = tempCoverage.contractType;
      field.value = value as ContractType;
      field.isDirty = true;
    }
    setCoverage(tempCoverage);
  };

  const renderEditCell = (item: ValueSummary<number>, fieldName: FieldNames, errorKey: ErrorKeys) => {
    const value = getInputValue(item.value);
    return (
      <TableCell>
        {viewMode !== AgreementViewMode.Inspect ? (
          <Validator keys={[`Coverage.${errorKey}`]}>
            <TextField type="number"
              maxValue={MaxNumberTimeValue}
              name={item.tier!}
              placeholder={formatRange(item)}
              value={value}
              dirty={item.isDirty}
              numberOfCharacters={5}
              onChange={e => handleInputChange(e, fieldName)} />

          </Validator>
        ) : formatRange(item, formatInteger) ?? formatInteger(item.value)}

      </TableCell>
    );
  };
  
  const isStandardAgreement = isStandardAgreementType(targetType);

  return (
    <DuoTableLayout>
      <Table columns={isStandardAgreement ? 4 : 3} striped>
        {renderCoverageTableHeaders(isStandardAgreement)}
            <TableRow top>
              <TableCell />
              {renderEditCell(coverage.quoteValidHours, 'quoteValidHours', 'QuoteValidHours')}
              {renderEditCell(coverage.daysCovered, 'daysCovered', 'DaysCovered')}
              {isStandardAgreement && renderEditCell(coverage.maximumDaysCovered, 'maximumDaysCovered', 'MaximumDaysCovered')}
            </TableRow>
      </Table>

      <ContractTypeTable coverage={coverage} handleInputChange={handleInputChange} />
    </DuoTableLayout>
  );
};
