import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { theme } from '@/utility/theme';
import { Toolbar } from '@/models/Toolbar';
import { HateoasHandler, checkAction } from '@/components/shared/HateoasHandler';
import { WarWeb } from '@/war';
import { darken } from 'polished';
import { Button, ButtonShape } from '../Button';
import { useQuoteContext } from './QuoteContext';
import { Warnings } from './Warnings';
import { QuoteActions } from '@/components/pages/bows/details/quoteActionsMap';

interface SidebarItemProps {
  toolbar?: Toolbar[];
  links?: WarWeb.HyperMediaLink[];
  compact?: boolean;
  background?: string;
  color?: string;
  border?: string;
  hasSubItem?: boolean;
  isSubItem?: boolean;
  fieldPath?: string;
  onClick?: () => void;
  isSelected?: boolean;
}

export const SidebarContainer = styled.div<SidebarItemProps>`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;

  color: ${(props) => props.color ?? props.theme.marineBlue};
  background-color: ${(props) => props.background};
  border: ${(props) => props.border};
  ${(props) =>
    !props.isSubItem &&
    css`
      padding: ${props.compact ? '0.3rem' : '1rem'};
    `}

  border-radius: 2px;

  ${(props) =>
    props.isSubItem
      ? css`
          border-radius: 0 0 2px 2px;
        `
      : css`
          border-radius: ${props.hasSubItem ? '2px 2px 0 0' : '2px'};
          margin-top: 0.5em;
        `};

  cursor: ${(props) => (props.onClick ? 'pointer' : undefined)};

  ${(props) =>
    props.isSelected &&
    css`
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: -10px;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-top: 15px solid transparent;
        border-left: 15px solid ${props.background};
        border-bottom: 15px solid transparent;
      }
    `};

  ${(props) =>
    !!props.onClick &&
    !props.isSelected &&
    css`
      &:hover {
        background-color: ${darken(0.1, props.background || theme.white)};
        transition: all 0.5s ease-in-out;
      }
    `};
`;

const Admin = styled.div`
  display: flex;

  ${ButtonShape} {
    padding: 0;
  }

  svg {
    width: 18px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: inherit;
  width: 100%;

  h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
  }

  h2 {
    margin: 0;
    font-size: 16px;
    font-weight: 300;
  }

  strong {
    font-size: 16px;
  }

  small {
    display: block;
    font-size: 14px;
    color: ${(props) => props.theme.sanMarino};
  }
`;

export const SidebarItem = ({
  toolbar,
  links,
  background,
  color,
  border,
  compact,
  hasSubItem,
  isSubItem,
  fieldPath,
  isSelected,
  onClick,
  children,
}: PropsWithChildren<SidebarItemProps>) => {
  const { showSidebarLinks } = useQuoteContext();
  const isRevalidateQuote = checkAction(links, QuoteActions.RevalidateQuote);

  return (
    <SidebarContainer
      onClick={onClick}
      background={compact ? '' : background || theme.lightBlue}
      color={color}
      border={border}
      hasSubItem={hasSubItem}
      isSubItem={isSubItem}
      isSelected={isSelected}
      compact={compact}
    >
      <Content>{children}</Content>
      <Warnings path={fieldPath} />

      {(showSidebarLinks || isRevalidateQuote) && (
        <Admin>
          {links &&
            toolbar?.map((tool, i) => (
              <HateoasHandler
                // eslint-disable-next-line react/no-array-index-key
                key={`${tool.action}-${i}`}
                links={links}
                action={tool.action}
                editVariant={
                  tool.onClick ? (
                    <Button icon={tool.icon} onClick={tool.onClick} />
                  ) : (
                    <Button icon={tool.icon} to={tool.to} />
                  )
                }
              />
            ))}
        </Admin>
      )}
    </SidebarContainer>
  );
};
