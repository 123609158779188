import React from 'react';
import styled from 'styled-components';
import { formatDate } from '@/utility/dateCalc';
import { TrailDot, TrailLine } from '@/components/pages/create-bow/components/Components';
import { WarWeb } from '@/war';
import { theme } from '@/utility/theme';
import { useQuoteContext } from './QuoteContext';
import { Warnings } from './Warnings';
import { SidebarItem } from './SidebarItem';
import { DaysDetails } from './DaysDetails';

const Wrapper = styled.div`
  display: flex;
  background-color: inherit;
`;

const Port = styled.div`
  white-space: nowrap;
  display: block;
  font-size: 14px;
`;

interface ItineraryBoxProps {
  coveredAreaIndex: number;
  itinerary: WarWeb.Destination[];
}
export const ItineraryBox = ({ coveredAreaIndex, itinerary }: ItineraryBoxProps) => {
  const { selectedConfiguration, setSelectedConfiguration, isMultiDestination, showSidebarLinks } = useQuoteContext();

  const isSinglePort = itinerary.length === 1;
  const isItemsClickable = isMultiDestination && showSidebarLinks;

  return (
    <SidebarItem
      isSubItem
      background={theme.white}
      border={`2px solid ${theme.sanMarino25} `}>

      {!isSinglePort && <TrailLine left offsetx="1rem + 15px" offsety="2rem" length={itinerary.length} />}

      {itinerary && itinerary.map(((destination, i) => {
        const { isTimeException } = destination.port;

        return (
          <SidebarItem
            key={destination.id}
            background={selectedConfiguration === destination.id ? theme.blueGray : theme.white}
            isSelected={selectedConfiguration === destination.id}
            onClick={!isItemsClickable || destination.port.isTimeException ? undefined : () => setSelectedConfiguration!(destination.id)}>
            <Wrapper>
              {isMultiDestination && (
                <TrailDot
                  first={i === 0}
                  last={i === itinerary.length - 1}
                  color={isTimeException ? theme.green : undefined} />
              )}
              <Port>
                <strong>{destination.port?.name}</strong>
                <br />

                {(destination.entry || destination.exit) && (
                  <>
                    {`${formatDate(destination.entry?.local) ?? 'TBD'} - ${formatDate(destination.exit?.local) ?? 'TBD'} `}
                  </>
                )}
                <br />
                <DaysDetails destination={destination} />
              </Port>
              <Warnings path={`^coveredAreas\\[${coveredAreaIndex}\\]\\.Itinerary\\[${i}\\]\\.[A-Za-z]+$`} />

            </Wrapper>

          </SidebarItem>
        );
      }))}
    </SidebarItem>
  );
};
