import React, {
  useRef, useState, useEffect, useCallback, PropsWithChildren
} from 'react';
import styled, { css } from 'styled-components';
import {
  AlphaOrder, Close, Search
} from '@instech/icons';
import { darken } from 'polished';
import { theme } from '@/utility/theme';
import { addOrRemove } from '@/utility/arrayHelpers';
import { useFacets } from '@/services/facetServices';
import useDebounce from '@/hooks/useDebouncedValue';
import { NoDataRow } from '@/components/shared/Table/Table';
import { SortOrder } from '@/models/SortOrder';
import { useFilterableTableHeadersContext } from '@/components/pages/defaultTermsConditions/FilterableTableHeadersContext';
import { bowStatus } from '@/utility/bowStatus';
import { DataType } from '@/models/Datatype';
import { Button } from '../../Button';
import { TextField } from '../../Form/FormFields';
import { Checkbox } from '../../Form/fields/Checkbox';

const DropdownWrapper = styled.div<{ isVisible: boolean, right: boolean }>`
  width: 300px;
  border-radius: 3px;
  background-color: #fff;
  position: absolute;
  ${props => props.right && css`
    right: 0;
  `};
  z-index: 3;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  display: ${props => props.isVisible ? 'flex' : 'none'};
  flex-direction: column;
  padding: 1em;
`;

const DropdownBackground = styled.div<{ isVisible: boolean }>`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  display: ${props => props.isVisible ? 'flex' : 'none'};
  z-index: 2;
`;

interface DropdownContainerProps {
  isVisible: boolean;
  right: boolean;
  clickOutside: (e: React.MouseEvent) => void;
}
export const DropdownContainer = ({ clickOutside, isVisible, right, children }: PropsWithChildren<DropdownContainerProps>) => (
  <>
    <DropdownBackground isVisible={isVisible} onClick={clickOutside} />
    <DropdownWrapper isVisible={isVisible} right={right}>
      {children}
    </DropdownWrapper>
  </>
);

export const DropdownElement = styled.div<{ first?: boolean, disabled?: boolean }>`
  color: ${props => props.disabled ? props.theme.sanMarino25 : props.theme.marineBlue};
  display: flex;
  align-items: center;
  ${props => !props.disabled && css`
    cursor: pointer;
  `};

  ${props => !props.first && css`
    margin-top: 1rem;
  `};
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 50px;
  height: 25px;
`;

const FacetWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const EmptyFacet = styled.div`
  padding: 3px 9px;
  background-color: ${theme.ultraLightGray};
  border-radius: 4px;
  font-style: italic;
  color: ${theme.blueGray};
`;

export const DropdownButtonGroup = styled(DropdownElement)`
  display: flex;
  justify-content: space-between;
`;

const FilterDropdownElement = styled(DropdownElement)`
  background-color: ${props => props.theme.whiteBlue};
  padding-right: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
`;

const FilterList = styled.div`
  background-color: ${props => props.theme.whiteBlue};
  border-radius: 2px;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  min-height: 100px;
  
  /* width */
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  scrollbar-width: thin;

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${props => props.theme.whiteBlue}; 
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${props => darken(0.3, props.theme.whiteBlue)}; 
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.marineBlue}; 
  }

  input {
    margin: 5px 10px !important;
  }
`;

export const SearchField = styled(TextField)`
  background-color: ${props => props.theme.whiteBlue};
  border-radius: 2px;
`;

const StyledSearch = styled(Search)`
  color: ${props => props.theme.marineBlue50};
`;

interface SearchFilterDropdownProps {
  facets: string[];
  endpoint: string;
  title?: string;
  parameterName: string;
  propertyName: string;
  close: () => void;
  display: boolean;
  type?: DataType;
  right: boolean;
}
export const SearchFilterDropdown = (
  {
    facets,
    endpoint,
    title,
    type,
    parameterName,
    propertyName,
    close,
    display,
    right = false
  }: SearchFilterDropdownProps
) => {
  const [searchString, setSearchString] = useState('');
  const debouncedSearchString = useDebounce(searchString, 500);
  const [filteredFacets, setFilteredFacets] = useState<string[]>(facets);
  const [selectedFacets, setSelectedFacets] = useState<string[]>([]);

  const { category, initialFilters, filters, setSortedHeader, changeFilter } = useFilterableTableHeadersContext();

  const currentFilters = {
    ...initialFilters,
    ...filters
  };

  const { data: facetsData, isLoading, error } = useFacets(display, endpoint, debouncedSearchString, propertyName, currentFilters, category);

  const mapper = (facet: string | boolean) => {
    if (!title || type !== 'Boolean') return facet;
    return facet ? 'True' : 'False';
  };

  const unmapper = (facet: string) => {
    if (!title || type !== 'Boolean') return facet;
    return facet === 'True' || facet === title;
  };

  const mapToLabel = (facet: string | boolean) => {
    if (!title || type !== 'Boolean') return String(facet);
    return facet === 'True' ? title : `Not ${title}`;
  };

  useEffect(() => {
    if (facetsData) {
      if (propertyName === 'Status') {
        setFilteredFacets([...facetsData.items.sort(
          (a, b) => (bowStatus.find(u => u.title === a)?.sortOrder ?? 0) - (bowStatus.find(u => u.title === b)?.sortOrder ?? 0)
        )]);
      } else if (!searchString) {
        setFilteredFacets([...new Set([...selectedFacets, ...facetsData.items])]);
      } else {
        setFilteredFacets([...facetsData.items]);
      }
    }
  }, [facetsData]);

  const checkCheckboxesForCurrentFilters = useCallback(() => {
    const key = parameterName[0].toLowerCase() + parameterName.substring(1); // hack
    const relevantFilters = {
      [parameterName]:
        filters[parameterName]?.map(mapper)
        || (!!initialFilters && initialFilters[key]?.map(mapper))
        || []
    };
    setSelectedFacets(parameterName ? relevantFilters[parameterName] || [] : []);
  }, [filters, parameterName]);

  useEffect(() => {
    checkCheckboxesForCurrentFilters();
  }, [filters, checkCheckboxesForCurrentFilters]);

  const handleInput = (e: any) => {
    const newState = e.target.value;
    setSearchString(newState);
  };

  const toggleFacet = (facet: string) => {
    const newSelectedFacets = [...selectedFacets];
    addOrRemove(newSelectedFacets, facet);
    setSelectedFacets(newSelectedFacets);
  };

  const changeSort = (sortOrder: SortOrder) => {
    setSortedHeader({ propertyName, sortOrder });
    changeFilter!('orderBy', `${propertyName} ${sortOrder}`);
    close();
  };

  const apply = () => {
    if (type === 'Boolean') {
      changeFilter!(parameterName, selectedFacets?.map(f => f === 'True' || f === title));
    } else {
      changeFilter!(parameterName, selectedFacets);
    }
    close();
  };

  const reset = () => {
    setSearchString('');
    setFilteredFacets(facetsData?.items || facets);
    setSelectedFacets([]);
    changeFilter!(parameterName, []);
  };

  const cancel = () => {
    checkCheckboxesForCurrentFilters();
    setSearchString('');
    close();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, positive: () => void, negative: () => void) => {
    if (e.key === 'Enter') positive();
    else if (e.key === 'Escape') negative();
  };

  const searchFieldRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (searchFieldRef?.current && display) searchFieldRef.current.focus();
  }, [display]);

  const filterApplied = filters && filters[parameterName];
  const valuesSet = selectedFacets.length > 0;

  const renderFacets = () => (
    filteredFacets.map(facet => (
      <FacetWrapper key={facet}>
        <Checkbox key={facet}
          rightLabel={mapToLabel(facet)}
          checked={selectedFacets.includes(facet)}
          onKeyDown={e => handleKeyDown(e, apply, cancel)}
          onChange={() => toggleFacet(facet)}
          name={facet}
        />
        {facet === '' && <EmptyFacet onClick={() => toggleFacet(facet)}>No value</EmptyFacet>}
      </FacetWrapper>
    ))
  );

  return (
    <DropdownContainer clickOutside={close} isVisible={display} right={right}>

      <DropdownElement tabIndex={0}
        onKeyDown={e => handleKeyDown(e, () => changeSort(SortOrder.Ascending), cancel)}
        onClick={() => changeSort(SortOrder.Ascending)}
        first>
        <Icon>
          <AlphaOrder />
        </Icon>
        Sort A to Z
      </DropdownElement>
      <DropdownElement tabIndex={0}
        onKeyDown={e => handleKeyDown(e, () => changeSort(SortOrder.Descending), cancel)}
        onClick={() => changeSort(SortOrder.Descending)}>
        <Icon />
        Sort Z to A
      </DropdownElement>

      <DropdownElement tabIndex={0}
        onKeyDown={e => handleKeyDown(e, reset, cancel)}
        onClick={reset}
        disabled={!filterApplied && !valuesSet}>
        <Icon>
          <Close />
        </Icon>
        {`Clear filter for ${title}`}
      </DropdownElement>

      {type !== 'Boolean' && (
        <DropdownElement>
          <SearchField
            id={`search_${title}`}
            name={parameterName}
            value={searchString}
            placeholder="Filter"
            onChange={handleInput}
            inputFieldRef={searchFieldRef}
            tabIndex={0}
            icon={<StyledSearch />}
            stretch
          />
        </DropdownElement>
      )}

      <FilterDropdownElement>
        <FilterList>
          {!isLoading && filteredFacets
            ? <>{renderFacets()}</>
            : <NoDataRow size="small" backgroundColor={theme.whiteBlue} loadStatus={{ loading: isLoading, success: !error }} />}
        </FilterList>
      </FilterDropdownElement>

      <DropdownButtonGroup>
        <Button background={theme.lightGray} onClick={cancel}>Cancel</Button>
        <Button background={theme.green} disabled={!filterApplied && !valuesSet} onClick={apply}>Filter</Button>
      </DropdownButtonGroup>

    </DropdownContainer>
  );
};
