import {
  FaListUl, FaListOl, FaBold, FaItalic, FaLink
} from 'react-icons/fa';

export const inline = [
  { label: 'Bold', style: 'BOLD', icon: FaBold },
  { label: 'Italic', style: 'ITALIC', icon: FaItalic }
];
export const blocks = [
  { label: 'List', style: 'ordered-list-item', icon: FaListOl },
  { label: 'Bullets', style: 'unordered-list-item', icon: FaListUl },
];
export const links = [
  { label: 'Link', style: 'LINK', icon: FaLink }
];
