import {
  createContext, PropsWithChildren, useContext, useMemo
} from 'react';
import { WarWeb } from '@/war';

const ConditionsContext = createContext<ConditionsProviderProps>({
  selectedTerms: [],
  setSelectedTerms: () => { }
});

interface ConditionsProviderProps {
  terms?: WarWeb.Term[];
  selectedTerms: number[];
  setSelectedTerms: (selectedTerms: number[]) => void;
}

// TODO: Move state inside the provider
export const ConditionsProvider = ({
  terms,
  selectedTerms,
  setSelectedTerms,
  children
}: PropsWithChildren<ConditionsProviderProps>) => {
  const value = useMemo(
    () => ({ terms, selectedTerms, setSelectedTerms }),
    [terms, selectedTerms, setSelectedTerms]
  );
  return (
    <ConditionsContext.Provider value={value}>
      {children}
    </ConditionsContext.Provider>
  );
};

export const useConditionsContext = () => useContext(ConditionsContext);
