import Qs from 'qs';
import { useSWREndpoint } from '@/hooks/useSWREndpoint';
import { WarWeb } from '@/war';
import { AcceptHeaders } from './config';
import { getData } from './utility/simpleFetch';

const basepath = 'clients';

const getClientsUri = (params?: WarWeb.ClientsSearchParameters) => `${basepath}?${Qs.stringify(params, { indices: false })}`;

export const useClients = (params?: WarWeb.ClientsSearchParameters) =>
  useSWREndpoint<WarWeb.ClientsMatch>(getClientsUri(params), AcceptHeaders.SearchResult);

export const getClients = (params?: WarWeb.ClientsSearchParameters) => getData(getClientsUri(params));

export const useClient = (clientId?: number) => useSWREndpoint<WarWeb.Client>(clientId ? `${basepath}/${clientId}` : null);
