import styled, { css } from 'styled-components';

export const Tab = styled.div<{ selected: boolean, highlight?: string }>`
  padding: 0.5rem 2rem 0.5rem 0.5rem;
  cursor: pointer;
  border-bottom: 2px solid ${props => props.theme.whiteBlue};
  color: ${props => props.theme.sanMarino};
  margin-top: 5px;

  ${props => props.selected && css`
    margin-top: 0;
    border-top: 5px solid ${props.highlight};
    border-bottom: none;
    border-right: 2px solid ${props.theme.whiteBlue};
    &:not(:first-child) {
      border-left: 2px solid ${props.theme.whiteBlue};
    }
    color: ${props.theme.marineBlue};
  `}
`;
