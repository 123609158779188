export interface Exposure {
  area: string;
  netShare: number;
  totalExposure: number;
  items: {
    fleet: string;
    type: string;
    vessel: string;
    value: number;
  }[]
}

const emptyExposureObject = (area: string) : Exposure => (
  { area,
    netShare: 0,
    totalExposure: 0,
    items: [
      {
        fleet: '.',
        type: '.',
        vessel: 'A',
        value: 0
      },
      {
        fleet: '.',
        type: '.',
        vessel: 'B',
        value: 0
      },
      {
        fleet: '.',
        type: '.',
        vessel: 'C',
        value: 0
      }
    ] });

export const emptyExposure: Exposure[] = [
  emptyExposureObject('a'),
  emptyExposureObject('b'),
  emptyExposureObject('c'),
  emptyExposureObject('d')
];

export const exposureOverviewMock : Exposure[] = [
  {
    area: 'Bergen',
    netShare: 123456,
    totalExposure: 456789,
    items: [
      {
        fleet: 'Mega Fleet',
        type: 'Ultra Tanker',
        vessel: 'Boat McBoatface',
        value: 234567
      },
      {
        fleet: 'Mega Fleet',
        type: 'Ultra Tanker',
        vessel: 'Boaty the Boat',
        value: 234567
      },
      {
        fleet: 'Mega Fleet',
        type: 'Ultra Tanker',
        vessel: 'McFloaty',
        value: 234567
      }
    ]
  },
  {
    area: 'Oslo',
    netShare: 123456,
    totalExposure: 456789,
    items: [
      {
        fleet: 'Mega Fleet',
        type: 'Ultra Tanker',
        vessel: 'Boat McBoatface',
        value: 234567
      },
      {
        fleet: 'Mega Fleet',
        type: 'Ultra Tanker',
        vessel: 'Boaty the Boat',
        value: 234567
      },
      {
        fleet: 'Mega Fleet',
        type: 'Ultra Tanker',
        vessel: 'McFloaty',
        value: 234567
      }
    ]
  },
  {
    area: 'Stavanger',
    netShare: 123456,
    totalExposure: 456789,
    items: [
      {
        fleet: 'Mega Fleet',
        type: 'Ultra Tanker',
        vessel: 'Boat McBoatface',
        value: 234567
      },
      {
        fleet: 'Mega Fleet',
        type: 'Ultra Tanker',
        vessel: 'Boaty the Boat',
        value: 234567
      },
      {
        fleet: 'Mega Fleet',
        type: 'Ultra Tanker',
        vessel: 'McFloaty',
        value: 234567
      }
    ]
  }, {
    area: 'Trondheim',
    netShare: 123456,
    totalExposure: 456789,
    items: [
      {
        fleet: 'Mega Fleet',
        type: 'Ultra Tanker',
        vessel: 'Boat McBoatface',
        value: 234567
      },
      {
        fleet: 'Mega Fleet',
        type: 'Ultra Tanker',
        vessel: 'Boaty the Boat',
        value: 234567
      },
      {
        fleet: 'Mega Fleet',
        type: 'Ultra Tanker',
        vessel: 'McFloaty',
        value: 234567
      }
    ]
  },
];
