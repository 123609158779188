import styled from 'styled-components';
import { PageHeader } from '@/components/layout/PageHeader';
import { Routes } from '@/components/routing/routes';
import { ValidationProvider } from '@/services/ValidationContext';
import { RemainingValidations } from '@/components/shared/RemainingValidations';
import { KnRTermsPageProvider } from './context/KnRTermsPageContext';
import { Entries } from './Entries';
import { NewEntry } from './NewEntry';
import { Selected } from './Selected';

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "selected selected selected selected"
      "new-entry entries entries entries";
    column-gap: 1rem;
    row-gap: 1rem;

    .selected{ grid-area: selected; }
    .new-entry{ grid-area: new-entry; }
    .entries{ grid-area: entries; }
`;
export const KidnapRansomTermsPage = () => (
  <KnRTermsPageProvider>
    <ValidationProvider>
      <PageHeader header="Edit K&R" breadcrumb={{ label: 'Fleet specific T&C', to: Routes.termsSpecific }} />

      <RemainingValidations />

      <Container>
        <div className="selected"><Selected /></div>
        <div className="new-entry"><NewEntry /></div>
        <div className="entries"><Entries /></div>
      </Container>
    </ValidationProvider>
  </KnRTermsPageProvider>
);
