import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Chevron } from '@/components/shared/Chevron';
import { theme } from '@/utility/theme';

const TreeElement = styled.div`
  margin: 0;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 24px;
    left: 1px;
    height: calc(100% - 48px);
    border-left: 1px solid gray;
  }

  margin-left: ${props => props.isChildElement ? '16px' : '4px'};

  ${props => !props.isParentToggled && css`
    display: none;
  `}
`;

const ChildElement = styled.p`
  margin: 0 16px;

  ${props => !props.isToggled && css`
    display: none;
  `}
`;

const Toggler = styled.span`
  display: flex;
  flex-direction: row;
  float: left;

  ${props => !props.isToggled && css`
    transform: rotate(-90deg);
  `}
`;

const Count = styled.span`
  color: purple;
  font-size: 0.8em;
  font-weight: bold;
`;

const DisplayArray = ({ length }) => (
  <>
    <Count>{length}</Count>
    {' ['}
  </>
);

const Bool = styled.span`
  color: purple;
`;

const StyledUndefined = styled.span`
  color: purple;
  font-style: italic;
`;
const Undefined = () => (
  <StyledUndefined>undefined</StyledUndefined>
);

const Path = styled.span`
  color: ${theme.sanMarino};
`;

export const TreeView = ({
  data,
  toggled = true,
  name = '',
  isLast = true,
  isChildElement = false,
  isParentToggled = true,
  depth = 0,
  path = ''
}) => {
  const [isToggled, setIsToggled] = useState(toggled);
  const [isFlattened, setIsFlattened] = useState(false);

  if (!data) {
    return (
      <em>&nbsp;</em>
    );
  }

  const printBool = b => {
    if (b) return <Bool>true</Bool>;
    return <Bool>false</Bool>;
  };

  return (
    <TreeElement isChildElement={isChildElement} isParentToggled={isParentToggled}>
      <Toggler isToggled={isToggled}
          onClick={() => setIsToggled(!isToggled)}
        >
        <Chevron bottom thin />
      </Toggler>
      {false && <Count onClick={() => setIsFlattened(!isFlattened)}>{depth}</Count>}
      {name ? (
        <strong>
          &nbsp;&nbsp;
          {`${name}: `}
        </strong>
      ) : <span>&nbsp;&nbsp;</span>}
      <Path>{`${path} `}</Path>

      {Array.isArray(data) ? <DisplayArray length={data.length} /> : '{'}
      {!isToggled && '...'}
      {Object.keys(data).map((v, i, a) =>
        typeof data[v] === 'object' ? (
          <TreeView
              key={v}
              data={data[v]}
              isLast={i === a.length - 1}
              name={Array.isArray(data) ? null : v}
              isChildElement
              isParentToggled={isParentToggled && isToggled}
              depth={depth + 1}
              path={`${path}${Array.isArray(data) ? '[' : '.'}${v}${Array.isArray(data) ? ']' : ''}`}
            />
        ) : (
          <ChildElement isToggled={isToggled} key={v}>
            {Array.isArray(data) ? '' : (
              <strong>
                {v}
                :
                {' '}
              </strong>
            )}
            {typeof data[v] === 'undefined' ? <Undefined /> : ''}
            {typeof data[v] === 'boolean' ? printBool(data[v]) : data[v]}
            {i === a.length - 1 ? '' : ','}
          </ChildElement>
        ))}
      {Array.isArray(data) ? ']' : '}'}
      {!isLast ? ',' : ''}
      <br clear="all" />
    </TreeElement>
  );
};
