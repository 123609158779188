import React, {
  createContext, FC, useContext, useEffect, useMemo, useState
} from 'react';
import { ValidationMessages } from '@/models/ValidationMessages';
import { isEmpty } from '@/utility/objectHelpers';

interface ValidationProps {
  errors?: ValidationMessages;
  setErrors: React.Dispatch<React.SetStateAction<ValidationMessages | undefined>>;
  seenErrors: string[];
  setSeenErrors: React.Dispatch<React.SetStateAction<string[]>>;
  dismissedErrors: string[];
  setDismissedErrors: React.Dispatch<React.SetStateAction<string[]>>;
  dismissedWarnings: string[];
  setDismissedWarnings: React.Dispatch<React.SetStateAction<string[]>>;

  warnings?: ValidationMessages;
  setWarnings: React.Dispatch<React.SetStateAction<ValidationMessages | undefined>>;
  seenWarnings: string[];
  setSeenWarnings: React.Dispatch<React.SetStateAction<string[]>>;

  clearErrors: () => void;
  resetWarnings: () => void;
}

const ValidationContext = createContext<ValidationProps | undefined>(undefined);

const ValidationProvider: FC = ({ children }) => {
  const [errors, setErrors] = useState<ValidationMessages | undefined>();
  const [seenErrors, setSeenErrors] = useState<string[]>([]);
  const [dismissedErrors, setDismissedErrors] = useState<string[]>([]);
  const [dismissedWarnings, setDismissedWarnings] = useState<string[]>([]);

  const [warnings, setWarnings] = useState<ValidationMessages | undefined>();
  const [seenWarnings, setSeenWarnings] = useState<string[]>([]);

  const clearErrors = () => setErrors(undefined);

  const resetWarnings = () => {
    setDismissedWarnings([]);
    setSeenWarnings([]);
  };

  const value = useMemo(
    () => (
      {
        errors,
        setErrors,
        seenErrors,
        setSeenErrors,
        clearErrors,
        dismissedErrors,
        setDismissedErrors,
        dismissedWarnings,
        setDismissedWarnings,
        warnings,
        setWarnings,
        seenWarnings,
        setSeenWarnings,
        resetWarnings
      }),
    [errors, seenErrors, dismissedErrors, dismissedWarnings, warnings, seenWarnings]
  );

  useEffect(() => {
    if (!errors || isEmpty(errors)) {
      setDismissedErrors([]);
      setSeenErrors([]);
    }
  }, [errors]);

  useEffect(() => {
    resetWarnings();
  }, [warnings]);

  return (
    <ValidationContext.Provider value={value}>
      {children}
    </ValidationContext.Provider>
  );
};

const useValidationContext = () => {
  const context = useContext(ValidationContext);

  if (context === undefined) {
    throw new Error('useValidationContext must be used within a ValidationProvider');
  }
  return context;
};

export { ValidationProvider, useValidationContext };
