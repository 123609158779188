import { useQuoteContext } from '@/components/shared/BowSidebar/QuoteContext';
import { RemainingValidations } from '@/components/shared/RemainingValidations';
import { Validator } from '@/components/shared/Validator';
import { useBow } from '@/services/bowServices';
import { useFleets } from '@/services/fleetsService';
import { useHateoasExecutorContext } from '@/services/HateoasExecutorContext';
import { useValidationContext } from '@/services/ValidationContext';
import React, {
  useEffect, useMemo, useState
} from 'react';
import styled from 'styled-components';
import { WarWeb } from '../../../war';
import { QuoteActions } from '../bows/details/quoteActionsMap';
import { PaginationBar } from '../common/PaginationBar';
import { steps } from './bowSteps';
import { useBowWizardStateContext } from './BowWizardStateContext';
import { FleetsPicker } from './components/FleetsPicker';
import {
  FleetAndVesselState, FleetState, VesselState
} from './components/models';
import { VesselPicker } from './components/VesselPicker';

const VesselContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 4rem;
`;

const Content = styled.div`
  width: 100%;
`;

export const VesselStep = () => {
  const { ids, setIds } = useQuoteContext();
  const { bowId, quoteId } = ids;

  const { isNonRateLead, setStepDone } = useBowWizardStateContext();
  const [vesselState, setVesselState] = useState<FleetAndVesselState>();

  const selectedRateLeadTypes: WarWeb.RateLeadType[] = isNonRateLead ? ['NonRateLead', 'Mixed'] : ['RateLead', 'Mixed'];
  const defaultFilters = { pageNumber: 1, pageSize: 15, selectedRateLeadTypes };
  const [filters, setFilters] = useState(defaultFilters);

  const { hateoasExecutor, loading } = useHateoasExecutorContext();
  const { errors } = useValidationContext();
  const { data: fleetsData, isLoading, error: fleetsError } = useFleets(filters, JSON.stringify(filters));

  const { data } = useBow(bowId);
  const selectedQuote = useMemo(
    () => data?.quotes.find((q: WarWeb.BowQuote) => q.id === quoteId),
    [data, quoteId]
  );

  useEffect(() => {
    if (data) {
      const quote = data.quotes.find((q: WarWeb.BowQuote) => q.id === quoteId);
      if (quote) {
        const { vessel } = quote;
        setVesselState({
          fleet: {
            id: vessel.fleet.id,
            name: vessel.fleet.name,
            clientId: data.client.id
          },
          vessel: {
            id: vessel.id,
            name: vessel.name
          }
        });
        const isDone = !!vessel && !(errors && errors.VesselId);
        setStepDone(isDone);
      }
    } else {
      setVesselState(undefined);
      setStepDone(false);
    }
  }, [data]);

  const setFleet = (fleet: FleetState) => {
    setVesselState({ fleet }); // overwrite intentional (start fresh)
    setStepDone(false);
  };

  const updateBackend = async (state: FleetAndVesselState) => {
    if (bowId && quoteId) {
      const setVesselRequest = {
        vesselId: state.vessel?.id,
        fleetId: state.fleet.id,
      };
      void hateoasExecutor(selectedQuote?.links, QuoteActions.SetVessel, setVesselRequest);
    } else {
      // no HATEOAS links, since no bow is created
      const staticLinks = [{
        action: 'CreateBow',
        href: 'bows',
        httpVerb: 'POST'
      }];

      const createBowRequest = {
        clientId: state.fleet.clientId,
        vesselId: state.vessel?.id,
        fleetId: state.fleet.id,
        ...(isNonRateLead && { bowType: 'NonRateLead' })
      };

      const updateResponse = await hateoasExecutor<WarWeb.Bow>(staticLinks, 'CreateBow', createBowRequest);
      if (updateResponse) setIds!({ bowId: updateResponse.data.id, quoteId: updateResponse.data.primaryQuote.id });
    }
  };

  const setVessel = async (vessel: VesselState) => {
    const newState: FleetAndVesselState = { ...vesselState!, vessel };
    setVesselState(newState);
    await updateBackend(newState);
  };

  const setFleetAndVessel = async (newState: FleetAndVesselState) => {
    setVesselState(newState);
    await updateBackend(newState);
  };

  useEffect(() => {
    if (fleetsData?.items.length === 1) {
      const fleetState: FleetState = {
        id: fleetsData.items[0].fleetId,
        name: fleetsData.items[0].fleetName,
        clientId: fleetsData.items[0].clientId
      };
      setFleet(fleetState);
    }
  }, [fleetsData]);

  return (
    <VesselContainer>

      <Validator keys={steps.filter(s => s.step !== 1)?.flatMap(u => u.warningsKeys.map(v => `/${v}/`))} hideWarnings />
      <RemainingValidations rootElement={selectedQuote} />

      <Content>
        {vesselState?.fleet ? (
          <VesselPicker
            fleetId={vesselState.fleet.id}
            clientId={vesselState.fleet.clientId}
            setVessel={setVessel}
            setFleet={setFleet}
            backendLoading={loading}
          />
        ) : (
          <>
            <FleetsPicker
              fleets={fleetsData?.items}
              setFleet={setFleet}
              setFleetAndVessel={setFleetAndVessel}
              loadStatus={{ loading: isLoading, success: !fleetsError }}
            />
            {fleetsData?.pagingMetadata && (
              <PaginationBar
                currentPage={filters.pageNumber}
                metadata={fleetsData.pagingMetadata}
                changePage={newPage => setFilters({ ...filters, pageNumber: newPage })}
              />
            )}
          </>
        )}
      </Content>
    </VesselContainer>
  );
};
