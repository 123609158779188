import { Button } from '@/components/shared/Button';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { useMe } from '@/services/meService';
import { deleteImpersonateUnderwriter, impersonateUnderwriter } from '@/services/underwriterImpersonationService';
import { theme } from '@/utility/theme';

export const UnderwriterImpersonator = () => {
  const { data: me } = useMe();

  const impersonateHandler = async () => {
    await impersonateUnderwriter();
    window.location.reload();
  };

  const revertRoleHandler = async () => {
    await deleteImpersonateUnderwriter();
    window.location.reload();
  };

  return (
    <ButtonGroup>
      <Button onClick={impersonateHandler} disabled={me?.role !== 'Admin'} background={theme.green}>Impersonate underwriter</Button>
      <Button onClick={revertRoleHandler} disabled={!me?.impersonated} background={theme.mediumRed}>Revert role</Button>
    </ButtonGroup>
  );
};
