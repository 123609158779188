import styled from 'styled-components';
import {
  Environment, getEnvironment, getEnvironmentName, getFeatureBranchName
} from '@/utility/environment';
import { theme } from '@/utility/theme';
import { FlexBox } from '@/components/shared/FlexBox';

const Container = styled(FlexBox)`
  @media (max-width: ${theme.breakpoints.tabletMax}) {
    display: none;
  }
  margin-bottom: -6px;
`;

const Header = styled.div`
    color: white;
    font-size: 20px;
`;

const Name = styled.span`
    text-transform: uppercase;
    font-weight: bold;
`;

const Branch = styled.div`
  color: orange;
  font-size: 13px;
  text-transform: uppercase;
`;

export const PortalName = () => {
  const isProd = getEnvironment() === Environment.Prod;
  const featureBranch = getFeatureBranchName();
  const fbShortened = featureBranch?.toLowerCase().replace('insiww', 'F');

  return (
    <Container flexDirection="column">
      <Header>
        {!isProd && (
          <Branch>{fbShortened || getEnvironmentName()}</Branch>
        )}
        <Name>War</Name>
      </Header>
    </Container>
  );
};
