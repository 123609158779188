import { InlineModalProps } from '@/components/modal/InlineModalProps';
import { ModalLayout } from '@/components/modal/ModalLayout';
import { Button } from '@/components/shared/Button';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { Checkbox } from '@/components/shared/Form/fields/Checkbox';
import {
  NoDataRow,
  renderTableHeaders, Table, TableCell, TableRow
} from '@/components/shared/Table/Table';
import { useVessels } from '@/services/vesselsService';
import { theme } from '@/utility/theme';
import { WarWeb } from '@/war';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useKnRTermsPageContext } from './context/KnRTermsPageContext';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 1rem 1rem;
  min-height: 30vh;
`;

const Selections = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 1em;
`;

const SelectionLabel = styled.label`
  padding: 1em;
  cursor: pointer;
  margin-right: 2rem;
  input {
    margin-left: 0.5rem;
  }
`;

const ButtonContainer = styled.div`
  padding-top: 2em;
`;

const tableHeaders = ['', 'Vessel', 'IMO', 'Type'];
type SelectionType = 'all' | 'manual';

const VesselRow = ({ vessel, toggleVessel, checked, selectionType }:
  {
    vessel: WarWeb.MarineMatch,
    toggleVessel: () => void,
    checked: boolean,
    selectionType?: SelectionType
  }) => (
    <TableRow onClick={toggleVessel}>
      <TableCell>
        <Checkbox name="" checked={checked || selectionType === 'all'} disabled={selectionType === 'all'} readOnly />
      </TableCell>
      <TableCell>{vessel.name}</TableCell>
      <TableCell>{vessel.imo}</TableCell>
      <TableCell>{vessel.vesselTypeName}</TableCell>
    </TableRow>
);

interface Props extends InlineModalProps {
  vesselIds?: number[];
  setVesselIds: (vesselIds?: number[]) => void;
}
export const VesselPickerModal = ({ vesselIds, setVesselIds, closeModal }: Props) => {
  const { fleetId } = useKnRTermsPageContext();
  const { data: vessels, isLoading, error } = useVessels(fleetId ? { fleetId, pageSize: 1000 } : undefined);
  const [selectedVessels, setSelectedVessels] = useState(vesselIds || []);
  const [selectionType, setSelectionType] = useState<SelectionType>(vesselIds?.some(Boolean) ? 'manual' : 'all');

  const toggleVessel = (id: number) => {
    if (selectedVessels.includes(id)) {
      const updatedVessels = selectedVessels.filter(entry => entry !== id);
      setSelectedVessels(updatedVessels);
    } else {
      const updatedVessels = selectedVessels?.concat(id) ?? [id];
      setSelectedVessels(updatedVessels);
    }
  };

  const saveAndClose = () => {
    setVesselIds(selectedVessels);
    closeModal();
  };

  const toggleAllEntries = () => {
    const allVesselIds = vessels?.items.map(v => v.vesselId!) || [];
    if (allVesselIds.every(x => selectedVessels.includes(x))) setSelectedVessels([]);
    else setSelectedVessels(allVesselIds);
  };

  const selectionTypeChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as SelectionType;
    setSelectionType(value);
    if (value === 'all') {
      setSelectedVessels([]);
    }
  };

  const options = {
    title: 'Select vessels'
  };

  return (
    <ModalLayout closeModal={closeModal} options={options}>
      <Container>
        <div>
          <Selections>
            <SelectionLabel htmlFor="type-all">
              All, including additions to fleet
              <input type="radio" id="type-all" name="type" value="all" checked={selectionType === 'all'} onChange={selectionTypeChangeHandler} />
            </SelectionLabel>
            <SelectionLabel htmlFor="type-manual">
              Manual select, excluding additions to fleet
              <input type="radio" id="type-manual" name="type" value="manual" checked={selectionType === 'manual'} onChange={selectionTypeChangeHandler} />
            </SelectionLabel>
          </Selections>
        </div>
        <Table columns={tableHeaders.length} layout="0.8fr 3fr 1fr 2fr" maxHeight="600px">
          {renderTableHeaders(tableHeaders)}
          {!isLoading && vessels?.items && vessels.items.length > 0
            ? vessels?.items.map(v => (
              <VesselRow vessel={v}
                key={v.id}
                checked={selectedVessels.includes(v.vesselId!)}
                selectionType={selectionType}
                toggleVessel={() => toggleVessel(v.vesselId!)} />
            ))
            : <NoDataRow loadStatus={{ loading: isLoading, success: !error }} />}
        </Table>

        <ButtonContainer>
          <ButtonGroup justify="space-between">
            <Button onClick={toggleAllEntries} disabled={selectionType === 'all'} background={theme.lightGray}>Toggle all</Button>
            <ButtonGroup justify="flex-end">
              <Button onClick={closeModal} background={theme.lightGray}>Cancel</Button>
              <Button onClick={saveAndClose} background={theme.green}>Okay</Button>
            </ButtonGroup>
          </ButtonGroup>
        </ButtonContainer>
      </Container>
    </ModalLayout>
  );
};
