import { DestinationOption } from '@/components/pages/bows/details/destinationOption';
import { useHateoasExecutorContext } from '@/services/HateoasExecutorContext';
import { Insify, WarWeb } from '@/war';
import { Check } from '@instech/icons';
import React from 'react';
import styled from 'styled-components';
import { QuoteActions } from '../../../pages/bows/details/quoteActionsMap';
import { HateoasHandler } from '../../HateoasHandler';
import {
  Table, TableCell, TableHeader, TableRow
} from '../../Table/Table';
import { Validator } from '../../Validator';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 1em;
`;

const KnrRebateLevels = [
  { id: -5, name: '≥ 12M', minimumCover: 12000000 },
  { id: -4, name: '≥ 10M', minimumCover: 10000000 },
  { id: -3, name: '≥ 7.5M', minimumCover: 7500000 },
  { id: -2, name: '≥ 5M', minimumCover: 5000000 },
  { id: -1, name: '≥ 2.5M', minimumCover: 2500000 }
];

interface ExternalKidnapRansomCoverageProps {
  destination: DestinationOption;
  config: Insify.War.Bows.Core.Models.KnrDetails.KnrDetails;
}
export const ExternalKidnapRansomCoverage = ({ destination, config }: ExternalKidnapRansomCoverageProps) => {
  const { hateoasExecutor } = useHateoasExecutorContext();

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const setKnrRequest: WarWeb.SetExternalKnrRequest = {
      sumInsured: +e.target.value
    };

    void hateoasExecutor(config.links, QuoteActions.SetExternalKnr, setKnrRequest, 'calculations');
  };

  const errorKey = `coveredAreas[${destination.coveredAreaIndex}].Itinerary[${destination.destinationIndex}].Configuration.Knr`;

  return (
    <Container>
      <Validator keys={[errorKey]}>
        <Table columns={2} layout="4fr 2fr" striped>
          <TableHeader span={2}>Specify External K&R Coverage</TableHeader>
          {KnrRebateLevels.map(c => (
            <TableRow key={c.id}>
              <TableCell fit>{c.name}</TableCell>
              <TableCell>
                <HateoasHandler
                  links={config.links}
                  action={QuoteActions.SetExternalKnr}
                  editVariant={(
                    <input type="radio"
                      name="externalKnrCoverage"
                      value={c.minimumCover}
                      checked={c.minimumCover === config.externalSumInsured?.value}
                      onChange={changeHandler}
                    />
                  )}
                  viewVariant={c.minimumCover === config.externalSumInsured?.value ? <Check /> : ''}
                />
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </Validator>

    </Container>
  );
};
