import { DestinationOption } from '@/components/pages/bows/details/destinationOption';
import { QuoteActions } from '@/components/pages/bows/details/quoteActionsMap';
import { DirtyableValue } from '@/models/utils/DirtyableValue';
import { useHateoasExecutorContext } from '@/services/HateoasExecutorContext';
import { isOnlyOption } from '@/utility/arrayHelpers';
import {
  formatPercentage, getInputString, tryParseFloat
} from '@/utility/formatter';
import { MaxNumberTimeValue } from '@/utility/globals';
import { WarWeb } from '@/war';
import { Check } from '@instech/icons';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NumberField } from '../../Form/fields/NumberField';
import { Dropdown } from '../../Form/FormFields';
import { checkLink, HateoasHandler } from '../../HateoasHandler';
import { HttpActionProps } from '../../HttpAction';
import { SpanCell } from '../../Table/core/Components';
import {
  Table, TableCell, TableHeader, TableRow
} from '../../Table/Table';
import { Validator } from '../../Validator';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 1em;
`;

const Wrapper = styled.div`
    display: flex;
    gap: 0.3em;
    align-items: center;
  `;

interface KidnapRansomCoverageTableProps {
  destination: DestinationOption;
  details?: WarWeb.KnrOptions;
  options?: WarWeb.AvailableKnrOptions;
  links?: HttpActionProps[];
}
export const KidnapRansomCoverageTable = ({ destination, details, options, links }: KidnapRansomCoverageTableProps) => {
  const { hateoasExecutor, loading, lastRequest } = useHateoasExecutorContext();

  const [lohRate, setLohRate] = useState<DirtyableValue<string>>({
    value: details?.lossOfHireRate?.toString()
  });

  const [state, setState] = useState<Partial<WarWeb.KnrOptions | undefined>>(details);

  useEffect(() => {
    if (details) {
      setState(details);
      setLohRate({ value: details.lossOfHireRate?.toString(), isDirty: false });
    }
  }, [details]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      (e.target as HTMLInputElement).blur();
    }
  };

  const submitState = (newState: Partial<WarWeb.KnrOptions>) => {
    setState(newState);
    if (!newState) return;

    const setKnrRequest: WarWeb.SetDestinationKnrRequest = {
      voyageType: newState.voyageType,
      armedGuardsIncluded: newState.armedGuardsIncluded,
      lossOfHireIncluded: newState.lossOfHireIncluded,
      lossOfHireDays: newState.lossOfHireDays
    };
    void hateoasExecutor(links, QuoteActions.SetKnr, setKnrRequest, 'kidnapRansom');
  };

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = e.target;

    if (name === 'lossOfHireRate') {
      setLohRate({ value, isDirty: true });
      return;
    }

    let newState;
    if (type === 'radio' && (value === 'on' || value === 'off')) {
      newState = { ...state, [name]: value === 'on' };
    } else {
      newState = { ...state, [name]: value };
    }
    submitState(newState);
  };

  const selectHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    submitState({ ...state, [name]: value });
  };

  const submitLohRate = () => {
    setLohRate({ ...lohRate, isDirty: false });
    void hateoasExecutor(links, QuoteActions.SetKnrLohRate, { rate: tryParseFloat(lohRate.value) }, 'kidnapRansom');
  };

  const showLossOfHireDays = !!state?.lossOfHireIncluded;
  const errorKey = `coveredAreas[${destination.coveredAreaIndex}].Itinerary[${destination.destinationIndex}].Configuration.Knr`;

  const armedGuardsNotPossible = isOnlyOption(options?.armedGuardsOptions, false);
  const lossOfHireNotPossible = isOnlyOption(options?.lossOfHireOptions, false);

  return (
    <Container>

      <Validator keys={[errorKey]} hideWarnings />

      <Container>
        <Validator keys={[`${errorKey}.SelectedOptions.VoyageType`]}>
          <HateoasHandler
            links={links}
            action={QuoteActions.SetKnr}
            editVariant={(
              <Dropdown
                name="voyageType"
                label="Voyage"
                disabled={loading}
                simplify
                placeholder="Please choose voyage type"
                selectedValue={details?.voyageType}
                changeHandler={selectHandler}
                options={options?.voyageOptions.map(n => ({ name: n, value: n }))}
              />
            )}
            viewVariant={<strong>{state?.voyageType}</strong>}
          />

        </Validator>
      </Container>

      {(!armedGuardsNotPossible || !lossOfHireNotPossible) && (
        <Table layout="2fr 1.2fr 1.2fr" columns={3}>
          <TableHeader />
          <TableHeader fit={false}>Included</TableHeader>
          <TableHeader fit={false}>Excluded</TableHeader>

          {!armedGuardsNotPossible && (
            <>
              <TableRow>
                <TableCell fit textWrap>Armed Guards</TableCell>
                <TableCell center>
                  <HateoasHandler
                    links={links}
                    action={QuoteActions.SetKnr}
                    editVariant={(
                      <input type="radio"
                        name="armedGuardsIncluded"
                        value="on"
                        disabled={loading || isOnlyOption(options?.armedGuardsOptions, false)}
                        checked={state?.armedGuardsIncluded || isOnlyOption(options?.armedGuardsOptions, true)}
                        onChange={changeHandler}
                      />
                    )}
                    viewVariant={state?.armedGuardsIncluded ? <Check /> : ''}
                  />
                </TableCell>
                <TableCell center>
                  <HateoasHandler
                    links={links}
                    action={QuoteActions.SetKnr}
                    editVariant={(
                      <input type="radio"
                        name="armedGuardsIncluded"
                        value="off"
                        disabled={loading || isOnlyOption(options?.armedGuardsOptions, true)}
                        checked={state?.armedGuardsIncluded === false || isOnlyOption(options?.armedGuardsOptions, false)}
                        onChange={changeHandler}
                      />
                    )}
                    viewVariant={state?.armedGuardsIncluded === false ? <Check /> : ''}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <SpanCell height="1" start={1} end={-1}>
                  <Validator keys={[`${errorKey}.SelectedOptions.ArmedGuardsIncluded`]} />
                </SpanCell>
              </TableRow>
            </>
          )}

          {!lossOfHireNotPossible && (
            <>
              <TableRow>
                <TableCell fit textWrap>Loss of Hire</TableCell>
                <TableCell center>
                  <HateoasHandler
                    links={links}
                    action={QuoteActions.SetKnr}
                    editVariant={(
                      <input type="radio"
                        name="lossOfHireIncluded"
                        value="on"
                        disabled={loading || isOnlyOption(options?.lossOfHireOptions, false)}
                        checked={state?.lossOfHireIncluded || isOnlyOption(options?.lossOfHireOptions, true)}
                        onChange={changeHandler}
                      />
                    )}
                    viewVariant={state?.lossOfHireIncluded ? <Check /> : ''}
                  />
                </TableCell>
                <TableCell center>
                  <HateoasHandler
                    links={links}
                    action={QuoteActions.SetKnr}
                    editVariant={(
                      <input type="radio"
                        name="lossOfHireIncluded"
                        value="off"
                        disabled={loading || isOnlyOption(options?.lossOfHireOptions, true)}
                        checked={state?.lossOfHireIncluded === false || isOnlyOption(options?.lossOfHireOptions, false)}
                        onChange={changeHandler}
                      />
                    )}
                    viewVariant={state?.lossOfHireIncluded === false ? <Check /> : ''}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <SpanCell height="1" start={1} end={-1}>
                  <Validator keys={[`${errorKey}.SelectedOptions.LossOfHireIncluded`]} />
                </SpanCell>
              </TableRow>

              {showLossOfHireDays && (
                <>
                  <TableRow>
                    <TableCell>Loss of Hire days</TableCell>
                    <TableCell span={2}>

                      <HateoasHandler
                        links={links}
                        action={QuoteActions.SetKnr}
                        editVariant={(
                          <Wrapper>
                            <Dropdown
                              name="lossOfHireDays"
                              compact
                              disabled={loading}
                              simplify
                              changeHandler={selectHandler}
                              placeholder="-"
                              selectedValue={`${details?.lossOfHireDays}`}
                              options={options?.lossOfHireDays?.filter(u => u !== 0).map(n => ({ name: `${n}`, value: `${n}` }))}
                            />
                            <span>days</span>
                          </Wrapper>
                        )}
                        viewVariant={(
                          <strong>
                            {state?.lossOfHireDays}
                            {' '}
                            days
                          </strong>
                        )}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <SpanCell height="1" start={1} end={-1}>
                      <Validator keys={[`${errorKey}.SelectedOptions.LossOfHireDays`]} />
                    </SpanCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Loss of Hire rate</TableCell>
                    <TableCell span={2}>

                      <HateoasHandler
                        links={links}
                        action={QuoteActions.SetKnrLohRate}
                        editVariant={(
                          <NumberField
                            maxValue={MaxNumberTimeValue}
                            name="lossOfHireRate"
                            disabled={loading}
                            step={0.01}
                            onKeyDown={handleKeyDown}
                            postfix="%"
                            value={getInputString(lohRate.value)}
                            dirty={lohRate.isDirty}
                            onChange={changeHandler}
                            onBlur={submitLohRate}
                          />
                        )}
                        viewVariant={(
                          <strong>
                            {formatPercentage(tryParseFloat(lohRate?.value))}
                          </strong>
                        )}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <SpanCell height="1" start={1} end={-1}>
                      <Validator keys={['Rate']} isRelevant={!!checkLink(links, lastRequest)} />
                    </SpanCell>
                  </TableRow>
                </>
              )}
            </>
          )}
        </Table>
      )}
    </Container>
  );
};
