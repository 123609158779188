import React, { useState } from 'react';
import { useExposure } from '@/services/bowServices';
import useDebounce from '@/hooks/useDebouncedValue';
import { PageHeader } from '@/components/layout/PageHeader';
import styled from 'styled-components';
import { Box } from '@/components/shared/Box';
import { ExposureTable } from './core/ExposureTable';
import { PaginationBar } from '../common/PaginationBar';

const ExposurePageContainer = styled.div``;

const headers = [
  { title: 'BOW ID' },
  { title: 'Client ref.',
    propertyName: 'ClientReference',
    parameterName: 'ClientReference' },
  { title: 'Status',
    propertyName: 'Status',
    parameterName: 'SelectedStatus' },
  { title: 'Fleet',
    propertyName: 'FleetName',
    parameterName: 'SelectedFleets' },
  { title: 'Vessel',
    propertyName: 'VesselName',
    parameterName: 'SelectedVessels' },
  { title: 'Type',
    propertyName: 'VesselType',
    parameterName: 'SelectedVesselTypes' },
  { title: 'Covered area',
    propertyName: 'CoveredAreas',
    parameterName: 'CoveredArea' },
  { title: 'Port',
    propertyName: 'Ports',
    parameterName: 'Port' },
  { title: 'Entry' },
  { title: 'Exit' },
  { title: '' }
];

export const ExposurePage = () => {
  const [filters, setFilters] = useState({ pageNumber: 1, pageSize: 15, Status: 3 });
  const debouncedFilters = useDebounce(filters, 500);

  const { data } = useExposure(debouncedFilters);

  return (
    <ExposurePageContainer>
      <PageHeader header="Exposure" />
      <Box>
        <ExposureTable headers={headers} bows={data} />
      </Box>

      <PaginationBar
        currentPage={filters.pageNumber}
        metadata={data?.pagingMetadata}
        changePage={newPage => setFilters({ ...filters, pageNumber: newPage })}
    />
    </ExposurePageContainer>
  );
};
