/* eslint-disable no-bitwise */
export enum TargetType {
  Unspecified = 0,
  Standard = 1 << 0,
  VesselTypeSpecific = 1 << 1,
  VesselSpecific = 1 << 2,
  Trade = 1 << 3,
}

export enum TargetTypeAsString {
  Unspecified = 'Unspecified',
  Standard = 'Standard',
  VesselTypeSpecific = 'VesselTypeSpecific',
  VesselSpecific = 'VesselSpecific',
  Trade = 'Trade'
}

export const convertTargetTypeFromAPIToEnum = (input?: string) => {
  const stringSplit = input?.split(', ') ?? [];
  const enumArray: TargetType[] = [];
  stringSplit.forEach(el => {
    const tempString = el as TargetTypeAsString;
    enumArray.push(TargetType[tempString]);
  });

  const result = enumArray.reduce(((prev, curr) => curr + prev), TargetType.Unspecified);
  return result;
};

export const targetTypeToFriendlyString = (input: TargetType) => {
  switch (input) {
    case TargetType.Standard:
      return 'Default';
    case TargetType.VesselTypeSpecific:
      return 'Vessel Type';
    case TargetType.VesselSpecific:
      return 'Vessel Specific';
    case TargetType.Trade:
      return TargetTypeAsString.Trade;
    case TargetType.Standard | TargetType.VesselTypeSpecific:
      return 'Default, Vessel Type';
    case TargetType.VesselSpecific | TargetType.Trade:
      return 'Vessel Specific, Trade';
    default:
      return TargetTypeAsString.Unspecified;
  }
};

// Determine if the target type is capable of having days covered/sum insured alternatives
export const targetTypeIsAlternativeCapable = (targetType: TargetType) =>
  targetType === TargetType.Standard || targetType === TargetType.VesselTypeSpecific;
