import { PageHeader } from '@/components/layout/PageHeader';
import { Box } from '@/components/shared/Box';
import { theme } from '@/utility/theme';
import { UserSingle } from '@instech/icons';
import styled from 'styled-components';

const BoxContent = styled.div`
    padding: 2rem;
`;
const Text = styled.p`
  font-size: 14px;
  margin-bottom: 27px;
`;

const Title = styled.h3`
  font-size: 18px;
`;

const Email = styled.a`
  text-decoration: underline;
  font-size: 14px;
  margin-top: 5px;
`;

export const ProfileSupport = () => (
  <>
    <PageHeader header="Support" />
    <Box header="How to contact support" icon={<UserSingle />} highlight={theme.marineBlue}>
      <BoxContent>
        <Text>Please refer to the information below to ensure that you get help from the correct department or person to better assist you.</Text>

        <Title>For questions regarding a specific BOW, or how to use the portal</Title>
        <Text>Please contact your usual underwriter at Norwegian Hull Club</Text>

        <Title>To report a bug, suggest a new feature or suggest an improvement</Title>
        <Text>
          Please contact
          {' '}
          <Email href="https://www.norclub.com/contact?a=people&q=anders%20dahl">Anders Dahl</Email>
          {' '}
          or
          {' '}
          <Email href="https://www.norclub.com/people/cph">Chris-Petter Haukedal</Email>
          {' '}
          at Norwegian Hull Club
        </Text>
        <Text>
          When contacting us about a problem you are having, please consider providing the following information
          (when relevant) so we can better assist you:
        </Text>
        <ul>
          <li>A sensible step-by-step description of the issue</li>
          <li>The browser and which device you are using</li>
          <li>How frequently the issue occurs</li>
          <li>Any screenshots to clarify your issue or question</li>
          <li>The BOW number, policy year and vessel name if the issue is specifically related to one particular assignment</li>
        </ul>
      </BoxContent>
    </Box>
  </>
);
