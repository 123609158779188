import { WarningsContainer } from '@/components/shared/BowSidebar/Warnings';
import { ValidationMessages } from '@/models/ValidationMessages';
import { ErrorProvider } from '@/services/ErrorContext';
import { useValidationContext } from '@/services/ValidationContext';
import { concatNullsafe } from '@/utility/arrayHelpers';
import { fieldNameFilter, keyToLabel } from '@/utility/errorHelpers';
import { formatDecimalsInText } from '@/utility/formatter';
import { isEmpty, remove } from '@/utility/objectHelpers';
import { WarningNotification } from '@instech/components';
import React, {
  FC, useCallback, useEffect, useState
} from 'react';
import { ErrorField, WarningField } from './Form/core/Components';

interface ValidatorProps {
  keys?: (string | undefined)[];
  isRelevant?: boolean;
  formatter?: (m: string) => string;
  hasDarkBackground?: boolean;
  hideErrors?: boolean;
  popup?: boolean;
  summary?: boolean;
  hideWarnings?: boolean;
  onlyWarnings?: boolean;
  rootElement?: any;
}
export const Validator: FC<ValidatorProps> = ({
  keys,
  isRelevant = true, hasDarkBackground = false,
  formatter,
  hideErrors = false,
  onlyWarnings = false,
  popup = false,
  summary = false,
  hideWarnings = false,
  rootElement,
  children
}) => {
  const { errors, seenErrors, dismissedErrors, setSeenErrors, warnings, seenWarnings, dismissedWarnings, setSeenWarnings } = useValidationContext();
  const [errorText, setErrorText] = useState<string | undefined>();
  const [errorKeys, setErrorKeys] = useState<string[]>([]);
  const [warningText, setWarningText] = useState<string | undefined>();
  const [warningKeys, setWarningKeys] = useState<string[]>([]);

  const [warningMessages, setWarningMessages] = useState<ValidationMessages>(); // TODO: Replace warningText with this?
  const [errorMessages, setErrorMessages] = useState<ValidationMessages>(); // TODO: Replace errorText with this?

  useEffect(() => {
    setErrorText(undefined);
    setErrorKeys([]);
    setWarningKeys([]);
    setWarningText(undefined);
    setWarningMessages(undefined);
    setErrorMessages(undefined);
  }, [errors, warnings]);

  const isRegex = (s: string) => s.startsWith('/') && s.endsWith('/');

  const checkValidation = useCallback((
    messages: ValidationMessages | undefined,
    checkKeys: (string | undefined)[] | undefined,
    seen: string[],
    setSeen: React.Dispatch<React.SetStateAction<string[]>>,
    dismissed: string[],
    setter: React.Dispatch<React.SetStateAction<string | undefined>>,
    messagesSetter: React.Dispatch<React.SetStateAction<ValidationMessages | undefined>>,
    matchedKeysSetter: React.Dispatch<React.SetStateAction<string[]>>
  ) => {
    if (!checkKeys || checkKeys.length === 0) return;
    if (!messages) return;

    let seenLocal: string[] = [...seen || []];
    const matched: string[] = [];
    let matchedMessages: ValidationMessages; // TODO: Replace matched with this?

    // const unseenMessages = remove(concatNullsafe(seen, dismissed), messages);
    const unseenMessages = remove(dismissed, messages); // should we maybe also not remove dismissed here, simply show ANY warnings (when inline)??

    if (unseenMessages && checkKeys && checkKeys.length > 0) {
      checkKeys?.forEach(k => {
        if (k !== undefined) {
          const filter = isRegex(k) ? k.slice(1, -1) : k;
          const filtered = fieldNameFilter(unseenMessages, filter, isRegex(k))
            // https://stackoverflow.com/questions/38750705/filter-object-properties-by-key-in-es6
            .reduce((obj: any, key: string) => {
              // eslint-disable-next-line no-param-reassign
              obj[key] = unseenMessages[key];
              return obj;
            }, {});

          if (!isEmpty(filtered)) {
            // messagesSetter(prev => ({ ...prev, ...filtered }));
            Object.keys(filtered).forEach(msgKey => { // in case k (pattern) was a regex!
              if (!seenLocal?.includes(msgKey)) seenLocal = concatNullsafe(seenLocal, [msgKey]);

              // Prevent duplicated validation messages
              // One validator (field) can "subscribe" to several warning keys, and these could return identical warning messages
              // In this case, we should only show the message once (on one and same field)
              unseenMessages[msgKey]?.forEach((m: string) => {
                if (!matched.includes(m)) {
                  matched.push(m);
                  matchedMessages = { ...matchedMessages, [msgKey]: filtered[msgKey] };
                }
              });
            });
          }
        }
      });

      messagesSetter(prev => ({ ...prev, ...matchedMessages }));
      setter(matched?.join('. '));
      setSeen(prev => [...new Set([...prev, ...seenLocal])]);
      matchedKeysSetter(seenLocal);
    }
  }, []);

  useEffect(() => {
    if (isRelevant && !hideWarnings && !onlyWarnings) {
      checkValidation(errors, keys, seenErrors, setSeenErrors, dismissedErrors, setErrorText, setErrorMessages, setErrorKeys);
    }
    checkValidation(warnings, keys, seenWarnings, setSeenWarnings, dismissedWarnings, setWarningText, setWarningMessages, setWarningKeys);
  }, [errors, dismissedErrors, warnings, dismissedWarnings]);

  const formatMessage = (m: string) => formatter ? formatter(m) : m;

  if (hideWarnings) return null;

  if (popup) {
    return (
      <WarningsContainer warningsContent={warningMessages} />
    );
  }

  if (summary) {
    return (
      <>
        {children}
        {warningMessages && Object.keys(warningMessages).map(key => (
          <WarningNotification
            size="medium"
            key={key}
            headingText={rootElement ? keyToLabel(rootElement, key, 0) : key}
            descriptionText={warningMessages[key].map((m: string) => formatDecimalsInText(m)).join(', ')} />
        ))}
      </>
    );
  }

  return (
    <ErrorProvider hasError={!!errorText} errorKeys={errorKeys} errorMessage={errorText}>
      {children}
      {errorText && !hideErrors && <ErrorField error={formatMessage(errorText)} hasDarkBackground={hasDarkBackground} />}
      {!errorText && !hideWarnings && warningText && <WarningField warnings={[formatMessage(warningText)]} hasDarkBackground={hasDarkBackground} />}
    </ErrorProvider>
  );
};
