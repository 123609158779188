import { useAgreementDetailsContext } from '@/components/pages/agreementDetails/AgreementDetailsContext';
import { AgreementSummaryTarget, useAgreementsPageContext } from '@/components/pages/agreements/AgreementsPageContext';
import { useState } from 'react';
import { useTabNames } from './useTabNames';
import { useActiveTab } from './useActiveTab';
import { useHasUnsavedChanges } from './useHasUnsavedChanges';
import { tabIsConditions } from '../utils';

const getCoverDaysFromTabName = (tab: string): number => {
  const preSplit = tab.split('-')[1];
  const days = preSplit.split('days')[0].trim();
  return parseInt(days);
};

export const useTabs = () => {
  const { agreementSummaryTarget, setAgreementSummaryTarget } = useAgreementsPageContext();
  const { mutateAgreement } = useAgreementDetailsContext();
  const tabs = useTabNames();
  const { activeTab, setActiveTab } = useActiveTab(tabs);
  const [nextTabIndex, setNextTabIndex] = useState<number>(0);
  const [showModal, setShowModal] = useState(false);
  const hasUnsavedChanges = useHasUnsavedChanges();

  const changeTab = (tabIndex: number) => {
    const tabName = tabs[tabIndex];
    if (tabIsConditions(tabName)) {
      const newTarget: AgreementSummaryTarget = { ...agreementSummaryTarget, daysCoveredGroup: undefined, minimumSumInsured: undefined };
      setAgreementSummaryTarget(newTarget);
      setActiveTab(tabs[tabIndex]);
      return;
    }

    const newTarget: AgreementSummaryTarget = { ...agreementSummaryTarget, daysCoveredGroup: getCoverDaysFromTabName(tabName), minimumSumInsured: 0 };
    setAgreementSummaryTarget(newTarget);
    setActiveTab(tabs[tabIndex]);
    mutateAgreement();
  };

  const handleTabClick = (tabIndex: number) => {
    if (hasUnsavedChanges) {
      setNextTabIndex(tabIndex);
      setShowModal(true);
      return;
    }
    changeTab(tabIndex);
  };

  const changeToNextTab = () => changeTab(nextTabIndex);

  return { tabs, activeTab, handleTabClick, showModal, setShowModal, changeToNextTab };
};
