interface HttpResponse {
  status: number;
  code?: number;
  message?: string;
}
export class HttpError extends Error {
  response: HttpResponse;

  constructor(status: number, code?: number, message?: string) {
    super();
    this.response = {
      status,
      code,
      message
    };
  }
}
