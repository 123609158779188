import { ChildrenProps } from '@/models/utils/ChildrenProps';
import { getDifferenceSymmetric } from '@/utility/array';
import * as React from 'react';

interface BulkChangeAreasProviderProps {
  state: BulkChangeAreasTargets;
  dispatch: React.Dispatch<Action>;
}
const BulkChangeAreasContext = React.createContext<BulkChangeAreasProviderProps | undefined>(undefined);

interface BulkChangeAreasTargets {
  checkedPorts: number[];
  selectedRegion?: number;
  selectedArea?: number;
}

type Action =
  | { type: 'SetCheckedPorts' | 'Revert'; payload: number[]; }
  | { type: 'TogglePort' | 'SetSelectedRegion' | 'SetSelectedArea'; payload: number; };

const reducer = (state: BulkChangeAreasTargets, action: Action): BulkChangeAreasTargets => {
  switch (action.type) {
    case 'SetCheckedPorts': {
      return { ...state, checkedPorts: action.payload };
    }
    case 'TogglePort': {
      return { ...state, checkedPorts: getDifferenceSymmetric(state.checkedPorts, [action.payload]) };
    }
    case 'SetSelectedRegion': {
      return { ...state, selectedRegion: action.payload, selectedArea: undefined };
    }
    case 'SetSelectedArea': {
      return { ...state, selectedArea: action.payload };
    }
    case 'Revert': {
      return { ...state, checkedPorts: action.payload };
    }
    default:
      throw new Error('Unhandled action type');
  }
};

const BulkChangeAreasProvider = ({ children }: ChildrenProps) => {
  const [state, dispatch] = React.useReducer(reducer, { checkedPorts: [] });

  const values = React.useMemo(() => ({
    state, dispatch
  }), [state]);

  return (
    <BulkChangeAreasContext.Provider value={values}>
      {children}
    </BulkChangeAreasContext.Provider>
  );
};

const useBulkChangeAreasContext = () => {
  const context = React.useContext(BulkChangeAreasContext);

  if (context === undefined) {
    throw new Error('useBulkChangeAreasContext must be used within a BulkChangeAreasProvider');
  }
  return context;
};

export { BulkChangeAreasProvider, useBulkChangeAreasContext };
