import React from 'react';
import { isBroker } from '@/components/routing/roles';
import { Button } from '@/components/shared/Button';
import { Pen } from '@instech/icons';
import styled, { css } from 'styled-components';
import { AgreementViewMode, useAgreementDetailsContext } from '../agreementDetails/AgreementDetailsContext';
import { useIsBulkEditing } from '../agreements/useIsBulkEditing';
import { useAgreementSelectionContext } from '../agreementDetails/AgreementSelectionContext';

const HeaderNameWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const AgreementNameWrapper = styled.div<{ isEmpty?: boolean }>`
  display: flex;
  padding-left: 0.5rem;
  align-items: flex-end;

  ${props =>
    props.isEmpty &&
    css`
      font-weight: normal;
      color: ${props.theme.marineBlue50};
    `}
`;

interface IHeaderContent {
  setIsEditTradeAgreementNameOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const HeaderContent = ({ setIsEditTradeAgreementNameOpen }: IHeaderContent) => {
  const { viewMode, targetsSummary, agreementName } = useAgreementDetailsContext();
  const isBulkEdit = useIsBulkEditing();
  const isCreating = viewMode === AgreementViewMode.Create;
  const isEdit = viewMode !== AgreementViewMode.Inspect;
  const editModeHeader = isBulkEdit ? `Edit ${targetsSummary?.agreementIds.length} Agreements` : 'Edit Agreement';
  const inspectModeHeader = isBulkEdit
    ? `Inspect ${targetsSummary?.agreementIds.length} Agreements`
    : 'Inspect Agreement';
  const header = isCreating ? 'Create new' : `${isEdit ? editModeHeader : inspectModeHeader}`;

  const { isTrade } = useAgreementSelectionContext();

  return (
    <>
      {isBroker() ? (
        targetsSummary?.areas[0]?.name
      ) : (
        <HeaderNameWrapper>
          {header}
          {isTrade && (
            <AgreementNameWrapper isEmpty={!agreementName}>
              {agreementName ? ` - ${agreementName}` : isEdit ? ' - Add a Name' : ''}
            </AgreementNameWrapper>
          )}
          {isTrade && isEdit && <Button icon={<Pen />} onClick={() => setIsEditTradeAgreementNameOpen(true)} />}
        </HeaderNameWrapper>
      )}
    </>
  );
};
