import { Routes } from '@/components/routing/routes';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(Link)`
    display: flex;
`;

const StyledImage = styled.img<{ scrolled: boolean }>`
  height: ${props => props.scrolled ? '32px' : '64px'};
  width: 130px;
`;

export const Brand = ({ scrolled = false }) => (
  <StyledLink to={Routes.base}>
    <StyledImage scrolled={scrolled} src={scrolled ? 'images/NHC_logo_symbol.svg' : 'images/NHC_logo.svg'} alt="NHC logo" />
  </StyledLink>
);
