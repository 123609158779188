import { useAgreementsPageContext } from '@/components/pages/agreements/AgreementsPageContext';
import { getActiveTabName, setActiveTabName } from '@/utility/sessionStore';
import { useEffect, useState } from 'react';
import { tabIsConditions } from '../utils';

export const useActiveTab = (tabs: string[]) => {
  const [state, setState] = useState<string| undefined>(getActiveTabName());
  const { agreementSummaryTarget: { daysCoveredGroup } } = useAgreementsPageContext();

  useEffect(() => {
    if (state === undefined && tabs.length !== 0) {
      setState(tabs[0]);
    }
  }, [tabs, state]);

  const setActiveTab = (tabName: string) => {
    setState(tabName);
    setActiveTabName(tabName);
  };

  // When editing the days covered value of the current tab, we want to set the active tab to the new days covered value
  useEffect(() => {
    if (daysCoveredGroup === undefined) return;
    if (state && tabIsConditions(state)) return;
    const newActiveTarget = tabs.find(tab => tab.includes(`${daysCoveredGroup} days`));
    if (newActiveTarget) {
      setActiveTab(newActiveTarget);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daysCoveredGroup]);

  return { activeTab: state, setActiveTab };
};
