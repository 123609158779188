import React, { FC } from 'react';
import { Prompt } from 'react-router-dom';

interface Props {
  dirty: boolean;
}
// From https://github.com/formium/formik/issues/1657#issuecomment-509754221
export const PromptIfDirty: FC<Props> = ({ dirty }) => (
  <Prompt
    when={dirty}
    message="Are you sure you want to leave? You have unsaved changes."
  />
);
