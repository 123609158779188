import { useSubareas } from "@/services/areasService";
import { EditExcludedAreasState } from "./types";

/**
 * Hook to get the next index for a new custom area. Takes into account new (unsaved) and existing areas.
 * Used to avoid duplicate names.
 */
export const useNewAreaIndex = (state: EditExcludedAreasState) => {
  const { data: areas } = useSubareas(state.regionId);
  const numExistingCustomAreas = areas?.filter(area => area.areaType === 'Ocean')?.length ?? 0;
  const numNewCustomAreas = state.oceanAreaNames?.length ?? 0;
  return (numExistingCustomAreas + numNewCustomAreas + 1) * -1;
}
