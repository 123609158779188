import React, { useMemo } from 'react';
import { WarWeb } from '@/war';
import { useBow } from '@/services/bowServices';

export const useQuote = (bowId?: string, quoteId?: string) => {
  const { data } = useBow(bowId);
  const selectedQuote: WarWeb.BowQuote | undefined = useMemo(
    () => data?.quotes.find((q: WarWeb.BowQuote) => q.id === quoteId),
    [quoteId, data?.quotes]
  );
  return selectedQuote;
};
