import { openErrorModal } from '@/components/error/ErrorModal';
import { PageHeader } from '@/components/layout/PageHeader';
import { useModalContext } from '@/components/modal/ModalContext';
import { isBroker, isInternalUser } from '@/components/routing/roles';
import { useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { Method, AxiosResponse, AxiosRequestConfig } from 'axios';
import { useBow } from '@/services/bowServices';
import { theme } from '@/utility/theme';
import { Routes } from '@/components/routing/routes';
import { CalculationsBox } from '@/components/shared/Bow/Calculations/CalculationsBox';
import { CoverageBox } from '@/components/shared/Bow/Coverage/CoverageBox';
import { KidnapRansomBox } from '@/components/shared/Bow/KidnapRansom/KidnapRansomBox';
import { useQuoteContext } from '@/components/shared/BowSidebar/QuoteContext';
import { Box } from '@/components/shared/Box';
import { Button } from '@/components/shared/Button';
import { Checkbox } from '@/components/shared/Form/fields/Checkbox';
import { HttpActionProps } from '@/components/shared/HttpAction';
import { ProgressBar } from '@/components/shared/ProgressBar';
import { RemainingValidations } from '@/components/shared/RemainingValidations';
import { NoDataRow } from '@/components/shared/Table/Table';
import { Validator } from '@/components/shared/Validator';
import { TreeView } from '@/development/TreeView';
import { useQuote } from '@/hooks/useQuote';
import { HateoasExecutorProvider } from '@/services/HateoasExecutorContext';
import { simpleRequest } from '@/services/utility/simpleFetch';
import { useValidationContext } from '@/services/ValidationContext';
import { bowStatus } from '@/utility/bowStatus';
import { isInternalEnvironment } from '@/utility/environment';
import { WarWeb } from '@/war';
import { useHistory } from 'react-router';
import { parseErrors } from '@/utility/errorHelpers';
import { toLowerKeys } from '@/utility/objectHelpers';
import { getInputString } from '@/utility/formatter';
import { Sidebar } from '../../../shared/BowSidebar/Sidebar';
import { steps } from '../../create-bow/bowSteps';
import { GenerateQuoteModal } from '../core/GenerateQuoteModal';
import { BowActions } from './bowActionsMap';
import { ConfirmationModal, ConfirmationModalProps } from './ConfirmationModal';
import { ClientRef } from './core/ClientRef';
import { DocumentationTable } from './core/DocumentationTable';
import { QuotesBar } from './core/QuotesBar';
import { QuoteActions } from './quoteActionsMap';
import { QuoteLabel } from './quoteLabelMap';
import { quoteStatusConfirmations } from './quoteStatusConfirmations';
import { useQuoteCommentsContext } from '../../create-bow/QuoteCommentsContext';
import { PaymentsBox } from '@/components/shared/Bow/PaymentDetails/PaymentsBox';

const Content = styled.div`
  background-color: ${(props) => props.theme.white};
  border-radius: 2px;
  box-shadow: 0 0 6px #4887b122;
  padding: 1.5rem;
`;

const ContentContainer = styled.div<{ isBroker: boolean }>`
  display: grid;
  grid-template-rows: auto;
  column-gap: 2rem;
  row-gap: 2rem;

  ${(props) =>
    props.isBroker
      ? css`
          grid-template-columns: 2fr 4fr 2fr;
          grid-template-areas:
            'summary k-r-coverage documents'
            'summary rates rates'
            'summary payments payments'
            'summary . .';
        `
      : `
        grid-template-columns: 1fr 2.5fr 5fr 1.7fr;
        grid-template-areas:
        "summary coverage k-r-coverage documents"
        "summary rates rates rates"
        "summary payments payments payments"
        "summary . . .";
    `};

  .summary {
    grid-area: summary;
  }
  .coverage {
    grid-area: coverage;
  }

  .k-r-coverage {
    grid-area: k-r-coverage;
  }
  .documents {
    grid-area: documents;
  }
  .rates {
    grid-area: rates;
  }
  .payments {
    grid-area: payments;
  }
`;

export const StyledStatusProgressBar = styled(ProgressBar)`
  width: 500px;
  display: flex;
  align-items: center;
`;

const HeaderContainer = styled.div`
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: baseline;
`;

const SidebarContainer = styled.div`
  height: 100%;
  min-width: 300px;
`;

const DebugBar = styled.div`
  display: flex;
  width: 60%;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  margin: 1em 0;
  padding: 0 2em;
  background-color: ${(props) => props.theme.white};
  border-radius: 3px;
`;

export const statusSteps = [
  { step: 1, name: 'Quoted' },
  { step: 2, name: 'Pending' },
  { step: 3, name: 'Confirmed' },
  { step: 4, name: 'Finalized' },
];

export const statusStep = (status: string | undefined) => {
  if (!status) return null;
  const step = bowStatus.find((n) => n.title === status)?.status;
  if (!statusSteps.find((n) => n.step === step)) return null;
  return step;
};

const QuotesBox = styled(Box)`
  margin-bottom: 1em;
`;

export const getBowDetailsPageHeader = (bowData: WarWeb.Bow) => `BOW ID ${bowData?.internalReference}`;

export const BowDetails = () => {
  const [loading, setLoading] = useState(false);
  const [lastRequest, setLastRequest] = useState<HttpActionProps>();
  const [whatsLoading, setWhatsLoading] = useState<string | undefined>('quote');
  const [showGenerateQuoteModal, setShowGenerateQuoteModal] = useState<boolean>(false);
  const [isAddingAlternative, setIsAddingAlternative] = useState<boolean>(false);

  const {
    setErrors,
    setDismissedErrors,
    setWarnings,
    resetWarnings,
    clearErrors,
    warnings,
    seenWarnings,
    dismissedWarnings,
  } = useValidationContext();
  
  const { ids, setSelectedQuoteId, setSelectedConfiguration } = useQuoteContext();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationModalProps, setConfirmationModalProps] = useState<ConfirmationModalProps>();
  const { setQuoteComments } = useQuoteCommentsContext();

  const { bowId, quoteId } = ids;
  const history = useHistory();

  const [showState, setShowState] = useState<boolean>(false);
  const [progressBarStep, setProgressBarStep] = useState<number>(1);

  const { open } = useModalContext();

  const { data: bowData, mutate, isLoading } = useBow(bowId);
  const selectedQuote = useQuote(bowId, quoteId);

  useEffect(() => {
    const newStep = (statusStep(bowData?.status) ?? 1) - 1;
    setProgressBarStep(newStep);
  }, [bowData]);

  const showQuote = async (showQuoteId: string) => {
    if (loading) return;
    clearErrors();
    setWhatsLoading('quote');
    if (setSelectedQuoteId) setSelectedQuoteId(showQuoteId); // TODO: Move states "into" QuoteContext
    if (setSelectedConfiguration) setSelectedConfiguration();
    await mutate();
    setWhatsLoading('');
    setIsAddingAlternative(false);
  };

  const isMounted = useRef(true);
  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  useEffect(() => {
    setWhatsLoading('');
    setWarnings(toLowerKeys(selectedQuote?.warnings));

    setQuoteComments({
      clientReference: getInputString(bowData?.clientReference),
      comment: getInputString(selectedQuote?.comment),
    });

    if (selectedQuote?.settings.inItineraryEditMode) {
      history.push(`${Routes.createbow}/itinerary?bowId=${bowId}&quoteId=${selectedQuote.id}`);
    } else {
      setTimeout(() => {
        setLoading(false); // prevent page from "blinking" the Bow Details before redirecting to Itinerary edit
      }, 1000);
    }
  }, [bowData, selectedQuote]);

  const onSuccess = (action: string) => {
    clearErrors();

    if (action === BowActions.DeleteBow) {
      history.push(Routes.bows);
      return;
    }
    if (action === QuoteActions.DeleteAlternative) setSelectedQuoteId!(bowData?.primaryQuote.id);

    void mutate();
  };

  const hateoasSetup = (link: HttpActionProps, sectionName?: string) => {
    setWhatsLoading(sectionName);
    setErrors(undefined);
    setLastRequest(link);
    setLoading(true);
  };

  const hateoasCleanup = () => {
    setWhatsLoading('');
    setDismissedErrors([]);
    resetWarnings();

    setLoading(false);
  };

  // eslint-disable-next-line comma-spacing
  const hateoasExecutor = async <T,>(
    links: HttpActionProps[] | undefined,
    action: string,
    body?: {},
    sectionName?: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T> | undefined> => {
    const link = links?.find((n) => n.action === action);
    if (!link) {
      const message = isBroker() ? undefined : `Action ${action} missing in links`;
      open(() => openErrorModal(message));
      return undefined;
    }

    hateoasSetup(link, sectionName);
    const { data, error } = await simpleRequest<T>(link.href, link.httpVerb as Method, body, config);

    if (error)
      parseErrors(isMounted.current, (message, title) => open(openErrorModal(message, title)), error, setErrors);
    else onSuccess(action);

    hateoasCleanup();
    return data;
  };

  const newAlternative = async () => {
    const copyQuoteRequest = {
      quoteId: selectedQuote?.id,
    };
    setIsAddingAlternative(true);
    const { data } =
      (await hateoasExecutor<WarWeb.BowQuote>(
        selectedQuote?.links,
        QuoteLabel.CreateAlternative,
        copyQuoteRequest,
        'quote'
      )) ?? {};
    void showQuote(data?.id);
  };

  const submitChanges = () => {
    void hateoasExecutor(selectedQuote?.links, QuoteActions.FinishOverride, {}, 'quote');
  };

  const confirmStatusChange = async (links: WarWeb.HyperMediaLink[], action: string) => {
    const confirmationConfig = quoteStatusConfirmations.find((n) => n.key === action);

    if (!bowId || !selectedQuote || !bowData) return;

    const request = { bowId, quoteId: selectedQuote.id };

    if (action === QuoteActions.DeleteAlternative) {
      await mutate(
        {
          ...bowData,
          quotes: bowData.quotes.filter((q) => q.id !== selectedQuote.id),
        },
        {
          revalidate: false,
        }
      );
    }

    // some status changes require a popup (confirmation of some sort), so we need a callback (to hateoasExecutor)
    // if not, we call hateoasExecutor directly
    if (action === QuoteLabel.GenerateQuote) {
      setShowGenerateQuoteModal(true);
    } else if (!confirmationConfig) {
      await hateoasExecutor<WarWeb.BowQuote>(links, action, request, 'quote');
    } else {
      setConfirmationModalProps({
        links: links,
        config: confirmationConfig,
        bowId: bowData.id,
        request,
        closeModal: () => setShowConfirmationModal(false),
        hateoasExecutor,
      });
      setShowConfirmationModal(true);
    }
  };

  const finalizedQuote = bowData?.quotes.find((q) => q.status==="Finalized" && q.id === ids.quoteId);
  const paymentDetails = finalizedQuote?.paymentDetails;

  return (
    <HateoasExecutorProvider
      hateoasExecutor={hateoasExecutor}
      lastRequest={lastRequest}
      loading={loading}
      setLoading={setLoading}
      whatsLoading={whatsLoading}
    >
      {!isLoading && bowData && (
        <>
          <HeaderContainer>
            <PageHeader
              header={
                <HeaderContent>
                  {getBowDetailsPageHeader(bowData)}
                  <ClientRef bowData={bowData} />
                </HeaderContent>
              }
              breadcrumb={{ label: 'Breach of warranty', to: Routes.bows }}
            />
            <StyledStatusProgressBar steps={statusSteps} step={progressBarStep} />
          </HeaderContainer>

          {isInternalEnvironment() && (
            <DebugBar>
              <div>
                bowId: {bowId}
                <br />
                quoteId:
                {'  '}
                {selectedQuote?.id}
              </div>
              <div>
                Created: {bowData?.created && new Date(Date.parse(bowData?.created.dateTime)).toLocaleString()}
                <br />
                Updated: {bowData?.lastUpdated && new Date(Date.parse(bowData?.lastUpdated.dateTime)).toLocaleString()}
              </div>

              <div>
                <Checkbox
                  checked={showState}
                  onChange={() => setShowState(!showState)}
                  name="ShowState"
                  rightLabel="Show State"
                />
              </div>
            </DebugBar>
          )}
          <QuotesBox header="Alternatives">
            <QuotesBar
              alternatives={bowData?.quotes}
              newAlternative={newAlternative}
              selectAlternative={showQuote}
              confirmStatusChange={confirmStatusChange}
              submitChanges={submitChanges}
            />

            {showState && selectedQuote?.links && <TreeView name="selectedQuote" data={selectedQuote.links} />}
          </QuotesBox>

          <Validator
            keys={steps.filter((s) => s.step !== 3)?.flatMap((u) => u.warningsKeys.map((v) => `/${v}/`))}
            hideWarnings
          />
          <RemainingValidations rootElement={selectedQuote} />

          <ContentContainer isBroker={isBroker()}>
            {whatsLoading !== 'quote' && selectedQuote ? (
              <>
                <SidebarContainer className="summary">
                  <Box header="Particulars">
                    <Sidebar confirmStatusChange={confirmStatusChange} hateoasExecutor={hateoasExecutor}>
                      <Button
                        to={`${Routes.createbow}/terms?bowId=${bowId}&quoteId=${selectedQuote.id}`}
                        background={theme.green}
                      >
                        Warranties / Subjectivities
                      </Button>
                    </Sidebar>
                  </Box>
                  {showState && <TreeView name="bowData" data={bowData} />}
                </SidebarContainer>

                {isInternalUser() && (
                  <div className="coverage">
                    <CoverageBox />
                  </div>
                )}

                <div className="k-r-coverage">
                  <KidnapRansomBox />
                </div>

                <div className="documents">
                  <Box header="Documentation">
                    <Content>
                      <DocumentationTable />
                    </Content>
                  </Box>
                </div>

                <div className="rates">
                  <CalculationsBox loadStatus={{ loading: whatsLoading === 'calculations', success: !!bowData }} />
                </div>
                
                {paymentDetails && <div className="payments">
                  <PaymentsBox paymentDetails={paymentDetails} />
                </div>}
              </>
            ) : (
              <NoDataRow loadStatus={{ loading: isAddingAlternative || loading || isLoading, success: !!bowData }} />
            )}
          </ContentContainer>
        </>
      )}

      {showGenerateQuoteModal && <GenerateQuoteModal closeModal={() => setShowGenerateQuoteModal(false)} />}
      {showConfirmationModal && confirmationModalProps && <ConfirmationModal {...confirmationModalProps} />}
    </HateoasExecutorProvider>
  );
};
