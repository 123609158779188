import {
  Table, TableCell, TableHeader, TableRow
} from '@/components/shared/Table/Table';
import { ContractType } from '@/models/Agreement';
import { ContractTypes } from '@/utility/contractTypes';
import { pluralize } from '@/utility/dateCalc';
import { WarWeb } from '@/war';
import styled from 'styled-components';

const TableContainer = styled.div`
  max-width: 700px;
`;

export const CoverageBroker = ({ coverage }: { coverage: WarWeb.BrokerCoverageSummary[] }) => (
  <TableContainer>
    <Table columns={4} striped>
      <TableHeader>Days Covered</TableHeader>
      <TableHeader>Quote valid</TableHeader>
      <TableHeader>Contract Type</TableHeader>
      <TableHeader>Maximum / Pro Rata</TableHeader>
      {coverage.map(row => (
        <TableRow key={row.daysCovered}>
          <TableCell>
            {`${row.daysCovered} ${pluralize('day', row.daysCovered)}`}
          </TableCell>
          <TableCell>
            {`${row.quoteValidHours} hours`}
          </TableCell>
          <TableCell>
            {ContractTypes[row.contractType as ContractType]}
          </TableCell>
          <TableCell>
            {`${row.maximumProRataDays} ${pluralize('day', row.maximumProRataDays)}`}
          </TableCell>
        </TableRow>
      ))}
    </Table>
  </TableContainer>
);
