import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { theme } from '@/utility/theme';

const Overlay = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;
  opacity: 0.5;
  background-color: ${theme.ultraLightGray};
  height: 100vh;
  width: 100%;
  cursor: progress;
`;

export const Disabled: FC = () => {
  const [display, setDisplay] = useState<boolean>(true);

  setTimeout(() => setDisplay(false), 8000);

  return display ? <Overlay /> : null;
};
