import { getUserRole } from '@/components/routing/roles';
import { FeedbackAndSupport } from 'insify-remote-component-loader';
import { useAccount } from 'its-js-authentication';
import React from 'react';
import { useHistory } from 'react-router';

export const FeedbackAndSupportPage = () => {
    const role = getUserRole();
    const history = useHistory();
    const account = useAccount();
    const portal: React.ComponentProps<typeof FeedbackAndSupport>['portal'] = {
        displayName: 'WarWeb',
        name: 'war'
    };

    return (
        <FeedbackAndSupport account={account} role={role} history={history} portal={portal} />
    );
};
