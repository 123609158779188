import { TargetType } from '@/models/TargetType';
import { getDifference } from '@/utility/array';
import { WarWeb } from '@/war';
import { useIsBulkEditing } from '../../agreements/useIsBulkEditing';
import { AgreementViewMode, useAgreementDetailsContext } from '../AgreementDetailsContext';
import { useAgreementSelectionContext } from '../AgreementSelectionContext';

export const useCopyModeTargets = () => {
  const { viewMode, targetsSummary } = useAgreementDetailsContext();
  const { areaIds, vesselIds, vesselTypeIds, targetType } = useAgreementSelectionContext();
  const isBulkEdit = useIsBulkEditing();

  if (isBulkEdit || viewMode === AgreementViewMode.Create) return undefined;

  const originalAreas = targetsSummary?.areas.map(x => x.areaId) || [];
  const originalVessels = targetsSummary?.vessels.map(x => x.vesselId) || [];
  const originalVesselTypes = targetsSummary?.vesselTypes.map(x => x.vesselTypeId) || [];

  const areasToAdd: WarWeb.AreaChangeSpecification[] = getDifference(areaIds, originalAreas).map(x => ({
    action: 'Add',
    areaId: x
  }));

  const vesselsToAdd: WarWeb.VesselChangeSpecification[] = getDifference(vesselIds, originalVessels).map(x => ({
    action: 'Add',
    vesselId: x
  }));

  const vesselTypesToAdd: WarWeb.VesselTypeChangeSpecification[] = getDifference(vesselTypeIds, originalVesselTypes).map(x => ({
    action: 'Add',
    vesselTypeId: x
  }));
  // const vesselTypesToDelete: WarWeb.VesselTypeChangeSpecification[] = getDifference(originalVesselTypes, vesselTypeIds).map(x => ({
  //   action: 'Delete',
  //   vesselTypeId: x
  // }));

  // const copyModeVesselTypes = vesselTypesToAdd.concat(vesselTypesToDelete);

  if (targetType === TargetType.Trade) {
    const areasToDelete: WarWeb.AreaChangeSpecification[] = getDifference(originalAreas, areaIds).map(x => ({
      action: 'Delete',
      areaId: x
    }));

    const tradeAreas = areasToAdd.concat(areasToDelete);
    const vesselsToDelete: WarWeb.VesselChangeSpecification[] = getDifference(originalVessels, vesselIds).map(x => ({
      action: 'Delete',
      vesselId: x
    }));

    const tradeVessels = vesselsToAdd.concat(vesselsToDelete);

    const tradeTargets: WarWeb.AgreementUpdateParameters = {
      areas: tradeAreas.length > 0 ? tradeAreas : undefined,
      vessels: tradeVessels.length > 0 ? tradeVessels : undefined
    };

    return tradeTargets;
  }

  const copyModeTargets: WarWeb.AgreementUpdateParameters = {
    areas: areasToAdd.length > 0 ? areasToAdd : undefined,
    vessels: vesselsToAdd.length > 0 ? vesselsToAdd : undefined,
    vesselTypes: vesselTypesToAdd.length > 0 ? vesselTypesToAdd : undefined
  };

  return copyModeTargets;
};
