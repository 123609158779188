import Qs from 'qs';
import { useSWREndpoint } from '@/hooks/useSWREndpoint';
import { WarWeb } from '@/war';
import { postFetcher } from './utility/simpleFetch';

export const getFacetsUri = (basePath: string, parameterName: string, filter: string, key?: string) =>
  `${basePath}/metadata/columns/${parameterName}/facets?filter=${filter}&maxCount=1000&key=${key}`;

export const useFacets = (load: boolean, basePath: string, filter: string, parameterName: string, params: any, key?: string) => {
  const facetsUri = getFacetsUri(basePath, parameterName, filter, key);
  return useSWREndpoint<WarWeb.FacetsSearchResult>(
    load ? facetsUri : null,
    undefined,
    () => postFetcher<WarWeb.FacetsSearchResult>(facetsUri, params)
  );
};
