import { Button } from '@/components/shared/Button';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { useMe } from '@/services/meService';
import { deleteImpersonateReinsurer, impersonateReinsurer } from '@/services/reinsurerImpersonationService';
import { theme } from '@/utility/theme';

export const ReinsurerImpersonator = () => {
  const { data: me } = useMe();

  const impersonateHandler = async () => {
    await impersonateReinsurer();
    window.location.reload();
  };

  const revertRoleHandler = async () => {
    await deleteImpersonateReinsurer();
    window.location.reload();
  };

  const validImpersonationRoles = me?.role === 'Admin' || me?.role === 'Underwriter';

  return (
    <ButtonGroup>
      <Button onClick={impersonateHandler} disabled={!validImpersonationRoles || me.impersonated} background={theme.lightGreen}>Impersonate reinsurer</Button>
      <Button onClick={revertRoleHandler} disabled={!me?.impersonated} background={theme.mediumRed}>Revert role</Button>
    </ButtonGroup>
  );
};
