import Qs from 'qs';
import { useSWREndpoint } from '@/hooks/useSWREndpoint';
import { WarWeb } from '@/war';
import { MinSearchLength } from '@/utility/globals';
import { AcceptHeaders } from './config';
import { getData, postData } from './utility/simpleFetch';

const basePathDestinations = 'destinations';
const getDestinationsUri = (params?: WarWeb.DestinationSearchParameters) => `${basePathDestinations}?${Qs.stringify(params, { indices: false })}`;

export const useDestinations = (params?: WarWeb.DestinationSearchParameters) =>
  useSWREndpoint<WarWeb.DestinationMatch[]>(
    params?.freetext && params.freetext.length >= MinSearchLength ? getDestinationsUri(params) : null,
    AcceptHeaders.SearchResult
  );

export const getDestinations = async (params?: WarWeb.DestinationSearchParameters) => getData(getDestinationsUri(params));
