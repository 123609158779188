import { SlimButton } from '@instech/components';
import { Plus } from '@instech/icons';
import { useEditExcludedAreasContext } from './EditExcludedAreasContext/EditExcludedAreasContext';

export const AddCustomAreaButton = () => {
  const { state: { regionId, editingNewArea }, addNewCustomArea } = useEditExcludedAreasContext();
  const hint = regionId === undefined ? 'You must select a region before you can add a custom area' : undefined;
  const buttonText = editingNewArea ? 'CREATED NEW CUSTOM AREA' : 'ADD NEW CUSTOM AREA';
  const variant = editingNewArea ? 'primary' : 'secondary';
  const disabled = editingNewArea || !!hint;
  return (
    <SlimButton startIcon={<Plus />} variant={variant} onClick={addNewCustomArea} title={hint} disabled={disabled}>
      {buttonText}
    </SlimButton>
  );
};
