import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { useState } from 'react';
import {
  createAgreementAlternative, deleteAgreementAlternative, useAgreementsSummaryAlternatives
} from '@/services/agreementsService';
import styled from 'styled-components';
import { TargetType } from '@/models/TargetType';
import { SlimButton } from '@instech/components';
import { useAgreementTargets } from '../agreements/useAgreementTargets';
import { useAgreementDetailsContext } from '../agreementDetails/AgreementDetailsContext';
import {
  DaysCoveredModal, DaysCoveredModalData, DaysCoveredModalMode
} from './DaysCoveredModal';
import { useAgreementsPageContext } from '../agreements/AgreementsPageContext';
import { useAgreementSelectionContext } from '../agreementDetails/AgreementSelectionContext';

const ErrorContainer = styled.div`
  color: ${props => props.theme.red};
`;

export const EditDaysCoveredGroup = () => {
  const { agreementTargets } = useAgreementTargets();
  const { mutateAgreement } = useAgreementDetailsContext();
  const { agreementSummaryTarget, setAgreementSummaryTarget } = useAgreementsPageContext();
  const { targetType } = useAgreementSelectionContext();
  const { data: alternatives } = useAgreementsSummaryAlternatives({ ...agreementTargets, daysCoveredGroup: undefined, minimumSumInsured: undefined });

  const [modalState, setModalState] = useState<DaysCoveredModalData>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isDeleting, setIsDeleting] = useState(false);

  const canEdit = targetType === TargetType.Standard || targetType === TargetType.VesselTypeSpecific;

  const hasMultipleSetsAfterSave = () => {
    if (!alternatives) return false;
    if (modalState?.mode === DaysCoveredModalMode.Delete && alternatives.length <= 2) return false;
    return true;
  };

  const getDaysCoveredTarget = () => {
    const daysCoveredGroup = agreementTargets.daysCoveredGroup ?? agreementSummaryTarget.daysCoveredGroup;
    return daysCoveredGroup ?? alternatives?.at(0)?.daysCovered;
  };

  const saveModalChanges = async (days: number) => {
    setErrorMessage(undefined);
    let response;

    if (modalState?.mode === DaysCoveredModalMode.Duplicate) {
      const parameters = {
        targets: {
          ...agreementTargets,
          minimumSumInsured: undefined,
          daysCoveredGroup: getDaysCoveredTarget()
        },
        daysCoveredDestination: days
      };
      response = await createAgreementAlternative(parameters);
      if (!response.error) {
        setAgreementSummaryTarget({ ...agreementSummaryTarget, hasMultipleSets: true });
      }
    } else if (modalState?.mode === DaysCoveredModalMode.Delete) {
      setIsDeleting(true);
      response = await deleteAgreementAlternative({ targets: { ...agreementTargets, minimumSumInsured: undefined } });
      if (!response.error) {
        const nextAlternative = alternatives?.find(x => x.daysCovered !== days);
        setAgreementSummaryTarget({ ...agreementSummaryTarget, daysCoveredGroup: nextAlternative?.daysCovered, hasMultipleSets: hasMultipleSetsAfterSave() });
      }

      setIsDeleting(false);
    }

    if (response?.error) {
      setErrorMessage(response.error.message);
    } else {
      mutateAgreement();
    }
  };

  const currentDaysCovered = agreementTargets.daysCoveredGroup;

  return (
    <>
      <ButtonGroup>
        <SlimButton
          fontSize="14px"
          variant="tertiary"
          onClick={() => setModalState({ daysCovered: currentDaysCovered, mode: DaysCoveredModalMode.Delete })}
          loading={isDeleting}
          disabled={isDeleting || !canEdit}>
          DELETE
        </SlimButton>
        <SlimButton
          fontSize="14px"
          variant="secondary"
          onClick={() => setModalState({ daysCovered: agreementTargets.daysCoveredGroup, mode: DaysCoveredModalMode.Duplicate })}
          disabled={!canEdit}>
          DUPLICATE
        </SlimButton>
      </ButtonGroup>
      {errorMessage && <ErrorContainer>{errorMessage}</ErrorContainer>}
      {modalState && <DaysCoveredModal save={saveModalChanges} close={() => setModalState(undefined)} data={modalState} />}
    </>
  );
};
