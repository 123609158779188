import React, { useState } from 'react';
import { Edit } from '@instech/icons';
import styled from 'styled-components';
import { Routes } from '@/components/routing/routes';
import {
  formatDate, formatTimeSpan, pluralize
} from '@/utility/dateCalc';
import { isAdmin, isUnderwriter } from '@/components/routing/roles';
import { QuoteActions } from '@/components/pages/bows/details/quoteActionsMap';
import { useHateoasExecutorContext } from '@/services/HateoasExecutorContext';
import { addOrRemove } from '@/utility/arrayHelpers';
import { WarWeb } from '@/war';
import { checkLink } from '@/components/shared/HateoasHandler';
import { theme } from '@/utility/theme';
import { formatRate, formatInteger } from '@/utility/formatter';
import { useValidationContext } from '@/services/ValidationContext';
import { fieldNameFilter } from '@/utility/errorHelpers';
import { Globals } from '@/utility/globals';
import { SidebarContainer, SidebarItem } from './SidebarItem';
import { useQuoteContext } from './QuoteContext';
import {
  Counter, Label, SidebarSubHeader
} from './Components';
import { SidebarEditField } from './SidebarEditField';
import { useSelectedQuote } from '../Bow/useSelectedQuote';
import { DaysDetails } from './DaysDetails';
import { ItineraryBox } from './ItineraryBox';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const CoveredArea = styled(Container)`
  
  ${SidebarContainer} {
    margin-top: 0;
    border-radius: none;
  }
`;

interface SidebarItineraryItemProps {
  coveredAreas: WarWeb.CoveredArea[];
  compact?: boolean;
  links?: WarWeb.HyperMediaLink[];
  warningsPath?: string;
  warningsAreRegex?: boolean;
}

export const SidebarItineraryItem = ({
  coveredAreas, compact = false,
  links, warningsPath,
  warningsAreRegex
}: SidebarItineraryItemProps) => {
  const { ids, selectedConfiguration, setSelectedConfiguration, isMultiDestination, showSidebarLinks } = useQuoteContext();
  const { bowId, quoteId } = ids;
  const { lastRequest } = useHateoasExecutorContext();
  const { warnings } = useValidationContext();

  const [editStates, setEditStates] = useState<string[]>([]); // which fields are enabled (shown)

  const selectedQuote = useSelectedQuote();
  if (!selectedQuote) return null;

  const toggleEdit = (tag: string) => {
    const updatedState = [...editStates];
    addOrRemove(updatedState, tag);
    setEditStates(updatedState);
  };

  const toolbarNoDestination = [
    {
      icon: <Edit />,
      action: QuoteActions.AddArea,
      to: `${Routes.createbow}/itinerary?bowId=${bowId}&quoteId=${quoteId}`
    }
  ];

  const toolbar = [
    {
      icon: <Edit />,
      action: QuoteActions.UpdateCoveredArea,
      to: `${Routes.createbow}/itinerary?bowId=${bowId}&quoteId=${quoteId}`
    }
  ];

  const toolbarTimeAdjustment = isAdmin() || isUnderwriter() ? [
    {
      icon: <Edit />,
      action: QuoteActions.SetTimeAdjustment,
      onClick: () => toggleEdit('timeAdjustment')
    }
  ] : [];

  const isItemsClickable = isMultiDestination && showSidebarLinks;

  return (
    <Container>
      <SidebarSubHeader>
        <Label>Time and Location</Label>
        <Counter number={fieldNameFilter(warnings, warningsPath, warningsAreRegex).length} />
      </SidebarSubHeader>

      {selectedQuote.duration.totalVoyageTime && (
        <SidebarItem
          compact={compact}
          color={compact ? undefined : theme.white}
          onClick={isItemsClickable && !compact ? () => setSelectedConfiguration!(Globals.TotalVoyageId) : undefined}
          background={selectedConfiguration === Globals.TotalVoyageId ? theme.status.blue : theme.softBlue}
          isSelected={!compact && selectedConfiguration === Globals.TotalVoyageId}>
          <h3>Total Voyage</h3>
          <h2>{formatTimeSpan(selectedQuote.duration.totalVoyageTime)}</h2>
        </SidebarItem>
      )}

      {!compact && (
        <>
          {coveredAreas.length > 0 ? coveredAreas.map((coveredArea, i) => (
            <CoveredArea key={coveredArea.id}>
              <SidebarItem
                onClick={isItemsClickable && coveredArea.coveredAreaType === 'OceanArea' ? () => setSelectedConfiguration!(coveredArea.id) : undefined}
                background={selectedConfiguration === coveredArea.id ? theme.blueGray : undefined}
                isSelected={selectedConfiguration === coveredArea.id}
                toolbar={toolbar}
                links={coveredArea.links}
                hasSubItem={coveredArea.itinerary && coveredArea.itinerary.length > 0}
                fieldPath={`^coveredAreas\\[${i}\\]\\.[A-Za-z]+$`}>
                <strong>
                  {coveredArea.name}
                </strong>

                {(coveredArea.entry || coveredArea.exit) && (
                  <>
                    {`${formatDate(coveredArea.entry?.local) ?? 'TBD'} - ${formatDate(coveredArea.exit?.local) ?? 'TBD'}`}
                  </>
                )}
                <br />
                <DaysDetails destination={coveredArea} />

              </SidebarItem>
              {
                coveredArea.itinerary?.length > 0 && (
                  <ItineraryBox
                    coveredAreaIndex={i}
                    itinerary={coveredArea.itinerary}
                  />
                )
              }
            </CoveredArea>
          )) : (
            <SidebarItem
              key={-1}
              toolbar={toolbarNoDestination}
              links={selectedQuote.links}
              fieldPath="coveredAreas">
              <em>No destinations added</em>
            </SidebarItem>
          )}
        </>
      )}

      {
        selectedQuote.duration.totalChargedDays && (
          <SidebarItem compact={compact}>
            <h3>Total Charged Days</h3>
            <h2>
              {`${selectedQuote.duration.totalChargedDays} ${pluralize('day', selectedQuote.duration.totalChargedDays)}`}
            </h2>
          </SidebarItem>
        )
      }

      {
        selectedQuote.duration.totalTimeException && (
          <SidebarItem compact={compact} background={theme.veryLightGreen}>
            <h3>Total Time Exception</h3>
            <h2>{formatTimeSpan(selectedQuote.duration.totalTimeException)}</h2>
          </SidebarItem>
        )
      }

      {
        selectedQuote.duration.breachDays != null && (
          <SidebarItem compact={compact}>
            <h3>Cover Days</h3>
            <h2>{`${formatInteger(selectedQuote.duration.breachDays)} / ${selectedQuote.coverageDetails?.daysCovered}`}</h2>
          </SidebarItem>
        )
      }

      {
        selectedQuote.duration.timeAdjustmentFactor != null && (
          <SidebarItem compact={compact} toolbar={editStates.includes('timeAdjustment') ? [] : toolbarTimeAdjustment} links={links}>
            <h3>Time Adjustment Factor</h3>
            <SidebarEditField
              key="timeAdjustment"
              value={selectedQuote.duration.timeAdjustmentFactor}
              name="timeAdjustment"
              makeRequest={(value?: number) => ({ factor: value, days: null })}
              action={QuoteActions.SetTimeAdjustment}
              links={links}
              active={editStates?.includes('timeAdjustment')}
              close={() => toggleEdit('timeAdjustment')}
              errorKey="Factor"
              isRelevant={!!checkLink(links, lastRequest)}
            >
              <h2>
                {`${formatRate(selectedQuote.duration.timeAdjustmentFactor)}`}
              </h2>
            </SidebarEditField>
          </SidebarItem>
        )
      }
    </Container>
  );
};
