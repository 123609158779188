import { useSelectedQuote } from '@/components/shared/Bow/useSelectedQuote';
import { ChangeIndicator } from '@/components/shared/BowSidebar/ChangeIndicator';
import { useQuoteContext } from '@/components/shared/BowSidebar/QuoteContext';
import { FlexBox } from '@/components/shared/FlexBox';
import { DateTimePicker, TextField } from '@/components/shared/Form/FormFields';
import {
  checkAction, checkLink, HateoasHandler
} from '@/components/shared/HateoasHandler';
import { Loader } from '@instech/components';
import { SpanCell } from '@/components/shared/Table/core/Components';
import { Validator } from '@/components/shared/Validator';
import { useEntryExitPoints } from '@/services/excludedAreasService';
import { useHateoasExecutorContext } from '@/services/HateoasExecutorContext';
import { getLastElement } from '@/utility/arrayHelpers';
import { formatDate } from '@/utility/dateCalc';
import { isNumber } from '@/utility/formatter';
import { keysOf } from '@/utility/objectHelpers';
import { theme } from '@/utility/theme';
import { TimeConverter } from '@/utility/timeConverter';
import { WarWeb } from '@/war';
import { BoatFront, Close } from '@instech/icons';
import { useState } from 'react';
import styled, { css } from 'styled-components';
import { FieldLabel } from '../../../shared/Form/core/Components';
import { Dropdown } from '../../../shared/Form/fields/Dropdown';
import { QuoteActions } from '../../bows/details/quoteActionsMap';
import {
  DotWrapper,
  RemoveContainer, Spacer, TrailDot, TrailLine, TrailWrapper
} from './Components';
import { Destination } from './Destination';

const Header = styled(FlexBox) <{ span?: number, background?: string }>`
  ${props => props.span && css`
    grid-column: ${isNumber(`${props.span}`) ? `span ${props.span}` : `${props.span}`};
  `};
  background-color: ${props => props.background};

  padding: 3px 0;
  min-height: 2em;

  padding-left: 1em;
  padding-right: 1em;

  border-radius: 3px 3px 0 0;
 
`;

const Row = styled(FlexBox) <{ isTimeException?: boolean, isCustomArea?: Boolean }>`
  display: contents;

  > div:first-child {
    border-left: 1px solid ${theme.marineBlue50};
    border-radius: 3px 0 0 0;
  }

  > div:last-child {
    padding-right: 1em;
    border-right: 1px solid ${theme.marineBlue50};
    border-radius: 0 3px 0 0;
  }

  font-size: 14px;
  color: ${theme.white};

  ${FieldLabel} {
    color: ${props => props.theme.white};
  }

  input, select {
    color: ${props => props.theme.marineBlue};
  }

  > div {
    background-color: ${props => props.isTimeException && !props.isCustomArea ? theme.lightGreen80 : theme.marineBlue50};
    padding: 0.5em 1em 0.8em 1em;
  }
`;

const CoveredAreaName = styled.div`
  display: flex;
  font-weight: bold;
  text-transform: uppercase;
`;

const StyledSpacer = styled(Spacer)`
  margin-bottom: 1em;
  box-shadow: 0 2px 6px 0 rgba(72,135,177,0.2);
`;

const SmallDropdown = styled.div`
  display: flex;

  padding-right: 5px;
`;
interface ICoveredArea extends Partial<WarWeb.CoveredArea> { }
interface DatesState {
  entry?: Date,
  exit?: Date
}
interface EntryExitState {
  entryPointId?: number,
  exitPointId?: number
}

interface CoveredAreaProps {
  index: number;
  coveredArea: WarWeb.CoveredArea,
  first: boolean,
  last: boolean
}

export const CoveredArea = ({
  index,
  coveredArea,
  first,
  last
}: CoveredAreaProps) => {
  const { hateoasExecutor, lastRequest, loading } = useHateoasExecutorContext();
  const { isMultiDestination } = useQuoteContext();

  const [datesState, setDatesState] = useState<DatesState>({ entry: coveredArea?.entry?.local, exit: coveredArea?.exit?.local });
  const [entryExitState, setEntryExitState] = useState<EntryExitState>({ entryPointId: coveredArea?.entryPoint?.id, exitPointId: coveredArea?.exitPoint?.id });

  const [changedField, setChangedField] = useState<string>();
  const isCustomArea = coveredArea.coveredAreaType === 'OceanArea';
  const { data: entryExitPoints } = useEntryExitPoints(isCustomArea ? coveredArea.areaId : undefined);

  const canUpdateArea = checkAction(coveredArea.links, QuoteActions.UpdateCoveredArea);

  const updateCoveredArea = (updatedArea: ICoveredArea) => {
    const updateCoveredAreaRequest: WarWeb.UpdateCoveredAreaRequest = {
      coveredAreaId: updatedArea.id,
      entryPointId: updatedArea.entryPoint?.id ?? undefined,
      exitPointId: updatedArea.exitPoint?.id ?? undefined,
      entry: updatedArea.entry?.local ? TimeConverter.ToNaiveDateTime(updatedArea.entry.local) : undefined,
      exit: updatedArea.exit?.local ? TimeConverter.ToNaiveDateTime(updatedArea.exit.local) : undefined
    };

    void hateoasExecutor(updatedArea.links, QuoteActions.UpdateCoveredArea, updateCoveredAreaRequest, 'coveredArea');
  };

  // todo: isDirty!
  const updateDates = (inputDate: DatesState) => {
    if (!canUpdateArea) return;
    const field = keysOf(inputDate)[0];
    setChangedField(field);
    const value = inputDate[field];
    if (!value) return;

    const isFirst = !datesState[field];
    if (isFirst) value.setHours(0);

    const updatedCoveredArea: ICoveredArea = {
      ...coveredArea,
      entry: {
        local: inputDate.entry || datesState?.entry
      },
      exit: {
        local: inputDate.exit || datesState?.exit
      },
      // echo any local, unsaved state (in case of errors)
      entryPoint: {
        id: entryExitState?.entryPointId
      },
      exitPoint: {
        id: entryExitState?.exitPointId
      },
    };
    setDatesState({
      ...datesState,
      ...(inputDate.entry && { entry: inputDate.entry }),
      ...(inputDate.exit && { exit: inputDate.exit })
    });

    updateCoveredArea(updatedCoveredArea);
  };

  const updatePoints = (inputPoint: EntryExitState) => {
    setChangedField(keysOf(inputPoint)[0]);
    const updatedCoveredArea: ICoveredArea = {
      ...coveredArea,
      entryPoint: {
        id: inputPoint.entryPointId || entryExitState?.entryPointId
      },
      exitPoint: {
        id: inputPoint.exitPointId || entryExitState?.exitPointId
      },
      // echo any local, unsaved state (in case of errors)
      entry: {
        local: datesState?.entry
      },
      exit: {
        local: datesState?.exit
      }
    };
    setEntryExitState({
      ...entryExitState,
      ...(inputPoint.entryPointId && { entryPointId: inputPoint.entryPointId }),
      ...(inputPoint.exitPointId && { exitPointId: inputPoint.exitPointId })
    });

    updateCoveredArea(updatedCoveredArea);
  };

  const deleteCoveredArea = () => {
    if (loading) return;
    void hateoasExecutor(coveredArea.links, QuoteActions.DeleteCoveredArea, { coveredAreaId: coveredArea.id }, 'coveredArea');
  };

  let entryDate;
  if (coveredArea?.entry) entryDate = coveredArea.entry.local;

  let exitDate;
  if (coveredArea?.exit) exitDate = coveredArea.exit.local;

  const emptyItinerary = !coveredArea?.itinerary.some(x => x);
  const isEntry = isCustomArea && first;
  const isExit = last && ((isCustomArea && !isMultiDestination) || emptyItinerary);

  const isTimeException = !coveredArea.agreementId || !coveredArea?.itinerary.some(x => !x.port.isTimeException);

  const warningKeys = [`CoveredAreas[${index}].Entry`, `CoveredAreas[${index}].Exit`];

  return (
    <>
      {isMultiDestination && (
        <>
          <DotWrapper cover={!isCustomArea && first}>
            {isCustomArea && (
              <TrailDot
                last={isExit}
                first={isEntry}
                color={isTimeException ? theme.green : undefined}
                // eslint-disable-next-line no-nested-ternary
                label={isExit ? 'End' : (isEntry || first ? 'Start' : undefined)}>
                {isExit && <BoatFront />}
              </TrailDot>
            )}
          </DotWrapper>
          {first && (
            <TrailWrapper>
              <TrailLine offsety="1em" offsetx="22px" />
            </TrailWrapper>
          )}
        </>
      )}

      {!isCustomArea ? (
        <>
          <Validator keys={warningKeys} hideWarnings />

          <Header flexDirection="row" alignItems="center" justifyContent="space-between" span={5} background={theme.marineBlue50}>
            <FlexBox flexDirection="row">
              <CoveredAreaName>{coveredArea.name}</CoveredAreaName>
              &nbsp;
              {`- ${formatDate(coveredArea.entry?.local) ?? 'TBD'} - ${formatDate(coveredArea.exit?.local) ?? 'TBD'}`}
            </FlexBox>
            <RemoveContainer>
              <HateoasHandler
                links={coveredArea.links}
                action={QuoteActions.DeleteCoveredArea}
                editVariant={<Close onClick={() => deleteCoveredArea()} />}
              />
            </RemoveContainer>
          </Header>
        </>
      ) : (
        <Row isTimeException={isTimeException} isCustomArea={isCustomArea}>
          <TextField
            name="coveredArea"
            label="Covered Area"
            nonedit
            value={coveredArea.name}
            tabIndex={0}
            id="first"
          />
          <FlexBox>

            {loading && !!checkLink(coveredArea.links, lastRequest) && changedField === 'entryPointId' ?
              <Loader />
              : (
                <FlexBox flexDirection="column">
                  <Validator
                    keys={[`coveredAreas[${index}].EntryPoint`]}
                    isRelevant={!!checkLink(coveredArea.links, lastRequest)}
                    hasDarkBackground>
                    <SmallDropdown>
                      <Dropdown
                        name="entryPointId"
                        label="Entry"
                        compact
                        placeholder="Select"
                        selectedValue={`${entryExitState?.entryPointId}`}
                        options={entryExitPoints?.map(p => ({ name: p.name, value: `${p.id}` }))}
                        disabled={loading || !canUpdateArea}
                        changeHandler={e => updatePoints({ entryPointId: +e.target.value })} // TODO! Pass event
                        tabIndex={0}
                      />
                    </SmallDropdown>
                  </Validator>
                </FlexBox>
              )}

            {(loading && !!checkLink(coveredArea.links, lastRequest) && changedField === 'exitPointId') ?
              <Loader />
              : (
                <FlexBox flexDirection="column">
                  <Validator
                    keys={[`coveredAreas[${index}].ExitPoint`]}
                    isRelevant={!!checkLink(coveredArea.links, lastRequest)}
                    hasDarkBackground
                  >
                    <SmallDropdown>
                      <Dropdown
                        name="exitPointId"
                        label="Exit"
                        compact
                        placeholder="Select"
                        selectedValue={`${entryExitState?.exitPointId}`}
                        options={entryExitPoints?.map(p => ({ name: p.name, value: `${p.id}` }))}
                        disabled={loading || !canUpdateArea}
                        changeHandler={e => updatePoints({ exitPointId: +e.target.value })}
                        tabIndex={0}
                      />
                    </SmallDropdown>
                  </Validator>
                </FlexBox>
              )}
          </FlexBox>

          <FlexBox flexDirection="column">
            <Validator
              keys={['Entry', `CoveredAreas[${index}].Entry`]}
              isRelevant={!!checkLink(coveredArea.links, lastRequest)}
              hasDarkBackground>
              <ChangeIndicator path={`coveredAreas[${index}].entry`}>
                <DateTimePicker
                  name={`CA_${index}_ETA`}
                  label="ETA"
                  id={`CA_${index}_ETA`}
                  withTime
                  timeZoneId={coveredArea.entryPoint?.timeZoneId || coveredArea.itinerary[0]?.port?.timeZoneId}
                  date={entryDate}
                  setDate={date => updateDates({ entry: date })}
                  disabled={loading || !canUpdateArea}
                  loading={loading && !!checkLink(coveredArea.links, lastRequest) && changedField === 'entry'}
                  hasDarkBackground
                />

              </ChangeIndicator>
            </Validator>
          </FlexBox>

          <FlexBox flexDirection="column">
            <Validator
              keys={['Exit', `CoveredAreas[${index}].Exit`]}
              isRelevant={!!checkLink(coveredArea.links, lastRequest)}
              hasDarkBackground>
              <ChangeIndicator path={`coveredAreas[${index}].exit`}>
                <DateTimePicker
                  name={`CA_${index}_ETD`}
                  label="ETD"
                  id={`CA_${index}_ETD`}
                  withTime
                  timeZoneId={coveredArea.exitPoint?.timeZoneId || getLastElement(coveredArea.itinerary)?.port?.timeZoneId}
                  date={exitDate}
                  setDate={date => updateDates({ exit: date })}
                  disabled={loading || !canUpdateArea}
                  loading={loading && !!checkLink(coveredArea.links, lastRequest) && changedField === 'exit'}
                  hasDarkBackground
                />
              </ChangeIndicator>
            </Validator>
          </FlexBox>

          <RemoveContainer>
            <HateoasHandler
              links={coveredArea.links}
              action={QuoteActions.DeleteCoveredArea}
              editVariant={<Close onClick={() => deleteCoveredArea()} />}
            />
          </RemoveContainer>

        </Row>
      )}

      {
        coveredArea?.itinerary?.map((each, i) => (
          <Destination
            key={`${each.id}_${each.entry}`}
            index={i}
            coveredAreaId={coveredArea.id}
            coveredAreaIndex={index}
            destination={each}
            first={first && !isCustomArea && i === 0}
            last={i === (coveredArea?.itinerary?.length ?? 1) - 1}
            exit={last}
          />
        ))
      }

      {isMultiDestination && <DotWrapper cover={last} />}

      <StyledSpacer
        start={isMultiDestination ? 3 : 1}
        background={isTimeException ? theme.lightGreen80 : theme.white}
        borderSides={theme.marineBlue50}
        borderBottom={theme.marineBlue50} />
    </>
  );
};
