/* eslint-disable react/jsx-no-useless-fragment */
import { DestinationOption, ConfigurationOption } from '@/components/pages/bows/details/destinationOption';
import { QuoteActions } from '@/components/pages/bows/details/quoteActionsMap';
import { useHateoasExecutorContext } from '@/services/HateoasExecutorContext';
import { formatLargeInteger } from '@/utility/formatter';
import { Globals } from '@/utility/globals';
import { WarWeb } from '@/war';
import { Add, Load, Remove } from '@instech/icons';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useQuoteContext } from '../../BowSidebar/QuoteContext';
import { Box, BoxContent } from '../../Box';
import { Button } from '../../Button';
import { HateoasHandler } from '../../HateoasHandler';
import { Table, TableCell, TableHeader, TableRow } from '../../Table/Table';
import { useSelectedQuote } from '../useSelectedQuote';
import { ExternalKidnapRansomCoverage } from './ExternalKidnapRansomCoverage';
import { KidnapRansomCoverageTable } from './KidnapRansomCoverageTable';
import { KidnapRansomVoyageSummary } from './KidnapRansomVoyageSummary';
import { useDestinationConfiguration } from '@/utility/destinationsDropdownHelpers';

const WideBox = styled(Box)`
  > div {
    width: 100%;
  }
  ${BoxContent} {
    display: flex;
    flex-direction: column;
    flex: auto;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 1em;
`;

const LoadingAnimation = styled(Load)`
  position: relative;
  top: 2rem;
  height: 40px;
`;

interface KidnapRansomDestinationProps {
  destination: DestinationOption;
}
export const KidnapRansomDestination = ({ destination }: KidnapRansomDestinationProps) => {
  const { selectedConfiguration, isMultiDestination } = useQuoteContext();
  const { hateoasExecutor, loading, whatsLoading } = useHateoasExecutorContext();
  const { configuration } = useDestinationConfiguration(destination.id);

  const selectedQuote = useSelectedQuote();

  const [relevantLinks, setRelevantLinks] = useState<WarWeb.HyperMediaLink[]>();

  useEffect(() => {
    setRelevantLinks(selectedConfiguration === Globals.TotalVoyageId ? selectedQuote?.links : configuration?.knr.links);
  }, [selectedConfiguration, configuration, selectedQuote]);

  const addKnr = () => {
    void hateoasExecutor(relevantLinks, QuoteActions.AddKnr, {}, 'kidnapRansom');
  };

  const removeKnr = () => {
    void hateoasExecutor(relevantLinks, QuoteActions.RemoveKnr, {}, 'kidnapRansom');
  };

  const switchToInternalKnr = () => {
    void hateoasExecutor(
      configuration?.knr.links,
      QuoteActions.SetKnr,
      configuration?.knr.selectedOptions ?? {},
      'kidnapRansom'
    );
  };

  const switchToExternalKnr = () => {
    const existingSettings: WarWeb.SetExternalKnrRequest = {
      sumInsured: configuration?.knr.externalSumInsured?.value ?? null,
    };

    void hateoasExecutor(configuration?.knr.links, QuoteActions.SetExternalKnr, existingSettings, 'kidnapRansom');
  };

  return (
    <WideBox
      header="K&R Coverage"
      shaded={false}
      action={{
        label: (
          <>
            <HateoasHandler
              links={relevantLinks}
              action={QuoteActions.AddKnr}
              editVariant={
                <Button onClick={addKnr} thin rightIcon={<Add />}>
                  {`Add${isMultiDestination && selectedConfiguration === Globals.TotalVoyageId ? ' All' : ''}`}
                </Button>
              }
            />

            <HateoasHandler
              links={relevantLinks}
              action={QuoteActions.RemoveKnr}
              editVariant={
                <Button onClick={removeKnr} thin rightIcon={<Remove />}>
                  {`Remove${isMultiDestination && selectedConfiguration === Globals.TotalVoyageId ? ' All' : ''}`}
                </Button>
              }
            />
          </>
        ),
      }}
    >
      <Content>
        {isMultiDestination && selectedConfiguration === Globals.TotalVoyageId ? (
          <KidnapRansomVoyageSummary />
        ) : (
          <>
            {loading && whatsLoading === 'kidnapRansom' ? (
              <Container>
                <LoadingAnimation />
              </Container>
            ) : (
              configuration?.knr && (
                <>
                  {configuration.knr.knrProvider === 'Internal' ? (
                    <KidnapRansomCoverageTable
                      destination={destination}
                      details={configuration.knr.selectedOptions}
                      options={configuration.knr.availableOptions}
                      links={configuration.knr.links}
                    />
                  ) : (
                    <HateoasHandler
                      links={configuration.knr.links}
                      action={QuoteActions.SetExternalKnr}
                      editVariant={
                        <ExternalKidnapRansomCoverage destination={destination} config={configuration.knr} />
                      }
                      viewVariant={
                        configuration.knr?.externalSumInsured ? (
                          <Container>
                            <Table>
                              <TableRow>
                                <TableHeader>Sum insured</TableHeader>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  {` ≥ ${formatLargeInteger(configuration.knr?.externalSumInsured?.value, 2)} USD`}
                                </TableCell>
                              </TableRow>
                            </Table>
                          </Container>
                        ) : null
                      }
                    />
                  )}
                </>
              )
            )}
          </>
        )}
      </Content>

      {configuration?.knr && (
        <LinkContainer>
          {configuration.knr.knrProvider === 'Internal' ? (
            <HateoasHandler
              links={configuration.knr.links}
              action={QuoteActions.SetExternalKnr}
              editVariant={
                <Button onClick={switchToExternalKnr} thin>
                  Switch to external K&R
                </Button>
              }
            />
          ) : (
            <HateoasHandler
              links={configuration.knr.links}
              action={QuoteActions.SetKnr}
              editVariant={
                <Button onClick={switchToInternalKnr} thin>
                  Switch to internal K&R
                </Button>
              }
            />
          )}
        </LinkContainer>
      )}
    </WideBox>
  );
};
