import { WarWeb } from '@/war';

export const ContractTypes = {
  MinimumProRata: 'Minimum/pro rata',
  ProRata: 'Pro rata',
  // Fixed: 'Fixed' //TODO: Add when backend supports Fixed as agreement contract type
};

export const VoyageContractTypes = {
  MinimumProRata: 'Minimum/pro rata',
  ProRata: 'Pro rata',
  Fixed: 'Fixed',
  MultiDestination: 'Multi destinations',
  Trade: 'Trade'
};

export const excludeFixedContractType = (contractType?: WarWeb.ContractType) => contractType === 'Fixed' ? undefined : contractType;
