import { ActionMeta, SingleValue } from 'react-select';
import styled from 'styled-components';
import { Box } from '@/components/shared/Box';
import { CustomSelect, CustomSelectValue } from '@/components/shared/Form/fields/CustomSelect';
import { Loader } from '@instech/components';
import { useFleetInterestSummary, useFleets } from '@/services/fleetsService';
import { formatInteger } from '@/utility/formatter';
import { Globals } from '@/utility/globals';
import { Validator } from '@/components/shared/Validator';
import { useKnRTermsPageContext } from './context/KnRTermsPageContext';

const ContentContainer = styled.div`
  display: flex;
  padding: 1rem;
  justify-content: space-between;
`;

const DropdownContainer = styled.div`
  width: 350px;
`;

const InsuredContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
`;

const InsuredInfo = styled.div`
  margin: 0 1rem;
`;

const InsuredHeader = styled.div`
  font-size: 14px;
`;

const InsuredValue = styled.div`
  font-size: 18px;
`;

export const Selected = () => {
  const { setFleetId, fleetId } = useKnRTermsPageContext();
  const { data: fleets } = useFleets({ pageSize: 1000 });
  const clientId = fleetId && fleets?.items.filter(x => x.fleetId === fleetId)[0].clientId;
  const { data: interestSummary, isLoading } = useFleetInterestSummary(clientId, fleetId);

  const policyPrefix = interestSummary?.find(x => x.interestNumber === Globals.KnRInterestNumber)?.policyPrefix[0];
  const sumInsured = interestSummary?.find(x => x.interestNumber === Globals.KnRInterestNumber)?.sumInsuredMax;

  const onFleetSelect = (input: SingleValue<CustomSelectValue>, action: ActionMeta<CustomSelectValue>) => {
    if (!input) return;
    setFleetId(input.value);
  };

  const options = fleets?.items.map(f => ({ label: f.name, value: f.fleetId }));

  return (
    <Box header="Selected">
      <ContentContainer>
        <DropdownContainer>
          <Validator keys={['FleetId']}>
            <CustomSelect
              name="fleet"
              placeholder="Select a fleet"
              aria-label="Fleet"
              onChange={onFleetSelect}
              options={options} />
            {' '}
          </Validator>
        </DropdownContainer>
        <InsuredContainer>
          {isLoading ? <Loader size="small" /> : null}
          <InsuredInfo>
            <InsuredHeader>Policy holder</InsuredHeader>
            <InsuredValue>{policyPrefix}</InsuredValue>
          </InsuredInfo>
          <InsuredInfo>
            <InsuredHeader>Sum insured</InsuredHeader>
            <InsuredValue>{formatInteger(sumInsured)}</InsuredValue>
          </InsuredInfo>
        </InsuredContainer>
      </ContentContainer>

    </Box>
  );
};
