import { PageHeader } from '@/components/layout/PageHeader';
import { isInternalUser } from '@/components/routing/roles';
import { Box } from '@/components/shared/Box';
import { Button } from '@/components/shared/Button';
import { Chevron } from '@/components/shared/Chevron';
import { useMe } from '@/services/meService';
import { theme } from '@/utility/theme';
import {
  Briefcase, PrivacyPolicy, UserSingle
} from '@instech/icons';
import { useAccount } from 'its-js-authentication';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BusinessOperationsImpersonator } from '../BusinessOperationsImpersonator';
import { ClientSelector } from '../ClientSelector';
import { ReinsurerImpersonator } from '../ReinsurerImpersonator';
import { UnderwriterImpersonator } from '../UnderwriterImpersonator';

const BoxContent = styled.div`
  padding: 2rem;
`;

const SpacedBox = styled(Box)`
  margin-top: 2rem;
`;

// TODO: One single "revert role" button that automatically uses the correct endpoint based on role?
export const ProfileSettings = () => {
  const { user } = useAccount();
  const { data: me } = useMe();
  const [showOthers, setShowOthers] = useState(false);

  useEffect(() => {
    if (me) setShowOthers(me.impersonated);
  }, [me]);

  return (
    <>
      <PageHeader header="Profile" />
      <Box header="Personal Information" icon={<UserSingle />} highlight={theme.marineBlue}>
        <BoxContent>
          <div>{`Name - ${user?.name}`}</div>
          <div>{`Email - ${user?.email}`}</div>
        </BoxContent>
      </Box>

      {(isInternalUser({ onlyEditors: true }) || me?.impersonated) && (
        <>
          {((me?.impersonated && me.role === 'Broker') || !me?.impersonated) && (
            <SpacedBox header="Impersonate Client" icon={<PrivacyPolicy />} highlight={theme.sanMarino}>
              <BoxContent>
                <ClientSelector />
              </BoxContent>
            </SpacedBox>
          )}

          {!me?.impersonated && (
            <SpacedBox>
              <Button onClick={() => setShowOthers(!showOthers)}>
                Impersonate other roles &nbsp;
                <Chevron bottom={!showOthers} top={showOthers} />
              </Button>
            </SpacedBox>
          )}
          {showOthers && (
            <>
              {((me?.role === 'Admin' || (me?.impersonated && me.role === 'Underwriter')) && (
                <SpacedBox header="Impersonate Underwriter" icon={<Briefcase />} highlight={theme.blueGray}>
                  <BoxContent>
                    <UnderwriterImpersonator />
                  </BoxContent>
                </SpacedBox>
              ))}
              {((me?.impersonated && me.role === 'BusinessOperations') || !me?.impersonated) && (
                <SpacedBox header="Impersonate Business Operations" icon={<Briefcase />} highlight={theme.blueGray}>
                  <BoxContent>
                    <BusinessOperationsImpersonator />
                  </BoxContent>
                </SpacedBox>
              )}
              {((me?.impersonated && me.role === 'Reinsurer') || !me?.impersonated) && (
                <SpacedBox header="Impersonate Reinsurer" icon={<PrivacyPolicy />} highlight={theme.sanMarino}>
                  <BoxContent>
                    <ReinsurerImpersonator />
                  </BoxContent>
                </SpacedBox>
              )}
            </>
          )}
        </>
      )}

    </>
  );
};
