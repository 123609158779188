import { Check } from '@instech/icons';
import React from 'react';
import styled, { css } from 'styled-components';
import { Box } from '@/components/shared/Box';
import {
  getDelimiters, getHeaders, NameGroup, NoDataRow, renderTableSectionHeaders
} from '@/components/shared/Table/core/Components';
import {
  renderTableHeaders, Table, TableCell, TableRow
} from '@/components/shared/Table/Table';
import { useHighRiskAreas } from '@/services/highRiskAreasService';
import { useKnRTermsForFleet } from '@/services/knrtermsService';
import { useVessels } from '@/services/vesselsService';
import { WarWeb } from '@/war';
import { useKnRTermsPageContext } from './context/KnRTermsPageContext';
import { tableSections } from './tableHeaders';

const ContentContainer = styled.div`
  padding: 1rem;
`;

const StyledTableRow = styled(TableRow) <{ selected?: boolean }>`
  ${props => props.selected && css`
    > span {
      background-color: ${props.theme.mediumBlue};
    }
  `};
`;

// TODO: Change number with hash or other unique identifier (id was removed, waiting for backend updates)
const EntriesTableRow = ({ row, mappedVessels, hra }:
  { row: WarWeb.KnrTermResponse, mappedVessels: Map<number, WarWeb.MarineMatch>, hra?: WarWeb.HighRiskArea[] }) => {
  const { entry, setEntry } = useKnRTermsPageContext();

  const vessels = row.target.vesselIds!.map(id => mappedVessels.get(id));
  const vesselNames = vessels.some(v => v?.name) ? vessels.map(v => v?.name) : ['All'];
  const vesselIMOs = vessels.map(v => v?.imo);
  const uniqueVesselTypes = [...new Set(vessels.map(v => v?.vesselTypeName))];

  const currentHra = hra?.find(x => x.id === row.target.highRiskAreaId)?.name;

  return (
    <StyledTableRow onClick={() => setEntry(row.id)} selected={entry === row.id}>
      <TableCell>{row.target.tripType}</TableCell>
      <TableCell>{currentHra}</TableCell>
      <TableCell>{row.target.armedGuardsIncluded && <Check />}</TableCell>
      <TableCell>{row.target.lossOfHireIncluded && <Check />}</TableCell>
      <TableCell>{row.target.lossOfHireDays === 0 ? null : row.target.lossOfHireDays}</TableCell>
      <TableCell delimiter="left"><NameGroup values={vesselNames} /></TableCell>
      <TableCell><NameGroup values={vesselIMOs} /></TableCell>
      <TableCell><NameGroup values={uniqueVesselTypes} /></TableCell>
      <TableCell delimiter="left">{row.additionalGrossPremium}</TableCell>
      <TableCell>{row.lossOfHireRate ? row.lossOfHireRate : undefined}</TableCell>
    </StyledTableRow>
  );
};

export const Entries = () => {
  const { fleetId } = useKnRTermsPageContext();
  const { data: terms, isLoading, error } = useKnRTermsForFleet(fleetId);
  const { data: hra } = useHighRiskAreas();
  const { data: vessels } = useVessels(fleetId ? { fleetId, pageSize: 1000 } : undefined);

  const mappedVessels = new Map(vessels?.items.map(v => [v.vesselId!, v]));

  const tableHeaders = getHeaders(tableSections);
  return (
    <Box header="Entries">
      {terms && terms.length > 0 ? (
        <ContentContainer>
          <Table columns={tableHeaders.length} striped>
            {renderTableSectionHeaders(tableSections)}
            {renderTableHeaders(tableHeaders.map(x => x.title || ''), getDelimiters(tableSections))}
            {terms.map(row => <EntriesTableRow hra={hra} row={row} key={row.id} mappedVessels={mappedVessels} />)}
          </Table>
        </ContentContainer>
      ) : <NoDataRow loadStatus={{ loading: isLoading, success: !error }} />}
    </Box>
  );
};
