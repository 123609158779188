export enum QuoteActions {
  SetVessel = 'SetVessel',
  AddArea = 'AddArea',
  UpdateCoveredArea = 'UpdateCoveredArea',
  DeleteCoveredArea = 'DeleteCoveredArea',
  UpdateDestination = 'UpdateDestination',
  UpdateDestinationTimes = 'UpdateDestinationTimes',
  DeleteDestination = 'DeleteDestination',
  SwapDestinations = 'SwapDestinations',
  SetRebate = 'SetRebate',
  SetReinsurance = 'SetReinsurance',
  SelectRebate = 'SelectRebate',
  SetCoverage = 'SetCoverage',
  SetRate = 'SetRate',
  SetPremium = 'SetPremium',
  SetKnrPremium = 'SetKnrPremium',
  SetKnr = 'SetKnr',
  RemoveKnr = 'RemoveKnr',
  RevalidateQuote = 'RevalidateQuote',
  RequestConfirmation = 'RequestConfirmation',
  AddKnr = 'AddKnr',
  SetExternalKnr = 'SetExternalKnr',
  SetKnrLohRate = 'SetKnrLohRate',
  UpdateComment = 'UpdateComment',
  SetTimeAdjustment = 'SetTimeAdjustment',
  SetDestinationRate = 'SetDestinationRate',
  SetDestinationPremium = 'SetDestinationPremium',
  SetTax = 'SetTax',
  RemoveTax = 'RemoveTax',
  RevertAlternative = 'RevertAlternative',
  CancelAlternative = 'CancelAlternative',
  AddTextCondition = 'AddTextCondition',
  RemoveTextCondition = 'RemoveTextCondition',
  AddRebate = 'AddRebate',
  RemoveRebate = 'RemoveRebate',
  CompleteItineraryEdit = 'CompleteItineraryEdit',
  FinishOverride = 'FinishOverride',
  //
  UnderwriterDocument = 'DocumentUrlNHC_Simple',
  BrokerDocument = 'DocumentUrlClient_Simple',
  KidnapRansomDocument = 'DocumentUrlKnr',
  DeleteAlternative = 'DeleteAlternative',
  GenerateQuote = 'GenerateQuote',
}
