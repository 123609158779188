import { resolvePath } from '@/utility/objectHelpers';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { useChangesContext } from './ChangesContext';

const Container = styled.div`
  & input {
    font-weight: 700;
  }
`;

interface ChangeIndicatorProps {
  path?: string;
}
export const ChangeIndicator = ({ path, children }: PropsWithChildren<ChangeIndicatorProps>) => {
  const { changes } = useChangesContext();

  const isChanged = resolvePath(changes?.primaryQuote, path);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!isChanged) return <>{children}</>;

  return <Container>{children}</Container>;
};
