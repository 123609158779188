/* eslint-disable no-restricted-syntax */
export function addOrRemove(array, value) {
  const index = array.indexOf(value);
  if (index === -1) {
    array.push(value);
  } else {
    array.splice(index, 1);
  }
}

export function unique(arr, keyProps) {
  const keyValueArray = arr.map(entry => {
    const key = keyProps.map(k => entry[k]).join('|');
    return [key, entry];
  });
  const map = new Map(keyValueArray);
  return Array.from(map.values());
}

export function groupBy(array, groupFunction) {
  const groups = {};
  array.forEach(o => {
    const group = JSON.stringify(groupFunction(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return Object.keys(groups).map(group => groups[group]);
}

export const groupByProp = (array, prop) => array ? array.reduce((acc, value) => {
  if (!acc[value[prop]]) acc[value[prop]] = [];
  acc[value[prop]].push(value);
  return acc;
}, {}) : {};

export const isOnlyOption = (array, value) => (array?.length === 1 && array[0] === value);

export const cmp = (a, b) => {
  if (a > b) return +1;
  if (a < b) return -1;
  return 0;
};

export const transpose = obj => {
  if (!obj) return [];
  const newObj = [];
  for (const [key, value] of Object.entries(obj)) {
    for (const [k, v] of Object.entries(value)) {
      newObj[k] = newObj[k] || [];
      newObj[k][key] = v;
    }
  }
  return newObj;
};

export const intersection = (arr1, arr2, key) => {
  if (!arr1 || !arr2) return null;
  if (key == null) {
    return arr1.filter(v => arr2.some(w => w === v));
  }
  if (!arr1[key] || !arr2[key]) return null;
  return arr1.filter(v => arr2.some(w => w[key] === v[key]));
};

export const concatNullsafe = (arr1, arr2) => {
  if (!arr1 && !arr2) return [];
  if (!arr1) return arr2;
  if (!arr2) return arr1;
  return arr1.concat(arr2);
};

export const contains = (arrays, array) => {
  const ordered = JSON.stringify(array.sort());
  let hits = 0;
  arrays.forEach(a => {
    if (JSON.stringify(a.sort()) === ordered) hits = 1;
  });
  return (hits !== 0);
};



export const minus = (a, b) => a?.filter(n => !b?.includes(n));

export const equals = (a, b) => {
  if (!a || !b) return (!a && !b); // null == null
  return JSON.stringify(a.sort()) === JSON.stringify(b.sort());
};

export const sameFields = (a, b) => {
  if (!a || !b) return (!a && !b); // null == null
  return JSON.stringify(a) === JSON.stringify(b);
};

export const max = (obj, fieldName) => {
  if (!obj || obj.length === 0) return null;
  return obj.reduce((prev, current) => (prev[fieldName] > current[fieldName]) ? prev : current); // returns object
};

export const merge = (key, a1, a2) =>
  a1.map(itm => ({
    [key]: itm[key],
    a: { ...a2.find(item => (item[key] === itm[key]) && item) },
    b: { ...itm }
  }));

export const getLastElement = array => Array.isArray(array) && array.length > 0 ? array[array.length - 1] : undefined;

export const sum = arr => arr?.reduce((pv, cv) => parseInt(pv) + parseInt(cv), 0);
