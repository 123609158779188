import { ChildrenProps } from '@/models/utils/ChildrenProps';
import { useEffect } from 'react';
import { isBroker } from '@/components/routing/roles';
import { AgreementDetailsInitializerBroker } from '@/components/pages/agreementDetails/AgreementDetailsInitializerBroker';
import { useAgreementsPageContext } from '../agreements/AgreementsPageContext';
import { AgreementDataProvider } from './AgreementDataContext';
import { AgreementDetailsProvider } from './AgreementDetailsContext';
import { AgreementDetailsInitializer } from './AgreementDetailsInitializer';
import { AgreementSelectionProvider } from './AgreementSelectionContext';
import { AgreementDataProviderBroker } from './AgreementDataContextBroker';

export const AgreementDetailsPage = ({ children }: ChildrenProps) => {
  const { setShowDeleteStatus } = useAgreementsPageContext();

  useEffect(() => {
    setShowDeleteStatus(false);
  }, []);

  return (
    <AgreementSelectionProvider>
      <AgreementDetailsProvider>
        {isBroker() ? (
          <AgreementDataProviderBroker>
            <AgreementDetailsInitializerBroker>
              {children}
            </AgreementDetailsInitializerBroker>
          </AgreementDataProviderBroker>
        ) : (
          <AgreementDataProvider>
            <AgreementDetailsInitializer>
              {children}
            </AgreementDetailsInitializer>
          </AgreementDataProvider>
        )}

      </AgreementDetailsProvider>
    </AgreementSelectionProvider>
  );
};
