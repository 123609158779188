import { BowActions } from '@/components/pages/bows/details/bowActionsMap';
import { QuoteActions } from '@/components/pages/bows/details/quoteActionsMap';
import { getLabel, QuoteLabel } from '@/components/pages/bows/details/quoteLabelMap';
import { steps } from '@/components/pages/create-bow/bowSteps';
import { useBow } from '@/services/bowServices';
import { useHateoasExecutorContext } from '@/services/HateoasExecutorContext';
import { bowStatus } from '@/utility/bowStatus';
import { theme } from '@/utility/theme';
import { TimeConverter } from '@/utility/timeConverter';
import { UserInitials } from '@/utility/userNameHelpers';
import { WarWeb } from '@/war';
import { TimeMachine, Trash } from '@instech/icons';
import { PropsWithChildren, useMemo } from 'react';
import styled from 'styled-components';
import { Button } from '../Button';
import { HateoasHandler } from '../HateoasHandler';
import { NoDataRow } from '../Table/Table';
import { Validator } from '../Validator';
import { useQuoteContext } from './QuoteContext';
import { SidebarCoverageItem } from './SidebarCoverageItem';
import { SidebarItineraryItem } from './SidebarItineraryItem';
import { SidebarPremiumItem } from './SidebarPremiumItem';
import { SidebarVesselItem } from './SidebarVesselItem';
import { HateoasExecutorType } from '@/services/HateoasExecutorContext';

const StyledSidebar = styled.div<{ background?: string }>`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.background || props.theme.white};
  border-radius: 2px;

  pre {
    padding-left: 1em;
    font-size: 10px;
  }
`;

const SidebarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.theme.marineBlue50};
  border-radius: 2px 2px 0 0;
  padding: 0 1em;

  img {
    height: 27px;
  }

  h1 {
    font-size: 18px;
    font-weight: 600;
    color: ${props => props.theme.white};
    padding-left: 1em;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SidebarContent = styled.div<{ compact: boolean, orientation?: string }>`
  display: flex;
  flex-direction: ${props => props.orientation ?? 'column'};
  justify-content: space-between;
  padding: 0 1em 1em ${props => props.compact ? '2em' : '1em'};
`;

const Content = styled.div`
  margin-top: 1rem;
`;

const Metadata = styled.div` 
  font-size: 14px;
  font-weight: lighter;
  color: ${props => props.theme.marineBlue75};
`;

interface SidebarProps {
  background?: string;
  header?: boolean;
  orientation?: string;
  compact?: boolean;
  confirmStatusChange?: (links: WarWeb.HyperMediaLink[], action: string) => {};
  hateoasExecutor?: HateoasExecutorType;
  warningsPaths?: (string | undefined)[];
  warningsAreRegex?: boolean;
}
export const Sidebar = ({
  background,
  header = false,
  compact = false,
  orientation,
  confirmStatusChange,
  hateoasExecutor,
  warningsPaths,
  warningsAreRegex = false,
  children
}: PropsWithChildren<SidebarProps>) => {
  const { ids } = useQuoteContext();
  const { bowId, quoteId } = ids;
  const { loading } = useHateoasExecutorContext();
  const { data, isLoading, error } = useBow(bowId);

  const quote: WarWeb.BowQuote | undefined = useMemo(
    () => data?.quotes.find((q: WarWeb.BowQuote) => q.id === quoteId),
    [data, quoteId]
  );

  const overallWarningKeys = steps.map(s => s.key);

  return (
    <StyledSidebar background={background}>
      {header && (
        <SidebarHeader>
          <Wrapper>
            <img src="images/bow.png" alt="Your Quote" />
            <h1>Your Quote</h1>
          </Wrapper>

          <Validator popup keys={overallWarningKeys} onlyWarnings />
        </SidebarHeader>
      )}
      {isLoading ?
        <NoDataRow loadStatus={{ loading: isLoading, success: !error }} />
        : (
          <>
            {quote && (
              <SidebarContent compact={compact} orientation={orientation}>

                {!compact && quote.coverageDetails && <SidebarCoverageItem hateoasExecutor={hateoasExecutor} />}

                {quote.vessel && (
                  <SidebarVesselItem
                    compact={compact}
                    warningsPath={warningsPaths && warningsPaths.length > 0 ? warningsPaths[0] : undefined}
                    warningsAreRegex={warningsAreRegex}
                  />
                )}

                {quote.coveredAreas && (
                  <SidebarItineraryItem
                    compact={compact}
                    coveredAreas={quote.coveredAreas}
                    links={quote?.links}
                    warningsPath={warningsPaths && warningsPaths.length > 0 ? warningsPaths[1] : undefined}
                    warningsAreRegex={warningsAreRegex}
                  />
                )}

                {quote.calculations && (
                  <SidebarPremiumItem
                    compact={compact}
                    warningsPath={warningsPaths && warningsPaths.length > 0 ? warningsPaths[2] : undefined}
                    warningsAreRegex={warningsAreRegex}
                  />
                )}

                <Content>
                  {children}
                </Content>

                {!compact && confirmStatusChange && (
                  <Content>
                    <HateoasHandler
                      links={quote?.links}
                      action={QuoteActions.CancelAlternative}
                      editVariant={(
                        <Button
                          onClick={() => confirmStatusChange(quote?.links, QuoteLabel.CancelAlternative)}
                          disabled={loading}
                          icon={<Trash />}
                          background={theme.lightBlue}
                        >
                          {`${getLabel(QuoteLabel.CancelAlternative)} ${bowStatus.find(n => n.title === quote?.status)?.noun}`}
                        </Button>
                      )}
                    />
                  </Content>
                )}

                {confirmStatusChange && (
                  <Content>
                    <HateoasHandler
                      links={quote?.links}
                      action={QuoteActions.RevertAlternative}
                      editVariant={(
                        <Button
                          onClick={() => confirmStatusChange(quote?.links, QuoteActions.RevertAlternative)}
                          disabled={loading}
                          icon={<TimeMachine />}
                          background={theme.lightBlue}
                        >
                          Revert status
                        </Button>
                      )}
                    />
                    <HateoasHandler
                      links={data?.links}
                      action={BowActions.RevertBow}
                      editVariant={(
                        <Button
                          onClick={() => confirmStatusChange(data?.links || [], BowActions.RevertBow)}
                          icon={<TimeMachine />}
                          background={theme.lightBlue}
                        >
                          Revert status
                        </Button>
                      )}
                    />
                  </Content>
                )}

                {!compact && confirmStatusChange && (
                  <Content>
                    <HateoasHandler
                      links={quote?.links}
                      action={QuoteActions.DeleteAlternative}
                      editVariant={(
                        <Button
                          onClick={() => confirmStatusChange(quote?.links, QuoteActions.DeleteAlternative)}
                          disabled={loading}
                          icon={<Trash />}
                          background={theme.mediumRed}
                        >
                          Delete alternative
                        </Button>
                      )}
                    />
                    <HateoasHandler
                      links={data?.links}
                      action={BowActions.DeleteBow}
                      editVariant={(
                        <Button
                          onClick={() => confirmStatusChange(data?.links || [], BowActions.DeleteBow)}
                          disabled={loading}
                          icon={<Trash />}
                          background={theme.mediumRed}
                        >
                          Delete BOW
                        </Button>
                      )}
                    />
                  </Content>
                )}

                {!compact && (
                  <Content>
                    <Metadata>
                      <div>
                        {`Last changed ${TimeConverter.ToDateTime(quote.lastUpdated?.dateTime)} by`}
                        &nbsp;
                        <UserInitials name={quote.lastUpdated?.user.name} />
                      </div>
                      <div>
                        {`Created ${TimeConverter.ToDateTime(quote.created?.dateTime)} by`}
                        &nbsp;
                        <UserInitials name={quote.created?.user.name} />
                      </div>
                    </Metadata>
                  </Content>
                )}
              </SidebarContent>
            )}
          </>
        )}
    </StyledSidebar>
  );
};
