import { Box } from '@/components/shared/Box';
import {
  NoDataRow,
  renderTableHeaders, Table, TableCell, TableRow
} from '@/components/shared/Table/Table';
import { useArea } from '@/services/areasService';
import { useKnRTermsForFleet } from '@/services/knrtermsService';
import { WarWeb } from '@/war';
import { Check } from '@instech/icons';
import React from 'react';
import styled from 'styled-components';
import { useAgreementSelectionContext } from '../../agreementDetails/AgreementSelectionContext';

const Container = styled.div`
  margin-top: 2rem;
`;

const headers = ['Voyage', 'Armed guards', 'Loss of hire', 'LOH days', 'Cost (USD)'];

const KnRRow = ({ row }: { row: WarWeb.KnrTermResponse }) => (
  <TableRow>
    <TableCell>{row.target.tripType}</TableCell>
    <TableCell>{row.target.armedGuardsIncluded && <Check />}</TableCell>
    <TableCell>{row.target.lossOfHireIncluded && <Check />}</TableCell>
    <TableCell>{row.target.lossOfHireDays === 0 ? null : row.target.lossOfHireDays}</TableCell>
    <TableCell>{row.additionalGrossPremium}</TableCell>
  </TableRow>
);

export const KnRTable = () => {
  const { vesselIds, areaIds, fleetIds } = useAgreementSelectionContext();
  const { data: area } = useArea(areaIds[0]);
  const { data: terms, isLoading, error } = useKnRTermsForFleet(fleetIds[0]);
  const relevantTerms = terms?.filter(x => x.target.vesselIds?.length === 0 || vesselIds.some(v => x.target.vesselIds?.includes(v)))
    .filter(knr => knr.target.highRiskAreaId === area?.highRiskAreaId);

  if (!relevantTerms || relevantTerms.length < 1) return null;

  return (
    <Container>
      <Box header="K&R Coverage" shaded={false}>
        {terms && terms.length > 0 ? (
          <Table columns={headers.length}>
            {renderTableHeaders(headers)}
            {relevantTerms?.map(row => <KnRRow key={row.id} row={row} />)}
          </Table>
        ) : <NoDataRow size="small" loadStatus={{ loading: isLoading, success: !error }} />}
      </Box>
    </Container>
  );
};
