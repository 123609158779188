import { Check } from '@instech/icons';
import React from 'react';
import styled from 'styled-components';
import { Divider } from '@instech/components';
import { TextField } from '@/components/shared/Form/FormFields';
import {
  Table, TableCell, TableRow
} from '@/components/shared/Table/Table';
import { ContractType, CoverageSummary } from '@/models/Agreement';
import { ValidationMessages } from '@/models/ValidationMessages';
import { ContractTypes } from '@/utility/contractTypes';
import {
  formatInteger, formatRange, getInputValue
} from '@/utility/formatter';
import { Validator } from '@/components/shared/Validator';
import { AgreementViewMode, useAgreementDetailsContext } from '@/components/pages/agreementDetails/AgreementDetailsContext';
import { MaxNumberTimeValue } from '@/utility/globals';
import { TableHeaderTitle } from '../../shared/SettingsComponents';

const ErrorCell = styled(TableCell)`
  color: #AD1F23;
  font-size: 14px;
`;

interface ContractTypeTableProps {
  coverage: CoverageSummary;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errors?: ValidationMessages;
}
export const ContractTypeTable = ({ coverage, handleInputChange, errors }: ContractTypeTableProps) => {
  const { viewMode } = useAgreementDetailsContext();
  const contractType = coverage.contractType.value ? ContractTypes[coverage.contractType.value] : '';

  const renderContractTypeRow = (type: ContractType) => (
    <TableRow>
      <TableCell>{ContractTypes[type]}</TableCell>
      <TableCell>
        <input
          onChange={handleInputChange}
          type="radio"
          name="contractType"
          value={type}
          checked={coverage.contractType.value === type}
        />
      </TableCell>
    </TableRow>
  );

  return (
    <Table>
      <TableHeaderTitle>Contract type</TableHeaderTitle>

      {viewMode === AgreementViewMode.Inspect ? (
        <Table columns={2} striped>
          {(contractType || coverage.contractType.rangeMinimum || coverage.contractType.rangeMaximum) && (
            <TableRow>
              <TableCell>{contractType || `${coverage.contractType.rangeMinimum}, ${coverage.contractType.rangeMaximum}`}</TableCell>
              <TableCell><Check /></TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>Maximum pro rata days</TableCell>
            <TableCell>{formatRange(coverage.maximumProRataDays, formatInteger) ?? coverage.maximumProRataDays.value}</TableCell>
          </TableRow>
        </Table>
      ) : (
        <>
          <Table columns={2} striped>
            {renderContractTypeRow('MinimumProRata')}
            {renderContractTypeRow('ProRata')}
            {/* {renderContractTypeRow('Fixed')} */}
            {errors && errors['Coverage.ContractType'] && <TableRow><ErrorCell span={2}>{errors['Coverage.ContractType']}</ErrorCell></TableRow>}
          </Table>

          <Table columns={2}>
            <TableRow><TableCell span={2}><Divider /></TableCell></TableRow>
            <TableRow>
              <TableCell>Maximum pro rata days</TableCell>
              <TableCell>
                <Validator keys={['Coverage.MaximumProRataDays']}>
                  <TextField
                    type="number"
                    maxValue={MaxNumberTimeValue}
                    numberOfCharacters={5}
                    placeholder={formatRange(coverage.maximumProRataDays)}
                    name="maximumProRataDays"
                    onChange={handleInputChange}
                    dirty={coverage.maximumProRataDays.isDirty}
                    value={getInputValue(coverage.maximumProRataDays.value)} />
                </Validator>
              </TableCell>
            </TableRow>
          </Table>
        </>
      )}

    </Table>
  );
};
