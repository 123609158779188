import React from 'react';
import {
  Switch, Route, Redirect
} from 'react-router';
import Qs from 'qs';
import { Routes } from '@/components/routing/routes';
import { useQuoteContext } from '@/components/shared/BowSidebar/QuoteContext';
import { steps } from './bowSteps';
import { VesselStep } from './VesselStep';
import { ItineraryStep } from './ItineraryStep';
import { PremiumStep } from './PremiumStep';
import { TermsAndConditionsStep } from './TermsAndConditionsStep';

interface WizardRouterProps {
  resolveStep: () => number;
}

const ResolveRoute = ({ resolveStep }: WizardRouterProps) => {
  const { ids } = useQuoteContext();
  const { bowId, quoteId } = ids;

  const foundStep = resolveStep();
  return <Redirect to={`${Routes.createbow}/${steps[foundStep].path}?${Qs.stringify({ bowId, quoteId })}`} />;
};

export const WizardRoutes = ({ resolveStep }: WizardRouterProps) => (
  <Switch>
    <Route exact path={`${Routes.createbow}`}>
      <ResolveRoute resolveStep={resolveStep} />
    </Route>
    <Route exact path={`${Routes.createbow}/${steps[0].path}`}>
      <VesselStep />
    </Route>
    <Route exact path={`${Routes.createbow}/${steps[1].path}`}>
      <ItineraryStep />
    </Route>
    <Route exact path={`${Routes.createbow}/${steps[2].path}`}>
      <PremiumStep />
    </Route>
    <Route exact path={`${Routes.createbow}/${steps[3].path}`}>
      <TermsAndConditionsStep />
    </Route>
  </Switch>
);
