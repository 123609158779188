import { Download, NhcLogoLarge } from '@instech/icons';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { useQuoteContext } from '@/components/shared/BowSidebar/QuoteContext';
import { MediaType } from '@/services/config';
import { addTimezoneOffset, parseBlobData } from '@/utility/downloadHelper';
import { theme } from '@/utility/theme';
import { WarWeb } from '@/war';
import { TimeConverter } from '@/utility/timeConverter';
import { useHateoasExecutorContext } from '@/services/HateoasExecutorContext';
import { ButtonShape, ButtonWithLoader } from '@/components/shared/Button';
import { getDocumentUri } from '@/services/legalDocumentsService';
import { RichTextView } from '@/components/shared/Form/RichTextForm/RichTextView';
import { DocumentationSet } from './documentationSet';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/-1;
  padding: 2rem 0;
`;

const Split = styled.div`
  display: flex;
  justify-content: space-between;

  >div:first-child {
    border-right: 1px solid ${theme.border.gray};
  }
`;

const ImageContainer = styled.div<{ isPlaceholder: boolean }>`
  height: 50%;
  align-items: center;
  display: flex;

  ${props => props.isPlaceholder && css`
    img {
      max-height: 32px;
    }
  `};
`;

const Card = styled.div`
  display: flex;
  width: 50%;
  padding: 0 4rem;
  justify-content: space-between;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  img {
      height: 60px;
  }
`;

const Content = styled.div`
  display: flex;
  padding-right: 4rem;
`;

const Information = styled.div`
  display: flex;
`;

const DownloadLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 1rem;
`;

const DownloadContainer = styled.div<{ small?: boolean }>` 
  width: 100%;

  ${ButtonShape} {
    color: ${theme.sanMarino};
    justify-content: flex-start;
    padding: 1em 0.5rem;
    ${props => props.small && css`
      font-weight: normal;
      font-size: 12px;
      text-transform: none;
    `}


    &:hover {
      background-color: ${props => props.theme.ultraLightGray};
    }
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
`;

const StyledDownload = styled(Download)`
  display: flex;
  stroke-width: 3px;
  width: 14px;
  margin-right: 1rem;
`;

const LegalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
`;

const LegalPurpose = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${theme.marineBlue};
`;

const LegalText = styled.div`
  font-size: 16px;
  color: ${theme.marineBlue};
  width: 400px;
  padding-bottom: 1em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const LegalExpiry = styled.div`
  padding-top: 3px;
  font-size: 14px;
  opacity: 0.75;
  color: ${theme.marineBlue};
`;

interface DocumentationDetailsProps {
  documentSetId: string;
  logo: React.ReactElement;
  variant: DocumentationVariant;
  comment: string;
  expiration: string;
  validity?: string;
}

export const DocumentationDetails: FC<DocumentationDetailsProps> = ({ documentSetId, logo, variant, comment, expiration, validity }) => {
  const { ids, quoteDocuments } = useQuoteContext();
  const { hateoasExecutor } = useHateoasExecutorContext();
  const { bowId, quoteId } = ids;
  if (!bowId || !quoteId || !documentSetId) return null;

  const getType = (type: string) => {
    if (type === 'Simple') return (variant === 'nhc' ? 'NhcSimple' : 'ClientSimple');
    if (type === 'Full') return (variant === 'nhc' ? 'NhcFull' : 'ClientFull');
    return ('Knr');
  };

const hasKnrDocument = quoteDocuments?.some((item: WarWeb.LegalDocument) => item.documentSetId === documentSetId && item.type === 'Knr');

  const downloadDocument = async (href: string) => {
    const links = [{
      action: 'DocumentUrl',
      httpVerb: 'GET',
      href: `/${addTimezoneOffset(href)}`
    }];

    const response = await hateoasExecutor(links, 'DocumentUrl', {}, 'documentation', {
      headers: {
        Accept: MediaType.Pdf
      },
      responseType: 'blob'
    });

    if (response) parseBlobData(response, MediaType.Pdf);
  };

  const downloadAll = () => {
    void downloadDocument(getDocumentUri(bowId, quoteId, documentSetId, getType('Simple')));
    void downloadDocument(getDocumentUri(bowId, quoteId, documentSetId, getType('Full')));
    if (hasKnrDocument) void downloadDocument(getDocumentUri(bowId, quoteId, documentSetId, getType('Knr')));
  };

  const expired = validity ? TimeConverter.ToDateTime(validity) : (expiration && TimeConverter.ToDateTime(expiration));
  const expiredDate = expired ? `Quote validity: ${expired}` : '';

  return (
    <>
      <Details>
        <ImageContainer isPlaceholder={variant === 'broker'}>{logo}</ImageContainer>
        <Information>
          <LegalContent>
            {variant === 'nhc' ? (
              <LegalPurpose>For legal purposes</LegalPurpose>
            ) : (
              <LegalPurpose>Not for legal purposes</LegalPurpose>
            )}
            <LegalText><RichTextView text={comment} /></LegalText>
            <LegalExpiry>{expiredDate}</LegalExpiry>
          </LegalContent>
        </Information>
      </Details>
      <Content>
        <DownloadLinks>
          {/* TODO: Implement Resend functionality */}
          {/* <DownloadContainer>
            <Actions>
              <Download />
            </Actions>
            Resend
          </DownloadContainer> */}

          <DownloadContainer>
            <ButtonWithLoader onClick={() => downloadDocument(getDocumentUri(bowId, quoteId, documentSetId, getType('Full')))}>
              <Actions>
                <StyledDownload />
                Full Calculation
              </Actions>
            </ButtonWithLoader>
          </DownloadContainer>

          <DownloadContainer>
            <ButtonWithLoader onClick={() => downloadDocument(getDocumentUri(bowId, quoteId, documentSetId, getType('Simple')))}>
              <Actions>
                <StyledDownload />
                Simplified Calculation
              </Actions>
            </ButtonWithLoader>
          </DownloadContainer>

          {hasKnrDocument && (
            <DownloadContainer>
              <ButtonWithLoader onClick={() => downloadDocument(getDocumentUri(bowId, quoteId, documentSetId, getType('Knr')))}>
                <Actions>
                  <StyledDownload />
                  K&R

                </Actions>
              </ButtonWithLoader>
            </DownloadContainer>
          )}

          <DownloadContainer>
            <ButtonWithLoader onClick={() => downloadAll()}
            >
              <Actions>
                <StyledDownload />
                Download all
              </Actions>
            </ButtonWithLoader>
          </DownloadContainer>

        </DownloadLinks>
      </Content>
    </>
  );
};
type DocumentationVariant = 'nhc' | 'broker';

interface DocumentationCardProps {
  documentation: DocumentationSet;
  comment: string;
  expiration: string;
}
export const DocumentationCard: FC<DocumentationCardProps> = ({ documentation, comment, expiration }) => (
  <Container>
    <Split>
      <Card>
        <DocumentationDetails
          documentSetId={documentation.documentSetId}
          validity={documentation.validity}
          variant="nhc"
          logo={<NhcLogoLarge />}
          comment={documentation?.comment ?? comment}
          expiration={expiration}
        />
      </Card>
      <Card>
        <DocumentationDetails
          documentSetId={documentation.documentSetId}
          validity={documentation.validity}
          variant="broker"
          logo={<img src="images/BROKER.png" alt="Broker logo" />}
          comment={documentation?.comment ?? comment}
          expiration={expiration}
        />
      </Card>
    </Split>
  </Container>
);
