import { Header } from '@/components/layout/Header';
import { FlexBox } from '@/components/shared/FlexBox';
import { ChildrenProps } from '@/models/utils/ChildrenProps';
import styled from 'styled-components';

const Page = styled.div`
  background: ${props => props.theme.whiteBlue};
  min-height: 100vh;
`;

const PageContent = styled(FlexBox)`
  min-height: 100vh;
  max-width: 3000px;
`;

const Background = styled.div`
  background-image: url('/water_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 70%;
  flex: 1;
  max-width: 870px;
`;

const Content = styled.div`
  background: ${props => props.theme.whiteBlue};
  flex: 1;
`;

const ContentArea = styled(FlexBox)`
  max-width: 516px;
  margin: 40px;
  margin-top: 145px;
  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    margin: 145px;
  }
`;

export const AcceptTermsPageLayout = ({ children }: ChildrenProps) => (
  <Page>
    <Header hideMenu />
    <PageContent alignItems="stretch">
      <Background />
      <Content>
        <ContentArea alignItems="flex-start" justifyContent="space-between" flexDirection="column">
          {children}
        </ContentArea>
      </Content>
    </PageContent>
  </Page>
);
