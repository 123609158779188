import React, { useState } from 'react';
import { AcceptTermsPageLayout } from '@/components/layout/acceptTerms/AcceptTermsLayout';
import styled from 'styled-components';
import { Button } from '@/components/shared/Button';
import { useModalContext } from '@/components/modal/ModalContext';
import { useAccount } from 'its-js-authentication';
import { formatDate } from '@/utility/dateCalc';
import moment from 'moment';
import { Routes } from '@/components/routing/routes';
import { useHistory } from 'react-router';
import { theme } from '@/utility/theme';
import { showTermsOfUseModal } from './TermsOfUse';

const termsOfUseIntroductionText = 'The Norwegian Hull Club War portal is a 100% transparent collaboration tool, where Norwegian Hull Club\'s war client ' +
  'can access up-to-date terms and conditions for any excluded area at any time for all vessels on a fleet.';

const TextLink = styled.div`
  font-size: 16px;
  display: block;
  color: ${props => props.theme.deepAzureBlue};
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 30px;
  line-height: 28px;
`;

const AcceptTermsProduct = styled.h1`
  font-size: 40px;
  font-weight: unset;
  margin: 0px;
`;

const AcceptTermsTitle = styled.h1`
  font-size: 45px;
  font-weight: unset;
  margin: 0px 0px 20px 0px;
`;

const AcceptTermsText = styled.p`
  font-size: 16px;
  line-height: 28px;
`;

const acceptButtonText = 'I HAVE READ AND ACCEPTED THE TERMS OF USE';

const AcceptButton = ({ submitting, onClick }: { submitting: boolean, onClick: () => void }) => (
  <Button
    background={theme.marineBlue}
    color={theme.whiteBlue}
    onClick={onClick}
  >
    {acceptButtonText}
  </Button>
);

export const AcceptTermsPage = () => {
  const [submitting, setSubmitting] = useState(false);
  const { open } = useModalContext();
  const history = useHistory();
  const { user } = useAccount();
  const today = formatDate(new Date());

  const handleAccept = async () => {
    const accepted = JSON.stringify({ user: user?.name, accepted: today });
    localStorage.setItem('acceptedTerms', accepted);
    history.push(Routes.base);
    setSubmitting(false);
    window.location.reload();
  };

  return (
    <AcceptTermsPageLayout>
      <AcceptTermsProduct>War Portal</AcceptTermsProduct>
      <AcceptTermsTitle>Terms of Use</AcceptTermsTitle>
      <AcceptTermsText>{termsOfUseIntroductionText}</AcceptTermsText>

      <TextLink onClick={() => open(showTermsOfUseModal)}>Read Terms of Use</TextLink>

      <AcceptButton submitting={submitting} onClick={handleAccept} />
    </AcceptTermsPageLayout>
  );
};
