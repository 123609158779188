import {
  createContext, FC, useContext, useState, useEffect
} from 'react';
import { Filters } from '@/models/Filters';
import { CountrySelection } from '../pages/specificTermsConditions/TermsConditionsPageContext';

// TODO: Maybe achieve separate interfaces for different types of tables...??
interface BaseSettings {
  filters?: Filters;
  scrollPosition?: number;
}

interface ExpandableSettings extends BaseSettings {
  expanded?: CountrySelection[];
}

interface PageSettings {
  [key: string]: ExpandableSettings;
}

interface PageSettingsProviderProps {
  pageSettings?: PageSettings;
  setPageSettings: (pageSettings: PageSettings) => void;
  pageKey?: string;
  setPageKey: (pageKey?: string) => void;
}

const PageSettingsContext = createContext<PageSettingsProviderProps>({
  setPageSettings: () => { },
  setPageKey: () => { }
});

export const PageSettingsProvider: FC<PageSettingsProviderProps> = ({ pageSettings, pageKey, setPageKey, setPageSettings, children }) => {
  const [pageSettingsLocal, setPageSettingsLocal] = useState<PageSettings>();
  const [pageKeyLocal, setPageKeyLocal] = useState<string>();

  useEffect(() => {
    const handleScroll = () => {
      if (pageKeyLocal) {
        const scrollPos = window.scrollY;
        if (scrollPos === 0) return;

        setPageSettingsLocal(
          pageSettingsLocal ?
            {
              [pageKeyLocal]: {
                ...pageSettingsLocal[pageKeyLocal],
                scrollPosition: scrollPos
              }
            }
            :
            {
              [pageKeyLocal]: {
                scrollPosition: scrollPos
              }
            }
        );
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  return (
    <PageSettingsContext.Provider value={{
      pageSettings: pageSettingsLocal, setPageSettings: setPageSettingsLocal, pageKey: pageKeyLocal, setPageKey: setPageKeyLocal
    }}>
      {children}
    </PageSettingsContext.Provider>
  );
};

export const usePageSettingsContext = () => useContext(PageSettingsContext);
