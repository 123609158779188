import { useSWREndpoint } from '@/hooks/useSWREndpoint';
import { WarWeb } from '@/war';
import { postData } from './utility/simpleFetch';

const basepath = 'textconditions';

export const useTextConditions = () => useSWREndpoint<WarWeb.Term[]>(basepath);

export const upsertTextCondition = async (params: WarWeb.UpsertTextTermRequest) =>
  postData<WarWeb.UpsertTextTermRequest>(basepath, params);
