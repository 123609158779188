import React, {
  PropsWithChildren, useEffect, useState
} from 'react';
import styled from 'styled-components';
import { useHateoasExecutorContext } from '@/services/HateoasExecutorContext';
import { HateoasHandler } from '@/components/shared/HateoasHandler';
import { DirtyableValue } from '@/models/utils/DirtyableValue';
import { useValidationContext } from '@/services/ValidationContext';
import { Validator } from '../Validator';
import { NumberField } from '../Form/fields/NumberField';

export const EditItemWrapper = styled.div`
  display: flex;

  h2 {
    align-self: center;
    font-size: 16px;
    font-weight: 300;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

interface SidebarEditFieldProps {
  currency?: string;
  value?: number;
  name: string;
  action: string;
  links?: any[];
  makeRequest: (value?: number) => {};
  active: boolean;
  close: () => void;
  errorKey: string;
  isRelevant: boolean;
}

export const SidebarEditField = ({
  currency,
  value,
  name,
  action,
  links,
  makeRequest,
  active,
  close,
  errorKey,
  isRelevant,
  children
}: PropsWithChildren<SidebarEditFieldProps>) => {
  const { hateoasExecutor } = useHateoasExecutorContext();
  const { errors } = useValidationContext();
  const [editValue, setEditValue] = useState<DirtyableValue<string>>({ value: value?.toString() });

  useEffect(() => {
    setEditValue({ value: value?.toString(), isDirty: false });
  }, [value, errors]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditValue({ value: e.target.value, isDirty: true });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      (e.target as HTMLInputElement).blur();
    }
  };

  const commitValue = () => {
    if (editValue.isDirty) {
      if (!editValue.value) {
        setEditValue({ value: value?.toString(), isDirty: false }); // reset to previous value
        return;
      }
      void hateoasExecutor(links, action, makeRequest(parseFloat(editValue.value)), 'calculations');
    }
    close();
  };

  const hasError = errors && isRelevant && !!errors[errorKey];

  return (
    <EditItemWrapper>
      <HateoasHandler
        links={active || hasError ? links : []}
        action={action}
        editVariant={(
          <Container>
            <Validator keys={[errorKey]} isRelevant={isRelevant}>
              <NumberField
                step={0.01}
                name={name}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                noErrors
                onBlur={commitValue}
                postfix={currency}
                value={editValue.value}
                dirty={editValue.isDirty}
              />
            </Validator>
          </Container>
        )}
        viewVariant={children}
      />
    </EditItemWrapper>
  );
};
