import { WarningFilled } from '@instech/icons';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { ValidationMessages } from '@/models/ValidationMessages';
import { isEmpty } from '@/utility/objectHelpers';
import { theme } from '@/utility/theme';
import { useValidationContext } from '@/services/ValidationContext';
import { fieldNameFilter } from '@/utility/errorHelpers';

enum AlertLevel {
  Error = 'error',
  Warning = 'warning',
  Info = 'info'
}

const statusColors = {
  [AlertLevel.Error]: {
    primary: `${theme.status.darkRed}`,
    background: '#FAC2B5'
  },
  [AlertLevel.Warning]: {
    primary: `${theme.yellow}`,
    background: '#ffe8b6'
  },
  [AlertLevel.Info]: {
    primary: `${theme.marineBlue}`,
    background: '#bfcedb'
  }
};

const Container = styled.div`

  .tooltip {
    max-width: 400px;
    line-height: 1.3em;
    white-space: normal;
    color: ${props => props.theme.marineBlue};
    box-shadow: 3px 6px 20px 12px rgba(72,135,177,0.2);
    border-left: 5px solid ${props => props.theme.status.yellow};
    opacity: 1 !important;
    padding: 1em;
    font-size: 14px;

    strong {
      text-transform: capitalize;
    }
  }

  padding-left: 0.5em;
  font-weight: normal;

  b {
    text-transform: capitalize;
  }
`;

const StyledWarningFilled = styled(WarningFilled)`
  color: ${props => props.theme.status.yellow};
`;

interface WarningsContainerProps {
  path?: string;
  warningsContent?: ValidationMessages;
}

export const WarningsContainer = ({ path, warningsContent }: WarningsContainerProps) => {
  const randomID = String(Math.random());

  if (!warningsContent || isEmpty(warningsContent)) return null;

  return (
    <Container>
      <StyledWarningFilled data-for={randomID} data-tip="null" />
      <ReactTooltip id={randomID} effect="solid" backgroundColor={statusColors.warning.background} className="tooltip">
        {Object.keys(warningsContent).sort((a, b) => a.split('.').length - b.split('.').length)
          .map(fieldName => {
            const lastPart = fieldName.lastIndexOf('.');
            // eslint-disable-next-line no-nested-ternary
            const startPos = lastPart ? lastPart + 1 : path ? path.length + 1 : 0;
            const suffix = fieldName.substring(startPos ?? 0).replace(/([A-Z])/g, ' $1');

            const label = suffix ? `${suffix}: ` : '';
            const numMessages = warningsContent[fieldName].length;

            return (
              <div key={fieldName}>
                {fieldName && <b>{label}</b>}
                {warningsContent[fieldName].map((s, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <span key={`${fieldName}-${i}`}>
                    {s}
                    {i < numMessages - 1 && (<>.&nbsp;</>)}
                  </span>
                ))}
              </div>
            );
          })}
      </ReactTooltip>
    </Container>
  );
};

interface WarningsProps {
  path?: string;
}

// TODO: Replace by Validator summary??
export const Warnings = ({ path }: WarningsProps) => {
  const { warnings } = useValidationContext();

  if (!warnings || !path) return null;

  const filtered: ValidationMessages | undefined = fieldNameFilter(warnings, path, true)
    // https://stackoverflow.com/questions/38750705/filter-object-properties-by-key-in-es6
    .reduce((obj: any, key: string) => {
      // eslint-disable-next-line no-param-reassign
      obj[key] = warnings[key];
      return obj;
    }, {});

  return (
    <WarningsContainer path={path} warningsContent={filtered} />
  );
};
