import { Checkbox } from '@/components/shared/Form/fields/Checkbox';
import { AgreementTypeEnum } from '@/models/Agreement';
import { TargetType } from '@/models/TargetType';
import { Check } from '@instech/icons';
import styled from 'styled-components';
import { useAgreementsPageContext } from '../../agreements/AgreementsPageContext';
import { AgreementViewMode, useAgreementDetailsContext } from '../AgreementDetailsContext';
import { useAgreementSelectionContext } from '../AgreementSelectionContext';

const TradeAgreementIndicator = styled.div`
    display: flex;
    align-items: center;
    margin-right: 1rem;
  
    strong {
      padding-left: 1rem;
    }
  
    label {
      font-weight: 600;
    }
  `;

export const TradeIndicator = () => {
  const { viewMode } = useAgreementDetailsContext();
  const { agreementType } = useAgreementsPageContext();
  const { setTargetType, targetType } = useAgreementSelectionContext();
  const isTrade = targetType === TargetType.Trade;

  if (agreementType === AgreementTypeEnum.Standard) return null;

  return ((isTrade || viewMode === AgreementViewMode.Create) ? (
    <TradeAgreementIndicator>
      {viewMode !== AgreementViewMode.Create ? (
        <>
          <Check />
          <strong>Trade Agreement</strong>
        </>
      ) : (
        <Checkbox
          name="agreementType"
          rightLabel="Trade Agreement"
          checked={isTrade}
          onChange={() => setTargetType(isTrade ? TargetType.VesselSpecific : TargetType.Trade)}
        />
      )}
    </TradeAgreementIndicator>
  ) : null);
};
