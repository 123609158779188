import { ValidationMessages } from '@/models/ValidationMessages';
import {
  createContext, FC, useContext, useMemo, useReducer, useCallback
} from 'react';
import { WarWeb } from '@/war';
import { addExcludedAreasBatch } from '@/services/excludedAreasService';
import {
  addAreaAliasAsync, deleteAreaAliasAsync, updateAreaAliasAsync
} from '@/services/areaAliasService';
import {
  AreaHra, AreaName, EntryExitPointSpecification, ExcludedPort
} from '../types';
import { EditExcludedAreasState, AreaAliasSpecification } from './types';
import {
  editExcludedAreasReducer, initialState, initializer
} from './editExcludedAreasReducer';
import { ActionTypes } from './actions';
import { useSeedEntryExitPoints } from './useSeedEntryExitPoints';
import { useExcludedAreaPageContext } from '../../context/ExcludedAreaPageContext';
import {
  getAliasesToDelete,
  getPorts, getTimeExceptions, getUniqueOceanAreaIds, getUpsertOceanAreaRequestForAreaId, parseAreaAliasErrors, parseValidationErrors
} from './utils';
import { useSeedAreaAliasSpecifications } from './useSeedAreaAliasSpecifications';
import { useCanSave } from './useCanSave';
import { useNewAreaIndex } from './useNewAreaIndex';

const EditExcludedAreasContext = createContext<ProviderProps>({
  state: initialState,
  setRegionId: () => { },
  setAreaId: () => { },
  setAreaType: () => { },
  setHraForArea: () => { },
  addEntryExitPoint: () => { },
  deleteEntryExitPoint: () => { },
  undoDeleteEntryExitPoint: () => { },
  addSelectedPort: () => { },
  deleteSelectedPort: () => { },
  updateSelectedPort: () => { },
  setSelectedPorts: () => { },
  isCustomArea: false,
  isNewArea: false,
  addNewCustomArea: () => { },
  canSave: false,
  saveAsync: () => Promise.resolve(false),
  setOceanAreaNames: () => { },
  deleteCustomArea: () => { },
  undoAddedCustomArea: () => { },
  addAreaAlias: () => { },
  deleteAreaAlias: () => { },
  undoDeleteAreaAlias: () => { },
  updateAreaAlias: () => { }
});

interface ProviderProps {
  state: EditExcludedAreasState;
  setRegionId: (id: number) => void;
  setAreaId: (id?: number) => void;
  setAreaType: (areaType?: string) => void;
  setEntryAndExitPoints?: (entryAndExitPoints: EntryExitPointSpecification[]) => void;
  setHraForArea: (hraSettings: AreaHra[]) => void;
  addEntryExitPoint: (point: EntryExitPointSpecification) => void;
  deleteEntryExitPoint: (id: number) => void;
  undoDeleteEntryExitPoint: (id: number) => void;
  addSelectedPort: (port: ExcludedPort) => void;
  deleteSelectedPort: (locode: string) => void;
  updateSelectedPort: (port: ExcludedPort) => void;
  setSelectedPorts: (port: ExcludedPort[]) => void;
  isCustomArea: boolean;
  isNewArea: boolean;
  addNewCustomArea: () => void;
  canSave: boolean;
  saveAsync: () => Promise<boolean>;
  setOceanAreaNames : (areaNames: AreaName[]) => void;
  deleteCustomArea: (area: WarWeb.Area) => void;
  undoAddedCustomArea: (id: number) => void;
  addAreaAlias: (alias: AreaAliasSpecification) => void;
  deleteAreaAlias: (id: number) => void;
  undoDeleteAreaAlias: (id: number) => void;
  updateAreaAlias: (alias: AreaAliasSpecification) => void;
}
export const EditExcludedAreasProvider: FC = ({ children }) => {
  const { regionId, areaId, areaType } = useExcludedAreaPageContext();
  const [state, dispatch] = useReducer(editExcludedAreasReducer, { regionId, areaId, areaType }, initializer);

  const newAreaIndex = useNewAreaIndex(state);

  const addEntryExitPoint = useCallback((point: EntryExitPointSpecification) =>
    dispatch({ type: ActionTypes.AddEntryExitPoint, payload: point }), [dispatch]);

  const setEntryAndExitPoints = useCallback((points: EntryExitPointSpecification[]) =>
    dispatch({ type: ActionTypes.SetEntryExitPoints, payload: points }), [dispatch]);

  const deleteEntryExitPoint = useCallback((id: number) =>
    dispatch({ type: ActionTypes.DeleteEntryExitPoint, payload: id }), [dispatch]);

  const undoDeleteEntryExitPoint = useCallback((id: number) =>
    dispatch({ type: ActionTypes.UndoDeleteEntryExitPoint, payload: id }), [dispatch]);

  const setRegionId = useCallback((id?: number) =>
    dispatch({ type: ActionTypes.SetRegionId, payload: id }), [dispatch]);

  const setAreaId = useCallback((id?: number) =>
    dispatch({ type: ActionTypes.SetAreaId, payload: id }), [dispatch]);

  const setAreaType = useCallback((type?: string) =>
    dispatch({ type: ActionTypes.SetAreaType, payload: type }), [dispatch]);

  const setHraForArea = useCallback((hraSettings: AreaHra[]) =>
    dispatch({ type: ActionTypes.SetHraForArea, payload: hraSettings }), [dispatch]);

  const addSelectedPort = useCallback((port: ExcludedPort) =>
    dispatch({ type: ActionTypes.AddSelectedPort, payload: port }), [dispatch]);

  const deleteSelectedPort = useCallback((locode: string) =>
    dispatch({ type: ActionTypes.DeleteSelectedPort, payload: locode }), [dispatch]);

  const updateSelectedPort = useCallback((port: ExcludedPort) =>
    dispatch({ type: ActionTypes.UpdateSelectedPort, payload: port }), [dispatch]);

  const setSelectedPorts = useCallback((ports: ExcludedPort[]) =>
    dispatch({ type: ActionTypes.SetSelectedPorts, payload: ports }), [dispatch]);

  const addNewCustomArea = useCallback(() =>
    dispatch({ type: ActionTypes.AddNewCustomArea, payload: newAreaIndex }), [dispatch, newAreaIndex]);

  const setOceanAreaNames = useCallback((areaNames: AreaName[]) =>
    dispatch({ type: ActionTypes.SetOceanAreaNames, payload: areaNames }), [dispatch]);

  const setIsSaving = useCallback((value: boolean) =>
    dispatch({ type: ActionTypes.SetIsSaving, payload: value }), [dispatch]);

  const deleteCustomArea = useCallback((area: WarWeb.Area) =>
    dispatch({ type: ActionTypes.DeleteCustomArea, payload: area }), [dispatch]);

  const undoAddedCustomArea = useCallback((id: number) =>
    dispatch({ type: ActionTypes.UndoAddedCustomArea, payload: id }), [dispatch]);

  const addAreaAlias = useCallback((alias: AreaAliasSpecification) =>
    dispatch({ type: ActionTypes.AddAreaAlias, payload: alias }), [dispatch]);

  const deleteAreaAlias = useCallback((id: number) =>
    dispatch({ type: ActionTypes.DeleteAreaAlias, payload: id }), [dispatch]);

  const undoDeleteAreaAlias = useCallback((id: number) =>
    dispatch({ type: ActionTypes.UndoDeleteAreaAlias, payload: id }), [dispatch]);

  const updateAreaAlias = useCallback((alias: AreaAliasSpecification) =>
    dispatch({ type: ActionTypes.UpdateAreaAlias, payload: alias }), [dispatch]);

  const setAreaAliases = useCallback((areaAliases: AreaAliasSpecification[]) =>
    dispatch({ type: ActionTypes.SetAreaAliases, payload: areaAliases }), [dispatch]);

  const addError = useCallback((error: ValidationMessages) =>
    dispatch({ type: ActionTypes.AddError, payload: error }), [dispatch]);

  const clearErrors = useCallback(() =>
    dispatch({ type: ActionTypes.ClearErrors, payload: undefined }), [dispatch]);

  useSeedEntryExitPoints(setEntryAndExitPoints, state.areaType, state.areaId);
  useSeedAreaAliasSpecifications(setAreaAliases, state.areaAliases, state.areaId);

  const isNewArea = (!!state.areaId && state.areaId < 0);
  const isCustomArea = (state.areaType === 'Ocean' || isNewArea);

  const canSave = useCanSave(state);

  const saveAreaAliasesAsync = useCallback(async () => {
    const aliasesToDelete = getAliasesToDelete(state.oceanAreaNames, state.areaAliases);

    const newAliasRequests = state.areaAliases
      .filter(a => a.action === 'Add')
      .map(addAreaAliasAsync);
    const updatedAliasRequests = state.areaAliases
      .filter(a => a.action === 'Edit')
      .map(updateAreaAliasAsync);
    const deletedAliasRequests = state.areaAliases
      .filter(a => aliasesToDelete.includes(a.id))
      .map(a => deleteAreaAliasAsync(a.id));

    const results = await Promise.all([...newAliasRequests, ...updatedAliasRequests, ...deletedAliasRequests]);

    return results
      .filter(r => r.error)
      .map(r => r.error!);
  }, [state.areaAliases, state.oceanAreaNames]);

  const saveAsync = useCallback(async () => {
    if (state.isSaving) throw new Error('Already saving');
    setIsSaving(true);
    clearErrors();

    const ports = getPorts(state.selectedPorts);
    const timeExceptions = getTimeExceptions(state.selectedPorts);
    const points = state.entryAndExitPoints
      .filter(p => p.action === 'Add' || p.action === 'Delete');
    const oceanAreaIds = getUniqueOceanAreaIds(state, ports, timeExceptions);

    const oceanAreas = oceanAreaIds
      .map(editId => getUpsertOceanAreaRequestForAreaId(editId, state, ports, points, timeExceptions));

    const request: WarWeb.BulkExcludeAreasRequest = { oceanAreas, ports: ports.filter(p => !p.isCustomArea) };

    const { error } = await addExcludedAreasBatch(request);
    setIsSaving(false);

    if (error) {
      const validationMessages = parseValidationErrors(error);
      addError(validationMessages);
      return false;
    }
    setIsSaving(true);
    const areaAliasErrors = await saveAreaAliasesAsync();
    setIsSaving(false);

    if (areaAliasErrors.length > 0) {
      const validationMessages = parseAreaAliasErrors(areaAliasErrors);
      addError(validationMessages);
      return false;
    }
    return true;
  }, [state, setIsSaving, saveAreaAliasesAsync, addError, clearErrors]);

  const value = useMemo(() => ({
    state,
    setRegionId,
    setAreaId,
    setAreaType,
    setEntryAndExitPoints,
    setHraForArea,
    addEntryExitPoint,
    deleteEntryExitPoint,
    undoDeleteEntryExitPoint,
    addSelectedPort,
    deleteSelectedPort,
    updateSelectedPort,
    setSelectedPorts,
    isCustomArea,
    isNewArea,
    addNewCustomArea,
    canSave,
    saveAsync,
    setOceanAreaNames,
    deleteCustomArea,
    undoAddedCustomArea,
    addAreaAlias,
    deleteAreaAlias,
    undoDeleteAreaAlias,
    updateAreaAlias
  }), [state,
    setRegionId,
    setAreaId,
    setAreaType,
    setEntryAndExitPoints,
    setHraForArea,
    addEntryExitPoint,
    deleteEntryExitPoint,
    undoDeleteEntryExitPoint,
    addSelectedPort,
    deleteSelectedPort,
    updateSelectedPort,
    setSelectedPorts,
    isCustomArea,
    isNewArea,
    addNewCustomArea,
    canSave,
    saveAsync,
    setOceanAreaNames,
    deleteCustomArea,
    undoAddedCustomArea,
    addAreaAlias,
    deleteAreaAlias,
    undoDeleteAreaAlias,
    updateAreaAlias]);

  return (
    <EditExcludedAreasContext.Provider value={value}>
      {children}
    </EditExcludedAreasContext.Provider>
  );
};

export const useEditExcludedAreasContext = () => useContext(EditExcludedAreasContext);
