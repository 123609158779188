import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    max-width: 200px;
    align-items: center;
`;

const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const Description = styled.div`
    text-transform: uppercase;
    color: ${props => props.theme.marineBlue50};
    letter-spacing: 0.07em;
    font-size: 11px;
`;

const Name = styled.div`
    font-size: 16px;
`;

interface NameDescriptionElementProps {
  name: string;
  description: string;
}
export const NameDescriptionElement = ({ name, description }: NameDescriptionElementProps) => (
  <Wrapper>
    <FlexContainer>
      <Name>{name}</Name>
      <Description>{description}</Description>
    </FlexContainer>
  </Wrapper>
);
