import React from 'react';
import { formatTimeSpan, pluralize } from '@/utility/dateCalc';
import { WarWeb } from '@/war';

const friendlyContractName = (contractType?: WarWeb.ContractType) => {
  switch (contractType) {
    case 'MinimumProRata': {
      return 'Minimum';
    }
    case 'ProRata': {
      return 'Pro Rata';
    }
    case 'Fixed': {
      return 'Fixed';
    }
    default: {
      return '';
    }
  }
};

interface DaysDetailsProps {
  destination: any;
}

export const DaysDetails = ({ destination }: DaysDetailsProps) => {
  const { duration, chargedDays, daysCovered, payableDays, chargedBy } = destination;

  return (
    <>
      <small>
        {formatTimeSpan(duration)}
        {!!payableDays && (
          <>{` ≈ ${payableDays} ${pluralize('day', payableDays)}`}</>
        )}
      </small>
      {chargedDays != null && (
        <small>
          {`Charged Days ${chargedDays} of ${daysCovered} - ${friendlyContractName(chargedBy?.toString())}`}
        </small>
      )}
    </>
  );
};
