import React, { FC } from 'react';
import styled from 'styled-components';
import { Remove } from '@instech/icons';
import { Filters } from '@/models/Filters';
import { DataType } from '@/models/Datatype';
import { formatDate } from '@/utility/dateCalc';
import moment from 'moment';
import { isEmpty, pick } from '@/utility/objectHelpers';
import { FilterButton } from './FilterButton';
import { TableColumnHeader } from './Table/core/Components';
import { Range, useFilterableTableHeadersContext } from '../pages/defaultTermsConditions/FilterableTableHeadersContext';
import { EmptyFacet } from './Table/core/SearchFilterDropdown';

const Content = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

interface FilterBarProps {
  searchableFields?: string[];
}
export const FilterBar: FC<FilterBarProps> = ({ searchableFields }) => {
  const { headers, filters, resetFilters, toggleFacet } = useFilterableTableHeadersContext();

  const defaultSearchableFields = Object.keys(filters)
    .filter(parameterName => headers.find(header => header.parameterName === parameterName))
    .filter(parameterName => !!filters[parameterName]);

  const appliedFilters: Filters = pick(filters, searchableFields || defaultSearchableFields);

  const formatValue = (value?: string | null, type?: DataType) => {
    if (!value) return null;
    if (type === 'Date') return formatDate(new Date(value));
    return value;
  };

  const formatRange = (range: Range, type?: DataType) => {
    const formattedMin = formatValue(type === 'Date' ? moment(range.min).utc().add('day', 1).hours(0)
      .toISOString() : range.min, type);
    const formattedMax = formatValue(range.max, type);

    if (formattedMin === formattedMax) return `${formattedMin}`;

    if (range.min && range.max) return `${formattedMin} - ${formattedMax}`;
    if (range.min) return `≥ ${formattedMin}`;
    return `≤ ${formattedMax}`;
  };

  return (
    <Content>
      {Object.keys(appliedFilters).map((searchField: string) => {
        const { type, title } = headers.find(header => header.parameterName === searchField) ?? {};

        if (type === 'Date' || type === 'Number') {
          const value = appliedFilters[searchField] as Range;
          return (
            <FilterButton
              key={searchField}
              label={`${title} ${formatRange(value, type)}`}
              selected
              iconHeight={15}
              icon={<Remove />}
              toggle={() => toggleFacet!(searchField)}
            />
          );
        }

        if (type === 'Boolean') {
          const values = appliedFilters[searchField] as boolean[];
          return values?.map((facet: boolean) => {
            let label = facet ? 'On' : 'Off';
            if (title) label = facet ? title : `Not ${title}`;
            return (
              <FilterButton
                key={searchField}
                label={label}
                selected
                iconHeight={15}
                icon={<Remove />}
                toggle={() => toggleFacet!(searchField)}
              />
            );
          });
        }

        const values = appliedFilters[searchField] as string[];
        return values?.map((facet: string) => (
          <FilterButton
            key={facet}
            label={facet || <EmptyFacet>No value</EmptyFacet>}
            selected
            iconHeight={15}
            icon={<Remove />}
            toggle={() => toggleFacet!(searchField, facet)}
          />
        ));
      })}

      <FilterButton
        label="Clear filters"
        tabIndex={isEmpty(pick(filters, searchableFields)) ? undefined : 0}
        toggle={resetFilters!} />

    </Content>
  );
};
