import React, {
  createContext, FC, useContext, useState
} from 'react';

interface ProviderProps {
    fleetId?: number;
    setFleetId: (id: number) => void;
    entry?: string;
    setEntry: (entry?: string) => void;
}
const KnRTermsPageContext = createContext<ProviderProps>({
  setFleetId: () => { },
  setEntry: () => { }
});
export const KnRTermsPageProvider: FC = ({ children }) => {
  const [fleetId, setFleetId] = useState<number>();
  const [entry, setEntry] = useState<string>();

  const value = { fleetId, setFleetId, entry, setEntry };

  return (
    <KnRTermsPageContext.Provider value={value}>
      {children}
    </KnRTermsPageContext.Provider>
  );
};

export const useKnRTermsPageContext = () => useContext(KnRTermsPageContext);
