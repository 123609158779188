import React, { useMemo } from 'react';
import { Editor, EditorState } from 'draft-js';
import styled from 'styled-components';
import { getEditorState } from '../RichTextForm/utility';
import { customBlockStyleFn } from './core/customStyles';

const TextView = styled.div`
  color: ${props => props.theme.marineBlue};
`;

interface RichTextViewProps {
  text: string;
}
export const RichTextView = ({ text }: RichTextViewProps) => {
  const editorState = useMemo(() => getEditorState(text), [text]);

  const onChange = (newEditorState: EditorState) => {
  };

  return (
    <TextView>
      <Editor
        editorState={editorState}
        blockStyleFn={customBlockStyleFn}
        onChange={onChange}
        readOnly
      />
    </TextView>
  );
};
