import { PageHeader } from '@/components/layout/PageHeader';
import {
  isAdmin, isBroker, isInternalUser, isUnderwriter
} from '@/components/routing/roles';
import { Routes } from '@/components/routing/routes';
import { Box } from '@/components/shared/Box';
import { Button } from '@/components/shared/Button';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { FilterBar } from '@/components/shared/FilterBar';
import { SelectionBar } from '@/components/shared/SelectionBar';
import { getHeaders } from '@/components/shared/Table/core/Components';
import { TermsIndexerStatus } from '@/components/shared/TermsIndexerStatus';
import { usePageSettingsContext } from '@/components/userSettings/PageSettingsContext';
import useDebounce from '@/hooks/useDebouncedValue';
import { useQuery } from '@/hooks/useQuery';
import { Filters } from '@/models/Filters';
import {
  SortedHeader, SortOrder, toFilter
} from '@/models/SortOrder';
import { TargetType } from '@/models/TargetType';
import { deleteAgreements } from '@/services/agreementsService';
import { useFleet, useFleets } from '@/services/fleetsService';
import { useTermsConditionsRegions } from '@/services/termsService';
import { theme } from '@/utility/theme';
import { WarWeb } from '@/war';
import { InformationNotification } from '@instech/components';
import {
  Add, Anchor, Pen, Trash
} from '@instech/icons';
import {
  useEffect, useMemo, useState
} from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { useAgreementsPageContext } from '../agreements/AgreementsPageContext';
import { useAgreementsOverviewContext } from '../agreementsOverview/AgreementsOverviewContext';
import { FleetsDropdown } from '../create-bow/components/FleetsDropdown';
import { FilterableTableHeadersProvider } from '../defaultTermsConditions/FilterableTableHeadersContext';
import { DeleteSpecificAgreementsConfirmationModal } from '../agreementsOverview/deleteSelection/DeleteAgreementsConfirmationModals';
import { TermsConditionsPageProvider } from './TermsConditionsPageContext';
import {
  defaultFilters, resolveTableSections, TermsConditionsTable
} from './TermsConditionsTable';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;

  padding: 1em 0;
  margin: 0 0.5em;
`;

const NotificationContainer = styled.div`
  margin: 0.5rem 0;
`;

const defaultSorting = {
  propertyName: 'LastUpdated',
  sortOrder: SortOrder.Descending
};

export const TermsConditionsPage = () => {
  const { agreementEntries, setAgreementEntries } = useAgreementsOverviewContext();
  const { setAgreementSummaryTarget, setCreateAgreementParams, agreementSummaryTarget } = useAgreementsPageContext();
  const { showDeleteStatus, setShowDeleteStatus } = useAgreementsPageContext();

  const { pageSettings, setPageSettings, setPageKey } = usePageSettingsContext();
  const [mainActionClicked, setMainActionClicked] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    setPageKey('specificTerms');
    return () => setPageKey();
  }, []);

  const restoredFilters = pageSettings?.specificTerms?.filters ?? {};

  const query = useQuery();
  const selectedFleetsParam = [query.get('SelectedFleets')];
  const selectedVesselsParam = [query.get('SelectedVessels')];
  const selectedCoveredAreasParam = [query.get('SelectedCoveredAreas')];

  const pageHeader = isBroker() ? 'Terms & Conditions' : 'Fleet specific T&C';
  const [done, setDone] = useState<boolean>(false);

  const history = useHistory();

  const defaultAndParamsFilters = {
    ...defaultFilters,
    ...restoredFilters,
    ...(isInternalUser() && selectedFleetsParam.some(x => Boolean(x)) && { SelectedFleets: selectedFleetsParam }),
    ...(isInternalUser() && selectedCoveredAreasParam.some(x => Boolean(x)) && { SelectedCoveredAreas: selectedCoveredAreasParam }),
    ...(selectedVesselsParam.some(x => Boolean(x)) && { SelectedVessels: selectedVesselsParam })
  };

  const [filters, setFilters] = useState<Filters>(defaultAndParamsFilters);

  const restoredSorting = toFilter(restoredFilters?.orderBy);
  const [sortedHeader, setSortedHeader] = useState<SortedHeader | undefined>(restoredSorting || defaultSorting);

  // firs, if broker, get all fleets the broker has access to
  const [selectedFleet, setSelectedFleet] = useState<WarWeb.MarineMatch>();
  const { data: fleets } = useFleets(isBroker() ? { pageSize: 1000 } : undefined);

  const clientId = -1;
  // eslint-disable-next-line no-unsafe-optional-chaining -- TODO: JEC
  const { data: fleet } = useFleet(clientId, +filters?.fleetId);
  const selectedFleetIsClaimsPenaltyBased = isBroker() ? fleet?.ncbPreference === 'ClaimsPenalty' : undefined;

  useEffect(() => {
    if (fleets) {
      let currentFleet: WarWeb.MarineMatch | undefined;
      if (filters?.fleetId) {
        currentFleet = fleets?.items.find(f => `${f.fleetId}` === filters.fleetId);
      } else if (selectedFleetsParam.some(x => Boolean(x))) {
        currentFleet = fleets?.items.find(f => f.name === selectedFleetsParam[0]);
      } else if (fleets && fleets.items.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        currentFleet = fleets.items[0];
      }
      setSelectedFleet(currentFleet);
    }
  }, [fleets]);

  useEffect(() => {
    setFilters(f => ({ ...f, fleetId: `${selectedFleet?.fleetId}` }));
  }, [selectedFleet]);

  // then, get regions (currently based on agreements for fleet)
  const debouncedFilters = useDebounce(filters, 500);
  const { data: regions, error, isLoading } = useTermsConditionsRegions(debouncedFilters, JSON.stringify(debouncedFilters));

  const isNameColumnVisible = regions?.items.some(i => i.coveredAreaType === 'Trade');

  const tableSections = resolveTableSections(selectedFleetIsClaimsPenaltyBased, isNameColumnVisible);
  const headers = getHeaders(tableSections);

  useEffect(() => {
    setDone(!!regions);
  }, [regions]);

  useEffect(() => {
    if (sortedHeader) {
      setFilters({ ...filters, orderBy: `${sortedHeader.propertyName} ${sortedHeader.sortOrder}` });
    }
  }, [sortedHeader]);

  useEffect(() => {
    setPageSettings(
      {
        specificTerms: {
          ...pageSettings?.specificTerms,
          filters
        }
      }
    );
  }, [filters]);

  const editAgreements = () => {
    const ids = agreementEntries.flatMap(x => x.agreementIds);
    const targetType = agreementEntries.some(x => x.type === 'Trade') ? TargetType.Trade : TargetType.VesselSpecific;

    setAgreementSummaryTarget({ agreementIds: ids, targetType, hasMultipleSets: false });

    setMainActionClicked(true);
    setTimeout(() => {
      history.push(Routes.termsSpecificDetails);
    }, 500);
  };

  const createAgreement = () => {
    const areaIds = [...new Set(agreementEntries.flatMap(x => x.areaIds))];
    setCreateAgreementParams({ areaIds });
    setMainActionClicked(true);

    setTimeout(() => {
      history.push(`${Routes.termsSpecificCreate}`);
    }, 500);
  };

  const deleteAgreementsHandler = async () => {
    setShowDeleteStatus(false);
    const deleteTargets: WarWeb.AgreementTargets = { ...agreementSummaryTarget };

    await deleteAgreements(deleteTargets);
    setShowDeleteStatus(true);
    setAgreementEntries([]);
  };

  const isAdminOrUWWithinOneFleet = useMemo(
    () => isAdmin() || (isUnderwriter() && [...new Set(agreementEntries.map(x => x.fleetId))].length <= 1),
    [agreementEntries]
  );

  return (
    <>
      <HeaderContainer>
        <HeaderContainer>
          <PageHeader header={pageHeader} />

          {isBroker() && selectedFleet && (
            <FleetsDropdown
              right
              currentFleet={selectedFleet.fleetId}
              currentClient={selectedFleet?.clientId}
              setFleet={setSelectedFleet} />
          )}

        </HeaderContainer>

        <ButtonGroup>
          {isInternalUser({ onlyEditors: true }) && (
            <>
              <Button icon={<Anchor />} to={Routes.termsKidnapRansom}>K&R Terms</Button>
              <Button
                tabIndex={0}
                icon={<Add />}
                background={theme.green}
                shouldActivateByEnter={isUnderwriter() && agreementEntries.length > 0}
                isActivated={mainActionClicked && isUnderwriter()}
                onClick={createAgreement}>
                Create new
              </Button>
            </>
          )}

        </ButtonGroup>

      </HeaderContainer>

      <TermsIndexerStatus />

      <FilterableTableHeadersProvider
        headers={headers}
        columnMetadata={regions?.columnMetadata}
        initialFilters={{ ...defaultFilters, ...(isBroker() && { fleetId: selectedFleet?.fleetId }) }}
        filters={filters}
        sortedHeader={sortedHeader}
        setSortedHeader={setSortedHeader}
        setFilters={setFilters}
        isLoading={isLoading}
      >

        <Box highlight={theme.green}>

          <HeaderContainer>
            {isInternalUser({ onlyEditors: true }) ? (
              <SelectionBar
                type="agreement"
                editMode
                selection={agreementEntries.flatMap(x => x.agreementIds)}
                selected={!!filters?.selectedAgreementIds && filters.selectedAgreementIds.length > 0}
                setter={setAgreementEntries}>
                <Button
                  icon={<Pen />}
                  onClick={editAgreements}
                  background={theme.ultraLightGray}
                  shouldActivateByEnter={agreementEntries.length > 0}
                  isActivated={mainActionClicked}
                  disabled={!isAdminOrUWWithinOneFleet || (!mainActionClicked && agreementEntries.length === 0)}>
                  View
                </Button>
                <Button
                  onClick={() => setShowDeleteModal(true)}
                  icon={<Trash />}
                  background={theme.ultraLightGray}
                  disabled={!isAdminOrUWWithinOneFleet || agreementEntries.length === 0}>
                  Delete
                </Button>
              </SelectionBar>
            ) : <div />}

            <FilterContainer>
              <FilterBar />
            </FilterContainer>
          </HeaderContainer>

          {showDeleteStatus && (
            <NotificationContainer>
              <InformationNotification headingText="Delete success!"
                size="large"
                descriptionText="Agreements successfully deleted. It might take a few minutes before the table is updated."
                onClose={() => setShowDeleteStatus(false)} />
            </NotificationContainer>
          )}

          <TermsConditionsPageProvider
            fleetId={selectedFleet?.fleetId}
            isClaimsPenaltyBased={selectedFleetIsClaimsPenaltyBased}
            editAction={isAdmin() ? editAgreements : createAgreement}
            setMainActionClicked={setMainActionClicked}
            regions={regions}
            isNameColumnVisible={isNameColumnVisible}
          >
            <TermsConditionsTable
              loadStatus={{ loading: !done, success: !error }}
            />
          </TermsConditionsPageProvider>
        </Box>
        {showDeleteModal && (
          <DeleteSpecificAgreementsConfirmationModal
            numberOfAgreements={agreementEntries.flatMap(x => x.agreementIds).length}
            numberOfVessels={agreementEntries.flatMap(x => x.vesselIds).length}
            apply={deleteAgreementsHandler}
            closeModal={() => setShowDeleteModal(false)} />
        )}
      </FilterableTableHeadersProvider>
    </>
  );
};
