import { AppRouting } from './components/routing/AppRouting';
import { RootRouting } from './components/routing/RootRouting';
import { ScrollToTop } from './utility/ScrollToTop';

export const App = () => (
  <RootRouting>
    <ScrollToTop />
    <AppRouting />
  </RootRouting>
);
