import { Approved, Pen } from '@instech/icons';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '@/components/shared/Button';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { Dropdown } from '@/components/shared/Form/FormFields';
import { Checkbox } from '@/components/shared/Form/fields/Checkbox';
import { RichText } from '@/components/shared/Form/RichTextForm/RichText';
import { RichTextView } from '@/components/shared/Form/RichTextForm/RichTextView';
import { TableCell, TableRow } from '@/components/shared/Table/Table';
import { useRebateAssociations } from '@/services/taxonomyService';
import { upsertTextCondition, useTextConditions } from '@/services/textConditionsService';
import { addOrRemove } from '@/utility/arrayHelpers';
import { theme } from '@/utility/theme';
import { WarWeb } from '@/war';
import { useConditionsContext } from './ConditionsContext';
import { isAdmin } from '@/components/routing/roles';

const EditContainer = styled.div`
  padding-bottom: 2em;
`;

const Condition = styled.div`
  font-size: 14px;
`;

const StyledEdit = styled(Pen)`
  height: 14px;
`;

const StyledApproved = styled(Approved)`
  path {
    fill: currentColor;
  }
`;

export type TextConditionAction = 'add' | 'remove';

interface ConditionsEditorRowProps {
  term: WarWeb.Term;
  editMode: boolean;
  setEditId: (a?: number) => void;
  canEdit: boolean;
  canToggle: boolean;
  update: (term: WarWeb.Term, action: TextConditionAction) => void;
}
export const ConditionsEditorRow = ({ term, editMode, setEditId, canEdit, canToggle, update }: ConditionsEditorRowProps) => {
  const { data: rebates } = useRebateAssociations();
  const { mutate } = useTextConditions();
  const { selectedTerms, setSelectedTerms } = useConditionsContext();
  const [editedText, setEditedText] = useState<string>(term.text);

  const isIncluded = selectedTerms?.includes(term.id);

  const onCheckboxChangeHandler = () => {
    const newSelectedTerms = [...selectedTerms];
    addOrRemove(newSelectedTerms, term.id);
    setSelectedTerms(newSelectedTerms);

    const action = !isIncluded ? 'add' : 'remove';
    update(term, action);
  };

  const onRebateChangeHandler = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    const request: WarWeb.UpsertTextTermRequest = {
      action: 'Edit',
      termId: term.id,
      associatedRebate: {
        rebateId: value === '' ? undefined : parseInt(value)
      }
    };

    await upsertTextCondition(request);
    void mutate();
  };

  const closeEdit = () => {
    setEditId();
  };

  const save = async () => {
    const request = {
      action: 'Edit' as WarWeb.ElementAction,
      termId: term.id,
      text: editedText,
      associatedRebate: undefined
    };

    await upsertTextCondition(request);
    void mutate();
    closeEdit();
  };

  const rebateOptions = rebates?.map(rebate => ({ name: rebate.name, value: `${rebate.id}` }));
  rebateOptions?.unshift({ name: 'All', value: '' });

  return (
    <TableRow>
      <TableCell>{term.id}</TableCell>
      {editMode ? (
        <>
          <TableCell span={2} textWrap>
            <Condition>
              <EditContainer>
                <RichText richText={term.text} onRichTextChange={value => setEditedText(value)} />
              </EditContainer>
            </Condition>
          </TableCell>
          <TableCell span={2} center>
            <ButtonGroup direction="column" justify="space-between">
              <Button onClick={closeEdit} background={theme.ultraLightGray}>
                Cancel
              </Button>

              <Button onClick={save} background={theme.green}>
                Save
              </Button>
            </ButtonGroup>
          </TableCell>
        </>
      ) : (
        <>
          <TableCell textWrap>
            <Condition><RichTextView text={term.text} /></Condition>
          </TableCell>
          {canEdit && <TableCell onClick={() => setEditId(term.id)}><StyledEdit /></TableCell>}
          {canToggle ? (
            <TableCell center onClick={onCheckboxChangeHandler}>
              <Checkbox
                readOnly
                name={`${term.id}`}
                checked={isIncluded}
              />
            </TableCell>
          ) : (
            <TableCell center>{isIncluded && <StyledApproved />} </TableCell>
          )}
          <TableCell center>
            {canEdit ? (
              <Dropdown
                compact
                name="associatedRebate"
                placeholder={rebates?.find(r => r.id === term.associatedRebate?.id)?.name ?? 'All'}
                selectedValue={rebates?.find(r => r.id === term.associatedRebate?.id)?.name}
                options={rebateOptions || []}
                changeHandler={onRebateChangeHandler}
              />
            ) : <span>{rebates?.find(r => r.id === term.associatedRebate?.id)?.name ?? 'All'}</span>}
          </TableCell>
        </>
      )}
    </TableRow>
  );
};
