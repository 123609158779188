import moment from 'moment';

export const formatDate = (d: Date | null) => {
  const date = moment(d);
  const formatString = date.parseZone().month() === 4 ? 'D MMM YYYY' : 'D MMM. YYYY';
  return d ? date.parseZone().format(formatString) : undefined;
};

export const formatTimeZoneOffset = (t: string) => `${t.charAt(0)} ${t.substring(1)}`;

const formatTimeSpanPart = (value: string, label: string) => (value && parseInt(value) > 0) ? `${parseInt(value)}${label} ` : '';

export const pluralize = (noun: string, count: number) => `${noun}${count === 0 || count > 1 ? 's' : ''}`;

export const formatTimeSpan = (input: string) => {
  if (!input) return null;
  const duration = input.match(/^(\d*\.)?(\d+):(\d+):(\d+)/);
  if (!duration) return null;
  const daysLabel = (duration[2] === '00' && duration[3] === '00') ? pluralize('day', parseInt(duration[1])) : 'd';
  return `${formatTimeSpanPart(duration[1], ` ${daysLabel}`)}${formatTimeSpanPart(duration[2], ' hr')}${formatTimeSpanPart(duration[3], ' min')}`;
};
