import { ModalLayout } from '@/components/modal/ModalLayout';
import { Button } from '@/components/shared/Button';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { FlexBox } from '@/components/shared/FlexBox';
import { FieldLayout } from '@/components/shared/Form/core/Components';
import { formatLargeInteger } from '@/utility/formatter';

import { theme } from '@/utility/theme';
import { TextField } from '@instech/components';
import {
  Formik, FormikProps, FormikValues
} from 'formik';
import { useState } from 'react';

interface FormValues {
  sumInsured: string;
}

export enum SumInsuredModalMode {
  Edit,
  Delete,
  Duplicate
}

export interface SumInsuredModalData {
  mode: SumInsuredModalMode;
  sum: number;
}

interface DuplicateSumInsuredModalProps {
  save: (days: number) => void;
  close: () => void;
  data: SumInsuredModalData;
}

const getTitle = (mode: SumInsuredModalMode): string => {
  let title = '';
  switch (mode) {
    case SumInsuredModalMode.Edit:
      title = 'Change value limit';
      break;
    case SumInsuredModalMode.Duplicate:
      title = 'New value limit';
      break;
    case SumInsuredModalMode.Delete:
      title = 'Are you sure you want to delete this?';
      break;
    default:
  }
  return title;
};

const getButtonSaveTitle = (mode: SumInsuredModalMode): string => {
  let title = '';
  switch (mode) {
    case SumInsuredModalMode.Edit:
      title = 'Save';
      break;
    case SumInsuredModalMode.Duplicate:
      title = 'Duplicate';
      break;
    case SumInsuredModalMode.Delete:
      title = 'Delete';
      break;
    default:
  }
  return title;
};

export const SumInsuredModal = ({ save, close, data }: DuplicateSumInsuredModalProps) => {
  const [isSaving, setIsSaving] = useState(false);

  const submitModal = async (values: FormValues) => {
    setIsSaving(true);
    if (data.mode === SumInsuredModalMode.Delete) {
      await save(data.sum);
    } else {
      await save(parseInt(values.sumInsured));
    }
    setIsSaving(false);
    close();
  };

  return (
    <ModalLayout closeModal={close} options={{ title: getTitle(data.mode), size: 'small' }}>
      <Formik
        initialValues={{ sumInsured: data.sum ? data.sum.toString() : '' }}
        onSubmit={submitModal}
      >
        {({ handleSubmit, dirty }: FormikProps<FormikValues>) => (
          <FlexBox flexDirection="column" gap="2rem">
            {data.mode !== SumInsuredModalMode.Delete ? (
              <FieldLayout>
                <TextField name="sumInsured" placeholder="" label="Insured value limit - USD" />
              </FieldLayout>
            ) : (
              <p>
                Deleting Sum Insured value option for&nbsp;
                {formatLargeInteger(data.sum, 2)}
              </p>
            )}

            <ButtonGroup justify="flex-end">
              <Button onClick={close} background={theme.ultraLightGray}>
                Cancel
              </Button>
              <Button background={theme.green}
                onClick={handleSubmit}
                disabled={data.mode !== SumInsuredModalMode.Delete && (!dirty || isSaving)}
                loading={isSaving}>
                {getButtonSaveTitle(data.mode)}
              </Button>
            </ButtonGroup>
          </FlexBox>
        )}
      </Formik>
    </ModalLayout>
  );
};
