import { useCallback } from 'react';
import { EditExcludedAreasState } from './types';
import { isChanged } from '../utils';

export const useCanSave = (state: EditExcludedAreasState) => {
  const canSave = useCallback(() => {
    if (state.isSaving) return false;
    if (state.selectedPorts.some(p => isChanged(p.excluded))
      || state.selectedPorts.some(p => isChanged(p.inArea))
      || state.selectedPorts.some(p => p.highRiskAreaIdUpdate?.highRiskAreaId != null)
      || state.entryAndExitPoints.some(p => p.action === 'Add' || p.action === 'Delete')
      || state.oceanAreaNames.length > 0
      || state.hraForArea.length > 0
      || state.areaAliases.some(p => p.action === 'Add' || p.action === 'Delete' || p.action === 'Edit')) return true;
    return false;
  }, [state.areaAliases, state.entryAndExitPoints, state.hraForArea.length, state.isSaving, state.oceanAreaNames.length, state.selectedPorts]);
  return canSave();
};
