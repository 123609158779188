import React, {
  FC, useEffect, useState
} from 'react';
import moment, { Moment } from 'moment';
import { SingleDatePicker } from 'react-dates';
import styled, { css } from 'styled-components';
import { Calendar, Clock } from '@instech/icons';
import { theme } from '@/utility/theme';
import { TimeConverter } from '@/utility/timeConverter';
import { formatTimeZoneOffset } from '@/utility/dateCalc';
import { TreeView } from '@/development/TreeView';
import { useErrorContext } from '@/services/ErrorContext';
import { Loader } from '@instech/components';
import {
  FieldLabel, FieldLayout, InputField
} from '../core/Components';
import { FlexBox } from '../../FlexBox';
import { TextField } from './TextField';

const Wrapper = styled.div<{ hasError?: boolean }>`
  display: flex;
  justify-content: flex-start;

  border-radius: 2px;
  ${props => props.hasError && css`
    border-radius: 4px 4px 0 0;
    border: 2px solid ${theme.status.darkRed};
  `};
`;

const Content = styled.div<{ hasError?: boolean }>`
  color: ${props => props.theme.marineBlue};
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.white};
  border: 1px solid ${props => props.theme.border.gray};

  border-radius: 2px;

  & input {
    color: ${props => props.hasError ? props.theme.status.darkRed : props.theme.marineBlue};
    border: none;
    box-shadow: none;
    border-style: none;
    outline: none;
    height: 38px;
    padding: 0;
    border-radius: 0;
   }
`;

const IconContainer = styled.div`
  padding-top: 5px;
  padding-left: 0.3em;
  padding-right: 0.5em;
  cursor: pointer;

  svg {
    width: 22px;
    height: 18px;

    @media (max-width: 1400px) {
      display: none;
    }
  }
`;

const DatePicker = styled.div`
  display: flex;
  align-items: center;

  .DateInput {
    width: 107px;
    @media (max-width: 1400px) {
      width: 85px;
    }
  }
`;

const TimeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 25px;
  width: 90px;
  border-left: 1px solid ${props => props.theme.sanMarino25};
`;

const UtcOffset = styled.div<{ hasDarkBackground: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: nowrap;
  font-size: 14px;
  color: ${props => props.hasDarkBackground ? props.theme.white : props.theme.sanMarino};
  padding: 0 0.4em;
  line-height: 1em;
`;

const getDateWithTime = (date: Moment, time: string) => {
  const [hour, minute] = time.split(':');
  return date.set('hour', parseInt(hour)).set('minute', parseInt(minute));
};

const DEFAULT_TIME = '00:00';

interface TimeState {
  value: string;
  isDirty?: boolean;
}

export enum FocusType {
  None = 0,
  DateField = 1,
  DatePickerWidget = 2,
  TimeField = 3
}

interface DateTimePickerProps {
  name: string;
  label?: string;
  id: string;
  date?: Date;
  time?: string;
  withTime?: boolean;
  setDate: (d?: Date) => void;
  setTime?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  timeZoneId?: string;
  initialFocus?: FocusType;
  hasDarkBackground?: boolean;
  loading?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  tabIndex?: number;
}
export const DateTimePicker: FC<DateTimePickerProps> = (
  {
    name,
    label,
    date,
    time,
    id,
    setDate,
    setTime,
    timeZoneId,
    withTime = false,
    initialFocus = FocusType.None,
    hasDarkBackground,
    loading = false, disabled, autoFocus, tabIndex
  }
) => {
  const { hasError } = useErrorContext();

  const [focusedInput, setFocusedInput] = useState<boolean>(initialFocus === FocusType.DatePickerWidget); // TAB entry/exit

  const [timeInput, setTimeInput] = useState<TimeState>({
    value: time || (date ? moment.parseZone(date).format('HH:mm') : DEFAULT_TIME),
    isDirty: false
  });

  const formattedDate = TimeConverter.ToNaiveDateTime(date);
  const [dateInput, setDateInput] = useState(date ? moment(formattedDate) : null);

  useEffect(() => {
    if (!time) {
      setTimeInput({
        value: date ? moment.parseZone(date).format('HH:mm') : DEFAULT_TIME,
        isDirty: false
      });
    }

    const formattedDateTmp = TimeConverter.ToNaiveDateTime(date);
    const newDate = moment(formattedDateTmp);
    setDateInput(date ? newDate : null);
  }, [date, time]);

  useEffect(() => {
    if (initialFocus === FocusType.DateField) {
      const inputElement = document.getElementById(id) as HTMLInputElement;
      if (inputElement) inputElement.focus();
    }
  });

  const [notValid, setNotValid] = useState(false);

  const withinPlusMinus10Years = (d: moment.Moment) => {
    const invalid = !d.isBetween(moment().subtract(10, 'years'), moment().add(10, 'years'));
    setNotValid(invalid);
    return invalid;
  };

  const handleUserKeyPress = (evt: any) => {
    if (evt.key === 'Escape') setFocusedInput(false);
    if (!focusedInput && evt.key === 'ArrowDown') setFocusedInput(true);

    if (evt.key === 'Enter') {
      const inputElement = document.getElementById(id) as HTMLInputElement;
      if (!inputElement) return;
      const inputValue = inputElement?.value;
      if (!inputValue) {
        setFocusedInput(true);
        return;
      }
      const d = moment(inputValue);
      if (d.isValid()) {
        const missingYear = '^\\d{1,2}\\s([A-Za-z]{3}\\.|[A-Za-z]*)\\s*$';
        const missingYearEnUs = '^([A-Za-z]{3}\\.|[A-Za-z]*)\\s\\d{1,2}\\s*$';
        if (inputValue.match(missingYear) || inputValue.match(missingYearEnUs)) d.set('year', moment().year());

        if (withinPlusMinus10Years(d)) return;
        inputElement.value = d.format('D MMM YYYY');
        setDateInput(d);
        setDate(d.toDate());
      } else {
        setNotValid(true);
      }
    }
  };

  const dateChangeHandler = (d: moment.Moment | null) => {
    if (d) {
      const dateUtc = d.utc();
      const dateWithTime = getDateWithTime(dateUtc, timeInput.value);

      if (dateUtc.parseZone().format('YYYY-MM-DD') === dateInput?.parseZone().format('YYYY-MM-DD')) {
        return;
      }

      setDate(TimeConverter.adjustForTimezone(dateWithTime.toDate()));
      setDateInput(dateWithTime);
    } else {
      // setDate(undefined);  // TODO: Should we support CLEARING dates? Backend currently does not support this
      setDateInput(null);
    }
    setFocusedInput(false);
  };

  const focusChange = (focused: boolean) => {
    if (focusedInput) setFocusedInput(focused);
  };

  const toggleDateFieldClick = () => {
    if (loading || disabled) return;
    if (!focusedInput) setFocusedInput(true);
  };

  const changeTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    const validInput = '^[\\d:]*$';
    if (!e.target.value.match(validInput)) return;
    if ((e.target.value.match(/:/g) || []).length > 1) return;
    setTimeInput({ value: e.target.value, isDirty: true });
  };

  const finalizeTime = () => {
    if (!timeInput.isDirty) {
      return;
    }

    // try to format input into proper time format
    // null
    // HH or H
    // HHMM or HMM
    // HH:MM or H:MM
    // HH: even?
    let finalTimeInput = timeInput.value;
    if (finalTimeInput) { //  allow NULL
      const check = '^([0-2]?)(\\d)\\:?([0-5]\\d)?$';
      const validInput = timeInput.value.match(check);
      if (validInput != null) {
        finalTimeInput = `${validInput[1] ?? '0'}${validInput[2]}:${validInput[3] ?? '00'}`;
      } else {
        finalTimeInput = moment.parseZone(date).format('HH:mm'); // reset (and don't submit)
        setTimeInput({ value: finalTimeInput, isDirty: false });
        return;
      }
    } else {
      finalTimeInput = DEFAULT_TIME;
    }
    setTimeInput({ value: finalTimeInput, isDirty: false });

    if (dateInput) {
      const dateWithTime = getDateWithTime(dateInput, finalTimeInput);
      setDate(dateWithTime.toDate());
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.target.blur();
    }
  };

  return (
    <FieldLayout>
      {label && (
        <FieldLabel as="label" htmlFor={name}>
          {label}
        </FieldLabel>
      )}
      <FlexBox>
        <Wrapper hasError={hasError || notValid}>
          <InputField hasIcon>
            <Content hasError={hasError || notValid}>
              <DatePicker onKeyUp={handleUserKeyPress} onClick={toggleDateFieldClick}>
                <IconContainer>
                  {loading ? <Loader size="small" />
                    : <Calendar />}
                </IconContainer>
                <SingleDatePicker
                  date={dateInput} // momentPropTypes.momentObj or null
                  onDateChange={dateChangeHandler} // PropTypes.func.isRequired
                  focused={!loading && !disabled && focusedInput} // PropTypes.bool
                  onFocusChange={({ focused }) => focusChange(focused)} // PropTypes.func.isRequired
                  id={id} // PropTypes.string.isRequired,
                  displayFormat="D MMM YYYY"
                  placeholder="DD MMM YYYY"
                  isOutsideRange={withinPlusMinus10Years}
                  noBorder
                />
              </DatePicker>
              {withTime && (
                <TimeWrapper>
                  <IconContainer>
                    <Clock />
                  </IconContainer>
                  <TextField
                    name={name}
                    minimal
                    type="text"
                    autoComplete="off"
                    compact
                    noErrors
                    placeholder="HH:MM"
                    value={timeInput.value}
                    onChange={changeTime}
                    onBlur={finalizeTime}
                    onKeyDown={handleKeyDown}
                    disabled={loading || disabled}
                    noLabel
                  />
                </TimeWrapper>
              )}
            </Content>

          </InputField>
        </Wrapper>

        {timeZoneId && (
          <UtcOffset hasDarkBackground={!!hasDarkBackground}>
            <div>UTC</div>
            <div>{formatTimeZoneOffset(moment().tz(timeZoneId).format('Z'))}</div>
          </UtcOffset>
        )}

      </FlexBox>
    </FieldLayout>
  );
};
