import { Routes } from '@/components/routing/routes';
import { FlexBox } from '@/components/shared/FlexBox';
import {
  Redirect, Route, Switch
} from 'react-router-dom';
import styled from 'styled-components';
import { ProfileLogout } from './pages/ProfileLogout';
import { ProfileSettings } from './pages/ProfileSettings';
import { ProfileSupport } from './pages/ProfileSupport';

const ProfileRoutes = () => (
  <Switch>
    <Route exact path={`${Routes.profile}/settings`}>
      <ProfileSettings />
    </Route>
    <Route exact path={`${Routes.profile}/support`}>
      <ProfileSupport />
    </Route>
    <Route exact path={`${Routes.profile}/logout`}>
      <ProfileLogout />
    </Route>
    <Redirect exact from="/profile" to="/profile/settings" />
  </Switch>
);

const Content = styled.div`
  flex: 1 1 auto;
  padding: 2rem;
  max-width: ${props => props.theme.portal.maxWidth};
  margin: 0 auto;
`;

export const Profile = () => (
  <FlexBox>
    <Content>
      <ProfileRoutes />
    </Content>
  </FlexBox>
);
