import { useState } from 'react';
import { useTimezones } from '@/services/timezoneService';
import useDebounce from '@/hooks/useDebouncedValue';
import { Filters } from '@/models/Filters';

export const useTimeZonesWithFilters = () => {
  const [filters, setFilters] = useState<Filters>();
  const debouncedFilters = useDebounce(filters, 500);
  const { data: timezones } = useTimezones(debouncedFilters);

  const updateSearchText = (searchText: string) => {
    setFilters({ ...filters, freetext: searchText });
  };

  return { timezones, updateSearchText };
};
