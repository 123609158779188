import React, { useEffect, useState } from 'react';
import { Dropdown } from '@/components/shared/Form/FormFields';
import { addOrUpdateArray } from '@/utility/stateArrayHelpers';
import { WarWeb } from '@/war';
import styled from 'styled-components';
import { useSubareas } from '@/services/areasService';
import { useHighRiskAreas } from '@/services/highRiskAreasService';
import { DirtyableValue } from '@/models/utils/DirtyableValue';
import useDebounce from '@/hooks/useDebouncedValue';
import { TextFieldControlled } from '@instech/components';
import { useEditExcludedAreasContext } from '../EditExcludedAreasContext/EditExcludedAreasContext';
import { AreaHra, AreaName } from '../types';

const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  & > div {
    flex: 1;
  }
`;

export const CustomAreaForm = () => {
  const {
    state: {
      regionId,
      areaId,
      oceanAreaNames,
      hraForArea
    },
    setHraForArea,
    setOceanAreaNames,
  } = useEditExcludedAreasContext();
  const { data: areas } = useSubareas(regionId);
  const [name, setName] = useState<DirtyableValue<string>>({ value: '', isDirty: false });
  const debouncedName = useDebounce(name.value, 500);
  const [hraSelection, setHraSelection] = useState<string>();

  const { data: highRiskAreas } = useHighRiskAreas();

  const noHRAValue = { name: 'No HRA', value: '0' };
  const dropdownHRAs = highRiskAreas?.map(hra => ({ name: hra.name, value: hra.id.toString() }));
  dropdownHRAs?.unshift(noHRAValue);

  const handleAreaNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName({ value: e.target.value, isDirty: true });
  };

  const handleHraChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!regionId || !areaId) return;
    const value = parseInt(e.target.value);

    setHraSelection(`${value}`);
    const highRiskAreaIdUpdate: WarWeb.HighRiskAreaIdUpdate = { highRiskAreaId: value || undefined };
    const newItem: AreaHra = { regionId, oceanAreaId: areaId, highRiskAreaIdUpdate };
    addOrUpdateArray<AreaHra>(hraForArea, setHraForArea, a => a.oceanAreaId === areaId, newItem);
  };

  useEffect(() => {
    if (name.isDirty) {
      const isDeleted = oceanAreaNames?.some(l => l.oceanAreaId === areaId && l.action === 'Delete');
      if (!regionId || !areaId || isDeleted) return;
      const newItem: AreaName = { regionId, oceanAreaId: areaId, name: debouncedName, action: areaId < 0 ? 'Add' : 'Edit' };
      addOrUpdateArray<AreaName>(oceanAreaNames, setOceanAreaNames, a => a.oceanAreaId === areaId, newItem);
    }
  }, [debouncedName]);

  useEffect(() => {
    if (!areas) return;
    const nameValue = oceanAreaNames?.find(o => o.oceanAreaId === areaId)?.name // from state
      ?? areas.find(a => a.id === areaId)?.name // from DB
      ?? ''; // default
    setName(prev => ({ ...prev, value: nameValue }));
  }, [areas, areaId, oceanAreaNames]);

  useEffect(() => {
    if (!areas) return;
    const hraValue = hraForArea?.find(o => o.oceanAreaId === areaId)?.highRiskAreaIdUpdate?.highRiskAreaId // from state
      ?? areas.find(a => a.id === areaId)?.highRiskAreaId // from DB
      ?? 0; // default
    setHraSelection(`${hraValue}`);
  }, [areas, areaId, hraForArea]);

  return (
    <FormWrapper>
      <TextFieldControlled
        placeholder="Area Name"
        value={name.value}
        label="Custom area name"
        name="areaName"
        onChange={handleAreaNameChange}
        maxLength={100}
        forceMaxLength
      />
      <Dropdown
        compact
        label="Area HRA index"
        name="HRA"
        selectedValue={hraSelection}
        options={dropdownHRAs}
        changeHandler={handleHraChange}
      />
    </FormWrapper>
  );
};
