import {
  useEffect, useMemo, useState
} from 'react';
import { useAgreementTargets } from '@/components/pages/agreements/useAgreementTargets';
import { useAgreementsSummaryAlternatives } from '@/services/agreementsService';
import { WarWeb } from '@/war';
import { useAgreementsPageContext } from '@/components/pages/agreements/AgreementsPageContext';
import { useAgreementDataContext } from '@/components/pages/agreementDetails/AgreementDataContext';
import { formatRange } from '@/utility/formatter';

// Saving changes triggers revalidation, this hook is to avoid returning undefined while revalidating
const useAlternatives = (targets: WarWeb.AgreementTargets) => {
  const { data, isLoading, isValidating } = useAgreementsSummaryAlternatives({ ...targets, daysCoveredGroup: undefined, minimumSumInsured: undefined });
  const [alternatives, setAlternatives] = useState<WarWeb.AgreementAlternativesSummary[] | undefined>(data);

  useEffect(() => {
    if (!isLoading && !isValidating) {
      setAlternatives(data);
    }
  }, [data, isLoading, isValidating]);

  return alternatives;
};

export const useTabNames = () => {
  const { agreementTargets } = useAgreementTargets();
  const alternatives = useAlternatives(agreementTargets);
  const { agreementSummaryTarget } = useAgreementsPageContext();
  const { coverage } = useAgreementDataContext();

  const daysCoveredTabs = useMemo(() => {
    if (agreementSummaryTarget.hasMultipleSets) {
      return alternatives?.flatMap(x => x.daysCovered).map(x => `Days Covered - ${x} days`) ?? [];
    }
    return [`Days covered - ${formatRange(coverage.daysCovered) ?? coverage.daysCovered.value} days`];
  }, [alternatives, coverage.daysCovered, agreementSummaryTarget.hasMultipleSets]);

  const tabNames = useMemo(() => [
    ...daysCoveredTabs,
    'Warranties',
    'Subjectivities'
  ], [daysCoveredTabs]);

  if (!alternatives) {
    return [];
  }

  return tabNames;
};
