import { ModalLayout } from '@/components/modal/ModalLayout';
import { useAgreementDetailsContext } from '@/components/pages/agreementDetails/AgreementDetailsContext';
import { useAgreementSelectionContext } from '@/components/pages/agreementDetails/AgreementSelectionContext';
import { Button } from '@/components/shared/Button';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { theme } from '@/utility/theme';
import { Add, TimeMachine } from '@instech/icons';
import { useEffect } from 'react';
import styled from 'styled-components';
import { BulkChangeAreasProvider, useBulkChangeAreasContext } from './BulkChangeAreasContext';
import { BulkChangeAreaColumn } from './columns/BulkChangeAreaColumn';
import { BulkChangePortColumn } from './columns/BulkChangePortColumn';
import { BulkChangeRegionColumn } from './columns/BulkChangeRegionColumn';

const ColumnsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: auto;
  height: 50vh;
  scrollbar-width: thin;
`;

const Column = styled.div`
  width: 32%;
`;

const BulkChangeAreasContent = () => {
  const { setIsBulkChangingAreas } = useAgreementDetailsContext();
  const { areaIds, setAreaIds } = useAgreementSelectionContext();
  const { state, dispatch } = useBulkChangeAreasContext();

  useEffect(() => {
    dispatch({ type: 'SetCheckedPorts', payload: areaIds });
  }, [areaIds, dispatch]);

  const revertHandler = () => {
    dispatch({ type: 'Revert', payload: areaIds });
  };

  const addClickHandler = () => {
    setAreaIds(state.checkedPorts);
    setIsBulkChangingAreas(false);
  };

  return (
    <>
      <ColumnsContainer>
        <Column><BulkChangeRegionColumn /></Column>
        <Column><BulkChangeAreaColumn /></Column>
        <Column><BulkChangePortColumn /></Column>
      </ColumnsContainer>

      <br />

      <ButtonGroup justify="flex-end">
        <Button icon={<TimeMachine />} onClick={revertHandler}>Revert</Button>
        <Button onClick={() => setIsBulkChangingAreas(false)} background={theme.lightGray}>Cancel</Button>
        <Button onClick={addClickHandler} background={theme.green} icon={<Add />}>Add</Button>
      </ButtonGroup>

    </>
  );
};

export const BulkChangeAreasModal = ({ closeModal }: { closeModal: () => void }) => {
  const modalOptions = {
    size: 'xl',
    title: 'Add areas to agreement',
    draggable: true
  };

  return (
    <ModalLayout closeModal={closeModal} options={modalOptions}>
      <BulkChangeAreasProvider>
        <BulkChangeAreasContent />
      </BulkChangeAreasProvider>
    </ModalLayout>
  );
};
