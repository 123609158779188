import {
  Anchor, CurrencyDollar, Gear, PrivacyPolicy
} from '@instech/icons';
import { useMemo } from 'react';
import { Routes } from '@/components/routing/routes';
import { Box } from '@/components/shared/Box';
import { ButtonMenu } from '@/components/shared/ButtonMenu';
import {
  NoDataRow, renderTableHeaders, Table, TableCell, TableRow
} from '@/components/shared/Table/Table';
import { useExcludedAreas } from '@/services/excludedAreasService';
import { useHighRiskAreas } from '@/services/highRiskAreasService';
import { WarWeb } from '@/war';
import { MenuItem } from '@/models/utils/MenuItem';
import QueryString from 'qs';
import { useExcludedAreaPageContext } from '../context/ExcludedAreaPageContext';

const tableHeaders = ['Code', 'Name', 'HRA', ''];

const PortRow = ({ port, hra }: { port: WarWeb.Area, hra?: string }) => {
  const locodeSplit = `${port.locode.substring(0, 2)} ${port.locode.substring(2)}`;

  const menuItems: MenuItem[] = [
    {
      id: 1,
      name: 'Fleet specific T&C',
      icon: <PrivacyPolicy />,
      route: `${Routes.termsSpecific}?${QueryString.stringify({ SelectedCoveredAreas: port.name })}`
    },
    {
      id: 2,
      name: 'Default T&C',
      icon: <Gear />,
      route: `${Routes.termsDefault}?${QueryString.stringify({ SelectedCoveredAreas: port.name })}`
    },
    {
      id: 3,
      name: 'Breach of Warranty',
      icon: <CurrencyDollar />,
      route: `${Routes.bows}?${QueryString.stringify({ SelectedPorts: port.name })}`
    }
  ];

  return (
    <TableRow>
      <TableCell>{locodeSplit}</TableCell>
      <TableCell>{port.name}</TableCell>
      <TableCell>{hra ?? 'No HRA'}</TableCell>
      <TableCell right><ButtonMenu items={menuItems} /></TableCell>
    </TableRow>
  );
};

export const PortColumn = () => {
  const { areaId } = useExcludedAreaPageContext();
  const { data: ports, error } = useExcludedAreas(areaId);
  const sortedPorts = useMemo(() => ports?.sort((x, y) => (x.name > y.name) ? 1 : -1), [ports]);

  const { data: highRiskAreas } = useHighRiskAreas();

  const renderPorts = () => {
    if (!areaId) return null;
    return sortedPorts ?
      sortedPorts.map(port => <PortRow key={port.id} port={port} hra={highRiskAreas?.find(hra => hra.id === port.highRiskAreaId)?.name} />) :
      <NoDataRow loadStatus={{ loading: !ports && !error, success: !error }} />;
  };

  return (
    <Box icon={<Anchor />} header="Ports">
      <Table columns={tableHeaders.length} striped>
        {renderTableHeaders(tableHeaders)}
        {renderPorts()}
      </Table>
    </Box>
  );
};
