import React, { useState } from 'react';
import { isInternalUser } from '@/components/routing/roles';
import {
  getColumnWidth,
  getDelimiters, renderTableSectionHeaders
} from '@/components/shared/Table/core/Components';
import {
  FilterableTableHeaders, NoDataRow, Table
} from '@/components/shared/Table/Table';
import { basepath as vesselsEndpoint, useVessels } from '@/services/vesselsService';
import { WarWeb } from '@/war';
import { NetworkStatusLoading } from '@/services/NetworkStatusLoading';
import { stickyItem } from '@/utility/stateArrayHelpers';
import { useFilterableTableHeadersContext } from '../defaultTermsConditions/FilterableTableHeadersContext';
import { tableSections, tableSectionsInternal } from './tableHeaders';
import { VesselRow } from './VesselRow';

interface VesselTableProps {
  loadStatus: NetworkStatusLoading;
}
export const VesselTable = ({ loadStatus }: VesselTableProps) => {
  const tableSectionsByRole = isInternalUser() ? tableSectionsInternal : tableSections;
  const [agreementCount, setAgreementCount] = useState(0);

  const [baseVessel, setBaseVessel] = useState<WarWeb.MarineMatch>();

  const { headers, filters } = useFilterableTableHeadersContext();

  const { data, error, isLoading, mutate } = useVessels(filters);
  const vessels = stickyItem<WarWeb.MarineMatch>(baseVessel, data?.items, (v => v.vesselId === baseVessel?.vesselId));

  return (
    <Table columns={headers.length} layout={headers.map(h => getColumnWidth(h.format)).join(' ')} striped>
      {renderTableSectionHeaders(tableSectionsByRole)}
      {vessels && (
        <FilterableTableHeaders
          endpoint={vesselsEndpoint}
          delimiters={getDelimiters(tableSectionsByRole)}
        />
      )}

      {vessels && vessels.length > 0
        ? vessels.map(vessel => (
          <VesselRow key={vessel?.vesselId}
            agreementCount={agreementCount}
            setAgreementCount={setAgreementCount}
            vessel={vessel}
            baseVessel={baseVessel}
            setBaseVessel={setBaseVessel}
            mutate={mutate}
          />
        ))
        : <NoDataRow loadStatus={{ loading: isLoading || loadStatus.loading, success: !(loadStatus.success || error) }} />}
    </Table>
  );
};
