export enum Environment {
    Dev = 'dev',
    Test = 'test',
    User = 'user',
    Prod = 'prod'
}

export const getEnvironment = () : Environment => {
  const { host } = window.location;

  if (host.includes('localhost')) {
    const stsUri = document.head.querySelector('meta[name="sts"]')?.getAttribute('content') ?? '';

    if (stsUri.includes(Environment.Dev)) return Environment.Dev;
    if (stsUri.includes(Environment.Test)) return Environment.Test;
  }

  if (host.includes(Environment.Dev)) return Environment.Dev;
  if (host.includes(Environment.Test)) return Environment.Test;
  if (host.includes(Environment.User)) return Environment.User;

  return Environment.Prod;
};

export const isInternalEnvironment = () : boolean => getEnvironment() === Environment.Dev || getEnvironment() === Environment.Test;

export const getEnvironmentName = () => {
  const environment = getEnvironment();
  if (environment === Environment.User) return 'user test';
  return environment;
};

export const getFeatureBranchName = () => {
  const { host } = window.location;
  const testBranch = 'test-insify-war-web-';
  const domain = '.azurewebsites.net';

  const featureBranch = host.match(`${testBranch}(.*)${domain}`);
  return featureBranch ? featureBranch[1] : null;
};
