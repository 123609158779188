import QueryString from 'qs';
import { useSWREndpoint } from '@/hooks/useSWREndpoint';
import { WarWeb } from '@/war';
import { MinSearchLength } from '@/utility/globals';
import { AcceptHeaders } from './config';
import {
  putData, getData, postFetcher
} from './utility/simpleFetch';

export interface SetTierRequestType {
  tierType: string;
  fleetId: number;
}

export const basepathFleets = 'fleets';

const getFleetUri = (clientId: number, fleetId: number) =>
  `clients/${clientId}/fleets/${fleetId}`;

const getUpdateTierUri = (clientId: number, fleetId: number) =>
  `${getFleetUri(clientId, fleetId)}/tier`;

export const updateFleetRequest = async (clientId: number, fleetId: number, fleetRequest: WarWeb.UpdateFleetRequest) =>
  putData(getUpdateTierUri(clientId, fleetId), fleetRequest);

export const getFleetsUri = (params?: any) => `${basepathFleets}?${QueryString.stringify(params, { indices: false })}`;

export const useFleets = (params?: WarWeb.MarineSearchParameters, key?: string) =>
  useSWREndpoint<WarWeb.SearchResult<WarWeb.MarineMatch>>(
    params ? `${basepathFleets}?key=${key}` : null,
    undefined,
    () => postFetcher(basepathFleets, params, AcceptHeaders.SearchResult)
  );
export const useFleetInterestSummary = (clientId?: number, fleetId?: number) => {
  const uri = clientId && fleetId ? `${getFleetUri(clientId, fleetId)}/interestsummary` : null;
  return useSWREndpoint<WarWeb.InterestSummary[]>(uri);
};

export const useFleet = (clientId?: number, fleetId?: number) => useSWREndpoint<WarWeb.Fleet>(clientId && fleetId ? getFleetUri(clientId, fleetId) : null);

// fleets and vessels (search)

const getFleetsAndVesselsUri = (params?: WarWeb.MarineSearchParameters) =>
  `${basepathFleets}/vessels/search?${QueryString.stringify(params, { indices: false })}`;

export const useFleetsAndVessels = (params?: WarWeb.MarineSearchParameters) =>
  useSWREndpoint<WarWeb.MarineMatch[]>(
    params?.freetext && params.freetext.length >= MinSearchLength ? getFleetsAndVesselsUri(params) : null,
    AcceptHeaders.SearchResult
  );

const getFleetHistoryUri = (clientId: number, fleetId: number) => `${getFleetUri(clientId, fleetId)}/history`;

export const useFleetHistory = (clientId: number, fleetId: number) => useSWREndpoint<WarWeb.FleetTierHistory>(getFleetHistoryUri(clientId, fleetId));

export const getFleetsAndVessels = (params?: WarWeb.MarineSearchParameters) => getData(getFleetsAndVesselsUri(params));
