import { ModalLayout } from '@/components/modal/ModalLayout';
import { ConditionsType } from '@/components/pages/agreementDetails/models';
import { Button } from '@/components/shared/Button';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { CustomSelect, CustomSelectValue } from '@/components/shared/Form/fields/CustomSelect';
import { useHateoasExecutorContext } from '@/services/HateoasExecutorContext';
import { useTextConditions } from '@/services/textConditionsService';
import { useValidationContext } from '@/services/ValidationContext';
import { theme } from '@/utility/theme';
import { WarWeb } from '@/war';
import { useEffect, useState } from 'react';
import { ActionMeta, SingleValue } from 'react-select';
import styled from 'styled-components';
import { ConditionsProvider } from './ConditionsContext';
import { ConditionsEditor } from './ConditionsEditor';
import { TextConditionAction } from './ConditionsEditorRow';
import { DangerNotification } from '@instech/components';

const FilterContainer = styled.div`
  float: left;
  margin: 0.5rem 0;
  padding-right: 2em;

  > div {
    width: 250px;
  }
`;

export interface NewTermForLocation {
  id: number;
  locationId: number;
}
export interface TermsByLocation {
  [locationId: number]: number[];
}

interface ConditionsLocationsModalProps {
  closeModal: () => void;
  conditionsType: ConditionsType;
  includedConditions: TermsByLocation;
  updateConditions: (textCondition: NewTermForLocation, conditionAction: TextConditionAction) => void,
  currentLocation: WarWeb.Location;
  locations: WarWeb.Location[];
}
export const ConditionsLocationsModal = ({
  closeModal,
  conditionsType,
  includedConditions,
  updateConditions,
  currentLocation,
  locations
}: ConditionsLocationsModalProps) => {
  const [selectedLocation, setSelectedLocation] = useState<WarWeb.Location>(currentLocation);

  const [selectedTerms, setSelectedTerms] = useState<number[]>(includedConditions[currentLocation.id] || []);

  const { data } = useTextConditions();
  const [terms, setTerms] = useState<WarWeb.Term[]>([]);
  const { lastRequestBody } = useHateoasExecutorContext();
  const { errors, clearErrors } = useValidationContext();

  useEffect(() => {
    if (data) {
      setTerms(data);
    }
  }, [data, includedConditions]);

  useEffect(() => {
    setSelectedTerms(includedConditions[selectedLocation?.id] || []);
  }, [selectedLocation]);

  const update = (term: WarWeb.Term, action: TextConditionAction) => {
    const textCondition: NewTermForLocation = {
      id: term.id,
      locationId: selectedLocation.id
    };
    updateConditions(textCondition, action);
  };

  useEffect(() => {
    if (errors) {
      setTimeout(() => {
        if (lastRequestBody?.textConditionId) {
          // reset (remove) invalid selection
          setSelectedTerms(selectedTerms.filter(t => t !== lastRequestBody.textConditionId));
          clearErrors();
        }
      }, 7000);
    }
  }, [errors]);

  const handleInput = (input: SingleValue<CustomSelectValue>, action: ActionMeta<CustomSelectValue>) => {
    if (!input) return;
    setSelectedLocation(locations.find(l => l.id === input.value) || { id: -1, name: 'General' });
  };

  const options = {
    title: `Available ${conditionsType}`,
    size: 'medium',
    draggable: true
  };

  return (
    <ModalLayout options={options} closeModal={closeModal}>
      {errors && (
        <DangerNotification size="medium" headingText="" descriptionText={errors?.TextConditionId?.join(', ')} />
      )}
      <FilterContainer>
        {currentLocation && locations && locations.length > 0 && (
          <CustomSelect
            name="port"
            aria-label="Applies to"
            placeholder={currentLocation.name}
            defaultValue={{ label: currentLocation.name, value: currentLocation.id }}
            options={locations?.map(l => ({
              label: l.name,
              value: l.id
            }))}
            onChange={handleInput}
          />
        )}
      </FilterContainer>

      <ConditionsProvider selectedTerms={selectedTerms} setSelectedTerms={setSelectedTerms} terms={terms}>
        <ConditionsEditor
          conditionsType={conditionsType}
          update={update} />
      </ConditionsProvider>

      <ButtonGroup justify="flex-end">
        <Button onClick={closeModal} background={theme.green}>Done</Button>
      </ButtonGroup>
    </ModalLayout>
  );
};
