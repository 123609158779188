import { WarWeb } from '@/war';
import { AxiosError } from 'axios';
import { ValidationMessages } from '@/models/ValidationMessages';
import { isValidationError } from '@/utility/errorHelpers';
import { isChanged } from '../utils';
import {
  AreaName,
  EntryExitPointSpecification,
  ExcludedPort, LocodeExclusionInfo, LocodeTimeExceptionInfo, Selection
} from '../types';
import { AreaAliasSpecification, EditExcludedAreasState } from './types';

export const zeroToNull = (value?: number) => value === 0 ? null : value;

export const resolveAction = (p?: Selection) => {
  if (!p) return undefined;
  if (p.isNewSelection) return 'Add';
  if (p.isRemovedSelection) return 'Delete';
  return 'Edit';
};

const highRiskAreaIdUpdate = (p: ExcludedPort) => {
  const value = zeroToNull(p.highRiskAreaIdUpdate?.highRiskAreaId);
  if (value !== undefined && value !== null) {
    return { highRiskAreaId: value };
  }
  return undefined;
};

const mapPort = (p: ExcludedPort): LocodeExclusionInfo => ({
  locode: p.locode,
  highRiskAreaIdUpdate: highRiskAreaIdUpdate(p),
  oceanAreaId: p.isCustomArea ? p.areaId : undefined,
  isCustomArea: p.isCustomArea,
  action: resolveAction(isChanged(p.excluded) ? p.excluded : p.inArea)
});

const portShouldBeIncluded = (port: ExcludedPort) =>
  // (NB! `inArea` checkbox is disabled for country/land areas)
  isChanged(port.excluded)
  // We also need to support the user removing a port from a custom area, which means "exclude back to default" (i.e. country).
  || (port.excluded?.persistedState && isChanged(port.inArea))
  // We also need to support only changing the HRA -- however, this has to be done by "re-excluding" the port (to country/ocean)
  || port.highRiskAreaIdUpdate != null;

export const getPorts = (selectedPorts: ExcludedPort[]): LocodeExclusionInfo[] =>
  selectedPorts
    .filter(portShouldBeIncluded)
    .map(mapPort);

const mapTimeExecption = (p: ExcludedPort): LocodeTimeExceptionInfo => ({
  locode: p.locode,
  oceanAreaId: p.areaId!,
  action: resolveAction(p.inArea)
});

/**
 * Time exceptions are ports included to an ocean area while NOT being excluded.
 * A time exception can be removed from ocean area.
 */
export const getTimeExceptions = (selectedPorts: ExcludedPort[]): LocodeTimeExceptionInfo[] =>
  selectedPorts
    .filter(p => !p.excluded) // has NEVER been (and is not) excluded
    .filter(p => isChanged(p.inArea))
    .map(mapTimeExecption);

const getEntryExitPoints = (entryExitPoints: EntryExitPointSpecification[]) =>
  entryExitPoints
    .map(({ id, ...p }) => ({
      ...p,
      ...(p.action !== 'Add' && { entryExitId: id })
    }));

/** Get a list of all ocean areas (IDs) somehow affected */
export const getUniqueOceanAreaIds = (state: EditExcludedAreasState, ports: LocodeExclusionInfo[], timeExceptions: LocodeTimeExceptionInfo[]) : number[] => {
  const set = new Set([
    ...state.oceanAreaNames.map(u => u.oceanAreaId),
    ...ports.filter(p => p.isCustomArea && p.oceanAreaId !== undefined).map(u => u.oceanAreaId!),
    ...state.entryAndExitPoints.filter(p => p.oceanAreaId !== undefined).map(u => u.oceanAreaId!),
    ...timeExceptions.map(u => u.oceanAreaId),
    ...state.hraForArea.map(u => u.oceanAreaId)
  ]);
  return [...set];
};

export const getUpsertOceanAreaRequestForAreaId = (
  areaId: number,
  state: EditExcludedAreasState,
  ports: LocodeExclusionInfo[],
  points: EntryExitPointSpecification[],
  timeExceptions: LocodeTimeExceptionInfo[]
) : WarWeb.UpsertOceanAreaRequest => {
  const config = state.oceanAreaNames.find(x => x.oceanAreaId === areaId);

  const oceanAreaPorts = config?.action === 'Delete' ? undefined : ports.filter(x => x.oceanAreaId === areaId);
  const oceanAreaPoints = config?.action === 'Delete' ? undefined : getEntryExitPoints(points.filter(x => x.oceanAreaId === areaId));
  const hraValueArea = zeroToNull(state.hraForArea.find(x => x.oceanAreaId === areaId)?.highRiskAreaIdUpdate?.highRiskAreaId);

  return {
    oceanAreaId: config?.action !== 'Add' ? areaId : undefined,
    regionId: config?.regionId,
    action: config?.action ?? 'Edit', // if no config (name etc) was found, then this must be "ports only" (i.e. edit)
    name: config?.name,
    ports: oceanAreaPorts?.map(({ oceanAreaId, isCustomArea, ...rest }) => rest),
    entryAndExitPoints: oceanAreaPoints?.map(({ oceanAreaId, ...rest }) => rest),
    timeExceptions: timeExceptions.filter(x => x.oceanAreaId === areaId)?.map(({ oceanAreaId, ...rest }) => rest),
    highRiskAreaIdUpdate: hraValueArea !== undefined && hraValueArea !== null ? {
      highRiskAreaId: hraValueArea
    } : undefined
  };
};

interface ValidationMessageResponse {
  title: string;
  errors: {
    [key: string]: string[];
  };
}

export const parseValidationErrors = (error: AxiosError<ValidationMessageResponse>, prefix?: string): ValidationMessages => {
  if (!isValidationError(error)) {
    return {
      Global: ['Something went wrong, could not save. Please try again later or contact support']
    };
  }

  const validationMessages = error.response?.data.errors ?? {};
  return {
    Global: Object.values(validationMessages).flat().map(e => `${prefix ?? ''}${e}`)
  };
};

export const parseAreaAliasErrors = (errors: AxiosError<ValidationMessageResponse>[]): ValidationMessages =>
  errors
    .map(error => parseValidationErrors(error, 'Predefined direction: '))
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});

const getDistinct = (array: any[]) => [...new Set(array)];

export const getAliasesToDelete = (oceanAreas: AreaName[], aliases: AreaAliasSpecification[]) => {
  const deletedAreas = oceanAreas.filter(a => a.action === 'Delete');
  const aliasesFromDeletedAreas = aliases
    .filter(a => deletedAreas.some(x => x.oceanAreaId === a.areaId))
    .map(a => a.id);
  const aliasesFromState = aliases
    .filter(a => a.action === 'Delete')
    .map(a => a.id);

  return getDistinct([...aliasesFromDeletedAreas, ...aliasesFromState]);
};
