import { PageHeader } from '@/components/layout/PageHeader';
import { Routes } from '@/components/routing/routes';
import styled from 'styled-components';
import { WarningFilled } from '@instech/icons';
import { AreaSelector } from './AreaSelector';
import { EditExcludedAreasProvider, useEditExcludedAreasContext } from './EditExcludedAreasContext/EditExcludedAreasContext';
import { PortsEditor } from './PortsEditor/PortsEditor';
import { CustomAreaEditor } from './CustomAreaEditor/CustomAreaEditor';
import { PredefinedDirectionsEditor } from './PredefinedDirectionsEditor/PredefinedDirectionsEditor';
import { AddCustomAreaButton } from './AddCustomAreaButton';
import { ExcludedAreasActions } from './ExcludedAreasActions';

const PageContent = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 2fr;
`;

const SideBar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  & > div { // Panes shoud have 100% width
    width: 100%;
  }
`;
const MainContent = styled.div`
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ErrorBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.status.lightRed};
  font-weight: 500;
  border-radius: 2px;
  color: #AD1F23;
  width: 100%;
  height: 2rem;
`;

const GlobalErrors = () => {
  const { state: { errors } } = useEditExcludedAreasContext();
  return (
    <>
      {errors.Global?.map(message => (
        <ErrorBar key={message}>
          <div><WarningFilled /></div>
          <div>{message}</div>
        </ErrorBar>
      ))}
    </>
  );
};

export const EditExcludedAreasPage = () => (
  <EditExcludedAreasProvider>
    <HeaderContainer>
      <PageHeader header="Edit Excluded Areas" breadcrumb={{ label: 'Default settings / Excluded Areas', to: Routes.termsExcludedAreas }} />
      <ExcludedAreasActions />
    </HeaderContainer>
    <GlobalErrors />
    <PageContent>
      <SideBar>
        <AreaSelector />
        <CustomAreaEditor />
        <AddCustomAreaButton />
        <PredefinedDirectionsEditor />
      </SideBar>
      <MainContent>
        <PortsEditor />
      </MainContent>
    </PageContent>
  </EditExcludedAreasProvider>
);
