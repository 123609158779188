import { theme } from '@/utility/theme';
import Select, { GroupBase, Props } from 'react-select';
import { FieldLabel, FieldLayout } from '../core/Components';

export const sharedCustomSelectStyles = {
  singleValue: (provided: any) => ({
    ...provided,
    color: 'currentColor'
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: 'currentColor',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: 'currentColor'
  }),
  input: (provided: any) => ({
    ...provided,
    color: 'currentColor'
  }),
};

export function CustomSelect<Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: Props<Option, IsMulti, Group>) {
  const { name, 'aria-label': label, styles, ...rest } = props;

  const defaultStyles = {
    ...sharedCustomSelectStyles,
    control: (provided: any) => ({
      ...provided,
      border: `1px solid ${theme.border.gray}`,
      borderRadius: '2px',
      height: '40px',
      minWidth: '300px',
    }),
    option: (provided: any) => ({
      ...provided,
      cursor: 'pointer',
      padding: 10
    }),
    menu: (provided: any) => ({
      ...provided,
      top: '35px',
      borderRadius: '0 0 2px 2px',
      zIndex: 10
    }),
  };

  return (
    <FieldLayout>
      {label && (
        <FieldLabel as="label" htmlFor={name}>
          {label}
        </FieldLabel>
      )}
      <Select name={name} styles={{ ...defaultStyles, ...styles }} {...rest} />
    </FieldLayout>
  );
}

export interface CustomSelectValue {
  value: number;
  label: string;
}
