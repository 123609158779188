import { HttpActionProps } from '@/components/shared/HttpAction';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import React, {
  createContext, FC, useContext
} from 'react';

export type HateoasExecutorType = <T>(
  links: HttpActionProps[] | undefined,
  action: string,
  body?: {},
  sectionName?: string,
  config?: AxiosRequestConfig
) => Promise<AxiosResponse<T> | undefined>;

interface HateoasExecutorProps {
  hateoasExecutor: HateoasExecutorType;
  lastRequest?: HttpActionProps;
  lastRequestBody?: any;
  lastRoute?: string;
  setLoading: (loading: boolean) => void;
  loading: boolean;
  whatsLoading?: string;
}

const HateoasExecutorContext = createContext<HateoasExecutorProps>({
  setLoading: () => { },
  loading: false,
  hateoasExecutor: async <T,>() => new Promise<AxiosResponse<T>>(() => { }),
  lastRequest: undefined,
  lastRequestBody: undefined,
  lastRoute: undefined
});

export const HateoasExecutorProvider: FC<HateoasExecutorProps> = ({
  hateoasExecutor,
  lastRequest,
  lastRequestBody,
  lastRoute,
  loading, setLoading,
  whatsLoading,
  children
}) => (
  <HateoasExecutorContext.Provider value={{
    hateoasExecutor,
    lastRequest,
    lastRoute,
    lastRequestBody,
    loading,
    setLoading,
    whatsLoading
  }}>
    {children}
  </HateoasExecutorContext.Provider>
);

export const useHateoasExecutorContext = () => useContext(HateoasExecutorContext);
