import { WarWeb } from '@/war';
import { ValidationMessages } from '@/models/ValidationMessages';
import {
  AreaHra, AreaName, EntryExitPointSpecification, ExcludedPort
} from '../types';
import { AreaAliasSpecification } from './types';

export enum ActionTypes {
  AddEntryExitPoint = 'AddEntryExitPoint',
  DeleteEntryExitPoint = 'DeleteEntryExitPoint',
  UndoDeleteEntryExitPoint = 'UndoDeleteEntryExitPoint',
  SetEntryExitPoints = 'SetEntryExitPoints',

  SetRegionId = 'SetRegionId',

  SetAreaId = 'SetAreaId',

  SetAreaType = 'SetAreaType',

  SetHraForArea = 'SetHraForArea',

  AddSelectedPort = 'AddSelectedPort',
  DeleteSelectedPort = 'DeleteSelectedPort',
  UpdateSelectedPort = 'UpdateSelectedPort',
  SetSelectedPorts = 'SetSelectedPorts',

  AddNewCustomArea = 'AddNewCustomArea',
  DeleteCustomArea = 'DeleteCustomArea',
  UndoAddedCustomArea = 'UndoCustomArea',

  SetIsSaving = 'SetIsSaving',

  SetOceanAreaNames = 'SetOceanAreaNames',

  AddAreaAlias = 'AddAreaAlias',
  DeleteAreaAlias = 'DeleteAreaAlias',
  UndoDeleteAreaAlias = 'UndoDeleteAreaAlias',
  UpdateAreaAlias = 'UpdateAreaAlias',
  SetAreaAliases = 'SetAreaAliases',

  AddError = 'AddError',
  ClearErrors = 'ClearErrors'
}

export type Action =
  { type: ActionTypes.AddEntryExitPoint, payload: EntryExitPointSpecification } |
  { type: ActionTypes.DeleteEntryExitPoint, payload: number } |
  { type: ActionTypes.UndoDeleteEntryExitPoint, payload: number } |
  { type: ActionTypes.SetEntryExitPoints, payload: EntryExitPointSpecification[] } |

  { type: ActionTypes.SetRegionId, payload?: number } |

  { type: ActionTypes.SetAreaId, payload?: number } |

  { type: ActionTypes.SetAreaType, payload?: string } |

  { type: ActionTypes.SetHraForArea, payload: AreaHra[] } |

  { type: ActionTypes.AddSelectedPort, payload: ExcludedPort } |
  { type: ActionTypes.DeleteSelectedPort, payload: string } |
  { type: ActionTypes.UpdateSelectedPort, payload: ExcludedPort } |
  { type: ActionTypes.SetSelectedPorts, payload: ExcludedPort[] } |

  { type: ActionTypes.AddNewCustomArea, payload: number } |
  { type: ActionTypes.DeleteCustomArea, payload: WarWeb.Area } |
  { type: ActionTypes.UndoAddedCustomArea, payload: number } |

  { type: ActionTypes.SetIsSaving, payload: boolean } |

  { type: ActionTypes.SetOceanAreaNames, payload: AreaName[] } |

  { type: ActionTypes.AddAreaAlias, payload: AreaAliasSpecification } |
  { type: ActionTypes.DeleteAreaAlias, payload: number } |
  { type: ActionTypes.UndoDeleteAreaAlias, payload: number } |
  { type: ActionTypes.UpdateAreaAlias, payload: AreaAliasSpecification } |
  { type: ActionTypes.SetAreaAliases, payload: AreaAliasSpecification[] } |

  { type: ActionTypes.AddError, payload: ValidationMessages } |
  { type: ActionTypes.ClearErrors, payload: undefined };
