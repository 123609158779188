export interface StatusConfirmation {
  key: string;
  title: string;
  conditions: string;
  checkbox: boolean;
  responseKey?: string;
  negativeAction: string;
  positiveAction: string;
}
export const quoteStatusConfirmations: StatusConfirmation[] = [
  {
    key: 'CancelAlternative',
    title: 'Cancel Alternative',
    conditions: 'Are you sure you want to cancel the alternative?',
    checkbox: false,
    negativeAction: 'No',
    positiveAction: 'Yes',
  },
  {
    key: 'BindCoverage',
    title: 'Bind Coverage',
    conditions:
      'I confirm I have read, understood and accepted the terms, conditions, warranties, and subjectivities as stated.',
    checkbox: true,
    responseKey: 'confirmed',
    negativeAction: 'Cancel',
    positiveAction: 'Bind Coverage',
  },
  {
    key: 'RequestConfirmation',
    title: 'Request Confirmation',
    conditions:
      'I confirm I have read, understood and accepted the terms, conditions, warranties, and subjectivities as stated.',
    checkbox: true,
    responseKey: 'confirmed',
    negativeAction: 'Cancel',
    positiveAction: 'Request Confirmation',
  },
  {
    key: 'SetReinsurance',
    title: 'Place Exposure & Confirm Coverage',
    conditions:
      'I confirm I have read, understood and accepted the terms, conditions, warranties, and subjectivities as stated.',
    checkbox: false,
    negativeAction: 'Close',
    positiveAction: 'Bind Coverage',
  },
  {
    key: 'FinalizeAlternative',
    title: 'Finalize Coverage',
    conditions:
      'This is the final step of the registration. No more changes can be made to the BOW and closing document(s) will be produced.',
    checkbox: false,
    negativeAction: 'Cancel',
    positiveAction: 'Finalize',
  },
  {
    key: 'DeleteBow',
    title: 'Delete Bow',
    conditions: 'Are you sure you want to delete the bow?',
    checkbox: false,
    negativeAction: 'No',
    positiveAction: 'Yes',
  },
];
