import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  padding: 60px 0;
  margin-top: 60px;
  background: lightgray;
`;

const Content = styled.div`
  max-width: 260px;
  margin: 0 auto;
`;

const Message = styled.div`
  margin-top: 20px;
  color: black;
`;

export const DefaultErrorMessage = ({ message, reset }) => (
  <Container>
    <Content>
      <h2>An Error occurred</h2>
      <a href="/" onClick={reset}>Click here to return to the frontpage</a>
      { message && <Message>{message}</Message> }
    </Content>
  </Container>
);
DefaultErrorMessage.propTypes = {
  message: PropTypes.string,
  reset: PropTypes.func.isRequired
};
