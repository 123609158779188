/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { TableRow, TableRowStyling } from './Components';

const Pane = styled.div<{ shaded?: boolean, backgroundColor?: string, hasOverflow?: boolean, sticky?: boolean; stretch?: boolean }>`
  position: ${props => props.sticky && 'sticky'};
  top: calc(${props => props.theme.header.scrolledHeight} - 55px);
  z-index: ${props => props.sticky && '2'};

  ${props => props.stretch && css`
    width: 100%;
  `};
  
  background-color: ${props => props.backgroundColor ? props.backgroundColor : props.theme.white};
  ${props => props.shaded && css`
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.06);
  `}
  ${props => props.hasOverflow && css`
    overflow: auto;
  `}
`;

interface GridTableProps {
  layout?: string;
  columns?: number;
  striped?: boolean;
  transposed?: boolean;
  maxHeight?: string;
  hover?: boolean;
}
const GridTable = styled.div<GridTableProps>`
  display: grid;
  position: relative;
  
  ${props => props.transposed
    ? css`
    grid-template-rows: repeat(${props.columns}, auto);
    grid-auto-flow: column;

    > span {
      border: none;
    }`
    : css`
  grid-template-columns: ${props.layout ? props.layout :
    props.columns ? `repeat(${props.columns}, auto)` :
      ''};
  `};

  ${props => props.striped && css`
    // Stripe cells of every other immediate TableRow
    > ${TableRowStyling}:nth-of-type(odd) > span {
      background-color: ${props.theme.flatWhite};
    }
    // Nested TableRows
    > ${TableRowStyling} > ${TableRowStyling} {
      > span {
        background-color: ${props.theme.ultraLightGray};
      }
      :nth-of-type(odd) > span {
        background-color: ${props.theme.lightGray};
      }
    }
  `};

  ${props => props.maxHeight && css`
    max-height: ${props.maxHeight};
    overflow: auto;
  `}
`;

interface TableProps {
  layout?: string;
  columns?: number;
  stretch?: boolean;
  striped?: boolean;
  shaded?: boolean;
  backgroundColor?: string;
  transposed?: boolean;
  maxHeight?: string;
  hasOverflow?: boolean;
  hover?: boolean;
  sticky?: boolean;
}
export const Table : FC<TableProps> = ({ layout, columns, striped, shaded, backgroundColor, transposed, maxHeight, hasOverflow, hover, stretch, sticky, children }) => (
  <Pane shaded={shaded} backgroundColor={backgroundColor} hasOverflow={hasOverflow} sticky={sticky} stretch={stretch}>
    <GridTable layout={layout} columns={columns} transposed={transposed} striped={striped} maxHeight={maxHeight} hover={hover}>
      {children}
    </GridTable>
  </Pane>
);
