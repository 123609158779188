import React, {
  FC, useRef, useState, useEffect
} from 'react';
import styled from 'styled-components';
import { Load } from '@instech/icons';
import { NetworkStatusLoading } from '@/services/NetworkStatusLoading';
import { useOutsideAlerter } from '@/hooks/mouseEventHelpers';
import { FieldLayout } from '../core/Components';
import { SearchResults } from './SearchResults';
import { TextFieldDeprecated as TextField } from './TextFieldDeprecated';

const FitFieldLayout = styled(FieldLayout)`
  position: relative;
  display: inline-block;
`;

const LoadingAnimation = styled(Load)`
  position: absolute;
  height: 16px;
  z-index: 10;
  top: 1.8rem;
  right: 1rem;
`;

interface SearchFieldDropdownProps {
  results?: any[],
  name: string;
  id?: string;
  label?: string;
  size?: number;
  keyField?: string;
  placeholder?: string;
  callback: (item: any) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  loadStatus: NetworkStatusLoading,
  renderResult: (item: any) => React.ReactNode;
  error?: string;
  tabIndex?: number;
  autoFocus?: boolean;
}
export const SearchFieldDropdown: FC<SearchFieldDropdownProps> = ({
  results,
  size,
  callback,
  renderResult,
  onChange,
  loadStatus,
  keyField,
  error,
  ...props
}) => {
  const [searchString, setSearchString] = useState('');
  const [display, setDisplay] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    setDisplay(!!results);
  }, [results]);

  const close = () => {
    setDisplay(false);
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchString(e.target.value);
    if (onChange) onChange(e);
  };

  const select = (item: any) => {
    callback(item);
    setSearchString('');
    close();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      if (results?.length === 1) {
        select(results[0]);
      }
    }
  };

  useOutsideAlerter(wrapperRef, display, close);

  return (
    <FitFieldLayout>
      <div ref={wrapperRef} onFocus={() => setDisplay(true)}>
        <TextField
          numberOfCharacters={size}
          autoComplete="off"
          onKeyDown={handleKeyDown}
          onChange={handleInput}
          value={searchString}
          // error={error}
          {...props}
        />
        {loadStatus?.loading && <LoadingAnimation />}
        <SearchResults
          results={results}
          display={display}
          select={select}
          renderResult={renderResult}
          keyField={keyField}
        />
      </div>
    </FitFieldLayout>
  );
};
