import { Routes } from '@/components/routing/routes';
import { QuoteProvider } from '@/components/shared/BowSidebar/QuoteContext';
import { usePageSettingsContext } from '@/components/userSettings/PageSettingsContext';
import { useQuote } from '@/hooks/useQuote';
import { useBow } from '@/services/bowServices';
import { ValidationProvider } from '@/services/ValidationContext';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { QuoteCommentsProvider } from '../../create-bow/QuoteCommentsContext';
import { countDestinations } from '../../create-bow/utilities/destinationsHelpers';
import { BowDetails } from './BowDetails';

const Page = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${props => props.theme.portal.maxWidth};
  margin: 0 auto;
`;

export const BowDetailsPage = () => {
  const [selectedConfiguration, setSelectedConfiguration] = useState();
  const history = useHistory();

  const { bowId, quoteId } = useParams();
  const [selectedQuoteId, setSelectedQuoteId] = useState(quoteId);

  const { data: bowData, error, isLoading } = useBow(bowId);
  const selectedOrDefaultQuoteId = selectedQuoteId || bowData?.primaryQuote.id;
  const selectedBowId = bowData?.id;

  const selectedQuote = useQuote(selectedBowId, selectedOrDefaultQuoteId);

  const [isNoClaimsBonusBased, setIsNoClaimsBonusBased] = useState(true);
  const [isMultiDestination, setIsMultiDestination] = useState();

  const { pageSettings, setPageSettings } = usePageSettingsContext();

  useEffect(() => {
    if (!isLoading && error?.response?.status === 404) history.push(Routes.bows);

    if (!isLoading && error?.response?.status === 409) {
      setPageSettings(
        {
          bows: {
            ...pageSettings?.bows,
            filters: {
              SelectedInternalReferences: [bowId]
            },
          }
        }
      );
      history.push(Routes.bows);
    }

    setIsNoClaimsBonusBased(selectedQuote?.claimsPenaltyInfo.calculationType === 'NoClaimBonus');
    setIsMultiDestination(() => countDestinations(selectedQuote) > 1);
  }, [bowData, selectedQuote, error]);

  return (
    <QuoteProvider
      ids={{ bowId: selectedBowId, quoteId: selectedOrDefaultQuoteId }}
      setSelectedQuoteId={setSelectedQuoteId}
      selectedConfiguration={selectedConfiguration}
      setSelectedConfiguration={setSelectedConfiguration}
      isNoClaimsBonusBased={isNoClaimsBonusBased}
      isMultiDestination={isMultiDestination}
    >
      <ValidationProvider>
        <QuoteCommentsProvider>
          <Page>
            <BowDetails />
          </Page>
        </QuoteCommentsProvider>
      </ValidationProvider>
    </QuoteProvider>
  );
};
