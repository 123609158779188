import { Routes } from '@/components/routing/routes';
import { Button } from '@/components/shared/Button';
import { theme } from '@/utility/theme';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { Trash } from '@instech/icons';
import { deleteAgreements } from '@/services/agreementsService';
import { isAdmin } from '@/components/routing/roles';
import { useAgreementsPageContext } from '../../agreements/AgreementsPageContext';
import { AgreementViewMode, useAgreementDetailsContext } from '../AgreementDetailsContext';
import { DeleteAgreementsConfirmationModal } from '../../agreementsOverview/deleteSelection/DeleteAgreementsConfirmationModals';

export const DeleteAgreementsButton = ({ disabled }: { disabled?: boolean; }) => {
  const { setShowDeleteStatus, agreementSummaryTarget } = useAgreementsPageContext();
  const { viewMode, targetsSummary } = useAgreementDetailsContext();
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const deleteHandler = async () => {
    const deleteTargets = {
      ...agreementSummaryTarget,
      daysCoveredGroup: undefined,
      minimumSumInsured: undefined
    };

    await deleteAgreements(deleteTargets);
    setShowDeleteStatus(true);
    setShowDeleteModal(false);
    history.push(Routes.termsDefault);
  };

  if (viewMode !== AgreementViewMode.Inspect || !isAdmin()) return null;

  return (
    <>
      <Button
        onClick={() => setShowDeleteModal(true)}
        background={theme.ultraLightGray}
        icon={<Trash />}
        disabled={disabled}>
        Delete
      </Button>
      {showDeleteModal && (
        <DeleteAgreementsConfirmationModal
          numberOfAgreements={targetsSummary.agreementIds.length}
          closeModal={() => setShowDeleteModal(false)}
          apply={deleteHandler} />
      )}
    </>
  );
};
