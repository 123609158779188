import { useSelectedQuote } from '@/components/shared/Bow/useSelectedQuote';
import { ChangeIndicator } from '@/components/shared/BowSidebar/ChangeIndicator';
import { useQuoteContext } from '@/components/shared/BowSidebar/QuoteContext';
import { CustomSelect, CustomSelectValue } from '@/components/shared/Form/fields/CustomSelect';
import { FocusType } from '@/components/shared/Form/fields/DateTimePicker';
import { DateTimePicker, TextField } from '@/components/shared/Form/FormFields';
import {
  checkAction, checkLink, HateoasHandler
} from '@/components/shared/HateoasHandler';
import { Validator } from '@/components/shared/Validator';
import { useHateoasExecutorContext } from '@/services/HateoasExecutorContext';
import { renderDestionationsResult } from '@/utility/destinationsDropdownHelpers';
import { keysOf } from '@/utility/objectHelpers';
import { TimeConverter } from '@/utility/timeConverter';
import { WarWeb } from '@/war';
import { BoatFront, Close } from '@instech/icons';
import React, {
  useEffect, useRef, useState
} from 'react';
import { ActionMeta, SingleValue } from 'react-select';
import styled, { css } from 'styled-components';
import { theme } from '../../../../utility/theme';
import { DestinationOption } from '../../bows/details/destinationOption';
import { QuoteActions } from '../../bows/details/quoteActionsMap';
import {
  DotWrapper, RemoveContainer, TrailDot
} from './Components';

const Centered = styled.div`
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  display: contents;
`;

const SubContent = styled.div<{ timeException: boolean }>`
  display: flex;
  align-items: flex-start;
  background-color: ${props => props.timeException ? props.theme.lightGreen80 : props.theme.white};

  width: 100%;

  > div {
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5em 1em 0.8em 1em;
    font-size: 14px;
  }

  &:first-child {
    border-left: 1px solid ${theme.marineBlue50};
  }

  &:last-child {
    border-right: 1px solid ${theme.marineBlue50};
    align-items: center;
  }
`;

export interface PortSwapSelectValue extends DestinationOption {
  value: number;
  label: string;
  isDisabled?: boolean;
}

const PortSwapOption = styled.div`
  padding: 10px 0;

  &:hover {
    cursor: pointer;
    background-color: ${theme.flatWhite};
  }
`;

const PortWrapper = styled.div`
  width: 100%;
`;

const PortSwapHeader = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${theme.lightBlue};
  padding: 10px 0;
  color: ${theme.marineBlue50};
  cursor: default;
`;

const CustomOption = (props: any) => {
  const { innerProps, innerRef } = props;

  return (
    !props.data.isValid ? (
      <PortSwapHeader>
        <div>{props.data.label}</div>
      </PortSwapHeader>
    )
      : (
        <PortSwapOption ref={innerRef} {...innerProps}>
          {renderDestionationsResult(props.data)}
        </PortSwapOption>
      )
  );
};

interface IDestination extends Partial<WarWeb.Destination> { }

interface DatesState {
  entry?: Date,
  exit?: Date
}

interface DestinationProps {
  index: number;
  coveredAreaId: number,
  coveredAreaIndex: number,
  destination: WarWeb.Destination,
  first: boolean,
  last: boolean,
  exit: boolean
}
export const Destination = ({
  index,
  coveredAreaId,
  coveredAreaIndex,
  destination,
  first,
  last,
  exit
}: DestinationProps) => {
  const [destinationState, setDestinationState] = useState(destination);
  const [datesState, setDatesState] = useState<DatesState>({ entry: destination?.entry?.local, exit: destination?.exit?.local });
  const { hateoasExecutor, loading, lastRequest, lastRequestBody } = useHateoasExecutorContext();
  const [changedField, setChangedField] = useState<string>();
  const { isMultiDestination } = useQuoteContext();
  const destinationRef = useRef<HTMLDivElement>(null);

  const { isTimeException } = destination.port;

  useEffect(() => {
    if (destination) {
      setDestinationState(destination);
      setDatesState({ entry: destination?.entry?.local, exit: destination?.exit?.local });
    }
  }, [destination]);

  const selectedQuote = useSelectedQuote();
  const coveredAreas = selectedQuote?.coveredAreas;
  if (!coveredAreas) return null;

  const selectablePorts: WarWeb.Destination[] = coveredAreas[coveredAreaIndex].itinerary?.filter(
    (x: WarWeb.Destination) => x.id !== destination?.id && x.entry !== destinationState.entry
  );

  const updateDestination = (id: number) => {
    const swapDestinationRequest = {
      otherDestinationId: id
    };

    void hateoasExecutor(destinationState.links, QuoteActions.SwapDestinations, swapDestinationRequest, 'destination');
  };

  const updateDestinationTimes = (dest: IDestination) => {
    const updateDestinationTimesRequest: WarWeb.UpdateDestinationTimesRequest = {
      coveredAreaId,
      destinationId: dest.id,
      entry: dest.entry?.local ? TimeConverter.ToNaiveDateTime(dest.entry.local) : undefined,
      exit: dest.exit?.local ? TimeConverter.ToNaiveDateTime(dest.exit.local) : undefined
    };

    void hateoasExecutor(dest.links, QuoteActions.UpdateDestinationTimes, updateDestinationTimesRequest, 'destination');
  };

  const deleteDestination = () => {
    if (loading) return;
    const deleteDestinationRequest = {
      coveredAreaId,
      destinationId: destinationState.id
    };

    void hateoasExecutor(destinationState.links, QuoteActions.DeleteDestination, deleteDestinationRequest, 'destination');
  };

  const updateDates = (inputDate: DatesState) => {
    const field = keysOf(inputDate)[0];
    setChangedField(field);
    const value = inputDate[field];
    if (!value) return;

    const isFirst = !datesState[field];
    if (isFirst) value.setHours(0);

    const updatedDestination: IDestination = {
      ...destination,
      entry: {
        local: inputDate.entry || datesState.entry
      },
      exit: {
        local: inputDate.exit || datesState?.exit
      }
    };
    setDatesState({
      ...datesState,
      ...(inputDate.entry && { entry: inputDate.entry }),
      ...(inputDate.exit && { exit: inputDate.exit })
    });

    updateDestinationTimes(updatedDestination);
  };

  const handleInput = (input: SingleValue<CustomSelectValue>, action: ActionMeta<CustomSelectValue>) => {
    if (!input) return;
    updateDestination(input.value);
  };

  let swapPortsOptions: PortSwapSelectValue[] = [];
  const defaultOption: PortSwapSelectValue = {
    label: destinationState.port.name,
    value: destinationState.id,
    name: destinationState.port.name,
    entry: destinationState.entry,
    exit: destinationState.exit,
    type: destinationState.port.isTimeException ? 'TimeException' : 'Port',
    isValid: true
  };

  if (selectablePorts?.length > 0) {
    swapPortsOptions = selectablePorts.map(p => (
      {
        label: p.port.name,
        value: p.id,
        name: p.port.name,
        entry: p.entry,
        exit: p.exit,
        type: p.port.isTimeException ? 'TimeException' : 'Port',
        isValid: true
      }));
    swapPortsOptions.unshift({
      label: 'Swap with destination:',
      name: '',
      type: 'Header',
      value: -1,
      isValid: false
    });
  }

  const styles = {
    control: (provided: any) => ({
      ...provided,
      minWidth: 0,
      border: `1px solid ${theme.marineBlue50}`,
      borderRadius: '2px',
      height: '40px'
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: theme.mediumRed,
    }),
  };

  const determineLabel = () => {
    if (isTimeException) return 'Time exc.';
    if (first) return 'Start';
    if (last && exit) return 'End';
    return undefined;
  };

  const lastRequestWasToAddThisDestination = !!lastRequest
    && !!checkAction([lastRequest], QuoteActions.AddArea) && destination.port.id === lastRequestBody.areaId;

  const entryDateFocus = !destinationState?.entry && lastRequestWasToAddThisDestination;
  const exitDateFocus = !entryDateFocus && !destinationState?.exit && lastRequestWasToAddThisDestination;

  if (lastRequestWasToAddThisDestination && destinationRef.current) destinationRef.current.scrollIntoView({ block: 'end' });

  return (
    <>
      {isMultiDestination && (
        <DotWrapper>
          <TrailDot
            last={last && exit}
            first={first}
            color={isTimeException ? theme.green : undefined}
            label={determineLabel()}>
            {last && exit && <BoatFront />}
          </TrailDot>
        </DotWrapper>
      )}

      <Content ref={destinationRef}>

        <SubContent timeException={isTimeException}>

          <div>
            <TextField
              name="area"
              label="Country"
              nonedit
              value={destinationState?.country.name}
            />
          </div>
        </SubContent>

        <SubContent timeException={isTimeException}>
          <Centered>
            <Validator
              keys={[`CoveredAreas[${coveredAreaIndex}].Itinerary[${index}].Port`]}
              isRelevant={!!checkLink(destinationState.links, lastRequest)}
            >
              {swapPortsOptions?.length > 0 ? (
                <PortWrapper>
                  <CustomSelect
                  aria-label="Port"
                  name="port"
                  placeholder={destinationState.port.name}
                  value={defaultOption}
                  options={swapPortsOptions}
                  components={{ Option: CustomOption }}
                  isDisabled={loading || !checkAction(destination.links, QuoteActions.UpdateDestination)}
                  onChange={handleInput}
                  styles={styles}
                />

                </PortWrapper>
              ) : (
                <TextField
                  name="port"
                  label="Port"
                  nonedit
                  value={destinationState?.port.name}
                />
              )}
            </Validator>
          </Centered>
        </SubContent>

        <HateoasHandler
          links={destinationState.links}
          action={QuoteActions.UpdateDestinationTimes}
          editVariant={(
            <>
              <SubContent timeException={isTimeException}>
                <Centered>
                  <Validator
                    keys={['Entry', `CoveredAreas[${coveredAreaIndex}].Itinerary[${index}].Entry`]}
                    isRelevant={!!checkLink(destinationState.links, lastRequest)}
                  >
                    <ChangeIndicator path={`coveredAreas[${coveredAreaIndex}].itinerary[${index}].entry`}>
                      <DateTimePicker
                        name={`CA_${coveredAreaIndex}_DEST_${index}_ETA`}
                        label="ETA"
                        id={`CA_${coveredAreaIndex}_DEST_${index}_ETA`}
                        withTime
                        timeZoneId={destinationState.port.timeZoneId}
                        date={destinationState?.entry?.local ?? undefined}
                        setDate={date => updateDates({ entry: date })}
                        loading={loading && !!checkLink(destinationState.links, lastRequest) && changedField === 'entry'}
                        initialFocus={entryDateFocus ? FocusType.DateField : undefined}
                        disabled={loading}
                      />

                    </ChangeIndicator>

                  </Validator>
                </Centered>
              </SubContent>

              <SubContent timeException={isTimeException}>

                <Centered>
                  <Validator
                    keys={['Exit', `CoveredAreas[${coveredAreaIndex}].Itinerary[${index}].Exit`]}
                    isRelevant={!!checkLink(destinationState.links, lastRequest)}
                  >
                    <ChangeIndicator path={`coveredAreas[${coveredAreaIndex}].itinerary[${index}].exit`}>
                      <DateTimePicker
                        name={`CA_${coveredAreaIndex}_DEST_${index}_ETD`}
                        label="ETD"
                        id={`CA_${coveredAreaIndex}_DEST_${index}_ETD`}
                        withTime
                        timeZoneId={destinationState.port.timeZoneId}
                        date={destinationState?.exit?.local ?? undefined}
                        setDate={date => updateDates({ exit: date })}
                        loading={loading && !!checkLink(destinationState.links, lastRequest) && changedField === 'exit'}
                        initialFocus={exitDateFocus ? FocusType.DateField : undefined}
                        disabled={loading}
                      />

                    </ChangeIndicator>
                  </Validator>
                </Centered>
              </SubContent>
            </>
          )}
          viewVariant={(
            <>
              <SubContent timeException={isTimeException}><div /></SubContent>
              <SubContent timeException={isTimeException}><div /></SubContent>
              {/* <SubContent timeException={timeException}>
                <div>
                  <ButtonWrapper>
                    <Button
                    thin
                    onClick={() => console.log('TODO')}
                    icon={<Calendar />}
                    background={theme.lightBlue}
                    >
                      Add estimated times
                    </Button>
                  </ButtonWrapper>
                </div>
              </SubContent>
              <SubContent timeException={timeException}>
                <div />
              </SubContent> */}
            </>
          )} />
        <SubContent timeException={isTimeException}>
          <RemoveContainer>
            <HateoasHandler
              links={destinationState.links}
              action={QuoteActions.DeleteDestination}
              editVariant={<Close onClick={() => deleteDestination()} />}
            />
          </RemoveContainer>
        </SubContent>
      </Content>
    </>
  );
};
