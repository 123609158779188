import { useEntryExitPoints } from '@/services/excludedAreasService';
import { ModalLayout } from '@/components/modal/ModalLayout';
import { Plus } from '@instech/icons';
import { LabelValuePair } from '@instech/components';
import { useEditExcludedAreasContext } from '../EditExcludedAreasContext/EditExcludedAreasContext';
import { AreaAliasSpecification } from '../EditExcludedAreasContext/types';
import { getAliasDescription } from './utils';
import { getNewActionItemId } from '../utils';
import { PredefinedDirectionsModalForm } from './PredefinedDirectionsModalForm';

interface FormValues {
  name: string;
  entry?: LabelValuePair;
  exit?: LabelValuePair;
}

interface Props { closeModal: () => void; }
export const AddPredefinedDirectionModal = ({ closeModal }: Props) => {
  const { state: { areaId, areaType, areaAliases }, addAreaAlias } = useEditExcludedAreasContext();
  const isNewArea = (!!areaId && areaId < 0);
  const isCustomArea = (areaType === 'Ocean' || isNewArea);
  const { data: entryExitPoints } = useEntryExitPoints(isCustomArea ? areaId : undefined);

  const dropdownOptions = entryExitPoints?.map(p => ({ label: p.name, value: `${p.id}` })) ?? [];
  const existingNames = areaAliases.map(alias => alias.name);

  const handleAdd = (values: FormValues) => {
    const entryPointId = Number(values.entry!.value);
    const exitPointId = Number(values.exit!.value);
    const description = getAliasDescription(entryPointId, exitPointId, entryExitPoints!);
    const alias: AreaAliasSpecification = {
      id: getNewActionItemId(areaAliases),
      name: values.name,
      entryPointId,
      exitPointId,
      description,
      action: 'Add',
      areaId: areaId!
    };
    addAreaAlias(alias);
    closeModal();
  };

  return (
    <ModalLayout closeModal={closeModal} options={{ title: 'Add new Direction', size: 'small' }}>
      <PredefinedDirectionsModalForm
        initialValues={{ name: '', entry: undefined, exit: undefined }}
        onSubmit={handleAdd}
        dropdownOptions={dropdownOptions}
        closeModal={closeModal}
        submitText="ADD"
        submitIcon={<Plus />}
        existingNames={existingNames}
      />
    </ModalLayout>
  );
};
