import * as Yup from 'yup';

export const stringRequired = (message = 'Required') => (
  Yup.string()
    .default('')
    .trim()
    .required(message)
);

export const dropdownOptionRequired = () => Yup.object().required('Required');
