import React from 'react';
import ReactTooltip from 'react-tooltip';
import { theme } from './theme';

const matchAllBeforeParenthesis = /^([^(]*)/;

export const getInitials = (name?: string) => {
  if (!name) return '';
  const match = name.match(matchAllBeforeParenthesis);
  if (match) return match[0].split(' ').map(n => n[0]).join('');
  return name;
};

// export const getLastName = (user: WarWeb.User) => {
//   const match = user?.name.match(/.*\s+([^(]+)/);
//   if (match) return match[match.length - 1];
//   return user?.name;
// };

export const UserInitials = ({ name, initials } : {name?: string, initials?: string}) => {
  if (!name) return null;
  const initial = initials || getInitials(name);

  return (
    <>
      <span data-tip={name}>{initial}</span>
      <ReactTooltip backgroundColor={theme.marineBlue} effect="solid" />
    </>
  );
};
