import React, {
  FC, useEffect, useState
} from 'react';
import { EmptyTable, TableHeader } from '@/components/shared/Table/core/Components';
import { isBroker } from '@/components/routing/roles';
import { WarWeb } from '@/war';
import { useQuoteContext } from '@/components/shared/BowSidebar/QuoteContext';
import { Globals } from '@/utility/globals';
import { CalculationsRow, getDelimiter } from './CalculationsRow';
import { Table } from '../../Table/Table';
import { useSelectedQuote } from '../useSelectedQuote';

interface CalculationsTableProps {
  calculations?: WarWeb.Calculation[];
  calculationsType: string;
  showTaxes: boolean;
  transposed: boolean;
}
export const CalculationsTable: FC<CalculationsTableProps> = ({ calculations, calculationsType, showTaxes, transposed }) => {
  const { isNoClaimsBonusBased } = useQuoteContext();
  const selectedQuote = useSelectedQuote();

  const blankRows = Array(4).fill({});
  const [displayData, setDisplayData] = useState<WarWeb.Calculation[]>(calculations || blankRows);

  const columnsLeft = isBroker()
    ? [
      'Currency',
      'Interest',
      'Sum Insured',
      'Gross Rate',
      'Net Rate',
      'Gross Premium'
    ] : [
      'Currency',
      'Interest',
      'Sum Insured',
      ...(!isNoClaimsBonusBased) ? ['Gross Rate<br />if NCB'] : [],
      'Gross Rate',
      ...(!isNoClaimsBonusBased) ? ['Gross Premium<br />if NCB'] : [],
      'Gross Premium'
    ];

  const showNetPremiumAfterTimeAdjustment = !!displayData[0]?.netPremiumAfterTimeAdjustment;
  const isAdjustedForMinimumPremium = !!calculations?.some(c => c.adjustedForMinimumPremium);
  const netPremiumPrefix = isAdjustedForMinimumPremium ? 'Minimum ' : 'Net ';

  const hasTimeAdjustmentFactor = selectedQuote?.duration?.timeAdjustmentFactor > 0 || false;
  const netPremiumTimeAdjustmentPrefix = !hasTimeAdjustmentFactor ? 'Minimum ' : 'Net ';

  const columnsRight = calculationsType === 'Voyage' ? [
    `${netPremiumTimeAdjustmentPrefix}Premium After Rebates`,
    ...(showNetPremiumAfterTimeAdjustment) ? [`${netPremiumPrefix}Premium After Time Adjustment`] : [],
    ...(showTaxes) ? ['Tax %'] : [],
    ...(showTaxes && isBroker()) ? ['Tax Amount'] : [],
    ...(showTaxes) ? [`${netPremiumPrefix}Premium Including Tax`] : []
  ] : [];

  const numberColumnsLeft = columnsLeft.length;
  let numberDynamicRebateColumns = transposed ? displayData[0]?.rebates?.length || 0 : 1;
  if (!isNoClaimsBonusBased && calculationsType === 'Voyage') {
    if (transposed && displayData && displayData.length > 0 && displayData[0]?.rebates?.length > 0) {
      numberDynamicRebateColumns -= 1; // because we hide (skip) NCB column below
    }
    columnsRight.push('Claims Penalty');
  }
  if (calculationsType !== 'Voyage') numberDynamicRebateColumns = 0; // we don't show rebates per destination
  const numberColumnsRight = columnsRight.length;
  const totalNumberColumns = numberColumnsLeft + numberDynamicRebateColumns * (transposed ? 2 : 1) + numberColumnsRight;

  useEffect(() => {
    if (calculations) {
      setDisplayData(calculations);
    }
  }, [calculations]);

  const firstRebate = !isNoClaimsBonusBased && displayData[0]?.rebates && displayData[0]?.rebates[0]?.shortName === 'NCB' ? 1 : 0;
  const rebateHeaders = displayData[0]?.rebates?.map(x => (isNoClaimsBonusBased || (!isNoClaimsBonusBased && x.shortName !== 'NCB')) ? x.shortName : null)
    .filter(x => x)?.join(', ');

  return (
    <Table columns={totalNumberColumns} striped={!transposed} transposed={transposed} shaded={false} layout={`repeat(${totalNumberColumns}, 1fr)`}>
      {!transposed && numberDynamicRebateColumns > 0 && (
        <>
          <TableHeader span={numberColumnsLeft} noBorder />
          <TableHeader span={numberDynamicRebateColumns} delimiter="left" noBorder><h2>Rebates</h2></TableHeader>
          <TableHeader span={numberColumnsRight} delimiter="left" noBorder />
        </>
      )}
      {transposed ? (
        <>
          <TableHeader />
          <TableHeader>Currency</TableHeader>
        </>
      ) : (
        <>
          <TableHeader>Currency</TableHeader>
          <TableHeader>Interest</TableHeader>
        </>
      )}
      <TableHeader right={!transposed}>Sum Insured</TableHeader>
      {isNoClaimsBonusBased || isBroker() ?
        (
          <>
            <TableHeader right={!transposed}>Gross Rate</TableHeader>
            {isBroker() && <TableHeader right={!transposed}>Net Rate</TableHeader>}
            <TableHeader right={!transposed}>Gross Premium</TableHeader>
          </>
        ) : (
          <>
            <TableHeader right={!transposed}>
              Gross Rate
              <br />
              if NCB
            </TableHeader>
            <TableHeader right={!transposed}>Gross Rate</TableHeader>
            <TableHeader right={!transposed}>
              Gross Premium
              <br />
              if NCB
            </TableHeader>
            <TableHeader right={!transposed}>Gross Premium</TableHeader>
          </>

        )}

      {calculationsType === 'Voyage' && (
        <>
          {transposed ? (
            displayData[0]?.rebates && displayData[0]?.rebates.map((each: WarWeb.RebateDeduction, i: number) => (
              (isNoClaimsBonusBased || (!isNoClaimsBonusBased && each.shortName !== 'NCB'))
              && (
                <React.Fragment key={each.rebateId}>
                  <TableHeader right={!transposed} delimiter={i === firstRebate ? 'left' : 'none'}>
                    {transposed ? each.name : each.shortName}
                  </TableHeader>
                  {transposed && <TableHeader right={!transposed}>Net after rebate</TableHeader>}
                </React.Fragment>
              )
            ))
          ) : (
            <TableHeader right={!transposed} delimiter="left">
              {rebateHeaders}
            </TableHeader>
          )}

          {columnsRight.map((header, i) => (
            <TableHeader key={header} right={!transposed} delimiter={i === 0 ? getDelimiter(transposed) : undefined}>
              {header}
            </TableHeader>
          ))}

        </>
      )}

      {displayData ? displayData.map((row: any, i: number) => (
        ((calculationsType === 'Voyage') || (calculationsType !== 'Voyage' && row.interestNumber !== Globals.KnRInterestNumber)) && (
          <CalculationsRow
            key={row.id}
            showTaxes={showTaxes}
            transposed={transposed}
            showNetPremiumAfterTimeAdjustment={showNetPremiumAfterTimeAdjustment}
            showCurrency={i === 0 || displayData[i - 1].currency !== displayData[i].currency}
            calculation={row}
            calculationsType={calculationsType}
            delimiters={[numberColumnsLeft, numberColumnsLeft + numberDynamicRebateColumns]}
          />
        )
      )) : (
        <EmptyTable />
      )}

    </Table>
  );
};
