import { useEntryExitPoints } from '@/services/excludedAreasService';
import { useEffect } from 'react';
import { EntryExitPointSpecification } from '../types';

export const useSeedEntryExitPoints = (callback: (points: EntryExitPointSpecification[]) => void, areaType?: string, areaId?: number) => {
  const isNewArea = (!!areaId && areaId < 0);
  const isCustomArea = (areaType === 'Ocean' || isNewArea);
  const { data: entryExitPoints } = useEntryExitPoints(isCustomArea && !isNewArea ? areaId : undefined);

  useEffect(() => {
    if (entryExitPoints) {
      callback(entryExitPoints.map(point => ({
        ...point,
        action: 'NotSet', // existing point
        oceanAreaId: areaId,
        timezoneId: point.timeZone
      })));
    }
  }, [entryExitPoints, areaId, callback]);
};
