import { TextField } from '@/components/shared/Form/FormFields';
import { Search } from '@instech/icons';
import styled from 'styled-components';

const StyledSearch = styled(Search)`
  svg {
    height: 10px;
  }
`;

export const SearchBox = ({ handleInput, ...props }) => (
  <TextField
    name="searchString"
    onChange={handleInput}
    rightIcon={<StyledSearch />}
    {...props}
  />
);
