import { ButtonGroup, SlimButton } from '@instech/components';
import { useHistory } from 'react-router-dom';
import { Routes } from '@/components/routing/routes';
import { useEditExcludedAreasContext } from './EditExcludedAreasContext/EditExcludedAreasContext';
import { useExcludedAreaPageContext } from '../context/ExcludedAreaPageContext';

export const ExcludedAreasActions = () => {
  const { state: { isSaving }, canSave, saveAsync } = useEditExcludedAreasContext();
  const { setHasSaved } = useExcludedAreaPageContext();
  const history = useHistory();

  const handleSave = async () => {
    const success = await saveAsync();
    if (success) {
      setHasSaved(true);
      history.push(Routes.termsExcludedAreas);
    }
  };

  const handleCancel = () => {
    setHasSaved(false);
    history.push(Routes.termsExcludedAreas);
  };

  return (
    <ButtonGroup>
      <SlimButton variant="secondary" disabled={isSaving} onClick={handleCancel}>CANCEL</SlimButton>
      <SlimButton disabled={!canSave} loading={isSaving} onClick={handleSave}>SAVE</SlimButton>
    </ButtonGroup>
  );
};
