import {
  ArrowForward,
  Open,
  Pen,
  Trash
} from '@instech/icons';
import { FlexBox } from '@/components/shared/FlexBox';
import {
  useEffect, useState, JSX
} from 'react';
import {
  createAgreementAlternative, deleteAgreementAlternative, updateAgreements, useAgreementsSummaryAlternatives
} from '@/services/agreementsService';
import styled from 'styled-components';
import { WarWeb } from '@/war';
import { formatLargeInteger } from '@/utility/formatter';
import ReactTooltip from 'react-tooltip';
import { theme } from '@/utility/theme';
import { isAdmin } from '@/components/routing/roles';
import { TargetType } from '@/models/TargetType';
import { IconButton } from '@instech/components';
import {
  SumInsuredModal, SumInsuredModalData, SumInsuredModalMode
} from './SumInsuredModal';
import { useAgreementDetailsContext } from '../agreementDetails/AgreementDetailsContext';
import { useAgreementTargets } from '../agreements/useAgreementTargets';
import { useAgreementsPageContext } from '../agreements/AgreementsPageContext';
import { useAgreementSaveTargets } from '../agreements/useAgreementSaveTargets';
import { ChangeTabConfirmationModal } from './ChangeTabConfirmationModal';
import { anyAgreementChanges } from '../defaultTermsDetails/deltaHelper';
import { useAgreementDataContext } from '../agreementDetails/AgreementDataContext';
import { useAgreementSelectionContext } from '../agreementDetails/AgreementSelectionContext';

const SumInsuredText = styled.div<{ isSelected?: boolean }>`
  cursor: pointer;
  border-bottom: ${props => props.isSelected ? `2px solid ${props.theme.marineBlue}` : ''};
`;

const ArrowContainer = styled.span`
  display: flex;
  margin-left: 1.5rem;
`;

const ErrorContainer = styled.div`
  color: ${props => props.theme.red};
`;

const StyledButton = styled(IconButton)`
  color: ${props => props.theme.marineBlue};
  &:disabled {
    color: ${props => props.theme.lightGray};
  }
`;

interface ActionButtonProps {
  icon: JSX.Element;
  onClick: () => void;
  disabled: boolean;
  tooltip: string;
}
const ActionButton = ({ icon, onClick, disabled, tooltip }: ActionButtonProps) => {
  const tooltipId = `${tooltip}-tooltipId`;
  return (
    <>
      <StyledButton
        data-tip
        data-for={tooltipId}
        icon={icon}
        disabled={disabled}
        onClick={onClick}
      />
      <ReactTooltip id={tooltipId} backgroundColor={theme.marineBlue} effect="solid">
        {tooltip}
      </ReactTooltip>
    </>
  );
};

export const SumInsuredYacht = () => {
  const { mutateAgreement } = useAgreementDetailsContext();
  const { coverage, premium, conditions } = useAgreementDataContext();
  const { agreementTargets } = useAgreementTargets();
  const saveTargets = useAgreementSaveTargets();
  const { agreementSummaryTarget, setAgreementSummaryTarget } = useAgreementsPageContext();
  const { targetType } = useAgreementSelectionContext();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [showModal, setShowModal] = useState(false);
  const [nextSumInsured, setNextSumInsured] = useState(0);

  const { data } = useAgreementsSummaryAlternatives({ ...agreementTargets, minimumSumInsured: undefined, daysCoveredGroup: undefined });
  const relevantAlternative = data?.find(alternative => alternative.daysCovered === agreementTargets.daysCoveredGroup) ?? data?.at(0);
  const currentSumInsuredOptions = relevantAlternative?.minimumSumInsured;
  const sumInsuredTarget = agreementTargets.minimumSumInsured ?? 0;
  const daysCoveredTarget = relevantAlternative?.daysCovered;

  const [duplicateModalState, setDuplicateModalState] = useState<SumInsuredModalData>();

  const canEdit = targetType === TargetType.Standard || targetType === TargetType.VesselTypeSpecific;

  const isDeletingLastAlternative = () => {
    if (!data) return false;
    if (data.length !== 1) return false;
    const alternative = data[0];
    if (alternative.minimumSumInsured && alternative.minimumSumInsured.length <= 2) return true;
    return false;
  };

  const saveModalChanges = async (sum: number) => {
    setErrorMessage(undefined);
    let response;

    if (duplicateModalState?.mode === SumInsuredModalMode.Duplicate) {
      response = await createAgreementAlternative({
        targets: {
          ...agreementTargets,
          minimumSumInsured: duplicateModalState?.sum,
          daysCoveredGroup: daysCoveredTarget
        },
        minimumSumInsuredDestination: sum,
        daysCoveredDestination: relevantAlternative?.daysCovered
      });

      if (!response.error) {
        setAgreementSummaryTarget({ ...agreementSummaryTarget, minimumSumInsured: sum, daysCoveredGroup: daysCoveredTarget, hasMultipleSets: true });
      }
    } else if (duplicateModalState?.mode === SumInsuredModalMode.Edit) {
      const tempCoverage: WarWeb.CoverageSpecification = { minimumSumInsured: sum };
      response = await updateAgreements({
        targets: saveTargets,
        coverage: tempCoverage,
        updateComment: `Changed sum insured value limit on a tab, from ${agreementTargets.minimumSumInsured} to ${sum}`
      });

      if (!response.error) {
        setAgreementSummaryTarget({ ...agreementSummaryTarget, minimumSumInsured: sum });
      }
    } else if (duplicateModalState?.mode === SumInsuredModalMode.Delete) {
      // Shouldn't be necessary to overwrite minimumSumInsured here, just kept as a safety to not delete too much if there's a bug
      response = await deleteAgreementAlternative({ targets: { ...agreementTargets, minimumSumInsured: sum } });

      if (!response.error) {
        setAgreementSummaryTarget({ ...agreementSummaryTarget, minimumSumInsured: 0, hasMultipleSets: !isDeletingLastAlternative() });
      }
    }

    setErrorMessage(response?.error?.response?.data?.title);
    if (!response?.error) {
      mutateAgreement();
    }
  };

  const showSumInsuredAgreement = (sum: number) => {
    const hasUnsavedChanges = anyAgreementChanges(coverage, premium, conditions);
    if (hasUnsavedChanges) {
      setNextSumInsured(sum);
      setShowModal(true);
      return;
    }
    setAgreementSummaryTarget({ ...agreementSummaryTarget, minimumSumInsured: sum });
  };

  useEffect(() => {
    if (agreementSummaryTarget.minimumSumInsured === undefined) {
      setAgreementSummaryTarget({ ...agreementSummaryTarget, minimumSumInsured: 0 });
    }
  }, [agreementSummaryTarget, setAgreementSummaryTarget]);

  return (
    <>
      {showModal && (
        <ChangeTabConfirmationModal
          closeModal={() => setShowModal(false)}
          apply={() => setAgreementSummaryTarget({ ...agreementSummaryTarget, minimumSumInsured: nextSumInsured })}
        />
      )}
      <FlexBox gap="2rem">
        {currentSumInsuredOptions?.map((sum, i) => {
          const isCurrentOptionSelected = sum === sumInsuredTarget;

          return (
            <FlexBox key={sum} alignItems="center" gap=".25rem">
              <SumInsuredText tabIndex={0} role="button" onClick={() => showSumInsuredAgreement(sum)} isSelected={isCurrentOptionSelected}>
                {`Sum Insured ≥ ${formatLargeInteger(sum, 2)}`}
              </SumInsuredText>
              {isCurrentOptionSelected && isAdmin() && (
                <>
                  <ActionButton
                    icon={<Open />}
                    onClick={() => setDuplicateModalState({ sum, mode: SumInsuredModalMode.Duplicate })}
                    disabled={!canEdit}
                    tooltip="DUPLICATE"
                  />
                  {sum === 0 ? null : (
                    <>
                      <ActionButton
                        icon={<Pen />}
                        onClick={() => setDuplicateModalState({ sum, mode: SumInsuredModalMode.Edit })}
                        disabled={!canEdit}
                        tooltip="CHANGE VALUE LIMIT"
                      />
                      <ActionButton
                        icon={<Trash />}
                        onClick={() => setDuplicateModalState({ sum, mode: SumInsuredModalMode.Delete })}
                        disabled={!canEdit}
                        tooltip="DELETE"
                      />
                    </>
                  )}
                </>
              )}
              {/* Show "next" arrow, skip last element */}
              {(i !== currentSumInsuredOptions.length - 1) && (
                <ArrowContainer>
                  <ArrowForward />
                </ArrowContainer>
              )}
            </FlexBox>
          );
        })}
        {
          duplicateModalState !== undefined &&
          <SumInsuredModal data={duplicateModalState} save={saveModalChanges} close={() => setDuplicateModalState(undefined)} />
        }
      </FlexBox>
      {errorMessage && <ErrorContainer>{errorMessage}</ErrorContainer>}
    </>
  );
};
