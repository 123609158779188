import React, { FC, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { theme } from '@/utility/theme';
import { TextField } from '../../FormFields';

const NameGroupStyled = styled.div`
  cursor: pointer;
`;

const Container = styled.span`
  .tooltip {
    color: ${theme.marineBlue};
    max-width: 200px;
    line-height: 1.5em;
    white-space: normal;
    box-shadow: 3px 6px 20px 12px rgba(72,135,177,0.2);
  }
`;

interface InputPopupProps {
  url: string;
  setUrl: (u: string) => void;
}

export const InputPopup: FC<InputPopupProps> = ({ url, setUrl, children }) => {
  const randomID = String(Math.random());
  // const [urlState, setUrlState] = useState(url ?? '');

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setUrl(e.target.value);
  };

  return (
    <Container>
      <NameGroupStyled data-for={randomID} data-tip="null" data-event="click">
        {children}
      </NameGroupStyled>
      <ReactTooltip id={randomID} backgroundColor={theme.marineBlue75} effect="solid" className="tooltip">
        link:
        {' '}
        <TextField
          name="url"
          autoComplete="off"
          onChange={handleInput}
          value={url}
        />
      </ReactTooltip>
    </Container>
  );
};
