import styled from 'styled-components';
import { darken } from 'polished';
import { theme } from '@/utility/theme';
import { HateoasHandler } from '@/components/shared/HateoasHandler';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { Button } from '@/components/shared/Button';
import { WarWeb } from '@/war';
import { useHateoasExecutorContext } from '@/services/HateoasExecutorContext';
import { useSelectedQuote } from '@/components/shared/Bow/useSelectedQuote';
import { Locked } from '@instech/icons';
import { isBroker } from '@/components/routing/roles';
import { useAutoReinsuranceFeature } from '@/services/featureFlagsService';
import { useQuoteContext } from '../../../../shared/BowSidebar/QuoteContext';
import { getLabel, QuoteLabel } from '../quoteLabelMap';
import { QuotesBox } from './QuotesBox';
import { QuoteActions } from '../quoteActionsMap';

const QuotesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${props => props.theme.white};
  align-items: center;
  padding-right: 1rem;
`;

const ScrollContainer = styled.div`
  position: relative;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  margin-right: 2rem;
  width: 100%;
  height: 92px;

  /* width */
  ::-webkit-scrollbar {
    height: 7px;
    margin-bottom: 1px;
  }
  scrollbar-width: thin;

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${props => props.theme.whiteBlue};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${props => darken(0.3, props.theme.whiteBlue)};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.marineBlue};
  }
`;

const Message = styled.div`
  display: flex;
  width: 350px;
  gap: 0.3em;
  color: ${theme.sanMarino};

  svg {
    width: 45px;
  }
`;

interface QuotesBarProps {
  alternatives?: WarWeb.BowQuote[];
  newAlternative: () => void;
  selectAlternative: (id: string) => void;
  confirmStatusChange: (links: WarWeb.HyperMediaLink[], action: string) => void;
  submitChanges: (status: string) => void;
}
export const QuotesBar = ({
  alternatives,
  newAlternative,
  selectAlternative,
  confirmStatusChange,
  submitChanges,
}: QuotesBarProps) => {
  const { loading } = useHateoasExecutorContext();
  const { ids } = useQuoteContext();
  const { quoteId } = ids;

  const { data: isAutoReinsuranceFeatureFlagOn } = useAutoReinsuranceFeature();

  const selectedQuote = useSelectedQuote();
  if (!alternatives || !selectedQuote) return null;

  return (
    <QuotesContainer>
      <ScrollContainer>
        {alternatives?.map((each: WarWeb.BowQuote) => (
          <QuotesBox
            key={each.alternative}
            selected={each.id === quoteId}
            selectAlternative={selectAlternative}
            quote={each}
          />
        ))}
      </ScrollContainer>

      {selectedQuote?.settings.underwriterOverrideInProgress && (
        <Message>
          <Locked />
          {isBroker()
            ? 'This alternative is locked as your Underwriter is making adjustments'
            : 'Alternative is locked for Broker until you submit your changes'}
        </Message>
      )}

      <ButtonGroup>
        <HateoasHandler
          links={selectedQuote?.links}
          action={QuoteLabel.CreateAlternative}
          editVariant={(
            <Button onClick={newAlternative} disabled={loading} background={theme.lightBlue}>
              {getLabel(QuoteLabel.CreateAlternative)}
            </Button>
          )}
        />

        <HateoasHandler
          links={selectedQuote?.links}
          action={QuoteLabel.GenerateQuote}
          editVariant={(
            <Button
              onClick={() => confirmStatusChange(selectedQuote?.links, QuoteLabel.GenerateQuote)}
              disabled={loading}
              background={theme.green}
            >
              {getLabel(QuoteLabel.GenerateQuote)}
            </Button>
          )}
        />

        <HateoasHandler
          links={selectedQuote?.links}
          action={QuoteLabel.SetReinsurance}
          editVariant={(
            <Button
              onClick={() => confirmStatusChange(selectedQuote?.links, QuoteLabel.SetReinsurance)}
              disabled={loading}
              background={isAutoReinsuranceFeatureFlagOn ? theme.lightBlue : theme.green}
            >
              {getLabel(QuoteLabel.SetReinsurance)}
            </Button>
          )}
        />

        <HateoasHandler
          links={selectedQuote?.links}
          action={QuoteLabel.BindCoverage}
          editVariant={(
            <Button
              onClick={() => confirmStatusChange(selectedQuote?.links, QuoteLabel.BindCoverage)}
              disabled={loading}
              background={theme.green}
            >
              {getLabel(QuoteLabel.BindCoverage)}
            </Button>
          )}
        />

        <HateoasHandler
          links={selectedQuote?.links}
          action={QuoteLabel.RequestConfirmation}
          editVariant={(
            <Button
              onClick={() => confirmStatusChange(selectedQuote?.links, QuoteLabel.RequestConfirmation)}
              disabled={loading}
              background={theme.green}
            >
              {getLabel(QuoteLabel.RequestConfirmation)}
            </Button>
          )}
        />

        <HateoasHandler
          links={selectedQuote?.links}
          action={QuoteLabel.FinalizeAlternative}
          editVariant={(
            <Button
              onClick={() => confirmStatusChange(selectedQuote?.links, QuoteLabel.FinalizeAlternative)}
              disabled={loading}
              background={theme.green}
            >
              {getLabel(QuoteLabel.FinalizeAlternative)}
            </Button>
          )}
        />

        <HateoasHandler
          links={selectedQuote?.settings.underwriterOverrideInProgress ? selectedQuote?.links : undefined}
          action={QuoteActions.FinishOverride}
          editVariant={(
            <Button onClick={submitChanges} disabled={loading} background={theme.green}>
              Submit Changes
            </Button>
          )}
        />
      </ButtonGroup>
    </QuotesContainer>
  );
};
