import { NoDataRow } from '@/components/shared/Table/Table';
import { useAgreementTargets } from '@/components/pages/agreements/useAgreementTargets';
import { useBrokerAgreements } from '@/services/agreementsService';
import { useEffect } from 'react';
import { TargetType } from '@/models/TargetType';
import { setAgreementDetailsParams } from '@/utility/sessionStore';
import { ChildrenProps } from '@/models/utils/ChildrenProps';
import { WarWeb } from '@/war';
import { Redirect } from 'react-router';
import { Routes } from '@/components/routing/routes';
import { useAgreementsPageContext } from '../agreements/AgreementsPageContext';
import { useAgreementDetailsContext } from './AgreementDetailsContext';
import { useAgreementSelectionContext } from './AgreementSelectionContext';
import { useAgreementDataContextBroker } from './AgreementDataContextBroker';

export const AgreementDetailsInitializerBroker = ({ children }: ChildrenProps) => {
  const { agreementSummaryTarget } = useAgreementsPageContext();
  const { setAreaIds, setFleetIds, setVesselIds, setVesselTypeIds, setAreaIdsTimeExceptions, setTargetType } = useAgreementSelectionContext();
  const { setTargetsSummary, setMutateAgreement } = useAgreementDetailsContext();
  const { setData } = useAgreementDataContextBroker();
  const { agreementTargets } = useAgreementTargets();

  const hasTargets = agreementTargets.agreementIds?.some(x => x) && agreementSummaryTarget.vesselIds?.some(x => x);

  const agreementTargetsBroker: WarWeb.BrokerAgreementSummaryTargets | undefined =
    hasTargets ? { agreementId: agreementTargets.agreementIds!.at(0), vesselIds: agreementSummaryTarget.vesselIds } : undefined;

  const { data, isValidating: isLoading, error, mutate } = useBrokerAgreements(agreementTargetsBroker);

  const setupData = () => {
    if (data) {
      setTargetType(agreementSummaryTarget.targetType);

      setData(data);

      const dataDeepCopy = structuredClone(data);
      const uniqueFleetIds = [...new Set(dataDeepCopy.targets.vessels.map(x => x.fleetId))];
      const vesselIds = dataDeepCopy.targets.vessels.map(x => x.vesselId);

      setTargetsSummary(dataDeepCopy.targets);
      setAreaIds(dataDeepCopy.targets.areas.map(x => x.areaId));
      setFleetIds(uniqueFleetIds);
      setVesselIds(vesselIds);
      setVesselTypeIds(dataDeepCopy.targets.vesselTypes.map(x => x.vesselTypeId));
      setAreaIdsTimeExceptions(dataDeepCopy.targets.areas.filter(x => x.areaType === 'NonExcluded').map(x => x.areaId));

      // If data was fetched and contains agreementIds, store selected ids in session store
      if (dataDeepCopy.targets.agreementIds.length > 0) {
        setAgreementDetailsParams({
          agreementIds: dataDeepCopy.targets.agreementIds.map(x => x.agreementId),
          targetType: TargetType.Unspecified,
          fleetIds: uniqueFleetIds,
          vesselIds,
          hasMultipleSets: false
        });
      }
    }
  };

  const resetData = () => {
    void mutate();
    setupData();
  };

  useEffect(() => {
    setupData();
    setMutateAgreement(() => resetData);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // No targets in this session, redirect to terms
  if (!agreementTargets.agreementIds?.some(x => x)) return <Redirect to={Routes.terms} />;

  return data && !isLoading ? (
    <>
      {children}
    </>
  ) :
    <NoDataRow loadStatus={{ loading: isLoading, success: data && !error }} />;
};
