export const Routes = {
  base: '/',
  login: '/login',
  logout: '/logout',
  callback: '/callback',
  welcome: '/welcome',
  dashboard: '/dashboard',
  bows: '/bows',
  exposure: '/bows/exposure',
  fleets: '/fleets',

  terms: '/terms',
  termsDetails: '/terms/details',
  termsSpecific: '/terms/specific',
  termsDefault: '/terms/default',
  termsDefaultDetails: '/terms/default/details',
  termsSpecificDetails: '/terms/specific/details',
  termsSpecificCreate: '/terms/specific/create',
  termsKidnapRansom: '/terms/kidnap-ransom',
  termsExcludedAreas: '/terms/excluded-areas',
  termsEditExcludedAreas: '/terms/excluded-areas/edit',

  createbow: '/bows/edit',
  profile: '/profile',
  feedbackAndSupport: '/feedback-and-support',
  announcements: '/announcements',
  termsOfUse: '/terms-of-use',
  noAccess: '/no-access'
};
