export const AgreementTiers = {
  Minimum: 'Global minimum',
  Maximum: 'Global maximum',
  A: 'Standard A',
  B: 'Standard B',
  C: 'Standard C'
};

export const ShorthandTiers = {
  Minimum: 'Minimum',
  Maximum: 'Maximum',
  A: 'Standard A',
  B: 'Standard B',
  C: 'Standard C'
};
