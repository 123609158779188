import { FlexBox } from '@/components/shared/FlexBox';
import { Loader } from '@instech/components';
import { Tooltip } from '@/components/shared/Table/core/Components';
import { BowCountsProps } from '@/services/bowServices';
import { sum } from '@/utility/arrayHelpers';
import { bowStatusToObject } from '@/utility/bowStatus';
import styled, { css } from 'styled-components';
import { TabProps } from '../dashboardTabs';

const CardContainer = styled.div<{ isOpen: boolean }>`
  display: flex;
  width: 250px;
  padding-left: 10px;
  height: 60px;

  ${props => props.isOpen && css`
    height: 70px;
  `};
`;

const StyledFlexBox = styled(FlexBox)`
  height: 100%;
  width: 100%;
`;

const BowCount = styled.div`
  display: flex;
  font-size: 36px;
  font-weight: bold;
`;

const Description = styled.div`
  display: flex;
  font-size: 12px;
`;

const StatusIndicator = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 16px;
`;

export const DashboardCard = ({ tab, activeTab, data }: { tab: TabProps, activeTab?: string, data: BowCountsProps }) => {
  const status = bowStatusToObject(tab.status);
  return (
    <Tooltip id={tab.key} name={tab.actionToResolve} delayShow={800}>

      <CardContainer isOpen={activeTab === tab.key} data-for={tab.key}>
        <StyledFlexBox flexDirection="column" justifyContent="space-between">
          <StyledFlexBox alignItems="center" justifyContent="space-between">

            <BowCount>{!data.isLoading ? sum(data.count) : <Loader size="small" />}</BowCount>
            <StatusIndicator>
              {status?.title === "PendingConfirmation" ? 'Pending' : status?.title}
              {status?.icon}
            </StatusIndicator>
          </StyledFlexBox>

          <Description>{tab.description}</Description>
        </StyledFlexBox>
      </CardContainer>
    </Tooltip>
  );
};
