import { useSelectedQuote } from '@/components/shared/Bow/useSelectedQuote';
import { areaTypeToObject } from '@/utility/areaType';
import { formatDate } from '@/utility/dateCalc';
import { WarWeb } from '@/war';
import { WarningFilled } from '@instech/icons';
import styled from 'styled-components';

const SearchResult = styled.div`
  display: flex;
  width: 80%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  font-size: 14px;
  line-height: 1.3em;

  svg {
    width: 40px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SearchSubContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DestinationName = styled.div`
  font-weight: bold;
  font-size: 16px;
`;

const StyledWarningFilled = styled(WarningFilled)`
  color: ${props => props.theme.status.yellow};
`;

export const renderDestionationsResult = (result: any) => {
  if (!result) return null;
  return (
    <SearchResult>
      <Container>
        {areaTypeToObject(result.type)?.icon}
        <SearchSubContainer>
          <DestinationName>{result.name}</DestinationName>
          {formatDate(result.entry?.local)}
          {' '}
          -
          {' '}
          {formatDate(result.exit?.local)}

        </SearchSubContainer>
      </Container>
      <Container>
        {result.hra}
        {result.hasWarnings && <div><StyledWarningFilled /></div>}
      </Container>
    </SearchResult>
  );
};

export const useDestinationIndex = (id?: number) => {
  const selectedQuote = useSelectedQuote();
  if (!selectedQuote) return {};

  if (!id) return null;

  // eslint-disable-next-line consistent-return
  selectedQuote.coveredAreas.forEach((c, coveredAreaIndex) => {
    if (c.id === id) {
      return {
        coveredArea: coveredAreaIndex
      };
    }
    const destinationIndex = c.itinerary?.findIndex(d => d.id === id);
    if (destinationIndex) {
      return {
        coveredArea: coveredAreaIndex,
        destination: destinationIndex
      };
    }
  });

  return null;
};

export const useDestinationConfiguration = (id?: number) => {
  const selectedQuote = useSelectedQuote();
  if (!selectedQuote) return {};

  if (!id) {
    return {
      configuration: undefined
    };
  }

  const coveredArea = selectedQuote.coveredAreas.find(c => c.id === id);
  if (coveredArea) return coveredArea;

  const destination = selectedQuote.coveredAreas.flatMap(c => c.itinerary)
    .find((d: WarWeb.Destination) => d.id === id);

  if (destination) return destination;

  return {
    configuration: undefined
  };
};
