import { Pen } from '@instech/icons';
import { InformationNotification } from '@instech/components';
import styled from 'styled-components';
import { PageHeader } from '@/components/layout/PageHeader';
import { isAdmin } from '@/components/routing/roles';
import { Routes } from '@/components/routing/routes';
import { Button } from '@/components/shared/Button';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { theme } from '@/utility/theme';
import { AreaColumn } from './AreaColumn';
import { PortColumn } from './PortColumn';
import { RegionColumn } from './RegionColumn';
import { useExcludedAreaPageContext } from '../context/ExcludedAreaPageContext';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AreasContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
`;

const Area = styled.div`
  width: 32%;
`;

const AzureSearchNotification = () => {
  const { hasSaved } = useExcludedAreaPageContext();
  if (!hasSaved) return null;
  return (
    <InformationNotification
      headingText="Changes saved"
      descriptionText="Your changes are saved, but it may take up to 5 minutes for them to be reflected here."
      size="large"
    />
  );
};

export const ExcludedAreasPage = () => (
  <Wrapper>
    <HeaderContainer>
      <PageHeader header="Excluded Areas" breadcrumb={{ label: 'Default settings', to: Routes.termsDefault }} />
      <ButtonGroup>
        {isAdmin() && <Button background={theme.green} icon={<Pen />} to={Routes.termsEditExcludedAreas}>Edit</Button>}
      </ButtonGroup>
    </HeaderContainer>
    <AzureSearchNotification />
    <AreasContainer>
      <Area><RegionColumn /></Area>
      <Area><AreaColumn /></Area>
      <Area><PortColumn /></Area>
    </AreasContainer>
  </Wrapper>
);
