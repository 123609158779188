import { InlineModalProps } from '@/components/modal/InlineModalProps';
import { ModalLayout } from '@/components/modal/ModalLayout';
import { Button, ButtonWithLoader } from '@/components/shared/Button';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { theme } from '@/utility/theme';
import { WarWeb } from '@/war';
import { Close, WarningFilled } from '@instech/icons';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 2rem;
  text-align: center;
`;

const StyledWarning = styled(WarningFilled)`
  path {
    fill: ${props => props.theme.marineBlue};
  }
  height: 60px;
  width: 60px;
  margin: 3rem;
`;

const DeleteHeader = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 2rem;
`;

const DeleteDescription = styled.div`
  margin-bottom: 2rem;
`;

const VesselContainer = styled.span`
  white-space: nowrap; 
`;

interface Props extends Required<InlineModalProps> {
  vessel: WarWeb.MarineMatch;

}
export const SetVesselNotNewConfirmationModal = ({ vessel, apply, closeModal }: Props) => {
  const submitHandler = async () => {
    await apply();
    closeModal();
  };

  const options = {
    size: 'small',
    clickOutsideToClose: true,
    draggable: true
  };

  return (
    <ModalLayout closeModal={closeModal} options={options}>
      <Container>
        <StyledWarning />
        <DeleteHeader>
          Do you want to remove the New flag for the vessel
          {' '}
          <VesselContainer>{vessel.name}</VesselContainer>
        </DeleteHeader>
        <DeleteDescription>
          The New flag will be removed from the vessel and standard agreement will be used.
          It might take up to five minutes before the New flag is removed in the Fleet Overview
        </DeleteDescription>
        <ButtonGroup justify="space-between">
          <Button background={theme.ultraLightGray} onClick={closeModal}>Cancel</Button>
          <ButtonWithLoader background={theme.green} icon={<Close />} onClick={submitHandler}>Remove</ButtonWithLoader>
        </ButtonGroup>
      </Container>
    </ModalLayout>
  );
};
