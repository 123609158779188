import { InlineModalProps } from '@/components/modal/InlineModalProps';
import { ModalLayout } from '@/components/modal/ModalLayout';
import { isInternalUser } from '@/components/routing/roles';
import { useQuoteContext } from '@/components/shared/BowSidebar/QuoteContext';
import { Sidebar } from '@/components/shared/BowSidebar/Sidebar';
import { Box } from '@/components/shared/Box';
import { Button } from '@/components/shared/Button';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { FieldLabel } from '@/components/shared/Form/core/Components';
import { TextField } from '@/components/shared/Form/FormFields';
import { HateoasHandler } from '@/components/shared/HateoasHandler';
import { RemainingValidations } from '@/components/shared/RemainingValidations';
import { Validator } from '@/components/shared/Validator';
import { useQuote } from '@/hooks/useQuote';
import { useBow } from '@/services/bowServices';
import { useHateoasExecutorContext } from '@/services/HateoasExecutorContext';
import { useValidationContext } from '@/services/ValidationContext';
import { isEmpty, pick } from '@/utility/objectHelpers';
import { theme } from '@/utility/theme';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { steps } from '../../create-bow/bowSteps';
import { useQuoteCommentsContext } from '../../create-bow/QuoteCommentsContext';
import { TermsByArea, TermsType } from '../../create-bow/TermsAndConditionsStep';
import { BowActions } from '../details/bowActionsMap';
import { QuoteLabel } from '../details/quoteLabelMap';

const NewQuoteContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 1rem 1rem;
`;

const Wide = styled.div`
  width: 100%;
`;

const Column = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${props => props.gap && css`
    row-gap: 1rem;
  `};

  ${FieldLabel} {
    font-size: 16px;
    margin-bottom: 0.5rem;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
`;

const Content = styled.div`
  padding: 0 1em 1em  2em;
`;

const Header = styled.h2<{ top?: boolean }>` 
  font-size: 16px;
  margin-top: 2rem;
  
  ${props => props.top && css`
    margin-top: 0;
  `};
`;

export const GenerateQuoteModal = ({ closeModal }: InlineModalProps) => {
  const { ids } = useQuoteContext();
  const { bowId, quoteId } = ids;
  const { hateoasExecutor } = useHateoasExecutorContext();
  const { warnings, errors } = useValidationContext();

  const { quoteComments, setQuoteComments } = useQuoteCommentsContext();
  const [hasSaved, setHasSaved] = useState(false);

  const { data: bowData } = useBow(bowId);

  const selectedQuote = useQuote(bowId, quoteId);

  useEffect(() => {
    if (!hasSaved) return;
    if (!errors || isEmpty(pick(errors, ['ClientReference', 'Comments']))) closeModal();
    else setHasSaved(false);
  }, [errors, hasSaved, closeModal]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setQuoteComments({ ...quoteComments, [e.target.name]: e.target.value });
  };

  const save = async () => {
    const request = {
      clientReference: quoteComments?.clientReference === '' ? undefined : quoteComments?.clientReference,
      comment: quoteComments?.comment
    };
    await hateoasExecutor(selectedQuote?.links, QuoteLabel.GenerateQuote, request, 'quote');
    setHasSaved(true);
  };

  const options = {
    title: 'New Quote',
    size: 'large',
    draggable: true
  };

  const warningsPaths = steps.flatMap(u => u.warningsKeys);

  return (
    <ModalLayout options={options} closeModal={closeModal}>
      <NewQuoteContainer>
        <Column gap="4rem">
          <Row>
            {bowData?.status === 'Draft' && (
              <Wide>
                <HateoasHandler
                  links={bowData?.links}
                  action={BowActions.SetClientReference}
                  editVariant={(
                    <Validator keys={['ClientReference']}>
                      <TextField
                        name="clientReference"
                        value={quoteComments.clientReference}
                        onChange={handleInputChange}
                        label="Client reference"
                        placeholder="E.g. &quot;MYBOW123&quot;"
                      />
                    </Validator>
                  )}
                  viewVariant={<p>Non Rate Lead</p>}
                />
              </Wide>
            )}

            <Column gap="2rem">
              {bowData?.status === 'Draft' && (
                <Wide>
                  <Validator keys={['Comment']}>
                    <TextField
                      rows={2}
                      name="comment"
                      value={quoteComments.comment}
                      onChange={handleInputChange}
                      label="Comment"
                      placeholder="What identifies this BOW?"
                    />

                  </Validator>
                </Wide>
              )}
              <ButtonGroup justify="flex-end">
                <Button onClick={closeModal} background={theme.lightGray}>Cancel</Button>
                <Button onClick={save} background={warnings && isInternalUser() ? theme.status.yellow : theme.green}>Save Quote</Button>
              </ButtonGroup>
            </Column>

          </Row>

          <RemainingValidations size="medium" rootElement={selectedQuote} level="warnings" showAll />
          <div>
            <Header top>{`NHC BOW ID: ${bowData?.internalReference}`}</Header>
            <Sidebar compact
              orientation="row"
              warningsPaths={warningsPaths}
              warningsAreRegex
              background={theme.whiteBlue} />
          </div>

          <Box background={theme.whiteBlue} header="Warranties" shaded={false}>
            <Content>
              <TermsByArea type={TermsType.Warranties} terms={selectedQuote?.warranties || []} readOnly />
            </Content>
          </Box>

          <Box background={theme.whiteBlue} header="Subjectivities" shaded={false}>
            <Content>
              <TermsByArea type={TermsType.Subjectivities} terms={selectedQuote?.subjectivities || []} readOnly />
            </Content>
          </Box>

        </Column>
      </NewQuoteContainer>
    </ModalLayout>
  );
};
