import React from 'react';
import {
  Route, Redirect, useLocation
} from 'react-router';
import { childrenPropType } from 'prop-types/custom-prop-types';
import { useAccount } from 'its-js-authentication';
import { Routes } from './routes';
import { useQueryString } from '@/hooks/useQuery';

const redirectState = (pathname, location, search) => ({
  pathname,
  state: { from: location },
  search
});

const LoginRedirect = () => {
  const location = useLocation();
  const search = useQueryString();

  return (<Redirect to={redirectState(Routes.login, location, search)} />);
};

export const ProtectedRoute = ({ children, ...props }) => {
  const { isAuthenticated } = useAccount();

  if (isAuthenticated !== true) {
    return <LoginRedirect />;
  }

  return (
    <Route {...props}>
      {children}
    </Route>
  );
};

ProtectedRoute.propTypes = {
  children: childrenPropType
};
