import { DestinationOption } from '@/components/pages/bows/details/destinationOption';
import { QuoteActions } from '@/components/pages/bows/details/quoteActionsMap';
import { useHateoasExecutorContext } from '@/services/HateoasExecutorContext';
import { useHighRiskAreas } from '@/services/highRiskAreasService';
import { useDestinationConfiguration } from '@/utility/destinationsDropdownHelpers';
import { Globals } from '@/utility/globals';
import { WarWeb } from '@/war';
import { Pen } from '@instech/icons';
import { useState } from 'react';
import styled from 'styled-components';
import { useQuoteContext } from '../../BowSidebar/QuoteContext';
import { Box } from '../../Box';
import { checkAction, HateoasHandler } from '../../HateoasHandler';
import { NoDataRow } from '../../Table/core/Components';
import { Table } from '../../Table/Table';
import { RebateAction } from '../Rebates/RebatesEditorRow';
import { RebatesModal } from '../Rebates/RebatesModal';
import { useSelectedQuote } from '../useSelectedQuote';
import { RebateAreaOption, RebateTableRow } from './RebatesRow';

const Content = styled.div`
  display: flex;
  flex-direction: co lumn;
  
  > div {
    width: 100%;
  }
`;

interface RebatesTableProps {
  destination: DestinationOption;
}
export const RebatesTable = ({ destination }: RebatesTableProps) => {
  const [showRebatesModal, setShowRebatesModal] = useState<boolean>(false);
  const { hateoasExecutor } = useHateoasExecutorContext();

  const { selectedConfiguration, isNoClaimsBonusBased, isMultiDestination } = useQuoteContext();
  const { configuration } = useDestinationConfiguration(destination.id);
  const { data: highRiskAreas } = useHighRiskAreas();

  const selectedQuote = useSelectedQuote();
  const coveredAreas = selectedQuote?.coveredAreas;

  if (!coveredAreas || !selectedQuote) return null;
  if (!(!isMultiDestination || selectedConfiguration === Globals.TotalVoyageId) && !configuration) return null;

  const rebates = !isMultiDestination || selectedConfiguration === Globals.TotalVoyageId ? selectedQuote.rebates : configuration!.rebates;
  const relevantLinks = !isMultiDestination || selectedConfiguration === Globals.TotalVoyageId ? selectedQuote.links : destination.links;

  let rebateAreaOptions: RebateAreaOption[] = [];
  if (isMultiDestination && selectedConfiguration === Globals.TotalVoyageId) {
    const rebatesWithMultipleValues = selectedQuote?.rebates?.filter(r => r.isSelected === null);

    if (rebatesWithMultipleValues && rebatesWithMultipleValues.length > 0) {
      rebateAreaOptions = coveredAreas?.flatMap((item: Partial<WarWeb.CoveredArea>) => {
        let country: RebateAreaOption[] = [];
        if (item.coveredAreaType === 'OceanArea') {
          const areaRebates = item.configuration?.rebates.filter(r => rebatesWithMultipleValues.find(s => s.id === r.id));

          if (areaRebates) {
            country = areaRebates.map(rebate => ({
              rebateId: rebate.id,
              coveredAreaId: item.id,
              type: item.coveredAreaType!.toString(),
              hra: highRiskAreas?.find(hra => hra.id === item.highRiskAreaId)?.name,
              name: item.name || '',
              included: rebate.isSelected
            }));
          }
        }

        const ports: RebateAreaOption[] = item.itinerary?.flatMap((dest: Partial<WarWeb.Destination>) => {
          const destRebates = dest.configuration?.rebates.filter(r => rebatesWithMultipleValues.find(s => s.id === r.id));

          return destRebates?.map(rebate => ({
            rebateId: rebate.id,
            coveredAreaId: dest.id,
            type: dest.port?.isTimeException ? 'TimeException' : 'Port',
            hra: highRiskAreas?.find(hra => hra.id === dest?.port?.highRiskAreaId)?.name,
            name: dest?.port?.name || '',
            included: rebate.isSelected
          })) ?? [];
        }) ?? [];
        return country.concat(ports);
      });
    }
  }

  const updateRebates = async (rebateId: number, action: RebateAction) => {
    const initialRebateValue = 0; // required from backend
    if (action === 'add') await hateoasExecutor(relevantLinks, QuoteActions.AddRebate, { rebateId, value: initialRebateValue });
    else if (selectedConfiguration !== Globals.TotalVoyageId || !isMultiDestination) {
      const links = configuration?.rebates.find(r => r.id === rebateId)?.links;
      await hateoasExecutor(links, QuoteActions.RemoveRebate, {});
    }
  };

  const ncbRebateIndex = rebates?.findIndex((r: any) => r.id === Globals.NoClaimsBonusRebateId);

  const claimsPenaltyFrontendRebate = {
    id: Globals.ClaimsPenaltyMockId,
    name: 'Claims Penalty',
    shortName: 'CP',
    isSelected: true,
    value: !isMultiDestination || selectedConfiguration === Globals.TotalVoyageId
      ? selectedQuote.claimsPenaltyInfo.claimsPenaltyPercent
      : configuration?.claimsPenaltyPercent ?? 0,
    links: [],
    potentialRebate: 0,
    actualRebate: 0,
    netBeforeRebate: 0
  };

  const destinationRebates = [...rebates || []];
  if (!isNoClaimsBonusBased) {
    destinationRebates.splice(ncbRebateIndex < 0 ? 0 : ncbRebateIndex, 0, claimsPenaltyFrontendRebate);
  }

  const containsAnyHraDestinations = coveredAreas.some(c => c.highRiskAreaId !== null)
    || coveredAreas.some(c => c.itinerary.some(i => i.port.highRiskAreaId !== null));

  return (
    <Content>
      <Box header={(containsAnyHraDestinations || checkAction(relevantLinks, QuoteActions.AddRebate)) && 'Rebates'}
        shaded={false}
        action={{
          label: <HateoasHandler
            links={relevantLinks}
            action={QuoteActions.AddRebate}
            editVariant={(
              <>
                <Pen onClick={() => setShowRebatesModal(true)} />
                <RebatesModal
                  isOpen={showRebatesModal}
                  closeModal={() => setShowRebatesModal(false)}
                  destination={destination}
                  includedRebates={rebates?.map(r => r.id)}
                  updateRebates={updateRebates} />
              </>
            )}
          />
        }}>
        <Table columns={3} stretch layout="1fr 3fr 100px">
          {destinationRebates?.map(row => (
            <RebateTableRow
              key={row.id}
              rebate={row}
              rebateAreaOptions={rebateAreaOptions}
            />
          ))}
        </Table>
      </Box>
    </Content>
  );
};
