import { useAgreementTargets } from '@/components/pages/agreements/useAgreementTargets';
import { isUnderwriter } from '@/components/routing/roles';
import { TargetType } from '@/models/TargetType';
import { getDifference } from '@/utility/array';
import { WarWeb } from '@/war';
import { AgreementViewMode, useAgreementDetailsContext } from '../agreementDetails/AgreementDetailsContext';
import { useAgreementSelectionContext } from '../agreementDetails/AgreementSelectionContext';
import { useIsBulkEditing } from './useIsBulkEditing';

export const useAgreementSaveTargets = (): WarWeb.AgreementTargets => {
  const isBulk = useIsBulkEditing();
  const { agreementTargets } = useAgreementTargets();
  const { areaIds, vesselIds, vesselTypeIds, targetType } = useAgreementSelectionContext();
  const { viewMode, targetsSummary } = useAgreementDetailsContext();

  const targetsForCreate = { areaIds, vesselIds, targetType };
  // Need to use agreementIds from targetsSummary, in case we are dealing with more agreements than it seemed like from the overview (e.g. std agreements)
  const bulkOrTradeEditTargets = { ...agreementTargets, agreementIds: targetsSummary.agreementIds.map(x => x.agreementId) };

  if (viewMode === AgreementViewMode.Create) return targetsForCreate;
  if (isBulk || targetType === TargetType.Trade) return bulkOrTradeEditTargets;

  const originalAreas = targetsSummary.areas.map(x => x.areaId) || [];
  const originalVessels = targetsSummary.vessels.map(x => x.vesselId) || [];
  const originalVesselTypes = targetsSummary.vesselTypes.map(x => x.vesselTypeId) || [];

  const areasRemoved = getDifference(originalAreas, areaIds);
  const vesselsRemoved = getDifference(originalVessels, vesselIds);
  const vesselTypesRemoved = getDifference(originalVesselTypes, vesselTypeIds);

  const areas = getDifference(originalAreas, areasRemoved);
  const vessels = getDifference([...new Set(originalVessels)], vesselsRemoved);
  const vesselTypes = getDifference([...new Set(originalVesselTypes)], vesselTypesRemoved);

  const singleEditTargets: WarWeb.AgreementTargets = {
    areaIds: areas,
    vesselIds: vessels,
    vesselTypeIds: vesselTypes,
    fleetId: isUnderwriter() ? targetsSummary.vessels.map(x => x.fleetId)[0] : undefined,
    targetType,
    daysCoveredGroup: agreementTargets.daysCoveredGroup,
    minimumSumInsured: agreementTargets.minimumSumInsured
  };

  return singleEditTargets;
};
