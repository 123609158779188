import { TargetType } from '@/models/TargetType';

export const Globals = {
  KnRInterestNumber: 913,
  TotalVoyageId: -1,
  ClaimsPenaltyMockId: -99,
  ClaimsPenaltyRebateId: -5,
  NoClaimsBonusRebateId: -1,
  KidnapRansomRebateId: -4,
  AgreementsOverviewGroupSize: 100,
  EntryHoursCheck: 12,
  ExitDaysCheck: 10
};

/**
 * Keys for Local and Session storage
 */
export enum GlobalStoreKeys {
  AgreementDetailsParams = 'AgreementDetailsParams',
  TermsConditionsRegionsToggled = 'TermsConditionsRegionsToggled',
  DefaultTermsConditionsRegionsToggled = 'DefaultTermsConditionsRegionsToggled',
  ActiveTab = 'ActiveTab'
}

export type AgreementDetailsParams = {
  areaIds?: number[];
  vesselTypeIds?: number[];
  agreementIds: number[];
  targetType: TargetType;
  fleetIds?: number[];
  daysCoveredGroup?: number;
  minimumSumInsured?: number;
  vesselIds?: number[];
  hasMultipleSets: boolean;
};

export const MinSearchLength = 2;

export const MaxNumberValue = 10000000000; // 10 billion, used for values
export const MaxNumberTimeValue = 10000; // used for hours, days, e.g. coverage fields
