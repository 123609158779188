import { PageHeader } from '@/components/layout/PageHeader';
import { isInternalUser } from '@/components/routing/roles';
import { Routes } from '@/components/routing/routes';
import { Box } from '@/components/shared/Box';
import { FilterBar } from '@/components/shared/FilterBar';
import { FlexBox } from '@/components/shared/FlexBox';
import { MarineObjectSearch } from '@/components/shared/MarineObjectSearch';
import { getHeaders } from '@/components/shared/Table/core/Components';
import { usePageSettingsContext } from '@/components/userSettings/PageSettingsContext';
import { Filters } from '@/models/Filters';
import { SortedHeader } from '@/models/SortOrder';
import { useFleets } from '@/services/fleetsService';
import { theme } from '@/utility/theme';
import { WarWeb } from '@/war';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { SingleValue } from 'react-select';
import styled from 'styled-components';
import { PaginationBar } from '../common/PaginationBar';
import { FilterableTableHeadersProvider } from '../defaultTermsConditions/FilterableTableHeadersContext';
import { tableSectionsExternalUser, tableSectionsInternalUser } from './core/headers';
import { FleetTable } from './FleetTable';

const StyledBox = styled(Box)`
  margin-bottom: 2rem;
`;

const marineType: WarWeb.MarineObjectType = 'Fleet';
const defaultFilters = { pageNumber: 1, pageSize: 20, type: marineType };

export const FleetOverview = () => {
  const { pageSettings, setPageSettings, setPageKey } = usePageSettingsContext();

  useEffect(() => {
    setPageKey('fleetOverview');
    return () => setPageKey();
  }, []);

  const restoredFilters = pageSettings?.fleetOverview?.filters ?? {};
  const [sortedHeader, setSortedHeader] = useState<SortedHeader>();

  const sections = isInternalUser() ? tableSectionsInternalUser() : tableSectionsExternalUser();
  const headers = getHeaders(sections);

  const [filters, setFilters] = useState<Filters>({ ...defaultFilters, ...restoredFilters });

  const { data: fleets, isLoading, error } = useFleets(filters, JSON.stringify(filters));
  const history = useHistory();

  const selectMarineObject = (selection: SingleValue<WarWeb.MarineMatch>) => {
    history.push(`${Routes.fleets}/clients/${selection?.clientId}/fleets/${selection?.fleetId}`);
  };

  useEffect(() => {
    setPageSettings(
      {
        fleetOverview: {
          ...pageSettings?.fleetOverview,
          filters
        }
      }
    );
  }, [filters]);

  const styles = {
    width: '35vw',
  };

  const searchableFields = Object.keys(filters)
    .filter(parameterName => headers.find(header => header.parameterName === parameterName))
    .filter(parameterName => !!filters[parameterName]);

  return (
    <FilterableTableHeadersProvider
      headers={headers}
      columnMetadata={fleets?.columnMetadata}
      initialFilters={defaultFilters}
      filters={filters}
      sortedHeader={sortedHeader}
      setSortedHeader={setSortedHeader}
      setFilters={setFilters}
      isLoading={isLoading}
    >
      <FlexBox justifyContent="space-between">
        <PageHeader header="Fleet overview" />

        <FlexBox alignItems="center">
          <MarineObjectSearch customStyles={styles} onChange={selectMarineObject} />
        </FlexBox>

        <FlexBox alignItems="center">
          <FilterBar searchableFields={searchableFields} />
        </FlexBox>
      </FlexBox>

      <StyledBox highlight={theme.border.blue}>
        <FleetTable
          fleets={fleets}
          sortedHeader={sortedHeader}
          loadStatus={{ loading: isLoading, success: !error }}
        />
      </StyledBox>

      {fleets?.pagingMetadata && (
        <PaginationBar
          currentPage={+filters.pageNumber}
          metadata={fleets.pagingMetadata}
          changePage={newPage => setFilters({ ...filters, pageNumber: newPage })}
        />
      )}
    </FilterableTableHeadersProvider>
  );
};
