import { SlimButton, TextField } from '@instech/components';
import { Plus } from '@instech/icons';
import { FieldLayout } from '@/components/shared/Form/core/Components';
import { WarWeb } from '@/war';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { stringRequired } from '@/utility/validationSchemas';
import { ModalLayout } from '@/components/modal/ModalLayout';
import { TimezoneResult } from '../../types';
import { useEditExcludedAreasContext } from '../../EditExcludedAreasContext/EditExcludedAreasContext';
import { getNewActionItemId } from '../../utils';
import {
  ButtonRow, FormLayout, ModalContentLayout
} from '../../ModalComponents';
import { TimeZoneField } from './TimeZoneField';

interface FormValues {
  pointName: string;
  timezone: TimezoneResult | undefined;
}

const validationSchema = Yup.object().shape({
  pointName: stringRequired(),
  timezone: Yup.object().required('Required')
});

interface Props { closeModal: () => void; }
export const AddEntryExitModal = ({ closeModal }: Props) => {
  const { addEntryExitPoint, state: { areaId, entryAndExitPoints } } = useEditExcludedAreasContext();

  const addPoint = (values: FormValues) => {
    const newPoint = {
      action: 'Add' as WarWeb.ElementAction,
      id: getNewActionItemId(entryAndExitPoints),
      name: values.pointName,
      timezoneId: values.timezone!.area,
      oceanAreaId: areaId
    };

    addEntryExitPoint(newPoint);
    closeModal();
  };

  return (
    <ModalLayout closeModal={closeModal} options={{ title: 'Add Entry / Exit point', size: 'small' }}>
      <Formik
        initialValues={{ pointName: '', timezone: undefined }}
        onSubmit={addPoint}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, dirty }: FormikProps<FormValues>) => (
          <ModalContentLayout>
            <FormLayout>
              <FieldLayout>
                <TextField name="pointName" placeholder="" label="Point Name" maxLength={255} forceMaxLength />
              </FieldLayout>
              <FieldLayout>
                <TimeZoneField />
              </FieldLayout>
            </FormLayout>
            <ButtonRow>
              <SlimButton onClick={closeModal} variant="secondary">CANCEL</SlimButton>
              <SlimButton onClick={() => handleSubmit()} startIcon={<Plus />} disabled={!dirty}>ADD</SlimButton>
            </ButtonRow>
          </ModalContentLayout>
        )}
      </Formik>
    </ModalLayout>
  );
};
