/* eslint-disable max-len */
export const IconMultiAgreement = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3022_56426)">
      <path d="M3 9L3 8.25L4.5 8.25L6.6225 8.25L8.295 8.25C8.55 5.925 9.99 3.96 12 2.9625C12.03 1.7325 13.02 0.75 14.25 0.75C15.495 0.75 16.5 1.755 16.5 3C16.5 4.245 15.495 5.25 14.25 5.25C13.5375 5.25 12.915 4.9125 12.5025 4.395C11.0775 5.1675 10.0575 6.5775 9.8175 8.25L12.1425 8.25C12.45 7.38 13.275 6.75 14.25 6.75C15.495 6.75 16.5 7.755 16.5 9C16.5 10.245 15.495 11.25 14.25 11.25C13.275 11.25 12.45 10.62 12.135 9.75L9.81 9.75C10.05 11.4225 11.0775 12.825 12.5025 13.605C12.915 13.0875 13.5375 12.75 14.25 12.75C15.495 12.75 16.5 13.755 16.5 15C16.5 16.245 15.495 17.25 14.25 17.25C13.02 17.25 12.03 16.2675 12.0075 15.0375C9.9975 14.04 8.5575 12.075 8.3025 9.75L6.63 9.75L4.5 9.75L3 9.75L3 9Z" fill="currentColor" />
      <path d="M5.1075 9.75H7.4325L7.44 8.25H5.115C4.8 7.38 3.975 6.75 3 6.75C1.755 6.75 0.75 7.755 0.75 9C0.75 10.245 1.755 11.25 3 11.25C3.975 11.25 4.8 10.62 5.1075 9.75Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_3022_56426">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>

);
