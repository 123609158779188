import React, { PropsWithChildren, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { lighten } from 'polished';
import { theme } from '@/utility/theme';
import { Loader } from '@instech/components';

const blink = keyframes`
  from {
    transform scale(1);
  }
  to {
    transform scale(.96);
  }
`;

const fade = keyframes`
  from {
    border-color: ${theme.lightGreen50};
  }
  to {
    border-color: ${theme.border.gray};
  }
`;

export interface ButtonProps {
  to?: string,
  href?: string,
  thin?: boolean,
  onClick?: (e: any) => void,
  background?: string,
  border?: string,
  loading?: boolean,
  textWrap?: boolean,
  icon?: React.ReactNode,
  rightIcon?: React.ReactNode,
  disabled?: boolean,
  tabIndex?: number,
  color?: string,
  target?: string,
  shouldActivateByEnter?: boolean,
  isActivated?: boolean
}

export const ButtonShape = styled.div<ButtonProps>`
  font-size: 14px;
  font-weight: bold;
  height: 40px;
  white-space: ${props => props.textWrap ? 'auto' : 'nowrap'};
  letter-spacing: 0.5px;
  padding: ${props => props.thin ? '0.5rem' : '0.5rem 2.5rem'};
  color: ${props => props.color};
  background-color: ${props => props.disabled ? lighten(0.3, props.background || props.theme.white) : props.background};
  border: ${props => props.border};
  border-radius: 2px;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props => props.disabled && css`
    svg {
      filter: invert(20%) brightness(250%) contrast(50%);
    }
  `} 
  
  ${props => props.isActivated && css`  
    animation-iteration-count: 1;
    animation: ${blink} 0.1s;
  `}   
`;

const EnterKeyBorderWrapper = styled.div<{ shouldActivateByEnter?: boolean, isEnterKey?: boolean }>`
  ${props => props.isEnterKey && css`
    position: relative;

    padding: 2px;
    top: -4px;
    left: 4px;
    border: 2px solid ${theme.border.gray};
    border-radius: 5px;

    animation: ${fade} 0.5s infinite alternate;
  `};
`;

export const IconContainer = styled.div<{ thin?: boolean, right?: boolean }>`
  ${props => !props.thin && css`
    margin-${props.right ? 'left' : 'right'}: 1rem;
  `};
  line-height: 0.5;

  svg {
    max-height: 18px;
  }
`;

export const Button = ({
  to = '',
  href = '',
  thin,
  onClick,
  background,
  border,
  children,
  icon,
  rightIcon,
  loading,
  textWrap = false,
  disabled = false,
  tabIndex,
  color,
  target,
  shouldActivateByEnter = false,
  isActivated = false
}: PropsWithChildren<ButtonProps>) => {
  const handleEnterKey = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (e.key === 'Enter') {
      if (onClick) onClick(e);
      e.preventDefault();
    }
  };

  const textColor = color || (disabled ? theme.marineBlue50 : theme.marineBlue);
  return (
    <>
      {to && (
        <Link to={to} onClick={event => disabled && event.preventDefault()} target={target} tabIndex={tabIndex}>
          <EnterKeyBorderWrapper shouldActivateByEnter={shouldActivateByEnter}>
            <ButtonShape
              background={background}
              border={border}
              color={textColor}
              thin={thin}
              textWrap={textWrap}
              disabled={disabled}
              isActivated={isActivated}>
              {icon && <IconContainer thin={thin}>{icon}</IconContainer>}
              {children}
              {rightIcon && <IconContainer right>{rightIcon}</IconContainer>}
            </ButtonShape>
          </EnterKeyBorderWrapper>
        </Link>
      )}
      {!to && (href || onClick) && (
        <a href={href || undefined}
          target={target}
          onClick={!disabled ? onClick : undefined}
          onKeyPress={handleEnterKey}
          tabIndex={tabIndex}>
          <EnterKeyBorderWrapper shouldActivateByEnter={shouldActivateByEnter}>
            <ButtonShape
              background={background}
              border={border}
              color={textColor}
              thin={thin}
              textWrap={textWrap}
              disabled={disabled}
              isActivated={isActivated}>
              {loading ? <Loader size="small" /> : (
                <>
                  {icon && <IconContainer thin={thin}>{icon}</IconContainer>}
                  {children}
                  {rightIcon && <IconContainer right>{rightIcon}</IconContainer>}
                </>
              )}
            </ButtonShape>
          </EnterKeyBorderWrapper>

        </a>
      )}
    </>
  );
};

export const ButtonWithLoader = ({ onClick, children, ...props }: PropsWithChildren<ButtonProps>) => {
  const [loading, setLoading] = useState(false);

  const clickHandler = async (e: any) => {
    if (loading) return;
    setLoading(true);
    if (onClick) await onClick(e);
    setLoading(false);
  };

  return <Button onClick={clickHandler} {...props} loading={loading}>{children}</Button>;
};
