import React, {
  useState, useEffect, PropsWithChildren
} from 'react';
import styled from 'styled-components';
import { useVessels } from '@/services/vesselsService';
import { Box } from '@/components/shared/Box';
import { SessionStore } from '@/utility/sessionStore';
import useDebounce from '@/hooks/useDebouncedValue';
import { WarWeb } from '@/war';
import { HiddenHeader } from '@/components/shared/Table/core/FilterableTableHeaders';
import { SearchBox } from './SearchBox';
import { FleetsDropdown } from './FleetsDropdown';
import {
  Table, TableHeader, TableCell, TableRow, NoDataRow
} from '../../../shared/Table/Table';
import { Chevron } from '../../../shared/Chevron';
import { FleetState, VesselState } from './models';
import { useBowWizardStateContext } from '../BowWizardStateContext';

const OpenIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  color: ${props => props.theme.sanMarino};
`;

const VesselArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-left: 0.5rem;
  padding: 1.3rem;
  max-width: 950px;
`;

const Content = styled.div`
  padding: 1.3rem;
`;

interface VesselRowProps {
  setVessel: (vessel: VesselState) => void;
  vessel: WarWeb.MarineMatch,
}

const VesselRow = ({ setVessel, vessel }: VesselRowProps) => {
  const [rowFocus, setRowFocus] = useState(false);

  const { vesselId } = vessel;
  if (!vesselId) return null;

  return (
    <TableRow
      hasFocus={rowFocus}
      hover
      onClick={() => setVessel({ id: vesselId, name: vessel.name })}
    >
      <TableCell tabIndex={0}
        onFocus={() => setRowFocus(true)}
        onBlur={() => setRowFocus(false)}
        onKeyDown={(e: any) => e.key === 'Enter' ? setVessel({ name: vessel.name, id: vesselId }) : null}>
        {vessel.name}
      </TableCell>
      <TableCell>{vessel.imo}</TableCell>
      <TableCell>{vessel.vesselTypeName}</TableCell>
      <TableCell><OpenIcon><Chevron right thin /></OpenIcon></TableCell>
    </TableRow>
  );
};

const TableArea = styled.div`
  max-height: 55vh;
  overflow-y: auto;
`;

interface TableWrapperProps {
  columns: number;
  sticky?: boolean;
  striped?: boolean;
}

const TableWrapper = ({ children, columns, ...props }: PropsWithChildren<TableWrapperProps>) => (
  <Table columns={columns} layout="3fr 1fr 3fr 1fr" {...props}>
    {children}
  </Table>
);

const VesselHeader = () => (
  <>
    <TableHeader>Vessel</TableHeader>
    <TableHeader>IMO</TableHeader>
    <TableHeader>Type</TableHeader>
    <TableHeader />
  </>
);

const VesselTable = ({ vessels, setVessel }: { vessels: WarWeb.MarineMatch[], setVessel: (vessel: VesselState) => void }) => (
  <>
    <TableWrapper columns={4} sticky>
      <VesselHeader />
    </TableWrapper>

    <TableArea>
      <TableWrapper columns={4} striped>
        <HiddenHeader>
          <VesselHeader />
        </HiddenHeader>

        {vessels && vessels.map((row, i) => (
          <VesselRow
            key={row.id}
            vessel={row}
            setVessel={setVessel}
          />
        ))}
      </TableWrapper>
    </TableArea>
  </>
);

const FilterBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 2rem;

    > div {
      flex: 1 1 50%;
    }
`;

interface VesselPickerProps {
  fleetId: number;
  clientId?: number;
  setVessel: (vessel: VesselState) => void;
  setFleet: (fleet: FleetState) => void;
  backendLoading: boolean;
}

export const VesselPicker = ({ fleetId, clientId, setVessel, setFleet, backendLoading }: VesselPickerProps) => {
  const [vesselsState, setVesselsState] = useState<WarWeb.MarineMatch[]>();
  const { isNonRateLead } = useBowWizardStateContext();

  const selectedRateLeadTypes: WarWeb.RateLeadType[] = isNonRateLead ? ['NonRateLead', 'Mixed'] : ['RateLead', 'Mixed'];
  const defaultFilters = {
    pageNumber: 1,
    pageSize: 100,
    fleetId,
    selectedRateLeadTypes
  };
  const restoredFilters = SessionStore.get('vesselsFilters');
  const [filters, setFilters] = useState(restoredFilters || defaultFilters);
  const debouncedFilters = useDebounce(filters, 500);
  const { data: vessels, isLoading, error } = useVessels(debouncedFilters);

  useEffect(() => {
    if (vessels) setVesselsState(vessels.items);
  }, [vessels]);

  useEffect(() => {
    setFilters({ ...filters, fleetId });
  }, [fleetId]);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, freetext: e.target.value });
  };

  return (
    <VesselArea>
      <Box>
        <Content>
          <FilterBar>
            <FleetsDropdown
              label="Fleet"
              isNonRateLead={isNonRateLead}
              currentFleet={fleetId}
              currentClient={clientId}
              setFleet={fleet => setFleet({ id: fleet.fleetId, clientId: fleet.clientId, name: fleet.name })}
            />
            <SearchBox
              label="Filter By"
              handleInput={handleInput}
              placeholder="Vessel name, IMO number, or Vessel Type"
            />
          </FilterBar>

          {!(isLoading || backendLoading) && vesselsState
            ? <VesselTable vessels={vesselsState} setVessel={setVessel} />
            : <NoDataRow loadStatus={{ loading: isLoading || backendLoading, success: !error }} />}

        </Content>
      </Box>
    </VesselArea>
  );
};
