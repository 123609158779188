import { AgreementDetailsParams, GlobalStoreKeys } from './globals';

const set = (key: string, data: any) => sessionStorage.setItem(key, JSON.stringify(data));
const get = (key: string) => {
  const item = sessionStorage.getItem(key);
  if (item) return JSON.parse(item);
  return null;
};

export const SessionStore = {
  set,
  get
};

export const getAgreementDetailsParams = (): AgreementDetailsParams =>
  JSON.parse(SessionStore.get(GlobalStoreKeys.AgreementDetailsParams));

export const setAgreementDetailsParams = (params: AgreementDetailsParams) =>
  SessionStore.set(GlobalStoreKeys.AgreementDetailsParams, JSON.stringify(params));

export const getActiveTabName = () => {
  const activeTab: string = SessionStore.get(GlobalStoreKeys.ActiveTab);
  return activeTab === '' ? undefined : activeTab;
};

export const setActiveTabName = (tabName: string) => SessionStore.set(GlobalStoreKeys.ActiveTab, tabName);

export const clearActiveTabName = () => SessionStore.set(GlobalStoreKeys.ActiveTab, '');
