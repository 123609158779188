import { WarWeb } from '@/war';
import {
  createContext, PropsWithChildren, useContext
} from 'react';

const ChangesContext = createContext<ChangesProviderProps>({});

interface ChangesProviderProps {
  changes?: Partial<WarWeb.Bow>;
}
export const ChangesProvider = ({ changes, children }: PropsWithChildren<ChangesProviderProps>) => (
  <ChangesContext.Provider value={{ changes }}>
    {children}
  </ChangesContext.Provider>
);

export const useChangesContext = () => useContext(ChangesContext);
