import { TableSection } from '@/components/shared/Table/core/Components';

export const getTableSectionsBroker = (isClaimsPenaltyBased?: boolean, isName?: boolean) => {
  const nameColumn = {
      title: 'Name',
      format: 'text',
      propertyName: 'AgreementName',
      parameterName: 'SelectedAgreementNames'
  };

  const titleColumn = { title: '', key: 1 };

  const commonColumns = [
      {
          title: 'Covered Areas',
          format: 'text',
          propertyName: 'CoveredAreas',
          parameterName: 'SelectedCoveredAreas'
      },
      {
          title: 'Vessel',
          format: 'text',
          propertyName: 'Vessels',
          parameterName: 'SelectedVessels'
      },
      {
          title: 'Vessel Type',
          format: 'text',
          propertyName: 'VesselTypes',
          parameterName: 'SelectedVesselTypes'
      }
  ];

  const columns = isName ? [titleColumn, nameColumn, ...commonColumns] : [titleColumn, ...commonColumns];

  const restItems = [
      {
          name: 'Rates',
          subheader: '% - Gross',
          columns: [
              {
                  title: 'H&M',
                  format: 'decimal',
                  type: 'Number',
                  propertyName: isClaimsPenaltyBased ? 'HmRateCp' : 'HmRateNcb',
                  parameterName: isClaimsPenaltyBased ? 'HmRateCp' : 'HmRateNcb',
              },
              {
                  title: 'IV',
                  format: 'decimal',
                  type: 'Number',
                  propertyName: isClaimsPenaltyBased ? 'TloRateCp' : 'TloRateNcb',
                  parameterName: isClaimsPenaltyBased ? 'TloRateCp' : 'TloRateNcb',
              },
              {
                  title: 'LOH',
                  format: 'decimal',
                  type: 'Number',
                  propertyName: isClaimsPenaltyBased ? 'LohRateCp' : 'LohRateNcb',
                  parameterName: isClaimsPenaltyBased ? 'LohRateCp' : 'LohRateNcb',
              },
          ]
      },
      {
          name: 'Bonuses & Rebates',
          subheader: '%',
          columns: [
              {
                  title: isClaimsPenaltyBased ? 'CP' : 'NCB',
                  format: 'decimal',
                  type: 'Number',
                  propertyName: isClaimsPenaltyBased ? 'ClaimsPenalty' : 'NcbRebate',
                  parameterName: isClaimsPenaltyBased ? 'ClaimsPenalty' : 'NcbRebate'
              },
              {
                  title: 'AGR',
                  format: 'decimal',
                  type: 'Number',
                  propertyName: 'AgrRebate',
                  parameterName: 'AgrRebate'
              },
              {
                  title: 'RCR',
                  format: 'decimal',
                  type: 'Number',
                  propertyName: 'RcrRebate',
                  parameterName: 'RcrRebate'
              },
              {
                  title: 'K&R',
                  format: 'decimal',
                  type: 'Number',
                  propertyName: 'Knr5Rebate',
                  parameterName: 'Knr5Rebate'
              },
          ]
      },   
      {
          name: 'K&R',
          subheader: 'USD - Gross',
          columns: [
              {
                  title: 'ST',
                  format: 'decimal',
                  type: 'Number',
                  propertyName: 'GrossSingleTransit',
                  parameterName: 'SingleTransitMax'
              },
              {
                  title: 'Incl. AG',
                  format: 'decimal',
                  type: 'Number',
                  propertyName: 'ArmedGuardsIncluded',
                  parameterName: 'SingleTransitArmedGuardsMax'
              }
          ]
      },
      {
          name: 'General',
          columns: [
              {
                  title: 'H&M net',
                  format: 'decimal',
                  type: 'Number',
                  propertyName: 'NetHmRate',
                  parameterName: 'NetHmRate',
                  dropdownAlign: 'right'
              },
              {
                  title: 'Quote Valid (hrs)',
                  format: 'wide-int',
                  type: 'Number',
                  propertyName: 'QuoteValidHours',
                  parameterName: 'QuoteValidHours',
                  dropdownAlign: 'right'
              },
              {
                  title: 'BOW Period',
                  format: 'wide-int',
                  type: 'Number',
                  propertyName: 'BreachDays',
                  parameterName: 'BreachDays',
                  dropdownAlign: 'right'
              },
              {
                  title: 'K&R Area',
                  format: 'wide-int',
                  propertyName: 'HighRiskArea',
                  parameterName: 'SelectedHighRiskAreas',
                  dropdownAlign: 'right'
              }
          ]
      }
  ];

  return [
      {
          columns
      },
      ...restItems
  ];
};

export const tableSectionsBrokerWithoutName : TableSection[] =
[
  {
    columns: [
      { title: '', key: 1 },
      { title: 'Covered Areas',
        format: 'text',
        propertyName: 'CoveredAreas',
        parameterName: 'SelectedCoveredAreas' },
      { title: 'Vessel',
        format: 'text',
        propertyName: 'Vessels',
        parameterName: 'SelectedVessels' },
      { title: 'Vessel Type',
        format: 'text',
        propertyName: 'VesselTypes',
        parameterName: 'SelectedVesselTypes' }
    ]
  },
  {
    name: 'Rates',
    subheader: '% - Gross',
    columns: [
      { title: 'H&M',
        format: 'decimal',
        type: 'Number',
        propertyName: 'HmRateNcb',
        parameterName: 'HmRateNcb' },
      { title: 'IV',
        format: 'decimal',
        type: 'Number',
        propertyName: 'TloRateNcb',
        parameterName: 'TloRateNcb' },
      { title: 'LOH',
        format: 'decimal',
        type: 'Number',
        propertyName: 'LohRateNcb',
        parameterName: 'LohRateNcb' },
    ]
  },
  {
    name: 'Bonuses & Rebates',
    subheader: '%',
    columns: [
      { title: 'NCB',
        format: 'decimal',
        type: 'Number',
        propertyName: 'NcbRebate',
        parameterName: 'NcbRebate' },
      { title: 'AGR',
        format: 'decimal',
        type: 'Number',
        propertyName: 'AgrRebate',
        parameterName: 'AgrRebate' },
      { title: 'RCR',
        format: 'decimal',
        type: 'Number',
        propertyName: 'RcrRebate',
        parameterName: 'RcrRebate' },
      { title: 'K&R',
        format: 'decimal',
        type: 'Number',
        propertyName: 'Knr5Rebate',
        parameterName: 'Knr5Rebate' },
    ]
  },
  {
    name: 'K&R',
    subheader: 'USD - Gross',
    columns: [
      { title: 'ST',
        format: 'decimal',
        type: 'Number',
        propertyName: 'GrossSingleTransit',
        parameterName: 'SingleTransitMax' },
      { title: 'Incl. AG',
        format: 'decimal',
        type: 'Number',
        propertyName: 'ArmedGuardsIncluded',
        parameterName: 'SingleTransitArmedGuardsMax' }
    ]
  },
  {
    name: 'General',
    columns: [
      { title: 'H&M net',
        format: 'decimal',
        type: 'Number',
        propertyName: 'NetHmRate',
        parameterName: 'NetHmRate',
        dropdownAlign: 'right' },
      { title: 'Quote Valid (hrs)',
        format: 'wide-int',
        type: 'Number',
        propertyName: 'QuoteValidHours',
        parameterName: 'QuoteValidHours',
        dropdownAlign: 'right' },
      { title: 'BOW Period',
        format: 'wide-int',
        type: 'Number',
        propertyName: 'BreachDays',
        parameterName: 'BreachDays',
        dropdownAlign: 'right' },
      { title: 'K&R Area',
        format: 'wide-int',
        propertyName: 'HighRiskArea',
        parameterName: 'SelectedHighRiskAreas',
        dropdownAlign: 'right' }
    ]
  }
];

export const tableSectionsBroker : TableSection[] =
[
  {
    columns: [
      { title: '', key: 1 },
      { title: 'Covered Areas',
        format: 'text',
        propertyName: 'CoveredAreas',
        parameterName: 'SelectedCoveredAreas' },
      { title: 'Name',
        format: 'text',
        propertyName: 'AgreementName',
        parameterName: 'SelectedAgreementNames' },
      { title: 'Vessel',
        format: 'text',
        propertyName: 'Vessels',
        parameterName: 'SelectedVessels' },
      { title: 'Vessel Type',
        format: 'text',
        propertyName: 'VesselTypes',
        parameterName: 'SelectedVesselTypes' }
    ]
  },
  {
    name: 'Rates',
    subheader: '% - Gross',
    columns: [
      { title: 'H&M',
        format: 'decimal',
        type: 'Number',
        propertyName: 'HmRateNcb',
        parameterName: 'HmRateNcb' },
      { title: 'IV',
        format: 'decimal',
        type: 'Number',
        propertyName: 'TloRateNcb',
        parameterName: 'TloRateNcb' },
      { title: 'LOH',
        format: 'decimal',
        type: 'Number',
        propertyName: 'LohRateNcb',
        parameterName: 'LohRateNcb' },
    ]
  },
  {
    name: 'Bonuses & Rebates',
    subheader: '%',
    columns: [
      { title: 'NCB',
        format: 'decimal',
        type: 'Number',
        propertyName: 'NcbRebate',
        parameterName: 'NcbRebate' },
      { title: 'AGR',
        format: 'decimal',
        type: 'Number',
        propertyName: 'AgrRebate',
        parameterName: 'AgrRebate' },
      { title: 'RCR',
        format: 'decimal',
        type: 'Number',
        propertyName: 'RcrRebate',
        parameterName: 'RcrRebate' },
      { title: 'K&R',
        format: 'decimal',
        type: 'Number',
        propertyName: 'Knr5Rebate',
        parameterName: 'Knr5Rebate' },
    ]
  },
  {
    name: 'K&R',
    subheader: 'USD - Gross',
    columns: [
      { title: 'ST',
        format: 'decimal',
        type: 'Number',
        propertyName: 'GrossSingleTransit',
        parameterName: 'SingleTransitMax' },
      { title: 'Incl. AG',
        format: 'decimal',
        type: 'Number',
        propertyName: 'ArmedGuardsIncluded',
        parameterName: 'SingleTransitArmedGuardsMax' }
    ]
  },
  {
    name: 'General',
    columns: [
      { title: 'H&M net',
        format: 'decimal',
        type: 'Number',
        propertyName: 'NetHmRate',
        parameterName: 'NetHmRate',
        dropdownAlign: 'right' },
      { title: 'Quote Valid (hrs)',
        format: 'wide-int',
        type: 'Number',
        propertyName: 'QuoteValidHours',
        parameterName: 'QuoteValidHours',
        dropdownAlign: 'right' },
      { title: 'BOW Period',
        format: 'wide-int',
        type: 'Number',
        propertyName: 'BreachDays',
        parameterName: 'BreachDays',
        dropdownAlign: 'right' },
      { title: 'K&R Area',
        format: 'wide-int',
        propertyName: 'HighRiskArea',
        parameterName: 'SelectedHighRiskAreas',
        dropdownAlign: 'right' }
    ]
  }
];

export const tableSectionsBrokerClaimsPenaltyWithoutName : TableSection[] =
[
  {
    columns: [
      { title: '', key: 1 },
      { title: 'Covered Areas',
        format: 'text',
        propertyName: 'CoveredAreas',
        parameterName: 'SelectedCoveredAreas' },
      { title: 'Vessel',
        format: 'text',
        propertyName: 'Vessels',
        parameterName: 'SelectedVessels' },
      { title: 'Vessel Type',
        format: 'text',
        propertyName: 'VesselTypes',
        parameterName: 'SelectedVesselTypes' }
    ]
  },
  {
    name: 'Rates',
    subheader: '% - Gross',
    columns: [
      { title: 'H&M',
        format: 'decimal',
        type: 'Number',
        propertyName: 'HmRateCp',
        parameterName: 'HmRateCp' },
      { title: 'IV',
        format: 'decimal',
        type: 'Number',
        propertyName: 'TloRateCp',
        parameterName: 'TloRateCp' },
      { title: 'LOH',
        format: 'decimal',
        type: 'Number',
        propertyName: 'LohRateCp',
        parameterName: 'LohRateCp' },
    ]
  },
  {
    name: 'Bonuses & Rebates',
    subheader: '%',
    columns: [
      { title: 'CP',
        format: 'decimal',
        type: 'Number',
        propertyName: 'ClaimsPenalty',
        parameterName: 'ClaimsPenalty' },
      { title: 'AGR',
        format: 'decimal',
        type: 'Number',
        propertyName: 'AgrRebate',
        parameterName: 'AgrRebate' },
      { title: 'RCR',
        format: 'decimal',
        type: 'Number',
        propertyName: 'RcrRebate',
        parameterName: 'RcrRebate' },
      { title: 'K&R',
        format: 'decimal',
        type: 'Number',
        propertyName: 'Knr5Rebate',
        parameterName: 'Knr5Rebate' },
    ]
  },
  {
    name: 'K&R',
    subheader: 'USD - Gross',
    columns: [
      { title: 'ST',
        format: 'decimal',
        type: 'Number',
        propertyName: 'GrossSingleTransit',
        parameterName: 'SingleTransitMax' },
      { title: 'Incl. AG',
        format: 'decimal',
        type: 'Number',
        propertyName: 'ArmedGuardsIncluded',
        parameterName: 'SingleTransitArmedGuardsMax' }
    ]
  },
  {
    name: 'General',
    columns: [
      { title: 'H&M net',
        format: 'decimal',
        type: 'Number',
        propertyName: 'NetHmRate',
        parameterName: 'NetHmRate',
        dropdownAlign: 'right' },
      { title: 'Quote Valid (hrs)',
        format: 'wide-int',
        type: 'Number',
        propertyName: 'QuoteValidHours',
        parameterName: 'QuoteValidHours',
        dropdownAlign: 'right' },
      { title: 'BOW Period',
        format: 'wide-int',
        type: 'Number',
        propertyName: 'BreachDays',
        parameterName: 'BreachDays',
        dropdownAlign: 'right' },
      { title: 'K&R Area',
        format: 'wide-int',
        propertyName: 'HighRiskArea',
        parameterName: 'SelectedHighRiskAreas',
        dropdownAlign: 'right' }
    ]
  }
];

export const tableSectionsBrokerClaimsPenalty : TableSection[] =
[
  {
    columns: [
      { title: '', key: 1 },
      { title: 'Covered Areas',
        format: 'text',
        propertyName: 'CoveredAreas',
        parameterName: 'SelectedCoveredAreas' },
      { title: 'Name',
        format: 'text',
        propertyName: 'AgreementName',
        parameterName: 'SelectedAgreementNames' },
      { title: 'Vessel',
        format: 'text',
        propertyName: 'Vessels',
        parameterName: 'SelectedVessels' },
      { title: 'Vessel Type',
        format: 'text',
        propertyName: 'VesselTypes',
        parameterName: 'SelectedVesselTypes' }
    ]
  },
  {
    name: 'Rates',
    subheader: '% - Gross',
    columns: [
      { title: 'H&M',
        format: 'decimal',
        type: 'Number',
        propertyName: 'HmRateCp',
        parameterName: 'HmRateCp' },
      { title: 'IV',
        format: 'decimal',
        type: 'Number',
        propertyName: 'TloRateCp',
        parameterName: 'TloRateCp' },
      { title: 'LOH',
        format: 'decimal',
        type: 'Number',
        propertyName: 'LohRateCp',
        parameterName: 'LohRateCp' },
    ]
  },
  {
    name: 'Bonuses & Rebates',
    subheader: '%',
    columns: [
      { title: 'CP',
        format: 'decimal',
        type: 'Number',
        propertyName: 'ClaimsPenalty',
        parameterName: 'ClaimsPenalty' },
      { title: 'AGR',
        format: 'decimal',
        type: 'Number',
        propertyName: 'AgrRebate',
        parameterName: 'AgrRebate' },
      { title: 'RCR',
        format: 'decimal',
        type: 'Number',
        propertyName: 'RcrRebate',
        parameterName: 'RcrRebate' },
      { title: 'K&R',
        format: 'decimal',
        type: 'Number',
        propertyName: 'Knr5Rebate',
        parameterName: 'Knr5Rebate' },
    ]
  },
  {
    name: 'K&R',
    subheader: 'USD - Gross',
    columns: [
      { title: 'ST',
        format: 'decimal',
        type: 'Number',
        propertyName: 'GrossSingleTransit',
        parameterName: 'SingleTransitMax' },
      { title: 'Incl. AG',
        format: 'decimal',
        type: 'Number',
        propertyName: 'ArmedGuardsIncluded',
        parameterName: 'SingleTransitArmedGuardsMax' }
    ]
  },
  {
    name: 'General',
    columns: [
      { title: 'H&M net',
        format: 'decimal',
        type: 'Number',
        propertyName: 'NetHmRate',
        parameterName: 'NetHmRate',
        dropdownAlign: 'right' },
      { title: 'Quote Valid (hrs)',
        format: 'wide-int',
        type: 'Number',
        propertyName: 'QuoteValidHours',
        parameterName: 'QuoteValidHours',
        dropdownAlign: 'right' },
      { title: 'BOW Period',
        format: 'wide-int',
        type: 'Number',
        propertyName: 'BreachDays',
        parameterName: 'BreachDays',
        dropdownAlign: 'right' },
      { title: 'K&R Area',
        format: 'wide-int',
        propertyName: 'HighRiskArea',
        parameterName: 'SelectedHighRiskAreas',
        dropdownAlign: 'right' }
    ]
  }
];

