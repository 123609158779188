import { Globals } from '@/utility/globals';
import { WarWeb } from '@/war';
import moment from 'moment';

export const baseFilters: Record<string, WarWeb.BowSearchParameters[]> = {
  green: [{
    selectedStatus: [
      'Quoted'
    ],
    // min: now < entry => entry > now
    overallAlertEntry: {
      min: moment().utc(),
      inclusion: 3
    },
    orderBy: 'LastUpdated desc',
    pageSize: 15
  },
  {
    selectedStatus: [
      'Confirmed',
    ],
    // min: now < exit => exit > now
    overallAlertExit: {
      min: moment().utc(),
      inclusion: 3
    },
    orderBy: 'LastUpdated desc',
    pageSize: 15
  }],
  blue: [{
    selectedStatus: [
      'PendingConfirmation'
    ],
    orderBy: 'LastUpdated desc',
    pageSize: 15
  }],
  red: [{
    selectedStatus: [
      'Confirmed',
    ],
    overallAlertExit: {
      max: moment().utc(),
      inclusion: 3
    },
    orderBy: 'LastUpdated desc',
    pageSize: 15
  }],
  yellow: [{
    selectedStatus: [
      'Quoted'
    ],
    overallAlertEntry: {
      max: moment().utc().add(Globals.EntryHoursCheck, 'hour').toDate(),
      inclusion: 3
    },
    orderBy: 'LastUpdated desc',
    pageSize: 15
  }],
  grey: [{
    selectedStatus: [
      'Draft'
    ],
    // entry < now + 12h
    overallAlertEntry: {
      // max: entry passed, or not more than 12h in the future
      max: moment().utc().add(Globals.EntryHoursCheck, 'hour').toDate(),
      inclusion: 3
    },
    orderBy: 'LastUpdated desc',
    pageSize: 15
  }]
};
