export enum QuoteLabel {
  CreateAlternative = 'CreateAlternative',
  GenerateQuote = 'GenerateQuote',
  BindCoverage = 'BindCoverage',
  RequestConfirmation = 'RequestConfirmation',
  SetReinsurance = 'SetReinsurance',
  FinalizeAlternative = 'FinalizeAlternative',
  CancelAlternative = 'CancelAlternative',
}

interface LabelMap {
  key: QuoteLabel;
  label: string;
}
export const quoteLabels: LabelMap[] = [
  { key: QuoteLabel.CreateAlternative, label: 'New Alternative' },
  { key: QuoteLabel.GenerateQuote, label: 'Generate Quote' },
  { key: QuoteLabel.BindCoverage, label: 'Bind Coverage' },
  { key: QuoteLabel.RequestConfirmation, label: 'Bind Coverage' },
  { key: QuoteLabel.SetReinsurance, label: 'Set Reinsurance' },
  { key: QuoteLabel.FinalizeAlternative, label: 'Finalize' },
  { key: QuoteLabel.CancelAlternative, label: 'Cancel' },
];

export const getLabel = (key: QuoteLabel) => quoteLabels.find((n) => n.key === key)?.label;
