import { useSWREndpoint } from '@/hooks/useSWREndpoint';
import { WarWeb } from '@/war';
import { AcceptHeaders, convertTargetsToKey } from './config';
import { postData } from './utility/simpleFetch';

export const basepath = 'vessels';

const vesselsFetcher = async (targets?: WarWeb.MarineSearchParameters) => {
  // TODO: Send SearchResult accept header through this custom fetcher
  const response = await postData<WarWeb.SearchResult<WarWeb.MarineMatch>>(basepath, targets);
  return response.data?.data;
};

export const useVessels = (params?: WarWeb.MarineSearchParameters) =>
  useSWREndpoint<WarWeb.SearchResult<WarWeb.MarineMatch>>(params ?
    convertTargetsToKey(basepath, params) : null, AcceptHeaders.SearchResult, () => vesselsFetcher(params));

export const setNotNew = async (vesselId: number) => postData(`${basepath}/setnotnew/${vesselId}`);

// export const useVessel = (vesselId?: number) => useSWREndpoint<WarWeb.Vessel>(vesselId ? `${basepath}/${vesselId}` : null);
