import { Pane } from '@instech/components';
import { PortsTable } from './PortsTable';
import { useEditExcludedAreasContext } from '../EditExcludedAreasContext/EditExcludedAreasContext';
import { isNewSelection } from './utils';

export const PortsEditor = () => {
  const { state: { selectedPorts } } = useEditExcludedAreasContext();
  const portsTargeted = selectedPorts?.filter(p => isNewSelection(p))?.length ?? 0;

  return (
    <Pane title={`Targeted ports (${portsTargeted})`} color="green">
      <PortsTable />
    </Pane>
  );
};
