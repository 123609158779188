import 'react-app-polyfill/stable';

import { createRoot } from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { SWRConfig } from 'swr';
import { AuthenticationProvider } from 'its-js-authentication';
import { ErrorBoundary } from './components/error/ErrorBoundary';
import { App } from './App';
import { unregister } from './registerServiceWorker';
import { ModalHandler } from './components/modal/Modal';
import { theme } from './utility/theme';
import { getJsonAsync } from './services/client';
import { userManager } from './utility/userManager';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'normalize.css';
import './custom.css';
import { PageSettingsProvider } from './components/userSettings/PageSettingsContext';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootContainer = document.getElementById('root');
const root = createRoot(rootContainer);

const swrConfig = {
  revalidateOnFocus: false,
  errorRetryCount: 3,
  fetcher: (...args) => getJsonAsync(...args)
};

root.render(
  <ErrorBoundary>
    <AuthenticationProvider userManager={userManager}>
      <BrowserRouter basename={baseUrl}>
        <ThemeProvider theme={theme}>
          <ErrorBoundary>
            <SWRConfig value={swrConfig}>
              <PageSettingsProvider>
                <ModalHandler>
                  <App />
                </ModalHandler>
              </PageSettingsProvider>
            </SWRConfig>
          </ErrorBoundary>
        </ThemeProvider>
      </BrowserRouter>
    </AuthenticationProvider>
  </ErrorBoundary>
);

unregister();
