import {
  AxiosError, AxiosRequestConfig, AxiosResponse, Method
} from 'axios';
import client from '../client';

export interface ValidationError {
  Message: string;
  Details: Record<string, string[]>;
}
export interface RequestResult<TResult> {
  data?: AxiosResponse<TResult>;
  error?: AxiosError<any>;
}

const MAX_RETRY_ATTEMPTS = 3;

export const simpleRequest = async <T>(url: string, method: Method, params?: any, config?: AxiosRequestConfig, retry = false) => {
  let data;
  let error;

  let attempts = 1;
  let done = false;
  while (!done) {
    if (retry && attempts > 1) error = undefined; // clear any previous error
    try {
      // eslint-disable-next-line no-await-in-loop
      data = await client.request<T>({ url, data: params, method, ...config });
    } catch (e) {
      error = e as AxiosError<any>;
    }
    done = !retry || error?.response?.status !== 412 || attempts >= MAX_RETRY_ATTEMPTS;
    attempts += 1;
  }
  return { data, error } as RequestResult<T>;
};

export const postData = async <T>(path: string, params?: any, config?: AxiosRequestConfig) => simpleRequest<T>(path, 'post', params, config);
export const putData = async <T>(path: string, params?: any) => simpleRequest<T>(path, 'put', params);
export const getData = async <T>(path: string) => simpleRequest<T>(path, 'get');
export const deleteData = async <T>(path: string, params?: any) => simpleRequest<T>(path, 'delete', params);

export const postFetcher = async <T>(url: string | null, targets?: any, config?: AxiosRequestConfig) => {
  if (url == null) return null;
  const response = await postData<T>(url, targets, config);
  return response.data?.data;
};
