import { Range, useFilterableTableHeadersContext } from '@/components/pages/defaultTermsConditions/FilterableTableHeadersContext';
import { DataType } from '@/models/Datatype';
import { SortOrder } from '@/models/SortOrder';
import { useValidationContext } from '@/services/ValidationContext';
import { theme } from '@/utility/theme';
import { Close, SortDown, SortUp } from '@instech/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import styled from 'styled-components';
import { Button } from '../../Button';
import { TextField } from '../../Form/fields/TextField';
import { Validator } from '../../Validator';
import {
  DropdownButtonGroup, DropdownContainer, DropdownElement, Icon
} from './SearchFilterDropdown';

const DropdownRangeInput = styled(DropdownElement)`
  display: flex;
  gap: 1em;
  padding: 1em;
  background-color: ${props => props.theme.whiteBlue};
  border-radius: 2px;
  width: 100%;

  span {
    font-size: 12px;
  }

  input {
    background-color: #fff;
    color: ${props => props.theme.marineBlue};
  }
`;

interface RangeFilterDropdownProps {
  title?: string;
  parameterName: string;
  propertyName: string;
  type?: DataType;
  close: () => void;
  display: boolean;
  right: boolean;
}
export const RangeFilterDropdown = (
  {
    title,
    parameterName,
    propertyName,
    type,
    close,
    display,
    right = false
  }: RangeFilterDropdownProps
) => {
  const { filters, setSortedHeader, changeFilter } = useFilterableTableHeadersContext();
  const { setWarnings } = useValidationContext();

  const serialize = (key: string, value: string | null) => {
    if (type === 'Date') {
      if (key === 'min') {
        return moment(value).utc().hours(0).subtract('hours', 12)
          .toISOString();
      }
      return moment(value).utc().hours(0).add('hours', 12)
        .toISOString();
    }
    return value;
  };

  const deserialize = (filter: any) => {
    if (!filters) return null;
    if (type === 'Date') {
      return {
        min: moment(filters[parameterName].min).utc().add('day', 1).hours(0)
          .toISOString(),
        max: moment(filters[parameterName].max).utc().hours(0)
          .toISOString()
      };
    }
    return filter;
  };

  const [range, setRange] = useState<Range>(
    filters && filters[parameterName] ? deserialize(filters[parameterName]) : { min: '', max: '', inclusion: 'BothInclusive' }
  );

  const [focusedInputState, setFocusedInputState] = useState<FocusedInputShape | null>(null);

  useEffect(() => {
    setRange(filters && filters[parameterName] ? deserialize(filters[parameterName]) : { min: '', max: '', inclusion: 'BothInclusive' });
  }, [filters]);

  const changeSort = (sortOrder: SortOrder) => {
    setSortedHeader({ propertyName, sortOrder });
    changeFilter!('orderBy', `${propertyName} ${sortOrder}`);
    close();
  };

  const apply = () => {
    if (range && range.min && range.max && parseFloat(range.min) > parseFloat(range.max)) {
      setWarnings({
        range: ['From value can not be greater than To value']
      });
      return;
    }
    setWarnings(undefined);

    changeFilter!(parameterName, {
      ...range,
      min: range.min === '' || !range.min ? undefined : serialize('min', range.min),
      max: range.max === '' || !range.max ? undefined : serialize('max', range.max)
    });
    close();
  };

  const reset = () => {
    changeFilter!(parameterName, []);
  };

  const cancel = () => {
    close();
  };

  const resetEditAndCancel = () => {
    setRange((filters && filters[parameterName]) ? filters[parameterName] : { ...range, min: null, max: null });
    close();
  };

  const [wasDateRangeOpen, setWasDateRangeOpen] = useState<boolean>(false);
  useEffect(() => {
    // this "trick" is required to be able to retain a state/boolean for use in handle (outside) click, below
    setTimeout(() => {
      setWasDateRangeOpen(!!focusedInputState);
    }, 0);
  }, [focusedInputState]);

  const handleClick = (e: React.MouseEvent) => {
    if (wasDateRangeOpen) return;
    close();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, positive: () => void, negative: () => void) => {
    if (e.key === 'Enter') positive();
    else if (e.key === 'Escape') negative();
  };

  const handleInput = (e: any) => {
    const target = e.target.name;
    setRange({ ...range, [target]: e.target.value ?? '' });
  };

  useEffect(() => {
    const elem = document.getElementById(`rangeMin_${title}`);
    if (display && elem) elem.focus();
  }, [display, title]);

  const handleDatesChange = (startDate: moment.Moment | null, endDate: moment.Moment | null) => {
    setRange({
      ...range,
      min: startDate?.toISOString() ?? null,
      max: endDate?.toISOString() ?? null
    });
  };

  const startDateVal = range?.min ? moment(range.min).utc() : null;
  const endDateVal = range?.max ? moment(range.max).utc() : null;

  const filterApplied = filters && filters[parameterName];
  const valuesSet = range.min || range.max;

  return (
    <DropdownContainer clickOutside={handleClick} isVisible={display} right={right}>
      <div>
        <DropdownElement tabIndex={0}
          onKeyDown={e => handleKeyDown(e, () => changeSort(SortOrder.Ascending), cancel)}
          onClick={() => wasDateRangeOpen ? undefined : changeSort(SortOrder.Ascending)}
          first>
          <Icon>
            <SortDown />
          </Icon>
          Sort Increasing
        </DropdownElement>
        <DropdownElement tabIndex={0}
          onKeyDown={e => handleKeyDown(e, () => changeSort(SortOrder.Descending), cancel)}
          onClick={() => wasDateRangeOpen ? undefined : changeSort(SortOrder.Descending)}>
          <Icon>
            <SortUp />
          </Icon>
          Sort Decreasing
        </DropdownElement>

        <DropdownElement tabIndex={0}
          onKeyDown={e => handleKeyDown(e, reset, cancel)}
          onClick={() => wasDateRangeOpen ? undefined : reset()}
          disabled={!filterApplied && !valuesSet}>
          <Icon>
            <Close />
          </Icon>
          {`Clear filter for ${title}`}
        </DropdownElement>
      </div>

      <Validator keys={['range']}>

        <DropdownRangeInput>

          {type === 'Date' ? (
            <DateRangePicker
              startDate={startDateVal}
              startDateId="1"
              endDate={endDateVal}
              endDateId="2"
              onDatesChange={({ startDate, endDate }) => handleDatesChange(startDate, endDate)} // PropTypes.func.isRequired,
              focusedInput={focusedInputState} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={focusedInput => setFocusedInputState(focusedInput)} // PropTypes.func.isRequired,
              keepOpenOnDateSelect
              isOutsideRange={d => false}
              minimumNights={0}
              small
              displayFormat="DD.MM.YYYY"
            />
          ) : (
            <>
              <TextField
                name="min"
                id={`rangeMin_${title}`}
                type="number"
                label="From"
                value={range.min ?? ''}
                onKeyDown={(e: any) => handleKeyDown(e, apply, resetEditAndCancel)}
                placeholder="Min"
                onChange={handleInput}
                tabIndex={0}
              />

              <TextField
                name="max"
                id={`rangeMax_${title}`}
                type="number"
                label="To"
                value={range.max ?? ''}
                onKeyDown={(e: any) => handleKeyDown(e, apply, resetEditAndCancel)}
                placeholder="Max"
                onChange={handleInput}
                tabIndex={0}
              />
            </>
          )}
        </DropdownRangeInput>
      </Validator>

      <DropdownButtonGroup>
        <Button background={theme.lightGray} onClick={cancel}>Cancel</Button>
        <Button background={theme.green} disabled={!range.min && !range.max} onClick={apply}>Filter</Button>
      </DropdownButtonGroup>

    </DropdownContainer>
  );
};
