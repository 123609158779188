import { theme } from '@/utility/theme';
import React, { FC } from 'react';

interface MiddotProps {
  width?: number;
  height?: number;
  radius?: number;
  fill?: string;
}

export const Middot: FC<MiddotProps> = ({
  width = 10,
  height = 10,
  radius = 2,
  fill = theme.marineBlue,
}) => {
  const viewWidth = width * radius;
  const viewHeight = height * radius;

  return (
    <svg
      width={viewWidth}
      height={viewHeight}
      viewBox={`0 0 ${viewWidth} ${viewHeight}`}
      version="1.1"
    >
      <g fill={fill}>
        <circle
          cx={radius + width - radius}
          cy={radius + width - radius}
          r={radius}
        />
      </g>
    </svg>
  );
};
