import { isInternalUser } from '@/components/routing/roles';
import { getDelimiters, getHeaders } from '@/components/shared/Table/core/Components';
import {
  FilterableTableHeaders,
  NoDataRow, Table
} from '@/components/shared/Table/Table';
import { SortedHeader } from '@/models/SortOrder';
import { basepathFleets } from '@/services/fleetsService';
import { WarWeb } from '@/war';
import { useState } from 'react';
import { tableSectionsExternalUser, tableSectionsInternalUser } from './core/headers';
import { FleetTableRow } from './FleetTableRow';

interface FleetTableProps {
  fleets?: WarWeb.SearchResult<WarWeb.MarineMatch>;
  sortedHeader?: SortedHeader;
  loadStatus: {
    loading: boolean,
    success: boolean
  };
}
export const FleetTable = ({ fleets, loadStatus, sortedHeader }: FleetTableProps) => {
  const [focusedId, setFocusedId] = useState<number>();

  const sections = isInternalUser() ? tableSectionsInternalUser() : tableSectionsExternalUser();
  const headers = getHeaders(sections);

  return (
    <Table columns={headers.length} striped>
      {fleets && (
        <FilterableTableHeaders
          endpoint={basepathFleets}
          delimiters={getDelimiters(sections)}
          wide
        />
      )}
      {!loadStatus.loading && fleets ? fleets?.items.map(fleet => (
        <FleetTableRow
          key={fleet.id}
          focusedId={focusedId}
          setFocusedId={setFocusedId}
          fleet={fleet} />
      ))
        : <NoDataRow loadStatus={loadStatus} />}
    </Table>
  );
};
