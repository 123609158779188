import React, { FC } from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div<{ selected: boolean, disabled: boolean, orientation: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${props => !props.disabled && css`
    cursor: pointer;
  `};
  border-radius: 5px;
  background-color: ${props => props.selected ? props.theme.lightGreen50 : props.theme.ultraLightGray};
  min-height: 28px;

  ${props => props.orientation === 'left' && css`
    padding-left: 0.7rem;
    padding-right: 1.3rem;
  `};
  ${props => props.orientation === 'middle' && css`
    padding: 0 1rem;
  `};
  ${props => props.orientation === 'right' && css`
    padding-left: 1.3rem;
    padding-right: 0.7rem;
  `};
  max-height: 1rem;
  margin-right: 0.7em;
  margin-bottom: 5px;
`;

const Label = styled.div<{ disabled: boolean }>`
  color: ${props => !props.disabled ? props.theme.marineBlue : props.theme.mediumGray};
  font-size: 14px;
  white-space: nowrap;
  font-weight: 500;
`;

const IconContainer = styled.div<{ orientation: string, disabled: boolean, iconHeight?: number }>`
  line-height: 0.5;
  padding: 0;
  padding-${props => props.orientation === 'left' ? 'right' : 'left'}: 0.5rem;
  flex-align: flex-end;

  svg {
    ${props => props.iconHeight && css`
      height: ${props.iconHeight}px;
    `};
    ${props => props.disabled && css`
      fill-opacity: 0.25;
    `};

  }
`;

interface FilterButtonProps {
  label: string | React.ReactElement;
  toggle: () => void;
  selected?: boolean;
  applicable?: boolean;
  disabled?: boolean;
  iconHeight?: number;
  icon?: React.ReactNode;
  leftIcon?: React.ReactNode;
  color?: string;
  tabIndex?: number;
}
export const FilterButton: FC<FilterButtonProps> = ({
  label,
  toggle,
  selected = false,
  applicable = true,
  disabled = false,
  color,
  iconHeight,
  icon,
  leftIcon,
  tabIndex
}) => {
  const inactive = !(selected || applicable);
  const noIcon = (!leftIcon && !icon);
  let orientation = leftIcon ? 'left' : 'right';
  if (noIcon) orientation = 'middle';

  return (
    <Container
      tabIndex={tabIndex}
      selected={selected}
      disabled={inactive || disabled}
      onClick={() => !inactive && !disabled && toggle()}
      onKeyDown={e => e.key === 'Enter' && !inactive && !disabled && toggle()}
      orientation={orientation}>
      {leftIcon && (
        <IconContainer iconHeight={iconHeight} disabled={inactive} orientation="left" color={color}>
          {leftIcon}
        </IconContainer>
      )}
      <Label disabled={inactive}>{label}</Label>
      {icon && (
        <IconContainer iconHeight={iconHeight} disabled={inactive || disabled} orientation="right">
          {icon}
        </IconContainer>
      )}
    </Container>
  );
};
