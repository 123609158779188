import { useHateoasExecutorContext } from '@/services/HateoasExecutorContext';
import { formatLargeInteger } from '@/utility/formatter';
import { Insify, WarWeb } from '@/war';
import { Load } from '@instech/icons';
import styled from 'styled-components';
import {
  Table, TableCell, TableRow
} from '../../Table/Table';
import { Validator } from '../../Validator';
import { useSelectedQuote } from '../useSelectedQuote';
import { ItemHeader, ItemSubheader } from './RebatesRow';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Setting = styled.span`
  white-space: nowrap;
  display: inline-block;
  padding-right: 0.25em;
`;

const LoadingAnimation = styled(Load)`
  position: relative;
  top: 2rem;
  height: 40px;
`;

interface KnrArea {
  type: string;
  name: string;
  coveredAreaIndex: number;
  destinationIndex?: number;
  configuration: WarWeb.DestinationConfiguration;
  fieldPath: string;
}

export const KidnapRansomVoyageSummary = () => {
  const { loading, whatsLoading } = useHateoasExecutorContext();

  const selectedQuote = useSelectedQuote();

  const listKnrSettings = (details: Insify.War.Bows.Core.Models.KnrDetails.KnrDetails) => {
    const { selectedOptions } = details;

    if (!selectedOptions) {
      if (details.externalSumInsured?.value) return [`≥ ${formatLargeInteger(details.externalSumInsured.value, 2)} USD`];
      return null;
    }

    const settings = [];
    if (selectedOptions.voyageType) settings.push(selectedOptions.voyageType);
    if (selectedOptions.armedGuardsIncluded) settings.push('Armed Guards');
    if (selectedOptions.lossOfHireIncluded) {
      settings.push(`Loss of Hire${selectedOptions.lossOfHireDays ? ` ${selectedOptions.lossOfHireDays} days` : ''}`);
    }
    return settings;
  };

  const allDestinations = selectedQuote?.coveredAreas.flatMap((ca, coveredAreaIndex) => {
    const country: KnrArea[] = [{
      name: ca.name,
      coveredAreaIndex,
      type: ca.coveredAreaType.toString(),
      configuration: ca.configuration,
      fieldPath: `/^coveredAreas\\[${coveredAreaIndex}\\]\\.Configuration\\.Knr/`
    }];

    const ports = ca.itinerary?.map((dest, destinationIndex) => {
      const port: KnrArea = {
        name: dest.port.name,
        coveredAreaIndex,
        destinationIndex,
        type: 'Port',
        configuration: dest.configuration,
        fieldPath: `/^coveredAreas\\[${coveredAreaIndex}\\]\\.Itinerary\\[${destinationIndex}\\]\\.Configuration\\.Knr/`
      };

      return port;
    });
    return country.concat(ports);
  });

  return (
    <Container>

      {loading && whatsLoading === 'kidnapRansom' ? <LoadingAnimation /> : (
        <Table>
          {allDestinations?.map(dest => {
            const config = dest.configuration?.knr;
            if (!config || dest.type === 'Country') return null;
            if (config.selectedOptions || config.knrProvider === 'External') {
              return (
                <TableRow key={dest.configuration.destinationId}>
                  <TableCell textWrap>
                    <ItemHeader>
                      <div>{dest.name}</div>
                      <Validator keys={[dest.fieldPath]} popup />
                    </ItemHeader>
                    <ItemSubheader>
                      {listKnrSettings(dest.configuration.knr)?.map((setting, i, arr) => (
                        <Setting key={`knrconfig-setting-${setting}`}>
                          {`${setting}${arr && i < arr.length - 1 ? ', ' : ''}`}
                        </Setting>
                      ))}
                    </ItemSubheader>
                  </TableCell>
                </TableRow>
              );
            }
            return null;
          })}
        </Table>
      )}
    </Container>
  );
};
