import { TableSection } from '@/components/shared/Table/core/Components';

export const tableSections: TableSection[] = [
  {
    name: 'Policy',
    columns: [
      { title: 'Voyage' },
      { title: 'HRA' },
      { title: 'Armed guards' },
      { title: 'Loss of hire' },
      { title: 'LOH days' },
    ]
  },
  {
    name: 'Vessels',
    columns: [
      { title: 'Name' },
      { title: 'IMO' },
      { title: 'Type' },
    ]
  },
  {
    name: 'Premium',
    columns: [
      { title: 'Cost', subtitle: 'USD' },
      { title: 'LOH rate', subtitle: '%' }
    ]
  },
];
