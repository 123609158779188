import { Box } from '@/components/shared/Box';
import { Chevron } from '@/components/shared/Chevron';
import {
  NoDataRow, renderTableHeaders, Table, TableCell, TableRow
} from '@/components/shared/Table/Table';
import { useRegions } from '@/services/areasService';
import { theme } from '@/utility/theme';
import { WarWeb } from '@/war';
import { World2 } from '@instech/icons';
import { useMemo } from 'react';
import { useExcludedAreaPageContext } from '../context/ExcludedAreaPageContext';

const tableHeaders = ['Name', ''];

const RegionRow = ({ region }: { region: WarWeb.Area }) => {
  const { regionId, setRegionId, setAreaId } = useExcludedAreaPageContext();

  const onRowClickHandler = () => {
    setRegionId(region.id);
    setAreaId();
  };

  return (
    <TableRow backgroundColor={regionId === region.id ? theme.lightGreen50 : undefined} onClick={onRowClickHandler}>
      <TableCell>{region.name}</TableCell>
      <TableCell right><Chevron right /></TableCell>
    </TableRow>
  );
};

export const RegionColumn = () => {
  const { data: regions, error, isLoading } = useRegions();
  const sortedRegions = useMemo(() => regions?.sort((x, y) => (x.name > y.name) ? 1 : -1), [regions]);

  return (
    <Box icon={<World2 />} header="Regions">
      <Table columns={tableHeaders.length} striped>
        {renderTableHeaders(tableHeaders)}
        {sortedRegions ?
          sortedRegions.map(region => <RegionRow key={region.id} region={region} />) :
          <NoDataRow loadStatus={{ loading: isLoading, success: !error }} />}
      </Table>
    </Box>
  );
};
