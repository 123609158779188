import { lighten } from 'polished';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { theme } from '@/utility/theme';
import {
  FieldLayout, FieldLabel, EmptyLabel, ErrorMessage
} from '../core/Components';

interface ToggleFieldProps {
  leftLabel?: boolean;
  error?: boolean | string;
  disabled?: boolean;
  backgroundColor?: string;
}
const ToggleField = styled.div<ToggleFieldProps>`

  & label {
    display: flex;
    align-items: center;
    line-height: 21px;
    padding-right: 5px;
  }

  & input[type="checkbox"] {
    position: relative;
    display: flex;
    align-items: center;
    width: 40px;
    height: 20px;
    padding-left: ${props => props.leftLabel ? '10px' : '0'};
    box-sizing: content-box;
    appearance: none;
    padding: 2px;
    margin: 10px;
    line-height: 21px;
    cursor: pointer;
    
    &:focus {
      outline: 1;
    }

    &::before {
      content: '';
      display: inline-block;
      left: 0px;
      width: 40px;
      height: 20px;
      background: ${props => props.theme.lightGray};
      border: 1px solid ${props => props.theme.border.gray};
      border-radius: 20px;
      box-shadow: 0 2px 5px 0 rgba(0,0,0,0.05);
      box-sizing: border-box;

      transition: all 0.20s ease-out;
    }
    &:checked::before {
        border-color: ${props => props.disabled ?
    lighten(0.1, props.backgroundColor ?? props.theme.marineBlue)
    : props.backgroundColor ?? props.theme.marineBlue};

        background: ${props => props.disabled ?
    lighten(0.1, props.backgroundColor ?? props.theme.marineBlue)
    : props.backgroundColor ?? props.theme.marineBlue};
      }
      
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 5px;
      bottom: 4px;
      border: 8px solid ${props => props.theme.white};
      border-radius: 7px;

      transition: all 0.20s ease-out;
    }

    &:checked::after {
      left: 24px;
    }
  }
`;

interface ToggleProps {
  name: string;
  label?: string;
  emptyLabel?: boolean;
  leftLabel?: string;
  rightLabel?: string;
  hideErrors?: boolean;
  isError?: boolean | string;
  isTouched?: boolean | string;
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  backgroundColor?: string;
}

export const Toggle: FC<ToggleProps> = ({
  name,
  label,
  emptyLabel,
  leftLabel,
  rightLabel,
  hideErrors,
  disabled = false,
  isTouched = false,
  isError = false,
  backgroundColor,
  ...fieldProps
}) => (
  <FieldLayout>
    {label && !emptyLabel && <FieldLabel as="label" htmlFor={name}>{label}</FieldLabel>}
    {!label && emptyLabel && <EmptyLabel />}
    <ToggleField disabled={disabled} backgroundColor={backgroundColor} error={isTouched && isError}>
      <label htmlFor={name}>
        {leftLabel}
        <input id={name} name={name} disabled={disabled} type="checkbox" {...fieldProps} />
        {rightLabel}
      </label>
    </ToggleField>
    {!hideErrors && (
      <ErrorMessage>
        {isError && isTouched ? isError : ''}
      </ErrorMessage>
    )}
  </FieldLayout>
);
