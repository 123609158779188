import { useSWREndpoint } from '@/hooks/useSWREndpoint';
import { WarWeb } from '@/war';
import { AcceptHeaders, convertTargetsToKey } from './config';
import { postData } from './utility/simpleFetch';

export const basepath = 'ports';

const portsFetcher = async (targets?: WarWeb.AreaSearchParameters) => {
  // TODO: Send SearchResult accept header here
  const response = await postData<WarWeb.SearchResult<WarWeb.AreaSearchParameters>>(basepath, targets);
  return response.data?.data;
};

export const usePorts = (params?: WarWeb.AreaSearchParameters) =>
  useSWREndpoint<WarWeb.SearchResult<WarWeb.AreaMatch>>(params ?
    convertTargetsToKey(basepath, params) : null, AcceptHeaders.SearchResult, () => portsFetcher(params));

export const getPorts = (params?: WarWeb.AreaSearchParameters) => postData<WarWeb.SearchResult<WarWeb.AreaMatch>>(basepath, params);
