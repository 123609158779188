import { isBroker, isInternalUser } from '@/components/routing/roles';
import { NoDataRow } from '@/components/shared/Table/core/Components';
import { useHateoasExecutorContext } from '@/services/HateoasExecutorContext';
import { NetworkStatusLoading } from '@/services/NetworkStatusLoading';
import { useValidationContext } from '@/services/ValidationContext';
import { concatNullsafe } from '@/utility/arrayHelpers';
import { renderDestionationsResult } from '@/utility/destinationsDropdownHelpers';
import { Globals } from '@/utility/globals';
import { theme } from '@/utility/theme';
import { WarWeb } from '@/war';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DestinationOption } from '../../../pages/bows/details/destinationOption';
import { QuoteActions } from '../../../pages/bows/details/quoteActionsMap';
import { useQuoteContext } from '../../BowSidebar/QuoteContext';
import { Box } from '../../Box';
import { Button, ButtonWithLoader } from '../../Button';
import { ButtonGroup } from '../../ButtonGroup';
import { SelectDropdown } from '../../Form/fields/SelectDropdown';
import { checkAction, HateoasHandler } from '../../HateoasHandler';
import { Middot } from '../../Middot';
import { useSelectedQuote } from '../useSelectedQuote';
import { CalculationsTable } from './CalculationsTable';

const Content = styled.div`
  border-radius: 2px;
  padding: 1rem 1.5rem 1.5rem 1.5rem;
`;

const ButtonContainer = styled.div`
  margin-bottom: 1rem;
`;

interface CalculationsBoxProps {
  loadStatus: NetworkStatusLoading;
}
export const CalculationsBox = ({ loadStatus }: CalculationsBoxProps) => {
  const { selectedConfiguration, setSelectedConfiguration, isMultiDestination } = useQuoteContext();
  const { hateoasExecutor } = useHateoasExecutorContext();
  const { warnings, errors } = useValidationContext();
  const [showDetails, setShowDetails] = useState(false);

  const selectedQuote = useSelectedQuote();
  const coveredAreas = selectedQuote?.coveredAreas;
  const calculations = selectedQuote?.calculations;

  const [calculationsData, setCalculationsData] = useState<WarWeb.Calculation[] | undefined>(calculations);

  const [showTaxes, setShowTaxes] = useState(false);

  useEffect(() => {
    if (calculations && calculations.length > 0 && calculations.some(x => x.taxRate)) {
      setShowTaxes(true);
    }
  }, [selectedQuote]);

  const totalVoyageNode: DestinationOption | undefined = coveredAreas && {
    id: Globals.TotalVoyageId,
    type: 'Voyage',
    name: 'Total Voyage',
    entry: coveredAreas[0]?.entry,
    exit: coveredAreas[coveredAreas.length - 1]?.exit,
    isValid: true
  };

  const destinationsOptions: DestinationOption[] | undefined = coveredAreas?.flatMap(item => {
    const country: DestinationOption[] = [{
      id: item.id,
      type: item.coveredAreaType.toString(),
      name: item.name,
      entry: item.entry,
      exit: item.exit,
      isValid: item.coveredAreaType === 'OceanArea'
    }];

    const ports = item.itinerary.flatMap(dest => ({
      id: dest.id,
      areaId: item.id,
      type: dest.port.isTimeException ? 'TimeException' : 'Port',
      name: dest.port.name,
      entry: dest.entry,
      exit: dest.exit,
      isValid: !dest.port.isTimeException
    }));
    return country.concat(ports);
  });
  if (totalVoyageNode) destinationsOptions?.unshift(totalVoyageNode);

  const selectedDestination = !isMultiDestination || isBroker() ? totalVoyageNode : destinationsOptions?.find(c => c.id === selectedConfiguration);

  const setConfiguration = (item: any) => {
    setSelectedConfiguration!(item.id);
  };

  useEffect(() => {
    if (!selectedConfiguration && destinationsOptions && destinationsOptions.length > 0) {
      setSelectedConfiguration!(destinationsOptions[0]?.id);
    }
  }, []);

  useEffect(() => {
    if (!coveredAreas || !coveredAreas[0]) return;
    const item = isBroker() ? totalVoyageNode : destinationsOptions?.find(d => d.id === selectedConfiguration);
    if (!item || !item.isValid) return;
    if (item.type === 'Voyage' || !isMultiDestination) {
      setCalculationsData(calculations);
      return;
    }

    // if admin has chosen a destination (port), we have temp frontend workaround.
    // TODO: Replace by actual calculations per destination from backend

    const destination = item.type === 'OceanArea' ? coveredAreas.find(area => area.id === item.id)
      : coveredAreas.find(area => area.id === item.areaId)?.itinerary.find(dest => dest.id === item.id);

    if (!destination) return;

    const destinationCalculations = calculations?.map(c => {
      const config = destination.configuration?.interests.find(i => i.interestNumber === c.interestNumber);
      const premiums = destination?.configuration?.premiums || [];
      if (!config) {
        const grossPremium = premiums?.grossPremium?.find(p => p.currency === c.currency)?.value || 0;
        const grossPremiumCp = premiums?.grossPremiumCp?.find(p => p.currency === c.currency)?.value || 0;
        return {
          ...c,
          grossPremium: c.rowType === 'Sum' ? grossPremium : c.grossPremium,
          grossPremiumCp: c.rowType === 'Sum' ? grossPremiumCp : c.grossPremiumCp
        };
      }
      return {
        ...c,
        rebates: [],
        grossRate: config.rate,
        grossRateCp: config.rateCp,
        grossPremium: config.calculatedGrossPremium,
        grossPremiumCp: config.grossPremiumCp,
        links: config.links
      };
    });

    setCalculationsData(destinationCalculations);
  }, [selectedConfiguration, calculations]);

  if (!coveredAreas) return null;

  const boxHeader = (
    <>
      Additional Premium
      {isBroker() && (
        <>
          <Middot />
          Total Voyage
        </>
      )}
    </>
  );

  const taxHandler = async () => {
    if (showTaxes) {
      const removeTaxRequest: WarWeb.RemoveTaxRequest = {};
      await hateoasExecutor(selectedQuote.links, QuoteActions.RemoveTax, removeTaxRequest, 'calculations');
      setShowTaxes(false);
    } else {
      setShowTaxes(true);
    }
  };

  const hasTotalVoyageSelected = selectedDestination?.id === totalVoyageNode?.id;

  return (
    <Box header={boxHeader}>

      {isInternalUser() && isMultiDestination && (
        <SelectDropdown
          results={destinationsOptions}
          callback={setConfiguration}
          selectedItem={destinationsOptions?.find(c => c.id === selectedConfiguration)}
          renderResult={renderDestionationsResult}
          keyField="id"
        />
      )}
      <Content>

        <ButtonContainer>
          <ButtonGroup justify="flex-end">
            <Button background={theme.lightBlue} onClick={() => setShowDetails(!showDetails)}>{showDetails ? 'Hide Calculation' : 'Show Calculation'}</Button>

            {!showDetails && hasTotalVoyageSelected && showTaxes &&
              !checkAction(selectedQuote.links, QuoteActions.RemoveTax) &&
              checkAction(calculations?.flatMap(x => x.links), QuoteActions.SetTax) &&
              <Button background={theme.lightBlue} onClick={() => setShowTaxes(false)}>Remove Tax</Button>}

            {!showDetails && hasTotalVoyageSelected && (showTaxes ? (
              <HateoasHandler
                links={selectedQuote.links}
                action={QuoteActions.RemoveTax}
                editVariant={<ButtonWithLoader background={theme.lightBlue} onClick={taxHandler}>Remove Tax</ButtonWithLoader>} />
            ) : (
              <HateoasHandler
                links={calculations?.flatMap(x => x.links)}
                action={QuoteActions.SetTax}
                editVariant={<Button background={theme.green} onClick={taxHandler}>Add tax</Button>} />
            ))}
          </ButtonGroup>
        </ButtonContainer>

        {selectedDestination && selectedDestination.isValid ? (
          <CalculationsTable
            calculations={calculationsData}
            calculationsType={selectedDestination.type}
            showTaxes={showTaxes}
            transposed={showDetails}
          />
        )
          : (
            <NoDataRow
              loadStatus={loadStatus}
              header={errors || warnings ? 'Calculations pending' : 'Nothing to see here'}
              message={errors || warnings ? 'Please correct remaining warnings' : 'Not relevant for chosen destination'}
            />
          )}

      </Content>
    </Box>
  );
};
