import PropTypes from 'prop-types';
import { EditorState } from 'draft-js';

// Allows one or more children
export const childrenPropType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node
]);

// Must have multiple children, useful when using array methods on children prop
export const childrenStrictPropType = PropTypes.arrayOf(PropTypes.node);

// Draft.js editor state
export const editorStatePropType = PropTypes.instanceOf(EditorState);

// For RichTextForm images
export const imageStateShape = {
  description: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string
};

// For RichTextForm images
export const imageStatePropType = PropTypes.arrayOf(
  PropTypes.shape(imageStateShape)
);

// breach of warranty (BOW) props
export const bowPropType = PropTypes.shape({
  id: PropTypes.string,
  internalId: PropTypes.number,
  externalId: PropTypes.string,
  vessel: PropTypes.string,
  vesselType: PropTypes.string,
  area: PropTypes.string,
  subArea: PropTypes.string,
  entry: PropTypes.string,
  exit: PropTypes.string,
  noClaimBonus: PropTypes.number,
  status: PropTypes.string,
  krRebate: PropTypes.number,
  krEnabled: PropTypes.bool,
  agRebate: PropTypes.number,
  grossLessBonuses: PropTypes.number,
  grossLessBonusesCurrency: PropTypes.string,
  days: PropTypes.number,
});

export const fleetPropType = PropTypes.shape({
  id: PropTypes.number,
  clientId: PropTypes.number,
  name: PropTypes.string,
  insuranceYear: PropTypes.number,
  teamInboxEmail: PropTypes.string,
  tier: PropTypes.string
});

export const interestPropType = PropTypes.shape({
  sumInsured: PropTypes.number,
  currency: PropTypes.string
});

export const vesselPropType = PropTypes.shape({
  id: PropTypes.string,
  fleetId: PropTypes.string,
  built: PropTypes.number,
  flag: PropTypes.string,
  name: PropTypes.string,
  vesselType: PropTypes.string,
  currency: PropTypes.string,
  hullAndMachineryInterest: PropTypes.arrayOf(interestPropType),
  lossOfHireInterest: PropTypes.arrayOf(interestPropType),
  totalLossInterest: PropTypes.arrayOf(interestPropType),
  kidnapRansomInterest: PropTypes.arrayOf(interestPropType),
  lastActiveBow: PropTypes.string,
  totalNetAdditionalPremium: PropTypes.number
});

// exposure (BOW variant) props
export const exposurePropType = PropTypes.shape({
  bowPropType,
  nhcShare: PropTypes.number,
  nhcNetShare: PropTypes.number,
  totalExposure: PropTypes.number
});

export const calculationPropType = PropTypes.shape({
  name: PropTypes.string,
  abbreviation: PropTypes.string,
  currency: PropTypes.number,
  sumInsured: PropTypes.number,
  grossRate: PropTypes.number,
  grossPremium: PropTypes.number,
  rebates: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    rebateId: PropTypes.string,
    rebate: PropTypes.number,
    shortName: PropTypes.string,
    netAfterRebate: PropTypes.number,
  })),
  netPremiumAfterRebates: PropTypes.number,
  netPremiumAfterTimeAdjustment: PropTypes.number
});

export const logEntryPropType = PropTypes.shape({
  id: PropTypes.string,
  area: PropTypes.string,
  fleets: PropTypes.arrayOf(PropTypes.string),
  comment: PropTypes.string,
  type: PropTypes.string,
  timestamp: PropTypes.date,
  user: PropTypes.string
});

export const tableHeadersPropType = PropTypes.shape({
  propertyName: PropTypes.string,
  title: PropTypes.string,
  parameterName: PropTypes.string
});

export const columnMetadataPropType = PropTypes.shape({
  facets: PropTypes.arrayOf(PropTypes.string),
  propertyName: PropTypes.string
});

export const itemsWithMetadataPropType = PropTypes.shape({
  items: PropTypes.arrayOf(bowPropType),
  columnMetadata: PropTypes.arrayOf(columnMetadataPropType),
  pagingMetadata: PropTypes.objectOf(PropTypes.any) // TODO: create custom prop type?
});

const agreementsBasePropType = PropTypes.shape({
  id: PropTypes.string,
  areaName: PropTypes.string,
  highRiskArea: PropTypes.number,
  vesselTypes: PropTypes.arrayOf(PropTypes.string),
  quoteValidHours: PropTypes.number,
  daysCovered: PropTypes.number,
  hullAndMachineryNetOfRebates: PropTypes.number,
  hullAndMachineryRate: PropTypes.number,
  increasedValueRate: PropTypes.number,
  lossOfHireRate: PropTypes.number,
  standaloneLossOfHire: PropTypes.number,
  standaloneLossOfHireMinimumPremium: PropTypes.number,
  noClaimBonusRebate: PropTypes.number,
  regularCallRebate: PropTypes.number,
  kidnappingAndRansomRebate: PropTypes.number
});

export const specificAgreementsPropType = PropTypes.shape({
  agreementsBasePropType,
  fleetNames: PropTypes.arrayOf(PropTypes.string),
  vesselNames: PropTypes.arrayOf(PropTypes.string),
  highRiskArea: PropTypes.number,
  armedGuardsRebate: PropTypes.number,
  kidnappingAndRansomGrossSingleTransitPremium: PropTypes.number,
  kidnappingAndRansomArmedGuardsIncludedPremium: PropTypes.number,
  kidnappingAndRansomArmedGuardsExcludedPremium: PropTypes.number
});

export const standardAgreementsPropType = PropTypes.shape({
  agreementsBasePropType,
  tier: PropTypes.string
});
