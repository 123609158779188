import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const Title = styled.h2`
  font-size: 18px;
  display: flex;
  align-items: center;
  margin: 0;
`;

const HeaderAction = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.sanMarino};
  font-size: 14px;
  cursor: pointer;

  svg {
    height: 22px;
  }
`;

const IconContainer = styled.div`
  margin: 0 0.7em;
  float: left;
  padding-top: 5px;

  svg {
    height: 16px;
  }
`;

interface BoxContentProps {
  white?: boolean;
  shaded?: boolean;
  highlight?: string;
  background?: string;
}
export const BoxContent = styled.div<BoxContentProps>`
  ${props => props.white && css`
    background: ${props.background ?? props.theme.white};
  `};
  ${props => props.shaded && css`
    box-shadow: 0 2px 6px 0 rgba(72,135,177,0.2);
  `};
  ${props => props.highlight && css`
      border-top: 5px solid ${props.highlight};
  `};
`;

interface BoxProps {
  header?: string | React.ReactNode;
  icon?: React.ReactNode;
  iconRight?: React.ReactNode;
  action?: {
    callback?: () => void;
    label: string | React.ReactElement;
  };
  className?: string;
  white?: boolean;
  shaded?: boolean;
  background?: string;
  highlight?: string;
}

export const Box = ({ header, icon, iconRight: rightIcon, action, children, className, highlight, white = true, background, shaded = true }: PropsWithChildren<BoxProps>) => (
  <Container className={className}>
    {header && (
      <Header>
        <Title>
          {icon && <IconContainer>{icon}</IconContainer>}
          {header}
          {rightIcon && <IconContainer>{rightIcon}</IconContainer>}
        </Title>
        {action && (
          <HeaderAction onClick={action.callback ? () => action.callback!() : undefined}>{action.label}</HeaderAction>
        )}
      </Header>
    )}
    <BoxContent white={white} shaded={shaded} highlight={highlight} background={background}>
      {children}
    </BoxContent>
  </Container>
);
