import { isInternalUser, isReinsurer } from './roles';
import { Routes } from './routes';

const nonBreakingSpace = '\u00A0';

const underwriterNavbarItems = [
  { path: Routes.dashboard, name: 'Dashboard' },
  { path: Routes.termsDefault, name: `Default${nonBreakingSpace}T&C` },
  { path: Routes.termsSpecific, name: `Fleet specific${nonBreakingSpace}T&C` },
  { path: Routes.fleets, name: 'Fleet Overview' },
  { path: Routes.bows, name: 'Breach of warranty' },
];

const brokerNavbarItems = [
  { path: Routes.dashboard, name: 'Dashboard' },
  { path: Routes.terms, name: 'Terms & conditions' },
  { path: Routes.fleets, name: 'Fleet Overview' },
  { path: Routes.bows, name: 'Breach of warranty' },
];

const reinsurerNavbarItems = [
  { path: Routes.bows, name: 'Breach of warranty' },
];

export const getNavbarItems = () => {
  if (isInternalUser()) {
    return underwriterNavbarItems;
  }
  if (isReinsurer()) return reinsurerNavbarItems;

  return brokerNavbarItems;
};
