import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { childrenPropType } from '../../../prop-types/custom-prop-types';


const StyledButton = styled.button`
  box-sizing: border-box;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: ${props => props && props.width};
  height: ${props => props && props.height};
`;

const TextContainer = styled.span`
  line-height: 20px;
  font-size: 14px;
  font-weight: bold;
  ${props => props.StartIcon && css`
    margin-left: 10px;
  `}
`;

const Container = styled.div`
  color: ${props => props.theme[props.color] || props.theme.marineBlue};
  ${props => props.underline && css`
    text-decoration: underline;
  `}
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const LinkButton = ({ children, startIcon: StartIcon, endIcon: EndIcon, width, height, onClick, underline, color }) => (
  <StyledButton width={width} height={height} onClick={onClick} type="button">
    <Container underline={underline} color={color}>
      {StartIcon && StartIcon}
      <TextContainer StartIcon={StartIcon}>{children}</TextContainer>
      {EndIcon && EndIcon}
    </Container>
  </StyledButton>
);

LinkButton.propTypes = {
  children: childrenPropType,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  width: PropTypes.string,
  height: PropTypes.string,
  onClick: PropTypes.func,
  underline: PropTypes.bool,
  color: PropTypes.string
};
