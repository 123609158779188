import { Cry } from '@instech/icons';
import styled from 'styled-components';
import { ModalHandler } from '@/components/modal/Modal';
import { Button } from '@/components/shared/Button';
import { theme } from '@/utility/theme';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { useModalContext } from '@/components/modal/ModalContext';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  color: ${theme.marineBlue};

  svg {
    height: 100px;
    padding-top: 2rem;
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem auto;

  h2 {
    margin: 0.3rem 2rem;
  }
  p {
    max-width: 80%;
    text-align: center;
    margin: 0.3rem auto;
  }

`;

interface ErrorProps {
  title?: string;
  message?: any;
  persists?: boolean;
}
export const Error = ({ title, message, persists = true }: ErrorProps) => {
  const { close } = useModalContext();

  const ok = () => {
    console.log('oK');
  };

  const errorMessage: string = typeof message === 'object' ? null : message?.toString();

  return (
    <ModalHandler>
      <Column>
        <Cry />
        <Text>
          <h2>{title ?? 'That didn\'t go well'}</h2>
          {errorMessage && <p><strong>{errorMessage}</strong></p>}
          {persists && <p>If the problem persists, contact support</p>}
        </Text>
        <ButtonGroup justify="flex-end">
          <Button onClick={close} background={theme.lightGray}>Close</Button>
          {/* <Button onClick={ok} background={theme.green}>Try again</Button> */}
        </ButtonGroup>
      </Column>
    </ModalHandler>
  );
};

export const openErrorModal = (
  title?: string,
  message?: any,
  persists?: boolean
) => ({
  component: Error,
  options: {
    size: 'small'
  },
  args: {
    title,
    message,
    persists
  }
});
