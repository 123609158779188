import React, { FC } from 'react';
import { ActionMeta, SingleValue } from 'react-select';
import styled, { css } from 'styled-components';
import { useFleets } from '@/services/fleetsService';
import { CustomSelect, CustomSelectValue } from '@/components/shared/Form/fields/CustomSelect';
import { WarWeb } from '@/war';
import { FieldLayout } from '../../../shared/Form/core/Components';
import { FleetState } from './models';
import { useBowWizardStateContext } from '../BowWizardStateContext';

const Row = styled.div<{ right?: boolean }>`
  color: ${props => props.theme.sanMarino};
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 20px;

  ${FieldLayout} {
    width: 100%;
    ${props => props.right ? css`
      padding-left: 20px;
    ` : css`
      padding-right: 20px;
    `}
  }

  h3 {
    padding-left: 0.5rem;
  }
`;

const resolveRateLeadFilter = (isNonRateLead?: boolean): WarWeb.RateLeadType[] | undefined => {
  if (isNonRateLead === undefined) return undefined;
  return isNonRateLead ? ['NonRateLead', 'Mixed'] : ['RateLead', 'Mixed'];
};

interface FleetsDropdownProps {
  currentFleet?: number;
  currentClient?: number;
  isNonRateLead?: boolean; // must be a prop, as component is also used outside BowWizardContext
  right?: boolean;
  setFleet: (fleet: WarWeb.MarineMatch) => void;
  label?: string;
}
export const FleetsDropdown: FC<FleetsDropdownProps> = ({ currentFleet, currentClient, setFleet, isNonRateLead, right = false, label }) => {
  const selectedRateLeadTypes: WarWeb.RateLeadType[] | undefined = resolveRateLeadFilter(isNonRateLead);
  const defaultFilters = { pageNumber: 1, pageSize: 1000, selectedRateLeadTypes };
  const { data: fleets, isLoading } = useFleets(defaultFilters);

  const changeFleet = (input: SingleValue<CustomSelectValue>, action: ActionMeta<CustomSelectValue>) => {
    if (!input || !fleets) return;
    const fleetMatch = fleets.items.find(n => n.fleetId === input.value);
    if (!fleetMatch) return;
    setFleet(fleetMatch);
  };
  const options = fleets?.items.map(f => ({ value: f.fleetId, label: f.name }));
  const placeholder = currentFleet ? fleets?.items.find(fleet => fleet.fleetId === currentFleet)?.name : fleets?.items[0].name;

  return (
    <Row right={right}>
      {fleets?.items.length === 1 ?
        <h3>{fleets?.items[0].name}</h3>
        : (
          <CustomSelect
            isLoading={isLoading}
            name="fleets"
            aria-label={label}
            placeholder={placeholder}
            options={options}
            onChange={changeFleet} />
        )}
    </Row>
  );
};
