import React from 'react';
import styled from 'styled-components';
import { RichUtils } from 'draft-js';
import PropTypes from 'prop-types';
import { editorStatePropType } from '../../../../../prop-types/custom-prop-types';
import {
  blocks, inline, links
} from './controlOptions';
import { RichTextDropdown } from './RichTextDropdown';
import { InputPopup } from './InputPopup';

const TextStyleWrapper = styled.div`
  margin: 0px 8px;
  cursor: pointer;
  border-bottom: 2px solid ${props => props.active ? props.theme.marineBlue : 'transparent'};
  color: ${props => props.active ? props.theme.marineBlue : props.theme.black};
  &:hover {
    color: ${props => props.theme.marineBlue};
  }
`;

const TextStyle = ({ label, style, icon: Icon, onToggle, active }) => {
  const callback = evt => {
    // Prevent losing editor focus when clicking the button
    evt.preventDefault();
    onToggle(style);
  };
  return <TextStyleWrapper title={label} onMouseDown={callback} active={active}><Icon /></TextStyleWrapper>;
};

TextStyle.propTypes = {
  label: PropTypes.string,
  style: PropTypes.string,
  icon: PropTypes.func.isRequired,
  onToggle: PropTypes.func,
  active: PropTypes.bool
};

export const DropdownWrapper = ({ options, onToggle, editorState }) => {
  const blockType = RichUtils.getCurrentBlockType(editorState);

  return <RichTextDropdown options={options} onChange={onToggle} value={blockType} />;
};

DropdownWrapper.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })),
  onToggle: PropTypes.func,
  editorState: editorStatePropType
};

const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const BlockStyleControls = ({ editorState, onToggle }) => {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  return (
    <Row>
      {blocks.map(item => (
        <TextStyle
          key={item.label}
          label={item.label}
          style={item.style}
          icon={item.icon}
          onToggle={onToggle}
          active={item.style === blockType}
        />
      ))}
    </Row>
  );
};

BlockStyleControls.propTypes = {
  editorState: editorStatePropType,
  onToggle: PropTypes.func
};

const Middlerow = styled.div`
  padding: 0 25px;
  display: flex;
`;

export const InlineStyleControls = ({ editorState, onToggle }) => {
  const currentStyle = editorState.getCurrentInlineStyle();
  return (
    <Middlerow>
      {inline.map(item => (
        <TextStyle
          key={item.label}
          label={item.label}
          style={item.style}
          icon={item.icon}
          onToggle={onToggle}
          active={currentStyle.has(item.style)}
        />
      ))}
    </Middlerow>
  );
};

InlineStyleControls.propTypes = {
  editorState: editorStatePropType,
  onToggle: PropTypes.func
};

export const LinkStyleControls = ({ editorState, onToggle }) => {
  const isLink = () => {
    const contentState = editorState.getCurrentContent();
    const startKey = editorState.getSelection().getStartKey();
    const startOffset = editorState.getSelection().getStartOffset();
    const currentBlock = contentState.getBlockForKey(startKey);
    const entityKey = currentBlock.getEntityAt(startOffset);
    return (
      entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK'
    );
  };

  return (
    <Middlerow>
      {links.map(item => (
        <InputPopup key={`btn-${item.label}`}>
          <TextStyle
          key={item.label}
          label={item.label}
          style={item.style}
          icon={item.icon}
          onToggle={onToggle}
          active={isLink()}
        />

        </InputPopup>
      ))}
    </Middlerow>
  );
};

LinkStyleControls.propTypes = {
  editorState: editorStatePropType,
  onToggle: PropTypes.func
};
