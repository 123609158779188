import styled, { css } from 'styled-components';

export const SidebarSubHeader = styled.div<{ border?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  ${props => props.border && css`
    padding-bottom: 0.7rem;
    border-bottom: 1px solid ${props.theme.blueGray};
  `};
`;

export const Label = styled.div<{ compact?: boolean }>`
    font-size: ${props => props.compact ? '16px' : '18px'};
    font-weight: 600;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${props => props.theme.sanMarino};
`;

const Circle = styled.span<{ backgroundColor?: string, color?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.backgroundColor ? props.backgroundColor : props.theme.status.yellow};
  border-radius: 100%;
  font-size: 14px;
  font-weight: 600;
  color: ${props => props.color ? props.color : props.theme.marineBlue};

  margin-left: 0.7rem;
`;

const CounterNumber = styled.span<{ numberDigits: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => 10 + props.numberDigits * 6}px;
  height: ${props => 10 + props.numberDigits * 6}px;
`;

interface CounterProps {
  number?: number;
  backgroundColor?: string;
  color?: string;
}
export const Counter = ({ number, backgroundColor, color }: CounterProps) => (
  !number || number === 0) ? null : (
    <Circle backgroundColor={backgroundColor} color={color}>
      <CounterNumber numberDigits={(`${number}`).length}>{number}</CounterNumber>
    </Circle>
  );
