import { ValueSummary } from '@/models/Agreement';
import { WarWeb } from '@/war';

const formatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 });

const fixedFormatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 10 });// "unlimited" decimals

const amountFormatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });

const amountEditFormatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, useGrouping: false });

const rateFormatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 6, minimumFractionDigits: 6 });

export const formatDecimal = (input?: number | null) => input != null ? fixedFormatter.format(input) : '';
export const formatAmount = (input?: number | null) => input != null ? amountFormatter.format(input) : '';
export const formatInvert = (formatProc: (input?: number | null) => string, input?: number | null) => {
  if (input == null) return '';
  /* Note: Javascript allow -0.00 and we need to avoid calling * -1 */
  return input === 0 ? formatProc(0) : formatProc(input * -1);
};
export const formatAmountEdit = (input?: number | null) => input != null ? amountEditFormatter.format(input) : '';
export const formatRate = (input?: number | null, suffix? : string) => input != null ? `${rateFormatter.format(input)}${suffix ? ` ${suffix}` : ''} ` : '';
export const formatPercentage = (input?: number | null) => `${formatDecimal(input)}${input != null ? ' %' : ''}`;
export const formatInteger = (input?: number | null) => input != null ? formatter.format(input) : '';
export const formatLargeInteger = (input: number, digits: number) => {
  // https://stackoverflow.com/questions/9461621/format-a-number-as-2-5k-if-a-thousand-or-more-otherwise-900
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup.slice().reverse().find(n => input >= n.value);
  return item ? (input / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
};

export const tryParseFloat = (input?: string | null) => input == null || input === '' ? null : parseFloat(input);

export const getInputValue = (input?: number | null) => input != null ? input : '';

export const getInputString = (input?: string | null) => input != null ? input : '';

export const isNumber = (n: string) => /^-?[\d.]+(?:e-?\d+)?$/.test(n);

export const formatRange = <T>(param?: ValueSummary<T>, formatProc?: (value?: T) => void, suffix?: string) => { // 5, 1, null, 7  => NA-7
  if (!param || param.isDirty || param.existingValueCount === 0) return undefined;
  const isDefinedForAllAgreements = param.existingValueCount === param.targetedValueCount;
  const rangeValueIsExact = param.rangeMinimum === param.rangeMaximum;
  if (isDefinedForAllAgreements) {
    if (rangeValueIsExact) {
      return undefined;
    }
    return `${formatProc ? formatProc(param.rangeMinimum) : param.rangeMinimum} - ${formatProc ? formatProc(param.rangeMaximum) : param.rangeMaximum} ${suffix || ''}`;
  }
  return `N/A - ${formatProc ? formatProc(param.rangeMaximum) : param.rangeMaximum} ${suffix || ''}`;
};

export const formatDecimalsInText = (text: string) => text?.replace(/([\d]+\.[\d]{3})[\d]+/, '$1');
