import { ValidationMessages } from '@/models/ValidationMessages';
import { AxiosError } from 'axios';
import { toLowerKeys } from './objectHelpers';

export const keyToLabel = (obj: any, key: string, level: number, simplify: boolean = true): string => {
  const [element, ...r] = key.split('.');
  const rest = r.join('.');

  if (!element) return '';

  const index = element.match(/^(.*)\[([\d]+)\]$/);
  if (index) {
    const fieldName = index[1];
    const foundKey = Object.keys(obj).find(u => u.toLowerCase() === fieldName.toLowerCase());
    if (!foundKey) return ''; // failed to find object/field in data! END (should not happen)

    const field = obj[foundKey][index[2]];
    const label = field?.name ?? field?.port?.name ?? element; // TODO! Backend should extend collection objects with a friendlyName and a description field
    return `${level > 0 ? ', ' : ''}${label ?? fieldName}${keyToLabel(field, rest, level + 1, simplify)}`;
  }

  const includeInOutput = !simplify || level === 0 || r?.length === 0; // first or last element

  const foundKey = Object.keys(obj).find(u => u.toLowerCase() === element.toLowerCase());
  if (!foundKey) return ''; // failed to find object/field in data! END (should not happen)
  const field = obj[foundKey];
  const output = includeInOutput ? `${level > 0 ? ': ' : ''}${element}` : '';
  return `${output}${keyToLabel(field, rest, level + 1, simplify)}`; // END
};

export const getWarningsText = (obj: any, key: string, delimiter: string) => {
  if (!obj || key == null || obj[key] == null) return null;
  return obj[key].join(delimiter);
};
export const isValidationError = (error: AxiosError) => error.response && (error.response.status === 400 || error.response.status === 422);

export const fieldNameFilter = (obj?: Record<string, any>, partialFieldName?: string, regex = false) => {
  if (!obj || !partialFieldName) return [];
  if (regex) {
    const reg = new RegExp(partialFieldName, 'gi');
    return Object.keys(obj).filter(k => k.match(reg));
  }

  // return Object.keys(obj).filter(k => k.startsWith(partialFieldName));
  return Object.keys(obj).filter(k => k.toLowerCase() === partialFieldName.toLowerCase()); // TODO: Rename to `fieldName` ?
};

export const getWarnings = (obj: Record<string, any>, fieldName: string) => {
  if (!obj) return {};
  return Object.keys(obj).filter(k => k.startsWith(fieldName))?.flatMap(k => {
    const suffix = k.substring(fieldName.length + 1);
    const i = suffix.indexOf('[');
    if (i >= 0) return [];
    return {
      // [suffix || fieldName]: obj[k]
      [suffix]: obj[k]
    };
  });
};

export const parseErrors = (isMounted: boolean, showErrorDialog: (a: string, b: string) => void, error: AxiosError<any, any> | undefined, setErrors?: (errors?: ValidationMessages) => void) => {
  if (!error) return;

  if (!isValidationError(error)) {
    if (!isMounted) return;
    const title = error.response?.data.title;
    const message = error.response?.data.detail;
    showErrorDialog(title, message);
  } else if (setErrors) setErrors(error.response?.data.errors || error.response?.data.Details);
};
