import { ActionMeta, SingleValue } from 'react-select';
import styled, { css } from 'styled-components';
import { CustomSelect, CustomSelectValue } from '@/components/shared/Form/fields/CustomSelect';
import { Loader } from '@instech/components';
import { useRegions, useSubareas } from '@/services/areasService';
import { theme } from '@/utility/theme';
import { WarWeb } from '@/war';
import { useEditExcludedAreasContext } from './EditExcludedAreasContext/EditExcludedAreasContext';
import { AreaName } from './types';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  & > div {
    flex: 1;
  }
`;

const FilterElement = styled.div<{ hasError?: boolean }>`
  ${props => props.hasError && css`
    background-color: ${theme.status.lightRed};
    border: 2px solid ${theme.status.red};
  `};
`;

const customSelectStyles = {
  control: (provided: any) => ({
    ...provided,
    minWidth: '100px',
  })
};

const getDropdownCountries = (regionId?: number, areas?: WarWeb.Area[], oceanAreaNames?: AreaName[]) => {
  const dropdownCountries = areas
    ?.map(c => ({ label: oceanAreaNames?.find(o => o.oceanAreaId === c.id)?.name ?? c.name, value: c.id }));
  const newCustomAreas = oceanAreaNames
    ?.filter(c => c.regionId === regionId && c.oceanAreaId < 0)
    ?.map(o => ({
      label: o.name,
      value: o.oceanAreaId
    }));

  if (newCustomAreas) return dropdownCountries?.concat(newCustomAreas);
  return dropdownCountries;
};

export const AreaFilters = () => {
  const {
    state: {
      regionId,
      areaId,
      oceanAreaNames
    },
    setAreaType,
    setRegionId,
    setAreaId
  } = useEditExcludedAreasContext();

  const { data: regions } = useRegions();
  const { data: areas } = useSubareas(regionId);

  const dropdownRegions = regions?.map(r => ({ label: r.name, value: r.id }));
  const dropdownCountries = getDropdownCountries(regionId, areas, oceanAreaNames);

  const onChangeHandler = (input: SingleValue<CustomSelectValue>, action: ActionMeta<CustomSelectValue>) => {
    if (!input) return;
    if (action.name === 'region') {
      setRegionId(input.value);
      setAreaId();
      setAreaType();
    } else if (action.name === 'country') {
      setAreaId(input.value);
      setAreaType(areas?.find(c => c.id === input.value)?.areaType ?? '');
    }
  };

  if (!dropdownRegions) return <Loader size="small" />;
  return (
    <Container>
      <FilterElement>
        <CustomSelect
          aria-label="Region"
          placeholder="Select a region"
          name="region"
          options={dropdownRegions}
          onChange={onChangeHandler}
          defaultValue={dropdownRegions.find(x => x.value === regionId)}
          styles={customSelectStyles}
        />
      </FilterElement>
      <FilterElement>
        <CustomSelect name="country"
          aria-label="Area"
          placeholder="Select an area"
          options={dropdownCountries}
          onChange={onChangeHandler}
          value={areaId !== undefined ? dropdownCountries?.find(x => x.value === areaId) : null}
          styles={customSelectStyles}
        />
      </FilterElement>
    </Container>
  );
};
