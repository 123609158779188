import { DestinationOption } from '@/components/pages/bows/details/destinationOption';
import { useDestinationConfiguration } from '@/utility/destinationsDropdownHelpers';
import { Globals } from '@/utility/globals';
import { useQuoteContext } from '../../BowSidebar/QuoteContext';
import { useSelectedQuote } from '../useSelectedQuote';
import { CoverageTable } from './CoverageTable';

interface CoverageDestinationProps {
  destination: DestinationOption;
}
export const CoverageDestination = ({ destination }: CoverageDestinationProps) => {
  const { selectedConfiguration, isMultiDestination } = useQuoteContext();
  const { configuration } = useDestinationConfiguration(destination.id);

  const selectedQuote = useSelectedQuote();
  if (!selectedQuote) return null;
  const { coverageDetails, links: quoteLinks } = selectedQuote;
  if (!coverageDetails) return null;

  const totalVoyageConfig = selectedConfiguration === Globals.TotalVoyageId ?
    {
      contractType: coverageDetails.pricingContractType,
      daysCovered: coverageDetails.daysCovered,
      hoursValid: coverageDetails.hoursValid,
      maximumProRataDays: coverageDetails.maximumProrataDays, // NB! Workaround for different casing
      links: quoteLinks
    }
    : undefined;

  if (!(!isMultiDestination || selectedConfiguration === Globals.TotalVoyageId) && !configuration) return null;

  const details = (!isMultiDestination || selectedConfiguration === Globals.TotalVoyageId) ? totalVoyageConfig : configuration;

  if (!details) return null;

  return (
    <CoverageTable
      details={details}
    />
  );
};
