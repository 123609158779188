import { ModalLayout } from '@/components/modal/ModalLayout';
import { useAgreementDataContext } from '@/components/pages/agreementDetails/AgreementDataContext';
import { ConditionsType } from '@/components/pages/agreementDetails/models';
import { isAdmin } from '@/components/routing/roles';
import { Button } from '@/components/shared/Button';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { useTextConditions } from '@/services/textConditionsService';
import { useValidationContext } from '@/services/ValidationContext';
import { theme } from '@/utility/theme';
import { WarWeb } from '@/war';
import { useEffect, useState } from 'react';
import { ConditionsProvider } from './ConditionsContext';
import { ConditionsEditor } from './ConditionsEditor';
import { TextConditionAction } from './ConditionsEditorRow';

export interface NewTerm {
  id: number;
  text?: string;
}

interface ConditionsModalProps {
  isOpen: boolean;
  closeModal: () => void;
  conditionsType: ConditionsType;
  includedConditions: NewTerm[];
  updateConditions: (textCondition: NewTerm, conditionAction: TextConditionAction) => void;
}
export const ConditionsModal = ({ isOpen, closeModal, conditionsType, includedConditions, updateConditions }: ConditionsModalProps) => {
  const { data } = useTextConditions();
  const { errors } = useValidationContext();
  const { conditions } = useAgreementDataContext();

  const [terms, setTerms] = useState<WarWeb.Term[]>([]);

  const [selectedTerms, setSelectedTerms] = useState<number[]>(includedConditions?.map(c => c.id));

  useEffect(() => {
    if (data) {
      setTerms(data);
    }
  }, [data, includedConditions]);

  useEffect(() => {
    setSelectedTerms(includedConditions?.map(c => c.id));
  }, [includedConditions]);

  if (!isOpen) return null;

  const update = (term: WarWeb.Term, action: TextConditionAction) => {
    const textCondition: NewTerm = {
      id: term.id,
      text: term.text,
    };
    updateConditions(textCondition, action);
  };

  const options = {
    title: `Available ${conditionsType}`,
    size: 'medium',
    draggable: true
  };

  return (
    <ModalLayout options={options} closeModal={closeModal}>
      {errors && <span>{errors?.TextConditionId?.join(', ')}</span>}

      <ConditionsProvider selectedTerms={selectedTerms} setSelectedTerms={setSelectedTerms} terms={terms}>
        <ConditionsEditor
          conditions={conditions}
          conditionsType={conditionsType}
          update={update}
          canEdit={isAdmin()} />
      </ConditionsProvider>

      <ButtonGroup justify="flex-end">
        <Button onClick={closeModal} background={theme.green}>Done</Button>
      </ButtonGroup>
    </ModalLayout>
  );
};
