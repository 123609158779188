import { useState } from 'react';
import { Pane, SlimButton } from '@instech/components';
import { Plus } from '@instech/icons';
import styled from 'styled-components';
import { Table } from '@/components/shared/Table/Table';
import { useModalOpenClose } from '@/hooks/useModalOpenClose';
import { useEditExcludedAreasContext } from '../EditExcludedAreasContext/EditExcludedAreasContext';
import { DeletableTableRow } from '../DeletableTableRow';
import { EditPredefinedDirectionModal } from './EditPredefinedDirectionModal';
import { AddPredefinedDirectionModal } from './AddPredefinedDirectionModal';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
`;

const AddDirectionButton = ({ onClick }: { onClick: () => void; }) => {
  const { state: { entryAndExitPoints, areaId } } = useEditExcludedAreasContext();
  const isDisabled = !entryAndExitPoints.some(p => p.oceanAreaId === areaId && p.action === 'NotSet');
  const hint = isDisabled ? 'You must add Entry/Exit points and save them first' : undefined;
  return (
    <SlimButton startIcon={<Plus />} variant="secondary" onClick={onClick} disabled={isDisabled} title={hint}>ADD NEW DIRECTION</SlimButton>
  );
};

const DirectionsTable = () => {
  const { state: { areaAliases, areaId }, deleteAreaAlias, undoDeleteAreaAlias } = useEditExcludedAreasContext();
  const [editingAlias, setEditingAlias] = useState<number>();
  const openModal = (aliasId: number) => setEditingAlias(aliasId);
  const closeModal = () => setEditingAlias(undefined);

  const directions = areaAliases.filter(alias => alias.areaId === areaId);
  return (
    <>
      <Table layout="1fr auto" striped>
        {directions.map(alias => (
          <DeletableTableRow
          key={alias.id}
          item={alias}
          removeItem={deleteAreaAlias}
          undoRemoveItem={undoDeleteAreaAlias}
          updateItem={openModal}
        />
        ))}
      </Table>
      {editingAlias && <EditPredefinedDirectionModal aliasId={editingAlias} closeModal={closeModal} />}
    </>
  );
};

const Editor = () => {
  const { isCustomArea, state: { areaId } } = useEditExcludedAreasContext();
  const { modalIsOpen, openModal, closeModal } = useModalOpenClose();

  if (!isCustomArea || !areaId) return <>Not applicable.</>;
  return (
    <Wrapper>
      <DirectionsTable />
      <AddDirectionButton onClick={openModal} />
      {modalIsOpen && <AddPredefinedDirectionModal closeModal={closeModal} />}
    </Wrapper>
  );
};

export const PredefinedDirectionsEditor = () => (
  <Pane title="Predefined directions" color="green">
    <Editor />
  </Pane>
);
