import React from 'react';
import styled, { css } from 'styled-components';

const DropdownContainer = styled.div<{ isOpen: boolean }>`
    width: 100%;
    border-radius: 3px;
    background-color: #fff;
    position: absolute;
    z-index: 10;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    display: ${props => props.isOpen ? 'inline' : 'none'};
  
    max-height: 25vh;
    overflow-y: auto;
  `;

export const DropdownElement = styled.div<{ info?: boolean, isValid?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
    padding: 0.5rem 1em;
  align-items: center;

  ${props => props.info && css`
    background-color: ${props.theme.ultraLightGray};
  `};

  ${props => !props.info && props.isValid && css`
    cursor: pointer;

    &:hover {
      background-color: ${props.theme.status.yellow};
    }
  `};

  ${props => !props.info && !props.isValid && css`
    cursor: not-allowed;
  `};
`;

interface SearchResultsProps {
  results?: any[];
  display: boolean;
  select: (item: any) => void;
  isValid?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  keyField?: string;
  renderResult: (item: any) => React.ReactNode;
}
export const SearchResults = ({ results, display, select, isValid, keyField, renderResult }: SearchResultsProps) => {
  const handleClick = (result: any) => {
    if (result.isValid === undefined || result.isValid) select(result);
  };

  return (
    <DropdownContainer isOpen={display}>
      {results && results.length === 0
        ? <DropdownElement info><em>No results</em></DropdownElement>
        : results?.map((result, i) => (
          <DropdownElement
            isValid={result.isValid === undefined || result.isValid}
            onClick={() => handleClick(result)}
            key={keyField ? result[keyField] : i}>
            {renderResult(result)}
          </DropdownElement>
        ))}
    </DropdownContainer>
  );
};
