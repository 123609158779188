import { QuoteActions } from '@/components/pages/bows/details/quoteActionsMap';
import { Dropdown } from '@/components/shared/Form/FormFields';
import {
  SpanCell, TableCell, TableRow
} from '@/components/shared/Table/core/Components';
import { ContractType } from '@/models/Agreement';
import { DirtyableValue } from '@/models/utils/DirtyableValue';
import { useHateoasExecutorContext } from '@/services/HateoasExecutorContext';
import { useValidationContext } from '@/services/ValidationContext';
import { ContractTypes } from '@/utility/contractTypes';
import { MaxNumberTimeValue } from '@/utility/globals';
import { WarWeb } from '@/war';
import React, { useEffect, useState } from 'react';
import { useQuoteContext } from '../../BowSidebar/QuoteContext';
import { NumberField } from '../../Form/fields/NumberField';
import { checkLink, HateoasHandler } from '../../HateoasHandler';
import { Table } from '../../Table/Table';
import { Validator } from '../../Validator';

interface CoverageSummary {
  hoursValid?: DirtyableValue<string>;
  daysCovered?: DirtyableValue<string>;
  contractType?: DirtyableValue<ContractType>;
  maximumProRataDays?: DirtyableValue<string>;
}

interface CoverageTableProps {
  details: Partial<WarWeb.DestinationConfiguration>;
}
export const CoverageTable = ({ details }: CoverageTableProps) => {
  const { errors } = useValidationContext();
  const { hateoasExecutor, loading, lastRequest } = useHateoasExecutorContext();
  const { selectedConfiguration } = useQuoteContext();

  const resetState = () => ({
    hoursValid: { value: details.hoursValid?.toString(), isDirty: false },
    daysCovered: { value: details.daysCovered?.toString(), isDirty: false },
    contractType: { value: details.contractType as ContractType, isDirty: false },
    maximumProRataDays: { value: details.maximumProRataDays?.toString(), isDirty: false }
  });

  const [state, setState] = useState<CoverageSummary>(() => resetState());

  useEffect(() => {
    setState(() => resetState());
  }, [selectedConfiguration]);

  useEffect(() => {
    const isRelevant = !!checkLink(details?.links, lastRequest);
    if (errors && isRelevant) {
      setState(() => resetState());
    }
  }, [errors]);

  const tryParse = (val?: string) => {
    if (!val || val === '') return undefined;
    const parsedValue = parseInt(val);
    return Number.isNaN(parsedValue) ? undefined : parsedValue;
  };

  const commitCoverageDetails = () => {
    const isStateDirty = state && Object.values(state).some(x => x?.isDirty);

    if (!isStateDirty || !state) return;

    const { contractType, daysCovered, hoursValid, maximumProRataDays } = state;

    const setCoverageRequest: WarWeb.SetDestinationCoverageRequest = {
      destinationId: details?.destinationId,
      contractType: contractType?.value,
      daysCovered: tryParse(daysCovered?.value),
      hoursValid: tryParse(hoursValid?.value),
      maximumProRataDays: tryParse(maximumProRataDays?.value)
    };

    void hateoasExecutor(details?.links, QuoteActions.SetCoverage, setCoverageRequest, 'coverage');
  };

  useEffect(() => {
    if (state?.contractType?.isDirty) {
      commitCoverageDetails();
    }
  }, [state?.contractType]);

  const processChange = (name: string, value: string) => {
    setState(prev => ({ ...prev, [name]: { value, isDirty: true } }));
  };

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    processChange(name, value);
  };

  const selectHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    processChange(name, value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      commitCoverageDetails();
    }
  };

  if (!details || !state) return null;

  const placeholder = 'Please choose contract type';

  return (
    <Table columns={2} layout="2fr 90px">
      <TableRow shaded={false}>
        <TableCell span={2}>
          <HateoasHandler
            links={details.links}
            action={QuoteActions.SetCoverage}
            editVariant={(
              <Validator keys={['ContractType']} isRelevant={!!checkLink(details.links, lastRequest)}>
                <Dropdown
                  name="contractType"
                  label="Contract type"
                  placeholder={placeholder}
                  selectedValue={state?.contractType?.value?.toString() ?? placeholder}
                  changeHandler={selectHandler}
                  options={Object.keys(ContractTypes).map((t: string) => ({ name: t, value: t }))}
                  disabled={loading}
                />
              </Validator>
            )}
            viewVariant={<strong>{state?.contractType?.value ?? 'Contract Type:'}</strong>}
          />
        </TableCell>
      </TableRow>

      <TableRow shaded={false}>
        <TableCell>Days covered</TableCell>
        <TableCell right>
          <HateoasHandler
            links={details.links}
            action={QuoteActions.SetCoverage}
            editVariant={(
              <NumberField
                type="int"
                maxValue={MaxNumberTimeValue}
                name="daysCovered"
                noErrors
                onChange={changeHandler}
                onKeyDown={handleKeyDown}
                onBlur={commitCoverageDetails}
                dirty={state?.daysCovered?.isDirty}
                value={state?.daysCovered?.value}
                disabled={loading}
              />
            )}
            viewVariant={state?.daysCovered?.value}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <SpanCell height="1" start={1} end={-1}>
          <Validator keys={['DaysCovered']} isRelevant={!!checkLink(details.links, lastRequest)} />
        </SpanCell>
      </TableRow>

      <TableRow shaded={false}>
        <TableCell>Quote validity hours</TableCell>
        <TableCell right>
          <HateoasHandler
            links={details.links}
            action={QuoteActions.SetCoverage}
            editVariant={(
              <NumberField
                type="int"
                maxValue={MaxNumberTimeValue}
                name="hoursValid"
                noErrors
                onChange={changeHandler}
                onKeyDown={handleKeyDown}
                onBlur={commitCoverageDetails}
                dirty={state?.hoursValid?.isDirty}
                value={state?.hoursValid?.value}
                disabled={loading}
              />
            )}
            viewVariant={state?.hoursValid?.value}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <SpanCell height="1" start={1} end={-1}>
          <Validator keys={['HoursValid']} isRelevant={!!checkLink(details.links, lastRequest)} />
        </SpanCell>
      </TableRow>

      {details.maximumProRataDays != null && (
        <>
          <TableRow shaded={false}>
            <TableCell>Maximum pro rata days</TableCell>
            <TableCell right>
              <HateoasHandler
                links={details.links}
                action={QuoteActions.SetCoverage}
                editVariant={(
                  <NumberField
                    type="int"
                    maxValue={MaxNumberTimeValue}
                    name="maximumProRataDays"
                    noErrors
                    onChange={changeHandler}
                    onKeyDown={handleKeyDown}
                    onBlur={commitCoverageDetails}
                    dirty={state?.maximumProRataDays?.isDirty}
                    value={state?.maximumProRataDays?.value}
                    disabled={loading}
                  />
                )}
                viewVariant={state?.maximumProRataDays?.value}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <SpanCell height="1" start={1} end={-1}>
              <Validator keys={['MaximumProRataDays']} isRelevant={!!checkLink(details.links, lastRequest)} />
            </SpanCell>
          </TableRow>
        </>
      )}

    </Table>
  );
};
