import { InlineModalProps } from '@/components/modal/InlineModalProps';
import { ModalLayout } from '@/components/modal/ModalLayout';
import { Button, ButtonWithLoader } from '@/components/shared/Button';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { theme } from '@/utility/theme';
import { WarningFilled } from '@instech/icons';
import styled from 'styled-components';
import { TargetTypeAsString, targetTypeToFriendlyString } from '@/models/TargetType';
import { useAgreementsSummaryAlternatives } from '@/services/agreementsService';
import { WarWeb } from '@/war';
import { useAgreementsPageContext } from '../../agreements/AgreementsPageContext';
import { useAgreementTargets } from '../../agreements/useAgreementTargets';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 2rem;
  text-align: center;
`;

const StyledWarning = styled(WarningFilled)`
  path {
    fill: ${props => props.theme.marineBlue};
  }
  height: 60px;
  width: 60px;
  margin: 3rem;
`;

const DeleteHeader = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 2rem;
`;

const DeleteDescription = styled.div`
  margin-bottom: 2rem;
`;

interface DeleteConfirmationModalProps extends Required<InlineModalProps> {
  header: string;
  description: string;
}
const DeleteConfirmationModal = ({ closeModal, apply, header, description }: DeleteConfirmationModalProps) => {
  const submitHandler = async () => {
    await apply();
    closeModal();
  };

  const options = {
    size: 'small',
    clickOutsideToClose: true
  };

  return (
    <ModalLayout closeModal={closeModal} options={options}>
      <Container>
        <StyledWarning />
        <DeleteHeader>
          {header}
        </DeleteHeader>
        <DeleteDescription>
          {description}
        </DeleteDescription>
        <ButtonGroup justify="space-between">
          <Button background={theme.ultraLightGray} onClick={closeModal}>Cancel</Button>
          <ButtonWithLoader background={theme.green} onClick={submitHandler}>Delete</ButtonWithLoader>
        </ButtonGroup>
      </Container>
    </ModalLayout>
  );
};

interface DeleteSpecificAgreementsConfirmationModalProps extends Required<InlineModalProps> {
  numberOfAgreements: number;
  numberOfVessels: number;
}
export const DeleteSpecificAgreementsConfirmationModal = (
  { closeModal, apply, numberOfAgreements, numberOfVessels }: DeleteSpecificAgreementsConfirmationModalProps
) => {
  const header = `Deleting ${numberOfAgreements} agreement(s) for a total of ${numberOfVessels} vessel(s)`;
  const description = 'The agreements will be reverted to a Default Agreement in accordance with fleet tier.';
  return (
    <DeleteConfirmationModal
      closeModal={closeModal}
      apply={apply}
      header={header}
      description={description}
    />
  );
};

const getHasMultipleAlternatives = (alternatives?: WarWeb.AgreementAlternativesSummary[]) => {
  if (!alternatives) return false;
  return alternatives.length > 1 || alternatives.some(x => x.minimumSumInsured && x.minimumSumInsured.length > 1);
};

const getDescription = (hasMultipleAlternatives: boolean, hasUnspecifiedAgreementType: boolean) => {
  const description = [];

  if (hasUnspecifiedAgreementType) {
    description.push('Default agreements will also be deleted if selected.');
  }

  if (hasMultipleAlternatives) {
    description.push('All days covered and sum insured tabs will be deleted.');
  }

  return description.join(' ');
};

interface DeleteAgreementsConfirmationModalProps extends Required<InlineModalProps> {
  numberOfAgreements: number;
}
export const DeleteAgreementsConfirmationModal = ({ closeModal, apply, numberOfAgreements }: DeleteAgreementsConfirmationModalProps) => {
  const { agreementTargets } = useAgreementTargets();
  const { data: alternatives } = useAgreementsSummaryAlternatives({ ...agreementTargets, daysCoveredGroup: undefined, minimumSumInsured: undefined });
  const { agreementSummaryTarget } = useAgreementsPageContext();
  const agreementType = targetTypeToFriendlyString(agreementSummaryTarget.targetType);
  const hasUnspecifiedAgreementType = agreementType === TargetTypeAsString.Unspecified;
  const agreementTypeText = hasUnspecifiedAgreementType ? '' : `${agreementType.toLowerCase()} `;
  const hasMultipleAlternatives = getHasMultipleAlternatives(alternatives);

  const description = getDescription(hasMultipleAlternatives, hasUnspecifiedAgreementType);
  const header = `Are you sure you want to delete the selected ${agreementTypeText}agreement(s)?`;

  return (
    <DeleteConfirmationModal
      closeModal={closeModal}
      apply={apply}
      header={header}
      description={description}
    />
  );
};
