import { useSWREndpoint } from '@/hooks/useSWREndpoint';
import { WarWeb } from '@/war';
import { postData } from './utility/simpleFetch';

const basepath = 'excludedareas';

export const getExcludedAreasUri = (id?: number) => `${basepath}/${id}`;

export const useExcludedAreas = (id?: number) => useSWREndpoint<WarWeb.Area[]>(id ? getExcludedAreasUri(id) : null);

export const addExcludedAreasBatch = async (request: WarWeb.BulkExcludeAreasRequest) => postData(`${basepath}/batch`, request);

const getEntryExitPointsUri = (oceanAreaId?: number) => `${basepath}/${oceanAreaId}/entryExitPoints`;

export const useEntryExitPoints = (oceanAreaId?: number) =>
  useSWREndpoint<WarWeb.EntryExitPoint[]>(oceanAreaId ? getEntryExitPointsUri(oceanAreaId) : null);
