import { TargetType } from '@/models/TargetType';
import { ChildrenProps } from '@/models/utils/ChildrenProps';
import React, { createContext, useContext, useMemo, useState } from 'react';

const AgreementSelectionContext = createContext<IAgreementSelectionContext | undefined>(undefined);

export interface IAgreementSelectionValues {
  areaIds: number[];
  areaIdsTimeExceptions?: number[];
  vesselIds: number[];
  vesselTypeIds: number[];
  fleetIds: number[];
  agreementIds: number[];
  targetType?: TargetType;
  isTrade?: boolean;
}

interface IAgreementSelectionContext extends IAgreementSelectionValues {
  setAreaIds: React.Dispatch<React.SetStateAction<number[]>>;
  setAreaIdsTimeExceptions: React.Dispatch<React.SetStateAction<number[]>>;
  setVesselIds: React.Dispatch<React.SetStateAction<number[]>>;
  setVesselTypeIds: React.Dispatch<React.SetStateAction<number[]>>;
  setFleetIds: React.Dispatch<React.SetStateAction<number[]>>;
  setAgreementIds: React.Dispatch<React.SetStateAction<number[]>>;
  setTargetType: (targetType?: TargetType) => void;
}

const AgreementSelectionProvider = ({ children }: ChildrenProps) => {
  const [areaIds, setAreaIds] = useState<number[]>([]);
  const [areaIdsTimeExceptions, setAreaIdsTimeExceptions] = useState<number[]>([]);
  const [vesselIds, setVesselIds] = useState<number[]>([]);
  const [vesselTypeIds, setVesselTypeIds] = useState<number[]>([]);
  const [fleetIds, setFleetIds] = useState<number[]>([]);
  const [agreementIds, setAgreementIds] = useState<number[]>([]);
  const [targetType, setTargetType] = useState<TargetType>();
  const isTrade = targetType === TargetType.Trade;

  const value = useMemo(
    () => ({
      areaIds,
      areaIdsTimeExceptions,
      vesselIds,
      vesselTypeIds,
      fleetIds,
      agreementIds,
      targetType,
      isTrade,
      setAreaIds,
      setAreaIdsTimeExceptions,
      setVesselIds,
      setVesselTypeIds,
      setFleetIds,
      setAgreementIds,
      setTargetType,
    }),
    [areaIds, areaIdsTimeExceptions, vesselIds, vesselTypeIds, fleetIds, agreementIds, targetType]
  );

  return <AgreementSelectionContext.Provider value={value}>{children}</AgreementSelectionContext.Provider>;
};

const useAgreementSelectionContext = () => {
  const context = useContext(AgreementSelectionContext);

  if (context === undefined) {
    throw new Error('useAgreementSelectionContext must be used within a AgreementSelectionProvider');
  }
  return context;
};
export { AgreementSelectionProvider, useAgreementSelectionContext };
