import { Box } from '@/components/shared/Box';
import { Chevron } from '@/components/shared/Chevron';
import {
  NoDataRow, renderTableHeaders, Table, TableCell, TableRow
} from '@/components/shared/Table/Table';
import { useRegions } from '@/services/areasService';
import { theme } from '@/utility/theme';
import { WarWeb } from '@/war';
import { World2 } from '@instech/icons';
import { useMemo } from 'react';
import { useBulkChangeAreasContext } from '../BulkChangeAreasContext';

const tableHeaders = ['Name', ''];

const RegionRow = ({ region }: { region: WarWeb.Area }) => {
  const { state, dispatch } = useBulkChangeAreasContext();

  const onRowClickHandler = () => {
    dispatch({ type: 'SetSelectedRegion', payload: region.id });
  };

  const isSelected = state.selectedRegion === region.id;

  return (
    <TableRow backgroundColor={isSelected ? theme.lightGreen50 : undefined} onClick={onRowClickHandler}>
      <TableCell>{region.name}</TableCell>
      <TableCell right><Chevron right /></TableCell>
    </TableRow>
  );
};

export const BulkChangeRegionColumn = () => {
  const { data: regions, error, isLoading } = useRegions();
  const sortedRegions = useMemo(() => regions && [...regions].sort((x, y) => x.name.localeCompare(y.name)), [regions]);

  return (
    <Box icon={<World2 />} header="Regions">
      <Table columns={tableHeaders.length} striped>
        {renderTableHeaders(tableHeaders)}
        {sortedRegions ?
          sortedRegions.map(region => <RegionRow key={region.id} region={region} />) :
          <NoDataRow loadStatus={{ loading: isLoading, success: !error }} />}
      </Table>
    </Box>
  );
};
