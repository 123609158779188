import { useHistory, useLocation } from 'react-router-dom';
import { Filters } from '@/models/Filters';
import { SortedHeader, toFilter } from '@/models/SortOrder';
import { addOrRemove } from '@/utility/arrayHelpers';
import { WarWeb } from '@/war';
import React, {
  createContext, FC, useContext
} from 'react';
import { TableColumnHeader } from '@/components/shared/Table/core/Components';

export interface Range {
  min?: string | null;
  max?: string | null;
}

interface FilterableTableHeadersProviderProps {
  headers: TableColumnHeader[],
  columnMetadata?: WarWeb.ColumnMetadata[];
  category?: string;
  setColumnMetadata?: (columnMetadata: WarWeb.ColumnMetadata[]) => void;
  initialFilters?: Filters;
  filters: Filters;
  setFilters: (filter: Filters) => void;
  sortedHeader?: SortedHeader;
  setSortedHeader: (header?: SortedHeader) => void;
  changeFilter?: (name: string, value: string | string[] | boolean | boolean[] | Range) => void;
  toggleFacet?: (parameterName: string, facet?: string) => void;
  resetFilters?: () => void;
  isLoading: boolean;
}
const FilterableTableHeadersContext = createContext<FilterableTableHeadersProviderProps>({
  headers: [],
  columnMetadata: undefined,
  category: undefined,
  isLoading: true,
  initialFilters: [],
  filters: [],
  setFilters: () => { },
  sortedHeader: undefined,
  setSortedHeader: () => { },
  toggleFacet: () => { },
  resetFilters: () => { }
});

export const FilterableTableHeadersProvider: FC<FilterableTableHeadersProviderProps> = (
  {
    headers,
    columnMetadata,
    category,
    setColumnMetadata,
    initialFilters,
    filters,
    setFilters,
    sortedHeader,
    setSortedHeader,
    changeFilter,
    isLoading,
    toggleFacet,
    resetFilters,
    children
  }
) => {
  const location = useLocation();
  const history = useHistory();

  const filterIsEmpty = (value: string | string[] | boolean | boolean[] | Range) => {
    if (Array.isArray(value)) return value.length === 0;
    if (!value) return true;

    if ((value as Range).min) {
      return !(!!(value as Range).min || !!(value as Range).max);
    }
    return false;
  };

  const changeFilterLocal = (name: string, value: string | string[] | boolean | boolean[] | Range) => {
    const newFilters = { ...filters, pageNumber: 1, [name]: value };
    if (filterIsEmpty(value)) delete newFilters[name];
    setFilters(newFilters);
  };

  const toggleFacetLocal = (parameterName: string, facet?: string) => {
    if (!filters) return;
    if (facet) {
      const queryParams = new URLSearchParams(location.search);
      const newSelectedFacets = filters[parameterName] ? [...filters[parameterName]] : [];
      addOrRemove(newSelectedFacets, facet);
      changeFilterLocal(parameterName, newSelectedFacets);
      if (queryParams.has(parameterName)) {
        queryParams.delete(parameterName);
        history.replace({
          search: queryParams.toString(),
        });
      }
    } else {
      changeFilterLocal(parameterName, []);
    }
  };

  const resetFiltersLocal = () => {
    setFilters(initialFilters || {});
    setSortedHeader(toFilter(initialFilters?.orderBy));
  };

  return (
    <FilterableTableHeadersContext.Provider value={{
      headers,
      columnMetadata,
      category,
      setColumnMetadata,
      initialFilters,
      filters,
      setFilters,
      setSortedHeader,
      sortedHeader,
      toggleFacet: toggleFacetLocal,
      changeFilter: changeFilterLocal,
      resetFilters: resetFiltersLocal,
      isLoading
    }}>
      {children}
    </FilterableTableHeadersContext.Provider>
  );
};

export const useFilterableTableHeadersContext = () => useContext(FilterableTableHeadersContext);
