import styled, { css } from 'styled-components';
import { Box } from '../../Box';
import {SimpleTable, TableRow, TableCell } from '@instech/components';
import { TimeConverter } from '@/utility/timeConverter';
import { WarWeb } from '@/war';
import { EmptyTable } from '@/components/shared/Table/core/Components';

const Content = styled.div`
  border-radius: 2px;
  padding: 1.5rem;

  div {
    background-color: transparent;
  }
`;
const TableHeader = styled.div`
  font-weight: bold;
  padding: 0.5rem 0;
`;
const SumTableRow = styled(TableRow)<StyledTableRowProps>`
   ${(props) =>
    props.isSum &&
    css`
      > span {
        font-weight: 600;
        border-top: 2px solid ${props.theme.border.sanMarino};
        border-bottom: 2px solid ${(props) => props.theme.border.sanMarino};
        background: ${(props) => props.theme.lightGreen25};
      }
    `}; 
`;
const StyledSimpleTable = styled(SimpleTable)`
  background: transparent;
`;
const StyledTableCell = styled(TableCell)`
  text-align: right;
`;
const InfoTableRow = styled(TableCell)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
`;

interface StyledTableRowProps {
  isSum: boolean;
}
interface PaymentDetails
{
  additionalDueDateDaysFirstInstallment: number;
  dueDate: WarWeb.LocalDateTime;
  paymentLines: PaymentLine[];
}
interface PaymentLine {
amountDue: {
  currency: string;
  value: number;
};
bic: string;
iban: string;
}
interface PaymentsBoxProps {
paymentDetails: PaymentDetails;
}

export const  PaymentsBox = ({ paymentDetails }:  PaymentsBoxProps) => {
const paymentLines = paymentDetails?.paymentLines;
const dueDate = paymentDetails?.dueDate.local;
  return (
    <Box header={'Payment Details'}>
      <Content>
        {paymentLines ? paymentLines.map((row: any, i: number) => (
        <StyledSimpleTable columns={2} key={i}>
          <SumTableRow isSum={true}>
            <TableCell>Net Premium due to Norwegian Hull Club</TableCell>
            <StyledTableCell> {row.amountDue.value.toLocaleString()} {row.amountDue.currency}</StyledTableCell>
          </SumTableRow>
          <InfoTableRow>
              <div>
                <TableHeader>SWIFT Code</TableHeader>{row.bic}
              </div>
              <div>
                <TableHeader>Account Number</TableHeader>{row.iban}
              </div>
              <div>
                <TableHeader>Our Reference</TableHeader>{row.ourReference}
              </div>
              <div>
                <TableHeader>Due Date</TableHeader>{TimeConverter.ToDate(dueDate)}
              </div>
            <TableCell>
            </TableCell>
            </InfoTableRow>
      </StyledSimpleTable>
          )) : <EmptyTable />}
      </Content>
    </Box>
  );
};
