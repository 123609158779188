import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '@/components/shared/Button';
import { theme } from '@/utility/theme';
import { ButtonGroup } from '@/components/shared/ButtonGroup';
import { Checkbox } from '@/components/shared/Form/fields/Checkbox';
import { ModalLayout } from '@/components/modal/ModalLayout';
import { InlineModalProps } from '@/components/modal/InlineModalProps';
import { StatusConfirmation } from './quoteStatusConfirmations';
import { WarningNotification } from '@instech/components';
import { PlaceExposure } from './PlaceExposure';
import { HateoasHandler } from '@/components/shared/HateoasHandler';
import { QuoteActions } from './quoteActionsMap';
import { QuoteLabel } from './quoteLabelMap';
import { useBow } from '@/services/bowServices';
import { WarWeb } from '@/war';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Conditions = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.lightBlue};
  padding: 1rem;
  margin: 1rem 0 2rem 0;
  border-radius: 4px;
`;

export const enum ReinsuranceValues {
  Section2And4 = 'Section2And4',
  Section2And5 = 'Section2And5',
  section2Share = 'section2Share',
  section4Share = 'section4Share',
}

export interface ConfirmationModalProps extends InlineModalProps {
  config: StatusConfirmation;
  links: WarWeb.HyperMediaLink[];
  bowId?: string;
  request?: any;
  positiveAction?: any;
  hateoasExecutor?: (links: any, action: string, options: any) => any;
}

export const ConfirmationModal = ({
  links,
  config,
  bowId,
  request,
  closeModal,
  hateoasExecutor,
  positiveAction,
}: ConfirmationModalProps) => {
  const [checkboxState, setCheckboxState] = useState(!config.checkbox);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const payload = config.responseKey
    ? { [config.responseKey]: checkboxState, ...request }
    : { ...request, confirmed: true };
  // we need the newest bow data here in modal to check available links
  const { data: bowData } = useBow(bowId);
  const isRadioButtonNotSelected =
    config.key === QuoteLabel.SetReinsurance && bowData?.primaryQuote?.reinsurance === null;

  // request to /reinsurance (SetReinsurance action) happens when radio button is clicked,
  // we need to send request to status/confirmed (BindCoverage action) by clicking on BindCoverage button
  const action = config.key === QuoteLabel.SetReinsurance ? QuoteLabel.BindCoverage : config.key;

  const ok = () => {
    closeModal();
    hateoasExecutor
      ? hateoasExecutor(links, action, payload)
      : positiveAction(config.responseKey ? { [config.responseKey]: checkboxState } : {});
  };

  const modalOptions = {
    size: 'small',
    title: config.title,
  };

  // Do not show Submit button if no BindCoverage Link
  const isNoLink =
    config.key === QuoteLabel.SetReinsurance &&
    !links?.find((item: { action: string }) => item.action === 'BindCoverage');

  return (
    <ModalLayout closeModal={closeModal} options={modalOptions}>
      <Column>
        <HateoasHandler
          links={bowData?.primaryQuote.links}
          action={QuoteActions.RequestConfirmation}
          editVariant={
            <WarningNotification
              size="medium"
              headingText="Cover needs to be explicitly confirmed by Norwegian Hull Club before entering the excluded area."
            />
          }
        />
        {config.key === QuoteLabel.SetReinsurance && (
          <PlaceExposure
            isDisabled={isDisabled}
            setIsDisabled={setIsDisabled}
            calculations={bowData?.quotes[0].calculations}
            hateoasExecutor={hateoasExecutor}
            bowData={bowData}
            loading={loading}
            setLoading={setLoading}
          />
        )}

        {config.key !== QuoteLabel.SetReinsurance && (
          <Conditions>
            <p>{config.conditions}</p>
            {config.checkbox && <Checkbox onChange={() => setCheckboxState(!checkboxState)} name="Accept" />}
          </Conditions>
        )}

        <ButtonGroup justify="flex-end">
          <Button onClick={closeModal} background={theme.lightGray}>
            {config.negativeAction}
          </Button>
          {!isNoLink && (
            <Button
              onClick={ok}
              disabled={!checkboxState || loading || isRadioButtonNotSelected || isDisabled}
              background={theme.green}
            >
              {config.positiveAction}
            </Button>
          )}
        </ButtonGroup>
      </Column>
    </ModalLayout>
  );
};
