import { Box } from '@/components/shared/Box';
import { Checkbox } from '@/components/shared/Form/fields/Checkbox';
import {
  NoDataRow, renderTableHeaders, Table, TableCell, TableHeader, TableRow
} from '@/components/shared/Table/Table';
import { useExcludedAreas } from '@/services/excludedAreasService';
import { useHighRiskAreas } from '@/services/highRiskAreasService';
import { getDifference } from '@/utility/array';
import { WarWeb } from '@/war';
import { Anchor } from '@instech/icons';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useBulkChangeAreasContext } from '../BulkChangeAreasContext';
import { BulkChangePortFilters } from './BulkChangePortFilters';

const CheckboxTableHeader = styled(TableHeader)`
  padding: 0;
`;

const tableHeaders = ['Code', 'Name', 'HRA'];

const PortRow = ({ port, hra }: { port: WarWeb.Area, hra?: string }) => {
  const { state, dispatch } = useBulkChangeAreasContext();
  const locodeSplit = `${port.locode.substring(0, 2)} ${port.locode.substring(2)}`;

  const onCheckboxChangeHandler = () => {
    dispatch({ type: 'TogglePort', payload: port.id });
  };

  return (
    <TableRow>
      <TableCell slim>
        <Checkbox name="port" onChange={onCheckboxChangeHandler} checked={state.checkedPorts.includes(port.id)} />
      </TableCell>
      <TableCell>{locodeSplit}</TableCell>
      <TableCell>{port.name}</TableCell>
      <TableCell>{hra ?? 'No HRA'}</TableCell>
    </TableRow>
  );
};

export const BulkChangePortColumn = () => {
  const { state, dispatch } = useBulkChangeAreasContext();
  const { data: ports, isLoading, error } = useExcludedAreas(state.selectedArea);
  const { data: highRiskAreas } = useHighRiskAreas();

  const [portsState, setPortsState] = useState(ports);
  const [showContent, setShowContent] = useState(false);
  const [filters, setFilters] = useState<{ locode: string, portName: string, hra?: number }>({ locode: '', portName: '' });

  useEffect(() => {
    if (ports) setPortsState(ports);
    setFilters({ locode: '', portName: '' });
  }, [ports]);

  useEffect(() => {
    setShowContent(!!state.selectedArea);
  }, [state.selectedArea]);

  useEffect(() => {
    let result = ports;
    if (filters.locode != null) {
      const value = filters.locode.toLowerCase().replaceAll(' ', ''); // We have white space in locode for display purposes, but it's not in the data
      result = result?.filter(x => x.locode.toLowerCase().includes(value));
    }
    if (filters.portName != null) result = result?.filter(x => x.name.toLowerCase().includes(filters.portName.toLowerCase()));
    if (filters.hra != null) result = result?.filter(x => filters.hra === 0 ? x.highRiskAreaId === null : x.highRiskAreaId === filters.hra);
    setPortsState(result);
  }, [filters]);

  const renderPorts = () => {
    if (!state.selectedArea) return null;
    return portsState ?
      portsState.map(port => <PortRow key={port.id} port={port} hra={highRiskAreas?.find(hra => hra.id === port.highRiskAreaId)?.name} />) :
      <NoDataRow loadStatus={{ loading: isLoading, success: !error }} />;
  };

  const containsAllPorts = portsState?.map(x => x.id).every(x => state.checkedPorts.includes(x));
  const containsSomePorts = state.checkedPorts.some(x => portsState?.map(y => y.id).includes(x));

  const onChangeHandler = () => {
    const portIds = portsState?.map(x => x.id) ?? [];

    if (!containsAllPorts) {
      const ids = [...new Set(state.checkedPorts.concat(portIds))];
      dispatch({ type: 'SetCheckedPorts', payload: ids });
    } else {
      const ids = getDifference(state.checkedPorts, portIds);
      dispatch({ type: 'SetCheckedPorts', payload: ids });
    }
  };

  const optionsPrep = ports && ([...new Set(ports?.map(x => x.highRiskAreaId))] ?? []);
  const options = highRiskAreas?.filter(hra => optionsPrep?.includes(hra.id)).map(hra => ({ label: hra.name, value: hra.id }));
  if ((optionsPrep as any)?.includes(null)) options?.unshift({ label: 'No HRA', value: 0 }); // TODO: Generated type doesn't include NULL for highRiskAreaId

  return (
    <Box icon={<Anchor />} header="Ports">
      {showContent && (
        <>
          <BulkChangePortFilters options={options} filters={filters} setFilters={setFilters} />
          <Table columns={tableHeaders.length + 1} striped>
            <CheckboxTableHeader>
              <Checkbox name="toggleAll"
                onChange={onChangeHandler}
                checked={containsSomePorts}
                indeterminate={!containsAllPorts}
              />
            </CheckboxTableHeader>
            {renderTableHeaders(tableHeaders)}
            {renderPorts()}
          </Table>
        </>
      )}
    </Box>
  );
};
